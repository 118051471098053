import React from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_VIDEO_LIMIT, GIFT_SHOP_UPLOAD_VIDEO_TYPE} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {DragDrop} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopUploadDropzoneVideoProps} from './GiftShopUploadDropzoneProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
`

export default function GiftShopUploadDropzoneVideo({
  onUploadVideo,
  videoValidator,
  onClickVideo,
  onNonVerifiedCreatorUpload,
}: GiftShopUploadDropzoneVideoProps) {
  const {translate} = useTranslation()
  const {open} = useDropzone({noClick: true})
  const {creatorStatus} = useSelector('yuserActivationState') || {}
  return (
    <StyledContainer
      onClick={() => {
        if (creatorStatus !== 'verified') {
          onNonVerifiedCreatorUpload()
        } else {
          onClickVideo()
          open()
        }
      }}>
      <DragDrop
        icon="video-view"
        header={translate('giftShop:uploadModeVideoHeader')}
        description={translate('giftShop:uploadModeVideoDescription', {
          max: GIFTSHOP_UPLOAD_VIDEO_LIMIT,
        })}
        noClick={creatorStatus !== 'verified'}
        onDrop={(files) => {
          if (creatorStatus !== 'verified') {
            onNonVerifiedCreatorUpload()
          } else {
            onUploadVideo(files)
          }
        }}
        validator={videoValidator}
        accept={GIFT_SHOP_UPLOAD_VIDEO_TYPE.join(',')}
      />
    </StyledContainer>
  )
}
