import React, {useCallback, useMemo} from 'react'
import {
  SERVICE_CANCELLATION_SEARCH_ADMIN,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {TreeHostPromoteTemplate} from 'pages'
import {requestData} from 'services'
import {ObjectState, TreeHostAdminData} from 'types'
import {useLocation} from 'utils'

export interface TreeHostInviteAdminProps {
  stateSelected: ObjectState<ReadonlyArray<TreeHostAdminData>>
}

export default function TreeHostPromoteAdmin({
  stateSelected,
}: TreeHostInviteAdminProps) {
  const {totalAdmin} = useLocation('tree_host_promote_admin').state

  const handleLoadSearch = useCallback(
    async (page: number, limit: number, query: string) => {
      const responses = await requestData('tree_host_search_admin', {
        cancelId: SERVICE_CANCELLATION_SEARCH_ADMIN,
        params: {
          page,
          limit,
          q: query,
        },
      })

      if (
        typeof responses === 'string' &&
        responses === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof responses !== 'string' && responses.status === 200
        ? responses.data.result
        : []
    },
    [],
  )

  const handleRenderPromote = useMemo(
    () => (
      <TreeHostPromoteTemplate
        stateSelectedAdmin={stateSelected}
        loadData={handleLoadSearch}
        totalAdmin={totalAdmin}
        type="admin"
      />
    ),
    [handleLoadSearch, stateSelected, totalAdmin],
  )

  return handleRenderPromote
}
