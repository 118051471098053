import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {API_NOTIFICATION_MAINTENANCE} from 'consts'
import {ServiceParamNotification} from './ServiceParamNotification'

export const SERVICE_MAP_NOTIFICATION: ServiceMap<ServiceParamNotification> = {
  notification_maintenance: {
    method: 'GET',
    url: getAPIBaseUrl() + API_NOTIFICATION_MAINTENANCE,
  }
}
