import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_DEFAULT_FEE_INDEPENDENT,
  HOST_DEFAULT_FEE_INTERNAL,
  HOST_IMAGE_TYPE,
  HOST_LOGO_MAX_SIZE,
  HOST_MAX_FEE_INDEPENDENT,
  HOST_MAX_FEE_INTERNAL,
  HOST_MAX_PRICE,
  HOST_MIN_PRICE,
  HOST_NAME_MAX_LENGTH,
  HOST_NAME_MIN_LENGTH,
  REGEX_ALPHANUMERIC_DOT_AND_SPACE,
  REGEX_NUMBER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeHostInformationForm, TreeUploadImageExtensionType} from 'types'
import {
  formatCurrency,
  getValueOfCurrency,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  useDidMount,
  useHistory,
} from 'utils'
import {
  Button,
  CheckboxItem,
  Icon,
  Image,
  Input,
  ModalLoading,
  Paragraph,
} from 'common/components'
import {
  VALIDATION_TREE_HOST_FEE_INDEPENDENT,
  VALIDATION_TREE_HOST_FEE_INTERNAL,
  VALIDATION_TREE_HOST_NAME,
  VALIDATION_TREE_MAX_PRICE,
  VALIDATION_TREE_MIN_PRICE,
} from 'common/validations'
import convertUnit from 'lib/unit'

interface StyledAddLogoIconContainerProps {
  cursor: CSSProperties['cursor']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledCenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledPartContainer = styled(StyledCenterColumn)`
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledLeftContainer = styled(StyledPartContainer)`
  margin-right: ${convertUnit(80)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: 0;
  }
`

const StyledUpperSection = styled(StyledCenterColumn)`
  margin-bottom: ${convertUnit(40)};
`

const StyledHashtagFormContainer = styled(StyledCenterColumn)`
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-bottom: ${convertUnit(40)};
  }
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
`

const StyledInputContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 100%;
  text-align: left;
`

const StyledSaveButton = styled(Button)`
  margin-top: ${convertUnit(40)};
  width: 100%;
`

const StyledAddLogoIconContainer = styled.div<StyledAddLogoIconContainerProps>`
  width: ${convertUnit(120)};
  margin-top: ${convertUnit(20)};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${convertUnit(8)};
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_3};
  box-sizing: border-box;
  cursor: ${({cursor}) => cursor};
`

const StyledInput = styled.input`
  display: none;
`

const StyledImg = styled(Image)`
  object-fit: cover;
  width: inherit;
  aspect-ratio: 1;
  border-radius: ${convertUnit(8)};
`

const StyledChangeLogo = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
  cursor: pointer;
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
`

export default function TreeRegistrationHostInformationForm() {
  const {translate} = useTranslation()
  const uploadRef = useRef<HTMLInputElement>(null)
  const [logo, setLogo] = useState<string>()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const form = useForm<TreeHostInformationForm>({
    defaultValues: {
      hashtag: '',
    },
  })
  const {setValue, register, watch, errors, setError, clearErrors} = form
  const {
    minPrice,
    maxPrice,
    hashtag,
    imgFile,
    imgExtension,
    imgHeight,
    imgSize,
    imgWidth,
    imgMime,
    hostFeeIndependent,
    hostFeeInternal,
  } = watch()
  const [firstBuy, setFirstBuy] = useState(true)

  const notValid = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      hashtag.match(REGEX_ALPHANUMERIC_DOT_AND_SPACE) === null ||
      getValueOfCurrency(maxPrice) < getValueOfCurrency(minPrice) ||
      hashtag.length < HOST_NAME_MIN_LENGTH ||
      hashtag.length > HOST_NAME_MAX_LENGTH ||
      parseInt(hostFeeIndependent, 10) > 100 ||
      parseInt(hostFeeInternal, 10) > 100 ||
      logo === undefined,
    [
      errors,
      hashtag,
      hostFeeIndependent,
      hostFeeInternal,
      logo,
      maxPrice,
      minPrice,
    ],
  )

  const handleLoadLogo = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      const file = uploadRef.current.files[0]
      const filename = file.name
      const extension = filename.substring(
        filename.lastIndexOf('.') + 1,
      ) as TreeUploadImageExtensionType
      const {type, size} = file
      if (!HOST_IMAGE_TYPE.includes(type)) {
        showSnackbar(translate('tree:addHostInfoErrorInvalidFileFormat'))
        return
      }
      if (size > HOST_LOGO_MAX_SIZE) {
        showSnackbar(translate('tree:addHostInfoErrorMaxFileSize'))
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const url = reader.result?.toString()
        setLogo(url)

        const image = document.createElement('img')
        if (url) {
          image.src = url
          image.onload = () => {
            const {width, height} = image
            setValue('imgFile', file)
            setValue('imgExtension', extension)
            setValue('imgHeight', height)
            setValue('imgWidth', width)
            setValue('imgSize', size)
            setValue('imgMime', type)
            setLogo(url)
          }
        }
      }
    }
  }, [setValue, translate])

  const handleGenerateLogoLink = useCallback(async () => {
    setLoading(true)
    const response = await requestData('tree_post_logo_link', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        logo: {
          extension: imgExtension,
          height: imgHeight,
          size: imgSize,
          width: imgWidth,
        },
      },
      onRequestSuccess: ({status}) => status !== 200 && setLoading(false),
      onRequestFailed: () => setLoading(false),
    })
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.logo
      : null
  }, [imgExtension, imgHeight, imgSize, imgWidth])

  const handlePublishToWasabi = useCallback(
    async (link: string) => {
      try {
        return handleUploadSingleFileToWasabi(imgFile, link, imgMime, {
          'Content-Type': imgMime,
          'x-amz-meta-imagewidth': imgWidth.toString(),
          'x-amz-meta-imageheight': imgHeight.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [imgFile, imgHeight, imgMime, imgWidth],
  )

  const handleUploadForm = useCallback(
    async (link: string) => {
      const response = await requestData('tree_patch_hosts', {
        actionType: 'execute',
        data: {
          logo_link: link,
          logo_size: imgSize,
          independent_creator_fee:
            hostFeeIndependent.length === 0
              ? HOST_DEFAULT_FEE_INDEPENDENT
              : parseInt(hostFeeIndependent, 10),
          internal_creator_fee:
            hostFeeInternal.length === 0
              ? HOST_DEFAULT_FEE_INTERNAL
              : parseInt(hostFeeInternal, 10),
          maximum_price:
            maxPrice.toString().length === 0
              ? HOST_MAX_PRICE
              : getValueOfCurrency(maxPrice),
          minimum_price:
            minPrice.toString().length === 0
              ? HOST_MIN_PRICE
              : getValueOfCurrency(minPrice),
          tag_name: hashtag,
          is_first_buy_enabled: firstBuy,
        },
        onRequestSuccess: ({status, data}) => {
          if (status !== 200) {
            setLoading(false)
            if (
              status === 400 &&
              data.detail?.tag_name === 'tag name already exists'
            ) {
              setError('hashtag', {
                type: 'manual',
                message: translate('tree:addHostInfoErrorHashtagAlreadyExist'),
              })
            }
          }
        },
        onRequestFailed: () => setLoading(false),
      })
      return typeof response !== 'string' && response.status === 200
    },
    [
      firstBuy,
      hashtag,
      hostFeeIndependent,
      hostFeeInternal,
      imgSize,
      maxPrice,
      minPrice,
      setError,
      translate,
    ],
  )

  const handlePublishInfo = useCallback(async () => {
    const link = await handleGenerateLogoLink()
    if (link) {
      const wasabiStatus = await handlePublishToWasabi(link)
      if (wasabiStatus) {
        const uploadStatus = await handleUploadForm(link)
        if (uploadStatus) {
          history.push('tree_host_profile', {})
        }
      }
    }
  }, [handleGenerateLogoLink, handlePublishToWasabi, handleUploadForm, history])

  const handleRenderUploadPicture = useMemo(
    () => (
      <StyledUpperSection>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('tree:addHostInfoLogoTitle')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('tree:addHostInfoLogoSubtitle')}
        </Paragraph>
        <StyledAddLogoIconContainer
          cursor={logo ? 'default' : 'pointer'}
          onClick={() => (logo ? undefined : uploadRef.current?.click())}>
          {logo ? (
            <StyledImg src={logo} alt={logo} />
          ) : (
            <Icon type="plus" size={convertUnit(48)} color="gray_3" />
          )}
        </StyledAddLogoIconContainer>
        {logo ? (
          <StyledChangeLogo
            fontSize="s"
            fontWeight="bold"
            color="primary_5"
            onClick={() => uploadRef.current?.click()}>
            {translate('tree:addHostInfoLogoChange')}
          </StyledChangeLogo>
        ) : null}
      </StyledUpperSection>
    ),
    [logo, translate],
  )

  const handleRenderHostNameForm = useMemo(
    () => (
      <StyledHashtagFormContainer>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('tree:addHostInfoHostNameTitle')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('tree:addHostInfoHostNameSubtitle')}
        </Paragraph>
        <StyledInputContainer>
          <Input
            form={form}
            name="hashtag"
            formRules={VALIDATION_TREE_HOST_NAME}
            label={translate('tree:addHostInfoHostNameLabel')}
            placeholder={translate('tree:addHostInfoHostNamePlaceholder')}
            maxLength={HOST_NAME_MAX_LENGTH + 1}
          />
        </StyledInputContainer>
      </StyledHashtagFormContainer>
    ),
    [form, translate],
  )

  const handleRenderCreatorPriceForm = useMemo(
    () => (
      <StyledUpperSection>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('tree:addHostInfoCreatorPriceTitle')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('tree:addHostInfoCreatorPriceSubtitle')}
        </Paragraph>
        <StyledInputContainer>
          <Input
            form={form}
            name="maxPrice"
            value={!maxPrice ? '' : formatCurrency(maxPrice)}
            allowedCharacters={REGEX_NUMBER}
            formRules={VALIDATION_TREE_MAX_PRICE(getValueOfCurrency(minPrice))}
            label={translate('tree:addHostInfoMaxPriceLabel')}
            placeholder={translate('tree:addHostInfoMaxPricePlaceholder')}
            leftText={translate('global:rp')}
            max={HOST_MAX_PRICE}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Input
            form={form}
            name="minPrice"
            value={!minPrice ? '' : formatCurrency(minPrice)}
            allowedCharacters={REGEX_NUMBER}
            formRules={VALIDATION_TREE_MIN_PRICE(getValueOfCurrency(maxPrice))}
            label={translate('tree:addHostInfoMinPriceLabel')}
            placeholder={translate('tree:addHostInfoMinPricePlaceholder')}
            leftText={translate('global:rp')}
            max={HOST_MAX_PRICE}
          />
        </StyledInputContainer>
      </StyledUpperSection>
    ),
    [form, maxPrice, minPrice, translate],
  )

  const handleRenderHostFeeForm = useMemo(
    () => (
      <StyledUpperSection>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('tree:addHostInfoHostFeeTitle')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('tree:addHostInfoHostFeeSubtitle')}
        </Paragraph>
        <StyledInputContainer>
          <Input
            form={form}
            name="hostFeeInternal"
            allowedCharacters={REGEX_NUMBER}
            formRules={VALIDATION_TREE_HOST_FEE_INTERNAL}
            label={translate('tree:addHostInfoHostFeeInternalLabel')}
            placeholder={translate('tree:addHostInfoHostFeePlaceholder')}
            rightIcon={<Icon type="percentage" size={convertUnit(16)} />}
            max={HOST_MAX_FEE_INTERNAL}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Input
            form={form}
            name="hostFeeIndependent"
            allowedCharacters={REGEX_NUMBER}
            formRules={VALIDATION_TREE_HOST_FEE_INDEPENDENT}
            label={translate('tree:addHostInfoHostFeeIndependentLabel')}
            placeholder={translate('tree:addHostInfoHostFeePlaceholder')}
            rightIcon={<Icon type="percentage" size={convertUnit(16)} />}
            max={HOST_MAX_FEE_INDEPENDENT}
          />
        </StyledInputContainer>
      </StyledUpperSection>
    ),
    [form, translate],
  )

  const handleRenderFirstBuy = useMemo(
    () => (
      <StyledCenterColumn>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('tree:addHostInfoFirstBuyTitle')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('tree:addHostInfoFirstBuySubtitle')}
        </Paragraph>
        <StyledCheckboxItem
          isSelected={firstBuy}
          description={translate('tree:addHostInfoFirstBuyDescription')}
          onClick={() => {
            setFirstBuy((prev) => !prev)
            showSnackbar(
              translate(
                firstBuy
                  ? 'tree:addHostInfoFirstBuyUnchecked'
                  : 'tree:addHostInfoFirstBuyChecked',
              ),
            )
          }}
          fontSize="m"
          color="black"
          containerStyle={{marginTop: convertUnit(20), alignSelf: 'start'}}
        />
      </StyledCenterColumn>
    ),
    [firstBuy, translate],
  )

  const handleRenderSaveButton = useMemo(
    () => (
      <StyledSaveButton
        label={translate('global:save')}
        disabled={notValid}
        onClick={handlePublishInfo}
      />
    ),
    [notValid, handlePublishInfo, translate],
  )

  const handleRenderLeftSection = useMemo(
    () => (
      <StyledLeftContainer>
        {handleRenderUploadPicture}
        {handleRenderHostNameForm}
      </StyledLeftContainer>
    ),
    [handleRenderHostNameForm, handleRenderUploadPicture],
  )

  const handleRenderRightSection = useMemo(
    () => (
      <StyledPartContainer>
        {handleRenderCreatorPriceForm}
        {handleRenderHostFeeForm}
        {handleRenderFirstBuy}
        {handleRenderSaveButton}
      </StyledPartContainer>
    ),
    [
      handleRenderCreatorPriceForm,
      handleRenderFirstBuy,
      handleRenderHostFeeForm,
      handleRenderSaveButton,
    ],
  )

  const handleRenderInputFile = useMemo(
    () => (
      <StyledInput
        ref={uploadRef}
        type="file"
        onChange={handleLoadLogo}
        accept={HOST_IMAGE_TYPE.join(',')}
      />
    ),
    [handleLoadLogo],
  )

  useDidMount(() => {
    register('imgExtension')
    register('imgHeight')
    register('imgSize')
    register('imgWidth')
    register('imgFile')
    register('imgMime')
    register('hashtag')
  })

  useEffect(() => {
    if (getValueOfCurrency(maxPrice) > getValueOfCurrency(minPrice)) {
      clearErrors('minPrice')
    }
  }, [clearErrors, maxPrice, minPrice])

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderInputFile}
      {handleRenderLeftSection}
      {handleRenderRightSection}
    </StyledContainer>
  )
}
