import React, {useMemo} from 'react'
import {LegalContentProps} from './LegalContentProps'
import {LegalBiometricFaceContent} from '../BiometricFaceContent'
import {LegalCookiePolicy} from '../CookiePolicy'
import {LegalCopyrightContent} from '../CopyrightContent'
import {
  LegalCommunityGuideline,
  LegalCreatorPortal,
} from '../CreatorPortalContent'
import {LegalFAQContent} from '../FAQContent'
import {LegalHelpCenterContent} from '../HelpCenterContent'
import {LegalHostContent} from '../HostContent'
import {LegalHowItWorksContent} from '../HowItWorksContent'
import {
  LegalPolicyContent,
  LegalTermsOfUseContent,
} from '../PrivacyPolicyContent'
import {LegalRefundPolicy} from '../RefundPolicy'
import {LegalSupergigaContent} from '../SupergigaContent'
import {LegalUnderageUserPolicyContent} from '../UnderageUserPolicyContent'
import {LegalYuserContent} from '../YuserPageContent'
import {LegalReasonOfUseContent} from '../ReasonsOfUse'
import {LegalSellGuidelines} from '../SellGuidelines'

export default function LegalContent({
  hide = [],
  section,
  handleScrollTo,
}: LegalContentProps) {
  const handleRenderContent = useMemo(() => {
    switch (section) {
      case 'how-it-works':
        return <LegalHowItWorksContent scrollTo={handleScrollTo} />
      case 'yuser':
        return <LegalYuserContent scrollTo={handleScrollTo} />
      case 'creator':
        return <LegalCreatorPortal scrollTo={handleScrollTo} />
      case 'host':
        return <LegalHostContent />
      case 'privacy':
        return <LegalPolicyContent scrollTo={handleScrollTo} />
      case 'terms-of-use':
        return <LegalTermsOfUseContent scrollTo={handleScrollTo} />
      case 'community-guidelines':
        return <LegalCommunityGuideline />
      case 'biometric-face-recognition':
        return <LegalBiometricFaceContent scrollTo={handleScrollTo} />
      case 'copyright':
        return <LegalCopyrightContent />
      case 'faq':
        return <LegalFAQContent scrollTo={handleScrollTo} />
      case 'help-center':
        return <LegalHelpCenterContent scrollTo={handleScrollTo} />
      case 'refund':
        return <LegalRefundPolicy />
      case 'cookie':
        return <LegalCookiePolicy />
      case 'underage-user-policy':
        return <LegalUnderageUserPolicyContent scrollTo={handleScrollTo} />
      case 'reasons-of-usage':
        return <LegalReasonOfUseContent />
      case 'supergiga':
        return <LegalSupergigaContent />
      case 'sell-guidelines-non-verified':
        return <LegalSellGuidelines isVerified={false} />
      case 'sell-guidelines-verified':
        return <LegalSellGuidelines isVerified />
    }
    return <></>
  }, [handleScrollTo, section])

  return (
    <>
      {hide.find((item) => item === section) ? undefined : handleRenderContent}
    </>
  )
}
