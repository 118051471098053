import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {isIOS, isSafari} from 'react-device-detect'
import Lottie from 'lottie-react'
import {Button, CheckboxItem, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {useTranslation} from 'i18n'
import {GiftShopKYCLandingPageType} from 'types'
import animationData from 'assets/lottie/kyc_user.json'
import {useLocation} from 'utils'
import {GiftShopTemplateModalNickname} from '../../../template'

const StyledContainer = styled.div`
  padding: 0 ${convertUnit(25)};
  display: flex;
  flex: 1;
  flex-direction: column;
`
const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${convertUnit(470)};
`
const StyledBottomContainer = styled.div`
  margin-top: ${convertUnit(20)};
  margin-bottom: ${convertUnit(25)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  cursor: pointer;
`
const StyledAgreement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(10)};
`
interface GiftShopRobopetActivationLandingProps {
  onAgree(): void
}

const StyledStartKYCContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: fit-content;
`
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${convertUnit(4)};
`

const StyledStartButton = styled(Button)`
  width: 100%;
`

export default function GiftShopRobopetActivationLanding({
  onAgree,
}: GiftShopRobopetActivationLandingProps) {
  const {translate} = useTranslation()
  const {isReapply} =
    useLocation('giftshop_robopet_activation_selfie').state || {}
  const {lang} = useSelector('user') || {}
  const [loadEnd, setLoadEnd] = useState(false)
  const [agree, setAgree] = useState(false)
  const [
    landingStatus,
    setLandingStatus,
  ] = useState<GiftShopKYCLandingPageType>(isReapply ? 'tnc' : 'start')

  const handleRenderTitle = useMemo(
    () => (
      <StyledRow>
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {3}
        </Paragraph>
        <Paragraph fontSize="m" color="primary_5">
          {translate('giftShop:xOfy')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {3}
        </Paragraph>
      </StyledRow>
    ),
    [translate],
  )

  const handleRenderKycUnsupportedWarning = useMemo(
    () =>
      isIOS &&
      !isSafari && (
        <Paragraph color="danger_5" fontWeight="medium">
          {translate('giftShop:kycWarningUnsupported')}
        </Paragraph>
      ),
    [translate],
  )

  const handleRenderImg = useMemo(
    () => <Lottie animationData={animationData} />,
    [],
  )

  const renderStartKYC = useMemo(
    () => (
      <StyledStartKYCContainer>
        {handleRenderKycUnsupportedWarning}
        {handleRenderImg}
        {handleRenderTitle}
        <Paragraph fontSize="m" fontWeight="medium" color="gray_7">
          {translate('global:kycModalSubtitle')}
        </Paragraph>
        <Paragraph fontSize="s" color="gray_5">
          {translate('global:kycModalInfo')}
        </Paragraph>
        <StyledStartButton
          label={translate('global:start')}
          onClick={() => (isReapply ? onAgree() : setLandingStatus('tnc'))}
        />
      </StyledStartKYCContainer>
    ),
    [
      handleRenderImg,
      handleRenderKycUnsupportedWarning,
      handleRenderTitle,
      isReapply,
      onAgree,
      translate,
    ],
  )

  const renderModalReason = useMemo(
    () => (
      <StyledContainer>
        <StyledIframe
          src={`${process.env.HOST_REASON_OF_USAGE}&lang=${lang}`}
          title="Reason of Use"
          onLoad={() => setLoadEnd(true)}
        />
        <StyledBottomContainer>
          <StyledAgreement>
            <StyledCheckbox
              isSelected={agree}
              onClick={() => setAgree((prev) => !prev)}
            />
            <Paragraph color="gray_5" fontSize="l">
              {translate('giftShop:kycAgreement')}
            </Paragraph>
          </StyledAgreement>
          <StyledButton
            disabled={!loadEnd || !agree}
            onClick={() => (isReapply ? setLandingStatus('start') : onAgree())}
            label={translate('global:iAgree')}
          />
        </StyledBottomContainer>
      </StyledContainer>
    ),
    [agree, isReapply, lang, loadEnd, onAgree, translate],
  )

  const renderPage = useMemo(() => {
    switch (landingStatus) {
      case 'start':
        return renderStartKYC
      case 'tnc':
        return renderModalReason
      default:
        return renderStartKYC
    }
  }, [landingStatus, renderModalReason, renderStartKYC])

  return (
    <>
      <GiftShopTemplateModalNickname />
      {renderPage}
    </>
  )
}
