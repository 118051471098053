import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {getContentURL, useDidMount, useHistory} from 'utils'
import {Paragraph} from 'common/components'
import GiftShopMessageRoomContentItemSingleContent from '../GiftShopMessageRoomContentItemImage'

export interface GiftShopMessageRoomContentItemResendRevisionProps {
  isSelf: boolean
  payload: FirebaseMessageRoomItemPayload['request_paid_content']
}

export default function GiftShopMessageRoomContentItemRequestPaidContent({
  payload,
}: GiftShopMessageRoomContentItemResendRevisionProps) {
  const {translate} = useTranslation()
  const {content_id, invoice_id, message, total_invoice_content} = payload
  const history = useHistory()
  const [data, setData] = useState<GiftShopContentData>()
  const [loading, setLoading] = useState(true)
  const getUrl = getContentURL({url: data?.url || '', type: 'collection'})

  const handleLoadContent = useCallback(() => {
    requestData('giftshop_get_contents_detail', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {content_ids: content_id},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result[0])
        setLoading(false)
      },
    })
  }, [content_id])

  const handleOnClickContent = useCallback(() => {
    history.replace('giftshop_transaction_detail', {
      invoice_id: payload.invoice_id,
    })
  }, [])

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentItemSingleContent
        data={data}
        onClick={handleOnClickContent}
        totalContent={total_invoice_content}
        src={getUrl}
        loading={loading}
      />
    ),
    [data, getUrl, handleOnClickContent, loading],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('messages:notificationResendApprovalInvoiceId', {
            invoice_id,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">{message}</Paragraph>
        <br />
      </>
    ),
    [invoice_id, message, translate],
  )

  useDidMount(handleLoadContent)

  return (
    <>
      {handleRenderContent}
      {handleRenderMessage}
    </>
  )
}
