import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {BanModalHandler} from 'types'
import {logout, setBanModalHandler} from 'utils'
import {BanModalProviderProps} from './BanModalProps'
import {BanModalContext} from './BanModalContext'
import BanModalItem from './BanModalItem'

export default function BanModalProvider({children}: BanModalProviderProps) {
  const [responseStatus, setResponseStatus] = useState(0)
  const [responseMessage, setResponseMessage] = useState('')

  const handleShowBanModal = useCallback(
    (response: number, message: string) => {
      setResponseStatus(response)
      setResponseMessage(message)
    },
    [],
  )

  const handleRemoveBanModal = useCallback(() => {
    setResponseStatus(0)
    setResponseMessage('')
    logout()
  }, [])

  const handleRenderBanModal = useMemo(
    () =>
      (responseStatus === 403 || responseStatus === 423) && (
        <BanModalItem
          status={responseStatus}
          message={responseMessage}
          onClickOk={handleRemoveBanModal}
        />
      ),
    [handleRemoveBanModal, responseMessage, responseStatus],
  )

  const handler = useMemo<BanModalHandler>(
    () => ({
      responseStatus,
      responseMessage,
      showBanModal: handleShowBanModal,
    }),
    [handleShowBanModal, responseMessage, responseStatus],
  )

  useEffect(() => {
    setBanModalHandler(handler)
  }, [handler])

  return (
    <BanModalContext.Provider value={handler}>
      {handleRenderBanModal}
      {children}
    </BanModalContext.Provider>
  )
}
