import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {HOST_BIO_MAX_LENGTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {Button, InputArea, ModalLoading} from 'common/components'
import {VALIDATION_TREE_HOST_BIODATA} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeEditProfileFormStringProps} from './TreeEditProfileFormProps'

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    padding: ${convertUnit(20)};
    border-radius: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeEditProfileFormBio({
  defaultValue,
  stateActiveContent,
}: TreeEditProfileFormStringProps) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)

  const form = useForm<{biodata: string}>({
    defaultValues: {
      biodata: defaultValue || '',
    },
  })
  const {watch} = form
  const {biodata} = watch()
  const disabled = useMemo(
    () => biodata.length > HOST_BIO_MAX_LENGTH || biodata === defaultValue,
    [biodata, defaultValue],
  )

  const handlePatchHost = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_hosts', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        biodata,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        status === 200 && setActiveContent('profile')
      },
    })
  }, [biodata, setActiveContent])

  const handleRenderForm = useMemo(
    () => (
      <InputArea
        form={form}
        name="biodata"
        formRules={VALIDATION_TREE_HOST_BIODATA}
        labelDescription={translate('global:charCount', {
          count: biodata.length,
          max: HOST_BIO_MAX_LENGTH,
        })}
        inputContainerStyle={{height: convertUnit(90)}}
        label={translate('tree:editHostFormLabelBio')}
        placeholder={translate('tree:editHostFormPlaceholderBio')}
        maxLength={HOST_BIO_MAX_LENGTH + 1}
      />
    ),
    [biodata.length, form, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={disabled}
        label={translate('global:save')}
        onClick={handlePatchHost}
      />
    ),
    [disabled, handlePatchHost, translate],
  )

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderForm}
      {handleRenderButton}
    </StyledContainer>
  )
}
