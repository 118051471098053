import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {FORKYGRAM_CAPTION_LINE_HEIGHT, ICON_SIZE, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {
  getBorder,
  getFontFamily,
  getFontSize,
  getTextShadow,
  showModalLimitedAccess,
  useDidMount,
  useDidUpdate,
  useHistory,
} from 'utils'
import {Icon, IconVerifiedBadge, Image, Paragraph} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AssetImageFotoTree} from 'types'
import {ForkygramTemplatePostCaptionMobileProps} from './ForkygramTemplatePostCaptionMobileProps'

interface StyledParagraphProps {
  collapse: boolean
  hasTag?: boolean
}

interface StyledProfileProps {
  shouldUpdateGap: boolean
}

interface StyledAvatarContainerProps {
  isLive: boolean
}

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: ${convertUnit(12)};
  bottom: ${convertUnit(18)};
  display: flex;
  flex-direction: column;
  width: calc(100% - (${convertUnit(70)}));
  max-width: calc(100% - (${convertUnit(82)}));
`

const StyledParagraph = styled(Paragraph)`
  overflow-wrap: anywhere;
  word-break: break-word;
  white-space: pre-line;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledParagraphContainer = styled.div<StyledParagraphProps>`
  height: fit-content;
  overflow: hidden;
  max-height: ${({collapse}) =>
    collapse ? convertUnit(FORKYGRAM_CAPTION_LINE_HEIGHT) : 'fit-content'};
`

const StyledCaptionContainer = styled.div<StyledParagraphProps>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  z-index: 5;
  ${({hasTag}) => ({marginTop: convertUnit(hasTag ? -4 : 0)})};
`

const StyledUsernameWrapper = styled.div`
  display: flex;
  align-items: center;
`
const StyledUsernameContainer = styled.div<StyledParagraphProps>`
  flex: 1;
  align-self: flex-end;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledProfileContainer = styled.div<StyledProfileProps>`
  ${({shouldUpdateGap}) => ({gap: convertUnit(shouldUpdateGap ? 16 : 8)})};
  display: flex;
  flex: 1;
  align-items: center;
`
const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  ${({theme, isLive}) => ({
    border: getBorder(1, 'solid', isLive ? theme.fotoyu_logo_1 : theme.white_1),
  })}
  position: relative;
  margin-bottom: ${convertUnit(2)};
  border-radius: ${convertUnit(4)};
  cursor: pointer;
  height: ${convertUnit(60)};
  aspect-ratio: 0.75;
  background-color: ${({theme}) => theme.black};
`
const StyledAvatar = styled(Image)`
  height: ${convertUnit(60)};
  aspect-ratio: 0.75;
  border-radius: ${convertUnit(3)};
  object-fit: contain;
`
const StyledFotoyuBadge = styled.img`
  width: ${convertUnit(ICON_SIZE)};
  height: ${convertUnit(ICON_SIZE)};
  line-height: ${convertUnit(16)};
  margin-bottom: ${convertUnit(-6)};
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-bottom: ${convertUnit(-4)};
`

const StyledLiveContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(1)};
  padding: 0 ${convertUnit(2)};
  background-color: ${({theme}) => theme.fotoyu_logo_1};
`

const StyledFotoTreeTag = styled.div<StyledParagraphProps>`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  align-items: flex-start;
  gap: ${convertUnit(4)};
  z-index: 1;
`
const StyledFotoTreePinContainer = styled.div`
  width: fit-content;
`
const StyledFotoTreePin = styled(Image)`
  height: ${convertUnit(16)};
`
const StyledFollowButton = styled.div`
  position: absolute;
  width: fit-content;
  top: ${convertUnit(4)};
  left: ${convertUnit(36)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(4)};
  padding: ${convertUnit(2)};
  padding-top: 0;
  cursor: pointer;
  z-index: 3;
`
const StyledInlineParagraph = styled(StyledParagraph)`
  display: inline;
  margin-right: ${convertUnit(4)};
`
const StyledCollaboratorMember = styled.span`
  display: flex;
  color: ${({theme}) => theme.white_1};
  font-size: ${getFontSize('s')};
  font-family: ${getFontFamily('regular')};
  line-height: ${convertUnit(17)};
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`
const StyledCollaboratorMemberUsername = styled.strong`
  cursor: pointer;
  font-family: ${getFontFamily('bold')};
  padding-left: ${convertUnit(2.5)};
  flex: 1;
`
const StyledCollaboratorWhitespace = styled.div`
  cursor: pointer;
  flex: 1;
  height: ${convertUnit(15)};
  padding: ${convertUnit(2)} 0;
`
const StyledIconVerifiedBadgeWrapper = styled.div`
  display: inline;
`

export default function ForkygramTemplatePostCaptionMobile({
  data,
  isLive,
  onFollow,
  stateFollowButton,
}: ForkygramTemplatePostCaptionMobileProps) {
  const {id, access_token, username} = useSelector('user') || {}
  const {status: childStatus} = useSelector('parentalConsentState')
  const history = useHistory()
  const {update} = useDispatch()
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const {translate} = useTranslation()
  const {is_official, creator_status, tag} = data
  const [followButton, setFollowButton] = stateFollowButton
  const fotoyuBadge = IMAGE_ASSET('giftshop', 'fotoyu-badge.svg')
  const usernameRef = useRef<HTMLDivElement>(null)
  const [usernameClientHeight, setUsernameClientHeight] = useState(0)

  const collaborator = useMemo(
    () =>
      data.collaborator_members?.find((val) => val.username !== data.username),
    [data.collaborator_members, data.username],
  )

  const handleNavigateFotoTreeDetail = useCallback(() => {
    update('lastTreeState', {videoIntro: true})
    history.push(
      'tree_fototree_detail',
      {
        treeId: tag.tree_id,
        treeAlias: tag.alias,
      },
      tag.alias,
    )
  }, [history, tag.alias, tag.tree_id, update])

  const handleNavigateProfile = useCallback(() => {
    if (id !== data.member_id && childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: data.member_id === id,
        postData: data,
      },
      data.username,
    )
  }, [childStatus, data, history, id])

  const handleNavigateProfileCollaborator = useCallback(() => {
    if (!collaborator) return
    if (username !== collaborator.username && childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: collaborator.username === username,
        postData: {
          username: collaborator.username,
          photo: collaborator.photo,
        },
      },
      collaborator.username,
    )
  }, [childStatus, collaborator, history, username])

  const collapse = useMemo(() => height > FORKYGRAM_CAPTION_LINE_HEIGHT, [
    height,
  ])

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  const handleFotoyuBadge = useMemo(
    () => (is_official ? <StyledFotoyuBadge src={fotoyuBadge} /> : null),
    [fotoyuBadge, is_official],
  )

  const handleRenderFotoTreeTag = useMemo(
    () =>
      tag.name && (
        <StyledFotoTreeTag
          collapse={collapse}
          onClick={handleNavigateFotoTreeDetail}>
          <StyledFotoTreePinContainer>
            <StyledFotoTreePin
              src={IMAGE_ASSET(
                'tree',
                `icons/pinpoint-${
                  tag.icon?.split('/icons/')[1]
                }` as AssetImageFotoTree,
              )}
              alt=""
            />
          </StyledFotoTreePinContainer>
          <StyledInlineParagraph
            fontWeight="medium"
            color="white_1"
            lineHeight={14}>
            {`${tag.name}${tag.location?.name && ` • ${tag.location.name}`}`}
          </StyledInlineParagraph>
        </StyledFotoTreeTag>
      ),
    [
      collapse,
      handleNavigateFotoTreeDetail,
      tag.icon,
      tag.location?.name,
      tag.name,
    ],
  )

  const handleRenderCollaboratorMember = useMemo(
    () =>
      collaborator && (
        <StyledCollaboratorMember>
          {translate('global:and')}
          <StyledCollaboratorMemberUsername
            onClick={handleNavigateProfileCollaborator}>
            @{collaborator.username}
          </StyledCollaboratorMemberUsername>
        </StyledCollaboratorMember>
      ),
    [collaborator, handleNavigateProfileCollaborator, translate],
  )

  const handleRenderCaption = useMemo(
    () =>
      data.caption !== '' && (
        <StyledCaptionContainer
          hasTag={!!data.tag.name}
          onClick={() =>
            height > FORKYGRAM_CAPTION_LINE_HEIGHT
              ? setHeight(FORKYGRAM_CAPTION_LINE_HEIGHT)
              : ref.current && setHeight(ref.current?.clientHeight)
          }
          collapse={collapse}>
          <StyledParagraphContainer collapse={collapse} ref={ref}>
            <StyledParagraph color="white_1" lineHeight={14}>
              {data.caption.replace(/\\n/g, '\n')}
            </StyledParagraph>
          </StyledParagraphContainer>
          {(height >= FORKYGRAM_CAPTION_LINE_HEIGHT ||
            (ref.current &&
              ref.current?.clientHeight > FORKYGRAM_CAPTION_LINE_HEIGHT)) && (
            <StyledParagraph color="white_1" fontWeight="bold">
              {'...'}
              {collapse ? translate('global:more') : translate('global:less')}
            </StyledParagraph>
          )}
        </StyledCaptionContainer>
      ),
    [collapse, data.caption, data.tag.name, height, translate],
  )

  useDidMount(() => {
    ref.current && setHeight(ref.current.clientHeight)
  })

  useDidUpdate(() => {
    usernameRef.current?.clientHeight &&
      setUsernameClientHeight(usernameRef.current.clientHeight)
  }, [usernameRef.current?.clientHeight])

  return (
    <StyledContainer>
      <StyledProfileContainer
        shouldUpdateGap={
          !data.is_followed &&
          followButton &&
          usernameClientHeight > FORKYGRAM_CAPTION_LINE_HEIGHT
        }>
        {!data.is_followed && followButton && data.member_id !== id ? (
          <StyledFollowButton
            onClick={() => {
              if (access_token) {
                setFollowButton(false)
              }
              onFollow()
            }}>
            <Icon type="plus" size={12} color="primary_5" />
          </StyledFollowButton>
        ) : (
          <></>
        )}
        <StyledAvatarContainer isLive={isLive} onClick={handleNavigateProfile}>
          <StyledAvatar
            src={data.photo}
            alt="avatar"
            onClick={handleNavigateProfile}
          />
          {isLive && (
            <StyledLiveContainer>
              <Icon type="fg" size={convertUnit(7)} color="white_1" />
              <Paragraph fontSize="xxs" fontWeight="bold" color="white_1">
                {translate('global:live').toUpperCase()}
              </Paragraph>
            </StyledLiveContainer>
          )}
        </StyledAvatarContainer>
        <StyledUsernameContainer collapse={collapse} ref={usernameRef}>
          <StyledUsernameWrapper>
            <StyledInlineParagraph
              className="pressable"
              fontWeight="bold"
              color="white_1"
              fontSize="m"
              onClick={handleNavigateProfile}>
              @{data.username}
            </StyledInlineParagraph>
            <StyledIconVerifiedBadgeWrapper>
              {handleFotoyuBadge}
              {handleRenderBadge}
            </StyledIconVerifiedBadgeWrapper>
            {collaborator && (
              <StyledCollaboratorWhitespace
                onClick={handleNavigateProfileCollaborator}
              />
            )}
          </StyledUsernameWrapper>
          {handleRenderCollaboratorMember}
        </StyledUsernameContainer>
      </StyledProfileContainer>
      {handleRenderFotoTreeTag}
      {handleRenderCaption}
    </StyledContainer>
  )
}
