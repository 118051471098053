import {ModalOnHoldHandler} from 'types'

let handler: ModalOnHoldHandler | undefined

export function setModalOnHoldHandler(instance: ModalOnHoldHandler) {
  handler = instance
}

export function showModalOnHold(visible: boolean) {
  handler && handler.showModalOnHold(visible)
}
