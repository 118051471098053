import React, {useCallback, useMemo, useRef} from 'react'
import {REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY} from 'consts'
import {
  ForkygramTextExtractionParam,
  ForkygramTextExtractionValues,
} from 'types'

export function useKeywordExtract({
  ref,
  keyword = '@',
  listSearchPropsState,
  searchState,
  startIndex = 1,
}: ForkygramTextExtractionParam) {
  const keywordPosition = useRef(0)
  const setListSearchProps = listSearchPropsState
    ? listSearchPropsState[1]
    : undefined
  const [search, setSearch] = searchState
  const targetPhrase = useRef('')

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key !== 'Enter' && ref.current)
        if (REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY.test(e.key)) {
          switch (e.key) {
            case keyword: {
              let keywordPosValue = ref.current.selectionStart || 0
              keywordPosition.current =
                keywordPosValue > 0 ? (keywordPosValue -= 1) : 0
              break
            }
            default: {
              const currentPos = ref.current?.selectionStart || 0
              if (ref.current.value.charAt(currentPos - 1) === '@') {
                keywordPosition.current = currentPos - 2
                setSearch(true)
              }
              break
            }
          }
        } else if (e.key === ' ') setSearch(false)
    },
    [keyword, ref, setSearch],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        search &&
        (e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLTextAreaElement)
      ) {
        const {value} = e.target
        if (value.includes(keyword)) {
          targetPhrase.current = value.slice(
            keywordPosition.current + 1 + startIndex,
            ref.current?.selectionStart || undefined,
          )
          setListSearchProps && setListSearchProps(new Date().getTime())
        } else setSearch(false)
      }
    },
    [keyword, ref, search, setListSearchProps, setSearch, startIndex],
  )

  return useMemo<ForkygramTextExtractionValues>(
    () => ({handleKeyDown, handleChange, extractedText: targetPhrase}),
    [handleChange, handleKeyDown],
  )
}
