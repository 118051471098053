import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {CheckboxItem, Icon, Paragraph} from 'common/components'
import {parseDate} from 'utils'
import convertUnit from 'lib/unit'
import {GiftShopTemplateListContentProps} from './GiftShopTemplateListContentProps'

interface CheckboxItemProps {
  hidden?: boolean
}

interface ItemContainerProps {
  selectable?: boolean
}

const StyledItemContainer = styled.div<ItemContainerProps>`
  ${({selectable}) => ({
    cursor: selectable ? 'pointer' : 'initial',
  })};
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  margin-bottom: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledItemDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledCheckboxItem = styled(CheckboxItem)<CheckboxItemProps>`
  ${({hidden}) => ({
    visibility: hidden ? 'hidden' : 'visible',
  })}
  margin-right: ${convertUnit(4)};
`

export default function GiftShopTemplateListContentItem({
  item,
  onClickItem,
  isSelected = false,
  selectable,
  toggleSelect,
}: GiftShopTemplateListContentProps) {
  const {purchase_status, is_deletable, content_type} = item

  const showSelect = useMemo(
    () =>
      toggleSelect &&
      (selectable ?? purchase_status === 'available') &&
      !(purchase_status === 'resend' && content_type === 'video') &&
      purchase_status !== 'resendpology' &&
      !is_deletable,
    [content_type, is_deletable, purchase_status, selectable, toggleSelect],
  )

  const handleRenderSelect = useMemo(
    () =>
      toggleSelect && (
        <StyledCheckboxItem
          isSelected={isSelected}
          size={32}
          hidden={!showSelect}
        />
      ),
    [isSelected, showSelect, toggleSelect],
  )

  const handleClick = useCallback(() => {
    if (toggleSelect && !showSelect) return undefined

    onClickItem && onClickItem(item)
    return undefined
  }, [item, onClickItem, showSelect, toggleSelect])

  return (
    <StyledItemContainer
      onClick={handleClick}
      selectable={(toggleSelect && showSelect) || !toggleSelect}>
      {handleRenderSelect}
      <Icon type="image" color="primary_5" size={28} />
      <StyledItemDetailWrapper>
        <Paragraph fontSize="m" fontWeight="medium">
          {item.title}
        </Paragraph>
        <Paragraph fontWeight="medium" color="gray_6">
          {parseDate(item.original_at as string, 'DD MMM YYYY, HH:mm:ss')}
        </Paragraph>
      </StyledItemDetailWrapper>
    </StyledItemContainer>
  )
}
