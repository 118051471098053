import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {INITIAL_DATA_IMAGE, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {GiftshopTemplateUploadResendModal} from 'pages'
import {
  GiftShopContentData,
  GiftshopResendModalState,
  GiftshopUploadContentData,
  GiftShopUploadResendData,
  WindowModeType,
} from 'types'
import {
  clearUserCache,
  getBorder,
  useDebounce,
  useDidUpdate,
  useGiftShopResendHandler,
  useHistory,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopProfileResendBatchListProps} from './GiftShopProfileResendBatchListProps'
import {GiftShopProfileResendBatchContent} from '../ResendBatchContent'

interface StyledFooterContainerProps {
  mode: WindowModeType
}

const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(12)} 0;
  margin-bottom: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-bottom: ${convertUnit(1)};
  }
`

const StyledHeaderItem = styled(Paragraph)`
  width: 50%;
  text-align: center;
`

const StyledFooterContainer = styled.div<StyledFooterContainerProps>`
  ${({theme, mode}) => ({
    borderTop: getBorder(1, 'solid', theme.gray_1),
    padding: `${convertUnit(15)} ${convertUnit(mode === 'mobile' ? 25 : 50)}`,
    backgroundColor: theme.white_1,
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const StyledCount = styled(Paragraph)`
  flex: 1;
  margin-right: ${convertUnit(25)};
`

const StyledUploadButton = styled(Button)`
  flex: 1;
`

const StyledTryAgain = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopProfileResendBatchList({
  data,
}: GiftShopProfileResendBatchListProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const mode = useWindowMode()
  const stateResendData = useState<
    {
      id: string
      image: GiftshopUploadContentData
    }[]
  >([])
  const resendData = stateResendData[0]
  const stateResendContent = useState<GiftShopUploadResendData>({
    image: INITIAL_DATA_IMAGE,
  })
  const {uploadResend} = useGiftShopResendHandler({
    stateContent: stateResendContent,
  })
  const resendModalState = useState<GiftshopResendModalState>('none')
  const setResendModal = resendModalState[1]
  const history = useHistory()
  const [success, setSuccess] = useState(0)
  const stateData = useState<ReadonlyArray<GiftShopContentData>>(data)
  const setData = stateData[1]
  const debounce = useDebounce()

  const handleResend = useCallback(() => {
    setResendModal('loading')
    resendData.forEach(async (item) => {
      const {id, image} = item
      const isSuccess = await uploadResend(image, id)
      if (isSuccess) {
        setSuccess((prev) => prev + 1)
        setData((prev) => prev.filter((fil) => fil.content_id !== id))
      } else {
        setResendModal('failed')
      }
    })
  }, [resendData, setData, setResendModal, uploadResend])

  useDidUpdate(() => {
    if (success === resendData.length) {
      setResendModal('success')
      clearUserCache('creation')
      debounce(
        () => history.replace('giftshop_profile', {self: true}, username),
        500,
      )
    }
  }, [success, resendData.length, history])

  const handleLoadData = useCallback(() => data, [data])

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:standard')}
        </StyledHeaderItem>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:hires')}
        </StyledHeaderItem>
      </StyledHeaderContainer>
    ),
    [mode, translate],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <>
        <GiftShopProfileResendBatchContent
          data={item}
          stateResendData={stateResendData}
        />
      </>
    ),
    [stateResendData],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledFooterContainer mode={mode}>
        <StyledCount fontSize="l" fontWeight="medium">
          {translate('giftShop:resendContentCount', {
            count: data.length,
          })}
        </StyledCount>
        <StyledUploadButton
          disabled={resendData.length < data.length}
          label={translate('global:upload')}
          backgroundColor="primary_5"
          onClick={handleResend}
        />
      </StyledFooterContainer>
    ),
    [data.length, handleResend, mode, resendData.length, translate],
  )

  const handleRenderTryAgain = useMemo(
    () => (
      <StyledTryAgain>
        <StyledButton
          label={translate('global:close')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={() =>
            history.replace('giftshop_profile', {self: true}, username)
          }
        />
        <StyledButton
          label={translate('global:tryAgain')}
          onClick={() => setResendModal('none')}
        />
      </StyledTryAgain>
    ),
    [history, setResendModal, translate, username],
  )

  const handleRenderModalResendStatus = useMemo(
    () => (
      <GiftshopTemplateUploadResendModal
        resendModalState={resendModalState}
        bottomElement={handleRenderTryAgain}
        success={success}
        total={resendData.length}
      />
    ),
    [handleRenderTryAgain, resendData.length, resendModalState, success],
  )

  return (
    <>
      {handleRenderModalResendStatus}
      <ListLazy
        data={data}
        scrollbar={false}
        stateData={stateData}
        keyExtractor={(item) => item.content_id}
        loadData={handleLoadData}
        renderItem={handleRenderItem}
        listHeaderElement={handleRenderHeader}
      />
      {handleRenderButton}
    </>
  )
}
