import React from 'react'
import styled from 'styled-components'
import {useWindowMode} from 'windows'
import {WindowModeType} from 'types'
import {IMAGE_ASSET} from 'consts'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileEmptyTabProps} from './TemplateProfileEmptyTabProps'

interface StyledImageProps {
  mode: WindowModeType
}

interface StyledDescProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
  text-align: center;
  align-self: center;
`

const StyledTitle = styled(Paragraph)``

const StyledDesc = styled(Paragraph)<StyledDescProps>`
  ${({mode}) => ({
    maxWidth: mode === 'website' ? 'initial' : convertUnit(350),
  })}
  width: 100%;
`

const StyledButton = styled(Button)`
  height: ${convertUnit(32)};
  margin-top: ${convertUnit(20)};
`

const StyledImage = styled(Image)<StyledImageProps>`
  ${({mode}) => ({
    width: mode === 'website' ? convertUnit(100) : convertUnit(70),
    height: mode === 'website' ? convertUnit(100) : convertUnit(70),
  })}
  align-self: flex-end;
`

export default function TemplateProfileEmptyTab({
  buttonLabel,
  description,
  title,
  onClickButton,
  withImage,
}: TemplateProfileEmptyTabProps) {
  const mode = useWindowMode()
  const roboImg = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <StyledContainer>
      {title && (
        <StyledTitle fontSize="l" fontWeight="bold">
          {title}
        </StyledTitle>
      )}
      {description && (
        <StyledDesc mode={mode} fontSize="s" color="gray_5">
          {description}
        </StyledDesc>
      )}
      {buttonLabel && (
        <StyledButton
          color="white_1"
          fontSize="s"
          backgroundColor="primary_5"
          label={buttonLabel}
          onClick={onClickButton}
        />
      )}
      {withImage && <StyledImage mode={mode} src={roboImg} alt={roboImg} />}
    </StyledContainer>
  )
}
