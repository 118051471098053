import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useDidMount, useHistory} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'

const StyledContainer = styled.div`
  width: 100%;
  padding: ${convertUnit(12)} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  background-color: ${({theme}) => theme.info_1};
  cursor: pointer;
`

const StyledSections = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  align-items: center;
  padding: 0 ${convertUnit(20)};
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCountContainer = styled.div`
  width: ${convertUnit(24)};
  height: ${convertUnit(24)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.warning_5};
`

export default function GiftShopCollectionResendApprovalBanner() {
  const {translate} = useTranslation()
  const [visible, setVisible] = useState(false)
  const [count, setCount] = useState(0)
  const history = useHistory()
  const {update} = useDispatch()

  const handleLoadData = useCallback(() => {
    requestData('gitfshop_get_collection_resend_approval_count', {
      actionType: 'fetch',
      useDefaultMessage: true,
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          const {total} = result
          setCount(total)
          if (total > 0) {
            setVisible(true)
          }
        }
      },
    })
  }, [])

  const handleRenderCloseIcon = useMemo(
    () => (
      <Icon
        type="close"
        size={convertUnit(16)}
        color="primary_5"
        onClick={() => setVisible((prev) => !prev)}
      />
    ),
    [],
  )

  const handleRenderText = useMemo(
    () => (
      <StyledTextContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {translate('giftShop:hiresConfirmationWaitingTitle')}
        </Paragraph>
        <Paragraph color="primary_5">
          {translate('giftShop:hiresConfirmationWaitingSubtitle')}
        </Paragraph>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderLeftSection = useMemo(
    () => (
      <StyledSections>
        {handleRenderCloseIcon}
        {handleRenderText}
      </StyledSections>
    ),
    [handleRenderCloseIcon, handleRenderText],
  )

  const handleRenderCounter = useMemo(
    () => (
      <StyledCountContainer>
        <Paragraph fontWeight="bold" color="white_1">
          {count}
        </Paragraph>
      </StyledCountContainer>
    ),
    [count],
  )

  const handleRenderRightSection = useMemo(
    () => (
      <StyledSections>
        {handleRenderCounter}
        <Icon type="chevron-right" size={convertUnit(16)} color="primary_5" />
      </StyledSections>
    ),
    [handleRenderCounter],
  )

  useDidMount(handleLoadData)

  return visible ? (
    <StyledContainer
      onClick={() => {
        update('giftshopState', {showCollectionMark: false})
        history.push('giftshop_collector_resend_approval', {})
      }}>
      {handleRenderLeftSection}
      {handleRenderRightSection}
    </StyledContainer>
  ) : (
    <></>
  )
}
