import React, {useCallback, useMemo, useState} from 'react'
import {useParams} from 'react-router'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_FARMER,
  SERVICE_CANCELLED_RESPONSE,
  TREE_MAX_FARMER,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import {TreeMember, WindowModeType} from 'types'
import {showSnackbar, useDebounce, useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  Icon,
  Image,
  Input,
  ListLazy,
  Paragraph,
  RadioItem,
} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledProps {
  mode: WindowModeType
}

interface StyledButtonProps {
  isFulfilled: Boolean
}

const StyledContainer = styled.div<StyledProps>`
  ${({mode}) => ({
    paddingTop: mode === 'website' ? convertUnit(40) : 0,
  })}
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${({theme}) => theme.white_3};
  box-sizing: border-box;
`

const StyledContentContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${convertUnit(600)};
  gap: ${convertUnit(20)};
  ${({mode}) => ({
    padding: mode !== 'mobile' ? convertUnit(40) : convertUnit(20),
    borderRadius: mode === 'website' ? convertUnit(20) : 0,
    maxHeight: mode === 'website' ? convertUnit(610) : 'unset',
  })}
  background-color: ${({theme}) => theme.white_1};
`

const StyledModalContentContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(32)};
  padding: ${convertUnit(20)};
  max-width: ${convertUnit(600)};
  max-height: ${convertUnit(610)};
  background-color: ${({theme}) => theme.white_1};
  ${({mode}) => ({
    borderRadius: mode === 'website' ? convertUnit(20) : 0,
  })}
`

const StyledItemContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(0)};
`

const StyledSelectedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${convertUnit(57)};
  justify-content: space-between;
  gap: ${convertUnit(2)};
`

const StyledSelectedListContainer = styled.div`
  display: flex;
  gap: ${convertUnit(16)};
`

const StyledItemInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: ${convertUnit(20)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(48)};
  height: ${convertUnit(48)};
  border-radius: ${convertUnit(48)};
`

const StyledSelectedImage = styled(Image)`
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border-radius: ${convertUnit(48)};
`

const StyledFarmerImage = styled(Image)`
  width: ${convertUnit(190)};
  height: ${convertUnit(190)};
`

const StyledCheckContainer = styled.div<StyledButtonProps>`
  ${({isFulfilled}) => ({cursor: isFulfilled ? 'pointer' : 'default'})}
`

const StyledInput = styled(Input)<StyledProps>`
  display: block;
  width: fill-available;
`

const StyledSelectedMemberContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  ${({mode}) => ({
    padding:
      mode !== 'website'
        ? `${convertUnit(0)} ${convertUnit(40)}`
        : `${convertUnit(0)}`,
  })}
`

const StyledParagraph = styled(Paragraph)`
  width: ${convertUnit(57)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-align: center;
`

const StyledModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(16)};
`

const StyledInfoContainer = styled.div`
  display: flex;
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_3};
`

export default function TreeFototreeAddFarmerScreen() {
  const farmerImg = IMAGE_ASSET('giftshop', 'farmer-illustration.png')
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {alias}: {alias: string} = useParams()
  const {tree_id, length} = useLocation('tree_fototree_add_farmer').state
  const [query, setQuery] = useState('')
  const [selectedMember, setSelectedMember] = useState<TreeMember[]>([])
  const history = useHistory()
  const debounce = useDebounce()
  const [modal, setModal] = useState(false)

  const memberIds = useMemo(() => selectedMember.map((item) => item.id), [
    selectedMember,
  ])

  const isFulfilled = useMemo(() => selectedMember.length > 0, [
    selectedMember.length,
  ])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_search_farmers', {
        useDefaultMessage: true,
        cancelId: SERVICE_CANCELLATION_SEARCH_FARMER,
        actionType: 'fetch',
        params: {tree_id, q: query, page, limit},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.members
        : []
    },
    [query, tree_id],
  )

  const handleSubmitForm = useCallback(() => {
    requestData('tree_put_farmers', {
      useDefaultMessage: true,
      actionType: 'fetch',
      data: {member_ids: memberIds, tree_id},
      onRequestSuccess: ({status}) => {
        if (status === 200) setModal(true)
        else if (status === 422)
          showSnackbar(
            translate('tree:addFarmerExceedLimit', {count: TREE_MAX_FARMER}),
          )
      },
    })
  }, [memberIds, translate, tree_id])

  const handleKeyExtractor = useCallback((item: TreeMember) => item.id, [])

  const onClickRadioItem = useCallback(
    (item: TreeMember) => {
      memberIds.includes(item.id)
        ? setSelectedMember((prev) =>
            prev.filter((elem) => elem.id !== item.id),
          )
        : memberIds.length + (length || 0) < 5
        ? setSelectedMember((prev) => [...prev, item])
        : showSnackbar(
            translate('tree:addFarmerExceedLimit', {count: TREE_MAX_FARMER}),
          )
    },
    [length, memberIds, translate],
  )

  const handleRenderItem = useCallback(
    (item: TreeMember) => (
      <StyledItemContainer onClick={() => onClickRadioItem(item)}>
        <StyledItemInfoContainer>
          <StyledImage src={item.photo} alt="member profile picture" />
          <Paragraph fontSize="m" fontWeight="bold">
            {item.username}
          </Paragraph>
        </StyledItemInfoContainer>
        <RadioItem size={16} isSelected={memberIds.includes(item.id)} />
      </StyledItemContainer>
    ),
    [memberIds, onClickRadioItem],
  )

  const handleSubmitButton = useMemo(
    () => (
      <StyledCheckContainer isFulfilled={isFulfilled}>
        <Icon
          type="check"
          color={isFulfilled ? 'primary_5' : 'gray_4'}
          onClick={handleSubmitForm}
        />
      </StyledCheckContainer>
    ),
    [handleSubmitForm, isFulfilled],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledInfoContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
          {translate('tree:hostManageAdminUsernameNotFound')}
        </Paragraph>
      </StyledInfoContainer>
    ),
    [translate],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        loadData={handleLoadData}
        search={query}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        scrollbar={false}
        listEmptyElement={query !== '' ? handleRenderEmpty : undefined}
      />
    ),
    [
      handleKeyExtractor,
      handleLoadData,
      handleRenderEmpty,
      handleRenderItem,
      query,
    ],
  )

  const handleRenderSelectedMember = useMemo(
    () => (
      <StyledSelectedMemberContainer mode={mode}>
        <Paragraph fontSize="xs" fontWeight="bold" color="gray_3">
          {translate('tree:selectedFarmer', {count: selectedMember.length})}
        </Paragraph>
        <StyledSelectedListContainer>
          {selectedMember.map((item) => (
            <StyledSelectedItemContainer>
              <StyledSelectedImage
                src={item.photo}
                alt="member profile picture"
              />
              <StyledParagraph fontSize="s" fontWeight="medium">
                {item.username}
              </StyledParagraph>
            </StyledSelectedItemContainer>
          ))}
        </StyledSelectedListContainer>
      </StyledSelectedMemberContainer>
    ),
    [mode, selectedMember, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer mode={mode}>
        <StyledContentContainer mode={mode}>
          <StyledInput
            mode={mode}
            containerStyle={
              mode === 'mobile'
                ? {
                    padding: `${convertUnit(20)}`,
                    boxSizing: 'border-box',
                  }
                : undefined
            }
            onChangeText={(text) => {
              debounce(() => setQuery(text))
            }}
            leftIcon={<Icon type="search" size={16} />}
            placeholder={translate('tree:addFarmerInputPlaceholder')}
            rightIcon={'delete'}
            onRightIconClick={() => setQuery('')}
          />
          {selectedMember.length > 0 && handleRenderSelectedMember}
          {handleRenderList}
        </StyledContentContainer>
      </StyledContainer>
    ),
    [
      debounce,
      handleRenderList,
      handleRenderSelectedMember,
      mode,
      selectedMember.length,
      translate,
    ],
  )

  const handleRenderSuccessModal = useMemo(
    () => (
      <StyledContainer mode={mode}>
        <StyledModalContentContainer mode={mode}>
          <StyledModalInnerContainer>
            <Paragraph fontSize={'xl'} fontWeight="bold">
              {translate('tree:addFarmerSuccess')}
            </Paragraph>
            <Paragraph fontSize={'m'} fontWeight="regular" color="gray_5">
              {translate('tree:addFarmerSuccessDescription')}
            </Paragraph>
            <StyledFarmerImage src={farmerImg} alt="farmer illustration" />
          </StyledModalInnerContainer>
          <Button
            style={{width: '100%'}}
            label={translate('tree:manageFarmer')}
            onClick={() =>
              history.replace('tree_fototree_manage_farmer', {tree_id}, alias)
            }
          />
        </StyledModalContentContainer>
      </StyledContainer>
    ),
    [alias, farmerImg, history, mode, translate, tree_id],
  )

  return (
    <GiftShopRootScreen
      headerTitle={translate('tree:addFarmer')}
      showBadgeIcon={false}
      onBackButtonClicked={() =>
        history.replace('tree_fototree_manage_farmer', {tree_id}, alias)
      }
      headerButtons={!modal && handleSubmitButton}>
      {modal ? handleRenderSuccessModal : handleRenderContent}
    </GiftShopRootScreen>
  )
}
