import React, {useCallback, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import {IMAGE_CROPPER_SIZE} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {ImageCropperProps} from './ImageCropperProps'
import {Button} from '../Button'
import {Icon} from '../Icon'
import {Modal} from '../Modal'

interface StyledModalProps {
  mode: WindowModeType
}

const StyledModal = styled(Modal)<StyledModalProps>`
  ${({mode}) => ({
    width: mode === 'mobile' ? convertUnit(275) : convertUnit(400),
    padding: mode === 'mobile' ? convertUnit(25) : convertUnit(50),
  })}
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButtonContainer = styled.div`
  width: ${convertUnit(250)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: ${convertUnit(25)} 0;
`
const StyledIconContainer = styled.div`
  width: ${convertUnit(44)};
  height: ${convertUnit(44)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_3};
  cursor: pointer;
  border-radius: ${convertUnit(50)};
`
const StyledSaveButton = styled(Button)`
  width: 100%;
`

export default function ImageCropper({
  visible,
  file,
  componentRef,
  borderRadius = 250,
  border = 0,
  shape = 'square',
  onBackdropPress,
  onClickSave,
}: ImageCropperProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)

  const getCropperSize = useCallback(
    (sizeDim: 'width' | 'height') =>
      IMAGE_CROPPER_SIZE[shape].find((item) => item.mode === mode)![sizeDim],
    [mode, shape],
  )

  return (
    <StyledModal
      visible={visible}
      onBackdropPress={onBackdropPress}
      mode={mode}>
      <AvatarEditor
        image={file}
        borderRadius={borderRadius}
        width={getCropperSize('width')}
        height={getCropperSize('height')}
        color={[230, 230, 230, 0.8]}
        border={border}
        scale={scale}
        rotate={rotate}
        ref={componentRef}
      />
      <StyledButtonContainer>
        <StyledIconContainer
          onClick={() => (scale < 10 ? setScale(scale + 0.5) : undefined)}>
          <Icon type="plus" size={15} />
        </StyledIconContainer>
        <StyledIconContainer
          onClick={() => (scale > 1 ? setScale(scale - 0.5) : undefined)}>
          <Icon type="minus" size={15} />
        </StyledIconContainer>
        <StyledIconContainer onClick={() => setRotate(rotate - 90)}>
          <Icon type="rotate-left" size={15} />
        </StyledIconContainer>
        <StyledIconContainer onClick={() => setRotate(rotate + 90)}>
          <Icon type="rotate-right" size={15} />
        </StyledIconContainer>
      </StyledButtonContainer>
      <StyledSaveButton
        label={translate('global:saveChanges')}
        onClick={onClickSave}
      />
    </StyledModal>
  )
}
