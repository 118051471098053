import React, {
  MutableRefObject,
  UIEventHandler,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {
  LIST_CHUNK_LENGTH,
  LIST_CHUNK_RADIUS,
  LIST_END_REACHED_THRESHOLD,
} from 'consts'
import {
  CSSHtmlProperties,
  ListEndReachedInfo,
  ListMetrics,
  ListToken,
} from 'types'

export function useListAction<ItemT>({
  ref,
  data,
  token,
  headerLength,
  footerLength,
  contentLength,
  empty,
  minLength,
  chunkLength = LIST_CHUNK_LENGTH,
  chunkRadius = LIST_CHUNK_RADIUS,
  onEndReachedThreshold = LIST_END_REACHED_THRESHOLD,
  visibilityIndexThreshold = 0.75,
  keyExtractor,
  forceUpdate,
  onScroll,
  onEndReached,
  onVisibilityIndexChange,
}: {
  ref: MutableRefObject<HTMLDivElement | null>
  data: ReadonlyArray<ItemT>
  token: MutableRefObject<ListToken>
  headerLength: MutableRefObject<number>
  footerLength: MutableRefObject<number>
  contentLength: MutableRefObject<number>
  empty: ListMetrics
  minLength: number
  chunkRadius?: number
  chunkLength?: number
  onEndReachedThreshold?: number
  visibilityIndexThreshold?: number
  keyExtractor(item: ItemT): string
  forceUpdate(): void
  onScroll?: CSSHtmlProperties<HTMLDivElement>['onScroll']
  onEndReached?(info: ListEndReachedInfo): void
  onVisibilityIndexChange?(index: number): void
}) {
  const metrics = useRef<Record<string, ListMetrics>>({})
  const rehydrate = useRef(false)
  const endZone = useRef(false)
  const chunk = useRef(0)
  const visibilityIndex = useRef(token.current.start)

  const handleGetMetrics = useCallback(
    (key: string | null) =>
      key !== null && metrics.current[key] ? metrics.current[key] : empty,
    [empty],
  )

  const handleUpdateMetrics = useCallback(
    (key: string, values: Partial<ListMetrics>) => {
      if (!metrics.current[key]) {
        metrics.current[key] = {
          length: minLength,
          offset: 0,
        }
      }

      const metric = metrics.current[key]

      for (const [k, v] of Object.entries(values)) {
        metric[k] = v
      }
    },
    [minLength],
  )

  const handleUpdateItem = useCallback(
    (item: ItemT, index: number) => {
      const key = keyExtractor(item)
      const keyPrevious =
        index && data.length ? keyExtractor(data[index - 1]) : null
      const metricPrevious = handleGetMetrics(keyPrevious)
      const {offset: offsetPrevious, length: lengthPrevious} = metricPrevious
      const offset = offsetPrevious + lengthPrevious

      handleUpdateMetrics(key, {offset})
    },
    [data, keyExtractor, handleGetMetrics, handleUpdateMetrics],
  )

  const handleUpdateVisibilityIndex = useCallback(
    (index: number) => {
      if (visibilityIndex.current !== index) {
        visibilityIndex.current = index
        onVisibilityIndexChange && onVisibilityIndexChange(index)
      }
    },
    [onVisibilityIndexChange],
  )

  const handleUpdateVisibility = useCallback(
    (scrollTop: number, checkToken = false) => {
      const scrollChunk = Math.floor(scrollTop / chunkLength)
      const top = (scrollChunk - chunkRadius) * chunkLength
      const bottom = (scrollChunk + chunkRadius + 1) * chunkLength
      const indices: number[] = []

      for (let index = 0; index < data.length; index += 1) {
        const item = data[index]
        const key = keyExtractor(item)
        const {offset: itemTop, length: itemLength} = handleGetMetrics(key)
        const itemBottom = itemTop + itemLength

        if (
          (itemBottom >= top && itemBottom <= bottom) ||
          (itemTop >= top && itemTop <= bottom)
        ) {
          indices.push(index)
        }
      }

      let newToken: ListToken

      if (indices.length) {
        newToken = {start: indices[0], size: indices.length}
      } else {
        newToken = {start: 0, size: 0}
      }

      const isChanged = Object.entries(newToken).some(
        ([k, v]) => token.current[k] !== v,
      )

      token.current = newToken

      if (!checkToken || isChanged) {
        forceUpdate()
      }
    },
    [
      data,
      token,
      chunkRadius,
      chunkLength,
      keyExtractor,
      forceUpdate,
      handleGetMetrics,
    ],
  )

  const handleUpdate = useCallback(() => {
    const scrollTop = ref.current?.scrollTop ?? 0

    rehydrate.current = false
    data.forEach(handleUpdateItem)

    if (data.length) {
      const keyLastIndex = keyExtractor(data[data.length - 1])
      const {offset, length} = handleGetMetrics(keyLastIndex)
      contentLength.current = offset + length
    }

    handleUpdateVisibility(scrollTop)
  }, [
    ref,
    data,
    contentLength,
    keyExtractor,
    handleGetMetrics,
    handleUpdateItem,
    handleUpdateVisibility,
  ])

  const handleItemLayout = useCallback(
    ({
      key,
      relativeIndex,
      length,
      relativeArray,
    }: {
      key: string
      relativeIndex: number
      length: number
      relativeArray: ItemT[]
    }) => {
      if (metrics.current[key]?.length !== length) {
        handleUpdateMetrics(key, {length})
        rehydrate.current = true
      }

      if (relativeIndex === relativeArray.length - 1 && rehydrate.current) {
        handleUpdate()
      }
    },
    [handleUpdate, handleUpdateMetrics],
  )

  const handleEndReached = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const {offsetHeight, scrollHeight, scrollTop} = event.currentTarget
      const dif = scrollHeight - (scrollTop + offsetHeight)
      const value = dif <= onEndReachedThreshold

      if (value !== endZone.current) {
        if (value) {
          onEndReached && onEndReached({distanceFromEnd: dif})
        }

        endZone.current = value
      }
    },
    [onEndReachedThreshold, onEndReached],
  )

  const handleVisibilityIndexChange = useCallback<
    UIEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (onVisibilityIndexChange) {
        const {scrollTop, offsetHeight} = event.currentTarget
        const top = scrollTop
        const bottom = scrollTop + offsetHeight

        let visibilityToken: {index: number; coverage: number} = {
          index: visibilityIndex.current,
          coverage: 0,
        }

        for (
          let index = token.current.start;
          index < index + token.current.size && index < data.length;
          index += 1
        ) {
          const key = keyExtractor(data[index])
          const {offset, length} = handleGetMetrics(key)
          const itemTop = offset
          const itemBottom = offset + length
          const coverage =
            (Math.min(itemBottom, bottom) - Math.max(itemTop, top)) / length

          if (coverage >= visibilityIndexThreshold) {
            handleUpdateVisibilityIndex(index)
            return
          }

          if (coverage > visibilityToken.coverage) {
            visibilityToken = {index, coverage}
          }
        }

        handleUpdateVisibilityIndex(visibilityToken.index)
      }
    },
    [
      data,
      token,
      visibilityIndexThreshold,
      onVisibilityIndexChange,
      keyExtractor,
      handleGetMetrics,
      handleUpdateVisibilityIndex,
    ],
  )

  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      const {scrollTop} = event.currentTarget
      const scrollChunk = Math.floor(scrollTop / chunkLength)

      onScroll && onScroll(event)
      handleEndReached(event)

      if (scrollChunk !== chunk.current) {
        chunk.current = scrollChunk
        handleUpdateVisibility(scrollTop, true)
      }

      handleVisibilityIndexChange(event)
    },
    [
      chunkLength,
      onScroll,
      handleEndReached,
      handleVisibilityIndexChange,
      handleUpdateVisibility,
    ],
  )

  const handleLayoutHeader = useCallback(
    (length: number) => {
      if (headerLength.current !== length) {
        rehydrate.current = true
      }

      headerLength.current = length
    },
    [headerLength],
  )

  const handleLayoutFooter = useCallback(
    (length: number) => {
      if (footerLength.current !== length) {
        rehydrate.current = true
      }

      footerLength.current = length
    },
    [footerLength],
  )

  return useMemo(
    () => ({
      handleGetMetrics,
      handleItemLayout,
      handleScroll,
      handleLayoutHeader,
      handleLayoutFooter,
      handleUpdateVisibility,
    }),
    [
      handleGetMetrics,
      handleItemLayout,
      handleScroll,
      handleLayoutHeader,
      handleLayoutFooter,
      handleUpdateVisibility,
    ],
  )
}
