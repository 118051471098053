import {Theme} from '../Theme'

export const THEME_DARK: Theme = {
  black: '#FFFFFF',
  black_1: '#E3E3E3',
  black_2: '#EBEBEB',
  danger_1: '#6A0A43',
  danger_2: '#801048',
  danger_3: '#9F1A50',
  danger_4: '#BE2655',
  danger_5: '#DD3559',
  danger_6: '#EA6474',
  danger_7: '#F48587',
  danger_8: '#FBB4AF',
  danger_9: '#FDDDD6',
  fotoyu_logo_1: '#B737E2',
  fotoyu_logo_2: '#A6F655',
  gray_1: '#1A1A1A',
  gray_2: '#333333',
  gray_3: '#4D4D4D',
  gray_4: '#666666',
  gray_5: '#808080',
  gray_6: '#999999',
  gray_7: '#B3B3B3',
  gray_8: '#CCCCCC',
  gray_9: '#E6E6E6',
  info_1: '#002765',
  info_2: '#00377A',
  info_3: '#004D97',
  info_4: '#0068B5',
  info_5: '#0086D3',
  info_6: '#39B0E4',
  info_7: '#60CEF1',
  info_8: '#96E8FA',
  info_9: '#CAF6FC',
  primary_1: '#F9E0F8',
  primary_2: '#F2C3F3',
  primary_3: '#D69BDD',
  primary_4: '#AF75BC',
  primary_5: '#7E4890',
  primary_6: '#65347B',
  primary_7: '#4D2467',
  primary_8: '#371653',
  primary_9: '#270D45',
  success_1: '#055312',
  success_2: '#096511',
  success_3: '#0F7D0F',
  success_4: '#209616',
  success_5: '#35AF1F',
  success_6: '#6CCF4F',
  success_7: '#98E774',
  success_8: '#C5F7A5',
  success_9: '#E4FBD1',
  warning_1: '#6F3F00',
  warning_2: '#865100',
  warning_3: '#A76A00',
  warning_4: '#C78500',
  warning_5: '#E8A200',
  warning_6: '#F1C03C',
  warning_7: '#F8D463',
  warning_8: '#FCE797',
  warning_9: '#FDF4CB',
  white_1: '#000000',
  white_2: '#030303',
  white_3: '#0F0F0F',
  white_4: '#5F5F5F',
  white_5: '#0F0F0F',
}
