import React from 'react'
import styled from 'styled-components'
import {getThemeColor} from 'utils'
import convertUnit from 'lib/unit'
import {GiftShopFeeModalProps} from './GiftShopPriceFeeModalProps'

const StyledModalOverlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`
const StyledBackdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
`

const StyledModal = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  bottom: 0;
  border-top-left-radius: ${convertUnit(16)};
  border-top-right-radius: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`
export default function ModalHalfscreen({
  visible,
  children,
  ...props
}: GiftShopFeeModalProps) {
  return visible ? (
    <StyledModalOverlay>
      <StyledBackdrop />
      <StyledModal {...props}>{children}</StyledModal>
    </StyledModalOverlay>
  ) : null
}
