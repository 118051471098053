import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { TextValidation } from '../TextValidation'
import { TextValidationGroupProps } from './TextValidationGroupProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default function TextValidationGroup({
  text,
  validations,
  containerStyle,
  onChangeValidity,
}: TextValidationGroupProps) {
  const isValid = useMemo(() => {
    if (text) {
      for (const { validation } of validations) {
        if (
          !(typeof validation === 'function'
            ? validation(text)
            : validation.test(text))
        ) {
          return false
        }
      }

      return true
    }

    return undefined
  }, [text, validations])

  const handleRenderValidations = useMemo(
    () =>
      validations.map(({ validation, message }, index) => (
        <TextValidation
          key={index.toString()}
          text={text}
          validation={validation}
        >
          {message}
        </TextValidation>
      )),
    [text, validations],
  )

  useEffect(() => {
    onChangeValidity(isValid)
  }, [isValid, onChangeValidity])

  return (
    <StyledContainer style={containerStyle}>
      {handleRenderValidations}
    </StyledContainer>
  )
}
