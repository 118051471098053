import React from 'react'
import convertUnit from 'lib/unit'
import {IconVerifiedBadgeProps} from './IconVerifiedBadgeProps'

export default function IconVerifiedBadge({
  width = convertUnit(16),
  height = convertUnit(16),
  fill = 'none',
  ...props
}: IconVerifiedBadgeProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 2 16 16">
      <g clipPath="url(#clip0_3230_13639)">
        <path
          d="M7.94128 12.2544C7.30981 13.007 6.18784 13.1052 5.43529 12.4737L0.666748 8.47247L2.04117 6.83459L6.57245 10.6367L12.5902 3.33987C13.2216 2.58732 14.3436 2.48916 15.0962 3.12062L15.3334 3.31971L7.94128 12.2544Z"
          fill="#A6F655"
        />
        <path
          d="M8.3243 12.5758L8.32431 12.5758L8.32652 12.5732L15.7187 3.63844L16.0352 3.25586L15.6548 2.93669L15.4175 2.7376C14.4535 1.92864 13.0161 2.05438 12.2071 3.01847L12.2044 3.02174L6.50755 9.92954L2.36256 6.45156L1.97955 6.13018L1.65815 6.51318L0.283735 8.15106L-0.0376807 8.53409L0.345357 8.85549L5.1139 12.8567C6.07799 13.6657 7.51533 13.5399 8.3243 12.5758Z"
          stroke="black"
          strokeOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_3230_13639">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
