import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {LayoutViewClient} from 'types'

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T | null>,
): LayoutViewClient {
  const [size, setSize] = useState<LayoutViewClient>({
    clientWidth: 0,
    clientHeight: 0,
  })

  const handleResize = useCallback(() => {
    setSize({
      clientWidth: ref.current?.offsetWidth || 0,
      clientHeight: ref.current?.offsetHeight || 0,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useLayoutEffect(() => {
    handleResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.offsetHeight, ref.current?.offsetHeight])

  return size
}

export default useElementSize
