import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestDataPayload} from 'services'
import {TreeSearchFototreeResponse, GiftShopUploadType} from 'types'
import {
  formatDateRange,
  getGiftshopLocationName,
  showSnackbar,
  useDebounce,
} from 'utils'
import {
  IconVerifiedBadge,
  Input,
  ListItemSelect,
  ListLazy,
  Menu,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormTagsProps} from './GiftShopTemplateUploadFormTagsProps'

interface StyledTagsProps {
  uploadType?: GiftShopUploadType
}

const StyledContentListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledTags = styled.div<StyledTagsProps>`
  width: ${({uploadType}) => (uploadType === 'bulk' ? '100%' : 'unset')};
  margin-left: ${({uploadType}) =>
    uploadType === 'single' ? convertUnit(25) : 0};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-left: ${convertUnit(0)};
  }
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  text-align: center;
`

const StyledBold = styled.span`
  font-family: 'Roboto-Bold';
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  line-height: ${convertUnit(12)};
`

export default function GiftShopTemplateUploadFormTags({
  form,
  tags,
  uploadType,
  hasLocationMetadata = false,
}: GiftShopTemplateUploadFormTagsProps) {
  const {translate} = useTranslation()
  const debounce = useDebounce()
  const stateTags = useState<readonly TreeSearchFototreeResponse[]>([])
  const [contentTags, setTags] = stateTags
  const [searchTag, setSearchTag] = useState(tags[0] ? tags[0].name : '')
  const [searchQuery, setSearchQuery] = useState(searchTag)
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const falseState = useState(false)
  const {setValue, watch} = form
  const values = watch()
  const selectedTags = values.tags
  const [error, setError] = useState<string>()

  const handleLoadData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestDataPayload('tree_search_fototree', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {page, limit, name: q, is_upload: true},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result.filter(
            (item) =>
              selectedTags.findIndex((tag) => tag.tag_id === item.tag_id) ===
              -1,
          )
        : null
    },
    [selectedTags],
  )

  const handleDeleteTag = useCallback(() => {
    setSearchTag('')
    setSearchQuery('')
    setValue(
      'tags',
      values.tags.filter((tag) => tag.tag_id !== selectedTags[0].tag_id),
    )
  }, [selectedTags, setValue, values.tags])

  const handleSelect = useCallback(
    (item: TreeSearchFototreeResponse) => {
      setError(undefined)
      setSearchTag(item.name)
      if (selectedTags.filter((value) => value.id === item.id).length) {
        setToggle((previous) => !previous)
        return
      }
      if (
        !values.location ||
        Object.keys(values.location).length === 0 ||
        !hasLocationMetadata
      ) {
        const {location: tagLocation, name} = item
        const locationVal = getGiftshopLocationName({
          latitude: tagLocation?.latitude.toString(),
          longitude: tagLocation?.longitude.toString(),
          name,
        })
        setValue('location', locationVal)
      }
      setValue('tags', [...values.tags, item])
      setToggle((previous) => !previous)
      setTags((previous) =>
        previous.filter((value) => value.tag_id !== item.tag_id),
      )
    },
    [
      hasLocationMetadata,
      selectedTags,
      setTags,
      setToggle,
      setValue,
      values.location,
      values.tags,
    ],
  )

  const handleRenderDropdownItem = useCallback(
    (item: TreeSearchFototreeResponse) => (
      <ListItemSelect
        title={item.name}
        titleRighIcon={
          item.status === 'VERIFIED' ? <StyledIconVerifiedBadge /> : <></>
        }
        overflowSubtitle={`${
          item.host_fee
            ? `${translate('giftShop:uploadTagHostFeeFototreeDesc', {
                hostFee: item.host_fee,
              })}`
            : ''
        }
        ${
          item.host_fee && (item.event_end_date || item.event_start_date)
            ? ' | '
            : ''
        }
        ${
          item.event_end_date && item.event_start_date
            ? `${formatDateRange(item.event_start_date, item.event_end_date)}`
            : ''
        }
        ${
          (item.host_fee || item.event_end_date || item.event_start_date) &&
          item.bio
            ? ' | '
            : ''
        }
        ${item.bio ? `${item.bio}` : ''}`}
        hoverColor="white_3"
        titleHoverColor="black"
        onClick={() => {
          if (selectedTags.length === 0) {
            handleSelect(item)
          } else {
            showSnackbar(
              translate('giftShop:fototreeCannotAddMoreThanOneSnackbar'),
            )
          }
        }}
      />
    ),
    [handleSelect, selectedTags.length, translate],
  )

  const handleRenderTagListEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="m">
          {translate('giftShop:uploadInputTagsNotFound')}
          <StyledBold>{`"${searchTag}"`}</StyledBold>
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [searchTag, translate],
  )

  const handleRenderTagList = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateTags}
          loadData={handleLoadData}
          search={searchQuery}
          data={contentTags}
          keyExtractor={(tag) => tag.tag_id}
          renderItem={handleRenderDropdownItem}
          listEmptyElement={handleRenderTagListEmpty}
        />
      </StyledContentListContainer>
    ),
    [
      contentTags,
      handleLoadData,
      handleRenderDropdownItem,
      handleRenderTagListEmpty,
      searchQuery,
      stateTags,
    ],
  )

  return (
    <StyledTags uploadType={uploadType}>
      <Menu
        stateToggle={
          selectedTags.filter((tag) => tag.type !== 'host').length < 1
            ? stateToggle
            : falseState
        }
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={handleRenderTagList}>
        <Input
          value={searchTag}
          label={translate('giftShop:uploadTagLabel')}
          containerStyle={{
            marginTop: convertUnit(25),
          }}
          rightIcon="delete"
          onRightIconClick={handleDeleteTag}
          placeholder={translate('giftShop:uploadInputTagsPlaceholder')}
          borderColor={error ? 'danger_5' : undefined}
          hoverBorderColor={error ? 'danger_5' : undefined}
          onChangeText={(text) => {
            setSearchTag(text)
            debounce(() => setSearchQuery(text), 500)
            setError(undefined)
            if (!text) handleDeleteTag()
          }}
          onBlur={() => {
            if (searchTag && !selectedTags.length) {
              setError(translate('giftShop:uploadInputTagsNotSelected'))
            }
          }}
          onFocus={() => {
            setError(undefined)
            setToggle(true)
          }}
        />
      </Menu>
      {error ? (
        <Paragraph color="danger_5" fontWeight="medium">
          {error}
        </Paragraph>
      ) : (
        <></>
      )}
      {(!!selectedTags[0] && selectedTags[0].host_fee) ||
      (!!tags[0] && tags[0].host_fee) ? (
        <Paragraph color="gray_5">
          {translate('giftShop:uploadTagHostFee', {
            hostFee: selectedTags[0].host_fee || tags[0].host_fee,
          })}
        </Paragraph>
      ) : (
        <></>
      )}
    </StyledTags>
  )
}
