import React, {CSSProperties, useCallback, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, CheckboxItem} from 'common/components'
import convertUnit from 'lib/unit'
import {ConfirmationModalProps} from './ConfirmationModalProps'
import {Modal} from '../Modal'
import {Paragraph} from '../Paragraph'

const StyledConfirmationModal = styled.div<CSSProperties>`
  width: ${({width = convertUnit(275)}) => width};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 5;
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${convertUnit(25)};
  justify-content: right;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(15)};
`

const StyledText = styled(Paragraph)`
  text-align: center;
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledButtonClose = styled(StyledButton)`
  margin-right: ${convertUnit(25)};
`

const StyledSubText = styled(StyledText)`
  margin-top: ${convertUnit(8)};
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${convertUnit(20)};
`

const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(10)};
`

const StyledCheckboxDesc = styled(Paragraph)`
  cursor: pointer;
`

export default function ConfirmationModal({
  text,
  title,
  subText,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  toggleModal,
  checkboxText,
  checboxDefaultStatus = false,
  ...props
}: ConfirmationModalProps) {
  const {translate} = useTranslation()
  const [isSelected, setIsSelected] = useState(checboxDefaultStatus)

  const handleConfirm = useCallback(() => {
    toggleModal()
    onConfirm(isSelected)
  }, [isSelected, onConfirm, toggleModal])

  return (
    <Modal {...props}>
      <StyledConfirmationModal>
        {title && (
          <StyledTitle fontWeight="bold" fontSize="xl">
            {title}
          </StyledTitle>
        )}
        <StyledText fontWeight="regular" fontSize="l">
          {text}
        </StyledText>
        {subText ? (
          <StyledSubText fontSize="s">{subText}</StyledSubText>
        ) : (
          <></>
        )}
        {checkboxText ? (
          <StyledCheckboxContainer>
            <StyledCheckbox
              isSelected={isSelected}
              size={16}
              onClick={() => setIsSelected((prev) => !prev)}
            />
            <StyledCheckboxDesc
              fontWeight="medium"
              color="gray_5"
              onClick={() => setIsSelected((prev) => !prev)}>
              {checkboxText}
            </StyledCheckboxDesc>
          </StyledCheckboxContainer>
        ) : (
          <></>
        )}
        <StyledModalButtonContainer>
          <StyledButtonClose
            label={cancelText || translate('global:no')}
            width={convertUnit(125)}
            backgroundHoverColor="white_3"
            backgroundColor="white_2"
            color="primary_5"
            onClick={() => {
              onCancel && onCancel()
              toggleModal()
            }}
          />
          <StyledButton
            backgroundColor="primary_5"
            color="white_1"
            label={confirmText || translate('global:yes')}
            onClick={handleConfirm}
            fontWeight="medium"
            fontSize="m"
          />
        </StyledModalButtonContainer>
      </StyledConfirmationModal>
    </Modal>
  )
}
