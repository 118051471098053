import { useContext } from 'react'
import { ThemeType } from './Theme'
import { ThemeContextState } from './ThemeContext'
import { THEME_MAP } from './ThemeMap'

export function useThemeTypeState() {
  return useContext(ThemeContextState)!
}

export function useTheme(type?: ThemeType) {
  const active = useThemeTypeState()[0]

  return THEME_MAP[type || active]
}
