import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, List, ModalLoading, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {
  ObjectState,
  UserSimilarAccountData,
  UserSimilarAccountPayload,
  UserSimilarAccountStatus,
} from 'types'
import {logout, showSnackbar, useDidMount} from 'utils'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {requestData} from 'services'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {
  GiftShopTemplateModalOtherAccount,
  GiftShopTemplateModalOtherAccountFinish,
  GiftShopTemplateSimilarAccountItem,
} from '../../template'
import GiftShopSimilarChooseAccountSelf from './GiftShopSimilarChooseAccountSelf'
import GiftShopSimilarChooseAccountMulti from './GiftShopSimilarChooseAccountMulti'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  background-color: ${({theme}) => theme.white_3};
`
const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${convertUnit(560)};
  padding: 0 ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    height: 100%;
  }
  background-color: ${({theme}) => theme.white_1};
`
const StyledHeader = styled(Paragraph)`
  padding-top: ${convertUnit(20)};
  white-space: pre-line;
`
const StyledButton = styled(Button)`
  width: fit-content;
`

interface GiftShopSimilarIdentityProps {
  stateFinished: ObjectState<boolean>
}

export default function GiftShopSimilarIdentity({
  stateFinished,
}: GiftShopSimilarIdentityProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const [selectedId, setSelectedId] = useState<UserSimilarAccountData>()
  const [finishedModal, setFinishedModal] = useState(false)
  const stateErrorMessage = useState('')
  const setErrorMessage = stateErrorMessage[1]
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const [selfLoading, setSelfLoading] = useState(false)
  const [similarStatus, setSimilarStatus] = useState<UserSimilarAccountStatus>()
  const [similarData, setSimilarData] = useState<
    ReadonlyArray<UserSimilarAccountData>
  >([])

  const handleLoadData = useCallback(async () => {
    const response = await requestData('giftshop_similar_get_account')
    if (typeof response !== 'string' && response.status === 200) {
      const getSelfData = response.data.result.filter((x) => x.id === id)[0]
      response.data.result.unshift(getSelfData)
      response.data.result.splice(
        response.data.result.lastIndexOf(getSelfData),
        1,
      )
      setSimilarData(response.data.result)
      return response.data.result
    }
    return null
  }, [id])

  const handleActivateOther = useCallback(
    (item: UserSimilarAccountData) => {
      setErrorMessage('')
      setSelectedId(item)
    },
    [setErrorMessage],
  )

  const handleSuccessConfirm = useCallback(() => {
    setSelectedId(undefined)
    setFinishedModal(true)
  }, [])

  const handleFinish = useCallback(() => {
    setFinishedModal(false)
    logout()
  }, [])

  const handleSubmit = useCallback(
    (item: UserSimilarAccountPayload) => {
      item.id === id ? setSelfLoading(true) : setLoading(true)
      requestData('giftshop_similar_post_account', {
        data: {id: item.id, identifier: item.identifier},
        onRequestReceived: () => {
          setSelfLoading(false)
          setLoading(false)
        },
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            if (item.id === id) {
              setSimilarStatus('WAITING_REMOVAL')
            } else {
              handleSuccessConfirm()
            }
          } else if (status === 400 && data.detail !== undefined) {
            if (data.detail.identifier === 'invalid') {
              setErrorMessage(
                translate('giftShop:similarOtherAccountFailedMessage', {
                  context: item.method,
                }),
              )
            } else {
              showSnackbar(
                translate('global:messageError', {context: `code-${status}`}),
              )
            }
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
        onRequestFailed: (reason) => {
          showSnackbar(translate('global:messageError', {context: reason}))
        },
      })
    },
    [handleSuccessConfirm, id, setErrorMessage, setLoading, translate],
  )

  const handleRenderItem = useCallback(
    (item: UserSimilarAccountData) => (
      <GiftShopTemplateSimilarAccountItem
        data={item}
        showCurrentLabel={item.id === id}
        rightComponent={
          <StyledButton
            label={translate('global:activate')}
            onClick={() =>
              item.id === id
                ? handleSubmit({id: item.id, identifier: item.username})
                : handleActivateOther(item)
            }
          />
        }
      />
    ),
    [handleActivateOther, handleSubmit, id, translate],
  )

  const handleRenderSelfSelect = useMemo(
    () => <GiftShopSimilarChooseAccountSelf stateFinished={stateFinished} />,
    [stateFinished],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        <StyledContentContainer>
          <List
            data={similarData}
            keyExtractor={(item) => item.id}
            renderItem={handleRenderItem}
            scrollbar={false}
            listHeaderElement={
              <StyledHeader fontSize="l" fontWeight="medium">
                {translate('giftShop:similarChooseAccountDesc')}
              </StyledHeader>
            }
          />
        </StyledContentContainer>
      </StyledContainer>
    ),
    [handleRenderItem, similarData, translate],
  )

  const handleRenderMultiAccountContent = useMemo(
    () => <GiftShopSimilarChooseAccountMulti stateFinished={stateFinished} />,
    [stateFinished],
  )

  const handleRenderVerifModal = useMemo(
    () =>
      selectedId !== undefined && (
        <GiftShopTemplateModalOtherAccount
          data={selectedId}
          onCancel={() => setSelectedId(undefined)}
          stateError={stateErrorMessage}
          stateLoading={stateLoading}
          onConfirm={handleSubmit}
        />
      ),
    [handleSubmit, selectedId, stateErrorMessage, stateLoading],
  )

  const handleRenderFinishModal = useMemo(
    () =>
      finishedModal && (
        <GiftShopTemplateModalOtherAccountFinish onClickOk={handleFinish} />
      ),
    [finishedModal, handleFinish],
  )

  const handleRenderPageByStatus = useMemo(() => {
    switch (similarStatus) {
      case 'WAITING':
        return handleRenderContent
      case 'WAITING_MULTI':
        return handleRenderMultiAccountContent
      case 'WAITING_REMOVAL':
        return handleRenderSelfSelect
      default:
        return <></>
    }
  }, [
    handleRenderContent,
    handleRenderMultiAccountContent,
    handleRenderSelfSelect,
    similarStatus,
  ])

  const handleGetSimilarStatus = useCallback(() => {
    if (id) {
      requestData('giftshop_similar_get_status', {
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            setSimilarStatus(data.result.status)
          }
        },
      })
    }
  }, [id])

  useDidMount(() => {
    handleGetSimilarStatus()
    handleLoadData()
  })

  return (
    <>
      <ModalLoading visible={selfLoading} />
      {handleRenderPageByStatus}
      {handleRenderVerifModal}
      {handleRenderFinishModal}
    </>
  )
}
