import {
  API_PAYMENT_CHECKOUT_CHECK_DOUBLE,
  API_PAYMENT_CHECKOUT_TOKEN,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamPayment} from './ServiceParamPayment'

export const SERVICE_MAP_PAYMENT: ServiceMap<ServiceParamPayment> = {
  checkout_token: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_CHECKOUT_TOKEN,
  },
  checkout_check_double: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_CHECKOUT_CHECK_DOUBLE,
  },
}
