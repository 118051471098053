import {
  AUTH_NICKNAME_MIN_LENGTH,
  AUTH_PASSWORD_MAX_LENGTH,
  AUTH_PASSWORD_MIN_LENGTH,
  AUTH_PHONE_MIN_LENGTH,
  AUTH_PHONE_MIN_LENGTH_OLD,
  AUTH_USERNAME_MIN_LENGTH,
  REGEX_EMAIL_STRICT,
  REGEX_LEADING_ZERO,
  REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY,
  REGEX_NO_DASH_UNDERSCORE_PERIOD,
  REGEX_NO_SPACE,
  REGEX_ONE_LETTER,
  REGEX_PASSWORD,
  REGEX_PASSWORD_MIX,
  REGEX_PHONE_NUMBER,
  REGEX_START_END_ALPHANUMERIC,
  REGEX_START_WITH_CHAR,
  REGEX_USERNAME,
  REGEX_USERNAME_CONTAINS_FOTOYU,
  REGEX_USERNAME_SIMILAR,
} from 'consts'
import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {getValidation} from '../Validation'

export const VALIDATION_AUTH_LOGIN_ACCOUNT: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    email: (text) =>
      text.includes('@') && !text.match(REGEX_EMAIL_STRICT)
        ? translate('auth:validationEmail')
        : true,
    phone: (text) =>
      text.match(REGEX_PHONE_NUMBER) && text.length < AUTH_PHONE_MIN_LENGTH_OLD
        ? translate('auth:validationPhoneMinLengthWithoutLeadingZero', {
            count: AUTH_PHONE_MIN_LENGTH_OLD,
          })
        : true,
    username: (text) =>
      text.length < AUTH_USERNAME_MIN_LENGTH
        ? translate('auth:validationUsernameMinLength', {
            count: AUTH_USERNAME_MIN_LENGTH,
          })
        : true,
  },
}

export const VALIDATION_AUTH_LOGIN_PASSWORD: InputFormRules = {
  required: true,
}

export const VALIDATION_AUTH_PASSWORD: InputFormRules = {
  ...VALIDATION_AUTH_LOGIN_PASSWORD,
  minLength: AUTH_PASSWORD_MIN_LENGTH,
  messages: {
    required: getValidation('auth:validationRequired'),
    minLength: getValidation('auth:validationPasswordMinLength', {
      count: AUTH_PASSWORD_MIN_LENGTH,
    }),
  },
  validate: {
    rule: (text) =>
      !REGEX_PASSWORD.test(text) ? translate('auth:validationPassword') : true,
  },
}

export const VALIDATION_AUTH_PHONE: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    rule: (text) =>
      !REGEX_PHONE_NUMBER.test(text) ? translate('auth:validationPhone') : true,
    minLength: (text) =>
      text.replace(REGEX_LEADING_ZERO, '').length < AUTH_PHONE_MIN_LENGTH
        ? translate('auth:validationPhoneMinLength', {
            count: AUTH_PHONE_MIN_LENGTH,
          })
        : true,
  },
}

export const VALIDATION_AUTH_PHONE_SIMILAR: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    rule: (text) =>
      !REGEX_PHONE_NUMBER.test(text) ? translate('auth:validationPhone') : true,
    minLength: (text) =>
      text.length < AUTH_PHONE_MIN_LENGTH_OLD
        ? translate('auth:validationPhoneMinLengthWithoutLeadingZero', {
            count: AUTH_PHONE_MIN_LENGTH_OLD,
          })
        : true,
  },
}

export const VALIDATION_AUTH_EMAIL: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    rule: (text) =>
      !text.match(REGEX_EMAIL_STRICT)
        ? translate('auth:validationEmail')
        : true,
  },
}

export const VALIDATION_AUTH_USERNAME: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    minLength: (text) =>
      text.length < AUTH_USERNAME_MIN_LENGTH
        ? translate('auth:validationUsernameLength', {
            count: AUTH_USERNAME_MIN_LENGTH,
          })
        : true,
    startEndAlphanumeric: (text) =>
      !text.match(REGEX_START_END_ALPHANUMERIC)
        ? translate('auth:validationUsernameStartEndAlphanumeric')
        : true,
    validUsername: (text) =>
      !text.match(REGEX_USERNAME) ? translate('auth:validationUsername') : true,
    noSpace: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationUsernameStartEndNoSpecial')
        : true,
    oneDot: (text) =>
      text.split('.').length > 2 ||
      text.split('-').length > 2 ||
      text.split('_').length > 2
        ? translate('auth:validationUsernameSinglePeriod')
        : true,
    startWithAlphabet: (text) =>
      !text.match(REGEX_START_WITH_CHAR)
        ? translate('auth:validationUsernameStartWithLetter')
        : true,
    noFotoyu: (text) =>
      text.toLowerCase().match(REGEX_USERNAME_CONTAINS_FOTOYU)
        ? translate('auth:validationUsernameContainsFotoyu')
        : true,
  },
}

export const VALIDATION_AUTH_USERNAME_SIMILAR: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    minLength: (text) =>
      text.length < AUTH_USERNAME_MIN_LENGTH
        ? translate('auth:validationUsernameLength', {
            count: AUTH_USERNAME_MIN_LENGTH,
          })
        : true,
    startEndAlphanumeric: (text) =>
      !text.match(REGEX_START_END_ALPHANUMERIC)
        ? translate('auth:validationUsernameStartEndAlphanumeric')
        : true,
    validUsername: (text) =>
      !text.match(REGEX_USERNAME_SIMILAR)
        ? translate('auth:validationUsername')
        : true,
    noSpace: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationUsernameStartEndNoSpecial')
        : true,
    oneDot: (text) =>
      text.split('.').length > 2 ||
      text.split('-').length > 2 ||
      text.split('_').length > 2
        ? translate('auth:validationUsernameSinglePeriod')
        : true,
    startWithAlphabet: (text) =>
      !text.match(REGEX_START_WITH_CHAR)
        ? translate('auth:validationUsernameStartWithLetter')
        : true,
    noFotoyu: (text) =>
      text.toLowerCase().match(REGEX_USERNAME_CONTAINS_FOTOYU)
        ? translate('auth:validationUsernameContainsFotoyu')
        : true,
  },
}

export const VALIDATION_AUTH_NICKNAME = (username: string): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    minLength: (text) =>
      text.length < AUTH_NICKNAME_MIN_LENGTH
        ? translate('auth:validationNicknameMinLength', {
            count: AUTH_NICKNAME_MIN_LENGTH,
          })
        : true,
    nicknameContainSpaces: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationNicknameNoSpace')
        : true,
    nicknameContainOneLetter: (text) =>
      !text.match(REGEX_ONE_LETTER)
        ? translate('auth:validationNicknameOneLetterRequired')
        : true,
    nicknameStartEndAlphanumeric: (text) =>
      !text.match(REGEX_START_END_ALPHANUMERIC)
        ? translate('auth:validationNicknameStartEndAlphanumeric')
        : true,
    nicknameStartWithAlphabet: (text) =>
      !text.match(REGEX_START_WITH_CHAR)
        ? translate('auth:validationNicknameStartCharacter')
        : true,
    nicknameMatchUsername: (text) =>
      username === text
        ? translate('auth:validationNicknameMatchUsername')
        : true,
    nicknameAllowedChar: (text) =>
      !text.match(REGEX_USERNAME)
        ? translate('auth:nicknameAllowedCharacters')
        : true,
    nicknameSingleDash: (text) =>
      text.split('-').length >= 3
        ? translate('auth:validationNicknameSingleDash')
        : true,
    nicknameSinglePeriod: (text) =>
      text.split('.').length >= 3
        ? translate('auth:validationNicknameSinglePeriod')
        : true,
    nicknameSingleUndescore: (text) =>
      text.split('_').length >= 3
        ? translate('auth:validationNicknameSingleUnderscore')
        : true,
  },
})

export const VALIDATION_CONFIRMATION_PASSWORD = (
  password: string,
): InputFormRules => ({
  validate: {
    passwordMatchConfirmPassword: (text) =>
      password !== text ? translate('auth:confirmationPassword') : true,
  },
})

export const VALIDATION_CREATE_PASSWORD: InputFormRules = {
  required: true,
  maxLength: AUTH_PASSWORD_MAX_LENGTH,
  messages: {
    required: getValidation('auth:validationRequired'),
    maxLength: getValidation('auth:validationPasswordMaxLength', {
      count: AUTH_PASSWORD_MAX_LENGTH,
    }),
  },
  validate: {
    minLength: (text) =>
      text.length < AUTH_PASSWORD_MIN_LENGTH
        ? translate('auth:validationPasswordMinLength', {
            minLength: AUTH_PASSWORD_MIN_LENGTH,
          })
        : true,
    notContainDashUnderscorePeriod: (text) =>
      !text.match(REGEX_NO_DASH_UNDERSCORE_PERIOD)
        ? translate('auth:validationPasswordNoDashUnderscorePeriod')
        : true,
    noSpace: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationPasswordNoSpace')
        : true,
    mustUseRomanLetter: (text) =>
      !text.match(REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY)
        ? translate('auth:validationPasswordNoNonRomanCharacters')
        : true,
    notMixed: (text) =>
      !text.match(REGEX_PASSWORD_MIX)
        ? translate('auth:validationPasswordMix')
        : true,
  },
}

export const VALIDATION_NEW_PASSWORD: InputFormRules = {
  required: true,
  minLength: AUTH_PASSWORD_MIN_LENGTH,
  maxLength: AUTH_PASSWORD_MAX_LENGTH,
  messages: {
    required: getValidation('auth:validationRequired'),
    minLength: getValidation('auth:validationPasswordMinLength', {
      count: AUTH_PASSWORD_MIN_LENGTH,
    }),
    maxLength: getValidation('auth:validationPasswordMaxLength', {
      count: AUTH_PASSWORD_MAX_LENGTH,
    }),
  },
  validate: {
    notContainDashUnderscorePeriod: (text) =>
      !text.match(REGEX_NO_DASH_UNDERSCORE_PERIOD)
        ? translate('auth:validationPasswordNoDashUnderscorePeriod')
        : true,
    noSpace: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationPasswordNoSpace')
        : true,
    mustUseRomanLetter: (text) =>
      !text.match(REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY)
        ? translate('auth:validationPasswordNoNonRomanCharacters')
        : true,
    rule: (text) =>
      !text.match(REGEX_PASSWORD)
        ? translate('auth:validationPasswordError')
        : true,
  },
}

export const VALIDATION_AUTH_REQUIRED: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
}

export const VALIDATION_AUTH_UPDATE_PHONE = (
  credential: string,
  dialCode: string,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    ...VALIDATION_AUTH_PHONE.validate,
    notSame: (text) =>
      text === credential.split('-')[1] && dialCode === credential.split('-')[0]
        ? translate('auth:validationPhoneHasBeenTaken')
        : true,
  },
})

export const VALIDATION_AUTH_UPDATE_EMAIL = (
  credential: string,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    ...VALIDATION_AUTH_EMAIL.validate,
    notSame: (text) =>
      text === credential
        ? translate('auth:validationEmailHasBeenTaken')
        : true,
  },
})
