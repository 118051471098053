import React, {CSSProperties, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AVATAR_SIZE_CHAT} from 'consts'
import {getThemeColor} from 'utils'
import {
  Avatar,
  CheckboxItem,
  ConfirmationModal,
  Icon,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeHostTemplateListHostMemberProps} from './TreeHostTemplateListHostMemberProps'

const StyledItemContainer = styled.div<CSSProperties>`
  display: flex;
  flex: 1;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  cursor: ${({cursor}) => cursor};
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const StyledOverlay = styled.div`
  position: absolute;
  width: ${convertUnit(AVATAR_SIZE_CHAT)};
  aspect-ratio: 1;
  background-color: ${({theme}) => getThemeColor(theme, 'white_1', 0.5)};
`

const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1;
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
  border-radius: 50%;
`

const StyledIconContainer = styled.div`
  cursor: pointer;
  z-index: 3;
  height: fit-content;
`

export default function TreeHostTemplateListHostMember({
  data,
  rightComponentType = 'remove',
  onRightComponentPress,
  onCancelInvite,
  isSelected = false,
}: TreeHostTemplateListHostMemberProps) {
  const {translate} = useTranslation()
  const [modal, setModal] = useState(false)

  const handleRenderRightComponent = useMemo(() => {
    switch (rightComponentType) {
      case 'select':
        return (
          <StyledCheckboxItem
            id={data.account_id}
            color="primary_5"
            isSelected={isSelected}
            size={16}
          />
        )
      case 'remove':
        return (
          <StyledIconContainer>
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={onRightComponentPress}
            />
          </StyledIconContainer>
        )
      case 'cancel':
        return (
          <StyledIconContainer>
            <Icon
              type="trash"
              size={convertUnit(16)}
              color="danger_5"
              onClick={() => setModal((prev) => !prev)}
            />
          </StyledIconContainer>
        )
    }
  }, [data.account_id, isSelected, onRightComponentPress, rightComponentType])

  const handleRenderConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modal}
        title={translate('tree:hostCancelInvitationTitle')}
        text={translate('tree:hostCancelInvitationDescription')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:confirm')}
        toggleModal={() => setModal((prev) => !prev)}
        onConfirm={() =>
          onCancelInvite ? onCancelInvite() : setModal((prev) => !prev)
        }
      />
    ),
    [modal, onCancelInvite, translate],
  )

  return (
    <StyledItemContainer
      cursor={rightComponentType === 'select' ? 'pointer' : 'default'}
      onClick={() =>
        rightComponentType === 'select' &&
        onRightComponentPress &&
        onRightComponentPress()
      }>
      {handleRenderConfirmationModal}
      <StyledRowContainer>
        {data.status !== 'accepted' && rightComponentType !== 'select' && (
          <StyledOverlay />
        )}
        <StyledAvatar
          containerStyle={{marginRight: 15}}
          src={data.photo}
          alt="Avatar"
          size={AVATAR_SIZE_CHAT}
        />
        <Paragraph
          fontSize="m"
          fontWeight="bold"
          color={
            data.status !== 'accepted' && rightComponentType !== 'select'
              ? 'gray_3'
              : 'black'
          }>
          {data.username}
        </Paragraph>
      </StyledRowContainer>
      {handleRenderRightComponent}
    </StyledItemContainer>
  )
}
