import React, {useCallback, useMemo} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import {ListPaginationModeType, WindowModeType} from 'types'
import {
  LIST_MASONRY_PAGINATION_BUTTON_GAP,
  LIST_MASONRY_PAGINATION_BUTTON_GAP_MOBILE,
  LIST_MASONRY_PAGINATION_LOAD_LIMIT,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {
  Icon,
  ListMasonryPaginationButtonControls,
  ListMasonryPaginationButtonPage,
  ListMasonryPaginationGapMarker,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useWindowMode} from 'windows'
import {GiftShopTemplatePaginationProps} from './GiftShopTemplatePaginationProps'

interface StyledMasonryContainerProps {
  mode?: WindowModeType
}

const StyledMasonryContainer = styled.div<StyledMasonryContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`

const StyledPaginationContainer = styled.div<StyledMasonryContainerProps>`
  ${({mode}) => ({
    placeSelf: mode === 'website' ? 'flex-end' : undefined,
  })}
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP)};
  padding: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP)};
  place-self: flex-end;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    place-self: center;
    gap: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP_MOBILE)};
    order: 1;
  }
`

export default function GiftShopTemplatePagination({
  page,
  dataCount = 0,
  setPage,
  movePage,
  renderMain,
}: GiftShopTemplatePaginationProps) {
  const mode = useWindowMode()

  const pageCount = useMemo(
    () => Math.ceil(dataCount / LIST_MASONRY_PAGINATION_LOAD_LIMIT),
    [dataCount],
  )

  const paginationCalcs = useMemo(() => {
    const compactMaxPages = mode !== 'mobile' ? 9 : 7
    const pageDiff = mode !== 'mobile' ? 2 : 1
    const middleMinThreshold = compactMaxPages - (pageDiff + 1)
    const middleMaxThreshold = pageCount - (compactMaxPages - 4)
    const paginationMode: ListPaginationModeType = (() => {
      if (pageCount <= compactMaxPages) return 'compact'
      if (page < middleMinThreshold) return 'start'
      if (middleMaxThreshold - (mode !== 'mobile' ? 0 : 1) >= page)
        return 'middle'

      return 'end'
    })()

    return {
      compactMaxPages,
      pageDiff,
      middleMinThreshold,
      middleMaxThreshold,
      paginationMode,
    }
  }, [mode, page, pageCount])

  const {
    compactMaxPages,
    middleMaxThreshold,
    pageDiff,
    paginationMode,
  } = paginationCalcs

  const safePage = useCallback(
    (p: number) => {
      let value = p

      if (value < 1) value = 1

      if (value > pageCount) value = pageCount

      return value
    },
    [pageCount],
  )

  const handleSafeSetPage = useCallback(
    (newPage: number) => {
      setPage(safePage(newPage))
    },
    [safePage, setPage],
  )

  const handleSafeMovePage = useCallback(
    (difference: number) => {
      const newDifference = safePage(page + difference) - page
      movePage(newDifference)
    },
    [movePage, page, safePage],
  )

  const handleRenderPaginationPageButtons = useMemo(() => {
    switch (paginationMode) {
      case 'compact':
        return Array.from({length: pageCount}).map((_, idx) => (
          <ListMasonryPaginationButtonPage
            page={page}
            setPage={handleSafeSetPage}
            destination={idx + 1}
          />
        ))
      case 'start':
        return (
          <>
            {Array.from({length: compactMaxPages - 2}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                page={page}
                setPage={handleSafeSetPage}
                destination={idx + 1}
              />
            ))}
            <ListMasonryPaginationGapMarker />
            <ListMasonryPaginationButtonPage
              page={page}
              setPage={handleSafeSetPage}
              destination={pageCount}
            />
          </>
        )
      case 'end':
        return (
          <>
            <ListMasonryPaginationButtonPage
              page={page}
              setPage={handleSafeSetPage}
              destination={1}
            />
            <ListMasonryPaginationGapMarker />
            {Array.from({length: compactMaxPages - 2}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                page={page}
                setPage={handleSafeSetPage}
                destination={idx + middleMaxThreshold - 1}
              />
            ))}
          </>
        )
      case 'middle':
        return (
          <>
            <ListMasonryPaginationButtonPage
              page={page}
              setPage={handleSafeSetPage}
              destination={1}
            />
            <ListMasonryPaginationGapMarker />
            {Array.from({length: compactMaxPages - 4}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                page={page}
                setPage={handleSafeSetPage}
                destination={idx + (page - pageDiff)}
              />
            ))}
            <ListMasonryPaginationGapMarker />
            <ListMasonryPaginationButtonPage
              page={page}
              setPage={handleSafeSetPage}
              destination={pageCount}
            />
          </>
        )
    }
  }, [
    paginationMode,
    pageCount,
    compactMaxPages,
    page,
    handleSafeSetPage,
    middleMaxThreshold,
    pageDiff,
  ])

  const handleRenderPaginationMenu = useMemo(
    () =>
      dataCount > 0 && (
        <StyledPaginationContainer mode={mode}>
          <ListMasonryPaginationButtonControls
            onClick={() => handleSafeMovePage(-5)}>
            <Icon type="double-arrow-left" />
          </ListMasonryPaginationButtonControls>
          <ListMasonryPaginationButtonControls
            onClick={() => handleSafeMovePage(-1)}>
            <Icon type="chevron-left" />
          </ListMasonryPaginationButtonControls>
          {handleRenderPaginationPageButtons}
          <ListMasonryPaginationButtonControls
            onClick={() => handleSafeMovePage(1)}>
            <Icon type="chevron-right" />
          </ListMasonryPaginationButtonControls>
          <ListMasonryPaginationButtonControls
            onClick={() => handleSafeMovePage(5)}>
            <Icon type="double-arrow-right" />
          </ListMasonryPaginationButtonControls>
        </StyledPaginationContainer>
      ),
    [dataCount, handleRenderPaginationPageButtons, handleSafeMovePage, mode],
  )

  const handleRenderMasonry = useCallback(
    (size: Size) => (
      <StyledMasonryContainer mode={mode}>
        {handleRenderPaginationMenu}
        {renderMain(size)}
      </StyledMasonryContainer>
    ),
    [mode, handleRenderPaginationMenu, renderMain],
  )

  return (
    <AutoSizer style={{display: 'block', width: '100%', height: '100%'}}>
      {handleRenderMasonry}
    </AutoSizer>
  )
}
