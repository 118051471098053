import {lazy} from 'react'

const TreeHostManageCreatorScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManageCreator' */ './TreeHostManageCreatorScreen'
    ),
)

const TreeHostManageCreatorPromoteScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'PromoteCreator' */ './TreeHostManageCreatorPromoteScreen'
    ),
)

export * from './TreeHostManageCreatorPromoteScreenParam'
export {TreeHostManageCreatorScreen, TreeHostManageCreatorPromoteScreen}
