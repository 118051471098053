import {lazy} from 'react'

const AuthResetPasswordScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthResetPassword' */ './AuthResetPasswordScreen'
    ),
)

export {AuthResetPasswordScreen}
