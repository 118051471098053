import React, {useCallback, useMemo, useState, useRef} from 'react'
import {useTranslation} from 'i18n'
import {ForkygramTemplatePostContent} from 'pages'
import {requestData} from 'services'
import {
  ForkygramPostActionOptions,
  ForkygramPostActionOptionsFeed,
  ForkygramReportCategoriesResponse,
} from 'types'
import {
  showModalLimitedAccess,
  showModalReprocessKyc,
  showSnackbar,
  useDidMount,
  useHistory,
} from 'utils'
import {ConfirmationModal, InputPicker} from 'common/components'
import {useSelector} from 'lib/redux'
import {GiftShopFeedContentProps} from './GiftShopFeedContentProps'
import {ValidateKYCModal} from '../ValidateKYCModal'
import {
  GiftShopTemplateOptionsModal,
  GiftShopTemplateReportModal,
  GiftShopTemplateShareModal,
} from '../../../template'
import {TemplateProfileSelfOptionModal} from '../../../../template'

export default function GiftShopFeedContent({
  data,
  index,
  visibilityIndex,
  stateMute,
  stateVideoPause,
  setVideoPlaying,
  onBlocked,
  onReported,
  onEdited,
  onDeleted,
  onFollow,
}: GiftShopFeedContentProps) {
  const {translate} = useTranslation()
  const pause = stateVideoPause[0]
  const [modal, setModal] = useState<ForkygramPostActionOptionsFeed>('none')
  const [selfModal, setSelfModal] = useState<ForkygramPostActionOptions>('none')
  const {access_token, id} = useSelector('user') || {}
  const {status: childStatus} = useSelector('parentalConsentState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {kycStatus} = useSelector('yuserActivationState')
  const history = useHistory()
  const ref = useRef<HTMLInputElement>(null)
  const stateFollowButton = useState(true)

  const handleToggleModal = useCallback(() => setModal('none'), [])

  const handleBlockAccount = useCallback(() => {
    access_token &&
      requestData('forkygram_post_block_account', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          blocked_id: data.member_id,
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            onBlocked(data.member_id)
            showSnackbar(translate('forkygram:blockYuserAccSuccess'))
          }
        },
      })
  }, [access_token, data.member_id, onBlocked, translate])

  const handleReportPost = useCallback(
    (category: ForkygramReportCategoriesResponse, description?: string) => {
      requestData('forkygram_post_report_posts', {
        data: {
          category_id: category.id,
          description,
          reported_id: data.id,
          reported_member_id: data.member_id,
        },
        onRequestReceived: () => handleToggleModal(),
        onRequestFailed: () =>
          showSnackbar(translate('forkygram:reportPostFailed')),
        onRequestSuccess: ({status}) => {
          onReported(data.id)
          showSnackbar(
            translate(
              status === 200
                ? 'forkygram:reportPostSuccess'
                : 'forkygram:reportPostFailed',
            ),
          )
        },
      })
    },
    [data.id, data.member_id, handleToggleModal, onReported, translate],
  )

  const handleDeletePost = useCallback(() => {
    requestData('forkygram_delete_post', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {id: data.id},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('giftShop:removeContentsSnackbarSuccess'))
          onDeleted(data.id)
        }
      },
    })
  }, [data.id, onDeleted, translate])

  const handleClickMore = useCallback(() => {
    if (id === data.member_id) setSelfModal('more')
    else setModal('options')
  }, [data.member_id, id])

  const handleFeatureDisabled = useCallback(() => {
    handleToggleModal()
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, handleToggleModal, history, kycStatus, similarStatus])

  const handleFollow = useCallback(() => {
    if (
      childStatus === 'UNDERAGE' ||
      !(kycStatus === 'verified' || kycStatus === 'verified_check')
    ) {
      stateFollowButton[1](true)
      handleFeatureDisabled()
      return
    }
    requestData('giftshop_post_follow', {
      data: {member_id: data.member_id},
      useDefaultMessage: true,
      actionType: 'execute',
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          onFollow()
        } else {
          stateFollowButton[1](true)
          showSnackbar(translate('global:messageError', {context: status}))
        }
      },
    })
  }, [
    childStatus,
    data.member_id,
    handleFeatureDisabled,
    kycStatus,
    onFollow,
    stateFollowButton,
    translate,
  ])

  const handleDelete = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    if (!data.can_delete) {
      showSnackbar(translate('giftShop:removeContentsSnackbarFailed_autopost'))
      return
    }
    setModal('delete')
  }, [data.can_delete, handleFeatureDisabled, kycStatus, translate])

  const handleRenderOptionsModal = useMemo(
    () => (
      <GiftShopTemplateOptionsModal
        onShare={() =>
          childStatus === 'UNDERAGE' ||
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
            ? handleFeatureDisabled()
            : setModal('share')
        }
        onBlock={() =>
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
            ? handleFeatureDisabled()
            : setModal('block')
        }
        onDelete={data.can_delete !== undefined ? handleDelete : undefined}
        onReport={() =>
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
            ? handleFeatureDisabled()
            : setModal('report')
        }
        toggleModal={handleToggleModal}
      />
    ),
    [
      childStatus,
      data.can_delete,
      handleDelete,
      handleFeatureDisabled,
      handleToggleModal,
      kycStatus,
    ],
  )

  const handleRenderShareModal = useMemo(
    () => (
      <GiftShopTemplateShareModal
        type="post"
        targetId={data.id}
        subTargetId={data.username}
        caption={data.caption}
        toggleModal={() =>
          id === data.member_id ? setSelfModal('none') : setModal('none')
        }
      />
    ),
    [data.caption, data.id, data.member_id, data.username, id],
  )

  const handleRenderReportModal = useMemo(
    () => (
      <GiftShopTemplateReportModal
        type="post"
        onSubmit={handleReportPost}
        toggleModal={() => {
          handleToggleModal()
          showSnackbar(translate('forkygram:reportCancel'))
        }}
      />
    ),
    [handleReportPost, handleToggleModal, translate],
  )

  const handleRenderBlockYuserModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        text={translate('forkygram:blockYuserAccText')}
        title={translate('forkygram:blockYuserAcc')}
        cancelText={translate('global:cancel')}
        onConfirm={handleBlockAccount}
        toggleModal={handleToggleModal}
      />
    ),
    [handleBlockAccount, handleToggleModal, translate],
  )

  const handleRenderValidateKYCModal = useMemo(
    () => <ValidateKYCModal visible toggleModal={handleToggleModal} />,
    [handleToggleModal],
  )

  const handleRenderDeleteModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('forkygram:deletePostTitle')}
        text={translate('forkygram:deletePostMessage')}
        onConfirm={handleDeletePost}
        toggleModal={() =>
          id === data.member_id ? setSelfModal('none') : setModal('none')
        }
        cancelText={translate('global:cancel')}
        confirmText={translate('global:delete')}
      />
    ),
    [data.member_id, handleDeletePost, id, translate],
  )

  const handleRenderMoreModal = useMemo(
    () => (
      <TemplateProfileSelfOptionModal
        visible
        toggleModal={() => setSelfModal('none')}
        onClickDelete={() => setSelfModal('delete')}
        onClickShare={() => {
          if (childStatus === 'UNDERAGE') {
            setSelfModal('none')
            showModalLimitedAccess()
            return
          }
          setSelfModal('share')
        }}
        onClickEdit={onEdited}
      />
    ),
    [childStatus, onEdited],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'block':
        return handleRenderBlockYuserModal
      case 'options':
        return handleRenderOptionsModal
      case 'report':
        return handleRenderReportModal
      case 'kyc':
        return handleRenderValidateKYCModal
      case 'delete':
        return handleRenderDeleteModal
      case 'share':
        return handleRenderShareModal
      case 'none':
        return null
    }
  }, [
    handleRenderBlockYuserModal,
    handleRenderDeleteModal,
    handleRenderOptionsModal,
    handleRenderReportModal,
    handleRenderShareModal,
    handleRenderValidateKYCModal,
    modal,
  ])

  const handleRenderSelfModal = useMemo(() => {
    switch (selfModal) {
      case 'more':
        return handleRenderMoreModal
      case 'delete':
        return handleRenderDeleteModal
      case 'share':
        return handleRenderShareModal
      case 'none':
        return null
    }
  }, [
    handleRenderDeleteModal,
    handleRenderMoreModal,
    handleRenderShareModal,
    selfModal,
  ])

  const handleRenderItem = useMemo(
    () => (
      <ForkygramTemplatePostContent
        isFeed
        isLive={data.is_live || false}
        active={visibilityIndex === index && !pause}
        focused={visibilityIndex === index}
        data={data}
        stateVideoPause={stateVideoPause}
        stateMute={stateMute}
        setVideoPlaying={setVideoPlaying}
        onClickMore={handleClickMore}
        onFollow={handleFollow}
        stateFollowButton={stateFollowButton}
      />
    ),
    [
      data,
      handleClickMore,
      handleFollow,
      index,
      pause,
      setVideoPlaying,
      stateFollowButton,
      stateMute,
      stateVideoPause,
      visibilityIndex,
    ],
  )

  const handleRenderInput = useMemo(
    () => (
      <InputPicker
        multiple
        inputRef={ref}
        onLoadFiles={(files) =>
          files.length > 0 && history.push('giftshop_send_to_face', {files})
        }
      />
    ),
    [history],
  )

  useDidMount(() => {
    const {content_type} = data
    if (setVideoPlaying && index === 0) {
      setVideoPlaying(content_type === 'video')
    }
  })

  return (
    <>
      {handleRenderModal}
      {handleRenderSelfModal}
      {handleRenderItem}
      {handleRenderInput}
    </>
  )
}
