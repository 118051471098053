import {ImageCropperShape, ImageCropperSize} from 'types'

export const IMAGE_CROPPER_SIZE: Record<
  ImageCropperShape,
  ImageCropperSize[]
> = {
  landscape: [
    {mode: 'mobile', width: 275, height: 100},
    {mode: 'tablet', width: 400, height: 120},
    {mode: 'website', width: 400, height: 200},
  ],
  portrait: [
    {mode: 'mobile', width: 210, height: 280},
    {mode: 'tablet', width: 300, height: 400},
    {mode: 'website', width: 300, height: 400},
  ],
  square: [
    {mode: 'mobile', width: 275, height: 275},
    {mode: 'tablet', width: 400, height: 400},
    {mode: 'website', width: 400, height: 400},
  ],
}
