import React from 'react'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_SINGLE_LIMIT} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {DragDrop} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadDropzoneSingleProps} from './GiftShopUploadDropzoneProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
`

export default function GiftShopUploadDropzoneSingle({
  onUploadSingle,
  validator,
  onClickSingle,
}: GiftShopUploadDropzoneSingleProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer onClick={onClickSingle}>
      <DragDrop
        icon="image"
        header={translate('giftShop:uploadModeSingleHeader')}
        description={translate('giftShop:uploadModeSingleDescription', {
          max: GIFTSHOP_UPLOAD_SINGLE_LIMIT,
        })}
        onDrop={(files) => onUploadSingle(files)}
        validator={validator}
      />
    </StyledContainer>
  )
}
