import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useGiftshopUploadCompressResendSetting} from 'utils'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileUploadModalProps} from './TemplateProfileUploadModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
`

const StyledSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${convertUnit(8)};
`

const StyledIconContainer = styled.div`
  margin-right: ${convertUnit(16)};
`

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  cursor: pointer;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(3)};
`

const StyledCompressContainer = styled(StyledRowContainer)`
  display: flex;
  margin-top: ${convertUnit(8)};
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TemplateProfileUploadModal({
  visible,
  onPost,
  onSell,
  toggleModal,
}: TemplateProfileUploadModalProps) {
  const {translate} = useTranslation()
  const {
    compressEnabled,
    resendEnabled,
  } = useGiftshopUploadCompressResendSetting()

  const handleRenderPhotoCompress = useMemo(
    () => (
      <>
        <Paragraph fontSize="xs" color="primary_5">
          {translate('giftShop:photoCompress')}
        </Paragraph>
        <Paragraph fontSize="xs" fontWeight="bold" color="primary_5">
          {translate(
            compressEnabled ? 'global:on' : 'global:off',
          ).toUpperCase()}
        </Paragraph>
      </>
    ),
    [compressEnabled, translate],
  )

  const handleRenderPhotoResend = useMemo(
    () => (
      <>
        <Paragraph fontSize="xs" color="primary_5">
          {translate('giftShop:photoResend')}
        </Paragraph>
        <Paragraph fontSize="xs" fontWeight="bold" color="primary_5">
          {translate(resendEnabled ? 'global:on' : 'global:off').toUpperCase()}
        </Paragraph>
      </>
    ),
    [resendEnabled, translate],
  )

  const handleRenderVideoResend = useMemo(
    () => (
      <>
        <Paragraph fontSize="xs" color="primary_5">
          {translate('giftShop:videoResend')}
        </Paragraph>
        <Paragraph fontSize="xs" fontWeight="bold" color="primary_5">
          {translate('global:on').toUpperCase()}
        </Paragraph>
      </>
    ),
    [translate],
  )

  const handleRenderCompressResend = useMemo(
    () => (
      <StyledRowContainer>
        <StyledCompressContainer>
          {handleRenderPhotoCompress}
          <Paragraph fontSize="s" fontWeight="bold" color="primary_5">
            {'•'}
          </Paragraph>
          {handleRenderPhotoResend}
          <Paragraph fontSize="s" fontWeight="bold" color="primary_5">
            {'•'}
          </Paragraph>
          {handleRenderVideoResend}
        </StyledCompressContainer>
      </StyledRowContainer>
    ),
    [
      handleRenderPhotoCompress,
      handleRenderPhotoResend,
      handleRenderVideoResend,
    ],
  )

  const handleRenderSell = useMemo(
    () => (
      <StyledItemContainer onClick={onSell}>
        <StyledSubtitleContainer>
          <StyledIconContainer>
            <Icon type="robopet" size={convertUnit(24)} />
          </StyledIconContainer>
          <Paragraph fontSize="m" fontWeight="bold">
            {translate('giftShop:sell')}
          </Paragraph>
        </StyledSubtitleContainer>
        <Paragraph fontSize="s" color="gray_5">
          {translate('giftShop:uploadModalSellDescription')}
        </Paragraph>
        {handleRenderCompressResend}
      </StyledItemContainer>
    ),
    [handleRenderCompressResend, onSell, translate],
  )

  const handleRenderPost = useMemo(
    () => (
      <StyledItemContainer onClick={onPost}>
        <StyledSubtitleContainer>
          <StyledIconContainer>
            <Icon type="home-outline" size={convertUnit(24)} />
          </StyledIconContainer>
          <Paragraph fontSize="m" fontWeight="bold">
            {translate('forkygram:post')}
          </Paragraph>
        </StyledSubtitleContainer>
        <Paragraph fontSize="s" color="gray_5">
          {translate('giftShop:uploadModalPostDescription')}
        </Paragraph>
      </StyledItemContainer>
    ),
    [onPost, translate],
  )

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('giftShop:uploadModalTitle')}
      </StyledTitle>
      {handleRenderSell}
      {handleRenderPost}
      <StyledButton
        label={translate('global:close')}
        backgroundColor="white_3"
        color="primary_5"
        onClick={toggleModal}
      />
    </StyledModal>
  )
}
