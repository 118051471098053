import { ListMasonryEmptySpaceParam } from 'types'

export function isListMasonryHasEmptySpace<ItemT>({
  batch,
  index,
  maxItem,
  maxRatio,
}: ListMasonryEmptySpaceParam<ItemT>) {
  if (index < batch.length) {
    const row = batch[index]

    return (
      index === batch.length - 1 &&
      row.ratio < maxRatio &&
      row.items.length < maxItem
    )
  }

  return false
}
