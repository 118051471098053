import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeFotoTreeLeafFootprintTree} from 'types'
import {useHistory} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Paragraph, Slider} from 'common/components'
import {FORKYGRAM_FOOTPRINTS_MAX_ITEM} from 'consts'
import ForkygramTemplateListGridContent from './ForkygramTemplateListGridContent'
import {ForkygramFootprintListGridContentProps} from './ForkygramTemplateListGridContentProps'
import {ForkygramListGridItem} from '../Item'

interface StyledMoreContainerProps {
  width: number
}

const StyledSliderContainer = styled.div`
  display: flex;
  flex: 1;
`

const StyledMoreContainer = styled.div<StyledMoreContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${({width}) => ({
    minWidth: convertUnit(width),
    minHeight: convertUnit(width),
  })}
`

export default function ForkygramFootprintListGridContent({
  self,
  memberId,
}: ForkygramFootprintListGridContentProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const {width} = useWindowLayout()
  const history = useHistory()
  const {id: userId} = useSelector('user') || {}
  const column = mode === 'website' ? 5 : 4
  const [imageList, setImageList] = useState<TreeFotoTreeLeafFootprintTree[]>(
    [],
  )
  const [footprintCount, setFootprintCount] = useState(0)

  const handleClickItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => {
      history.push('forkygram_footprint_detail', {
        memberId,
        treeId: item.tree_id,
        treeName: item.tree_name,
        treeAlias: item.alias,
      })
    },
    [history, memberId],
  )

  const handleClickAll = useCallback(() => {
    history.push('forkygram_footprint_all', {
      memberId,
    })
  }, [history, memberId])

  const handleRenderMore = useMemo(
    () => (
      <StyledMoreContainer
        width={mode === 'website' ? (width - 97) / column : width / column}
        onClick={handleClickAll}>
        <Paragraph fontSize="m" fontWeight="bold" color="white_1">
          {footprintCount - FORKYGRAM_FOOTPRINTS_MAX_ITEM}+
        </Paragraph>
        <Paragraph color="white_1">{translate('global:seeMore')}</Paragraph>
      </StyledMoreContainer>
    ),
    [column, footprintCount, handleClickAll, mode, translate, width],
  )

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree, index: number) => {
      if (index === FORKYGRAM_FOOTPRINTS_MAX_ITEM) {
        return handleRenderMore
      }

      return index <= FORKYGRAM_FOOTPRINTS_MAX_ITEM ? (
        <ForkygramListGridItem
          borderColor="black"
          data={{
            contentType: item.post.content_type,
            url: item.url,
            caption: item.tree_name,
            thumbnail: item.url,
            isHidden: item.is_hidden,
          }}
          column={column}
          onClickItem={() => handleClickItem(item)}
        />
      ) : (
        <></>
      )
    },
    [column, handleClickItem, handleRenderMore],
  )

  const handleLoadData = useCallback(() => {
    if ((self && userId) || memberId) {
      requestData('tree_get_leaf_footprints', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {
          member_id: self && userId ? userId : memberId,
          limit: FORKYGRAM_FOOTPRINTS_MAX_ITEM,
        },
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setImageList(result.footprints)
            setFootprintCount(result.count)
          }
        },
      })
    }
  }, [memberId, self, userId])

  const handleRenderFootprintCount = useMemo(
    () => (
      <Paragraph color="gray_3" className="pressable">
        {translate('tree:fototreeFootprintsTreeCount', {count: footprintCount})}
      </Paragraph>
    ),
    [footprintCount, translate],
  )

  useEffect(() => handleLoadData(), [handleLoadData])

  return imageList.length ? (
    <ForkygramTemplateListGridContent
      header
      headerIcon="tree-feature"
      headerTitle={translate('tree:fototreeFootprints')}
      headerRightElement={handleRenderFootprintCount}
      backgroundColor="black"
      containerHeight="auto"
      onClickHeader={handleClickAll}
      color="white_1">
      <StyledSliderContainer>
        <Slider renderItem={handleRenderItem} data={imageList} />
      </StyledSliderContainer>
    </ForkygramTemplateListGridContent>
  ) : (
    <></>
  )
}
