import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'
import {LabelProps} from './LabelProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
  }
`

const StyledDanger = styled.small`
  color: ${({theme}) => theme.danger_5};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(5)};
`

const StyledTextContainer = styled.div`
  display: flex;
  flex: 1;
`

export default function Label({
  label,
  labelDescription,
  required,
  containerStyle,
  children,
  labelDescriptionColor,
  labelDescriptionDirection,
  labelDescriptionWeight,
}: LabelProps) {
  return (
    <StyledContainer style={containerStyle}>
      <StyledTextContainer>
        {label && (
          <StyledParagraph fontSize="m" fontWeight="medium">
            {label}
            {required && <StyledDanger>*</StyledDanger>}
          </StyledParagraph>
        )}
        {labelDescription && labelDescriptionDirection === 'row' ? (
          <Paragraph
            fontSize="s"
            fontWeight={labelDescriptionWeight || 'medium'}
            color={labelDescriptionColor || 'gray_3'}
            style={{marginLeft: 'auto'}}>
            {labelDescription}
          </Paragraph>
        ) : null}
      </StyledTextContainer>
      {labelDescription && labelDescriptionDirection === 'column' ? (
        <Paragraph
          fontSize="s"
          fontWeight={labelDescriptionWeight || 'medium'}
          color={labelDescriptionColor || 'gray_3'}
          style={{marginBottom: convertUnit(4)}}>
          {labelDescription}
        </Paragraph>
      ) : <></>}
      {children}
    </StyledContainer>
  )
}
