import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopExploreTabView,
  GiftShopRootEmpty,
  GiftShopTemplateMasonry,
} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {useHistory} from 'utils'

const StyledMasonryContainer = styled.div`
  position: relative;
  height: 100%;
  flex: '1 1 auto';
`

export default function GiftShopFavoriteContent() {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<GiftShopContentData>>([])
  const data = stateData[0]
  const favEmptyImage = IMAGE_ASSET('giftshop', 'empty.png')

  const handleLoadFavoriteData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('giftshop_get_favorite', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit},
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.map((item) => ({...item, is_favorite: true}))
        : []
    },
    [],
  )

  const handleClickFavoriteItem = useCallback(
    (item: GiftShopContentData) =>
      history.push('giftshop_favorite_detail', {
        data: [...data],
        selectedItemId: item.content_id,
      }),
    [data, history],
  )

  const handleTabView = useMemo(
    () => <GiftShopExploreTabView selectedTab="favorite" />,
    [],
  )

  const handleRenderFavoriteEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={favEmptyImage}
        imageAlt={favEmptyImage}
        title={translate('giftShop:favoriteEmptyTitle')}
        message={translate('giftShop:favoriteEmptySubtitle')}
      />
    ),
    [favEmptyImage, translate],
  )

  const handleRenderList = useMemo(
    () => (
      <GiftShopTemplateMasonry
        loadData={handleLoadFavoriteData}
        stateData={stateData}
        showResBadge
        listEmptyElement={handleRenderFavoriteEmpty}
        onClickItem={handleClickFavoriteItem}
      />
    ),
    [
      handleClickFavoriteItem,
      handleLoadFavoriteData,
      handleRenderFavoriteEmpty,
      stateData,
    ],
  )

  return (
    <>
      {handleTabView}
      <StyledMasonryContainer>{handleRenderList}</StyledMasonryContainer>
    </>
  )
}
