import React from 'react'
import {Source, Layer} from 'react-map-gl'
import {TREE_MAP_LAYER_ID_CREATOR_LIVE_CATWALK} from 'consts'
import {useTheme} from 'themes'
import {getCreatorPointsWithinRadius} from 'utils'
import {FototreeMapCreatorCatwalkProps} from './FototreeMapCreatorProps'

export default function FototreeMapCreatorCatwalk({
  data,
}: FototreeMapCreatorCatwalkProps) {
  const theme = useTheme()
  return (
    <Source
      id="map-catwalk-source"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: getCreatorPointsWithinRadius(data).map((point) => ({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: point,
          },
          properties: {
            title: 'CONTENT CATWALK',
          },
        })),
      }}>
      <Layer
        id={TREE_MAP_LAYER_ID_CREATOR_LIVE_CATWALK}
        type="symbol"
        layout={{
          'symbol-placement': 'line-center',
          'text-font': ['Ubuntu Bold'],
          'text-field': ['get', 'title'],
          'text-size': 12,
          'text-offset': [0, -2.5],
          'text-allow-overlap': true,
          'text-ignore-placement': true,
        }}
        paint={{
          'text-color': theme.primary_5,
          'text-halo-color': theme.white_5,
          'text-halo-width': 1,
        }}
      />
    </Source>
  )
}
