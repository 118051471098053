import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {requestData} from 'services'
import {ForkygramGetLikeResponse} from 'types'
import {Icon, ListLazy, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramLikeModalContentItem} from '../Item'
import {ForkygramLikeModalContentProps} from './ForkygramLikeModalContentProps'

const StyledModal = styled(Modal)`
  display: flex;
  width: ${convertUnit(480)};
  height: 90%;
  max-height: ${convertUnit(677)};
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
`

const StyledListContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
`

const StyledHeaderContainer = styled.div`
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  display: flex;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(20)};
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${convertUnit(20)};
  text-align: center;
`

const StyledEmptyMessage = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
`

export default function ForkygramLikeModalContent({
  visible,
  toggleModal,
  postId,
}: ForkygramLikeModalContentProps) {
  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('forkygram_get_like', {
        params: {post_id: postId, page, limit},
        useDefaultMessage: true,
        actionType: 'fetch',
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : null
    },
    [postId],
  )

  const handleKeyExtractor = useCallback(
    (item: ForkygramGetLikeResponse) => item.member.id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramGetLikeResponse) => (
      <ForkygramLikeModalContentItem data={item} toggleModal={toggleModal} />
    ),
    [toggleModal],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('forkygram:likes')}
        </Paragraph>
        <Icon type="close" onClick={toggleModal} />
      </StyledHeaderContainer>
    ),
    [toggleModal],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('forkygram:emptyLikeTitle')}
        </Paragraph>
        <StyledEmptyMessage fontSize="s" fontWeight="regular" color="gray_5">
          {translate('forkygram:emptyLikeMessage')}
        </StyledEmptyMessage>
      </StyledEmptyContainer>
    ),
    [],
  )

  const handleRenderContent = useMemo(
    () => (
      <ListLazy
        loadData={handleLoadData}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        listEmptyElement={handleRenderEmpty}
      />
    ),
    [handleKeyExtractor, handleLoadData, handleRenderEmpty, handleRenderItem],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderHeader}
      <StyledListContainer>{handleRenderContent}</StyledListContainer>
    </StyledModal>
  )
}
