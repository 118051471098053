import {GopayOutletData} from 'pages'

export const PAYMENT_CURRENCY_NAME_DEFAULT = 'IDR'

export const PAYMENT_CURRENCY_ID_DEFAULT = 'MR9Xdjv70WpeODE8DEPLAyb8xVBQzq31'

export const INITIALIZE_GOPAY_DATA: GopayOutletData = {
  actions: {
    cancel: '',
    deeplink_redirect: '',
    qr_checkout_string: '',
    generate_qr_code: '',
  },
  id: '',
  name: '',
  payment_type: '',
  transaction_time: '',
}
