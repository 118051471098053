import React from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_NAVBAR_ICON_SIZE} from 'consts'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopRootBottomNavBarItemProps} from './GiftShopRootBottomNavBarItemProps'

interface StyledContainerProps {
  isSelected: boolean
  darkBar?: boolean
}

const StyleOuterContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, isSelected, darkBar}) => ({
    color: `${
      isSelected
        ? darkBar
          ? theme.primary_4
          : theme.primary_5
        : darkBar
        ? theme.gray_1
        : theme.gray_6
    } !important`,
    ...(!isSelected && {
      ':hover': {color: `${theme.gray_4} !important`},
    }),
  })}
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledLabel = styled(Paragraph)`
  text-align: center;
  margin: ${convertUnit(-4)} 0 0 0;
`

export default function GiftShopRootBottomNavBarItem({
  icon,
  isSelected,
  label,
  onClick,
}: GiftShopRootBottomNavBarItemProps) {
  return (
    <StyleOuterContainer>
      <StyledContainer
        isSelected={isSelected}
        onClick={isSelected ? undefined : onClick}>
        <Icon type={icon} size={GIFT_SHOP_NAVBAR_ICON_SIZE} color="inherit" />
        <StyledLabel
          fontSize="xs"
          lineHeight={17}
          fontWeight={isSelected ? 'quicksandBold' : 'quicksandMedium'}
          color="inherit">
          {label}
        </StyledLabel>
      </StyledContainer>
    </StyleOuterContainer>
  )
}
