import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {Image} from 'common/components'
import convertUnit from 'lib/unit'
import {PolicyRootNavBarItem} from '../NavBarItem'
import {PolicyRootNavBarProps} from './PolicyRootNavBarProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    borderColor: theme.gray_1,
    backgroundColor: theme.white_1,
  })}
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  border-right-style: solid;
  border-right-width: ${convertUnit(1)};
`

const StyledScrollContainer = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(0)};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border-radius: ${convertUnit(0)};
  }
`

const StyledLogo = styled.div`
  margin: ${convertUnit(20)} 0;
  text-align: center;
`
export default function PolicyRootNavBar({
  select,
  data,
}: PolicyRootNavBarProps) {
  const fotoyulogo = IMAGE_ASSET('giftshop', 'logo-fotoyu-cms.png')
  return (
    <StyledContainer>
      <StyledLogo>
        <Image src={fotoyulogo} alt="Fotoyu Logo" />
      </StyledLogo>
      <StyledScrollContainer>
        {data.map(({key, label, onClick}) => (
          <PolicyRootNavBarItem
            key={key}
            label={label}
            isSelected={select === key}
            onClick={onClick}
          />
        ))}
      </StyledScrollContainer>
    </StyledContainer>
  )
}
