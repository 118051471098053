import React, {useCallback, useEffect, useMemo} from 'react'
import {translate} from 'i18n'
import styled from 'styled-components'
import {getFontFamily, useTimer} from 'utils'
import {parseShape} from 'types'
import {useTheme} from 'themes'
import {Paragraph} from '../Paragraph'
import {ParsedText} from '../ParsedText'
import {TextTimerProps} from './TextTimerProps'

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export default function TextTimer({
  textCooldown,
  textAvailable,
  cooldown = 60,
  resetWhenPress = true,
  onPress,
  shouldReset,
  leadingText,
  lastSaved,
  countOnStart,
  showChangeCredential,
  onClickChangeCredential,
  ...props
}: TextTimerProps) {
  const theme = useTheme()
  const today = useMemo(() => new Date().getTime(), [])
  const timeDiff = Math.floor(today - lastSaved) / 1000
  const continueCount: boolean = timeDiff < cooldown
  const {countdown, reset} = useTimer({
    countOnStart: countOnStart ?? true,
    duration: lastSaved && continueCount ? cooldown - timeDiff : 0,
  })
  const isAvailable = useMemo(() => countdown > 0, [countdown])

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  const text = useMemo(
    () =>
      isAvailable && resetWhenPress
        ? textCooldown === 'email'
          ? ` <cooldown>${translate('auth:verificationEmailResendCountdown', {
              countdown:
                countdown > 60
                  ? `${countDownMinute}m${countDownSecond}`
                  : `${countDownSecond}`,
            })}</cooldown>`
          : textCooldown === 'whatsapp'
          ? ` <cooldown>${translate(
              'auth:verificationWhatsappResendCountdown',
              {
                countdown:
                  countdown > 60
                    ? `${countDownMinute}m${countDownSecond}`
                    : `${countDownSecond}`,
              },
            )}</cooldown>`
          : ` <cooldown>${translate('auth:forgotPasswordPhoneResendCountdown', {
              countdown:
                countdown > 60
                  ? `${countDownMinute}m${countDownSecond}`
                  : `${countDownSecond}`,
            })}</cooldown>`
        : ` <resend>${textAvailable}</resend>`,
    [
      isAvailable,
      resetWhenPress,
      textCooldown,
      countdown,
      countDownMinute,
      countDownSecond,
      textAvailable,
    ],
  )

  const handlePressResend = useCallback(() => {
    if (!isAvailable && resetWhenPress) {
      reset(cooldown)
      onPress()
    }
  }, [isAvailable, resetWhenPress, onPress, reset, cooldown])

  const parseText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<resend>.*?<\/resend>/,
        style: {
          color: theme.primary_5,
          cursor: 'pointer',
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<resend>|<\/resend>/g, ''),
        onClick: handlePressResend,
      },
      {
        pattern: /<cooldown>.*?<\/cooldown>/,
        style: {
          color: theme.gray_3,
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<cooldown>|<\/cooldown>/g, ''),
      },
      {
        pattern: /<change>.*?<\/change>/,
        style: {
          color: theme.primary_5,
          cursor: 'pointer',
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<change>|<\/change>/g, ''),
        onClick: onClickChangeCredential,
      },
    ],
    [handlePressResend, onClickChangeCredential, theme.gray_3, theme.primary_5],
  )

  useEffect(() => {
    shouldReset && reset(cooldown)
  }, [cooldown, reset, shouldReset])

  return (
    <StyledContainer>
      <StyledParagraph {...props} color="gray_5">
        {leadingText}
        <ParsedText parse={parseText}>{text}</ParsedText>
        {showChangeCredential ? (
          <ParsedText parse={parseText}>
            {translate('auth:verificationChangeCredential', {
              context: textCooldown,
            })}
          </ParsedText>
        ) : (
          <></>
        )}
      </StyledParagraph>
    </StyledContainer>
  )
}
