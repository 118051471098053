import React, {useMemo} from 'react'
import {GIFT_SHOP_MAX_DESC_CHARACTER} from 'consts'
import {useTranslation} from 'i18n'
import {InputArea} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileEditBulkModalFormProps} from './GiftShopProfileEditBulkModalProps'

export default function GiftShopProfileEditBulkModalFormDescription({
  form,
}: GiftShopProfileEditBulkModalFormProps) {
  const {translate} = useTranslation()

  const handleRenderInputDescription = useMemo(
    () => (
      <InputArea
        form={form}
        name="description"
        label={translate('giftShop:uploadDescriptionLabel')}
        placeholder={translate('giftShop:uploadInputDescriptionPlaceholder')}
        inputStyle={{
          height: convertUnit(71),
        }}
        maxLength={GIFT_SHOP_MAX_DESC_CHARACTER}
      />
    ),
    [form, translate],
  )

  return handleRenderInputDescription
}
