import React, {useCallback, useMemo, useState, useEffect} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {
  EmailForm,
  GiftshopProfileVerifyPassword,
  ObjectState,
  ProfileEditEmailFieldType,
  ProfileVerifyEmailErrorMessage,
} from 'types'
import {requestData} from 'services'
import {
  formatCooldownTime,
  showPrompt,
  showSnackbar,
  useDidMount,
  useHistory,
} from 'utils'
import {Button, Input, Paragraph} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {
  VALIDATION_AUTH_LOGIN_PASSWORD,
  VALIDATION_GIFTSHOP_EDITPROFILE_EMAIL,
} from 'common/validations'
import {
  GiftShopEditProfileLimitPasswordModal,
  GiftShopEditProfilePasswordVerifyModal,
} from '../Modal'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(25)};
  border-radius: ${convertUnit(30)};
`

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(50)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(770)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
`
interface GiftshopEmailFormProps {
  stateActiveContent: ObjectState<string>
}

export default function GiftshopEmailForm({
  stateActiveContent,
}: GiftshopEmailFormProps) {
  const setActiveContent = stateActiveContent[1]
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const history = useHistory()
  const {has_password} = useSelector('user') || {}

  const form = useForm<EmailForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    reValidateMode: 'onChange',
  })
  const {handleSubmit, watch, errors} = form
  const {email, password} = watch()

  const [publishedState, setPublishedState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordLimitModal, setPasswordLimitModal] = useState(false)
  const [cooldownTimer, setCooldownTimer] = useState('0')
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<ProfileVerifyEmailErrorMessage>({
    type: '' as ProfileEditEmailFieldType,
    message: '',
  })
  const [passwordVerifyModal, setPasswordVerifyModal] = useState<{
    visible: boolean
    item: GiftshopProfileVerifyPassword
  }>({
    visible: false,
    item: 'none',
  })

  const handlePasswordVerifyModal = useCallback(
    (item: GiftshopProfileVerifyPassword) =>
      setPasswordVerifyModal((prev) => ({visible: !prev.visible, item})),
    [],
  )

  const handleSuccessSendEmail = useCallback(() => {
    update('lastUserState', {emailTime: new Date().getTime()})
    history.push('auth_verification_email', {
      email,
      disableCensor: true,
    })
  }, [email, history, update])

  const handleResendEmail = useCallback(async () => {
    setLoading(true)
    await requestData('giftshop_patch_resend_email', {
      onRequestSuccess: ({data, status}) => {
        if (status === 200) {
          handleSuccessSendEmail()
        } else if (status === 429) {
          const {wait_time} = data.detail || {}
          const {hour, minute, second} = formatCooldownTime(
            wait_time,
            false,
            true,
          )
          showSnackbar(
            translate('auth:resendLimitReached', {
              duration: hour + minute + second,
            }),
          )
        }
      },
    })
    setLoading(false)
  }, [handleSuccessSendEmail, translate])

  const handleAddNewEmail = useCallback(async () => {
    setLoading(true)
    const response = await requestData('giftshop_post_add_email', {
      data: {email, password},
    })
    if (typeof response !== 'string') {
      setPublishedState(true)
      const {
        status,
        data: {result, detail, message},
      } = response
      if (status >= 200 && status < 300) {
        update('userNewEmail', {email})
        update('verificationStatus', {
          verificationType: 'add_email',
          isEmailVerified: false,
        })
        if (status === 200) {
          handleSuccessSendEmail()
          return
        }
        if (result.resend_time) {
          if (result.resend_time === '0s') {
            await handleResendEmail()
          } else {
            const {hour, minute, second} = formatCooldownTime(
              result.resend_time,
              false,
              true,
            )
            showSnackbar(
              translate('auth:resendLimitReached', {
                duration: hour + minute + second,
              }),
            )
          }
        }
      } else if (
        status === 400 &&
        message === 'password invalid' &&
        typeof detail !== 'undefined'
      ) {
        setErrorMessage({
          type: 'password',
          message: translate('auth:validationLoginAttempt', {
            attempt: detail.attempt,
          }),
        })
      } else if (status === 409 && message === 'email exist') {
        setErrorMessage({
          type: 'email',
          message: translate('giftShop:addEmailErrorExist'),
        })
      } else if (status === 422 && message === 'password does not exist') {
        handlePasswordVerifyModal('email')
      } else if (status === 423 && typeof detail !== 'undefined') {
        setCooldownTimer(detail.wait_time)
        setPasswordLimitModal(true)
      } else if (status === 429 && typeof detail !== 'undefined') {
        const {hour, minute, second} = formatCooldownTime(
          detail.wait_time,
          false,
          true,
        )
        showSnackbar(
          translate('auth:resendLimitReached', {
            duration: hour + minute + second,
          }),
        )
      } else {
        setErrorMessage({type: '' as ProfileEditEmailFieldType, message: ''})
        showSnackbar(translate('global:messageError', {message}))
      }
    }
    setLoading(false)
  }, [
    email,
    handlePasswordVerifyModal,
    handleResendEmail,
    handleSuccessSendEmail,
    password,
    translate,
    update,
  ])

  const handleRenderErrorMessage = useMemo(
    () =>
      !!errorMessage && (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage.message}
        </StyledError>
      ),
    [errorMessage],
  )

  const handleRenderEmail = useMemo(
    () => (
      <>
        <Input
          type="email"
          name="email"
          form={form}
          formRules={VALIDATION_GIFTSHOP_EDITPROFILE_EMAIL}
          label={translate('global:emailNew')}
          placeholder={translate('giftShop:addEmailPlaceholder')}
          onChangeText={() => {
            setErrorMessage({
              type: '' as ProfileEditEmailFieldType,
              message: '',
            })
          }}
        />
        {errorMessage.type === 'email' && handleRenderErrorMessage}
      </>
    ),
    [errorMessage.type, form, handleRenderErrorMessage, translate],
  )

  const handleRenderPassword = useMemo(
    () => (
      <>
        <Input
          type="password"
          name="password"
          form={form}
          formRules={VALIDATION_AUTH_LOGIN_PASSWORD}
          label={translate('global:password')}
          placeholder={translate('auth:loginInputPasswordPlaceholder')}
          containerStyle={{marginTop: convertUnit(20)}}
          rightIcon="visibility"
          onChangeText={() => {
            setErrorMessage({
              type: '' as ProfileEditEmailFieldType,
              message: '',
            })
          }}
        />
        {errorMessage.type === 'password' && handleRenderErrorMessage}
      </>
    ),
    [errorMessage.type, form, handleRenderErrorMessage, translate],
  )

  const handleRenderPasswordVerifyModal = useMemo(
    () => (
      <GiftShopEditProfilePasswordVerifyModal
        visible={passwordVerifyModal.visible}
        item={passwordVerifyModal.item}
        onCancel={() => {
          handlePasswordVerifyModal('none')
          history.goBack()
        }}
        onConfirm={() => setActiveContent('password')}
      />
    ),
    [
      handlePasswordVerifyModal,
      history,
      passwordVerifyModal.item,
      passwordVerifyModal.visible,
      setActiveContent,
    ],
  )

  const isButtonDisabled = useMemo(() => {
    const isValidEmail = errors.email !== undefined || email === ''
    const isValidPassword = errors.password !== undefined || password === ''

    return isValidEmail || isValidPassword
  }, [email, errors.email, errors.password, password])

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={isButtonDisabled}
        isLoading={loading}
        label={translate('global:save')}
        onClick={handleSubmit(handleAddNewEmail)}
      />
    ),
    [isButtonDisabled, loading, translate, handleSubmit, handleAddNewEmail],
  )

  const handleRenderPasswordLimitModal = useMemo(
    () =>
      passwordLimitModal && (
        <GiftShopEditProfileLimitPasswordModal
          cooldownTimer={cooldownTimer}
          onConfirm={() => setPasswordLimitModal((prev) => !prev)}
        />
      ),
    [cooldownTimer, passwordLimitModal],
  )

  useEffect(() => {
    const prompt = (email !== '' || password !== '') && !publishedState
    showPrompt(prompt, translate('giftShop:uploadModalMessage'))
  }, [email, password, publishedState, translate])

  useDidMount(() => {
    if (!has_password) {
      handlePasswordVerifyModal('email')
    }
  })

  return (
    <StyledContainer>
      {handleRenderPasswordLimitModal}
      {handleRenderPasswordVerifyModal}
      <StyledScreenContainer>
        {handleRenderEmail}
        {handleRenderPassword}
        {handleRenderButton}
      </StyledScreenContainer>
    </StyledContainer>
  )
}
