import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {translate} from 'i18n'
import {useDebounce} from 'utils'
import {Icon, Input} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftshopProfileSearchCoverProps} from './GiftshopProfileSearchCoverProps'

const StyledSearchMenuContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
    align-items: initial;
  }
`

const StyledSearchContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const StyledInput = styled(Input)`
  width: ${convertUnit(244)};
  height: ${convertUnit(26)};
  padding-right: ${convertUnit(16)};
`

export default function GiftshopProfileSearchCreationCover({
  stateSearchQuery,
}: GiftshopProfileSearchCoverProps) {
  const setSearchQuery = stateSearchQuery[1]
  const debounce = useDebounce()
  const [input, setInput] = useState<string>('')

  const handleRenderInput = useMemo(
    () => (
      <StyledInput
        value={input}
        containerStyle={{width: '100%'}}
        leftIcon={<Icon type="search" color="gray_3" />}
        rightIcon={
          input === '' ? undefined : (
            <Icon
              type="close"
              color="gray_3"
              onClick={() => {
                setInput('')
                setSearchQuery('')
              }}
            />
          )
        }
        placeholder={translate('giftShop:searchCreationPlaceholder')}
        onChangeText={(text) => {
          setInput(text)
          debounce(() => setSearchQuery(text), 500)
        }}
      />
    ),
    [debounce, input, setSearchQuery],
  )

  return (
    <StyledSearchMenuContainer>
      <StyledSearchContainer>{handleRenderInput}</StyledSearchContainer>
    </StyledSearchMenuContainer>
  )
}
