import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
  padding-top: ${convertUnit(35)};
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalCopyrightContent() {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:copyright')}
        </StyledTitle>
        <StyledDescription>
          {translate('policy:copyrightAboutDescA')}
          <br />
          <br />
          {translate('policy:copyrightAboutDescB')}
          <br />
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:copyrightAboutDescC')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleProtect = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightProtected')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightProtectedDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:copyrightProtectedDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:copyrightProtectedDigitalDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:copyrightProtectedDigitalDesc', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleBeforeBuy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightDigitalBefore')}
        </StyledSubTitle>
        <StyledListUl>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:copyrightDigitalBeforeCreatorDesc')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:copyrightDigitalBeforeCreatorDesc', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:copyrightDigitalBeforeYuserDesc')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:copyrightDigitalBeforeYuserDesc', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleAfterBuy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightDigitalAfter')}
        </StyledSubTitle>
        <StyledListUl>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:copyrightDigitalAfterCreatorDesc')}
            </StyledDescription>
            <StyledList type="a">
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:copyrightDigitalAfterCreatorDesc', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:copyrightDigitalAfterYuserDesc')}
            </StyledDescription>
            <StyledSubTitle fontSize="l">
              {translate('policy:copyrightDigitalAfterYuserDescSinglePhoto')}
            </StyledSubTitle>
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate(
                    'policy:copyrightDigitalAfterYuserDescSinglePhoto',
                    {
                      context: `${index + 1}`,
                    },
                  )}
                </StyledListItem>
              ))}
            </StyledList>
            <StyledSubTitle fontSize="l">
              {translate('policy:copyrightDigitalAfterYuserDescGroupPhoto')}
            </StyledSubTitle>
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate(
                    'policy:copyrightDigitalAfterYuserDescGroupPhoto',
                    {
                      context: `${index + 1}`,
                    },
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleCompetition = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightPhotoCompetition')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightPhotoCompetitionDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleCreatorViolation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightInfringementCreator')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightInfringementCreatorDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem>
              {translate('policy:copyrightInfringementCreatorDescA', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:copyrightInfringementCreatorDescB')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem>
              {translate('policy:copyrightInfringementCreatorDescB', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:copyrightInfringementCreatorDescC')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleYuserViolation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightInfringementYuser')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightInfringementYuserDescA')}
          <br />
          {translate('policy:copyrightInfringementYuserDescA')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleNotification = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightInfringementNotification')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightInfringementNotificationDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem>
              {translate('policy:copyrightInfringementNotificationDescA', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:copyrightInfringementNotificationDescB')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRebuttal = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:copyrightInfringementCounterNotification')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:copyrightInfringementCounterNotificationDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem>
              {translate(
                'policy:copyrightInfringementCounterNotificationDescA',
                {
                  context: `${index + 1}`,
                },
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:copyrightInfringementCounterNotificationDescB')}
          <br />
          {translate('policy:copyrightInfringementCounterNotificationDescC')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleTitle}
      {handleProtect}
      {handleBeforeBuy}
      {handleAfterBuy}
      {handleCompetition}
      {handleCreatorViolation}
      {handleYuserViolation}
      {handleNotification}
      {handleRebuttal}
    </>
  )
}
