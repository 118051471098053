import {useCallback, useMemo} from 'react'
import {requestData} from 'services'
import {useTranslation} from 'i18n'
import {REDUX_STORE} from 'lib/redux'
import {showModalMaintenance} from 'utils/components'
import {parseDate} from 'utils/dates'

export function useCheckMaintenance() {
  const {translate} = useTranslation()
  const handleCheckMaintenance = useCallback(async () => {
    const response = await requestData('notification_maintenance')
    const {showMaintenanceModal} = REDUX_STORE.getState().maintenanceModalStatus
    if (typeof response !== 'string' && response.status === 200) {
      const {
        end_date,
        start_date,
        status,
        description,
        title,
      } = response.data.result
      if (status && showMaintenanceModal) {
        showModalMaintenance(status, {
          start_date: start_date
            ? parseDate(start_date, 'DD MMMM YYYY HH:mm')
            : '',
          end_date: end_date ? parseDate(end_date, 'DD MMMM YYYY HH:mm') : '',
          description:
            description || translate('auth:serverUnderMaintenanceDescGeneral'),
          title: title || translate('auth:serverUnderMaintenance'),
          status,
        })
      }
    }
  }, [translate])
  return useMemo(() => ({handleCheckMaintenance}), [handleCheckMaintenance])
}
