import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  AVATAR_SIZE_CHAT,
  SERVICE_CANCELLATION_SEARCH_MESSAGE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {translate} from 'i18n'
import {useDebounce, useHistory} from 'utils'
import {requestData} from 'services'
import {MessageMemberListData} from 'types'
import {
  Avatar,
  Icon,
  IconVerifiedBadge,
  Input,
  ListLazy,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'

const StyledInputContainer = styled.div`
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(20)};
  padding-top: ${convertUnit(16)};
  padding-bottom: ${convertUnit(16)};
`

const StyledItemContainer = styled.div<CSSProperties>`
  display: flex;
  flex: 1;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  cursor: pointer;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1;
`

const StyledNotFoundContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })};
  height: ${convertUnit(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`

export default function GiftShopMessageListNew() {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const debounce = useDebounce()

  const handleLoadData = useCallback(
    async (page: number, limit: number, query: string) => {
      const responses = await requestData('forkygram_search_message', {
        cancelId: SERVICE_CANCELLATION_SEARCH_MESSAGE,
        params: {
          page,
          limit,
          q: query,
        },
      })

      if (
        typeof responses === 'string' &&
        responses === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof responses !== 'string' && responses.status === 200
        ? responses.data.result.filter((item) => !item.is_underage)
        : []
    },
    [],
  )

  const renderNotFound = useMemo(
    () => (
      <StyledNotFoundContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
          {translate('messages:userNotFound')}
        </Paragraph>
      </StyledNotFoundContainer>
    ),
    [],
  )

  const renderItem = useCallback(
    (item: MessageMemberListData) => (
      <StyledItemContainer
        onClick={() =>
          history.push('giftshop_message_room', {
            participantId: item.member_id,
            roomType: item.room_type,
          })
        }>
        <StyledRowContainer>
          <StyledAvatar
            containerStyle={{marginRight: 15}}
            src={item.photo}
            alt="Avatar"
            size={AVATAR_SIZE_CHAT}
          />
          <Paragraph fontSize="m" fontWeight="bold">
            {item.username}
          </Paragraph>
          {item.creator_status === 'verified' && (
            <StyledIconVerifiedBadge
              height={convertUnit(18)}
              width={convertUnit(24)}
            />
          )}
        </StyledRowContainer>
      </StyledItemContainer>
    ),
    [history],
  )

  const handleRenderEmpty = useMemo(() => (search ? renderNotFound : <></>), [
    renderNotFound,
    search,
  ])

  return (
    <>
      <StyledInputContainer>
        <Input
          leftIcon={<Icon type="search" color="gray_3" />}
          onChangeText={(text) => debounce(() => setSearch(text))}
          placeholder={translate('forkygram:uploadSearchPeoplePlaceholder')}
          defaultValue={''}
          rightIcon="delete"
          onRightIconClick={() => setSearch('')}
        />
      </StyledInputContainer>
      <ListLazy
        loadData={handleLoadData}
        search={search}
        renderItem={renderItem}
        keyExtractor={(item) => item.member_id}
        listEmptyElement={handleRenderEmpty}
        scrollbar={false}
      />
    </>
  )
}
