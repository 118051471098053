import {useCallback, useMemo} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  GiftshopUploadPublishFormMap,
  GiftshopUploadPublishParam,
  GiftshopUploadPublishValues,
} from 'types'
import {useDispatch, useSelector} from 'lib/redux'
import {useGiftshopUploadPublishSeries} from './GiftShopUploadPublishSeriesHooks'
import {showSnackbar} from '../../../components'
import {getGiftshopLocationName} from '../GiftshopHelper'
import {useGiftShopUploadPublishSeriesVideo} from './GiftShopUploadPublishSeriesVideoHooks'
import {useGiftshopUploadPublishSeriesSendToFace} from './GiftShopUploadPublishSeriesSendToFaceHooks'

export function useGiftshopUploadPublish({
  mapRef,
  data,
  formRef,
  sendToFace = false,
  sendToFaceData = {
    description: '',
    tags: [],
  },
  uploadType,
}: GiftshopUploadPublishParam) {
  const {translate} = useTranslation()
  const {publishSeries} = useGiftshopUploadPublishSeries({
    mapRef,
  })
  const {publishSeries: publishVideo} = useGiftShopUploadPublishSeriesVideo({
    mapRef,
  })
  const {
    publishSeries: publishSendToFace,
  } = useGiftshopUploadPublishSeriesSendToFace({
    mapRef,
    sendToFaceData,
  })
  const {map, setMapValue, updateMap} = mapRef
  const {update} = useDispatch()
  const {mapForm} = map.current
  const lastUploadPhoto = useSelector('lastUploadStatePhoto')
  const lastUploadVideo = useSelector('lastUploadStateVideo')
  const hostState = useSelector('hostState')

  const photoLocationValid = useMemo(
    () =>
      (uploadType === 'bulk' || uploadType === 'single') &&
      lastUploadPhoto &&
      lastUploadPhoto.latitude &&
      lastUploadPhoto.longitude,
    [lastUploadPhoto, uploadType],
  )
  const videoLocationValid = useMemo(
    () =>
      uploadType === 'video' &&
      lastUploadVideo &&
      lastUploadVideo.latitude &&
      lastUploadVideo.longitude,
    [lastUploadVideo, uploadType],
  )

  const handleGenerateInitializeData = useCallback(() => {
    const result: GiftshopUploadPublishFormMap = {}

    for (const {id, images} of Object.values(data)) {
      const values = formRef.current[id]

      for (const image of images) {
        result[image.id] = {
          id: image.id,
          status: 'not-started',
          item: {...values, image, series: id},
        }
      }
    }

    return result
  }, [data, formRef])

  const handleValidateForm = useCallback(async () => {
    let isValid = true

    for (const values of Object.values(formRef.current)) {
      const {id, price, location, tags} = values

      const selectedData = data[id]
      const {images} = selectedData
      const img = images[0]
      const {latitude: imgLat, longitude: imgLong} = img
      const validLatLong =
        imgLat !== '' && imgLat !== 'NaN' && imgLong !== '' && imgLong !== 'NaN'

      if (selectedData.id === id && !sendToFace) {
        if (
          validLatLong &&
          imgLat &&
          imgLong &&
          Object.keys(location).length === 0
        ) {
          formRef.current[id].location = getGiftshopLocationName({
            latitude: imgLat,
            longitude: imgLong,
          })
        } else if (tags.length > 0) {
          formRef.current[id].location = getGiftshopLocationName({
            latitude: tags[0].location?.latitude.toString(),
            longitude: tags[0].location?.longitude.toString(),
            name: tags[0].name,
          })
        } else if (
          (photoLocationValid || videoLocationValid) &&
          Object.keys(location).length === 0
        ) {
          const {latitude, longitude, name} =
            uploadType === 'video' ? lastUploadVideo : lastUploadPhoto

          formRef.current[id].location = getGiftshopLocationName({
            latitude,
            longitude,
            name,
          })
        }
      }

      // eslint-disable-next-line no-await-in-loop
      /** @todo */
      // const triggerResult = await trigger()
      const triggerResult = true

      if (!sendToFace) {
        if (
          !triggerResult ||
          id === '' ||
          price === '' ||
          (!validLatLong &&
            !(
              uploadType !== 'video' &&
              lastUploadPhoto.latitude &&
              lastUploadPhoto.longitude
            )) ||
          (!(
            uploadType === 'video' &&
            lastUploadVideo.latitude &&
            lastUploadVideo.longitude
          ) &&
            tags.length === 0 &&
            location === '')
        ) {
          isValid = false
          !(
            isValid &&
            (!validLatLong ||
              (uploadType === 'video' && lastUploadVideo.latitude) ||
              (uploadType !== 'video' && lastUploadPhoto.latitude) ||
              location === '')
          ) && showSnackbar(translate('giftshop:emptyLocation'))
        }
      }
    }

    return isValid
  }, [
    data,
    formRef,
    lastUploadPhoto,
    lastUploadVideo,
    photoLocationValid,
    sendToFace,
    translate,
    uploadType,
    videoLocationValid,
  ])

  const handlePublish = useCallback(
    async (reset: boolean = true) => {
      if (!navigator.onLine) {
        showSnackbar(
          translate('global:messageError', {context: 'no-connection'}),
        )
        return
      }

      const isValid = await handleValidateForm()
      if (!isValid) {
        return
      }

      const response = await requestData('giftshop_get_storage')

      if (typeof response === 'string' || response.status !== 200) {
        showSnackbar(translate('giftShop:uploadFailed'))
        return
      }

      const {remaining_storage, limit_storage} = response.data.result

      const totalSize = Object.values(data).reduce(
        (previous, series) =>
          previous +
          series.images.reduce(
            (prevSeries, variant) => prevSeries + variant.size,
            0,
          ),
        0,
      )

      if (totalSize > remaining_storage) {
        const gb = 2 ** 30
        const limit = limit_storage / gb

        showSnackbar(translate('giftShop:uploadFailed_storage', {limit}))
        return
      }

      const queue = reset ? handleGenerateInitializeData() : map.current.mapForm

      setMapValue({
        active: true,
        mapForm: queue,
      })
      updateMap()

      const lastForm = Object.values(formRef.current).pop()

      if (lastForm && !sendToFace) {
        const {description, price, tags} = lastForm
        update(
          uploadType === 'video'
            ? 'lastUploadStateVideo'
            : 'lastUploadStatePhoto',
          {
            description,
            price,
            tags: hostState.role ? tags : [],
          },
        )
      }

      for (const series of Object.values(data)) {
        uploadType === 'video'
          ? // eslint-disable-next-line no-await-in-loop
            await publishVideo(series)
          : sendToFace
          ? // eslint-disable-next-line no-await-in-loop
            await publishSendToFace(series)
          : // eslint-disable-next-line no-await-in-loop
            await publishSeries(series)
      }
    },
    [
      handleValidateForm,
      data,
      handleGenerateInitializeData,
      map,
      setMapValue,
      updateMap,
      formRef,
      sendToFace,
      translate,
      update,
      uploadType,
      hostState.role,
      publishVideo,
      publishSendToFace,
      publishSeries,
    ],
  )

  const handleRetry = useCallback(() => {
    setMapValue({
      mapForm: Object.entries(mapForm)
        .filter(
          ([, {status}]) => status !== 'success' && status !== 'duplicate',
        )
        .reduce<GiftshopUploadPublishFormMap>(
          (previous, [key, item]): GiftshopUploadPublishFormMap => ({
            ...previous,
            [key]: {...item, status: 'not-started'},
          }),
          {},
        ),
    })
    handlePublish(false)
  }, [mapForm, setMapValue, handlePublish])

  return useMemo<GiftshopUploadPublishValues>(
    () => ({retry: handleRetry, publish: handlePublish}),
    [handlePublish, handleRetry],
  )
}
