import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {AUTH_COUNTRY_DEFAULT, REGEX_PHONE_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import {
  AuthCountryResponse,
  AuthCredentialType,
  AuthFormRegisterEasySignupData,
  ObjectState,
} from 'types'
import {
  Button,
  Icon,
  Input,
  InputPhone,
  Modal,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {
  VALIDATION_AUTH_UPDATE_EMAIL,
  VALIDATION_AUTH_UPDATE_PHONE,
} from 'common/validations'

const StyledTitle = styled(Paragraph)`
  text-align: center;
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${convertUnit(8)};
`

interface TemplateAuthEasySignupChangeCredentialModalProps {
  stateModal: ObjectState<boolean>
  method: AuthCredentialType
  currentCredential: string
  stateLoading: ObjectState<boolean>
  onCancel(): void
  onConfirm(credential: string): void
  stateError: ObjectState<string>
}

export default function TemplateAuthEasySignupChangeCredentialModal({
  stateModal,
  method,
  currentCredential = '',
  stateLoading,
  onCancel,
  onConfirm,
  stateError,
}: TemplateAuthEasySignupChangeCredentialModalProps) {
  const {translate} = useTranslation()
  const [modalVisible, setModalVisible] = stateModal
  const form = useForm<AuthFormRegisterEasySignupData>({
    defaultValues: {
      email: '',
      phone: '',
    },
  })
  const {watch, errors} = form
  const {email, phone} = watch()
  const loading = stateLoading[0]
  const [error, setError] = stateError
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const dialCode = stateCountry[0].dial_code

  const disabled = useMemo(
    () =>
      (method === 'email' && (email === '' || errors.email !== undefined)) ||
      (method === 'phone' && (phone === '' || errors.phone !== undefined)),
    [email, errors.email, errors.phone, method, phone],
  )

  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )

  const handleRenderError = useMemo(
    () =>
      error ? (
        <Paragraph fontWeight="medium" color="danger_5">
          {error}
        </Paragraph>
      ) : (
        <></>
      ),
    [error],
  )

  const handleRenderEmailInput = useMemo(
    () => (
      <Input
        name="email"
        form={form}
        formRules={VALIDATION_AUTH_UPDATE_EMAIL(currentCredential)}
        onChangeText={() => setError('')}
        label={translate('auth:registerEasySignupUpdateCredentialLabel', {
          context: 'email',
        })}
        labelDescription={translate(
          'auth:registerEasySignupUpdateCredentialDescription',
          {context: 'email'},
        )}
        labelDescriptionDirection="column"
        labelDescriptionColor="gray_5"
        placeholder={translate('auth:registerIdentifierInputEmailPlaceholder')}
        type="email"
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="delete"
      />
    ),
    [currentCredential, form, setError, translate],
  )

  const handleRenderPhoneNumberInput = useMemo(
    () => (
      <InputPhone
        name="phone"
        loadData={handleLoadDataPhoneDummy}
        stateSelect={stateCountry}
        form={form}
        formRules={VALIDATION_AUTH_UPDATE_PHONE(currentCredential, dialCode)}
        onChangeText={() => setError('')}
        allowedCharacters={REGEX_PHONE_NUMBER}
        emptyMessage={(search) =>
          translate('auth:registerIdentifierSearchCountryEmpty', {
            search,
          })
        }
        label={translate('auth:registerEasySignupUpdateCredentialLabel', {
          context: 'whatsapp',
        })}
        labelDescription={translate(
          'auth:registerEasySignupUpdateCredentialDescription',
          {context: 'whatsapp'},
        )}
        labelDescriptionDirection="column"
        labelDescriptionColor="gray_5"
        placeholder={translate('auth:registerIdentifierInputPhonePlaceholder')}
        maxLength={13}
        searchPlaceholder={translate('global:search')}
        autoComplete={false}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="delete"
      />
    ),
    [
      currentCredential,
      dialCode,
      form,
      handleLoadDataPhoneDummy,
      setError,
      stateCountry,
      translate,
    ],
  )

  const handleCancel = useCallback(() => {
    setModalVisible(false)
    setError('')
    onCancel && onCancel()
  }, [onCancel, setError, setModalVisible])

  return (
    <StyledModal visible={modalVisible}>
      <StyledIcon>
        <Icon
          type={method === 'email' ? 'edit-email' : 'edit-phonenumber'}
          size={40}
        />
      </StyledIcon>
      <StyledTitle fontSize="xl" fontWeight="bold">
        {translate('auth:registerEasySignupUpdateCredentialTitle', {
          context: method === 'email' ? 'email' : 'whatsapp',
        })}
      </StyledTitle>
      {method === 'email'
        ? handleRenderEmailInput
        : handleRenderPhoneNumberInput}
      {handleRenderError}
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={handleCancel}
        />
        <StyledButton
          label={translate('global:confirm')}
          disabled={disabled}
          isLoading={loading}
          onClick={() => onConfirm(email || `${dialCode}-${phone}`)}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
