import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {parseDate, showSnackbar, useHistory} from 'utils'
import {Button, ButtonOutline, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopResendApprovalContentProps} from './GiftShopResendApprovalContentProps'
import {GiftShopResendApprovalImageItem} from '../ImageItem'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertUnit(4)};
  padding: 0 ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledImagesContainer = styled(StyledRow)`
  gap: ${convertUnit(12)};
`

const StyledExpiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  text-align: center;
`

const StyledButtonsContainer = styled(StyledRow)`
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  width: 100%;
  height: ${convertUnit(28)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  width: 100%;
  height: ${convertUnit(28)};
`

export default function GiftShopResendApprovalContent({
  data,
  onComplete,
  onChatExpired,
}: GiftShopResendApprovalContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {
    content_id,
    creator_member_id,
    expired_date,
    hires_url,
    invoice_id,
    original_at,
    original_url,
    title,
    content_type,
    hires_size,
    hires_video_duration,
    hires_video_url,
    original_size,
    original_video_duration,
    original_video_url,
    hires_resolution,
    original_resolution,
  } = data
  const {id: selfId} = useSelector('user')!
  const isSelf = selfId === creator_member_id
  const parsedDate = useMemo(
    () => parseDate(original_at.toString(), 'MMMM D, YYYY, HH:mm'),
    [original_at],
  )

  const parsedExpiredDate = useMemo(
    () => parseDate(expired_date, 'MMMM D, YYYY, HH:mm'),
    [expired_date],
  )

  const isExpired = useMemo(
    () => new Date(expired_date).getTime() < new Date().getTime(),
    [expired_date],
  )

  const handleExpiredContent = useCallback(() => {
    showSnackbar(translate('giftShop:resendRevisionChatExpired'))
    onChatExpired && onChatExpired(content_id, invoice_id)
  }, [content_id, invoice_id, onChatExpired, translate])

  const handleChatCreator = useCallback(() => {
    isExpired
      ? handleExpiredContent()
      : history.push('giftshop_message_room', {
          participantId: creator_member_id,
          roomType: 'giftshop',
          chatType: 'resend_revision',
          content: {
            contentIds: [content_id],
            invoiceId: invoice_id,
            title,
            url: hires_url,
          },
        })
  }, [
    content_id,
    creator_member_id,
    handleExpiredContent,
    hires_url,
    history,
    invoice_id,
    isExpired,
    title,
  ])

  const handleRenderImages = useMemo(
    () => (
      <StyledImagesContainer>
        <GiftShopResendApprovalImageItem
          date={parsedDate}
          src={
            content_type === 'photo' ? original_url : original_video_url || ''
          }
          title={title}
          content_type={content_type}
          duration={original_video_duration}
          size={original_size}
          resolution={original_resolution}
        />
        <GiftShopResendApprovalImageItem
          date={parsedDate}
          src={content_type === 'photo' ? hires_url : hires_video_url || ''}
          title={title}
          content_type={content_type}
          duration={hires_video_duration}
          size={hires_size}
          resolution={hires_resolution}
        />
      </StyledImagesContainer>
    ),
    [
      content_type,
      hires_resolution,
      hires_size,
      hires_url,
      hires_video_duration,
      hires_video_url,
      original_resolution,
      original_size,
      original_url,
      original_video_duration,
      original_video_url,
      parsedDate,
      title,
    ],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledButtonOutline
          label={translate('global:chatTheCreator')}
          disabled={isSelf}
          color="gray_5"
          borderColor="gray_5"
          disableBackgroundColor="gray_3"
          backgroundHoverColor="white_1"
          onClick={handleChatCreator}
        />
        <StyledButton
          label={translate('global:complete')}
          onClick={onComplete}
        />
      </StyledButtonsContainer>
    ),
    [handleChatCreator, isSelf, onComplete, translate],
  )

  const handleRenderExpiredSection = useMemo(
    () => (
      <StyledExpiredContainer>
        <Paragraph>
          {translate('giftShop:hiresConfirmationCompleteTime')}
        </Paragraph>
        <Paragraph fontWeight="bold">{parsedExpiredDate}</Paragraph>
        {handleRenderButtons}
      </StyledExpiredContainer>
    ),
    [handleRenderButtons, parsedExpiredDate, translate],
  )

  return (
    <StyledContainer>
      {handleRenderImages}
      {handleRenderExpiredSection}
    </StyledContainer>
  )
}
