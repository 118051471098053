import {useCallback, useEffect, useMemo, useRef} from 'react'
import {
  LayoutDimension,
  LayoutView,
  ListLazyActionLoad,
  ListPaginationAction,
  ListPaginationActionParam,
  ListPaginationIndicatorStatus,
} from 'types'
import {LIST_LOAD_LIMIT} from 'consts'
import {useDefaultState, useDidUpdate, useMapRef} from '../../objects'

export function useListPaginationAction<ItemT, S>({
  data,
  stateData,
  search,
  refresh,
  refreshing,
  refreshDefaultEnabled = true,
  limitPerPage = LIST_LOAD_LIMIT,
  assertPage,
  pageCount,
  defaultPage = 1,
  loadData,
  onContentSizeChange,
  onRefresh,
  onLayout,
  onLayoutDimension,
}: ListPaginationActionParam<ItemT, S>) {
  const emptyData = useRef([]).current
  const stateItems = useDefaultState(data || emptyData, stateData)
  const [items, setItems] = stateItems
  const {map, setMapValue, updateMap} = useMapRef({
    initialize: false,
    loading: true,
    page: defaultPage,
    refreshDefault: false,
    lastFetchedPage: 0,
  })

  const {
    initialize,
    loading,
    page,
    lastFetchedPage,
    refreshDefault,
  } = map.current

  const status = useMemo((): ListPaginationIndicatorStatus => {
    if (loading) {
      return 'loading'
    }

    return 'normal'
  }, [loading])

  const pageRefresh = useMemo(
    () =>
      refreshing !== undefined || !refreshDefaultEnabled
        ? undefined
        : refreshDefault,
    [refreshing, refreshDefaultEnabled, refreshDefault],
  )

  const handleLoadData = useCallback(
    async ({page: newPage = page, isRefresh = false}: ListLazyActionLoad) => {
      if (lastFetchedPage && newPage === lastFetchedPage && !isRefresh) return

      const safePage = Math.max(Math.min(newPage ?? 1, pageCount), 1)

      setMapValue({loading: true, lastFetchedPage: safePage})
      initialize && updateMap()
      setMapValue({page: safePage})

      const responses = await loadData(safePage, limitPerPage, search)

      setMapValue({
        initialize: true,
        refreshDefault: false,
        loading: false,
      })

      if (Array.isArray(responses)) {
        setItems(responses)
      } else {
        updateMap()
      }
    },
    [
      page,
      lastFetchedPage,
      pageCount,
      setMapValue,
      initialize,
      updateMap,
      loadData,
      limitPerPage,
      search,
      setItems,
    ],
  )

  const handleSearch = useCallback(
    () => handleLoadData({merge: false, page: 1}),
    [handleLoadData],
  )

  const handleRefresh = useCallback(() => {
    onRefresh && onRefresh()
    setMapValue({refreshDefault: true})
    handleLoadData({merge: false, page, isRefresh: true})
  }, [onRefresh, setMapValue, handleLoadData, page])

  const handleLayout = useCallback(
    (layout: LayoutView) => {
      onLayout && onLayout(layout)
    },
    [onLayout],
  )

  const handleLayoutDimension = useCallback(
    (layout: LayoutDimension) => {
      onLayoutDimension && onLayoutDimension(layout)
    },
    [onLayoutDimension],
  )

  const handleContentSizeChange = useCallback(
    (width: number, height: number) => {
      onContentSizeChange && onContentSizeChange(width, height)
    },
    [onContentSizeChange],
  )

  useEffect(() => {
    handleLoadData({page: assertPage})
  }, [assertPage, handleLoadData])

  useDidUpdate(() => setItems(data || emptyData), [data])

  useDidUpdate(() => {
    initialize && handleSearch()
  }, [search])

  useDidUpdate(() => {
    initialize && handleRefresh()
  }, [refresh])

  return useMemo<ListPaginationAction<ItemT>>(
    () => ({
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    }),
    [
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    ],
  )
}
