import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopBalanceCreatePasswordModalProps} from './GiftShopBalanceCreatePasswordModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(12)};
  text-align: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(114)};
  height: ${convertUnit(114)};
`

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopBalanceCreatePasswordModal({
  visible,
  toggleModal,
  desc,
  backToPage,
}: GiftShopBalanceCreatePasswordModalProps) {
  const {translate} = useTranslation()
  const image = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const history = useHistory()

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:createPassword')}
      </Paragraph>
    ),
    [translate],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={image} alt="image" />,
    [image],
  )

  const handleRenderInfo = useMemo(
    () => (
      <Paragraph fontSize="m">
        {desc ?? translate('giftShop:addBankAccountCreatePasswordInfo')}
      </Paragraph>
    ),
    [desc, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('giftShop:createPassword')}
          onClick={() =>
            history.push('giftshop_edit_profile', {menu: 'password', desc: backToPage})
          }
        />
        <StyledButton
          label={translate('global:cancel')}
          backgroundColor="white_2"
          color="primary_5"
          onClick={toggleModal}
        />
      </StyledButtonContainer>
    ),
    [backToPage, history, toggleModal, translate],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderTitle}
      {handleRenderImage}
      {handleRenderInfo}
      {handleRenderButton}
    </StyledModal>
  )
}
