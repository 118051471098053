import React, {useCallback} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory, useLocation} from 'utils'
import {Paragraph, Image, Button, Icon} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(335)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(200)};
  height: ${convertUnit(200)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  gap: ${convertUnit(16)};
`
const StyledButton = styled(Button)`
  width: 100%;
`

const StyledSubtitleContainer = styled.div`
  margin-top: ${convertUnit(8)};
  margin-bottom: ${convertUnit(20)};
  text-align: center;
`

const StyledDescriptionContainer = styled.div`
  margin: ${convertUnit(20)} 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

const StyledIconContainer = styled.div`
  padding: ${convertUnit(16)} 0;
  display: flex;
  align-self: flex-start;
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
`

export default function TemplateSimilarIdentityMultiAccount() {
  const image = IMAGE_ASSET('giftshop', 'robopet-confused.png')
  const {translate} = useTranslation()
  const history = useHistory()
  const {afterKyc, fromRoboyu, fromIfm} =
    useLocation('giftshop_similar_identity').state || {}

  const handleBack = useCallback(() => {
    afterKyc
      ? history.push('giftshop_explore', {})
      : fromRoboyu
      ? history.go(-2)
      : fromIfm
      ? history.replace('giftshop_feed', {})
      : history.goBack()
  }, [afterKyc, fromIfm, fromRoboyu, history])

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledIconContainer>
          <StyledBackIconCursor onClick={handleBack}>
            <Icon type="back" size={convertUnit(20)} color="primary_5" />
          </StyledBackIconCursor>
        </StyledIconContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('global:sorry')}
        </Paragraph>
        <StyledSubtitleContainer>
          <Paragraph fontSize="l">
            {translate('giftShop:similarMultiAccountSubtitle')}
          </Paragraph>
        </StyledSubtitleContainer>
        <StyledImage src={image} alt={image} />
        <StyledDescriptionContainer>
          <Paragraph fontSize="m" fontWeight="bold">
            {translate('giftShop:similarMultiAccountDescFirst')}
          </Paragraph>
          <Paragraph fontSize="m">
            {translate('giftShop:similarMultiAccountDescSecond')}
          </Paragraph>
        </StyledDescriptionContainer>
        <StyledButtonContainer>
          <StyledButton
            label={translate('auth:similarIdentityButton')}
            onClick={() =>
              history.replace('giftshop_similar_choose_account', {})
            }
          />
          <StyledButton
            color="primary_5"
            backgroundColor="white_3"
            label={translate('global:cancel')}
            onClick={handleBack}
          />
        </StyledButtonContainer>
      </StyledContentContainer>
    </StyledContainer>
  )
}
