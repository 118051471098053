import React from 'react'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {getFontFamily, getFontSize} from 'utils'
import {TooltipProps} from './TooltipProps'

const StyledTooltip = styled(ReactTooltip)<TooltipProps>`
  ${({
    theme,
    borderRadius = 6,
    backgroundColor = 'black_1',
    color = 'white_2',
    fontSize = 'xs',
    fontWeight = 'regular',
    padding = [4, 8],
  }) => ({
    backgroundColor: `${theme[backgroundColor]} !important`,
    borderRadius: `${convertUnit(borderRadius)} !important`,
    color: `${theme[color]} !important`,
    padding: `${convertUnit(padding[0])} ${convertUnit(padding[1])} !important`,
    fontFamily: getFontFamily(fontWeight),
    fontSize: `${getFontSize(fontSize)} !important`,
  })};
`

export default function Tooltip({delayShow = 50, ...props}: TooltipProps) {
  return <StyledTooltip delayShow={delayShow} {...props} />
}
