import {lazy} from 'react'

const AuthEasySignupChildrenWefieScreen = lazy(
  () => import('./AuthEasySignupChildrenWefieScreen'),
)

const AuthEasySignupScreen = lazy(() => import('./AuthEasySignupScreen'))

const AuthEasySignupSelfieScreen = lazy(
  () => import('./AuthEasySignupSelfieScreen'),
)

const AuthEasySignupSSOScreen = lazy(() => import('./AuthEasySignupSSOScreen'))

const AuthEasySignupUnderageScreen = lazy(
  () => import('./AuthEasySignupUnderageScreen'),
)

const AuthEasySignupVerificationScreen = lazy(
  () => import('./AuthEasySignupVerificationScreen'),
)

const AuthEasySignupWelcomeScreen = lazy(
  () => import('./AuthEasySignupWelcomeScreen'),
)

export {
  AuthEasySignupChildrenWefieScreen,
  AuthEasySignupScreen,
  AuthEasySignupSelfieScreen,
  AuthEasySignupSSOScreen,
  AuthEasySignupUnderageScreen,
  AuthEasySignupVerificationScreen,
  AuthEasySignupWelcomeScreen,
}

export * from './AuthEasySignupScreenParam'
