import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  AUTH_NICKNAME_MAX_LENGTH,
  AUTH_NICKNAME_MIN_LENGTH,
  REGEX_USERNAME,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopUploadTagNicknameModal} from 'pages'
import {GiftShopContentDataNickname, GiftShopUploadType} from 'types'
import {useDidMount} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Chips, Input, Paragraph} from 'common/components'
import {VALIDATION_GIFTSHOP_NICKNAME_UPLOAD} from 'common/validations'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormPeopleTagProps} from './GiftShopTemplateUploadFormProps'

interface StyledPeopleTagProps {
  uploadType?: GiftShopUploadType
}

const StyledPeopleTag = styled.div<StyledPeopleTagProps>`
  width: ${({uploadType}) => (uploadType === 'bulk' ? '100%' : 'unset')};
  margin-left: ${({uploadType}) =>
    uploadType === 'video' ? 0 : convertUnit(25)};
  margin-top: ${convertUnit(25)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-left: ${convertUnit(0)};
    margin-top: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  border-radius: ${convertUnit(8)};
  height: ${convertUnit(42)};
  margin-left: ${convertUnit(8)};
  width: auto;
`

export default function GiftShopTemplateUploadFormPeopleTag({
  form,
  uploadType,
}: GiftShopTemplateUploadFormPeopleTagProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {setValue, watch} = form
  const {peopleTag} = watch()
  const [input, setInput] = useState('')
  const [error, setError] = useState('')
  const [modal, setModal] = useState(false)
  const [tempPeopleTag, setTempPeopleTag] = useState<
    ReadonlyArray<GiftShopContentDataNickname>
  >()

  const disabled = useMemo(
    () =>
      input.match(REGEX_USERNAME) === null ||
      input.length > AUTH_NICKNAME_MAX_LENGTH ||
      input.length < AUTH_NICKNAME_MIN_LENGTH ||
      peopleTag.length >= 10 ||
      peopleTag.some((item) => item.nickname === input),
    [input, peopleTag],
  )

  const handleToggleModal = useCallback(() => {
    setModal((prev) => !prev)
  }, [])

  const handleAddNickname = useCallback(() => {
    const existingTag = tempPeopleTag?.find((tag) => tag.nickname === input)
    const newTags = existingTag
      ? [...peopleTag, existingTag]
      : [...peopleTag, {id: null, nickname: input}]
    setValue('peopleTag', newTags)
    setInput('')
  }, [input, peopleTag, setValue, tempPeopleTag])

  const handleDeleteNickname = useCallback(
    (nick: string) => {
      setValue(
        'peopleTag',
        peopleTag.filter((item) => item.nickname !== nick),
      )
    },
    [peopleTag, setValue],
  )

  const handleRenderChips = useMemo(
    () => (
      <Chips
        data={peopleTag}
        keyExtractor={(item) => item.id + item.nickname}
        labelExtractor={(item) => item.nickname}
        onDelete={(item) => handleDeleteNickname(item.nickname)}
      />
    ),
    [handleDeleteNickname, peopleTag],
  )

  const handleRenderAddNicknameModal = useMemo(
    () => (
      <GiftShopUploadTagNicknameModal
        visible={modal}
        onConfirm={() => {
          handleToggleModal()
          handleAddNickname()
        }}
        toggleModal={handleToggleModal}
      />
    ),
    [handleAddNickname, handleToggleModal, modal],
  )

  useDidMount(() => setTempPeopleTag(peopleTag))

  useEffect(() => {
    if (input.length > 0) {
      if (input.length < AUTH_NICKNAME_MIN_LENGTH) {
        setError(
          translate('auth:validationNicknameMinLength', {
            count: AUTH_NICKNAME_MIN_LENGTH,
          }),
        )
        return
      }
      if (input.length > AUTH_NICKNAME_MAX_LENGTH) {
        setError(
          translate('auth:validationNicknameMaxLength', {
            count: AUTH_NICKNAME_MAX_LENGTH,
          }),
        )
        return
      }
      if (input.match(REGEX_USERNAME) === null) {
        setError(translate('auth:nicknameAllowedCharacters'))
        return
      }
    }
    if (
      input.length === 0 ||
      (input.length <= AUTH_NICKNAME_MAX_LENGTH &&
        input.length >= AUTH_NICKNAME_MIN_LENGTH &&
        input.match(REGEX_USERNAME) !== null)
    ) {
      setError('')
    }
  }, [input, translate])

  return (
    <StyledPeopleTag uploadType={uploadType}>
      {handleRenderAddNicknameModal}
      <Input
        value={input}
        formRules={VALIDATION_GIFTSHOP_NICKNAME_UPLOAD}
        disabled={peopleTag && peopleTag.length >= 10}
        label={translate('giftShop:uploadPeopleTagLabel')}
        placeholder={translate('giftShop:uploadInputPeopleTagPlaceholder')}
        autoComplete={false}
        rightIcon="delete"
        onRightIconClick={() => setInput('')}
        rightIconStyle={{
          visibility: input.length === 0 ? 'hidden' : 'visible',
        }}
        containerStyle={{
          marginTop: mode !== 'website' ? convertUnit(25) : 0,
          width: '100%',
        }}
        onChangeText={setInput}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !disabled) {
            uploadType === 'bulk' ? handleToggleModal() : handleAddNickname()
          }
        }}
        maxLength={AUTH_NICKNAME_MAX_LENGTH + 1}
        rightElement={
          <StyledButton
            label={translate('global:add')}
            disabled={disabled}
            onClick={
              uploadType === 'bulk' ? handleToggleModal : handleAddNickname
            }
          />
        }
      />
      <Paragraph fontWeight="medium" color="danger_5">
        {error}
      </Paragraph>
      {handleRenderChips}
    </StyledPeopleTag>
  )
}
