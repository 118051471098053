import React from 'react'
import styled from 'styled-components'
import {GiftShopContentData} from 'types'
import {getBorder, getCurrencyValue} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import Skeleton from 'react-loading-skeleton'
import {useTheme} from 'themes'
import {translate} from 'i18n'

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  align-items: flex-start;
  margin: ${convertUnit(16)} 0;
`

const StyledContentDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(9)};
  justify-content: flex-start;
  width: 100%;
`

const StyledContentTitleContainer = styled.div`
  width: 100%;
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(76)};
  height: ${convertUnit(76)};
  object-fit: contain;
`

const StyledSkeleton = styled(Skeleton)`
  margin-top: ${convertUnit(5)};
  margin-bottom: ${convertUnit(5)};
`

export interface GiftShopMessageRoomContentItemSingleContentProps {
  loading?: boolean
  data?: GiftShopContentData
  totalContent?: number
  src: string
  onClick?: () => void
}

export default function GiftShopMessageRoomContentItemSingleContent({
  loading,
  data,
  totalContent,
  src,
  onClick,
}: GiftShopMessageRoomContentItemSingleContentProps) {
  const theme = useTheme()

  return (
    <>
      <StyledContentContainer
        onClick={() => {
          !loading && onClick && onClick()
        }}>
        {loading ? (
          <StyledSkeleton
            width={76}
            height={76}
            baseColor={theme.gray_1}
            highlightColor={theme.white_4}
          />
        ) : (
          <>
            <StyledImage src={src} alt={data?.title || ''} />
            <StyledContentDescriptionContainer>
              <StyledContentTitleContainer>
                <Paragraph fontSize="m" fontWeight="bold">
                  {data?.title}
                </Paragraph>
              </StyledContentTitleContainer>
              {totalContent && totalContent > 1 ? (
                <Paragraph fontWeight="medium" color="gray_5">
                  {translate(
                    'messages:notificationResendApprovalAdditionalContent',
                    {total: totalContent - 1},
                  )}
                </Paragraph>
              ) : (
                <></>
              )}
              <Paragraph fontSize="m">
                {getCurrencyValue(data?.price || 0)}
              </Paragraph>
            </StyledContentDescriptionContainer>
          </>
        )}
      </StyledContentContainer>
    </>
  )
}
