import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopTemplateOptionsModal,
  GiftShopTemplateReportModal,
  TemplateAuthAccessModal,
} from 'pages'
import {requestData} from 'services'
import {
  ForkygramPostActionOptions,
  ForkygramPostActionOptionsProfileOther,
  ForkygramReportCategoriesResponse,
} from 'types'
import {
  showModalLimitedAccess,
  showModalReprocessKyc,
  showSnackbar,
  useHistory,
} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {ConfirmationModal} from 'common/components'
import {useSelector} from 'lib/redux'
import {GiftShopTemplateShareModal} from '../../../../gift-shop'
import {ForkygramTemplatePostContent} from '../../../../forkygram'
import {TemplateProfileSelfOptionModal} from '../SelfOptionModal'
import {TemplateProfileDetailItemProps} from './TemplateProfileDetailItemProps'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`

export default function TemplateProfileDetailItem({
  data,
  componentRef,
  onDelete,
  onEdit,
  isFeed,
  isLive,
  self,
  scrollPosition,
  index,
  stateVideoPause,
  stateMute,
  onReported,
  onPin,
  onFollow,
  stateFollowButton,
  setVideoPlaying,
}: TemplateProfileDetailItemProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [selfModal, setSelfModal] = useState<ForkygramPostActionOptions>('none')
  const {height} = useWindowLayout()
  const pause = stateVideoPause[0]
  const mode = useWindowMode()
  const contentHeight =
    mode === 'website' ? height : height - GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT
  const scrollPos = scrollPosition + 0.2 * contentHeight
  const focused = Math.floor(scrollPos / contentHeight) === index
  const active = focused && !pause

  const [modal, setModal] = useState<ForkygramPostActionOptionsProfileOther>(
    'none',
  )
  const [loginModal, setLoginModal] = useState(false)
  const user = useSelector('user')
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')

  const handleToggleModal = useCallback(() => setModal('none'), [])

  const handleBlockAccount = useCallback(() => {
    user &&
      requestData('forkygram_post_block_account', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          blocked_id: data.member_id,
        },
        onRequestSuccess: ({status}) => {
          status === 200 &&
            showSnackbar(translate('forkygram:blockYuserAccSuccess'))
        },
      })
  }, [data.member_id, translate, user])

  const handleReportPost = useCallback(
    (category: ForkygramReportCategoriesResponse, description?: string) => {
      requestData('forkygram_post_report_posts', {
        data: {
          category_id: category.id,
          description,
          reported_id: data.id,
          reported_member_id: data.member_id,
        },
        onRequestReceived: () => handleToggleModal(),
        onRequestFailed: () =>
          showSnackbar(translate('forkygram:reportPostFailed')),
        onRequestSuccess: ({status}) => {
          onReported(data.id)
          showSnackbar(
            translate(
              status === 200
                ? 'forkygram:reportPostSuccess'
                : 'forkygram:reportPostFailed',
            ),
          )
        },
      })
    },
    [data.id, data.member_id, handleToggleModal, onReported, translate],
  )

  const handleFeatureDisabled = useCallback(() => {
    handleToggleModal()
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, handleToggleModal, history, kycStatus, similarStatus])

  const handleShare = useCallback(() => {
    if (
      childStatus === 'UNDERAGE' ||
      !(kycStatus === 'verified' || kycStatus === 'verified_check')
    ) {
      setModal('none')
      handleFeatureDisabled()
      return
    }
    setModal('share')
  }, [childStatus, handleFeatureDisabled, kycStatus])

  const handleSelfShare = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      setSelfModal('none')
      handleFeatureDisabled()
      return
    }
    setSelfModal('share')
  }, [childStatus, handleFeatureDisabled])

  const handleRenderOptionsModal = useMemo(
    () => (
      <GiftShopTemplateOptionsModal
        onShare={handleShare}
        onBlock={() =>
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
            ? handleFeatureDisabled()
            : setModal('block')
        }
        onReport={() =>
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
            ? handleFeatureDisabled()
            : setModal('report')
        }
        toggleModal={handleToggleModal}
      />
    ),
    [handleFeatureDisabled, handleShare, handleToggleModal, kycStatus],
  )

  const handleRenderShareModal = useMemo(
    () => (
      <GiftShopTemplateShareModal
        type="post"
        targetId={data.id}
        subTargetId={data.username}
        caption={data.caption}
        toggleModal={() =>
          user?.id === data.member_id ? setSelfModal('none') : setModal('none')
        }
      />
    ),
    [data.caption, data.id, data.member_id, data.username, user?.id],
  )

  const handleRenderReportModal = useMemo(
    () => (
      <GiftShopTemplateReportModal
        type="post"
        onSubmit={handleReportPost}
        toggleModal={() => {
          handleToggleModal()
          showSnackbar(translate('forkygram:reportCancel'))
        }}
      />
    ),
    [handleReportPost, handleToggleModal, translate],
  )

  const handleRenderBlockYuserModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        text={translate('forkygram:blockYuserAccText')}
        title={translate('forkygram:blockYuserAcc')}
        cancelText={translate('global:cancel')}
        onConfirm={handleBlockAccount}
        toggleModal={handleToggleModal}
      />
    ),
    [handleBlockAccount, handleToggleModal, translate],
  )

  const handleRenderOtherModal = useMemo(() => {
    switch (modal) {
      case 'block':
        return handleRenderBlockYuserModal
      case 'options':
        return handleRenderOptionsModal
      case 'report':
        return handleRenderReportModal
      case 'share':
        return handleRenderShareModal
      case 'none':
        return null
    }
  }, [
    handleRenderBlockYuserModal,
    handleRenderOptionsModal,
    handleRenderReportModal,
    handleRenderShareModal,
    modal,
  ])

  const handleRenderDeleteModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('forkygram:deletePostTitle')}
        text={translate('forkygram:deletePostMessage')}
        onConfirm={onDelete}
        toggleModal={() => setSelfModal('none')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:delete')}
      />
    ),
    [onDelete, translate],
  )

  const handleRenderMoreModal = useMemo(
    () => (
      <TemplateProfileSelfOptionModal
        visible
        isPinned={data.is_pinned}
        toggleModal={() => setSelfModal('none')}
        onClickDelete={() => setSelfModal('delete')}
        onClickEdit={onEdit}
        onClickPin={() => onPin(data.id, data.is_pinned)}
        onClickShare={handleSelfShare}
      />
    ),
    [data.id, data.is_pinned, handleSelfShare, onEdit, onPin],
  )

  const handleRenderSelfModal = useMemo(() => {
    switch (selfModal) {
      case 'more':
        return handleRenderMoreModal
      case 'delete':
        return handleRenderDeleteModal
      case 'share':
        return handleRenderShareModal
      case 'none':
        return null
    }
  }, [
    handleRenderDeleteModal,
    handleRenderMoreModal,
    handleRenderShareModal,
    selfModal,
  ])

  const handleClickMore = useCallback(() => {
    if (!user?.access_token) {
      setLoginModal(true)
      return
    }
    if (self) setSelfModal('more')
    else setModal('options')
  }, [self, user?.access_token])

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplatePostContent
        data={data}
        isFeed={isFeed}
        isLive={isLive}
        onClickMore={handleClickMore}
        active={active}
        focused={focused}
        stateMute={stateMute}
        stateVideoPause={stateVideoPause}
        setVideoPlaying={setVideoPlaying}
        onFollow={onFollow}
        stateFollowButton={stateFollowButton}
      />
    ),
    [
      active,
      data,
      focused,
      handleClickMore,
      isFeed,
      isLive,
      onFollow,
      setVideoPlaying,
      stateFollowButton,
      stateMute,
      stateVideoPause,
    ],
  )

  return (
    <StyledContainer ref={componentRef}>
      {handleRenderLoginModal}
      {handleRenderOtherModal}
      {handleRenderSelfModal}
      {handleRenderContent}
    </StyledContainer>
  )
}
