import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileUpgradeCreatorStatusProps} from './GiftShopProfileUpgradeCreatorStatusProps'

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(520)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(20)};
  text-align: center;
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImg = styled(Image)`
  width: 100%;
  max-width: ${convertUnit(80)};
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopProfileUpgradeCreatorStatus({
  description,
  title,
  image,
  showButton = true,
}: GiftShopProfileUpgradeCreatorStatusProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const img = IMAGE_ASSET('giftshop', image)

  const handleRenderImage = useMemo(
    () => (
      <StyledImgContainer>
        <StyledImg src={img} alt={img} />
      </StyledImgContainer>
    ),
    [img],
  )

  const handleRenderText = useMemo(
    () => (
      <StyledTextContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {title}
        </Paragraph>
        <Paragraph fontSize="m">{description}</Paragraph>
      </StyledTextContainer>
    ),
    [description, title],
  )

  const handleRenderButton = useMemo(
    () =>
      showButton ? (
        <StyledButton
          label={translate('global:backTo', {
            page: translate('forkygram:home'),
          })}
          onClick={() => history.push('giftshop_feed', {})}
        />
      ) : (
        <></>
      ),
    [history, showButton, translate],
  )

  return (
    <StyledContainer>
      {handleRenderImage}
      {handleRenderText}
      {handleRenderButton}
    </StyledContainer>
  )
}
