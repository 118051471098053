import React, {useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {GiftShopPriceFeeModal, GiftShopPricingDetail} from 'pages'
import {Paragraph, Button} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopPricingDetailModalProps} from './GiftShopPricingFormProps'

const StyledSelectionModalContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: ${convertUnit(25)} 0;
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCloseButton = styled(Button)`
  justify-content: center;
  width: 100%;
  height: ${convertUnit(44)};
  margin-bottom: ${convertUnit(20)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(20)};
`

const StyledMobilePricingContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({theme}) => theme.white_2};
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: ${convertUnit(20)};
  overflow-y: scroll;
`

export default function GiftShopPricingDetailModal({
  price,
  toggleModal,
  data,
  ...props
}: GiftShopPricingDetailModalProps) {
  const handlePricingDetail = useMemo(
    () => (
      <>
        <GiftShopPricingDetail price={price} platform="Web" data={data} />
      </>
    ),
    [data, price],
  )

  const handlePricingContainer = useMemo(
    () => (
      <StyledMobilePricingContainer>
        {handlePricingDetail}
      </StyledMobilePricingContainer>
    ),
    [handlePricingDetail],
  )

  return (
    <GiftShopPriceFeeModal {...props}>
      <StyledSelectionModalContainer>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('giftshop:pricingDetail')}
        </StyledParagraph>
        {handlePricingContainer}
        <StyledCloseButton
          label={translate('global:close')}
          width={convertUnit(125)}
          backgroundColor="white_3"
          color="primary_5"
          backgroundHoverColor="info_1"
          onClick={toggleModal}
        />
      </StyledSelectionModalContainer>
    </GiftShopPriceFeeModal>
  )
}
