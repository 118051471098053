import {BlobReader, BlobWriter, ZipWriter} from '@zip.js/zip.js'
import {ZipBlobOptions} from './ZipHelperProps'

export async function zipBlobs({blobs, nameExtractor}: ZipBlobOptions) {
  const zipFileWriter = new BlobWriter()
  const zipWriter = new ZipWriter(zipFileWriter)
  const map = blobs.map((blob, index) => {
    const filename = nameExtractor(blob, index)
    const blobReader = new BlobReader(blob)
    return zipWriter.add(filename, blobReader)
  })
  await Promise.all(map)
  return zipWriter.close()
}
