import React from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_HEADER_HEIGHT,
  GIFT_SHOP_ROOT_MENU_WIDTH,
  IMAGE_ASSET,
} from 'consts'
import {useWindowMode} from 'windows'
import {Icon, Image} from 'common/components'
import convertUnit from 'lib/unit'
import {PolicyRootBottomNavBarProps} from './PolicyTopNavBarProps'
import {PolicyRootTopNavBarItem} from '../TopNavBarItem'
import {WindowModeType} from 'types'

interface ImageProps {
  mode: WindowModeType
}

const StyledSectionNavBar = styled.div`
  display: flex;
  margin: ${convertUnit(25)};
  margin-bottom: 0;
  height: ${convertUnit(64)};
  justify-content: space-between;
  align-items: center;
`

const StyledContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  top: ${convertUnit(89)};
  align-self: center;
  position: absolute;
  z-index: 10;
`

const StyledMenu = styled.div`
  display: flex;
  width: ${convertUnit(GIFT_SHOP_ROOT_MENU_WIDTH)};
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledMenuDetail = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLogoContainer = styled.div`
  margin: ${convertUnit(20)} 0;
  text-align: center;
`
const StyledLogo = styled(Image)<ImageProps>`
  ${({mode}) => ({
    width: mode === 'mobile' ? convertUnit(100) : undefined,
    height: mode === 'mobile' ? convertUnit(30) : undefined,
  })}
  object-fit: contain;
`

export default function GiftShopRootBottomNavBar({
  select,
  data,
  expand,
  onExpand,
}: PolicyRootBottomNavBarProps) {
  const mode = useWindowMode()
  const fotoyulogo = IMAGE_ASSET('giftshop', 'logo-fotoyu-cms.png')
  return (
    <StyledMenuDetail>
      <StyledSectionNavBar>
        <StyledMenu onClick={onExpand}>
          <Icon size={24} type={expand ? 'close' : 'menu'} color="primary_5" />
        </StyledMenu>
        <StyledLogoContainer>
          <StyledLogo src={fotoyulogo} alt="Fotoyu Logo" mode={mode} />
        </StyledLogoContainer>
      </StyledSectionNavBar>
      {expand ? (
        <StyledContainer>
          {data.map(({key, label, onClick}) => (
            <PolicyRootTopNavBarItem
              key={key}
              label={label}
              isSelected={select === key}
              onClick={onClick}
            />
          ))}
        </StyledContainer>
      ) : undefined}
    </StyledMenuDetail>
  )
}
