import {SnackbarHandler, parseShape} from 'types'

let handler: SnackbarHandler | undefined

export function setSnackbarHandler(instance: SnackbarHandler) {
  handler = instance
}

export function showSnackbar(message: string, parseText?: parseShape[]) {
  handler && handler.showSnackbar(message, parseText)
}
