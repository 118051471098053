import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useHistory} from 'utils'
import {ButtonOutline, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'

const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(ButtonOutline)`
  width: 100%;
  border-width: ${convertUnit(2)};
`

const StyledSelfieBtn = styled(ButtonOutline)`
  height: fit-content;
  border-radius: ${convertUnit(28)};
  border-color: ${({theme}) => theme.primary_5};
  width: 100%;
  border-width: ${convertUnit(2)};
`

const StyledSelfieBtnLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledAddSelfieLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
`

export default function GiftShopRoboPetButton() {
  const history = useHistory()
  const {translate} = useTranslation()

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledActionContainer>
          <StyledSelfieBtn
            onClick={() => {
              history.push('giftshop_robopet_retake', {})
            }}>
            <StyledSelfieBtnLabelContainer>
              <StyledAddSelfieLabel>
                <Icon
                  type="face-scan"
                  color="primary_5"
                  size={convertUnit(16)}
                />

                <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
                  {translate('giftShop:retakeKYC')}
                </Paragraph>
              </StyledAddSelfieLabel>
              <Paragraph fontSize="s" color="primary_5">
                {translate('giftShop:addSelfieTooltipDesc')}
              </Paragraph>
            </StyledSelfieBtnLabelContainer>
          </StyledSelfieBtn>
          <StyledButton
            borderColor="gray_5"
            color="gray_5"
            label={translate('global:settings')}
            fontSize="m"
            fontWeight="bold"
            leftIcon={<Icon type="cog" color="gray_5" size={convertUnit(16)} />}
            onClick={() => {
              history.push('giftshop_setting', {})
            }}
          />
        </StyledActionContainer>
      </>
    ),
    [history, translate],
  )

  return handleRenderContent
}
