import React, {useCallback} from 'react'
import styled from 'styled-components'
import {ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledItem = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.gray_1};
  padding: ${convertUnit(8)} ${convertUnit(20)};
`

export default function GiftShopUploadPublishReportDuplicate({
  data,
}: {
  data: {
    id: string
    status: any
    name: string
  }[]
}) {
  const handleRenderItem = useCallback(
    ({name}) => (
      <StyledItem>
        <Paragraph fontSize="m" fontWeight="bold" color="warning_6">
          {name}
        </Paragraph>
      </StyledItem>
    ),
    [],
  )

  return (
    <ListLazy
      data={data}
      keyExtractor={(item) => item.id}
      renderItem={handleRenderItem}
      loadData={() => null}
    />
  )
}
