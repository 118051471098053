import React from 'react'
import styled from 'styled-components'
import {Button, Icon, Paragraph} from 'common/components'
import {GiftShopFloatingButtonExploreProps} from './GiftShopFloatingButtonProps'

const StyledScreenContainer = styled.div`
  position: absolute;
  top: 8;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(Button)`
  flex-direction: column;
  height: auto;
  z-index: 3;
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

export default function GiftShopFloatingButtonExplore({
  title,
  subtitle,
  handleRefresh,
  shouldRenderButton,
}: GiftShopFloatingButtonExploreProps) {
  return shouldRenderButton ? (
    <StyledScreenContainer>
      <StyledButton backgroundColor="white_3" onClick={handleRefresh}>
        <Paragraph color="primary_5" fontSize="m" fontWeight="bold">
          {title}
        </Paragraph>
        <StyledContainer>
          <Icon color="primary_5" type="refresh" />
          <Paragraph color="primary_5" fontSize="s" fontWeight="medium">
            {subtitle}
          </Paragraph>
        </StyledContainer>
      </StyledButton>
    </StyledScreenContainer>
  ) : (
    <></>
  )
}
