import React, {ReactNode, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftshopResendModalState, ObjectState} from 'types'
import {useHistory} from 'utils'
import {Modal} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopTemplateUploadModal} from '../Upload'

export interface GiftshopTemplateUploadResendModalProps {
  resendModalState: ObjectState<GiftshopResendModalState>
  bottomElement?: ReactNode
  total?: number
  success?: number
}

const StyledModal = styled(Modal)`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(325)};
  padding: ${convertUnit(25)};
  margin: ${convertUnit(25)};
`

export default function GiftshopTemplateUploadResendModal({
  resendModalState,
  bottomElement,
  success,
  total,
}: GiftshopTemplateUploadResendModalProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const [resendModal, setResendModal] = resendModalState
  const history = useHistory()

  const handleRenderContent = useMemo(() => {
    switch (resendModal) {
      case 'loading':
        return (
          <GiftShopTemplateUploadModal
            title={translate('giftShop:uploadPublishLoadingTitle')}
            description={translate('giftShop:modalLoadingDescription')}
            progressColor="primary_5"
            success={success || 0}
            total={total || 1}
          />
        )
      case 'failed':
        return (
          <GiftShopTemplateUploadModal
            title={translate('giftShop:resendBatchFailedTitle')}
            description={translate('giftShop:resendBatchFailedDescription')}
            total={5}
            success={4}
            progressColor="danger_5"
            bottomElement={bottomElement}
          />
        )
      default:
        return (
          <GiftShopTemplateUploadModal
            title={translate('giftShop:uploadPublishFinishedTitle')}
            description={translate('giftShop:uploadPublishFinishedDescription')}
            progressColor="success_5"
            success={1}
            total={1}
          />
        )
    }
  }, [bottomElement, resendModal, success, total, translate])

  return (
    <StyledModal
      visible={resendModal !== 'none'}
      onBackdropPress={() => {
        if (resendModal === 'success') {
          setResendModal('none')
          history.replace('giftshop_profile', {self: true}, username)
        }
      }}>
      {handleRenderContent}
    </StyledModal>
  )
}
