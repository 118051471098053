import React from 'react'
import {useTranslation} from 'i18n'
import {GiftShopTemplateUploadModal} from '../../../template'
import {GiftShopUploadPublishModalLoadingProps} from './GiftShopUploadPublishModalLoadingProps'

export default function GiftShopUploadPublishModalLoading({
  success,
  total,
  ...props
}: GiftShopUploadPublishModalLoadingProps) {
  const {translate} = useTranslation()

  return (
    <GiftShopTemplateUploadModal
      {...props}
      title={translate('giftShop:uploadPublishLoadingTitle')}
      description={translate('giftShop:uploadPublishLoadingDescription', {
        success,
        total,
      })}
      note={translate('giftShop:uploadPublishLoadingNote')}
      progressColor="primary_5"
      success={success}
      total={total}
    />
  )
}
