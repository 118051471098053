import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {Marker, InteractiveMapProps} from 'react-map-gl'
import convertUnit from 'lib/unit'
import {Map, Pin} from 'common/components'

export interface TreePlantSelectedLocationProps {
  latitude: number
  longitude: number
}

const StyledMapContainer = styled.div`
  height: ${convertUnit(74)};
  border-radius: ${convertUnit(8)};
  margin-bottom: ${convertUnit(8)};
  width: 100%;
  overflow: hidden;
`

export default function TreePlantSelectedLocation({
  latitude,
  longitude,
}: TreePlantSelectedLocationProps) {
  const mapAttributeState = useState<InteractiveMapProps>({
    latitude,
    longitude,
    dragPan: false,
    dragRotate: false,
    zoom: 15,
  })

  const renderMap = useMemo(
    () =>
      latitude && longitude ? (
        <Map viewportState={mapAttributeState}>
          <Marker longitude={longitude} latitude={latitude}>
            <Pin />
          </Marker>
        </Map>
      ) : null,
    [latitude, longitude, mapAttributeState],
  )

  return <StyledMapContainer>{renderMap}</StyledMapContainer>
}
