import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {UserSimilarAccountData} from 'types'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledModal = styled(Modal)`
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  margin-bottom: ${convertUnit(16)};
`

interface GiftShopTemplateModalMultiAccountConfirmationProps {
  data: UserSimilarAccountData
  onCancel(): void
  onConfirm(): void
}

export default function GiftShopTemplateModalMultiAccountConfirmation({
  data,
  onCancel,
  onConfirm,
}: GiftShopTemplateModalMultiAccountConfirmationProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible onBackdropPress={onCancel}>
      <StyledTitleContainer>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('global:chosen')}
        </StyledParagraph>
        <StyledParagraph fontSize="xl" fontWeight="bold" color="primary_5">
          {`@${data.username}`}
        </StyledParagraph>
      </StyledTitleContainer>
      <StyledParagraph fontSize="l">
        {translate('giftShop:similarMultiAccountConfirmation')}
      </StyledParagraph>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={onCancel}
        />
        <StyledButton
          label={translate('global:activate')}
          onClick={onConfirm}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
