import React, {useCallback, useRef} from 'react'
import styled from 'styled-components'
import {useDidMount, useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'

const StyledContentContainer = styled.div`
  background-color: ${({theme}) => theme.white_2};
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  max-width: ${convertUnit(869)};
`

export default function GiftShopCheckoutContent() {
  const {HOST_WEB_PAYMENT} = process.env
  const history = useHistory()
  const params = useLocation('giftshop_checkout').state
  const frame = useRef<HTMLIFrameElement>()
  const mode = useWindowMode()

  const messageHandler = useCallback(
    ({origin}: MessageEvent) => {
      origin === HOST_WEB_PAYMENT &&
        frame.current?.contentWindow?.postMessage(
          {
            ...params,
          },
          HOST_WEB_PAYMENT,
        )
    },
    [HOST_WEB_PAYMENT, params],
  )

  useDidMount(() => {
    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  })

  const responseHandler = useCallback(
    ({origin, data}: MessageEvent) => {
      if (data === 'Robopet')
        origin === HOST_WEB_PAYMENT && history.push('giftshop_robopet', {})
    },
    [HOST_WEB_PAYMENT, history],
  )

  useDidMount(() => {
    if (params === undefined) {
      history.replace('giftshop_robopet', {})
    }
    window.addEventListener('message', responseHandler)
  })

  return (
    <StyledContentContainer>
      <StyledIframe
        src={HOST_WEB_PAYMENT}
        title="Payment"
        ref={(e) => {
          frame.current = e!
        }}
        style={{
          margin: mode === 'mobile' ? '0' : convertUnit(25),
        }}
      />
    </StyledContentContainer>
  )
}
