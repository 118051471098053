import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {getContentURL, getCurrencyValue, isDiscountPrice} from 'utils'
import {BOUNDING_BOX_BORDER_RADIUS, IMAGE_ASSET} from 'consts'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {CheckboxItem, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopCartContentItemProps} from './GiftShopCartContentItemProps'

interface StyledImageProps {
  mode: WindowModeType
  broken?: boolean
}

const StyledContainer = styled.div`
  ${({theme}) => ({
    borderBottom: `solid ${convertUnit(1)} ${theme.gray_1}`,
  })}
  display: flex;
  flex-direction: row;
  margin: 0 ${convertUnit(25)};
  padding: ${convertUnit(25)} 0;
  align-items: center;
`

const StyledImage = styled(Image)<StyledImageProps>`
  ${({broken, mode}) => ({
    width: broken
      ? convertUnit(64)
      : mode === 'mobile'
      ? convertUnit(80)
      : convertUnit(96),
    objectFit: broken ? 'scale-down' : 'cover',
    padding: broken
      ? mode === 'mobile'
        ? convertUnit(8)
        : convertUnit(16)
      : '0',
  })}
  aspect-ratio: 1;
  border-radius: ${convertUnit(BOUNDING_BOX_BORDER_RADIUS)};
  margin-left: ${convertUnit(25)};
  background-color: ${({theme}) => theme.gray_2};
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${convertUnit(25)};
  word-wrap: break-word;
  overflow-wrap: anywhere;
`

const StyledCartItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const StyledDescriptionCreator = styled(Paragraph)`
  margin-top: ${convertUnit(2)};
`

const StyledDescriptionPrice = styled(Paragraph)`
  margin-top: ${convertUnit(2)};
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledStripPrice = styled(Paragraph)`
  margin-top: ${convertUnit(2)};
  margin-right: ${convertUnit(8)};
  text-decoration: line-through;
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
`

export default function GiftShopCartContentItem({
  data,
  isSelected,
  isFree,
  onSelect,
  onClickItem,
}: GiftShopCartContentItemProps) {
  const mode = useWindowMode()
  const {title, creator_name, price, initial_price} = data
  const [broken, setBroken] = useState(false)
  const brokenImage = IMAGE_ASSET('giftshop', 'broken-image-explore.png')

  const isPriceDiscount = useMemo(() => isDiscountPrice(price, initial_price), [
    initial_price,
    price,
  ])

  const handleRenderPriceFree = useMemo(
    () => (
      <StyledPriceContainer>
        <StyledStripPrice fontSize="m" fontWeight="bold" color="gray_3">
          {getCurrencyValue(price)}
        </StyledStripPrice>
        <StyledDescriptionPrice
          fontSize="m"
          fontWeight="bold"
          color="primary_5">
          {getCurrencyValue(0)}
        </StyledDescriptionPrice>
      </StyledPriceContainer>
    ),
    [price],
  )

  const handleRenderPrice = useMemo(
    () => (
      <StyledPriceContainer>
        {isPriceDiscount && initial_price && (
          <StyledStripPrice fontSize="m" fontWeight="bold" color="gray_3">
            {getCurrencyValue(initial_price)}
          </StyledStripPrice>
        )}
        <StyledDescriptionPrice
          fontSize="m"
          fontWeight="bold"
          color="primary_5">
          {getCurrencyValue(price)}
        </StyledDescriptionPrice>
      </StyledPriceContainer>
    ),
    [initial_price, price, isPriceDiscount],
  )

  return (
    <StyledContainer>
      <StyledCheckboxItem
        isSelected={isSelected}
        onClick={() => onSelect(!isSelected)}
      />
      <StyledCartItemContainer onClick={() => onClickItem(data)}>
        <StyledImage
          mode={mode}
          broken={broken}
          src={getContentURL({url: data.url, type: data.type})}
          alt={title}
          onError={(e) => {
            e.currentTarget.src = brokenImage
            setBroken(true)
          }}
        />
        <StyledDescriptionContainer>
          <Paragraph fontSize="m" fontWeight="bold">
            {title}
          </Paragraph>
          <StyledDescriptionCreator
            fontSize="s"
            fontWeight="medium"
            color="gray_5">
            {creator_name}
          </StyledDescriptionCreator>
          {isFree ? handleRenderPriceFree : handleRenderPrice}
        </StyledDescriptionContainer>
      </StyledCartItemContainer>
    </StyledContainer>
  )
}
