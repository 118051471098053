import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_GET_EXPLORE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useExplorePagination} from 'context'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {
  clearUserCache,
  formatExploreFilterDate,
  showModalReprocessKyc,
  useDefaultState,
  useDidMount,
  useDidUpdate,
  useFirestoreExploreRefresh,
  useHistory,
  useLocation,
} from 'utils'
import {useWindowMode} from 'windows'
import {ActivityIndicator, Button} from 'common/components'
import history from 'lib/history'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopTemplateDetailContent} from '../../../template'
import {GiftShopRootEmpty} from '../../../root'
import {GiftShopExploreContentDetailProps} from './GiftShopExploreContentDetailProps'
import {GiftShopExplorePagination} from '../Pagination'

const StyledScreenContainer = styled.div`
  position: absolute;
  top: 8;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.white_1};
`

const StyledActivityIndicatorContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const StyledActivityIndicator = styled.div`
  border-radius: 50%;
  background-color: ${({theme}) => theme.white_1};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(70)};
`

export default function GiftShopExploreContentDetail({
  isFilter,
}: GiftShopExploreContentDetailProps) {
  const {data: paramData, selectedItemId, isPaginated} = useLocation(
    'giftshop_explore_detail',
  ).state
  const emptyImage = IMAGE_ASSET(
    'giftshop',
    isFilter ? 'roboyu-filter.png' : 'robopet-confused.png',
  )
  const {translate} = useTranslation()
  const exploreLastUpdate = useFirestoreExploreRefresh()
  const {update, reset} = useDispatch()
  const [exploreLastUpdateLocal, setExploreLastUpdateLocal] = useState<number>(
    Math.floor(new Date().getTime() / 1000),
  )
  const [newDate, setNewDate] = useState<Date>(new Date())
  const shouldRenderButton = useMemo(
    () => exploreLastUpdate && exploreLastUpdate > exploreLastUpdateLocal,
    [exploreLastUpdate, exploreLastUpdateLocal],
  )
  const filter = useSelector('exploreFilter')
  const locationHistory = useHistory()
  const {kycStatus} = useSelector('yuserActivationState')
  const {id} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {sensitivityLevel} = useSelector('roboyuSensitivityLevel')
  const {exploreMonthPagination} = useSelector('exploreMonthPaginationState')
  const statePageContext = useExplorePagination()
  const statePage = useDefaultState(1, statePageContext)
  const [currPage, setCurrPage] = statePage
  const [loadPage, setLoadPage] = useState(currPage)
  const mode = useWindowMode()
  const [isLoadData, setIsLoadData] = useState(false)
  const [selectedId, setSelectedId] = useState(selectedItemId)
  const stateData = useState<ReadonlyArray<GiftShopContentData>>(
    paramData || [],
  )
  const [data] = stateData
  const filterYear = useMemo(
    () =>
      exploreMonthPagination?.length && isPaginated && !isFilter && currPage > 1
        ? exploreMonthPagination[currPage - 1].year
        : undefined,
    [currPage, exploreMonthPagination, isFilter, isPaginated],
  )
  const filterMonth = useMemo(
    () =>
      exploreMonthPagination?.length && isPaginated && !isFilter && currPage > 1
        ? exploreMonthPagination[currPage - 1].month
        : undefined,
    [currPage, exploreMonthPagination, isFilter, isPaginated],
  )
  const [isCheckMonth, setIsCheckMonth] = useState(false)

  useDidMount(() => {
    if (history.location.state && history.location.state.data) {
      const state = {...history.location.state}
      state.data = []
      history.replace({...history.location, state})
    }
  })

  const handleCheckMonth = useCallback(async () => {
    if (!isFilter && isCheckMonth) {
      const response = await requestData('giftshop_get_explore_month', {
        actionType: 'fetch',
        params: {
          sensitivity_level: sensitivityLevel,
        },
        onRequestSuccess: ({status, data: {result}}) => {
          if (result && currPage > result.length) {
            setCurrPage(result.length)
            setLoadPage(result.length)
          }
          status === 200 &&
            update('exploreMonthPaginationState', {
              exploreMonthPagination: result,
            })
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        setIsCheckMonth(false)
        return response.data.result
      }
    }
    return []
  }, [currPage, isCheckMonth, isFilter, sensitivityLevel, setCurrPage, update])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const monthResponses = await handleCheckMonth()
      const {
        startDate,
        endDate,
        latitude,
        longitude,
        tags,
        creators,
        filterSensitivity,
      } = filter
      const {startTimestamp, endTimestamp} = formatExploreFilterDate(
        startDate,
        endDate,
      )
      const tagIds = tags.map((item) => item.tag_id)
      const lat = parseFloat(latitude)
      const long = parseFloat(longitude)
      const creatorIds = creators
        ? creators.map((creator) => creator.creator_id)
        : []
      const response = await requestData('giftshop_post_explore', {
        actionType: 'execute',
        data: {
          tags: tagIds,
          page,
          limit,
          is_filter: isFilter,
          is_detail: true,
          latitude: lat,
          longitude: long,
          type: 'all',
          from: startTimestamp,
          to: endTimestamp,
          creator_ids: creatorIds,
          sensitivity_level: isFilter ? filterSensitivity : sensitivityLevel,
          year:
            monthResponses && monthResponses.length
              ? monthResponses[loadPage - 1].year
              : filterYear,
          month:
            monthResponses && monthResponses.length
              ? monthResponses[loadPage - 1].month
              : filterMonth,
        },
        cancelId: SERVICE_CANCELLATION_GET_EXPLORE,
        onRequestReceived: () => setIsLoadData(false),
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.filter((item) => item.is_reported !== true)
        : []
    },
    [
      filter,
      filterMonth,
      filterYear,
      handleCheckMonth,
      isFilter,
      loadPage,
      sensitivityLevel,
    ],
  )

  const handleClearFilter = useCallback(() => {
    reset('exploreFilter')
    location.reload()
  }, [reset])

  const handleClearFilterButton = useMemo(
    () =>
      isFilter && (
        <StyledButtonContainer>
          <Button
            onClick={handleClearFilter}
            label={translate('giftShop:isThisYouFilterEmptyButton')}
          />
        </StyledButtonContainer>
      ),
    [handleClearFilter, isFilter, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImage}
        imageAlt={emptyImage}
        title={translate(
          isFilter
            ? 'giftShop:isThisYouFilterEmptyTitle'
            : 'giftShop:isThisYouEmptyTitle',
        )}
        message={translate(
          isFilter
            ? 'giftShop:isThisYouFilterEmptyMessage'
            : 'giftShop:isThisYouEmptyDesc',
        )}
        messageAlt={
          !isFilter
            ? translate('giftShop:isThisYouEmptyDesc', {
                context: 'Alt',
              })
            : undefined
        }
        bottomElement={handleClearFilterButton}
      />
    ),
    [emptyImage, handleClearFilterButton, isFilter, translate],
  )

  const handleRefresh = useCallback(() => {
    exploreLastUpdate && setExploreLastUpdateLocal(exploreLastUpdate)
    setNewDate(new Date())
    update('giftshopState', {
      exploreRefresher: new Date(),
    })
  }, [exploreLastUpdate, update])

  const handleRenderFloatingButton = useMemo(
    () =>
      shouldRenderButton && (
        <StyledScreenContainer>
          <Button
            label={translate('giftShop:exploreFloatingButtonTitle')}
            backgroundColor="white_3"
            color="primary_5"
            onClick={handleRefresh}
          />
        </StyledScreenContainer>
      ),
    [handleRefresh, shouldRenderButton, translate],
  )

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return locationHistory.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      locationHistory.push('giftshop_robopet_activation_selfie', {})
      return
    }
    locationHistory.push('giftshop_robopet', {})
  }, [kycStatus, locationHistory, similarStatus])

  const handleOnBuyNow = useCallback(
    (item: GiftShopContentData) => {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      const {url, type, price} = item
      const par: GiftShopContentData = {
        ...item,
        url: type ? url[type] : url,
      }
      clearUserCache('cart')
      locationHistory.push('payment_checkout', {
        data: [par],
        firstPurchase: [],
        totalPrice: price,
      })
    },
    [handleFeatureDisabled, kycStatus, locationHistory],
  )

  const handleLogicShowDescriptionActionButtons = useCallback(
    (item: GiftShopContentData) => item.member_id !== id,
    [id],
  )

  const handleRenderPagination = useMemo(
    () =>
      exploreMonthPagination &&
      isPaginated &&
      !isFilter && (
        <StyledPaginationContainer>
          <GiftShopExplorePagination
            statePage={statePage}
            pageCount={exploreMonthPagination.length}
            onClickPage={() => {
              setSelectedId('')
            }}
          />
        </StyledPaginationContainer>
      ),
    [exploreMonthPagination, isFilter, isPaginated, statePage],
  )

  const handleLoadActivityIndicator = useMemo(
    () =>
      isLoadData ? (
        <StyledActivityIndicatorContainer>
          <StyledActivityIndicator>
            <ActivityIndicator iconSize={30} iconColor="primary_5" />
          </StyledActivityIndicator>
        </StyledActivityIndicatorContainer>
      ) : (
        <></>
      ),
    [isLoadData],
  )

  useDidUpdate(() => {
    if (
      data.length === 0 &&
      exploreMonthPagination &&
      exploreMonthPagination.length > 1
    ) {
      setIsLoadData(true)
      setIsCheckMonth(true)
      if (currPage === exploreMonthPagination.length) {
        setCurrPage((prev) => prev - 1)
      }
      handleRefresh()
    }
  }, [data.length])

  useEffect(() => {
    if (currPage === loadPage) return
    const timer = setTimeout(() => {
      setLoadPage(currPage)
      setIsLoadData(true)
      setIsLoadData(true)
      handleRefresh()
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [currPage, handleRefresh, loadPage])

  return (
    <>
      {mode === 'website' && handleRenderPagination}
      {handleLoadActivityIndicator}
      <GiftShopTemplateDetailContent
        stateContentData={stateData}
        showSkipButton
        showPreviewBadge
        showBuyNowButton
        showFavorite
        showTextShadow
        showArchiveButton
        redirectCreator
        refresh={newDate}
        selectedItemId={selectedId}
        requestType="giftshop_delete_explore"
        loadData={handleLoadData}
        listEmptyElement={handleRenderEmpty}
        onBuyNow={handleOnBuyNow}
        showFullOverlay
        showPrice
        darkBackgroundImage
        showDescriptionButton={handleLogicShowDescriptionActionButtons}
      />
      {mode !== 'website' && handleRenderPagination}

      {handleRenderFloatingButton}
    </>
  )
}
