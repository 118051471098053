import React, {useCallback, useMemo, useState, useRef} from 'react'
import {useParams} from 'react-router'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import {TreeMember, WindowModeType} from 'types'
import {useDidMount, useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  ConfirmationModal,
  Icon,
  Image,
  List,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {ManageFarmerSkeleton} from '../containers/skeleton'

interface StyledProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledProps>`
  ${({mode}) => ({
    paddingTop: mode === 'website' ? convertUnit(40) : 0,
  })}
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${({theme}) => theme.white_3};
`

const StyledContentContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ${convertUnit(600)};
  gap: ${convertUnit(32)};
  ${({mode}) => ({
    padding: mode !== 'mobile' ? convertUnit(40) : convertUnit(20),
    borderRadius: mode === 'website' ? convertUnit(20) : 0,
    maxHeight: mode === 'website' ? convertUnit(610) : 'unset',
  })}
  border-radius: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledInfoContainer = styled.div`
  display: flex;
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_3};
`

const StyledListEmptyContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_3};
`

const StyledItemContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(0)};
`

const StyledItemInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: ${convertUnit(20)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(48)};
  height: ${convertUnit(48)};
  border-radius: ${convertUnit(48)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function TreeFototreeManageFarmerScreen() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {alias}: {alias: string} = useParams()
  const {tree_id} = useLocation('tree_fototree_manage_farmer').state
  const [data, setData] = useState<TreeMember[]>([])
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const selectedItemRef = useRef<TreeMember>({
    creator_status: '',
    id: '',
    photo: '',
    username: '',
  })

  const handleDeleteFarmer = useCallback(
    (item: TreeMember) => {
      requestData('tree_delete_farmer', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {member_id: item.id, tree_id},
        onRequestSuccess: ({status}) => {
          if (status === 200)
            setData((prev) => prev.filter((elem) => elem !== item))
        },
      })
    },
    [tree_id],
  )

  const handleLoadData = useCallback(() => {
    setLoading(true)
    requestData('tree_get_farmers', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {tree_id},
      onRequestReceived: () => {
        setLoading(false)
      },
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && result.members.length > 0
          ? setData(result.members)
          : undefined
      },
    })
  }, [tree_id])

  const handleKeyExtractor = useCallback((item: TreeMember) => item.id, [])

  const handleRenderItem = useCallback(
    (item: TreeMember) => (
      <StyledItemContainer>
        <StyledItemInfoContainer>
          <StyledImage src={item.photo} alt="member profile picture" />
          <Paragraph fontSize="m" fontWeight="bold">
            {item.username}
          </Paragraph>
        </StyledItemInfoContainer>
        <Icon
          style={{cursor: 'pointer'}}
          type="close"
          size={16}
          onClick={() => {
            selectedItemRef.current = item
            setModal(true)
          }}
        />
      </StyledItemContainer>
    ),
    [],
  )

  const handleRenderList = useMemo(
    () => (
      <List
        data={data}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        scrollbar={false}
      />
    ),
    [data, handleKeyExtractor, handleRenderItem],
  )

  const handleRenderLoadingIndicator = useMemo(
    () => loading && <ManageFarmerSkeleton rows={5} />,
    [loading],
  )

  const handleRenderModal = useMemo(
    () =>
      selectedItemRef.current && (
        <ConfirmationModal
          onConfirm={() => handleDeleteFarmer(selectedItemRef.current)}
          text={translate('tree:removeFarmerDescription')}
          title={translate('tree:removeFarmer')}
          toggleModal={() => setModal(false)}
          visible={modal}
        />
      ),
    [handleDeleteFarmer, modal, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer mode={mode}>
        <StyledContentContainer mode={mode}>
          <StyledInfoContainer>
            <Icon type="info" size={20} />
            <Paragraph fontSize="s" fontWeight="medium">
              {translate('tree:manageFarmerInfo')}
            </Paragraph>
          </StyledInfoContainer>
          {loading ? (
            handleRenderLoadingIndicator
          ) : data.length > 0 ? (
            handleRenderList
          ) : (
            <StyledListEmptyContainer>
              <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
                {translate('tree:manageFarmerEmpty')}
              </Paragraph>
            </StyledListEmptyContainer>
          )}
          <StyledButton
            label={translate('tree:addFarmer')}
            fontSize="m"
            onClick={() =>
              history.replace(
                'tree_fototree_add_farmer',
                {
                  tree_id,
                  length: data.length,
                },
                alias,
              )
            }
          />
        </StyledContentContainer>
      </StyledContainer>
    ),
    [
      alias,
      data.length,
      handleRenderList,
      handleRenderLoadingIndicator,
      history,
      loading,
      mode,
      translate,
      tree_id,
    ],
  )

  useDidMount(() => handleLoadData())

  return (
    <GiftShopRootScreen
      headerTitle={translate('tree:manageFarmer')}
      showBadgeIcon={false}
      onBackButtonClicked={() => history.goBack()}>
      {handleRenderModal}
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
