import React from 'react'
import {useTranslation} from 'i18n'
import {GiftShopTemplateUploadModal} from '../../../template'
import {GiftShopUploadPublishModalFinishedProps} from './GiftShopUploadPublishModalFinishedProps'

export default function GiftShopUploadPublishModalFinished({
  ...props
}: GiftShopUploadPublishModalFinishedProps) {
  const {translate} = useTranslation()

  return (
    <GiftShopTemplateUploadModal
      {...props}
      title={translate('giftShop:uploadPublishFinishedTitle')}
      description={translate(
        'giftShop:uploadPublishFinishedDescription_plural',
      )}
      progressColor="success_5"
    />
  )
}
