import React, {useMemo} from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadModalProps} from './GiftShopTemplateUploadModalProps'

interface StyledProgressProps {
  progressColor: ThemeColor
  progress: number
}

const StyledProgressContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.gray_1})}
  width: 100%;
  height: ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
  margin: ${convertUnit(20)} 0;
  overflow: hidden;
`

const StyledProgress = styled.div<StyledProgressProps>`
  ${({theme, progress, progressColor}) => ({
    backgroundColor: theme[progressColor],
    width: `${progress * 100}%`,
  })}
  height: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledNote = styled(StyledParagraph)`
  margin-top: ${convertUnit(5)};
`

export default function GiftShopTemplateUploadModal({
  title,
  description,
  note,
  progressColor,
  success,
  total,
  bottomElement,
}: GiftShopTemplateUploadModalProps) {
  const handleRenderProgress = useMemo(
    () => (
      <StyledProgressContainer>
        <StyledProgress
          progress={success / total}
          progressColor={progressColor}
        />
      </StyledProgressContainer>
    ),
    [success, total, progressColor],
  )

  return (
    <>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {title}
      </StyledParagraph>
      {handleRenderProgress}
      {!!description && (
        <StyledParagraph fontSize="l" color="gray_5">
          {description}
        </StyledParagraph>
      )}
      {!!note && (
        <StyledNote fontSize="m" color="gray_3">
          {note}
        </StyledNote>
      )}
      {bottomElement}
    </>
  )
}
