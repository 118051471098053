import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {UseFormMethods} from 'react-hook-form'
import {AUTH_USERNAME_MAX_LENGTH, REGEX_ALLOWED_USERNAME, REGEX_EMOJI} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Input, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {VALIDATION_AUTH_USERNAME} from 'common/validations'
import {AuthFormSimpleRegisterData, ObjectState} from 'types'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(375)};
  display: flex;
  flex-direction: column;
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${convertUnit(20)};
`
const StyledHeaderDescription = styled(Paragraph)`
  text-align: center;
`
const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: ${convertUnit(25)};
  gap: ${convertUnit(8)};
`
const StyledButton = styled(Button)`
  width: 100%;
`

interface TemplateAuthAccessUsernameModalProps {
  stateVisible: ObjectState<boolean>
  stateLoading: ObjectState<boolean>
  form: UseFormMethods<AuthFormSimpleRegisterData>
  onClick(username: string): void
}

export default function TemplateAuthAccessUsernameModal({
  stateVisible,
  stateLoading,
  form,
  onClick,
}: TemplateAuthAccessUsernameModalProps) {
  const {translate} = useTranslation()
  const [visible, setVisible] = stateVisible
  const {watch, errors, setValue} = form
  const {username} = watch()
  const isLoading = stateLoading[0]
  const isDisabled = useMemo(() => (username === '' || errors.username !== undefined
  ), [errors.username, username])

  const handleRenderTitle = useMemo(
    () => (
      <StyledHeaderDescription fontSize="l" fontWeight="bold">
        {translate('auth:registerEasySignupWelcomeMessage')}
      </StyledHeaderDescription>
    ),
    [translate],
  )

  const handleCancel = useCallback(() => {
    setVisible(false)
    setValue('username', '')
  }, [setValue, setVisible])

  const handleRenderForm = useMemo(() => (
    <Input
      name="username"
      form={form}
      autoComplete={false}
      formRules={VALIDATION_AUTH_USERNAME}
      label={translate('global:username')}
      placeholder={translate('auth:registerEasySignupWelcomePlaceholder')}
      containerStyle={{marginTop: convertUnit(25)}}
      rightIcon="delete"
      maxLength={AUTH_USERNAME_MAX_LENGTH}
      allowedCharacters={REGEX_ALLOWED_USERNAME}
      onChangeText={() => {
        setValue('username', username.toLowerCase().replace(REGEX_EMOJI, ''))
      }}
    />
  ), [form, setValue, translate, username])

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        {handleRenderTitle}
        {handleRenderForm}
        <StyledButtonContainer>
          <StyledButton
            label={translate('global:cancel')}
            color="primary_5"
            backgroundColor="white_3"
            onClick={handleCancel} />
          <StyledButton
            label={translate('global:continue')}
            disabled={isDisabled}
            isLoading={isLoading}
            onClick={() => onClick(username)} />
        </StyledButtonContainer>
      </StyledContainer>
    ),
    [handleCancel, handleRenderForm, handleRenderTitle, isDisabled, isLoading, onClick, translate, username],
  )

  return <StyledModal visible={visible}>{handleRenderContent}</StyledModal>
}
