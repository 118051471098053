import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_DEFAULT_FEE_INTERNAL,
  HOST_MAX_FEE_INTERNAL,
  REGEX_NUMBER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {Button, Icon, Input, ModalLoading} from 'common/components'
import {VALIDATION_TREE_HOST_FEE_INTERNAL} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeEditProfileFormStringProps} from './TreeEditProfileFormProps'

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    padding: ${convertUnit(20)};
    border-radius: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeEditProfileFormHostFeeInternal({
  defaultValue,
  stateActiveContent,
}: TreeEditProfileFormStringProps) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)

  const form = useForm<{internalFee: string}>({
    defaultValues: {
      internalFee: defaultValue || '0',
    },
  })
  const {watch, errors} = form
  const {internalFee} = watch()
  const disabled = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      parseInt(internalFee, 10) > HOST_MAX_FEE_INTERNAL ||
      internalFee === defaultValue,
    [errors, internalFee, defaultValue],
  )

  const handlePatchHost = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_hosts', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        internal_creator_fee:
          internalFee.length === 0
            ? HOST_DEFAULT_FEE_INTERNAL
            : parseInt(internalFee, 10),
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        status === 200 && setActiveContent('profile')
      },
    })
  }, [internalFee, setActiveContent])

  const handleRenderForm = useMemo(
    () => (
      <Input
        form={form}
        name="internalFee"
        allowedCharacters={REGEX_NUMBER}
        formRules={VALIDATION_TREE_HOST_FEE_INTERNAL}
        label={translate('tree:editHostFormLabelHostFeeInternal')}
        placeholder={translate('tree:editHostFormPlaceholderHostFee')}
        rightIcon={<Icon type="percentage" size={convertUnit(16)} />}
        max={HOST_MAX_FEE_INTERNAL}
      />
    ),
    [form, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={disabled}
        label={translate('global:save')}
        onClick={handlePatchHost}
      />
    ),
    [disabled, handlePatchHost, translate],
  )

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderForm}
      {handleRenderButton}
    </StyledContainer>
  )
}
