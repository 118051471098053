import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Icon, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopCartAvailableFirstPurchase} from './GiftShopCartAvailableContentHeaderProps'

const StyledFreeContentContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  ${({theme}) => ({backgroundColor: theme.white_3})}
  padding: ${convertUnit(8)} ${convertUnit(20)};
`
const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(20)};
`

const StyledTag = styled.span`
  display: inline;
  color: ${({theme}) => theme.primary_5};
  &:not(:last-child)::after {
    content: ' , ';
  }
`

export default function GiftShopCartAvailableContentHeader({
  tagName,
}: GiftShopCartAvailableFirstPurchase) {
  const {translate} = useTranslation()
  const {kycStatus} = useSelector('yuserActivationState')

  const handleRenderTagName = useMemo(
    () =>
      tagName.map((tag) => (
        <StyledTag key={tag.tag_name}>#{tag.tag_name}</StyledTag>
      )),
    [tagName],
  )

  return tagName.length > 0 && (kycStatus === 'verified' || kycStatus === 'verified_check') ? (
    <StyledFreeContentContainer>
      <Icon type="voucher" color="black" />
      <StyledParagraph fontSize="m" fontWeight="medium">
        {translate('giftShop:freeContentInfoBox')}
        {handleRenderTagName}
      </StyledParagraph>
    </StyledFreeContentContainer>
  ) : null
}
