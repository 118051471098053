export function maskEmail(email: string) {
  const split = email.split('@')
  const splitDomain = email.split('@')[1]
  const domain = splitDomain.split('.')
  return `${
    email.substring(0, 3) +
    new Array(split[0].length - 5).fill('*').join('') +
    split[0].slice(-2)
  }@${
    split[1].substring(0, 1) +
    new Array(domain[0].length - 1).fill('*').join('') +
    domain[0].slice(-1)
  }.${domain[1]}`
}

export function maskPhone(phoneCode: string, phoneNumber: string) {
  let maskedPhone = '+'
  maskedPhone = maskedPhone.concat(`${phoneCode} `)
  const maskedNumber = phoneNumber
    .substring(0, 3)
    .concat('*'.repeat(phoneNumber.length - 5))
    .concat(phoneNumber.substring(phoneNumber.length - 2))
  maskedPhone = maskedPhone.concat(maskedNumber)
  return `
    ${maskedPhone}
    `
}

export function maskPhoneZero(phone) {
  return `
    ${
      phone.substring(0, 2) +
      new Array(phone.length - 4).fill('*').join('') +
      phone.slice(-2)
    }
    `
}
