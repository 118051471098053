import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopTemplateShareModal} from 'pages'
import {showModalReprocessKyc, showSnackbar, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Image, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  width: ${convertUnit(520)};
  padding: ${convertUnit(32)} ${convertUnit(40)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    padding: ${convertUnit(20)};
  }
`

const StyledShareItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: inherit;
`

const StyledTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLine = styled.div`
  width: 100%;
  height: ${convertUnit(1)};
  background-color: ${({theme}) => theme.gray_1};
  margin: ${convertUnit(20)} 0;
`

const StyledImg = styled(Image)`
  margin-top: ${convertUnit(20)};
  object-fit: 'contain';
  width: 100%;
  max-width: ${convertUnit(335)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 100%;
  }
`

const StyledShareIconButton = styled.div`
  width: ${convertUnit(34)};
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({theme}) => theme.primary_5};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  height: ${convertUnit(34)};
`

export default function GiftshopSettingShareProfile() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const imgSrc = IMAGE_ASSET('giftshop', 'feat_sc.png')
  const history = useHistory()
  const [shareModalProfile, setShareModalProfile] = useState(false)
  const [shareModalCreator, setShareModalCreator] = useState(false)
  const {creatorStatus, kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {username} = useSelector('user')!

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, similarStatus])

  const handlePressShare = useCallback(
    (profile: boolean) => {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      if (profile) setShareModalProfile(true)
      else if (creatorStatus === 'verified') setShareModalCreator(true)
      else
        showSnackbar(
          translate('giftShop:shareProfileSearchLinkCreatorOnlySnackbar'),
        )
    },
    [creatorStatus, handleFeatureDisabled, kycStatus, translate],
  )

  const handleRenderShareProfileModal = useMemo(
    () =>
      username &&
      shareModalProfile && (
        <GiftShopTemplateShareModal
          type="profile"
          targetId={username}
          toggleModal={() => setShareModalProfile(false)}
        />
      ),
    [shareModalProfile, username],
  )

  const handleRenderShareCreatorModal = useMemo(
    () =>
      shareModalCreator && (
        <GiftShopTemplateShareModal
          type="creator_link"
          targetId=""
          toggleModal={() => setShareModalCreator(false)}
        />
      ),
    [shareModalCreator],
  )

  const handleRenderShareProfile = useMemo(
    () => (
      <StyledShareItemContainer>
        <StyledTextsContainer>
          <Paragraph fontSize="l" fontWeight="bold">
            {translate('giftShop:shareProfileFotoyu')}
          </Paragraph>
          <Paragraph color="gray_5">
            {translate('giftShop:shareProfileFotoyuDescription')}
          </Paragraph>
        </StyledTextsContainer>

        {mode === 'mobile' ? (
          <StyledShareIconButton onClick={() => handlePressShare(true)}>
            <Icon type="share" color="white_1" size={16} />
          </StyledShareIconButton>
        ) : (
          <StyledButton
            label={translate('global:share')}
            onClick={() => handlePressShare(true)}
          />
        )}
      </StyledShareItemContainer>
    ),
    [handlePressShare, mode, translate],
  )

  const handleRenderShareCreatorLink = useMemo(
    () => (
      <StyledShareItemContainer>
        <StyledTextsContainer>
          <Paragraph fontSize="l" fontWeight="bold">
            {translate('giftShop:shareProfileSearchLink')}
          </Paragraph>
          <Paragraph color="gray_5">
            {translate('giftShop:shareProfileSearchLinkDescription')}
          </Paragraph>
        </StyledTextsContainer>

        {mode === 'mobile' ? (
          <StyledShareIconButton onClick={() => handlePressShare(false)}>
            <Icon type="share" color="white_1" size={16} />
          </StyledShareIconButton>
        ) : (
          <StyledButton
            label={translate('global:share')}
            onClick={() => handlePressShare(false)}
          />
        )}
      </StyledShareItemContainer>
    ),
    [handlePressShare, mode, translate],
  )

  const handleRenderImage = useMemo(() => <StyledImg src={imgSrc} alt="" />, [
    imgSrc,
  ])

  return (
    <StyledContainer>
      {handleRenderShareCreatorModal}
      {handleRenderShareProfileModal}
      {handleRenderShareProfile}
      <StyledLine />
      {handleRenderShareCreatorLink}
      {handleRenderImage}
    </StyledContainer>
  )
}
