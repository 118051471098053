import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_MAX_PRICE,
  HOST_MIN_PRICE,
  REGEX_NUMBER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {formatCurrency, getValueOfCurrency} from 'utils'
import {Button, Input, ModalLoading} from 'common/components'
import {VALIDATION_TREE_MIN_PRICE} from 'common/validations'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeEditProfileFormNumberProps} from './TreeEditProfileFormProps'

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    padding: ${convertUnit(20)};
    border-radius: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeEditProfileFormMinPrice({
  defaultValue,
  stateActiveContent,
}: TreeEditProfileFormNumberProps) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)
  const {maximumPrice} = useSelector('hostState')

  const form = useForm<{minPrice: number}>({
    defaultValues: {
      minPrice: defaultValue || 0,
    },
  })
  const {watch, errors} = form
  const {minPrice} = watch()
  const disabled = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      getValueOfCurrency(minPrice) === defaultValue ||
      getValueOfCurrency(minPrice) > getValueOfCurrency(maximumPrice ?? 0),
    [defaultValue, errors, maximumPrice, minPrice],
  )

  const handlePatchHost = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_hosts', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        minimum_price:
          minPrice.toString().length === 0
            ? HOST_MIN_PRICE
            : getValueOfCurrency(minPrice),
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        status === 200 && setActiveContent('profile')
      },
    })
  }, [minPrice, setActiveContent])

  const handleRenderForm = useMemo(
    () => (
      <Input
        form={form}
        name="minPrice"
        value={!minPrice ? '' : formatCurrency(minPrice)}
        allowedCharacters={REGEX_NUMBER}
        formRules={VALIDATION_TREE_MIN_PRICE(
          getValueOfCurrency(maximumPrice ?? 0),
        )}
        label={translate('tree:editHostFormLabelMinPrice')}
        placeholder={translate('tree:editHostFormPlaceholderMinPrice')}
        leftText={translate('global:idr')}
        max={HOST_MAX_PRICE}
      />
    ),
    [form, maximumPrice, minPrice, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={disabled}
        label={translate('global:save')}
        onClick={handlePatchHost}
      />
    ),
    [disabled, handlePatchHost, translate],
  )

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderForm}
      {handleRenderButton}
    </StyledContainer>
  )
}
