import {lazy} from 'react'

export * from './GiftShopCartDetailScreenParam'

const GiftShopCartScreen = lazy(
  () => import(/* webpackChunkName: 'Cart' */ './GiftShopCartScreen'),
)

const GiftShopCartDetailScreen = lazy(
  () =>
    import(/* webpackChunkName: 'CartDetail' */ './GiftShopCartDetailScreen'),
)
export {GiftShopCartScreen, GiftShopCartDetailScreen}
