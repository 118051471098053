import React, {useMemo} from 'react'
import styled from 'styled-components'
import {AUTH_USER_MIN_AGE, COMPANY_NAME, DOMAIN_NAME} from 'consts'
import {getAuthPrivacy, getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

export interface LegalUnderageUserPolicyContentProps {
  scrollTo: (childRef: number | undefined) => void
}

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(42)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
  padding-top: ${convertUnit(35)};
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalUnderageUserPolicyContent({
  scrollTo,
}: LegalUnderageUserPolicyContentProps) {
  const {translate} = useTranslation()

  const isSection = useMemo(
    () => document.location.href.includes('section'),
    [],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: 'roboto-bold'},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleUnderageUser = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:privacyPolicyChild')}
        </StyledTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:privacyPolicyChildDesc', {
              age: AUTH_USER_MIN_AGE,
              name: COMPANY_NAME,
            })}
          </ParsedText>
          <StyledLink
            href={`mailto:${translate('policy:emailSupport', {
              Domain: DOMAIN_NAME,
            })}`}>
            {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleWhyParent = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildMatter')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildMatterDesc', {
            age: AUTH_USER_MIN_AGE,
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleCategory = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserCategory')}</StyledSubTitle>
        <StyledListUl>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:privacyPolicyChildCategoryChild')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:privacyPolicyChildCategoryChildDesc', {
                age: AUTH_USER_MIN_AGE,
                name: COMPANY_NAME,
              })}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:privacyPolicyChildCategoryParent')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:privacyPolicyChildCategoryParentDesc')}
            </StyledDescription>
          </StyledListItem>
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleCollectedInformation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildInformationCollect')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildInformationCollectDesc', {
            name: COMPANY_NAME,
          })}
          <StyledLink
            href={isSection ? getAuthPrivacy() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(4)}>
            {translate('policy:privacyPolicy')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, scrollTo, translate],
  )

  const handleInformationUsage = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildInformationUse')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildInformationUseDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleInformationSharing = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildInformationShare')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildInformationShareDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRoboyuActivation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildParentConsent')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:privacyPolicyChildParentConsent', {
            name: COMPANY_NAME,
          })}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildParentConsentHasAccountList', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 8}, (_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(
                'policy:privacyPolicyChildParentConsentHasAccountList',
                {
                  context: `${index + 1}`,
                  name: COMPANY_NAME,
                },
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:privacyPolicyChildParentConsentHasNotAccount', {
            name: COMPANY_NAME,
          })}
        </StyledSubTitle>
        <StyledDescription>
          {translate(
            'policy:privacyPolicyChildParentConsentHasNotAccountList',
            {name: COMPANY_NAME},
          )}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 6}, (_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(
                'policy:privacyPolicyChildParentConsentHasNotAccountList',
                {
                  context: `${index + 1}`,
                  age: AUTH_USER_MIN_AGE,
                  name: COMPANY_NAME,
                },
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleSecurity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildSecurity')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildSecurityDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleChoices = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildChoice')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildChoiceDesc', {
            name: COMPANY_NAME,
          })}
          <StyledLink
            href={isSection ? `${getAuthPrivacy()}#penghapusanAkun` : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() =>
              document
                .getElementById('penghapusanAkun')
                ?.scrollIntoView({behavior: 'smooth'})
            }>
            {translate('global:here')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, translate],
  )

  const handleChanges = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildChange')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildChangeDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleContact = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:privacyPolicyChildContact')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:privacyPolicyChildContactDesc')}
          <StyledLink
            href={`mailto:${translate('policy:emailSupport', {
              Domain: DOMAIN_NAME,
            })}`}>
            {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleUnderageUser}
      {handleWhyParent}
      {handleCategory}
      {handleCollectedInformation}
      {handleInformationUsage}
      {handleInformationSharing}
      {handleRoboyuActivation}
      {handleSecurity}
      {handleChoices}
      {handleChanges}
      {handleContact}
    </>
  )
}
