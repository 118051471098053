import React from 'react'
import FototreeFootprintHeader from './FototreeFootprintHeader'
import FototreeFootprintList from './FototreeFootprintList'
import {FototreeFootprintProps} from './FototreeFootprintProps'

export default function FototreeFootprint({
  stateOverlay,
  stateLoading,
  data,
  onLoadData,
}: FototreeFootprintProps) {
  const overlay = stateOverlay[0]

  return (
    <>
      <FototreeFootprintHeader
        stateOverlay={stateOverlay}
        stateLoading={stateLoading}
        data={data}
        onLoadData={onLoadData}
      />
      {overlay === 'full' ? <FototreeFootprintList data={data} /> : null}
    </>
  )
}
