import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {GiftShopTemplateUploadElaborateModal} from '../../../template'
import {GiftShopUploadPublishModalRetryElaborateProps} from './GiftShopUploadPublishModalRetryElaborateProps'

interface StyledSectionButtonProps {
  direction: 'row' | 'column'
}

const StyledSectionButton = styled.div<StyledSectionButtonProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({direction}) => direction};
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledButtonClose = styled(StyledButton)`
  ${({theme}) => ({backgroundColor: theme.white_3})}
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export default function GiftShopUploadPublishModalRetryElaborate({
  statuses,
  onRetry,
  onView,
  stateLoadingRetryButton,
  ...props
}: GiftShopUploadPublishModalRetryElaborateProps) {
  const {translate} = useTranslation()
  const loading = stateLoadingRetryButton[0]

  const {duplicate, fail, success} = useMemo(
    () => ({
      fail: statuses.filter((status) => status === 'failed').length,
      duplicate: statuses.filter((status) => status === 'duplicate').length,
      success: statuses.filter((status) => status === 'success').length,
    }),
    [statuses],
  )

  const handleView = useCallback(() => {
    const defaultTab = duplicate > 0 ? 'duplicate' : 'failed'
    if (onView) onView(defaultTab)
  }, [duplicate, onView])

  const handleRenderFailDescription = useMemo(
    () =>
      fail > 0 && (
        <StyledParagraph color="danger_5" fontSize="l" fontWeight="medium">
          {translate('giftShop:uploadPublishRetryCountFail', {
            count: fail,
          })}
        </StyledParagraph>
      ),
    [fail, translate],
  )
  const handleRenderDuplicateDescription = useMemo(
    () =>
      duplicate > 0 && (
        <StyledParagraph color="warning_6" fontSize="l" fontWeight="medium">
          {translate('giftShop:uploadPublishRetryCountDuplicate', {
            count: duplicate,
          })}
        </StyledParagraph>
      ),
    [duplicate, translate],
  )
  const handleRenderUploadedDescription = useMemo(
    () =>
      success > 0 && (
        <StyledParagraph color="gray_5" fontSize="l">
          {translate(
            duplicate > 0
              ? 'giftShop:uploadPublishRetryCountUploadedDuplicate'
              : 'giftShop:uploadPublishRetryCountUploaded',
            {
              count: success,
            },
          )}
        </StyledParagraph>
      ),
    [duplicate, translate, success],
  )

  const handleRenderCount = useMemo(
    () => (
      <>
        {handleRenderFailDescription}
        {handleRenderDuplicateDescription}
        {handleRenderUploadedDescription}
      </>
    ),
    [
      handleRenderDuplicateDescription,
      handleRenderFailDescription,
      handleRenderUploadedDescription,
    ],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopTemplateUploadElaborateModal
        {...props}
        title={translate('giftShop:uploadPublishRetryTitle')}
        bottomElement={handleRenderCount}
        statuses={statuses}
      />
    ),
    [handleRenderCount, props, statuses, translate],
  )

  const handleRenderDuplicateActions = useMemo(
    () => (
      <StyledSectionButton direction="column">
        <Button
          label={translate('giftShop:uploadPublishRetryViewReport')}
          onClick={handleView}
        />
      </StyledSectionButton>
    ),
    [handleView, translate],
  )

  const handleRenderActions = useMemo(
    () => (
      <StyledSectionButton direction="column">
        <Button
          label={translate('global:retry')}
          onClick={onRetry}
          isLoading={loading}
          disabled={loading}
          disableBackgroundColor="primary_5"
          disabledFontColor="white_1"
        />
        <StyledButtonClose
          label={translate('giftShop:uploadPublishRetryViewReport')}
          onClick={handleView}
          color="primary_5"
        />
      </StyledSectionButton>
    ),
    [handleView, loading, onRetry, translate],
  )

  const handleRenderButton = useMemo(
    () => (fail === 0 ? handleRenderDuplicateActions : handleRenderActions),
    [fail, handleRenderActions, handleRenderDuplicateActions],
  )

  return (
    <>
      {handleRenderContent}
      {handleRenderButton}
    </>
  )
}
