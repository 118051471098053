export function getLocationPermission(
  onPermitted: (result: PermissionStatus) => void,
  onNotPermitted: () => void,
) {
  if (navigator.geolocation && navigator.permissions) {
    navigator.permissions
      .query({name: 'geolocation'})
      .then((result) => onPermitted(result))
  } else {
    onNotPermitted()
  }
}
