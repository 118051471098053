import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled, {CSSProperties} from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, InputArea, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {getBorder, showSnackbar} from 'utils'
import {
  FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE,
  FORKYGRAM_UPLOAD_MAX_RESOLUTION,
  FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION,
  GIFT_SHOP_MAX_UPLOAD_PAYMENT_PROOF_NOTES_CHARACTER,
  GIFT_SHOP_UPLOAD_PAYMENT_PROOF_TYPE,
} from 'consts'
import {useForm} from 'react-hook-form'
import {PaymentProofForm} from 'types'
import {GiftShopTemplatePaymentProofModalProps} from './GiftShopTemplatePaymentProofModalProps'

const StyledConfirmationModal = styled.div<CSSProperties>`
  width: ${({width = convertUnit(275)}) => width};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 5;
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${convertUnit(25)};
  justify-content: right;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(15)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledButtonClose = styled(StyledButton)`
  margin-right: ${convertUnit(25)};
`

const StyledUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
`

const StyledContainer = styled.div`
  ${({theme}) => ({
    background: theme.white_2,
    border: getBorder(1, 'solid', theme.gray_1),
  })}
  height: 100%;
  width: 100%;
  border-radius: ${convertUnit(8)};
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: ${convertUnit(15)};
`

const StyledUploadSubtitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  overflow-x: hidden;
`

export default function GiftShopTemplatePaymentProofModal({
  visible,
  loading,
  toggleModal,
  onSubmit,
}: GiftShopTemplatePaymentProofModalProps) {
  const {translate} = useTranslation()

  const form = useForm<PaymentProofForm>({
    defaultValues: {
      notes: '',
    },
  })
  const {watch, setValue} = form

  const values = watch()
  const {notes} = values

  const [file, setFile] = useState<File>()

  const uploadRef = useRef<HTMLInputElement>(null)

  const handleUploadReport = useCallback(() => {
    if (!file) return

    onSubmit(file, notes)
  }, [file, notes, onSubmit])

  const handleInputNotes = useCallback(
    (text: string) => {
      if (
        notes &&
        notes.length >= GIFT_SHOP_MAX_UPLOAD_PAYMENT_PROOF_NOTES_CHARACTER
      ) {
        showSnackbar(
          translate('giftShop:bioMaxMessage', {
            count: GIFT_SHOP_MAX_UPLOAD_PAYMENT_PROOF_NOTES_CHARACTER,
          }),
        )
      } else setValue('notes', text)
    },
    [notes, setValue, translate],
  )

  const handleCheckImage = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      const img = uploadRef.current.files[0]
      const {size, type} = img

      if (!GIFT_SHOP_UPLOAD_PAYMENT_PROOF_TYPE.includes(type)) {
        showSnackbar(translate('forkygram:uploadInvalidImageFormat'))
        return
      }

      if (type.startsWith('image') && size > FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE) {
        showSnackbar(translate('forkygram:uploadMaxImageSizeMessage'))
        return
      }

      const reader = new FileReader()
      reader.onload = async () => {
        const url = reader.result?.toString()

        if (!url) return

        if (url?.includes('image')) {
          const image = document.createElement('img')

          image.src = url

          image.onload = () => {
            const {width, height} = image

            if (width * height > FORKYGRAM_UPLOAD_MAX_RESOLUTION) {
              showSnackbar(
                translate('forkygram:uploadMaxImageResolutionMessage'),
              )
              return
            }
            if (
              width < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION ||
              height < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION
            ) {
              showSnackbar(
                translate('forkygram:uploadMinImageResolutionMessage'),
              )
            }

            setFile(img)
          }
        }
      }
      reader.readAsDataURL(img)
    }
  }, [translate])

  const handleRenderInputFile = useMemo(
    () => (
      <StyledUploadInput
        type="file"
        ref={uploadRef}
        onChange={handleCheckImage}
        accept={GIFT_SHOP_UPLOAD_PAYMENT_PROOF_TYPE.join(',')}
        disabled={loading}
      />
    ),
    [handleCheckImage, loading],
  )

  return (
    <Modal visible={visible}>
      <StyledConfirmationModal>
        <StyledTitle fontWeight="bold" fontSize="xl">
          {translate('giftShop:paymentProofTitle')}
        </StyledTitle>
        <StyledContainer>
          <Icon type="image-alt" color="primary_5" size={convertUnit(40)} />
          <StyledUploadSubtitle>
            {!file ? (
              <Paragraph color="gray_3">
                {translate('giftShop:paymentWaitOrSendProofUploadSubtitle')}
              </Paragraph>
            ) : (
              <>
                <Paragraph color="gray_3">
                  {translate('global:uploadedImage')}
                  <br />
                </Paragraph>
                <Paragraph
                  fontWeight="bold"
                  style={{
                    width: '100%',
                    fontSize: '15px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}>
                  {file.name}
                </Paragraph>
              </>
            )}
          </StyledUploadSubtitle>
        </StyledContainer>
        <StyledButtonClose
          label={translate('giftShop:uploadAnImage')}
          width={convertUnit(275)}
          backgroundHoverColor="white_3"
          backgroundColor="white_2"
          color="primary_5"
          onClick={() => {
            uploadRef.current?.click()
          }}
        />
        {handleRenderInputFile}
        <InputArea
          name="notes"
          form={form}
          placeholder={translate('global:notes')}
          inputStyle={{height: convertUnit(89)}}
          onChangeText={handleInputNotes}
          maxLength={GIFT_SHOP_MAX_UPLOAD_PAYMENT_PROOF_NOTES_CHARACTER}
        />
        <StyledModalButtonContainer>
          <StyledButtonClose
            label={translate('global:cancel')}
            width={convertUnit(125)}
            backgroundHoverColor="white_3"
            backgroundColor="white_2"
            color="primary_5"
            onClick={() => {
              setFile(undefined)
              toggleModal()
            }}
            disabled={loading}
          />
          <StyledButton
            backgroundColor="primary_5"
            color="white_1"
            label={translate('global:submit')}
            onClick={handleUploadReport}
            fontWeight="medium"
            fontSize="m"
            disabled={typeof file === 'undefined'}
            isLoading={loading}
          />
        </StyledModalButtonContainer>
      </StyledConfirmationModal>
    </Modal>
  )
}
