import styled from 'styled-components'
import React from 'react'
import convertUnit from 'lib/unit'
import {ModalFullscreenProps} from './ModalFullscreenProps'

const StyledModal = styled.div<React.CSSProperties>`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${({borderRadius = convertUnit(16)}) => borderRadius};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

export default function ModalFullscreen({
  visible,
  children,
  ...props
}: ModalFullscreenProps) {
  return visible ? <StyledModal {...props}>{children}</StyledModal> : null
}
