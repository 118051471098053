import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftshopEditProfileSocialContentItemProps} from './GiftShopEditProfileContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(10)};
  border-radius: ${convertUnit(8)};
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
  flex-direction: column;
  width: 49%;
  word-break: break-all;
  margin: 0.5%;
  justify-content: space-between;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledLinkContainer = styled.div`
  display: flex;
  align-self: flex-end;
`

const StyledSpan = styled.span`
  color: ${({theme}) => theme.info_5};
`

export default function GiftshopProfileSocialContentItem({
  item,
  onClickItem,
}: GiftshopEditProfileSocialContentItemProps) {
  const {translate} = useTranslation()
  const capitalizedLabel =
    item.social_type.charAt(0).toUpperCase() + item.social_type.substring(1)

  return (
    <StyledContainer>
      <Paragraph fontSize={'m'} fontWeight="medium">
        {capitalizedLabel}: <StyledSpan>{item.social_value}</StyledSpan>
      </Paragraph>
      <StyledLinkContainer>
        <Paragraph
          fontSize={'s'}
          fontWeight="medium"
          color="primary_5"
          style={{cursor: 'pointer'}}
          onClick={() => onClickItem(item)}>
          {translate('giftShop:socialLink', {context: 'edit'})}
        </Paragraph>
      </StyledLinkContainer>
    </StyledContainer>
  )
}
