import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {AuthTemplateScreen} from '../../template'
import {AuthLoginForm} from '../containers'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`
const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertUnit(25)} 0;
  flex-direction: column;
  justify-content: center;
`

export default function AuthLoginScreen() {
  return (
    <AuthTemplateScreen>
      <StyledContainer>
        <StyledContentContainer>
          <AuthLoginForm />
        </StyledContentContainer>
      </StyledContainer>
    </AuthTemplateScreen>
  )
}
