import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  Button,
  ButtonOutline,
  ConfirmationModal,
  Image,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeHostNotificationContentItemProps} from './TreeHostNotificationContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${convertUnit(16)};
  gap: ${convertUnit(20)};
  padding: ${convertUnit(12)} ${convertUnit(20)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(48)};
  height: ${convertUnit(48)};
  border-radius: 50%;
  object-fit: cover;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRightContainer = styled(StyledTextContainer)`
  gap: ${convertUnit(8)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  height: ${convertUnit(28)};
`

const StyledButton = styled(Button)`
  height: ${convertUnit(28)};
  width: fit-content;
`

const StyledBold = styled.span`
  font-family: 'Roboto-Bold';
`

export default function TreeHostNotificationItem({
  data,
  onAccept,
  onReject,
}: TreeHostNotificationContentItemProps) {
  const {translate} = useTranslation()
  const [modal, setModal] = useState<'accept' | 'reject'>()
  const {host_logo_photo, host_tag_name, status, role} = data

  const handleInvitationText = useMemo(() => {
    switch (role) {
      case 'admin':
        return translate('tree:hostNotificationAdminInvitation')
      case 'independent':
        return translate(
          'tree:hostNotificationOfficialCreatorIndependentInvitation',
        )
      case 'internal':
        return translate(
          'tree:hostNotificationOfficialCreatorInternalInvitation',
        )
    }
    return ''
  }, [role, translate])

  const handleInvitationRole = useMemo(() => {
    switch (role) {
      case 'admin':
        return translate('tree:hostNotificationAdmin')
      case 'independent':
        return translate('tree:hostNotificationOfficialCreatorIndependent')
      case 'internal':
        return translate('tree:hostNotificationOfficialCreatorInternal')
    }
    return ''
  }, [role, translate])

  const handleRenderAcceptConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('global:confirmation')}
        text={translate('tree:hostNotificationInvitationConfirmationAccept')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        toggleModal={() => setModal(undefined)}
        onConfirm={() => onAccept(data)}
      />
    ),
    [data, onAccept, translate],
  )

  const handleRenderRejectConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('global:confirmation')}
        text={translate('tree:hostNotificationInvitationConfirmationReject')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        toggleModal={() => setModal(undefined)}
        onConfirm={() => onReject(data)}
      />
    ),
    [data, onReject, translate],
  )

  const handleRenderPhoto = useMemo(
    () => <StyledImage src={host_logo_photo} alt={host_tag_name} />,
    [host_logo_photo, host_tag_name],
  )

  const handleRenderText = useMemo(
    () => (
      <StyledTextContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {host_tag_name}
        </Paragraph>
        <Paragraph>
          {handleInvitationText}
          <StyledBold>{handleInvitationRole}</StyledBold>
        </Paragraph>
      </StyledTextContainer>
    ),
    [handleInvitationRole, handleInvitationText, host_tag_name],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButtonOutline
          color="primary_5"
          borderColor="primary_5"
          borderWidth={2}
          label={translate('global:accept')}
          onClick={() => setModal('accept')}
        />
        <StyledButtonOutline
          color="gray_5"
          borderColor="gray_5"
          borderWidth={2}
          label={translate('global:reject')}
          onClick={() => setModal('reject')}
        />
      </StyledButtonContainer>
    ),
    [translate],
  )

  const handleRenderAnsweredButton = useMemo(
    () => (
      <StyledButton
        disabled
        label={translate(
          status === 'accepted' ? 'global:accepted' : 'global:rejected',
        )}
      />
    ),
    [status, translate],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'accept':
        return handleRenderAcceptConfirmationModal
      case 'reject':
        return handleRenderRejectConfirmationModal
      default:
        return <></>
    }
  }, [
    handleRenderAcceptConfirmationModal,
    handleRenderRejectConfirmationModal,
    modal,
  ])

  return (
    <>
      {handleRenderModal}
      <StyledContainer>
        {handleRenderPhoto}
        <StyledRightContainer>
          {handleRenderText}
          {status === 'unconfirmed'
            ? handleRenderButtons
            : handleRenderAnsweredButton}
        </StyledRightContainer>
      </StyledContainer>
    </>
  )
}
