import React, {useMemo, useState} from 'react'
import {useGiftshopUploadProperties} from 'utils'
import {GiftShopUploadContentItemProps} from './GiftShopUploadContentItemProps'
import GiftShopUploadContentItemSeries from './GiftShopUploadContentItemSeries'
import {GiftShopTemplateUploadForm} from '../../../template'

export default function GiftShopUploadContentItem({
  data,
  total,
  showDeleteButton = true,
  defaultValues: initialValues,
  uploadType,
  onDeleteVariant,
  onSetParent,
  onDelete,
  onLoadFileSeries,
  toggleModal,
  latestData,
  quickFill,
  ...props
}: GiftShopUploadContentItemProps) {
  const priceState = useState(0)
  const stateSelectId = useState<string>()
  const selectId = stateSelectId[0]
  const {defaultValues, select} = useGiftshopUploadProperties({
    initialValues,
    selectId,
    data,
    uploadType,
  })
  const hasLocationMetadata = useMemo(
    () => !!select.latitude && !!select.longitude,
    [select.latitude, select.longitude],
  )

  const handleRenderSingleUploadImage = useMemo(
    () => (
      <GiftShopUploadContentItemSeries
        data={data}
        total={total}
        select={select}
        showDeleteButton={showDeleteButton}
        stateSelectId={stateSelectId}
        onLoadFileSeries={onLoadFileSeries}
        onSetParent={onSetParent}
        onDeleteVariant={onDeleteVariant}
        onDelete={onDelete}
      />
    ),
    [
      data,
      total,
      select,
      showDeleteButton,
      stateSelectId,
      onLoadFileSeries,
      onSetParent,
      onDeleteVariant,
      onDelete,
    ],
  )

  return (
    <GiftShopTemplateUploadForm
      {...props}
      defaultValues={defaultValues}
      showDeleteButton={showDeleteButton}
      priceState={priceState}
      onDelete={onDelete}
      renderImage={
        uploadType === 'single' ? handleRenderSingleUploadImage : <></>
      }
      toggleModal={toggleModal}
      uploadType={uploadType}
      hasLocationMetadata={hasLocationMetadata}
      latestData={latestData}
      quickFill={quickFill}
    />
  )
}
