import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledFooter = styled.div`
  ${({theme}) => () => ({backgroundColor: theme.gray_1})};
  width: 100%;
  height: ${convertUnit(75)};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function FooterTemplate() {
  return (
    <StyledFooter>
      <Paragraph fontWeight="medium" fontSize="m" color="gray_4">
        {'© 2021 PT. Super Giga Generasi. All Right Reserved'}
      </Paragraph>
    </StyledFooter>
  )
}
