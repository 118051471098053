import React from 'react'
import {MessageMode, ObjectState} from 'types';
import GiftShopMessageListHome from './GiftShopMessageListHome';
import GiftShopMessageListNew from './GiftShopMessageListNew';

export interface GiftShopMessageListProps {
  stateMode: ObjectState<MessageMode>;
}

export default function GiftShopMessageList({stateMode}: GiftShopMessageListProps) {
  const [mode] = stateMode;
  return mode === 'message' ? (
    <GiftShopMessageListHome stateMode={stateMode} />
  ) : (
    <GiftShopMessageListNew />
  );
}
