import {
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import { WindowModeType } from 'types'

export function getWindowMode(
  width: number = window.innerWidth,
): WindowModeType {
  if (width <= WINDOW_MODE_MOBILE_WIDTH) {
    return 'mobile'
  }
  if (width <= WINDOW_MODE_TABLET_WIDTH) {
    return 'tablet'
  }
  return 'website'
}
