import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {Button, Image, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'

interface GiftShopRobopetActivationSelfieReviewProps {
  selfie: string
  onRetry(): void
  onAccept(): void
  isRetake?: boolean
}

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(560)};
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const StyledImageContainer = styled.div`
  width: 80%;
  max-width: ${convertUnit(250)};
  box-sizing: border-box;
  margin: auto;
`
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
`
const StyledBottomContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(560)};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${convertUnit(20)};
  margin: auto;
  background-color: ${({theme}) => theme.white_1};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${convertUnit(20)};
  padding: ${convertUnit(18)} 0 0 0;
`
const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
`
export default function GiftShopRobopetActivationSelfieReview({
  selfie,
  onAccept,
  onRetry,
  isRetake,
}: GiftShopRobopetActivationSelfieReviewProps) {
  const {translate} = useTranslation()
  return (
    <StyledContainer>
      <StyledImageContainer>
        <StyledImage src={selfie} alt="" />
      </StyledImageContainer>
      <StyledBottomContainer>
        <StyledParagraph fontSize="m">
          {translate('giftShop:kycSelfieReviewTitle')}
        </StyledParagraph>
        {isRetake ? (
          <></>
        ) : (
          <StyledParagraph fontWeight="medium" color="danger_5">
            {translate('giftShop:kycSelfieReviewMessage')}
          </StyledParagraph>
        )}
        <StyledButtonContainer>
          <StyledButton
            backgroundHoverColor="white_3"
            backgroundColor="white_2"
            color="primary_5"
            label={translate('giftShop:kycSelfieRetake')}
            onClick={onRetry}
          />
          <StyledButton label={translate('global:yes')} onClick={onAccept} />
        </StyledButtonContainer>
      </StyledBottomContainer>
    </StyledContainer>
  )
}
