import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {GiftShopCheckoutScreenParams} from 'pages'
import {WindowModeType} from 'types'
import {useDidMount, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {Modal, ModalProps} from '../Modal'
import {Paragraph} from '../Paragraph'
import {Icon} from '../Icon'

interface StyledProps {
  mode: WindowModeType
}

const StyledContentContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: center;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  max-width: ${convertUnit(1024)};
`

const StyledClosedButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
  transition: all 200ms;
  font-size: ${convertUnit(30)};
  font-weight: bold;
`

const StyledModalHeader = styled.div<StyledProps>`
  ${({mode}) => ({
    padding: mode === 'website' ? 0 : convertUnit(20),
  })}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledModal = styled(Modal)<StyledProps>`
  ${({mode}) => ({
    width: mode === 'website' ? convertUnit(900) : '100%',
    height: mode === 'website' ? convertUnit(500) : '100%',
    padding: mode === 'website' ? convertUnit(25) : convertUnit(10),
  })}
`

interface PaymentModalProps extends ModalProps {
  params: GiftShopCheckoutScreenParams
  toggleModal: () => void
}

export default function PaymentModal({
  params,
  toggleModal,
  ...props
}: PaymentModalProps) {
  const history = useHistory()
  const frame = useRef<HTMLIFrameElement>()
  const mode = useWindowMode()
  const host = useMemo(() => process.env.HOST_WEB_PAYMENT || '', [])

  const validateOrigin = useCallback(
    (origin: string) => origin.startsWith(host),
    [host],
  )

  const messageHandler = useCallback(
    ({origin}: MessageEvent) => {
      const contentWindow = frame.current?.contentWindow
      const valid = validateOrigin(origin)

      if (valid && contentWindow) {
        contentWindow.postMessage(params, origin)
      }
    },
    [params, validateOrigin],
  )

  const handleMessage = useCallback(() => {
    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  }, [messageHandler])

  const responseHandler = useCallback(
    ({origin, data}: MessageEvent) => {
      const valid = validateOrigin(origin)

      if (!valid) {
        return
      }

      if (data === 'Explore') {
        history.push('giftshop_explore', {})
      } else if (data === 'Cart') {
        toggleModal()
      }
    },
    [history, toggleModal, validateOrigin],
  )

  const handleRefresh = useCallback((e) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useDidMount(() => {
    window.addEventListener('message', responseHandler)
    window.addEventListener('beforeunload', handleRefresh)

    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  })

  useEffect(handleMessage, [handleMessage])

  return (
    <StyledModal mode={mode} {...props}>
      <StyledModalHeader mode={mode}>
        <StyledClosedButton onClick={toggleModal}>
          <Icon type="close" />
        </StyledClosedButton>
        <Paragraph fontSize="xl" fontWeight="medium">
          {translate('global:checkoutPayment')}
        </Paragraph>
      </StyledModalHeader>
      <StyledContentContainer>
        <StyledIframe
          src={host}
          title="Payment"
          ref={(e) => {
            frame.current = e!
          }}
          style={{
            margin: mode === 'mobile' ? '0' : convertUnit(25),
          }}
        />
      </StyledContentContainer>
    </StyledModal>
  )
}
