export function parseComment(
  member_params: {id: string; username: string; photo: string}[],
  comment: string,
) {
  return member_params.reduce((acc, member) => {
    const regex = new RegExp(`{{${member.id}}}`)
    return acc.replace(regex, `@${member.username}`)
  }, comment)
}

export function parseUsername(
  member_params: {id: string; username: string; photo: string}[],
  member_id: string,
) {
  return member_params.reduce((acc, member) => {
    const regex = new RegExp(`${member.id}`)
    return acc.replace(regex, `@${member.username}`)
  }, member_id)
}
