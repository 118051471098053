import React, {useMemo} from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph, ParsedText} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationInviteFarmerProps {
  payload: FirebaseMessageRoomItemPayload['notification_invite_farmer']
}

export default function GiftShopMessageRoomContentItemNotificationInviteFarmer({
  payload: {username, tree_name, owner_username},
}: GiftShopMessageRoomContentItemNotificationInviteFarmerProps) {
  const {translate} = useTranslation()
  const Pattern = (text: string) => useMemo(() => new RegExp(text), [text])

  return (
    <>
      <Paragraph fontSize="m">
        <ParsedText
          parse={[
            {
              pattern: Pattern(username),
              style: {fontFamily: 'roboto-bold'},
            },
          ]}>
          {translate('messages:hiUsername', {username})}
        </ParsedText>
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        <ParsedText
          parse={[
            {
              pattern: Pattern(owner_username),
              style: {fontFamily: 'roboto-bold'},
            },
            {
              pattern: Pattern(tree_name),
              style: {fontFamily: 'roboto-bold'},
            },
          ]}>
          {translate('messages:notificationInviteFarmer', {owner_username, tree_name})}
        </ParsedText>
      </Paragraph>
    </>
  )
}
