import {lazy} from 'react'

const GiftShopProfileCreationScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'CreationDate' */ './GiftShopProfileCreationScreen'
    ),
)
const GiftShopProfileCreationDetailScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'CreationDetail' */ './GiftShopProfileCreationDetailScreen'
    ),
)
const GiftShopProfilePostDetailScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'PostDetail' */ './GiftShopProfilePostDetailScreen'
    ),
)
const GiftShopProfileResendBatchScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'ResendBatch' */ './GiftShopProfileResendBatchScreen'
    ),
)

const GiftShopProfileResendSingleScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'ResendBatch' */ './GiftShopProfileResendSingleScreen'
    ),
)

const GiftShopProfileResendVideoScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'ResendVideo' */ './GiftShopProfileResendVideoScreen'
    ),
)

const GiftShopProfileScreen = lazy(
  () => import(/* webpackChunckName: 'Profile' */ './GiftShopProfileScreen'),
)

const GiftShopProfileSearchCreationScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'Upgrade' */ './GiftshopProfileSearchCreationScreen'
    ),
)

const GiftShopProfileUpgradeCreatorScreen = lazy(
  () =>
    import(
      /* webpackChunckName: 'Upgrade' */ './GiftShopProfileUpgradeCreatorScreen'
    ),
)

export * from './GiftShopProfileCreationScreenParam'
export * from './GiftShopProfileCreationDetailScreenParam'
export * from './GiftShopProfilePostDetailScreenParam'
export * from './GiftShopProfileResendBatchScreenParam'
export * from './GiftShopProfileResendSingleScreenParam'
export * from './GiftShopProfileResendVideoScreenParam'
export * from './GiftShopProfileScreenParam'

export {
  GiftShopProfileCreationScreen,
  GiftShopProfileCreationDetailScreen,
  GiftShopProfilePostDetailScreen,
  GiftShopProfileResendBatchScreen,
  GiftShopProfileResendSingleScreen,
  GiftShopProfileResendVideoScreen,
  GiftShopProfileScreen,
  GiftShopProfileSearchCreationScreen,
  GiftShopProfileUpgradeCreatorScreen,
}
