import React, {useState} from 'react'
import styled from 'styled-components'
import {requestData} from 'services'
import {TreeGetCategoryResponse} from 'types'
import {useDidMount} from 'utils'
import convertUnit from 'lib/unit'
import {TreePlantTypeContentItem} from '../TreeTypeContentItem'
import {TreePlantTypeContentProps} from './TreePlantTypeContentProps'

const StyledContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_3})}
  height: ${convertUnit(80)};
  border-radius: ${convertUnit(16)};
  display: flex;
  flex-direction: row;
  width: 100%;
`

export default function TreePlantTypeContent({
  form,
}: TreePlantTypeContentProps) {
  const [data, setData] = useState<TreeGetCategoryResponse[]>([])
  const {register, setValue} = form

  useDidMount(() => {
    requestData('tree_get_plant_fototree_category', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({status, data: response}) => {
        if (status === 200) {
          setData(response.result)
          setValue('fotoTreeCategory', undefined)
        }
      },
    })
  })

  useDidMount(() => {
    register('fotoTreeCategory')
  })

  return (
    <StyledContainer>
      {data.map((item) => (
        <TreePlantTypeContentItem
          key={item.id}
          form={form}
          data={item}
        />
      ))}
    </StyledContainer>
  )
}
