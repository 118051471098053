import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {LanguageData} from 'types'
import {getLanguages} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Image, Input, List, Paragraph} from 'common/components'
import {LANGUAGES} from 'consts/language'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContentContainerProps {
  width: number
}

const StyledContainer = styled.div`
  padding-top: ${convertUnit(20)};
  display: flex;
  justify-content: center;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding-top: 0;
  }
`

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${convertUnit(580)};
  padding: ${convertUnit(16)} ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: ${({width}) => convertUnit(width)};
  }
`

const StyledInputContainer = styled.div`
  width: 100%;
  padding: ${convertUnit(16)} 0;
`

const StyledListContainer = styled.div`
  height: ${convertUnit(500)};
  display: flex;
`

const StyledLanguageItemContainer = styled.div`
  width: 100%;
  padding: ${convertUnit(15)} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledEmptyImg = styled(Image)`
  object-fit: contain;
  margin-bottom: ${convertUnit(25)};
  max-width: ${convertUnit(200)};
  max-height: ${convertUnit(165)};
`

export default function GiftShopSettingLanguage() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {update} = useDispatch()
  const [data, setData] = useState(LANGUAGES)
  const [query, setQuery] = useState('')
  const emptyImg = IMAGE_ASSET('giftshop', 'not-found.png')
  const {width} = useWindowLayout()
  const user = useSelector('user')

  const handleSelect = useCallback(
    (item: LanguageData) => {
      const {languageTag} = item
      requestData('giftshop_profile_edit_language', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {lang: languageTag},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('languageState', {lang: languageTag})
            update('user', {lang: languageTag})
          }
        },
      })
    },
    [update],
  )

  const handleSearch = useCallback((text: string) => {
    setQuery(text)
    const languages = getLanguages(text)
    setData(languages)
  }, [])

  const handleRenderLanguageItem = useCallback(
    (item: LanguageData) => (
      <StyledLanguageItemContainer onClick={() => handleSelect(item)}>
        <Paragraph fontSize={mode === 'website' ? 'l' : 'm'}>
          {item.title}
        </Paragraph>
        {item.languageTag === user?.lang && (
          <Icon type="check" color="primary_5" size={convertUnit(20)} />
        )}
      </StyledLanguageItemContainer>
    ),
    [handleSelect, mode, user?.lang],
  )

  const handleRenderInput = useMemo(
    () => (
      <StyledInputContainer>
        <Input
          value={query}
          onChangeText={handleSearch}
          leftIcon={
            <Icon type="search" size={convertUnit(16)} color="gray_3" />
          }
          placeholder={translate('global:search')}
        />
      </StyledInputContainer>
    ),
    [handleSearch, query, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <StyledEmptyImg src={emptyImg} alt={emptyImg} />
        <Paragraph fontSize="m">
          {translate('giftShop:languageEmpty')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [emptyImg, translate],
  )

  return (
    <StyledContainer>
      <StyledContentContainer width={width - 40}>
        {handleRenderInput}

        <StyledListContainer>
          <List
            data={data}
            keyExtractor={(item) => item.languageTag}
            renderItem={handleRenderLanguageItem}
            listEmptyElement={handleRenderEmpty}
            scrollbar={false}
          />
        </StyledListContainer>
      </StyledContentContainer>
    </StyledContainer>
  )
}
