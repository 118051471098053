import {ModalLimitedAccessHandler} from 'types'

let handler: ModalLimitedAccessHandler | undefined

export function setModalLimitedAccessHandler(
  instance: ModalLimitedAccessHandler,
) {
  handler = instance
}

export function showModalLimitedAccess() {
  handler && handler.showModalLimitedAccess()
}
