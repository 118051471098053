export const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const REGEX_EMAIL_STRICT = /^[a-zA-Z0-9]+(([-._][a-zA-Z0-9]+)?)+@[a-zA-Z0-9]+([-][a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,})+$/
export const REGEX_PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
export const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-zA-Z])([~`!@#$%^&*()+=[\]\\{}|;':",/<>?a-zA-Z0-9]+)$/
export const REGEX_NUMBER = /\b0*([1-9][0-9]*|0)\b/
export const REGEX_USERNAME = /^[A-Za-z0-9_-]{6,30}$/
export const REGEX_USERNAME_SIMILAR = /^[A-Za-z0-9._-]{6,30}$/
export const REGEX_ALLOWED_USERNAME = /^[A-Za-z0-9._-]$/
export const REGEX_ONE_LETTER = /^.*[a-zA-Z]+.*$/
export const REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY = /^[~`!@#$%^&*()+=[\]\\{}|;':",/<>?a-zA-Z0-9-]+$/
export const REGEX_NO_DASH_UNDERSCORE_PERIOD = /^[^._-]+$/
export const REGEX_NO_SPACE = /^\S*$/
export const REGEX_EMOJI = /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g
export const REGEX_START_END_ALPHANUMERIC = /^[A-Za-z0-9].*[A-Za-z0-9]$/
export const REGEX_HTTPS = /https?:\/\//
export const REGEX_URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const REGEX_ALPHANUMERIC_DOT_AND_SPACE = /^[A-Za-z0-9.\s]*$/
export const REGEX_ALPHANUMERIC_AND_UNDERSCORE = /^[a-zA-Z0-9_]*$/
export const REGEX_ALPHABET_AND_SPACE = /^[a-zA-Z\s]*$/
export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/
export const REGEX_ALPHANUMERIC_SLASH_AND_DOT = /^[a-zA-Z0-9/.-_=]*$/
export const REGEX_START_WITH_CHAR = /^[A-Za-z]/
export const REGEX_USERNAME_CONTAINS_FOTOYU = /f[^a-z]*o[^a-z]*t[^a-z]*o[^a-z]*y[^a-z]*u/
export const REGEX_PASSWORD_MIX = /^(?=.*[a-zA-Z])(?=.*[0-9])/
export const REGEX_LEADING_ZERO = /^0+/g
export const REGEX_THOUSANDS = /\B(?=(\d{3})+(?!\d))/g
export const REGEX_ENCODED_URI = /[!@#$%^&*()]/
export const REGEX_MULTIPLE_NEWLINES = /\n+/g
