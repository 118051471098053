import {useCallback, useMemo, useState} from 'react'

export function useRefresh({
  lastUpdate,
  onRefresh,
}: {
  lastUpdate?: number
  onRefresh: () => void
}) {
  const [lastUpdateLocal, setLastUpdateLocal] = useState<number>(
    Math.floor(new Date().getTime() / 1000),
  )

  const handleRefresh = useCallback(() => {
    onRefresh()
    lastUpdate && setLastUpdateLocal(lastUpdate)
  }, [lastUpdate, onRefresh])

  const shouldRenderButton = useMemo(
    () => !!(lastUpdate && lastUpdate > lastUpdateLocal),
    [lastUpdate, lastUpdateLocal],
  )

  return useMemo(
    () => ({
      handleRefresh,
      shouldRenderButton,
    }),
    [handleRefresh, shouldRenderButton],
  )
}
