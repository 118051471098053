import { useCallback, useMemo } from 'react'
import {
  FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE,
  HOST_IMAGE_TYPE,
  TREE_UPLOAD_PROFILE_MAX_SIZE,
  FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
} from 'consts'
import { translate } from 'i18n'
import { TreeCheckImageFileParams } from 'types'
import { getAndSupplyFileMime, showSnackbar } from 'utils'
import { convertImage } from 'utils/lib/heic2any/heic2anyHelper'

export function useTreeImageFile({
  ref,
  form,
  stateCrop,
  stateTempImage,
  stateLoading,
  isUpload,
  onSaveChanges,
}: TreeCheckImageFileParams) {
  const { setValue } = form
  const setCrop = stateCrop[1]
  const setImage = stateTempImage[1]
  const setLoading = stateLoading[1]

  const handleCheckImage = useCallback(() => {
    if (ref.current?.files?.length) {
      let file = ref.current?.files[0]
      let type = getAndSupplyFileMime(HOST_IMAGE_TYPE, file)

      if (!HOST_IMAGE_TYPE.includes(type)) {
        showSnackbar(translate('giftShop:invalidFormatData'))
        return
      }
      if (file.size > TREE_UPLOAD_PROFILE_MAX_SIZE) {
        showSnackbar(translate('giftShop:editProfileMaxFileSize'))
        return
      }

      const isSpecialImageType = FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE.includes(
        type,
      )

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        const url = reader.result?.toString()

        if (!url) return

        if (
          url.includes('image') ||
          (type.includes('image') && isSpecialImageType)
        ) {
          const img = document.createElement('img')

          setLoading(true)

          if (isSpecialImageType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE

            const { file: newImage, blob } = await convertImage(
              url,
              FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
              file.name
            )
            img.src = URL.createObjectURL(blob)
            file = newImage
          } else {
            img.src = url
          }

          img.onload = () => {
            setImage(file)
            setValue('imgMime', type)
            setValue('imgExtension', type.split('/')[1])
            setCrop(true)
          }
          setLoading(false)
        }
      }
    }
  }, [ref, setCrop, setImage, setLoading, setValue])

  const handleCrop = useCallback(
    (img?: File) => {
      if (img) {
        setLoading(true)
        const reader = new FileReader()
        reader.readAsDataURL(img)
        reader.onload = () => {
          const url = reader.result?.toString()
          const image = document.createElement('img')
          if (url) {
            image.src = url
            image.onload = () => {
              const { width, height } = image
              setValue('imgUrl', url)
              setValue('imgFile', img)
              setValue('imgHeight', height)
              setValue('imgWidth', width)
              setValue('imgSize', img.size)
              onSaveChanges && onSaveChanges()
            }
            image.onerror = () => {
              showSnackbar(translate('global:messageError'))
            }
            image.onabort = () => {
              showSnackbar(translate('global:messageError'))
            }
            isUpload && setLoading(false)
          }
        }
        setCrop(false)
      }
    },
    [isUpload, onSaveChanges, setCrop, setLoading, setValue],
  )

  return useMemo(
    () => ({
      handleCheckImage,
      handleCrop,
    }),
    [handleCheckImage, handleCrop],
  )
}
