import currency from 'currency.js'

export function getCurrencyValue(
  nominal: number,
  code: string = 'IDR',
  locale: string = 'id',
) {
  const formattedValue = Math.abs(nominal).toLocaleString(locale, {
    currency: code,
    currencyDisplay: 'code',
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  // Adjust negative formatting
  if (nominal < 0) {
    const negativeValue = formattedValue.slice(code.length + 1)
    return `${code} -${negativeValue}`
  }
  return formattedValue
}

export function formatCurrency(
  value: currency.Any,
  separator = '.',
  decimal = ',',
  precision = 0,
) {
  return currency(value, {
    symbol: '',
    separator,
    decimal,
    precision,
  }).format()
}

export function getValueOfCurrency(
  value: currency.Any,
  separator = '.',
  decimal = ',',
  precision = 0,
) {
  return currency(value, {
    symbol: '',
    separator,
    decimal,
    precision,
  }).intValue
}

export function isDiscountPrice(
  currPrice: number,
  prevPrice: number | undefined,
) {
  return !!prevPrice && prevPrice > currPrice
}
