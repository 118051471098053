import React, {createElement, FC} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {useLocation} from 'utils'
import {RouteMapEntry, ROUTE_MAP} from './RouteMap'
import {RouteParam} from './RouteParam'

interface MiddlewareParam {
  route: keyof RouteParam
  path: string
  exact: boolean
  component: FC<any>
  relativePath: string
  defaultState: any
}

export default function Middleware({
  route,
  path,
  component,
  exact = true,
  relativePath,
  defaultState,
}: MiddlewareParam) {
  const routeMapEntry = ROUTE_MAP[route] as RouteMapEntry<RouteParam, keyof RouteParam>
  const locationState = useLocation(route).state
  const query = useLocation(route).search

  /** @todo: make better control flow for query */
  if (!locationState && routeMapEntry.defaultState === null) {
    return <Redirect to={{pathname: '/'}} />
  }

  if (location.pathname === path && !history.state && !query) {
    return (
      <Redirect
        to={{
          pathname: path,
          state: defaultState,
        }}
      />
    )
  }

  return (
    <Route key={route} path={`${relativePath}${path}`} exact={exact}>
      {createElement(component, undefined, undefined)}
    </Route>
  )
}
