import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  GiftShopUploadReportStateType,
  GiftshopUploadPublishFormMap,
  GiftshopUploadPublishStatus,
  ObjectState,
  WindowModeType,
} from 'types'
import {useWindowMode} from 'windows'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopUploadPublishReportFail from './GiftShopUploadPublishReportFail'
import GiftShopUploadPublishReportTabView from './GiftShopUploadPublishReportTabView'
import GiftShopUploadPublishReportDuplicate from './GiftShopUploadPublishReportDuplicate'

interface StyledWindowModeProps {
  mode: WindowModeType
}

const StyledReportContainer = styled.div<StyledWindowModeProps>`
  display: flex;
  flex-direction: ${({mode}) => (mode === 'website' ? 'row' : 'column')};
  flex: 1;
`

const StyledSideContainer = styled.div<StyledWindowModeProps>`
  display: flex;
  ${({mode}) =>
    mode === 'website'
      ? {
          flexDirection: 'column',
          width: convertUnit(300),
        }
      : {flexDirection: 'row', justifyContent: 'space-between'}}
  padding: ${convertUnit(40)};
  background-color: ${({theme}) => theme.white_4};
`

const StyledMainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`

const StyledSideUploadCount = styled.div``

const StyledSideListCount = styled.div`
  margin-top: ${convertUnit(8)};
`

const StyledListContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
`

export default function GiftShopUploadPublishReport({
  stateViewReport,
  mapForm,
}: {
  stateViewReport: ObjectState<GiftShopUploadReportStateType>
  mapForm: GiftshopUploadPublishFormMap
}) {
  const {tab} = stateViewReport[0]
  const mode = useWindowMode()
  const {translate} = useTranslation()

  const counts = useMemo(() => {
    const entries = Object.values(mapForm).map(({id, status, item}) => ({
      id,
      name: item.image.file.name,
      status,
    }))
    const failEntries = entries.filter(({status}) => status === 'failed')
    const duplicateEntries = entries.filter(
      ({status}) => status === 'duplicate',
    )

    const statuses = entries.map(
      ({status}) => status,
    ) as GiftshopUploadPublishStatus[]
    const fail = statuses.filter((status) => status === 'failed').length
    const duplicate = statuses.filter((status) => status === 'duplicate').length

    return {
      entries,
      statuses,
      count: statuses.length,
      fail,
      failEntries,
      duplicate,
      duplicateEntries,
    }
  }, [mapForm])

  const {count, duplicate, fail, failEntries, duplicateEntries} = counts

  const handleRenderContent = useMemo(
    () =>
      tab === 'failed' ? (
        <GiftShopUploadPublishReportFail data={failEntries} />
      ) : (
        <GiftShopUploadPublishReportDuplicate data={duplicateEntries} />
      ),
    [duplicateEntries, failEntries, tab],
  )

  return (
    <StyledReportContainer mode={mode}>
      <StyledSideContainer mode={mode}>
        <StyledSideUploadCount>
          <Paragraph fontSize="m" fontWeight="medium" color="gray_5">
            {translate('giftShop:uploadPublishReportDescription')}
          </Paragraph>
          <Paragraph fontSize="xl" fontWeight="bold">
            {count} {translate('global:content')}
          </Paragraph>
        </StyledSideUploadCount>
        <StyledSideListCount>
          <Paragraph color="danger_5" fontSize="m" fontWeight="medium">
            {fail} {translate('global:failed')}
          </Paragraph>
          <Paragraph color="warning_6" fontSize="m" fontWeight="medium">
            {duplicate} {translate('global:duplicate')}
          </Paragraph>
        </StyledSideListCount>
      </StyledSideContainer>
      <StyledMainContainer>
        <GiftShopUploadPublishReportTabView
          stateViewReport={stateViewReport}
          fail={fail}
          duplicate={duplicate}
        />
        <StyledListContainer>{handleRenderContent}</StyledListContainer>
      </StyledMainContainer>
    </StyledReportContainer>
  )
}
