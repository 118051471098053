import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {
  GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {TemplateAuthAccessModal, ValidateKYCModal} from 'pages'
import {useHistory} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Image, ProgressBar} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {ForkygramTemplatePostButtons} from '../Buttons'
import {ForkygramTemplatePostCaptionMobile} from '../CaptionMobile'
import {ForkygramTemplatePostContentProps} from './ForkygramTemplatePostContentProps'

interface StyledRelativeProps {
  width: number
}

interface StyledContentProps {
  height: number
}

interface StyledButtonPlayPauseProps {
  visibility: CSSProperties['visibility']
}

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(StyledRowContainer)`
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: 100%;
  }
`

const StyledRelative = styled.div<StyledRelativeProps>`
  position: relative;
  height: fit-content;
  overflow-x: hidden;
  width: ${({width}) => convertUnit(width)};
  background-color: ${({theme}) => theme.black};
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(600)};
  background-color: ${({theme}) => theme.black};
  margin: auto;
`

const StyledContentImage = styled(Image)<StyledContentProps>`
  max-width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: ${({height}) => convertUnit(height)};
`

const StyledIconContainer = styled.div`
  position: absolute;
  top: ${convertUnit(16)};
  left: ${convertUnit(16)};
  cursor: pointer;
  z-index: 2;
`

const StyledButtonPlayPause = styled.div<StyledButtonPlayPauseProps>`
  ${({visibility}) => ({visibility})}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: ${convertUnit(72)};
  width: ${convertUnit(72)};
  align-items: center;
  justify-content: center;
  display: flex;
`

const StyledVideo = styled(ReactHlsPlayer)<StyledContentProps>`
  max-width: 100%;
  object-fit: scale-down;
  max-height: ${({height}) => convertUnit(height)};
`

const StyledOverlay = styled.div<StyledContentProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: ${({height}) => convertUnit(height)};
  content: ' ';
`

const StyledProgressBarContainer = styled.div`
  bottom: ${convertUnit(4)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  padding: 0 ${convertUnit(8)};
`

export default function ForkygramTemplatePostContent({
  data,
  isFeed = true,
  isLive,
  active,
  focused,
  stateMute,
  stateVideoPause,
  setVideoPlaying,
  toggleTimeout = 500,
  onClickMore,
  onFollow,
  stateFollowButton,
}: ForkygramTemplatePostContentProps) {
  const {width, height} = useWindowLayout()
  const {content_type} = data
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)
  const history = useHistory()
  const [progress, setProgress] = useState(0)
  const [showButton, setShowButton] = useState(false)
  const stateModalLogin = useState(false)
  const [videoPause, setVideoPause] = stateVideoPause
  const [loginModal, setLoginModal] = stateModalLogin
  const [mute, setMute] = stateMute

  const {access_token} = useSelector('user') || {}
  const timestamp = useRef(new Date().getTime())
  const [valKycModal, setValKycModal] = useState(false)

  const handleHideButton = useCallback(() => {
    const now = new Date().getTime()
    const dif = now - timestamp.current

    if (dif >= toggleTimeout) {
      setShowButton(false)
    }
  }, [toggleTimeout])

  const handleToggleVideo = useCallback(() => {
    timestamp.current = new Date().getTime()

    setVideoPause((previous) => !previous)
    setShowButton(true)

    setTimeout(handleHideButton, toggleTimeout)
  }, [toggleTimeout, setVideoPause, handleHideButton])

  const handleVideoTimeUpdate = useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >((event) => {
    const {currentTime, duration} = event.currentTarget
    const extra = (currentTime / duration) * 1

    setProgress(Math.min(100, (currentTime + extra) / duration) * 100)
  }, [])

  const handleBlur = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [])

  const handleFocus = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [])

  const handleFocusEvent = useCallback(() => {
    if (content_type === 'video' && videoRef.current && active) {
      window.addEventListener('blur', handleBlur)
      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('blur', handleBlur)
        window.removeEventListener('focus', handleFocus)
      }
    }

    return undefined
  }, [active, content_type, handleBlur, handleFocus])

  const handlePressClickMore = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    if (onClickMore) onClickMore()
  }, [access_token, onClickMore, setLoginModal])

  const handleRenderRightButtons = useMemo(
    () => (
      <ForkygramTemplatePostButtons
        ownerId={data.member_id}
        postId={data.id}
        username={data.username}
        photo={data.photo}
        member_id={data.member_id}
        creator_status={data.creator_status}
        data={data}
        isFeed={isFeed}
        mute={mute}
        handleShowModal={() => setLoginModal(true)}
        onPressMute={() => setMute((prev) => !prev)}
        onClickMore={handlePressClickMore}
        allowMusic
      />
    ),
    [data, handlePressClickMore, isFeed, mute, setLoginModal, setMute],
  )

  const handleRenderMobileCaption = useMemo(
    () => (
      <ForkygramTemplatePostCaptionMobile
        data={data}
        isLive={isLive}
        onFollow={() => {
          if (access_token) {
            onFollow()
          } else {
            setLoginModal(true)
          }
        }}
        stateFollowButton={stateFollowButton}
      />
    ),
    [access_token, data, isLive, onFollow, setLoginModal, stateFollowButton],
  )

  const handleRenderValidateKycModal = useMemo(
    () => (
      <ValidateKYCModal
        toggleModal={() => setValKycModal((prev) => !prev)}
        visible={valKycModal}
      />
    ),
    [valKycModal],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  const handleRenderBackIcon = useMemo(
    () =>
      !isFeed && (
        <StyledIconContainer>
          <Icon
            type="back"
            size={convertUnit(24)}
            color="white_1"
            onClick={() => history.goBack()}
          />
        </StyledIconContainer>
      ),
    [history, isFeed],
  )

  const handleRenderOverlay = useMemo(
    () => (
      <StyledOverlay
        height={mode === 'website' ? height - 5 : height - 69}
        onClick={() => {
          content_type === 'video' && handleToggleVideo()
        }}
      />
    ),
    [content_type, handleToggleVideo, height, mode],
  )

  const handleRenderContent = useMemo(
    () =>
      content_type === 'photo' ? (
        <StyledContentImage
          src={data.url}
          alt=""
          height={mode === 'website' ? height - 5 : height - 69}
        />
      ) : (
        <>
          <StyledVideo
            loop
            id={data.id}
            src={data.url}
            height={mode === 'website' ? height - 5 : height - 69}
            playerRef={videoRef}
            muted={mute}
            onTimeUpdate={handleVideoTimeUpdate}
          />
          <StyledButtonPlayPause visibility={showButton ? 'visible' : 'hidden'}>
            <Icon
              type={videoPause ? 'pause' : 'play'}
              size={convertUnit(56)}
              color="white_1"
            />
          </StyledButtonPlayPause>
        </>
      ),
    [
      data.id,
      data.url,
      content_type,
      height,
      mode,
      mute,
      videoPause,
      showButton,
      handleVideoTimeUpdate,
    ],
  )

  const handleRenderProgressBar = useMemo(
    () => (
      <StyledProgressBarContainer>
        <ProgressBar progress={progress} />
      </StyledProgressBarContainer>
    ),
    [progress],
  )

  useEffect(handleFocusEvent, [handleFocusEvent])

  useEffect(() => {
    if (!videoRef.current) {
      if (setVideoPlaying) setVideoPlaying(false)
    } else if (videoRef.current) {
      if (active) {
        if (content_type === 'video') {
          setTimeout(() => {
            if (setVideoPlaying) setVideoPlaying(true)
          }, 100)
        }
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [active, focused, content_type, setVideoPlaying])

  useEffect(() => {
    if (videoRef.current && !focused && setVideoPlaying) {
      videoRef.current.currentTime = 0
    }
  }, [focused, content_type, setVideoPlaying])

  return (
    <StyledContainer>
      <StyledRelative width={width}>
        <StyledContentContainer
          height={
            mode === 'website'
              ? height
              : height - GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT
          }>
          {handleRenderValidateKycModal}
          {handleRenderBackIcon}
          {handleRenderContent}
          {handleRenderOverlay}
          {handleRenderMobileCaption}
          {handleRenderRightButtons}
          {handleRenderModalLogin}
          {handleRenderProgressBar}
        </StyledContentContainer>
      </StyledRelative>
    </StyledContainer>
  )
}
