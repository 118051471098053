import React, {useCallback} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {GiftShopContentData} from 'types'
import {GIFT_SHOP_ROOT_CONTENT_PADDING} from 'consts'
import {ListMasonryLazy} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateMasonryProps} from './GiftShopTemplateMasonryProps'
import {GiftShopTemplateMasonryItem} from '../MasonryItem'

export default function GiftShopTemplateMasonry({
  stateData,
  toggleSelect = false,
  showResBadge = false,
  selected,
  cache,
  refresh,
  onClickItem,
  search,
  scrollbar,
  showPurchaseStatusBadge,
  showUnwishlistBadge,
  showFoundConfirmed,
  isSelected,
  onSelectRule,
  ...props
}: GiftShopTemplateMasonryProps) {
  const handleLoadLayout = useCallback(
    (item: GiftShopContentData) => item.resolution,
    [],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <GiftShopTemplateMasonryItem
        showResBadge={showResBadge}
        item={item}
        toggleSelect={toggleSelect}
        isSelected={
          isSelected ? isSelected(item.content_id) : selected?.includes(item)
        }
        onClickItem={onClickItem}
        showPurchaseStatusBadge={showPurchaseStatusBadge}
        showUnwishlistBadge={showUnwishlistBadge}
        showFoundConfirmed={showFoundConfirmed}
        onSelectRule={onSelectRule}
      />
    ),
    [
      showResBadge,
      toggleSelect,
      isSelected,
      selected,
      onClickItem,
      showPurchaseStatusBadge,
      showUnwishlistBadge,
      showFoundConfirmed,
      onSelectRule,
    ],
  )

  const handleRenderMasonry = useCallback(
    ({height, width}: Size) => (
      <ListMasonryLazy
        {...props}
        refresh={refresh}
        height={height}
        width={width}
        cache={cache}
        stateData={stateData}
        loadLayout={handleLoadLayout}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        style={{
          padding: convertUnit(GIFT_SHOP_ROOT_CONTENT_PADDING),
        }}
        scrollbar={scrollbar}
        search={search}
      />
    ),
    [
      props,
      refresh,
      cache,
      stateData,
      handleLoadLayout,
      handleKeyExtractor,
      handleRenderItem,
      scrollbar,
      search,
    ],
  )

  return <AutoSizer>{handleRenderMasonry}</AutoSizer>
}
