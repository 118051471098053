import {ExplorePaginationHandler} from 'types'

let handler: ExplorePaginationHandler | undefined

export function setExplorePaginationHandler(
  instance: ExplorePaginationHandler,
) {
  handler = instance
}

export function resetStateExplorePagination() {
  handler && handler.resetStateExplorePagination()
}
