import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopProfileResendVideoItem} from 'pages'
import {getFileSize} from 'utils'
import {ActivityIndicator, Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopResendRevisionImageItemProps} from './GiftShopResendRevisionImageItemProps'

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledVersionContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: ${convertUnit(12)} 0;
  margin-bottom: ${convertUnit(20)};
`

const StyledImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`

const StyledInfoContainer = styled(StyledColumn)`
  padding: ${convertUnit(12)};
  gap: ${convertUnit(2)};
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_2};
`

const StyledImageContainer = styled.div`
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(4)};
  text-align: center;
  aspect-ratio: 1;
  width: 100%;
  background-color: ${({theme}) => theme.white_3};
  display: flex;
`

const StyledAddHiresPlaceholder = styled(StyledColumn)`
  gap: ${convertUnit(4)};
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export default function GiftShopResendRevisionImageItem({
  date,
  src,
  title,
  duration,
  size,
  contentType,
  versionTitle,
  isNewContent,
  resolution,
  loadingImg,
}: GiftShopResendRevisionImageItemProps) {
  const {translate} = useTranslation()

  const handleRenderTitle = useMemo(
    () => (
      <StyledVersionContainer>
        <Paragraph fontSize="m" fontWeight="medium" color="primary_5">
          {versionTitle}
        </Paragraph>
      </StyledVersionContainer>
    ),
    [versionTitle],
  )

  const handleRenderImageItem = useMemo(
    () => (
      <StyledImageContainer>
        {loadingImg ? (
          <ActivityIndicator iconColor="gray_5" iconSize={30} />
        ) : src && title && date ? (
          <StyledImage src={src} alt={title} />
        ) : (
          <StyledAddHiresPlaceholder>
            <Icon type="image" size={convertUnit(32)} color="gray_3" />
            <Paragraph fontWeight="medium" color="gray_3">
              {translate('giftShop:resendRevisionImagePlaceholder')}
            </Paragraph>
          </StyledAddHiresPlaceholder>
        )}
      </StyledImageContainer>
    ),
    [date, loadingImg, src, title, translate],
  )

  const handleRenderFileName = useMemo(
    () => (
      <Paragraph fontWeight="bold" color={title ? 'primary_5' : 'gray_5'}>
        {title ?? translate('giftShop:resendBatchNoFileName')}
      </Paragraph>
    ),
    [title, translate],
  )

  const handleRenderResolutionAndSize = useMemo(
    () => (
      <Paragraph fontWeight="medium" color="gray_5">
        {resolution && size
          ? `${resolution.width} x ${resolution.height} • ${getFileSize(size)}`
          : `${translate('giftShop:resendBatchNoResolution')} • ${translate(
              'giftShop:resendBatchNoFileSize',
            )}`}
      </Paragraph>
    ),
    [resolution, size, translate],
  )

  const handleRenderDate = useMemo(
    () => (
      <Paragraph color="gray_4">
        {date ?? translate('giftShop:resendBatchNoDate')}
      </Paragraph>
    ),
    [date, translate],
  )

  const handleRenderImageInfo = useMemo(
    () => (
      <StyledInfoContainer>
        {handleRenderFileName}
        {handleRenderResolutionAndSize}
        {handleRenderDate}
      </StyledInfoContainer>
    ),
    [handleRenderDate, handleRenderFileName, handleRenderResolutionAndSize],
  )

  const handleRenderImage = useMemo(
    () => (
      <>
        {handleRenderImageItem}
        {handleRenderImageInfo}
      </>
    ),
    [handleRenderImageInfo, handleRenderImageItem],
  )

  const handleRenderVideo = useMemo(
    () => (
      <GiftShopProfileResendVideoItem
        date={date}
        src={src}
        title={title}
        duration={duration}
        isNewContent={isNewContent}
        size={size}
      />
    ),
    [date, duration, isNewContent, size, src, title],
  )

  return (
    <StyledColumn>
      {handleRenderTitle}
      {contentType === 'photo' ? handleRenderImage : handleRenderVideo}
    </StyledColumn>
  )
}
