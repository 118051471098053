import React, {useMemo} from 'react'
import styled from 'styled-components'
import {AVATAR_SIZE_CHAT} from 'consts'
import {
  FirebaseMessageRoomItem,
  FirebaseMessageRoomMemberItem,
  FirebaseMessageRoomScreenParam,
  FirebaseMessageRoomType,
} from 'types'
import {useFirebaseUser, useHistory, useMessageContent} from 'utils'
import {Avatar, IconVerifiedBadge, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: ${convertUnit(16)} ${convertUnit(20)};
  cursor: pointer;
`
const StyledHeader = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`
const StyledUserData = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`
const StyledDateTime = styled.div`
  margin-left: ${convertUnit(10)};
`
const StyledMessagePreview = styled(Paragraph)`
  height: ${convertUnit(15)};
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledUsername = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`
const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1;
  margin-right: ${convertUnit(15)};
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`
const StyledMessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  word-wrap: break-word;
  margin-top: ${convertUnit(4)};
`
const StyledChatIndicator = styled.div`
  width: ${convertUnit(24)};
  height: ${convertUnit(24)};
  border-radius: ${convertUnit(24)};
  background-color: ${({theme}) => theme.warning_5};
`

export interface GiftShopMessageListItemForkygramProps {
  members: FirebaseMessageRoomMemberItem[]
  messages: FirebaseMessageRoomItem
  roomId: string
  roomType: FirebaseMessageRoomType
  onClick?({
    participantId,
    roomPhoto,
    roomName,
  }: FirebaseMessageRoomScreenParam): void
  hasNewMessage: boolean
  search: string
}
export default function GiftShopMessageListItemForkygram({
  members,
  messages,
  roomId,
  roomType,
  hasNewMessage,
  search,
}: GiftShopMessageListItemForkygramProps) {
  const {id} = useSelector('user')!
  const history = useHistory()
  const participant = useMemo(() => members.find((item) => item.id !== id), [
    id,
    members,
  ])
  const {data} = useFirebaseUser({id: participant?.id || ''})
  const {photo = '', nickname = '', username = ''} = data || {}
  const {description, time} = useMessageContent({message: messages})

  const name = useMemo(
    () => (participant?.role === 'account' ? nickname : username) || '-',
    [nickname, participant?.role, username],
  )

  const isValid = useMemo(
    () => name.toLowerCase().includes(search.toLowerCase()),
    [name, search],
  );

  const handleRenderBadge = useMemo(
    () =>
      data && data.creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [data],
  )

  return participant && data && isValid? (
    <StyledContainer
      onClick={() =>
        history.push('giftshop_message_room', {
          participantId: participant.id,
          roomType,
          roomId,
          roomName: name,
          roomPhoto: photo,
        })
      }>
      <StyledAvatar
        containerStyle={{marginRight: 15}}
        src={photo}
        alt="Avatar"
        size={AVATAR_SIZE_CHAT}
      />
      <StyledUserData>
        <StyledHeader>
          <StyledUsername>
            <Paragraph fontSize="m" fontWeight="bold">
              {name}
            </Paragraph>
            {handleRenderBadge}
          </StyledUsername>
          <StyledDateTime>
            <Paragraph color="gray_3">{time}</Paragraph>
          </StyledDateTime>
        </StyledHeader>
        <StyledMessageContainer>
          <StyledMessagePreview>{description}</StyledMessagePreview>
          {hasNewMessage && <StyledChatIndicator />}
        </StyledMessageContainer>
      </StyledUserData>
    </StyledContainer>
  ) : null
}
