import React from 'react'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_BULK_LIMIT} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {DragDrop} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadDropzoneBulkProps} from './GiftShopUploadDropzoneProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
`

export default function GiftShopUploadDropzoneBulk({
  onUploadBulk,
  validator,
  onClickBulk,
}: GiftShopUploadDropzoneBulkProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer onClick={onClickBulk}>
      <DragDrop
        icon="multi-image"
        header={translate('giftShop:uploadModeBulkHeader')}
        description={translate('giftShop:uploadModeBulkDescription', {
          max: GIFTSHOP_UPLOAD_BULK_LIMIT,
        })}
        onDrop={(files) => onUploadBulk(files)}
        validator={validator}
      />
    </StyledContainer>
  )
}
