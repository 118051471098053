import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  INITIAL_PRICING_DATA,
  SERVICE_CANCELLATION_GET_PRICE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {WindowModeType} from 'types'
import {getCurrencyValue} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopPricingFormProps} from './GiftShopPricingFormProps'
import GiftShopPricingDetailModal from './GiftShopPricingDetailModal'
import GiftShopPricingDetail from './GiftShopPricingDetailForm'

interface StyledProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledProps>`
  ${({mode}) =>
    mode !== 'website' && {
      paddingLeft: convertUnit(15),
      paddingTop: convertUnit(15),
    }}
  background-color: ${({theme}) => theme.white_2};
  display: flex;
  flex-direction: column;
  border: ${convertUnit(1)} solid ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(8)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(25)};
  }
`

const StyledInfoIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
`

const StyledPayableContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(5)};
`

const StyledInfoDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(5)};
  margin-bottom: ${convertUnit(15)};
`

const StyledTitleParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  margin-left: ${convertUnit(20)};
  margin-bottom: ${convertUnit(5)};
  padding: ${convertUnit(2)};
`

export default function GiftShopPricingForm({
  price,
  priceState,
  tagId,
}: GiftShopPricingFormProps) {
  const {translate} = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [data, setData] = useState(INITIAL_PRICING_DATA)
  const setUserPrices = priceState[1]
  const mode = useWindowMode()

  const handlePriceForm = useCallback(() => {
    if (price > 0) {
      requestData('giftshop_get_transaction_fee', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_GET_PRICE,
        params: {price, tag_id: tagId},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setData(result)
            setUserPrices(result.price.web.final.nominal)
          }
        },
      })
    } else {
      setData(INITIAL_PRICING_DATA)
    }
  }, [price, setUserPrices, tagId])

  useEffect(() => {
    const debounce = setTimeout(() => {
      handlePriceForm()
    }, 300)
    return () => clearTimeout(debounce)
  }, [handlePriceForm])

  return (
    <StyledContainer mode={mode}>
      {mode !== 'website' ? (
        <>
          <Paragraph fontWeight="medium" color="gray_3">
            {translate('giftShop:collectorPay')}
          </Paragraph>
          <StyledPayableContainer>
            <Paragraph fontWeight="bold" fontSize="m">
              {getCurrencyValue(data.price.web.final.nominal)}
            </Paragraph>
          </StyledPayableContainer>
          <StyledInfoDetails>
            <Paragraph fontSize="xs" fontWeight="medium" color="primary_5">
              {translate('giftShop:priceEarn')}
              {getCurrencyValue(data.price.web.net_creator.nominal)}
            </Paragraph>
            <StyledInfoIconContainer>
              <Icon
                type="info"
                color="primary_5"
                size={convertUnit(12)}
                onClick={() => setModalVisible((previous) => !previous)}
              />
            </StyledInfoIconContainer>
            <GiftShopPricingDetailModal
              price={price}
              data={data}
              visible={modalVisible}
              toggleModal={() => setModalVisible((previous) => !previous)}
            />
          </StyledInfoDetails>
        </>
      ) : (
        <>
          <StyledTitleParagraph fontWeight="bold">
            {translate('giftshop:pricingDetail')}
          </StyledTitleParagraph>
          <GiftShopPricingDetail price={price} platform="Web" data={data} />
        </>
      )}
    </StyledContainer>
  )
}
