import {TranslationKeyForkygram} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_FORKYGRAM_ID: TranslationResource<TranslationKeyForkygram> = {
  translation: {
    'forkygram:addLocation': 'Tambah Lokasi',
    'forkygram:authAccessDescription':
      'Silahkan masuk untuk dapat mengakses fitur aplikasi, atau daftar jika kamu belum memiliki akun.',
    'forkygram:authAccessHeader': 'Masuk',
    'forkygram:bannedSnackbar': 'Yuser tidak ditemukan!',
    'forkygram:block': 'Blokir',
    'forkygram:blockedDescription':
      'Kamu tidak dapat melihat foto atau video karena kamu telah memblokir orang ini.',
    'forkygram:blockYuser': 'Blokir Yuser',
    'forkygram:blockYuserAcc': 'Blokir Akun Yuser',
    'forkygram:blockYuserAccSuccess': 'Akun berhasil diblokir',
    'forkygram:blockYuserAccText':
      'Apakah kamu yakin ingin memblokir akun pengguna?',
    'forkygram:blockYuserProfile':
      'Apakah kamu yakin ingin memblokir akun ini?',
    'forkygram:caption': 'Keterangan',
    'forkygram:captionInputPlaceholder':
      'Masukkan keterangan di sini (Maks. 300 karakter)',
    'forkygram:comment': 'Komentar',
    'forkygram:commentHeader': 'Komentar ({{comment_count}})',
    'forkygram:commentHeader_plural': 'Komentar ({{comment_count}})',
    'forkygram:commentLimitPerDay':
      'Maaf! Kamu hanya bisa memberi {{comment_limit}} komentar/hari.',
    'forkygram:commentPlaceholder': 'komentar sebagai {{username}}',
    'forkygram:commentPlaceholder_GUEST':
      'Mohon lakukan login atau pendaftaran untuk meninggalkan komentar.',
    'forkygram:continue': 'Lanjutkan',
    'forkygram:count': '{{count}}',
    'forkygram:count_B': '{{count}}M',
    'forkygram:count_K': '{{count}}RB',
    'forkygram:count_M': '{{count}}JT',
    'forkygram:countUploadDuration': '{{count}} detik lalu',
    'forkygram:countUploadDuration_minute': '{{count}} menit lalu',
    'forkygram:countUploadDuration_hour': '{{count}} jam lalu',
    'forkygram:countUploadDuration_day': '{{count}} hari lalu',
    'forkygram:deletePostMessage':
      'Apakah kamu yakin ingin menghapus postingan ini?',
    'forkygram:deletePostTitle': 'Hapus Postingan',
    'forkygram:deletePostComment': 'Hapus Komentar',
    'forkygram:deletePostCommentMessage':
      'Apakah kamu yakin ingin menghapus komentar ini?',
    'forkygram:emptyComment': 'Kamu belum mempunyai komentar apapun saat ini.',
    'forkygram:emptyFeedMessage':
      'Tidak ada konten yang dapat ditampilkan sekarang',
    'forkygram:emptyFeedTitle': 'Ups, sepertinya kamu telah melihat semuanya!',
    'forkygram:emptyLikeMessage':
      'Kamu bisa melihat daftar orang yang menyukai postingan kamu di sini.',
    'forkygram:emptyLikeTitle': 'Orang yang Menyukai Postinganmu',
    'forkygram:feedTabTitle': 'Semua',
    'forkygram:feedTabTitle_fyp': 'ForYu',
    'forkygram:feedTabTitle_ugc': 'Dibuat Pengguna',
    'forkygram:follow': 'Ikuti',
    'forkygram:followerCount': '<follow>{{follow}}</follow> Pengikut',
    'forkygram:followerCount_plural': '<follow>{{follow}}</follow> Pengikut',
    'forkygram:followers': 'Pengikut',
    'forkygram:following': 'Mengikuti',
    'forkygram:followingCount': '<follow>{{follow}}</follow> Mengikuti',
    'forkygram:fototreeTagMismatch': 'Mohon pilih FotoTree dari daftar.',
    'forkygram:fototreeTagNotFound':
      'Kami tidak dapat menemukan FotoTree {{tag_name}}',
    'forkygram:giftshop': 'Giftshop',
    'forkygram:home': 'Beranda',
    'forkygram:hideReply': 'Sembunyikan balasan',
    'forkygram:leafs': 'Daun',
    'forkygram:likes': 'Suka',
    'forkygram:likeLimitPerHour':
      'Maaf! Kamu hanya bisa menyukai 350 postingan/jam.',
    'forkygram:likeLimitPerDay':
      'Maaf! Kamu hanya bisa menyukai 1000 postingan/hari',
    'forkygram:logOutMessage': 'Apakah kamu yakin ingin keluar?',
    'forkygram:message': 'Pesan',
    'forkygram:myAccount': 'Akun Saya',
    'forkygram:noCollection': 'Tidak ada koleksi',
    'forkygram:noPost': 'Belum ada postingan',
    'forkygram:noPostDescription':
      'Yuser ini belum mempublikasikan apa pun di sini',
    'forkygram:notificationSettingHeader': 'Pengaturan Pemberitahuan',
    'forkygram:optional': ' (Opsional)',
    'forkygram:post': 'Postingan',
    'forkygram:postDelete': 'Hapus postingan',
    'forkygram:postEdit': 'Sunting postingan',
    'forkygram:postPin': 'Pin postingan',
    'forkygram:postPinFailed': 'Maksimal konten yang bisa di-pin adalah 9.',
    'forkygram:postPinSuccess': 'Postingan telah di-pin ke profil kamu.',
    'forkygram:postShare': 'Bagikan postingan',
    'forkygram:postUnpin': 'Unpin postingan',
    'forkygram:postUnpinSuccess':
      'Postingan yang di-pin telah dilepas dari profil kamu.',
    'forkygram:postEmptyButton': 'Unggah Sekarang',
    'forkygram:postEmptyDescription':
      'Kamu belum mengunggah momenmu. Kami bersemangat untuk momen kamu yang mengagumkan.',
    'forkygram:postEmptyHeader': 'Momen Kamu',
    'forkygram:postUpdated': 'Postingan Diperbaharui',
    'forkygram:privateAccount': 'Akun Ini Bersifat Pribadi',
    'forkygram:pushNotification': 'Pemberitahuan',
    'forkygram:registerOption': 'Gunakan email atau nomor telepon',
    'forkygram:reply': 'Balas',
    'forkygram:replying': 'Membalas',
    'forkygram:reportComment': 'Laporkan komentar',
    'forkygram:reportComment_HATE SPEECH': 'Ujaran kebencian',
    'forkygram:reportComment_SPAM': 'Komentar spam',
    'forkygram:reportComment_HARRASMENT': 'Perundungan atau pelecehan',
    'forkygram:reportComment_HARASSMENT': 'Perundungan atau pelecehan',
    'forkygram:reportComment_OTHER': 'Isu lainnya',
    'forkygram:reportCommentCancel': 'Laporan dibatalkan.',
    'forkygram:reportCommentSuccess':
      'Terima Kasih telah melaporkan. Komentar telah berhasil dilaporkan.',
    'forkygram:reportCommentFailed': 'Ada masalah saat melaporkan komentar..',
    'forkygram:reportCommentAlreadyReported':
      'Kamu sudah melaporkan komentar ini.',
    'forkygram:reportCancel': 'Laporan dibatalkan.',
    'forkygram:reportOtherPlaceholder': 'Tulis alasanmu disini...',
    'forkygram:reportPostFailed': 'Ada masalah saat melaporkan postingan.',
    'forkygram:reportPostSuccess':
      'Terima kasih telah melaporkan. Postingan telah berhasil dilaporkan.',
    'forkygram:reportYuser': 'Laporkan Yuser',
    'forkygram:reportYuserFailed': 'Ada masalah saat melaporkan akun ini.',
    'forkygram:reportYuserSuccess':
      'Terima kasih telah melaporkan akun ini. Akun berhasil dilaporkan.',
    'forkygram:shareYuser': 'Bagikan Yuser',
    'forkygram:searchFollowersPlaceholder': 'Cari Pengikut',
    'forkygram:searchFollowingPlaceholder': 'Cari Mengikuti',
    'forkygram:searchFriends': 'Temukan Teman',
    'forkygram:searchFriendsNotFound':
      'Maaf! Kami tidak dapat menemukan pengguna yang kamu cari',
    'forkygram:searchFriendsNotFoundSubtitle':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'forkygram:tagged': 'Ditandai',
    'forkygram:taggedEmptyDescription':
      'Ketika orang lain menandai kamu pada postingan mereka, akan ditampilkan di sini.',
    'forkygram:taggedEmptyHeader': 'Tidak ada postingan yang ditandai',
    'forkygram:treeHeight': 'Tinggi Pohon',
    'forkygram:unblock': 'Buka Blokir',
    'forkygram:unblockSuccessMessage': 'Akun berhasil dibuka blokirnya',
    'forkygram:uploadHeader': 'Info Konten',
    'forkygram:uploadInvalidImageFormat':
      'File gambar tidak sesuai atau tidak diperbolehkan. Hanya file dengan ekstensi berikut yang diperbolehkan: jpg, jpeg, png, heic, dan heif',
    'forkygram:uploadInvalidVideoFormat':
      'File video tidak sesuai atau tidak diperbolehkan. Hanya file dengan ekstensi berikut yang diperbolehkan: mp4(mpeg-4), dan mov',
    'forkygram:uploadLimitMessage':
      'Kamu telah mencapai batas jumlah posting. Coba kembali setelah {{value}} {{unit}}.',
    'forkygram:uploadLimitMessage_leaf':
      'Kamu telah mencapai batas jumlah daun. Coba kembali setelah {{value}} {{unit}}.',
    'forkygram:uploadMaxImageResolutionMessage':
      'Resolusi maksimum gambar adalah 25 megapixels',
    'forkygram:uploadMaxImageSizeMessage': 'Ukuran maksimum gambar adalah 5 MB',
    'forkygram:uploadMaxVideoDurationMessage':
      'Durasi maksimum video adalah 30 detik',
    'forkygram:uploadMaxVideoSizeMessage': 'Ukuran maksimum video adalah 30 MB',
    'forkygram:uploadMaxVideoWidthMessage':
      'Lebar maksimum video adalah 1080 pixels',
    'forkygram:uploadMinImageResolutionMessage':
      'Resolusi minimum gambar adalah 600x600 pixel',
    'forkygram:uploadPostActivateRoboyuBottomDesc':
      'Saat RoboYu kamu telah aktif, kamu dapat kembali ke sini untuk mulai mengunggah konten kamu.',
    'forkygram:uploadPostActivateRoboyuUpperDesc':
      'Kamu harus mengaktifkan RoboYu terlebih dahulu sebelum mengunggah konten pertama kamu.',
    'forkygram:uploadSearchLocationPlaceholder': 'Cari Lokasi',
    'forkygram:uploadSearchPeoplePlaceholder': 'Cari berdasarkan username',
    'forkygram:uploadSuccessMessage': 'Selamat! Gambar telah berhasil diunggah',
    'forkygram:uploadSuccessMessage_leaf':
      'Kamu telah meninggalkan selembar daun kenangan.',
    'forkygram:uploadTagPeople': 'Tandai Orang ',
    'forkygram:uploadTagPeopleCount': '{{count}} Orang Ditandai',
    'forkygram:uploadTagPeopleCount_plural': '{{count}} Orang Ditandai',
    'forkygram:viewReply': 'Tampilkan {{count}} balasan',
    'forkygram:viewReply_plural': 'Tampilkan {{count}} balasan',
  },
}
