import {TranslationKeyMessages} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_MESSAGES_ID: TranslationResource<TranslationKeyMessages> = {
  translation: {
    'messages:badgeBot': 'Bot',
    'messages:badgeCreator': 'Kreator',
    'messages:buttonMyCollection': 'Koleksi saya',
    'messages:buttonResendHires': 'Kirim Ulang Hi-Res',
    'messages:buttonViewBalance': 'Lihat Saldo',
    'messages:creatorRatingModalInfo':
      'Penilaian kamu penting untuk peningkatan kreator. Terima kasih!',
    'messages:creatorRatingModalTitle': 'Penilaian Kreator',
    'messages:creatorRatingSubmitFailed':
      'Silakan periksa koneksi internet kamu dan coba lagi.',
    'messages:creatorRatingSubmitSuccess': 'Berhasil mengirimkan ulasan.',
    'messages:deleteMessage': 'Hapus Pesan',
    'messages:deleteMessageConfirmation':
      'Kamu yakin ingin menghapus obrolan ini? Tindakan ini tidak bisa dibatalkan.',
    'messages:emptyDesc':
      'Dapatkan pesan dari pengguna lain di sini, atau mulai yang baru.',
    'messages:emptyMessageList': 'Belum Ada Pesan',
    'messages:emptyTitle': 'Pesan Kamu',
    'messages:hiUsername': 'Hai {{username}}, ',
    'messages:hostAdminDemotion':
      'Hai {{username}},\n\nKamu sudah tidak lagi menjadi host admin di {{host_tag}}.',
    'messages:hostAdminPromotion':
      'Hai {{username}},\n\n{{host_leader_username}} baru saja mempromosikan kamu untuk menjadi host admin {{host_tag}}.',
    'messages:hostCreatorDemotion':
      'Hai {{username}},\n\nKamu sudah tidak menjadi kreator {{creator_type}} lagi di {{host_tag}}.',
    'messages:hostCreatorPromotion':
      'Hai {{username}},\n\n{{host_leader_username}} baru saja mengangkat kamu untuk menjadi kreator dari {{host_tag}}.',
    'messages:inputPlaceholder': 'Pesan...',
    'messages:listRoomMessages': '{{message}}',
    'messages:listRoomMessages_self': 'Kamu: {{message}}',
    'messages:newMessage': 'Pesan Baru',
    'messages:notificationAdminInvitation':
      '{{host_leader_username}} baru saja mengirim undangan untuk mempromosikan kamu sebagai host admin untuk {{host_tag}}.',
    'messages:notificationAdminInvitationAccepted':
      'Selamat! Kamu baru saja menjadi host admin untuk {{host_tag}}.',
    'messages:notificationAdminInvitationRejected':
      'Terima kasih atas tanggapannya.',
    'messages:notificationAwaitingPayment':
      'Hai {{username}},\n\nPesanan kamu sebesar {{total_payment}} dengan nomor pembayaran {{invoice_id}} belum dibayar. Silahkan melakukan pembayaran ke {{payment_method}} dengan nomor billing {{billing_number}} sebelum {{transaction_expiration}}.\n\nAbaikan notifikasi ini jika kamu telah melakukan pembayaran.',
    'messages:notificationAwaitingPayment_virtual_account':
      'Hai {{username}},\n\nPesanan kamu sebesar {{total_payment}} dengan nomor pembayaran {{invoice_id}} belum dibayar. Silahkan melakukan pembayaran ke {{payment_method}} dengan nomor virtual akun {{billing_number}} sebelum {{transaction_expiration}}.\n\nAbaikan notifikasi ini jika kamu telah melakukan pembayaran.',
    'messages:notificationAwaitingPayment_ewallet':
      'Hai {{username}},\n\nPesanan kamu sebesar {{total_payment}} dengan nomor pembayaran {{invoice_id}} belum dibayar. Silahkan melakukan pembayaran ke {{payment_method}}.\n\nAbaikan notifikasi ini jika kamu telah melakukan pembayaran.',
    'messages:notificationComment': '{{username}} mengomentari: {{comment}}',
    'messages:notificationCommentMention':
      '{{username}} menyebut kamu di komentar: {{comment}}',
    'messages:notificationCreatorOfficialCreatorInvitation':
      '{{host_leader_username}} baru saja mengirim undangan untuk mempromosikan kamu sebagai kreator {{creator_type}} untuk {{host_tag}}.',
    'messages:notificationCreatorOfficialCreatorInvitationAccepted':
      'Selamat! Kamu baru saja menjadi kreator {{creator_type}} untuk {{host_tag}}.',
    'messages:notificationCreatorOfficialCreatorInvitationRejected':
      'Terima kasih atas tanggapannya.',
    'messages:notificationCreatorReviewedInfoFirst':
      'Kontenmu terjual! {{invoice_id}}',
    'messages:notificationCreatorReviewedInfoSecond':
      'Aku turut senang! Mari bersulang!',
    'messages:notificationCreatorReviewedOpening': 'Hai {{username}}, ',
    'messages:notificationCreatorReviewedRating':
      '{{buyer_nickname}} telah memberi kamu penilaian.',
    'messages:notificationFollow': '{{username}} mulai mengikuti kamu.',
    'messages:notificationHostAdminInvitationAccepted':
      'Selamat! {{host_member_username}} baru saja menerima undangan untuk menjadi host admin untuk {{host_tag}}.',
    'messages:notificationHostAdminInvitationRejected':
      '{{host_member_username}} tidak meng-konfirm undangan menjadi host admin untuk {{host_tag}}.',
    'messages:notificationHostOfficialCreatorInvitationAccepted':
      'Selamat! {{host_member_username}} baru saja menerima undangan untuk menjadi kreator {{creator_type}} untuk {{host_tag}}.',
    'messages:notificationHostOfficialCreatorInvitationRejected':
      '{{host_member_username}} tidak meng-konfirm undangan menjadi kreator {{creator_type}} untuk {{host_tag}}.',
    'messages:notificationInviteFarmer':
      '{{owner_username}} baru saja menambahkan kamu untuk menjadi petani di FotoTree {{tree_name}}',
    'messages:notificationLike': '{{username}} menyukai foto kamu.',
    'messages:notificationLikeComment':
      '{{username}} menyukai komentar kamu: {{comment}}',
    'messages:notificationLikeCommentGroup':
      '{{username}} menyukai komentar kamu.',
    'messages:notificationMakeItFree':
      '{{creator_username}} telah memutuskan untuk mengembalikan uang dari konten yang telah kamu beli:',
    'messages:notificationPromotionHostCancel':
      '{{host_tag}} membatalkan undangan.',
    'messages:notificationPurchase':
      'Hai {{username}},\n\nSelamat! Pembelian kamu selesai. Dan kami sama bersemangatnya dengan kamu.',
    'messages:notificationPurchaseCreator':
      'Hai {{username}},\n\nKontenmu telah sukses di beli oleh {{collector_nickname}}',
    'messages:notificationPurchaseCreatorExtra':
      'Aku turut senang! Mari bersulang!',
    'messages:notificationPurchaseReviewButton': 'Beri Penilaian',
    'messages:notificationPurchaseReviewInfo':
      'Selamat! Pembelian kamu selesai. Dan kami sama bersemangatnya dengan kamu.',
    'messages:notificationPurchaseReviewOpening': 'Hai {{username}}, ',
    'messages:notificationPurchaseReviewRatingFilled': 'Penilaian kamu:',
    'messages:notificationPurchaseReviewRatingQuestion':
      'Bagaimana kamu menilai {{creator_username}}?',
    'messages:notificationReceiveHiresTitle': 'Selamat {{collector_username}}!',
    'messages:notificationReceiveHiresDescription':
      'Pembelian kamu sudah selesai. {{creator_username}} telah mengirimimu gambar beresolusi tinggi dari konten yang kamu beli:',
    'messages:notificationRefundTitle':
      'Kamu telah menerima pengembalian dana!',
    'messages:notificationRefund':
      '\n\n{{creator_username}} tidak mengirimkan kamu konten Hi-Res dalam 2 hari. Kamu akan menerima {{refundAmount}} dari pembelian atas:',
    'messages:notificationRefundAmount': 'Jumlah Pengembalian:',
    'messages:notificationRefundExtra':
      'Kamu dapat memeriksa pengembalian dana di saldo kamu.',
    'messages:notificationRefundCreatorTitle':
      'Transaksi kamu ditutup separuh selesai.\n',
    'messages:notificationRefundCreatorSubtitle':
      'Kamu tidak mengirimkan konten Hi-Res dalam 2 hari. Oleh karena itu, kamu tidak dapat mengklaim jumlah harga penuh dari transaksi ini:',
    'messages:notificationRefundCreatorExtra':
      'Jumlah sebesar {{creator_refund_amount}} akan dipotong dari transaksi ini, dan dikembalikan ke {{collector_nickname}}.\n\nKamu dapat memeriksa detail transaksi pada riwayat transaksi.',
    'messages:notificationRemoveFarmer':
      'Kamu sudah tidak lagi menjadi petani di FotoTree {{tree_name}}',
    'messages:notificationReplyComment':
      '{{username}} membalas komentar kamu: {{comment}}',
    'messages:notificationResendApprovalButton': 'Konfirmasi hi-res',
    'messages:notificationResendApprovalFirst':
      '{{creator_nickname}} telah mengirimkan hi-res dari konten berikut:',
    'messages:notificationResendApprovalAdditionalContent': '+ {{total}} lagi',
    'messages:notificationResendApprovalInvoiceId':
      'Invoice ID: {{invoice_id}}',
    'messages:notificationResendApprovalSecond':
      'Harap konfirmasi konten hi-res agar transaksi dapat diselesaikan. Terima kasih!',
    'messages:notificationResendpologyFirst':
      '{{creator_username}}  tidak mengirimkan konten Hi-Res yang kamu beli pada {{purchased_at}} dalam 2 hari, ',
    'messages:notificationResendpologySecond':
      'oleh karena itu telah mengirimkan ulang gambar Hi-Res tersebut sebagai permintaan maaf untuk ketidaknyamananmu.',
    'messages:notificationResendpologyCreatorFirst':
      ' Kamu tidak mengirimkan konten Hi-Res dalam 2 hari. Oleh karena itu, kamu tidak dapat mengklaim jumlah harga penuh dari transaksi ini:',
    'messages:notificationResendpologyCreatorPurchaseDate':
      'Tanggal pembelian: {{purchased_at}}',
    'messages:notificationResendpologyCreatorSecond':
      'Jumlah sebesar {{collector_refund_amount}} telah dipotong dari transaksi ini dan dikembalikan ke {{collector_nickname}}. Kamu dapat memeriksa detail transaksi pada riwayat transaksi.',
    'messages:notificationResendpologyCreatorThird':
      'Jika kamu mau, kamu bisa tetap kirim konten Hi-Res secara gratis sebagai servis.\n\nKamu juga bisa hapus tombol ini, atau biarkan hilang sendiri setelah 7 hari.',
    'messages:notificationStorageAddition':
      'Hai {{username}},\n\nSelamat! Kamu mendapatkan tambahan penyimpanan 50MB dari pembelian pertama {{collector_nickname}}.',
    'messages:notificationStorageRequestSuccess':
      'Hai {{username}},\n\nPenyimpanan kamu sudah bertambah sebanyak {{storage_addition_amount}} GB! Silahkan cek di menu Penyimpanan kamu.',
    'messages:notificationTreeApproved':
      'Selamat <username>{{username}}!</username>\n\n<tree_name>{{tree_name}}</tree_name> FotoTree kamu telah disetujui dan sekarang bersifat publik!',
    'messages:notificationTreeNonPublic':
      'Hai <username>{{username}}</username>,\n\nFotoTree <tree_name>{{tree_name}}</tree_name> kamu berhasil ditanam. FotoTree ini akan bersifat tidak publik sampai admin mempublikasikannya.',
    'messages:notificationTreeRemoved':
      'Hai <username>{{username}}</username>,\n\nFotoTree <tree_name>{{tree_name}}</tree_name> kamu telah dihapus, karena tidak memenuhi sebagian atau beberapa ketentuan komunitas kami.',
    'messages:notificationWithdraw':
      'Hai {{username}}, \n\nPenarikan kamu {{withdraw_id}} telah berhasil dilakukan pada {{withdraw_date}}, sebesar {{withdraw_amount}}. \n\nJika aktivitas ini bukan dilakukan oleh kamu sendiri, mohon mengubah kata sandi kamu dan hubungi kami segera.',
    'messages:openInvitationButton': 'Buka undangan',
    'messages:reminderAbusiveContent':
      'Oh tidak {{username}},\n\nKonten yang baru saja kamu unggah dideteksi melanggar pedoman komunitas forky dan kami tidak dapat mengunggahnya ke giftshop.',
    'messages:reminderAbusiveContentExtra':
      'Kami tidak mengizinkan pembagian atau penerbitan konten yang melanggar pedoman komunitas kami\n\nKamu bisa membaca pedoman komunitas kami kembali disini',
    'messages:reminderCart':
      'Hai {{username}},\n\nAku rasa kamu baru saja secara tidak sengaja meninggalkan beberapa barang luar biasa di keranjang kamu.\n\nKeranjang kamu tidak kosong sejak kamu pergi. Jadi kami sudah menyimpannya untuk kamu.',
    'messages:reminderCreatorActivation':
      'Hai {{username}},\n\nSelamat datang di klub, dengan senang hati kami menginformasikan bahwa akun kamu telah berhasil diverifikasi.\n\nKamu telah bergabung dengan para kreator kreatif di seluruh dunia\n\nayo mulai mengunggah karya pertama kamu hari ini!\n\nCatatan:\nSemua klub punya peraturan, begitupun kami Kamu bisa membacanya disini',
    'messages:reminderResendContent':
      'Hai {{username}},\n\n{{collector_nickname}} baru saja membeli kreasimu.',
    'messages:reminderResendContentExtra':
      'Silakan kirim kreasimu yang beresolusi tinggi dalam waktu 2 hari.',
    'messages:reminderRobopet':
      'Lama tidak berjumpa {{username}},\n\naku baru saja memilih beberapa konten yang mengagumkan untukmu\n\nCek sekarang di jelajah',
    'messages:reminderWaitingHires':
      'Kamu baru saja membeli kreasi dari {{creator_username}}.',
    'messages:reminderWaitingHiresGreet': 'Hai {{username}},',
    'messages:reminderWaitingHiresExtra':
      'Jika konten kamu masih dalam versi preview, maka kreator akan mengirim ulang versi Hi-Res dalam waktu 2 hari, jika tidak, kamu akan menerima pengembalian dana penuh (tidak termasuk biaya bank).',
    'messages:reminderWishlist':
      'Hai {{username}},\n\nTahukah kamu bahwa pembuat konten baru-baru ini mengubah sesuatu dalam detail konten di daftar keinginan kamu\n\nmari cari tahu apa yang baru',
    'messages:resendpologyButton': 'Tetap Kirim Ulang',
    'messages:system': 'Sistem',
    'messages:system_input': 'Kamu tidak dapat membalas pesan sistem.',
    'messages:system_notification': 'Notifikasi',
    'messages:system_robopet': 'RoboYu',
    'messages:unsupported': 'Pesan Tidak Didukung',
    'messages:userNotFound': 'Yuser Tidak Ditemukan',
  },
}
