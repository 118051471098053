import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthCredentialType, ObjectState} from 'types'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(8)};
  word-break: break-word;
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`

interface TemplateAuthEasySignupCredentialConfirmModalProps {
  stateModal: ObjectState<boolean>
  credential: string
  credentialType?: AuthCredentialType
  onAgree(): void
}
export default function TemplateAuthEasySignupCredentialConfirmModal({
  stateModal,
  credential,
  credentialType,
  onAgree,
}: TemplateAuthEasySignupCredentialConfirmModalProps) {
  const {translate} = useTranslation()
  const [modal, setModal] = stateModal
  return (
    <StyledModal visible={modal}>
      <StyledImageContainer>
        <Icon
          type={credentialType === 'email' ? 'email-ol' : 'whatsapp-ol'}
          size={48}
        />
      </StyledImageContainer>
      <StyledTitle fontSize="xl" fontWeight="bold">
        {credential}
      </StyledTitle>
      <StyledTitle fontSize="l">
        {translate('auth:registerEasySignupCredentialConfirm', {
          context: credentialType,
        })}
      </StyledTitle>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={() => setModal(false)}
        />
        <StyledButton
          label={translate('global:continue')}
          onClick={() => {
            setModal(false)
            onAgree()
          }}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
