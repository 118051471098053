import {lazy} from 'react'

const GiftShopSearchByCreatorContentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'SearchByCreator' */ './GiftShopSearchByCreatorContentScreen'
    ),
)

const GiftShopSearchByCreatorCreationContentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'CreatorContent' */ './GiftShopSearchByCreatorCreationContentScreen'
    ),
)

const GiftShopSearchByCreatorCreationContentDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'CreatorContentDetail' */ './GiftShopSearchByCreatorCreationContentDetailScreen'
    ),
)

export {
  GiftShopSearchByCreatorContentScreen,
  GiftShopSearchByCreatorCreationContentScreen,
  GiftShopSearchByCreatorCreationContentDetailScreen,
}

export * from './GiftShopSearchByCreatorCreationContentScreenParam'
export * from './GiftShopSearchByCreatorCreationContentDetailScreenParam'
