import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopReportCancelConfirmationProps} from './GiftShopReportCancelConfirmationProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(300)};
  padding: ${convertUnit(25)} ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledModalBody = styled.div`
  flex-direction: column;
  align-items: center;
  margin-top: ${convertUnit(10)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(34)};
`

const StyledYesButton = styled(Button)`
  display: flex;
  flex-direction: row;
  margin-left: ${convertUnit(30)};
  padding: ${convertUnit(9)} ${convertUnit(16)};
  width: ${convertUnit(125)};
`

const StyledCancelButton = styled(Button)`
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(9)} ${convertUnit(16)};
  width: ${convertUnit(125)};
  ${({theme}) => ({backgroundColor: theme.white_3})};
`

export default function GiftShopReportCancelConfirmation({
  stateModalIndex,
  onCancel,
}: GiftShopReportCancelConfirmationProps) {
  const setModalIndex = stateModalIndex[1]
  const {translate} = useTranslation()

  const handleCancel = useCallback(() => {
    onCancel()
    setModalIndex('hidden')
  }, [onCancel, setModalIndex])

  return (
    <StyledModal visible>
      <StyledTitle>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('global:cancel')}
        </Paragraph>
      </StyledTitle>
      <StyledModalBody>
        <StyledParagraph fontSize="l" fontWeight="regular">
          {translate('global:cancelConfirmation')}
        </StyledParagraph>
      </StyledModalBody>
      <StyledButtonContainer>
        <StyledCancelButton
          label={translate('global:stay')}
          onClick={() => setModalIndex('report')}
          color="primary_5"
        />
        <StyledYesButton
          label={translate('global:sure')}
          onClick={handleCancel}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
