import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {PartnershipModal} from 'pages'
import {ThemeColor} from 'themes'
import {getHowItWorksWebUrl} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopRootNavBarProps} from './GiftShopRootNavBarProps'
import {GiftShopRootNavBarItem} from '../NavBarItem'

interface StyledContainerProps {
  darkBar?: boolean
  darkBarColor?: ThemeColor
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, darkBar, darkBarColor = 'gray_9'}) => ({
    borderColor: darkBar ? theme.gray_8 : theme.white_3,
    backgroundColor: darkBar ? theme[darkBarColor] : theme.white_1,
  })}
  display: flex;
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  border-right-style: solid;
  border-right-width: ${convertUnit(1)};
  z-index: 2;
  justify-content: space-between;
`

const StyledHowItWorksContainer = styled.div`
  padding: ${convertUnit(20)} ${convertUnit(5)};
  text-align: center;
  width: ${convertUnit(80)};
  box-sizing: border-box;
`

const StyledTextContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
`

const StyledSeparator = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  margin: ${convertUnit(12)};
  ${({theme, darkBar}) => ({
    borderTop: `solid ${convertUnit(1)} ${
      darkBar ? theme.gray_7 : theme.gray_1
    }`,
  })}
  height: ${convertUnit(1)};
`

export default function GiftShopRootNavBar({
  select,
  data,
  darkBar,
  darkBarColor,
}: GiftShopRootNavBarProps) {
  const {translate} = useTranslation()
  const stateModalPartership = useState(false)
  const setModalPartnership = stateModalPartership[1]
  const handleRenderPartnershipModal = useMemo(
    () => <PartnershipModal stateModal={stateModalPartership} />,
    [stateModalPartership],
  )
  return (
    <StyledContainer darkBar={darkBar} darkBarColor={darkBarColor}>
      {handleRenderPartnershipModal}
      <div>
        <div>
          {data.primary.map(({key, icon, label, onClick}) => (
            <GiftShopRootNavBarItem
              key={key}
              icon={icon}
              label={label}
              isSelected={select === key}
              darkBar={darkBar}
              onClick={() =>
                select === key ? undefined : onClick && onClick()
              }
            />
          ))}
          <StyledSeparator darkBar={darkBar} />
          {data.secondary.map(({key, icon, label, isSendToFace, onClick}) => (
            <GiftShopRootNavBarItem
              key={key}
              icon={icon}
              label={label}
              isSelected={select === key}
              darkBar={darkBar}
              onClick={() =>
                select === key ? undefined : onClick && onClick()
              }
              isSendToFace={isSendToFace}
            />
          ))}
        </div>
      </div>
      <StyledHowItWorksContainer>
        <StyledLink href={getHowItWorksWebUrl()} target="_blank">
          <Paragraph fontSize="s" color="gray_5">
            {translate('global:howItWorks')}
          </Paragraph>
        </StyledLink>
        <Paragraph fontSize="s" color="gray_5">
          •
        </Paragraph>
        <StyledTextContainer>
          <Paragraph
            fontSize="s"
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            color="gray_5"
            onClick={() => setModalPartnership(true)}>
            {translate('giftShop:partnership')}
          </Paragraph>
        </StyledTextContainer>
      </StyledHowItWorksContainer>
    </StyledContainer>
  )
}
