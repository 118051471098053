import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {showModalReprocessKyc, useHistory} from 'utils'
import {
  Button,
  ButtonOutline,
  IconVerifiedBadge,
  Image,
  Paragraph,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramLikeModalContentItemProps} from './ForkygramLikeModalContentItemProps'

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
  flex: none;
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  cursor: pointer;
  width: ${convertUnit(36)};
  height: ${convertUnit(36)};
  flex: none;
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
  box-sizing: border-box;
`

const StyledFollowingButton = styled(ButtonOutline)`
  height: ${convertUnit(28)};
  padding: ${convertUnit(4)} ${convertUnit(12)};
  margin-left: ${convertUnit(4)};
  cursor: pointer;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: fit-content;
  }
`

const StyledFollowButton = styled(Button)`
  height: ${convertUnit(28)};
  padding: ${convertUnit(4)} ${convertUnit(12)};
  margin-left: ${convertUnit(4)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: fit-content;
  }
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  box-sizing: border-box;
`
const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function ForkygramLikeModalContentItem({
  data,
  toggleModal,
}: ForkygramLikeModalContentItemProps) {
  const {creator_status, username, photo, is_followed, id} = data.member
  const {id: userId} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const history = useHistory()
  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )
  const [isFollowed, setIsFollowed] = useState<boolean>(is_followed)
  const {kycStatus} = useSelector('yuserActivationState')

  const handleFeatureDisabled = useCallback(() => {
    toggleModal()
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, similarStatus, toggleModal])

  const handleFollow = useCallback(() => {
    setIsFollowed(() => !isFollowed)

    id &&
      requestData('giftshop_post_follow', {
        data: {member_id: id},
        useDefaultMessage: true,
        actionType: 'execute',
        onRequestFailed: () => {
          setIsFollowed(() => !isFollowed)
        },
      })
  }, [id, isFollowed])

  const handleUnfollow = useCallback(() => {
    setIsFollowed(() => !isFollowed)

    id &&
      requestData('giftshop_delete_follow', {
        data: {member_id: id},
        useDefaultMessage: true,
        actionType: 'execute',
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setIsFollowed(() => !isFollowed)
          }
        },
      })
  }, [id, isFollowed])

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: id === userId,
        postData: {member_id: id, photo, username},
      },
      username,
    )
  }, [history, id, photo, userId, username])

  const handleRenderButton = useMemo(
    () =>
      isFollowed ? (
        <StyledFollowingButton
          fontSize="s"
          borderColor="gray_5"
          color="gray_6"
          fontWeight="bold"
          label={translate('forkygram:following')}
          onClick={() => {
            if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
              handleFeatureDisabled()
              return
            }
            handleUnfollow()
          }}
        />
      ) : (
        <StyledFollowButton
          fontSize="s"
          fontWeight="bold"
          label={translate('forkygram:follow')}
          onClick={() => {
            if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
              handleFeatureDisabled()
              return
            }
            handleFollow()
          }}
        />
      ),
    [isFollowed, kycStatus, handleUnfollow, handleFeatureDisabled, handleFollow],
  )

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledAvatar
          src={photo}
          alt="avatar"
          onClick={handleNavigateProfile}
        />
        <StyledParagraph
          className="pressable"
          fontSize="m"
          fontWeight="medium"
          onClick={handleNavigateProfile}>
          {username}
        </StyledParagraph>
        {handleRenderBadge}
      </StyledContentContainer>
      {userId === id ? null : handleRenderButton}
    </StyledContainer>
  )
}
