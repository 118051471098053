import {useCallback, useMemo} from 'react'
import {
  useHistory as useHistoryOrigin,
  useLocation as useLocationOrigin,
} from 'react-router'
import {Routes} from 'types'
import {
  getQueryObject,
  parseObjectToQuery,
  QueryKey,
  QueryType,
  RouteMapEntry,
  RouteParam,
  ROUTE_MAP,
} from '../../routes'
import {getRoutePath} from './RouteHelper'

export function useHistory<K extends Routes>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route?: K,
) {
  const history = useHistoryOrigin<RouteParam[K]>()

  const getParamPath = useCallback(
    <P extends Routes>(path: P, recursivePath: string) => {
      const routeMapEntry = ROUTE_MAP[path]
      return routeMapEntry.path.replace(/:\w+/g, recursivePath)
    },
    [],
  )

  const handlePush = useCallback(
    <P extends Routes>(path: P, state, recursivePath?: string) => {
      if (recursivePath) {
        const route = getParamPath(path, recursivePath)
        history.push(route, state)
        return
      }
      history.push(getRoutePath(path), state)
    },
    [getParamPath, history],
  )

  const handleReplace = useCallback(
    <P extends Routes>(path: P, state, recursivePath?: string) => {
      if (recursivePath) {
        const route = getParamPath(path, recursivePath)
        history.replace(route, state)
        return
      }
      history.replace(getRoutePath(path), state)
    },
    [getParamPath, history],
  )

  /** @todo: merge handlePushQuery with handlePush */
  const handlePushQuery = useCallback(
    <P extends Routes>(argument: {
      path: P
      state: RouteParam[P] | undefined
      queryObject: P extends QueryKey ? QueryType[P] : {}
      recursivePath?: string
    }) => {
      const {path, state, queryObject, recursivePath} = argument
      const queryString = parseObjectToQuery(queryObject)
      if (recursivePath) {
        history.push(
          `${getParamPath(path, recursivePath)}${queryString}`,
          state as RouteParam[K],
        )
        return
      }
      history.push(
        `${getRoutePath(path)}${queryString}`,
        state as RouteParam[K],
      )
    },
    [getParamPath, history],
  )

  const handleReplaceQuery = useCallback(
    <P extends Routes>(argument: {
      path: P
      state: RouteParam[P] | undefined
      queryObject: P extends QueryKey ? QueryType[P] : {}
      recursivePath?: string
    }) => {
      const {path, state, queryObject, recursivePath} = argument
      const queryString = parseObjectToQuery(queryObject)
      if (recursivePath) {
        history.replace(
          `${getParamPath(path, recursivePath)}${queryString}`,
          state as RouteParam[K],
        )
        return
      }
      history.replace(
        `${getRoutePath(path)}${queryString}`,
        state as RouteParam[K],
      )
    },
    [getParamPath, history],
  )

  return useMemo(
    () => ({
      ...history,
      push: handlePush,
      replace: handleReplace,
      pushQuery: handlePushQuery,
      replaceQuery: handleReplaceQuery,
    }),
    [history, handlePush, handleReplace, handlePushQuery, handleReplaceQuery],
  )
}

export function useLocation<K extends Routes>(route: K) {
  const {defaultState} = ROUTE_MAP[route] as RouteMapEntry<
    RouteParam,
    keyof RouteParam
  >
  const locationOrigin = useLocationOrigin<RouteParam[K]>()

  /** @todo: replace intersection type */
  const location = useMemo<
    {query: K extends QueryKey ? QueryType[K] : {}} & typeof locationOrigin
  >(
    () => ({
      ...locationOrigin,
      state: locationOrigin.state ?? (defaultState as RouteParam[K]),
      query: getQueryObject(route),
    }),
    [locationOrigin, defaultState, route],
  )

  return location
}

export function useQueryParam() {
  const {search} = useLocationOrigin()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function useQueryParamValue<T extends string>(keys: T[]) {
  const query = useQueryParam()

  return useMemo(() => {
    const result: {[K in T]?: string} = {}

    for (const key of keys) {
      const value = query.get(key)

      result[key] = value !== null ? value : undefined
    }

    return result
  }, [keys, query])
}
