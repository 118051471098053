import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {ListFailedProps} from './ListFailedProps'
import {Icon} from '../Icon'
import {Paragraph} from '../Paragraph'

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_3} @media
    (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    background-color: ${({theme}) => theme.white_1};
  }
`

const StyledRetry = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  cursor: pointer;
`

export default function ListFailed({onRetry}: ListFailedProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <Paragraph fontSize="l" fontWeight="bold" color="gray_6">
        {translate('global:failedLoad')}
      </Paragraph>
      <StyledRetry onClick={onRetry}>
        <Icon type="rotate" size={convertUnit(16)} color="gray_3" />
        <Paragraph fontSize="s" fontWeight="medium" color="gray_3">
          {translate('global:tapToRetry')}
        </Paragraph>
      </StyledRetry>
    </StyledContainer>
  )
}
