import React, {useMemo} from 'react'
import styled from 'styled-components'
import { Paragraph } from 'common/components'
import { useTranslation } from 'i18n'
import convertUnit from 'lib/unit'
import { calcTimeDiff, parseDate } from 'utils'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${convertUnit(12.5)};
  padding-bottom: ${convertUnit(12.5)};
`

export interface GiftShopMessageRoomContentSeparatorProps {
  time: string
}

export default function GiftShopMessageRoomContentSeparator({
  time,
}: GiftShopMessageRoomContentSeparatorProps) {
  const {translate} = useTranslation()

  const name = useMemo(() => {
    const date = new Date(time)
    if (calcTimeDiff(date, new Date().toISOString()).hours <= 24) {
      return translate('global:today')
    }
    if (calcTimeDiff(date, new Date().toISOString()).hours > 24 &&
      calcTimeDiff(date, new Date().toISOString()).hours <= 48) {
      return translate('global:yesterday')
    }
    return parseDate(time, 'dddd, DD MMMM YYYY')
  }, [time, translate])

  return (
    <StyledContainer>
      <Paragraph fontSize="m" color="gray_3">
        {name}
      </Paragraph>
    </StyledContainer>
  )
}
