import {IMAGE_SIGNATURE_SIZE, IMAGE_SIGNATURE_SIZE_INTERMEDIATE} from 'consts'
import {ImageSignatureParameter} from 'types'

export function formatColorToHex(value: number) {
  return value.toString(16).padStart(2, '0')
}

function getSignatureRGB(imageData: ImageData) {
  const {data} = imageData

  let signature = ''
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]

    signature += formatColorToHex(r)
    signature += formatColorToHex(g)
    signature += formatColorToHex(b)
  }

  return signature
}

function getSignatureFromImage(
  source: HTMLImageElement | HTMLCanvasElement,
  signatureFunction: (imageData: ImageData) => string,
  {
    canvasWidth,
    canvasHeight,
    offsetX,
    offsetY,
    outputWidth,
    outputHeight,
  }: ImageSignatureParameter,
) {
  const canvas = document.createElement('canvas')
  canvas.width = canvasWidth
  canvas.height = canvasHeight

  const context = canvas.getContext('2d')
  if (!context) return null

  context.imageSmoothingEnabled = false
  context.drawImage(source, 0, 0, canvasWidth, canvasHeight)

  const imageData = context.getImageData(
    offsetX,
    offsetY,
    outputWidth,
    outputHeight,
  )

  const signature = signatureFunction(imageData)

  return {
    canvas,
    signature,
  }
}

export async function createImageSignature(
  image: HTMLImageElement,
  signatureFinalSize = IMAGE_SIGNATURE_SIZE,
  signatureIntermediateSize = IMAGE_SIGNATURE_SIZE_INTERMEDIATE,
) {
  const offset = Math.round(signatureIntermediateSize / 3)

  const part2 = getSignatureFromImage(image, getSignatureRGB, {
    canvasWidth: signatureIntermediateSize,
    canvasHeight: signatureIntermediateSize,
    offsetX: offset,
    offsetY: offset,
    outputWidth: offset,
    outputHeight: offset,
  })

  if (!part2) return null

  const {canvas, signature: signature2} = part2

  const part1 = getSignatureFromImage(canvas, getSignatureRGB, {
    canvasWidth: signatureFinalSize,
    canvasHeight: signatureFinalSize,
    offsetX: 0,
    offsetY: 0,
    outputWidth: signatureFinalSize,
    outputHeight: signatureFinalSize,
  })

  if (!part1) return null

  const {signature} = part1

  if (!signature || !signature2) return null

  return signature + signature2
}

export function createImageSignatureFromFile(
  file: File,
  signatureFinalSize?: number,
  signatureIntermediateSize?: number,
) {
  return new Promise<string | null>((resolve) => {
    const blob = URL.createObjectURL(file)
    const image = new Image()

    const handleError = () => {
      URL.revokeObjectURL(blob)
      resolve(null)
    }

    const handleLoadImage = () => {
      URL.revokeObjectURL(blob)
      resolve(
        createImageSignature(
          image,
          signatureFinalSize,
          signatureIntermediateSize,
        ),
      )
    }

    image.src = blob
    image.onabort = handleError
    image.onerror = handleError
    image.onload = handleLoadImage
  })
}
