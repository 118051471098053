import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IndicatorBarStatus, IndicatorBarStatusData} from 'types'
import {IndicatorBarProps} from './IndicatorBarProps'
import {ThemeColor} from 'themes'

interface StyledProgressProps {
  backgroundColor: ThemeColor
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #f0f0f0;
  overflow: hidden;
`
const StyledProgress = styled.div<StyledProgressProps>`
  ${({theme, backgroundColor}) => ({
    backgroundColor: theme[backgroundColor],
  })}
  height: 100%;
`
export default function IndicatorBar<S = boolean>({
  statuses,
  colorExtractor,
}: IndicatorBarProps<S>) {
  const progress = useMemo(() => {
    const map: IndicatorBarStatusData<S>[] = []
    let status: IndicatorBarStatus<S> = null
    let count = 0
    const submitStatus = () => {
      const key = `${map.length}: ${status}`
      map.push({key, status, count})
      count = 0
    }
    for (const item of statuses) {
      if (count > 0 && status !== item) submitStatus()
      status = item
      count += 1
    }
    submitStatus()
    return map
  }, [statuses])

  const renderProgress = useMemo(
    () =>
      progress.map(
        ({key, status, count}) => (
          <StyledProgress
            key={key}
            style={{flex: count}}
            backgroundColor={colorExtractor(status)}
          />
        ),
        [],
      ),
    [progress, colorExtractor],
  )

  return <StyledContainer>{renderProgress}</StyledContainer>
}
