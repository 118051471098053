import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'

const StyledBetaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledBetaIcon = styled.div`
  background-color: ${({theme}) => theme.primary_5};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(4)} ${convertUnit(8)};
  width: fit-content;
  margin-bottom: ${convertUnit(4)};
`

export default function BetaBadge() {
  const {translate} = useTranslation()

  return (
    <StyledBetaContainer>
      <StyledBetaIcon>
        <Paragraph fontSize="s" fontWeight="medium" color="white_1">
          {translate('global:beta').toUpperCase()}
        </Paragraph>
      </StyledBetaIcon>
    </StyledBetaContainer>
  )
}
