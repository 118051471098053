import React from 'react'
import styled from 'styled-components'
import {Chips, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {TreeEditProfileAliasProps} from './TreeEditFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5%;
`

export default function TreeEditProfileAlias({
  data,
  onAction,
}: TreeEditProfileAliasProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <Paragraph lineHeight={26} fontSize="m" color="gray_5">
        {translate('tree:alias')}
      </Paragraph>
      {data && (
        <Chips
          data={data}
          keyExtractor={(item) => item.id + item.name}
          labelExtractor={(item) => item.name}
          fontColor="primary_5"
          backgroundColor="primary_1"
          containerStyle={{marginBottom: convertUnit(4)}}
          showCloseButton={false}
        />
      )}
      <Paragraph
        lineHeight={21}
        className="pressable"
        fontWeight="medium"
        color="primary_5"
        onClick={onAction}>
        {data ? translate('global:change') : translate('global:add')}
      </Paragraph>
    </StyledContainer>
  )
}
