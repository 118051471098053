import React from 'react'
import styled from 'styled-components'
import {Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostAudioProps} from './ForkygramTemplatePostAudioProps'

const StyledMuteContainer = styled.div`
  cursor: pointer;
`

export default function ForkygramTemplatePostAudio({
  mute,
  onPressMute,
}: ForkygramTemplatePostAudioProps) {
  return (
    <StyledMuteContainer onClick={onPressMute}>
      <Icon
        type={mute ? 'mute' : 'unmute'}
        size={convertUnit(28)}
        color="white_1"
        hasOpacity
        hasShadow
      />
    </StyledMuteContainer>
  )
}
