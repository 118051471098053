import React, {useCallback, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, {useTheme} from 'styled-components'
import {IMAGE_ASSET, REGEX_MULTIPLE_NEWLINES} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeFotoTreeDetailResponse} from 'types'
import {useDidMount, getFontFamily, formatDateRange, getBoxShadow} from 'utils'
import {Paragraph, Image, Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {TreePopupContentProps} from './TreeFototreeModalProps'

const StyledRowContainer = styled.div`
  display: flex;
`

const StyledColumnContainer = styled(StyledRowContainer)`
  flex-direction: column;
`

const StyledContainer = styled(StyledColumnContainer)`
  padding: ${convertUnit(20)};
  min-width: ${convertUnit(200)};
  max-width: ${convertUnit(335)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {verticalOffset: 4, opacity: 0.16})};
`

const StyledHeaderContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(20)};
  margin-bottom: ${convertUnit(12)};
  align-items: center;
  justify-content: center;
`

const StyledTextContainer = styled(StyledColumnContainer)`
  flex: 1;
  gap: ${convertUnit(4)};
`
const StyledTextEventDate = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledEventDateContainer = styled(StyledRowContainer)`
  align-items: center;
`

const StyledDetailContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(12)};
`

const StyledSpan = styled.span`
  font-family: ${getFontFamily('bold')};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(68)};
  aspect-ratio: 0.75;
  border-radius: ${convertUnit(8)};
  object-fit: contain;
`

const StyledHiddenCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertUnit(4)};
`

const StyledIconExclamation = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.info_5};
  rotate: 180deg;
  border-radius: 50%;
  width: ${convertUnit(10)};
  height: ${convertUnit(10)};
`

const StyledBio = styled(Paragraph)`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: ${convertUnit(18)};
`

export default function TreePopupContent({id, tree}: TreePopupContentProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const {translate} = useTranslation()
  const theme = useTheme()
  const [data, setData] = useState<TreeFotoTreeDetailResponse | undefined>(tree)

  const handleLoadTreeInfo = useCallback(() => {
    requestData('tree_get_fototree_detail', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {tree_id: id},
      onRequestSuccess: ({data: fetchData, status}) => {
        if (status === 200) {
          setData(fetchData.result)
        }
      },
    })
  }, [id])

  useDidMount(() =>
    typeof tree === 'undefined' ? handleLoadTreeInfo() : undefined,
  )

  return data ? (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledImage
          src={data.url}
          fallbackSrc={defaultImage}
          alt={data.name}
        />
        <StyledTextContainer>
          <Paragraph fontSize="l" fontWeight="bold" lineHeight={22}>
            {data.name}
          </Paragraph>
          <StyledDetailContainer>
            <Paragraph>
              <StyledSpan>{data.count_memory}</StyledSpan>{' '}
              {translate('forkygram:leafs')}
            </Paragraph>
            |
            <Paragraph>
              <StyledSpan>{data.height || 0}</StyledSpan>{' '}
              {translate('forkygram:treeHeight')}
            </Paragraph>
          </StyledDetailContainer>
          <StyledHiddenCountContainer>
            <StyledIconExclamation>
              <Icon
                type="exclamation-lg"
                size={convertUnit(6)}
                color="white_1"
              />
            </StyledIconExclamation>
            <Paragraph fontWeight="medium" fontSize="xs" color="info_5">
              {translate('tree:leafCountIncludePrivate')}
            </Paragraph>
          </StyledHiddenCountContainer>
        </StyledTextContainer>
      </StyledHeaderContainer>
      {data?.event_start_date && data?.event_end_date && (
        <StyledEventDateContainer>
          <Icon type="calendar-event" />
          <StyledTextEventDate fontWeight="bold">
            {formatDateRange(data.event_start_date, data.event_end_date)}
          </StyledTextEventDate>
        </StyledEventDateContainer>
      )}
      <StyledBio>{data?.bio.replace(REGEX_MULTIPLE_NEWLINES, '\n')}</StyledBio>
    </StyledContainer>
  ) : (
    <StyledContainer>
      <StyledHeaderContainer>
        <Skeleton
          width={68}
          height={88}
          borderRadius={8}
          baseColor={theme.gray_1}
          highlightColor={theme.white_4}
        />
        <StyledTextContainer>
          <Paragraph fontSize="l" fontWeight="bold">
            <Skeleton
              borderRadius={17}
              baseColor={theme.gray_1}
              highlightColor={theme.white_4}
            />
          </Paragraph>
          <StyledDetailContainer>
            <Paragraph>
              <Skeleton
                borderRadius={17}
                width={40}
                baseColor={theme.gray_1}
                highlightColor={theme.white_4}
              />
            </Paragraph>
            |
            <Paragraph>
              <Skeleton
                borderRadius={17}
                width={40}
                baseColor={theme.gray_1}
                highlightColor={theme.white_4}
              />
            </Paragraph>
          </StyledDetailContainer>
        </StyledTextContainer>
      </StyledHeaderContainer>
      <Skeleton
        borderRadius={17}
        count={3}
        baseColor={theme.gray_1}
        highlightColor={theme.white_4}
      />
    </StyledContainer>
  )
}
