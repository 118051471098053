import React from 'react'
import styled from 'styled-components'
import {GiftShopUploadSeriesData} from 'types'
import {Image} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateContentVideoBadge} from '../../../template'
import {GiftShopUploadContentItemBulkItemProps} from './GiftShopUploadContentItemBulkItemProps'

interface StyledImgProps {
  width?: string
}

interface StyledSelectedProps {
  isSelected: boolean
}

const StyledItem = styled.div<StyledImgProps>`
  ${({width}) => ({
    width,
  })}
  position: relative;
  aspect-ratio: 1;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  padding-right: ${convertUnit(20)};
`

const StyledImg = styled(Image)<StyledSelectedProps>`
  ${({theme, isSelected}) => ({
    borderWidth: isSelected ? convertUnit(4) : 0,
    borderColor: isSelected ? theme.primary_5 : undefined,
    borderStyle: isSelected ? 'solid' : 'none',
  })}
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  align-self: center;
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
`

const StyledVideo = styled.img<StyledSelectedProps>`
  ${({theme, isSelected}) => ({
    borderWidth: isSelected ? convertUnit(4) : 0,
    borderColor: isSelected ? theme.primary_5 : undefined,
    borderStyle: isSelected ? 'solid' : 'none',
  })}
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  align-self: center;
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
`

export default function GiftShopUploadContentItemBulkItem<
  ItemT extends GiftShopUploadSeriesData
>({
  item,
  isVideo,
  isSelected,
  width,
  onClick,
}: GiftShopUploadContentItemBulkItemProps<ItemT>) {
  const {
    src,
    file: {name},
    videoFace,
  } = item.images[0]

  return (
    <StyledItem onClick={() => onClick(item.id)} width={width}>
      {isVideo && videoFace ? (
        <>
          <StyledVideo src={videoFace.src} isSelected={isSelected} />
          <GiftShopTemplateContentVideoBadge offset={32} />
        </>
      ) : (
        <StyledImg src={src} alt={name} isSelected={isSelected} />
      )}
    </StyledItem>
  )
}
