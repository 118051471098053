import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  AVATAR_SIZE_CHAT,
  FORKYGRAM_SEARCH_FRIENDS_QUERY_LIMIT,
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_MEMBER,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {useDebounce, useDidUpdate, useHistory} from 'utils'
import {requestData} from 'services'
import {UserInfoBase} from 'types'
import {
  ActivityIndicator,
  Avatar,
  Icon,
  IconVerifiedBadge,
  Image,
  Input,
  List,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
  width: 100%;
  height: ${convertUnit(570)};
  max-width: ${convertUnit(560)};
  background-color: ${({theme}) => theme.white_1};
  display: flex;
  border-radius: ${convertUnit(16)};
  justify-content: center;
  flex-direction: column;
  align-self: center;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    height: 100%;
  }
`

const StyledInputContainer = styled.div`
  margin-bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0 ${convertUnit(20)};
  }
`

const StyledNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledItemContainer = styled.div<CSSProperties>`
  display: flex;
  flex: 1;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  cursor: pointer;
`

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`

const StyledEmptyImg = styled(Image)`
  object-fit: contain;
  margin-bottom: ${convertUnit(25)};
  max-width: ${convertUnit(200)};
  max-height: ${convertUnit(165)};
`

const StyledEmptyMessage = styled(Paragraph)`
  text-align: center;
`

export default function ForkygramSearchFriends() {
  const {translate} = useTranslation()
  const debounce = useDebounce()
  const history = useHistory()
  const user = useSelector('user')
  const emptyImg = IMAGE_ASSET('giftshop', 'not-found.png')
  const activeRequestCount = useRef(0)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<UserInfoBase[]>()

  const handleLoadData = useCallback(async () => {
    setLoading(!!search)
    activeRequestCount.current += 1
    try {
      await requestData('forkygram_get_member', {
        cancelId: SERVICE_CANCELLATION_SEARCH_MEMBER,
        params: {
          q: search,
          limit: FORKYGRAM_SEARCH_FRIENDS_QUERY_LIMIT,
          page: 1,
        },
        onRequestFailed: (res) =>
          res === SERVICE_CANCELLED_RESPONSE && undefined,
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) setData(result)
        },
      })
    } finally {
      activeRequestCount.current -= 1
      if (activeRequestCount.current === 0) {
        setLoading(false)
      }
    }
  }, [search])

  const handleRenderItem = useCallback(
    (item: UserInfoBase) => (
      <StyledItemContainer
        onClick={() =>
          history.push(
            'giftshop_profile',
            {
              self: user ? item.id === user.id : false,
              postData: {
                member_id: item.id,
                username: item.username,
                photo: item.photo,
              },
            },
            item.username,
          )
        }>
        <StyledRowContainer>
          <StyledAvatar
            containerStyle={{marginRight: 15}}
            src={item.photo}
            alt="Avatar"
            size={AVATAR_SIZE_CHAT}
          />
          <Paragraph fontSize="m" fontWeight="bold">
            {item.username}
          </Paragraph>
          {item.creator_status === 'verified' && (
            <StyledIconVerifiedBadge
              height={convertUnit(18)}
              width={convertUnit(24)}
            />
          )}
        </StyledRowContainer>
      </StyledItemContainer>
    ),
    [history, user],
  )

  const renderNotFound = useMemo(
    () => (
      <StyledNotFoundContainer>
        <StyledEmptyImg src={emptyImg} alt={emptyImg} />
        <StyledEmptyMessage fontSize="l" fontWeight="bold">
          {translate('forkygram:searchFriendsNotFound')}
        </StyledEmptyMessage>
        <StyledEmptyMessage fontSize="m" color="gray_3">
          {translate('forkygram:searchFriendsNotFoundSubtitle')}
        </StyledEmptyMessage>
      </StyledNotFoundContainer>
    ),
    [emptyImg, translate],
  )

  const handleRenderLoading = useMemo(
    () =>
      loading ? (
        <StyledLoadingContainer>
          <ActivityIndicator iconColor="primary_5" />
        </StyledLoadingContainer>
      ) : (
        <></>
      ),
    [loading],
  )

  const handleRenderEmpty = useMemo(
    () => (!loading && search && data?.length === 0 ? renderNotFound : <></>),
    [data?.length, loading, renderNotFound, search],
  )

  useDidUpdate(() => {
    handleLoadData()
  }, [handleLoadData])

  return (
    <StyledContainer>
      <StyledInputContainer>
        <Input
          leftIcon={<Icon type="search" color="gray_3" />}
          placeholder={translate('forkygram:uploadSearchPeoplePlaceholder')}
          defaultValue={search}
          rightIcon="delete"
          onRightIconClick={() => {
            setData(undefined)
            setSearch('')
          }}
          onChangeText={(text) => debounce(() => setSearch(text), 500)}
        />
      </StyledInputContainer>
      <List
        keyExtractor={(item) => item.id}
        renderItem={handleRenderItem}
        listEmptyElement={handleRenderEmpty}
        listFooterElement={handleRenderLoading}
        scrollbar={false}
        data={data}
        emptyContainerStyle={{flex: 0}}
      />
    </StyledContainer>
  )
}
