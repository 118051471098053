import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_BULK_LIMIT, GIFTSHOP_UPLOAD_VIDEO_LIMIT} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopTemplateHostFeeModal,
  GiftShopTemplateZeroPriceModal,
} from 'pages'
import {TreeSearchFototreeResponse, WindowModeType} from 'types'
import {getBorder, showSnackbar, useGiftshopUploadPublish} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadPublishProps} from './GiftShopUploadPublishProps'
import GiftShopUploadPublishModalElaborate from './GiftShopUploadPublishModalElaborate'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledButtonProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    borderTop: getBorder(1, 'solid', theme.gray_1),
    padding: `${convertUnit(15)} ${convertUnit(mode === 'mobile' ? 25 : 50)}`,
    backgroundColor: theme.white_1,
    justifyContent: 'space-between',
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  flex: 1;
  margin-right: ${convertUnit(25)};
`

const StyledButton = styled(Button)<StyledButtonProps>`
  flex: 1;
  ${({mode}) => ({
    flex: 1,
    width: mode === 'website' ? convertUnit(200) : '100%',
  })}
`

export default function GiftShopUploadPublishBulk({
  total,
  data,
  formRef,
  mapRef,
  uploadType,
  videoFaceData,
  hideModal,
  onView,
}: GiftShopUploadPublishProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {publish, retry} = useGiftshopUploadPublish({
    data,
    mapRef,
    formRef,
    videoFaceData,
    uploadType,
  })
  const {active, mapForm} = mapRef.map.current
  const [modal, setModal] = useState<'zeroprice' | 'hostfee' | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState(false)

  const handleToggleModal = useCallback(() => {
    setModal(undefined)
  }, [])

  const handleCheckZeroPriceAndHostFee = useCallback(
    async (price: string, tag?: TreeSearchFototreeResponse) => {
      if (price === '') {
        showSnackbar(translate('giftShop:uploadPriceEmpty'))
        return false
      }
      if (parseInt(price, 10) === 0) {
        setModal('zeroprice')
        return false
      }
      if (!!tag && tag.host_fee) {
        setModal('hostfee')
        return false
      }
      return true
    },
    [translate],
  )

  const handleCheckLocation = useCallback(
    async (location: string, tags: TreeSearchFototreeResponse[]) => {
      if (location === '' && tags.length === 0) {
        showSnackbar(
          translate('giftShop:uploadNeedLocationOrFototreeErrorMessage'),
        )
        return false
      }
      return true
    },
    [translate],
  )

  const handleCheckBulkUploadRequirement = useCallback(async () => {
    const formData = Object.values(formRef.current)
    const dt = formData[0]

    setLoading(true)

    const checkLocation = await handleCheckLocation(dt.location, dt.tags)

    if (!checkLocation) {
      setLoading(false)
      return
    }

    const checkPriceAndHostFee = await handleCheckZeroPriceAndHostFee(
      dt.price,
      dt.tags[0],
    )

    if (checkLocation && checkPriceAndHostFee) {
      publish()
    } else {
      setLoading(false)
    }
  }, [formRef, handleCheckLocation, handleCheckZeroPriceAndHostFee, publish])

  const handleRenderZeroPriceConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateZeroPriceModal
        visible
        onConfirm={() => {
          setLoading(true)
          publish()
        }}
        toggleModal={handleToggleModal}
        onCancel={() => setLoading(false)}
      />
    ),
    [handleToggleModal, publish],
  )

  const handleRenderHostFeeConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateHostFeeModal
        visible
        onConfirm={() => {
          setLoading(true)
          publish()
        }}
        onCancel={() => setLoading(false)}
        toggleModal={handleToggleModal}
      />
    ),
    [handleToggleModal, publish],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'hostfee':
        return handleRenderHostFeeConfirmationModal
      case 'zeroprice':
        return handleRenderZeroPriceConfirmationModal
      default:
        return <></>
    }
  }, [
    handleRenderHostFeeConfirmationModal,
    handleRenderZeroPriceConfirmationModal,
    modal,
  ])

  return (
    <StyledContainer mode={mode}>
      {handleRenderModal}
      <GiftShopUploadPublishModalElaborate
        mapForm={mapForm}
        onRetry={retry}
        onView={(tab) => {
          if (onView) onView(tab)
        }}
        toggle={active && !hideModal}
      />
      <StyledParagraph fontSize="l" fontWeight="medium">
        {translate('giftShop:contentCount', {
          count: total,
          max:
            uploadType === 'video'
              ? GIFTSHOP_UPLOAD_VIDEO_LIMIT
              : GIFTSHOP_UPLOAD_BULK_LIMIT,
        })}
      </StyledParagraph>
      <StyledButton
        mode={mode}
        label={translate('global:publish')}
        onClick={() => {
          handleCheckBulkUploadRequirement()
        }}
        backgroundColor="primary_5"
        isLoading={loading}
        disabled={loading || active}
      />
    </StyledContainer>
  )
}
