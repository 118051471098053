import React, {useCallback, useMemo, useState} from 'react'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import {requestData} from 'services'
import {Paragraph} from 'common/components'
import {useDidMount, useHistory} from 'utils'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'
import {
  getMessageContentIds,
  getMessagePrice,
  updateMessagePrice,
} from '../GiftshopMessageArrayHelper'

export interface GiftShopMessageRoomContentItemNotificationPurchaseProps {
  payload: FirebaseMessageRoomItemPayload['notification_purchase']
}

export default function GiftShopMessageRoomContentItemNotificationPurchase({
  payload: {contents, data: contentData},
}: GiftShopMessageRoomContentItemNotificationPurchaseProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const contentIds = getMessageContentIds(contentData, contents)
  const prices = getMessagePrice(contentData)

  const handleLoadData = useCallback(
    () =>
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          result = updateMessagePrice(result, contentIds, prices)
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <Paragraph fontSize="m">
        {translate('messages:notificationPurchase', {
          username,
        })}
      </Paragraph>
    ),
    [username, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
    </>
  )
}
