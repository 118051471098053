import React, {useMemo} from 'react'
import styled from 'styled-components'
import {HOST_MAX_PRICE, HOST_MIN_PRICE, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getCurrencyValue} from 'utils'
import {Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeEditProfileContentItem} from '../ContentItem'
import {TreeEditProfileContentProps} from './TreeEditProfileContentProps'
import {TreeEditProfileCover} from '../Cover'
import {TreeEditProfileFirstBuy} from '../Form'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.white_1};
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledFormsContainer = styled.div`
  padding: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: ${convertUnit(20)};
    margin-top: ${convertUnit(40)};
  }
`

const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledItems = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledSettingRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledSettingColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeEditProfileContent({
  stateActiveContent,
}: TreeEditProfileContentProps) {
  const {translate} = useTranslation()
  const {
    coverPhoto,
    logoPhoto,
    tagName,
    biodata,
    minimumPrice,
    maximumPrice,
    independentCreatorFee,
    internalCreatorFee,
  } = useSelector('hostState')
  const setActiveContent = stateActiveContent[1]

  const handleRenderHashtag = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          breakWord
          title={translate('tree:addHostInfoHostNameLabel')}
          value={tagName}
          onChange={() => setActiveContent('hostName')}
        />
      </StyledItems>
    ),
    [setActiveContent, tagName, translate],
  )

  const handleRenderBio = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          title={translate('tree:editHostProfileBioTitle')}
          value={biodata}
          emptyText={translate('tree:editHostProfileBioEmpty')}
          onChange={() => setActiveContent('bio')}
        />
      </StyledItems>
    ),
    [biodata, setActiveContent, translate],
  )

  const handleRenderMinPrice = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          smallTitle
          title={translate('tree:editHostProfileMinPriceTitle')}
          value={getCurrencyValue(minimumPrice ?? HOST_MIN_PRICE)}
          onChange={() => setActiveContent('minPrice')}
        />
      </StyledItems>
    ),
    [minimumPrice, setActiveContent, translate],
  )

  const handleRenderMaxPrice = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          smallTitle
          title={translate('tree:editHostProfileMaxPriceTitle')}
          value={getCurrencyValue(maximumPrice ?? HOST_MAX_PRICE)}
          onChange={() => setActiveContent('maxPrice')}
        />
      </StyledItems>
    ),
    [maximumPrice, setActiveContent, translate],
  )

  const handleRenderSetingCreatorPrice = useMemo(
    () => (
      <StyledSettingColumnContainer>
        <Paragraph fontSize="m" fontWeight="medium" color="gray_5">
          {translate('tree:editHostProfileSettingPriceTitle')}
        </Paragraph>
        <StyledSettingRowContainer>
          {handleRenderMinPrice}
          {handleRenderMaxPrice}
        </StyledSettingRowContainer>
      </StyledSettingColumnContainer>
    ),
    [handleRenderMaxPrice, handleRenderMinPrice, translate],
  )

  const handleRenderHostFeeIndependentCreator = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          smallTitle
          title={translate('tree:editHostIndependentOfficialCreatorTitle')}
          value={`${independentCreatorFee?.toString()}%`}
          onChange={() => setActiveContent('hostFeeIndependent')}
        />
      </StyledItems>
    ),
    [independentCreatorFee, setActiveContent, translate],
  )

  const handleRenderHostFeeInternalCreator = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileContentItem
          smallTitle
          title={translate('tree:editHostInternalOfficialCreatorTitle')}
          value={`${internalCreatorFee?.toString()}%`}
          onChange={() => setActiveContent('hostFeeInternal')}
        />
      </StyledItems>
    ),
    [internalCreatorFee, setActiveContent, translate],
  )

  const handleRenderHostFee = useMemo(
    () => (
      <StyledSettingColumnContainer>
        <Paragraph fontSize="m" fontWeight="medium" color="gray_5">
          {translate('tree:editHostFormLabelHostFee')}
        </Paragraph>
        <StyledSettingRowContainer>
          {handleRenderHostFeeInternalCreator}
          {handleRenderHostFeeIndependentCreator}
        </StyledSettingRowContainer>
      </StyledSettingColumnContainer>
    ),
    [
      handleRenderHostFeeIndependentCreator,
      handleRenderHostFeeInternalCreator,
      translate,
    ],
  )

  const handleRenderFirstBuy = useMemo(
    () => (
      <StyledItems>
        <TreeEditProfileFirstBuy />
      </StyledItems>
    ),
    [],
  )

  const handleRenderUpperSection = useMemo(
    () => (
      <StyledItemsContainer>
        {handleRenderHashtag}
        {handleRenderBio}
      </StyledItemsContainer>
    ),
    [handleRenderBio, handleRenderHashtag],
  )

  const handleRenderBottomSection = useMemo(
    () => (
      <StyledItemsContainer>
        {handleRenderSetingCreatorPrice}
        {handleRenderHostFee}
      </StyledItemsContainer>
    ),
    [handleRenderHostFee, handleRenderSetingCreatorPrice],
  )

  const handleRenderFirstBuySection = useMemo(
    () => (
      <StyledItemsContainer>
        <StyledItems />
        {handleRenderFirstBuy}
      </StyledItemsContainer>
    ),
    [handleRenderFirstBuy],
  )

  return (
    <StyledContainer>
      <TreeEditProfileCover coverPhoto={coverPhoto} logoPhoto={logoPhoto} />
      <StyledFormsContainer>
        {handleRenderUpperSection}
        {handleRenderBottomSection}
        {handleRenderFirstBuySection}
      </StyledFormsContainer>
    </StyledContainer>
  )
}
