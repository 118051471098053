import React, {useState, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {getBorder, getFontSize, useInputHandler} from 'utils'
import {FontSizeType, FontWeightType} from 'types'
import {ThemeColor} from 'themes'
import {FONT_FAMILY_MAP} from 'consts'
import convertUnit from 'lib/unit'
import {InputProps} from './InputProps'
import {InputIcon} from '../InputIcon'
import {InputBase} from '../InputBase'
import {Paragraph} from '../Paragraph'

interface StyledInputProps {
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  placeholderColor?: ThemeColor
  disabled?: boolean
  disabledColor?: ThemeColor
  borderColor?: ThemeColor
}

const StyledInput = styled.input<StyledInputProps>`
  ${({
    theme,
    color = 'black',
    placeholderColor = 'gray_3',
    fontSize = 'm',
    fontWeight = 'regular',
    disabledColor = 'gray_2',
    disabled,
  }) => ({
    fontSize: getFontSize(fontSize),
    fontFamily: FONT_FAMILY_MAP[fontWeight],
    color: disabled ? theme[disabledColor] : theme[color],
    '::placeholder': {
      color: theme[placeholderColor],
    },
    backgroundColor: disabled ? theme.white_3 : 'inherit',
    borderColor: theme.gray_6,
  })}
  flex: 1;
  height: ${convertUnit(42)};
  box-sizing: border-box;
  border-style: none;
  outline: none;
  min-width: 0;
  -webkit-appearance: none;
  padding: ${convertUnit(16)} ${convertUnit(14)};
`

const StyledLeftText = styled(Paragraph)`
  border-right: ${({theme}) => getBorder(1, 'solid', theme.white_3)};
  padding: 0 ${convertUnit(16)};
`

export default function Input<TFieldValues extends object = object>({
  label,
  labelDescription,
  name,
  type: baseType = 'text',
  containerStyle,
  leftIcon,
  rightIcon,
  required,
  form,
  formRules,
  autoComplete,
  allowedCharacters,
  leftText,
  maxLength,
  min,
  max,
  disabled = false,
  inputStyle,
  rightIconStyle,
  disabledColor,
  borderColor,
  iconColor,
  onChangeText,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  onRightIconClick,
  ...props
}: InputProps<TFieldValues>) {
  const stateType = useState(baseType)
  const ref = useRef<HTMLInputElement | null>(null)
  const handler = useInputHandler<TFieldValues, HTMLInputElement>({
    refElement: ref,
    allowedCharacters,
    form,
    formRules,
    name,
    onBlur,
    onChange,
    onChangeText,
    onFocus,
    onKeyPress,
  })
  const {
    isFocused,
    error,
    errorType,
    showError,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyPress,
    handleRef,
  } = handler
  const type = stateType[0]

  const handleRenderInput = useMemo(
    () => (
      <>
        <InputIcon
          inputRef={ref}
          baseType={baseType}
          form={form}
          stateType={stateType}
          component={leftIcon}
          iconColor={iconColor}
        />
        {leftText && (
          <StyledLeftText fontSize="m" color="gray_5">
            {leftText}
          </StyledLeftText>
        )}
        <StyledInput
          {...props}
          name={name}
          ref={handleRef}
          type={type}
          autoComplete={autoComplete === false ? 'off' : undefined}
          size={1}
          maxLength={maxLength}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          min={min}
          max={max}
          style={inputStyle}
          disabled={disabled}
          disabledColor={disabledColor}
        />
        <InputIcon
          inputRef={ref}
          baseType={baseType}
          form={form}
          stateType={stateType}
          component={rightIcon}
          onClick={onRightIconClick}
          style={rightIconStyle}
          iconColor={iconColor}
        />
      </>
    ),
    [
      baseType,
      form,
      stateType,
      leftIcon,
      iconColor,
      leftText,
      props,
      name,
      handleRef,
      type,
      autoComplete,
      maxLength,
      handleChange,
      handleFocus,
      handleBlur,
      handleKeyPress,
      min,
      max,
      inputStyle,
      disabled,
      disabledColor,
      rightIcon,
      onRightIconClick,
      rightIconStyle,
    ],
  )

  return (
    <InputBase
      {...props}
      containerStyle={containerStyle}
      formRules={formRules}
      label={label}
      labelDescription={labelDescription}
      refValue={ref?.current?.value}
      required={required}
      inputElement={handleRenderInput}
      isFocused={isFocused}
      error={error}
      errorType={errorType}
      showError={showError}
      borderColor={borderColor}
    />
  )
}
