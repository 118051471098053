import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {YOUR_PRICE_FEE, YOUR_PRICE_FEE_MOBILE} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopPricingDetailProps} from 'pages'
import {getCurrencyValue} from 'utils'
import {ThemeColor} from 'themes'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'

interface StyledProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledProps>`
  ${({mode}) =>
    mode !== 'website'
      ? {padding: convertUnit(20)}
      : {
          paddingBottom: convertUnit(10),
          paddingRight: convertUnit(20),
          paddingLeft: convertUnit(20),
        }}
  background-color: ${({theme}) => theme.white_2};
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${convertUnit(8)};
`

const StyledPricing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledSeperatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-top: ${convertUnit(2)};
  margin-bottom: ${convertUnit(2)};
`

const StyledSeperator = styled.div`
  background-color: ${({theme}) => theme.gray_3};
  display: flex;
  flex: 1;
  height: ${convertUnit(1)};
`

const StyledSeperatorText = styled(Paragraph)`
  margin-left: ${convertUnit(5)};
`

interface StyledTextProps {
  total?: Boolean
  color?: ThemeColor
}

const StyledText = styled(Paragraph)<StyledTextProps>`
  ${({theme, total, color = 'gray_3'}) => ({
    color: total ? theme.primary_5 : theme[color],
  })}
  white-space: pre-wrap;
  padding: ${convertUnit(2)};
`

export default function GiftShopPricingDetail({
  price,
  platform,
  data,
}: GiftShopPricingDetailProps) {
  const {translate} = useTranslation()
  const feePercent = platform === 'Web' ? YOUR_PRICE_FEE : YOUR_PRICE_FEE_MOBILE
  const mode = useWindowMode()

  const pricingDetails = useCallback(() => {
    switch (platform) {
      case 'Apple':
        return {
          serviceFee: data.service_fee.apple,
          fotoyuFee: data.fotoyu_fee.apple,
          hostFee: data.host_fee.apple,
          finalPrice: data.price.apple.final,
          netCreator: data.price.apple.net_creator,
        }
      case 'Google':
        return {
          serviceFee: data.service_fee.google,
          fotoyuFee: data.fotoyu_fee.google,
          hostFee: data.host_fee.google,
          finalPrice: data.price.google.final,
          netCreator: data.price.google.net_creator,
        }
      default:
        return {
          serviceFee: data.service_fee.web,
          fotoyuFee: data.fotoyu_fee.web,
          hostFee: data.host_fee.web,
          finalPrice: data.price.web.final,
          netCreator: data.price.web.net_creator,
        }
    }
  }, [data, platform])

  const handleSeperator = useMemo(
    () => (
      <StyledSeperatorContainer>
        <StyledSeperator />
        <StyledSeperatorText fontWeight="bold" color="gray_3">
          {'+'}
        </StyledSeperatorText>
      </StyledSeperatorContainer>
    ),
    [],
  )

  const handleFirstParagraph = useMemo(
    () => (
      <>
        <StyledPricing>
          <StyledText>
            {translate('giftShop:priceNet', {
              count: pricingDetails().netCreator.percentage,
            })}
          </StyledText>
          <StyledText total fontWeight="bold">
            {getCurrencyValue(pricingDetails().netCreator.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledPricing>
          <StyledText>
            {translate('giftShop:pricefee_forky', {
              count: pricingDetails().fotoyuFee.percentage,
            })}
          </StyledText>
          <StyledText fontWeight="bold">
            {getCurrencyValue(pricingDetails().fotoyuFee.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledPricing>
          <StyledText>
            {translate('giftShop:pricefee_host', {
              count: pricingDetails().hostFee.percentage,
            })}
          </StyledText>
          <StyledText fontWeight="bold">
            {getCurrencyValue(pricingDetails().hostFee.nominal)}
          </StyledText>
        </StyledPricing>
      </>
    ),
    [pricingDetails, translate],
  )

  const handleSecondParagraph = useMemo(
    () => (
      <>
        <StyledPricing>
          <StyledText>
            {translate('giftShop:pricefee', {count: feePercent})}
          </StyledText>
          <StyledText fontWeight="bold">{getCurrencyValue(price)}</StyledText>
        </StyledPricing>
        <StyledPricing>
          <StyledText>
            {translate('giftShop:pricefee_paymentservice')}
          </StyledText>
          <StyledText fontWeight="bold">
            {getCurrencyValue(pricingDetails().serviceFee.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledText>
          {translate('giftShop:pricefee_paymentservicepercentage', {
            count: pricingDetails().serviceFee.percentage,
          })}
        </StyledText>
      </>
    ),
    [feePercent, price, pricingDetails, translate],
  )

  const handleFinalPrice = useMemo(
    () => (
      <>
        <StyledPricing>
          <StyledText fontWeight="bold" color="black">
            {translate('giftShop:collectorPay')}
          </StyledText>
          <StyledText total fontWeight="bold">
            {getCurrencyValue(pricingDetails().finalPrice.nominal)}
          </StyledText>
        </StyledPricing>
      </>
    ),
    [pricingDetails, translate],
  )

  return (
    <StyledContainer mode={mode}>
      {handleFirstParagraph}
      {handleSeperator}
      {handleSecondParagraph}
      {handleSeperator}
      {handleFinalPrice}
    </StyledContainer>
  )
}
