import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUDIO_ASSET, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopKYCRecordStatus, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {Image, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const StyledOutlineOverlay = styled(Image)`
  padding-top: ${convertUnit(40)};
  height: 90%;
`
const StyledIndicatorContainer = styled.div<StyledContainerProps>`
  flex-direction: row;
  position: absolute;
  top: ${convertUnit(20)};
  right: 0;
  left: 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: ${convertUnit(40)};
  padding-right: ${convertUnit(40)};
`
const StyledRecordIcon = styled(Image)`
  width: ${convertUnit(50)};
`
const StyledBottomContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: ${convertUnit(20)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export interface GiftShopRobopetActivationSelfieOverlayRecordProps {
  progress: GiftShopKYCRecordStatus
  isRecording: boolean
  onAudioEnd(): void
  isEasySignup?: boolean
}
export default function GiftShopRobopetActivationSelfieOverlayRecord({
  progress,
  isRecording,
  onAudioEnd,
  isEasySignup,
}: GiftShopRobopetActivationSelfieOverlayRecordProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const faceFront = IMAGE_ASSET('giftshop', 'front-face.png')
  const faceLeft = IMAGE_ASSET('giftshop', 'left-face.png')
  const overlayFront = IMAGE_ASSET('giftshop', 'front-outline.png')
  const overlayLeft = IMAGE_ASSET('giftshop', 'left-outline.png')
  const audioFront = AUDIO_ASSET('giftshop', 'en-part-1.mp3')
  const audioFrontId = AUDIO_ASSET('giftshop', 'id-part-1.mp3')
  const audioEasySignupFront = AUDIO_ASSET('giftshop', 'en-kyc-1.mp3')
  const audioEasySignupSide = AUDIO_ASSET('giftshop', 'en-kyc-2.mp3')
  const audioEasySignupFrontId = AUDIO_ASSET('giftshop', 'id-kyc-1.mp3')
  const audioEasySignupSideId = AUDIO_ASSET('giftshop', 'id-kyc-2.mp3')

  const recordGif = IMAGE_ASSET('giftshop', 'record.gif')
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio())
  const {lang} = useSelector('languageState')

  const handleRenderHeadOverlay = useMemo(() => {
    switch (progress) {
      case 'front':
        return faceFront
      case 'side':
        return faceLeft
      default:
        return faceFront
    }
  }, [faceFront, faceLeft, progress])

  const handleRenderFaceOutlineOverlay = useMemo(() => {
    switch (progress) {
      case 'front':
        return overlayFront
      case 'side':
        return overlayLeft
      default:
        return overlayFront
    }
  }, [overlayFront, overlayLeft, progress])

  const handleAudio = useCallback(
    (audioAsset: HTMLAudioElement) => {
      audio.pause()
      switch (progress) {
        case 'front':
          audioAsset.src = isEasySignup
            ? lang === 'en'
              ? audioEasySignupFront
              : audioEasySignupFrontId
            : lang && lang === 'en'
            ? audioFront
            : audioFrontId
          break
        case 'side':
          audioAsset.src =
            lang === 'en' ? audioEasySignupSide : audioEasySignupSideId
          break
        default:
          audioAsset.src = lang && lang === 'en' ? audioFront : audioFrontId
          break
      }
      audioAsset.load()
      if (audioAsset.volume > 0.15) audioAsset.volume = 0.15
      audioAsset.play().catch(() => {})
      return audioAsset
    },
    [
      audio,
      audioEasySignupFront,
      audioEasySignupFrontId,
      audioEasySignupSide,
      audioEasySignupSideId,
      audioFront,
      audioFrontId,
      isEasySignup,
      lang,
      progress,
    ],
  )

  const handleUpdateAudio = useCallback(() => {
    setAudio((prev) => handleAudio(prev))
  }, [handleAudio])

  useEffect(() => {
    handleUpdateAudio()
    if (progress === 'done') {
      audio.pause()
    }
    audio.addEventListener('ended', onAudioEnd)
    return () => audio.removeEventListener('ended', onAudioEnd)
  }, [audio, handleUpdateAudio, onAudioEnd, progress])

  return (
    <StyledContainer>
      <StyledIndicatorContainer mode={mode}>
        <Image src={handleRenderHeadOverlay} alt="" />
        {isRecording ? <StyledRecordIcon src={recordGif} alt="" /> : <></>}
      </StyledIndicatorContainer>
      <StyledOutlineOverlay src={handleRenderFaceOutlineOverlay} alt="" />
      <StyledBottomContainer>
        <StyledParagraph fontSize="xl" color="white_1">
          {translate('giftShop:kycRecord', {context: progress})}
        </StyledParagraph>
      </StyledBottomContainer>
    </StyledContainer>
  )
}
