import React, {useMemo, useRef} from 'react'
import styled from 'styled-components'
import {getFontSize, useInputHandler} from 'utils'
import {FontSizeType, FontWeightType} from 'types'
import {ThemeColor} from 'themes'
import {FONT_FAMILY_MAP} from 'consts'
import convertUnit from 'lib/unit'
import {InputAreaProps} from './InputAreaProps'
import {InputBase} from '../InputBase'

interface StyledInputProps {
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  placeholderColor?: ThemeColor
}

const StyledInput = styled.textarea<StyledInputProps>`
  ${({
    theme,
    color = 'black',
    placeholderColor = 'gray_3',
    fontSize = 'm',
    fontWeight = 'regular',
  }) => ({
    fontSize: getFontSize(fontSize),
    fontFamily: FONT_FAMILY_MAP[fontWeight],
    color: theme[color],
    '::placeholder': {
      color: theme[placeholderColor],
    },
  })}
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-style: none;
  outline: none;
  padding: ${convertUnit(14)} ${convertUnit(16)};
  background-color: inherit;
  -webkit-appearance: none;
`

export default function Input<TFieldValues extends object = object>({
  label,
  name,
  containerStyle,
  form,
  formRules,
  autoComplete,
  allowedCharacters,
  maxLength,
  inputStyle,
  multiline,
  borderColor,
  autosize,
  onChangeText,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  ...props
}: InputAreaProps<TFieldValues>) {
  const ref = useRef<HTMLTextAreaElement | null>(null)
  const handler = useInputHandler<TFieldValues, HTMLTextAreaElement>({
    refElement: ref,
    allowedCharacters,
    form,
    formRules,
    name,
    multiline,
    autosize,
    onBlur,
    onChange,
    onChangeText,
    onFocus,
    onKeyPress,
  })
  const {
    isFocused,
    error,
    errorType,
    showError,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyPress,
    handleRef,
  } = handler

  const handleRenderInput = useMemo(
    () => (
      <StyledInput
        {...props}
        name={name}
        ref={handleRef}
        autoComplete={autoComplete === false ? 'off' : undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        maxLength={maxLength}
        style={inputStyle}
      />
    ),
    [
      props,
      name,
      handleRef,
      autoComplete,
      handleChange,
      handleFocus,
      handleBlur,
      handleKeyPress,
      maxLength,
      inputStyle,
    ],
  )

  return (
    <InputBase
      {...props}
      containerStyle={containerStyle}
      formRules={formRules}
      label={label}
      refValue={ref?.current?.value}
      inputElement={handleRenderInput}
      isFocused={isFocused}
      error={error}
      errorType={errorType}
      showError={showError}
      borderColor={borderColor}
    />
  )
}
