import {
  GiftShopExploreDetailTagResponse,
  TreeSearchFototreeResponse,
} from 'types'

export function convertTagToSearchTreeItem(
  tag: GiftShopExploreDetailTagResponse,
): TreeSearchFototreeResponse {
  return {
    ...tag,
    alias: '',
    category_name: 'event',
    distance: '',
    owner_id: '',
    tag_id: tag.id,
    url: '',
    bio: tag.bio || '',
    location: {
      latitude: tag.location?.latitude || 0,
      longitude: tag.location?.longitude || 0,
      name: tag.location?.name || '',
    },
    status: tag.status || 'VERIFIED',
    event_end_date: tag.event_end_date,
    event_start_date: tag.event_start_date,
  }
}

export function convertSearchTreeItemToTag(
  tag: TreeSearchFototreeResponse,
): GiftShopExploreDetailTagResponse {
  return {
    ...tag,
    id: tag.tag_id,
    type: tag.type!,
    is_bib: tag.is_bib!,
    host_fee: tag.host_fee || 0,
  }
}
