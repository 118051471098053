import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { LayoutDimension, NetworkStatus } from 'types'
import { getWindowMode } from 'utils'
import {
  WindowLayoutContext,
  WindowModeContext,
  WindowNetworkStatusContext,
} from './WindowContext'

export interface WindowProviderProps {
  children: ReactNode
}

export default function WindowProvider({
  children,
}: WindowProviderProps) {
  const [layout, setLayout] = useState<LayoutDimension>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
    navigator.onLine ? 'online' : 'offline',
  )
  const [mode, setMode] = useState(getWindowMode())

  const handleResize = useCallback(() => {
    setLayout({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    setMode(getWindowMode())
  }, [])

  const handleNetworkStatus = useCallback(
    () => setNetworkStatus(navigator.onLine ? 'online' : 'offline'),
    [],
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    window.addEventListener('online', handleNetworkStatus)
    window.addEventListener('offline', handleNetworkStatus)

    return () => {
      window.removeEventListener('online', handleNetworkStatus)
      window.removeEventListener('offline', handleNetworkStatus)
    }
  }, [handleNetworkStatus])

  return (
    <WindowLayoutContext.Provider value={layout}>
      <WindowModeContext.Provider value={mode}>
        <WindowNetworkStatusContext.Provider value={networkStatus}>
          {children}
        </WindowNetworkStatusContext.Provider>
      </WindowModeContext.Provider>
    </WindowLayoutContext.Provider>
  )
}
