import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthFormSimpleRegisterData, ObjectState} from 'types'
import {ThemeColor} from 'themes'
import {Button, Icon, Input, Modal, Paragraph} from 'common/components'
import {
  AUTH_PASSWORD_MAX_LENGTH,
  AUTH_PASSWORD_MIN_LENGTH,
  REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY,
  REGEX_NO_DASH_UNDERSCORE_PERIOD,
  REGEX_PASSWORD_MIX,
} from 'consts'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {requestData} from 'services'
import {showSnackbar} from 'utils'

const StyledInputContainer = styled.div`
  margin: ${convertUnit(16)} 0;
`
const StyledErrorMessage = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`
const StyledIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${convertUnit(8)};
`
const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(16)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`

interface GiftShopTemplateModalPasswordProps {
  stateModal: ObjectState<boolean>
  onCancel?(): void
  onSuccess?(): void
}

export default function GiftShopTemplateModalPassword({
  stateModal,
  onCancel,
  onSuccess,
}: GiftShopTemplateModalPasswordProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const [modalPassword, setModalPassword] = stateModal
  const [passwordError, setPasswordError] = useState('')
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      password: '',
      confirm: '',
    },
  })
  const {watch} = form
  const {password, confirm} = watch()
  const [loading, setLoading] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState<boolean>()
  const [minLength, setMinLength] = useState<boolean>()
  const [passwordRegex, setPasswordRegex] = useState<boolean>()

  const isPasswordDisabled = useMemo(
    () =>
      password === '' ||
      confirm === '' ||
      !minLength ||
      !passwordRegex ||
      !passwordMatch,
    [confirm, minLength, password, passwordMatch, passwordRegex],
  )

  const minLengthColor = useMemo<ThemeColor>(
    () =>
      minLength === undefined ? 'gray_3' : minLength ? 'success_5' : 'danger_5',
    [minLength],
  )

  const passwordRegexColor = useMemo<ThemeColor>(
    () =>
      passwordRegex === undefined
        ? 'gray_3'
        : passwordRegex
        ? 'success_5'
        : 'danger_5',
    [passwordRegex],
  )

  const handleRenderError = useMemo(
    () =>
      passwordError ? (
        <Paragraph fontWeight="medium" color="danger_5">
          {passwordError}
        </Paragraph>
      ) : (
        <></>
      ),
    [passwordError],
  )

  const handleRenderPasswordInput = useMemo(
    () => (
      <>
        <Input
          label={translate('global:password')}
          form={form}
          name="password"
          type="password"
          onChangeText={(text) => {
            setPasswordError('');
            setMinLength(text.length >= AUTH_PASSWORD_MIN_LENGTH);
            setPasswordRegex(
              REGEX_NO_DASH_UNDERSCORE_PERIOD.test(text) &&
                REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY.test(text) &&
                REGEX_PASSWORD_MIX.test(text),
            );
          }}
          rightIcon="visibility"
          autoCapitalize="none"
          placeholder={translate('auth:registerPasswordInputPlaceholder')}
          maxLength={AUTH_PASSWORD_MAX_LENGTH}
        />
        <StyledInputContainer>
          <Input
            label={translate('global:passwordConfirm')}
            form={form}
            onChangeText={() => setPasswordError('')}
            name="confirm"
            type="password"
            autoCapitalize="none"
            placeholder={translate(
              'auth:createPasswordSSOConfirmationPlaceholder',
            )}
            rightIcon="visibility"
            maxLength={AUTH_PASSWORD_MAX_LENGTH}
          />
          {!passwordMatch && (
            <StyledErrorMessage
              fontWeight="medium"
              color="danger_5">
              {translate('auth:confirmationPassword')}
            </StyledErrorMessage>
          )}
          {handleRenderError}
        </StyledInputContainer>
      </>
    ),
    [form, handleRenderError, passwordMatch, setPasswordError, translate],
  )

  const handleRenderValidation = useMemo(
    () => (
      <>
        <StyledIndicator>
          <Icon type="check" color={minLengthColor} />
          <Paragraph fontWeight="medium" color={minLengthColor}>
            {translate('auth:generalValidationMinLength', {length: AUTH_PASSWORD_MIN_LENGTH})}
          </Paragraph>
        </StyledIndicator>
        <StyledIndicator style={{marginBottom: 0}}>
          <Icon type="check" color={passwordRegexColor} />
          <Paragraph fontWeight="medium" color={passwordRegexColor}>
            {translate('auth:generalValidationAlphanumeric')}
          </Paragraph>
        </StyledIndicator>
      </>
    ),
    [minLengthColor, passwordRegexColor, translate],
  )

  const handleCancel = useCallback(() => {
    setModalPassword(false)
    setPasswordError('')
    onCancel && onCancel()
  }, [onCancel, setModalPassword])

  const handleSave = useCallback(() => {
    setLoading(true)
    requestData('auth_patch_passwordsso', {
      data: {password},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('auth:successCreatePassword'))
          update('user', {has_password: true})
          setModalPassword(false)
          onSuccess && onSuccess()
        } else {
          showSnackbar(translate('global:messageError', {context: status}))
        }
      },
    })
  }, [onSuccess, password, setModalPassword, translate, update])

  useEffect(() => {
    setPasswordMatch(confirm === '' || confirm === password)
  }, [confirm, password])

  return (
    <StyledModal visible={modalPassword}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('auth:registerEasySignupCreatePassword')}
      </StyledTitle>
      {handleRenderPasswordInput}
      {handleRenderValidation}
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={handleCancel} />
        <StyledButton
          label={translate('global:save')}
          disabled={isPasswordDisabled}
          isLoading={loading}
          onClick={handleSave} />
      </StyledButtonContainer>
    </StyledModal>
  )
}
