import {GIFT_SHOP_CACHE_EXPIRATION_DAYS} from 'consts'
import {Cache} from 'types'
import {REDUX_STORE} from 'lib/redux'

interface CacheData<ItemT> {
  data: ItemT
  date: Date
}

export function getCacheKey(cache: Cache) {
  const userId = REDUX_STORE.getState().user?.id

  if (userId) {
    if (typeof cache === 'string') {
      return cache.endsWith(`/${userId}`) ? cache : `${cache}/${userId}`
    }
    return cache.key.endsWith(`/${userId}`)
      ? `${cache.key}/${cache.id}`
      : `${cache.key}/${userId}/${cache.id}`
  }
  return null
}

export function setCacheData(cache: Cache, value: any) {
  const key = getCacheKey(cache)
  const cachedData = {
    data: value,
    date: new Date(),
  }
  if (key) {
    return localStorage.setItem(key, JSON.stringify(cachedData))
  }
}

export async function getCacheData<ItemT>(
  cache: Cache,
  defaultValue?: ItemT,
): Promise<ItemT | null> {
  const key = getCacheKey(cache)
  if (key) {
    const cachedData = localStorage.getItem(key)

    if (cachedData) {
      const result: CacheData<ItemT> = await JSON.parse(cachedData)
      const expirationDate = new Date(result.date)
      expirationDate.setDate(
        expirationDate.getDate() + GIFT_SHOP_CACHE_EXPIRATION_DAYS,
      )
      const currentDate = new Date()
      if (expirationDate.getTime() > currentDate.getTime()) {
        return result.data
      }
      localStorage.removeItem(key)
    }
  }
  return defaultValue || null
}

export function clearUserCache(cache?: Cache) {
  const userId = REDUX_STORE.getState().user?.id
  if (userId) {
    const cacheKey = cache ? `${cache}/${userId}` : `/${userId}`
    for (const key in localStorage) {
      if (key.includes(cacheKey)) {
        localStorage.removeItem(key)
      }
    }
  }
}
