import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {getFontFamily, getFontSize, getThemeColor} from 'utils'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
`
const StyledButton = styled.div`
  width: ${convertUnit(50)};
  height: ${convertUnit(50)};
  border-radius: ${convertUnit(50)};
  border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
  cursor: pointer;
  background-color: ${({theme}) => theme.danger_5};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${convertUnit(8)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledExampleTitle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledExample = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(10)};
`
const StyledExampleImage = styled(Image)`
  width: ${convertUnit(75)};
  margin-top: ${convertUnit(4)};
`
const StyledBottomContainer = styled.div`
  max-width: ${convertUnit(325)};
  position: absolute;
  flex-direction: column;
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  bottom: ${convertUnit(20)};
`
const StyledExampleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledExampleItemContainer = styled.div`
  margin-top: ${convertUnit(12)};
`
const StyledTitleContainer = styled.div`
  max-width: ${convertUnit(325)};
  position: absolute;
  flex-direction: column;
  margin-top: ${convertUnit(20)};
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  z-index: 1;
`
const StyledList = styled.ol`
  font-family: ${getFontFamily('medium')};
  font-size: ${getFontSize('s')};
  color: ${({theme}) => theme.white_1};
`
export interface GiftShopRobopetActivationSelfieOverlayProps {
  onClick(): void
}
export default function GiftShopRobopetActivationSelfieOverlay({
  onClick,
}: GiftShopRobopetActivationSelfieOverlayProps) {
  const {translate} = useTranslation()
  const exampleGood = IMAGE_ASSET('giftshop', 'ex-do-1.png')
  const exampleBad1 = IMAGE_ASSET('giftshop', 'ex-dont-1.png')
  const exampleBad2 = IMAGE_ASSET('giftshop', 'ex-dont-2.png')
  const exampleBad3 = IMAGE_ASSET('giftshop', 'ex-dont-3.png')

  const renderWarningItem = useCallback(
    (item: string) => (
      <li>
        <Paragraph fontWeight="medium" color="white_1">
          {item}
        </Paragraph>
      </li>
    ),
    [],
  )
  const renderExample = useMemo(
    () => (
      <StyledExampleContainer>
        <StyledExampleTitle>
          <Icon type="check" color="success_3" />
          <Paragraph fontWeight="bold" fontSize="l" color="success_3">
            {translate('giftShop:kycExampleGood')}
          </Paragraph>
        </StyledExampleTitle>
        <StyledExample>
          <StyledExampleImage src={exampleGood} alt="" />
        </StyledExample>
        <StyledExampleItemContainer>
          <StyledExampleTitle>
            <Icon type="close" color="danger_4" />
            <Paragraph fontWeight="bold" fontSize="l" color="danger_4">
              {translate('giftShop:kycExampleBad')}
            </Paragraph>
          </StyledExampleTitle>
          <StyledExample>
            <StyledExampleImage src={exampleBad1} alt="" />
            <StyledExampleImage src={exampleBad2} alt="" />
            <StyledExampleImage src={exampleBad3} alt="" />
          </StyledExample>
        </StyledExampleItemContainer>
      </StyledExampleContainer>
    ),
    [exampleBad1, exampleBad2, exampleBad3, exampleGood, translate],
  )
  return (
    <>
      <StyledTitleContainer>
        <StyledParagraph color="white_1" fontWeight="medium" fontSize="l">
          {translate('giftShop:kycFollowInstructionFull')}
        </StyledParagraph>
        <StyledList>
          {Array.from({length: 3}).map((_, idx) =>
            renderWarningItem(
              translate('giftShop:kycSelfieWarning', {context: `${idx + 1}`}),
            ),
          )}
        </StyledList>
      </StyledTitleContainer>
      <StyledContainer>{renderExample}</StyledContainer>
      <StyledBottomContainer>
        <StyledParagraph fontSize="m" color="white_1">
          {translate('giftShop:kycTapToStart')}
        </StyledParagraph>
        <StyledButtonContainer>
          <StyledButton onClick={onClick} />
        </StyledButtonContainer>
      </StyledBottomContainer>
    </>
  )
}
