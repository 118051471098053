import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Modal, Paragraph, Icon, Button} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useHistory} from 'utils'
import {GiftshopRetakeKYCModalProps} from './GiftshopRetakeKYCModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(250)};
  max-height: ${convertUnit(476)};
  padding: ${convertUnit(25)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    border-radius: ${convertUnit(18)};
    width: ${convertUnit(0)};
  }
`

const StyledTitle = styled(Paragraph)`
  width: 100%;
  text-align: center;
  margin-bottom: ${convertUnit(20)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(10)};
  width: 100%;
  margin-top: ${convertUnit(25)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

export default function GiftshopRetakeKYCModal({
  visible,
  toggleModal,
}: GiftshopRetakeKYCModalProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const handleRenderTitle = useMemo(
    () => (
      <>
        <Icon type="face-scan" />
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('giftShop:retakeKYC')}
        </StyledTitle>
      </>
    ),
    [translate],
  )

  const handleRenderBody = useMemo(
    () => (
      <Paragraph fontSize="m" fontWeight="regular">
        {translate('giftShop:retakeKYCMessage')}
      </Paragraph>
    ),
    [translate],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledButton
          label={translate('giftShop:retakeKYC')}
          onClick={() => {
            toggleModal()
            history.push('giftshop_robopet_retake', {})
          }}
        />
        <StyledButton
          label={translate('global:later')}
          backgroundColor="white_3"
          color="primary_5"
          onClick={toggleModal}
        />
      </StyledButtonsContainer>
    ),
    [history, toggleModal, translate],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderTitle}
      {handleRenderBody}
      {handleRenderButtons}
    </StyledModal>
  )
}
