import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledHeader = styled(Paragraph)`
  text-align: center;
`

const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${convertUnit(30)};
`

const StyledRobopet = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(226)};
  height: ${convertUnit(140)};
  flex-grow: 0;
`

const StyledParagraph = styled(Paragraph)`
  width: ${convertUnit(280)};
  text-align: center;
  display: inline;
`

const StyledParagraphContainer = styled.div`
  justify-content: center;
  margin-top: ${convertUnit(20)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

interface GiftShopRobopetChildIncompleteProps {
  onClick(): void
}
export default function GiftShopRobopetChildIncomplete({
  onClick,
}: GiftShopRobopetChildIncompleteProps) {
  const {translate} = useTranslation()
  const img = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <>
      <StyledHeader fontSize="xl" fontWeight="bold">
        {translate('auth:registerEasySignupChildReapplyTitle')}
      </StyledHeader>
      <StyledParagraphContainer>
        <StyledParagraph fontSize="l">
          {translate('auth:registerEasySignupChildReapplyDesc')}
        </StyledParagraph>
      </StyledParagraphContainer>
      <StyledImageContainer>
        <StyledRobopet src={img} alt="img" />
      </StyledImageContainer>
      <StyledParagraphContainer>
        <StyledParagraph fontSize="m" color="gray_5">
          {translate('auth:wefieReapplyModalDescription')}
        </StyledParagraph>
      </StyledParagraphContainer>
      <StyledButton label={translate('global:tryAgain')} onClick={onClick} />
    </>
  )
}
