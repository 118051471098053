import React from 'react'
import {useTranslation} from 'i18n'
import {showSnackbar} from 'utils'
import {ConfirmationModal} from 'common/components'
import {GiftShopTemplateHostFeeModalProps} from './GiftShopTemplateHostFeeModalProps'

export default function GiftShopTemplateHostFeeModal({
  visible,
  onCancel,
  onConfirm,
  toggleModal,
}: GiftShopTemplateHostFeeModalProps) {
  const {translate} = useTranslation()
  return (
    <ConfirmationModal
      visible={visible}
      title={translate('giftShop:uploadTagHostFeeModalTitle')}
      text={translate('giftShop:uploadTagHostFeeModalDescription')}
      cancelText={translate('global:edit')}
      confirmText={translate('global:yesImSure')}
      onConfirm={onConfirm}
      onCancel={() => {
        onCancel && onCancel()
        showSnackbar(translate('giftShop:uploadTagHostFeeEditFotoTree'))
      }}
      toggleModal={toggleModal}
    />
  )
}
