import {ServiceOptions} from 'types'
import {SERVICE_MAP_AUTH} from './auth'
import {SERVICE_MAP_FORKYGRAM} from './forkygram'
import {SERVICE_MAP_GIFT_SHOP} from './gift-shop'
import {SERVICE_MAP_LEGAL} from './legal'
import {SERVICE_MAP_MEMBER} from './member'
import {SERVICE_MAP_NOTIFICATION} from './notification'
import {SERVICE_MAP_PAYMENT} from './payment'
import {SERVICE_MAP_TREE} from './tree'
import {ServiceParam} from './ServiceParam'

export const SERVICE_MAP: Record<keyof ServiceParam, ServiceOptions> = {
  ...SERVICE_MAP_AUTH,
  ...SERVICE_MAP_GIFT_SHOP,
  ...SERVICE_MAP_FORKYGRAM,
  ...SERVICE_MAP_LEGAL,
  ...SERVICE_MAP_MEMBER,
  ...SERVICE_MAP_NOTIFICATION,
  ...SERVICE_MAP_PAYMENT,
  ...SERVICE_MAP_TREE,
}
