import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopGetReportResponse} from 'types'
import {showSnackbar} from 'utils'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopReportContentProps} from './GiftShopReportContentProps'
import {GiftShopReportContentItem} from '../ReportContentItem'

const StyledModal = styled(Modal)`
  width: ${convertUnit(300)};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(34)};
`

const StyledSaveButton = styled(Button)`
  display: flex;
  flex-direction: row;
  margin-left: ${convertUnit(30)};
  padding: ${convertUnit(9)} ${convertUnit(16)};
  width: ${convertUnit(125)};
`

const StyledModalBody = styled.div`
  flex-direction: column;
  align-items: flex-start;
`
const StyledReason = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: ${convertUnit(15)};
`

const StyledCancelButton = styled(Button)`
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(9)} ${convertUnit(16)};
  width: ${convertUnit(125)};
  ${({theme}) => ({backgroundColor: theme.white_3})};
`

export default function GiftShopReportContent({
  memberId,
  contentId,
  stateSelected,
  stateLoading,
  onConfirm,
  onCancel,
}: GiftShopReportContentProps) {
  const {translate} = useTranslation()
  const [select, setSelect] = stateSelected
  const [reportValues, setReportValues] = useState<GiftShopGetReportResponse[]>(
    [],
  )
  const setLoading = stateLoading[1]

  const handleLoadData = useCallback(() => {
    requestData('giftshop_get_report', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          setReportValues(data.result)
        }
      },
    })
  }, [])

  const handleSave = useCallback(() => {
    setLoading(true)
    select &&
      requestData('giftshop_post_report', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          category_id: select.id,
          reported_id: contentId,
          reported_member_id: memberId,
        },
        onRequestFailed: () => {
          showSnackbar(translate('global:reportFailMessage'))
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            onConfirm()
            showSnackbar(translate('global:reportSuccessMessage'))
            setLoading(false)
          } else {
            showSnackbar(translate('global:reportFailMessage'))
          }
        },
      })
  }, [contentId, memberId, onConfirm, select, setLoading, translate])

  const handleRenderOption = useMemo(
    () =>
      reportValues.map((itemValue) => (
        <GiftShopReportContentItem
          key={itemValue.id}
          reportName={translate('giftShop:reportReason', {
            context: itemValue.name.toUpperCase(),
          })}
          selected={select?.id === itemValue.id}
          onClick={() => {
            setSelect(itemValue)
          }}
        />
      )),
    [reportValues, select, setSelect, translate],
  )

  useEffect(() => {
    handleLoadData()
  }, [handleLoadData])

  return (
    <>
      <StyledModal visible>
        <StyledTitle>
          <Paragraph fontSize="xl" fontWeight="bold">
            {translate('global:reportContent')}
          </Paragraph>
        </StyledTitle>
        <StyledReason>
          <Paragraph fontSize="m" fontWeight="bold" color="primary_3">
            {translate('global:reportReason')}
          </Paragraph>
        </StyledReason>
        <StyledModalBody>{handleRenderOption}</StyledModalBody>
        <StyledButtonContainer>
          <StyledCancelButton
            label={translate('global:cancel')}
            onClick={onCancel}
            color="primary_5"
          />
          <StyledSaveButton
            label={translate('global:submit')}
            onClick={handleSave}
            disabled={select === undefined}
          />
        </StyledButtonContainer>
      </StyledModal>
    </>
  )
}
