import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {
  calcTimeDiff,
  formatHours,
  getCurrencyValue,
  parseDate,
  showSnackbar,
} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, Image, ModalLoading, Paragraph} from 'common/components'
import firebase from 'lib/firebase'
import convertUnit from 'lib/unit'
import {
  TransactionInfoItem,
  TransactionPaymentHistoryParams,
  PaymentInstructions,
  VirtualAccountData,
  RetailData,
} from 'pages'
import {useSelector} from 'lib/redux'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({mode, theme}) => ({
    flexDirection: mode === 'website' ? 'row' : 'column',
    margin: convertUnit(mode === 'website' ? 25 : 0),
    padding: convertUnit(mode === 'website' ? 50 : 25),
    backgroundColor: theme.white_1,
  })}
  display: flex;
  box-sizing: border-box;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_1};
    width: ${convertUnit(7)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border-radius: ${convertUnit(8)};
  }
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledRightSection = styled(StyledSection)<StyledContainerProps>`
  ${({mode}) => ({
    marginLeft: mode === 'website' ? convertUnit(50) : undefined,
    marginTop: mode === 'website' ? undefined : convertUnit(25),
  })}
`

const StyledTitle = styled(Paragraph)``

const StyledLogo = styled(Image)`
  width: ${convertUnit(44)};
  height: ${convertUnit(26)};
  object-fit: contain;
`

const StyledInstructionTitle = styled(Paragraph)`
  margin-top: ${convertUnit(40)};
`

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledCopyParagraph = styled(Paragraph)`
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledDurationText = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
`

const StyledDescriptionContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(10)};
  padding: ${convertUnit(10)};
`

const StyledMessageContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_4,
  })}
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(20)};
`

export default function PaymentHistory({
  invoiceId,
  transactionDate,
  paymentType,
  paymentMethod,
}: TransactionPaymentHistoryParams) {
  const {translate} = useTranslation()
  const [virtualAccount, setVirtualAccount] = useState<VirtualAccountData>({
    account_number: '',
    bank_code: '',
    expected_amount: 0,
    expiration_date: '',
    external_id: '',
    id: '',
    name: '',
    owner_id: '',
    status: '',
  })
  const [retail, setRetail] = useState<RetailData>({
    expected_amount: 0,
    expiration_date: '',
    id: '',
    payment_code: '',
    retail_outlet_name: '',
  })
  const [paymentFee, setPaymentFee] = useState(0)
  const [price, setPrice] = useState(0)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [timeDiff, setTimeDiff] = useState(
    calcTimeDiff(
      currentDate,
      retail.expected_amount === 0
        ? virtualAccount.expiration_date
        : retail.expiration_date,
    ),
  )
  const mode = useWindowMode()
  const [loading, setLoading] = useState(true)
  const name = useSelector('user')

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(invoiceId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setVirtualAccount(data.virtual_account)
            setRetail(data.retail_outlet)
            setPaymentFee(data.extra_fee)
            setPrice(data.price)
            setLoading(false)
          }
        }),
    [invoiceId],
  )
  useEffect(handleFirestore, [handleFirestore])

  const details = useMemo(() => {
    if (retail.expected_amount !== 0) {
      return {
        expected_amount: retail.expected_amount,
        expiration_date: retail.expiration_date,
        code: retail.payment_code,
        name: retail.retail_outlet_name,
      }
    }
    return {
      expected_amount: virtualAccount.expected_amount,
      expiration_date: virtualAccount.expiration_date,
      code: virtualAccount.account_number,
      name: virtualAccount.bank_code,
    }
  }, [
    retail.expected_amount,
    retail.expiration_date,
    retail.payment_code,
    retail.retail_outlet_name,
    virtualAccount.account_number,
    virtualAccount.bank_code,
    virtualAccount.expected_amount,
    virtualAccount.expiration_date,
  ])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [currentDate])

  useEffect(() => {
    setTimeDiff(calcTimeDiff(currentDate, details.expiration_date))
  }, [currentDate, details.expiration_date, retail.expected_amount])

  const handleRenderInfo = useMemo(
    () => (
      <StyledSection>
        <TransactionInfoItem
          title={translate('giftShop:checkoutTransactionDate')}
          description={parseDate(
            transactionDate.toISOString(),
            'MMMM D, YYYY, HH:mm',
          )}
        />
        <StyledDateContainer>
          <StyledDurationText fontSize="m" fontWeight="medium">
            {translate('giftShop:checkoutDurationEnds')}
          </StyledDurationText>
          <StyledDurationText fontSize="m" fontWeight="medium">
            {translate('giftShop:checkoutTimeLeft')}
          </StyledDurationText>
        </StyledDateContainer>
        <StyledDateContainer>
          <Paragraph fontSize="m">
            {parseDate(details.expiration_date, 'MMMM D, YYYY, HH:mm')}
          </Paragraph>
          <Paragraph fontSize="m" fontWeight="medium" color="primary_5">
            {formatHours(timeDiff.hours, timeDiff.minutes, timeDiff.seconds)}
          </Paragraph>
        </StyledDateContainer>
        <TransactionInfoItem
          title={translate('giftShop:totalPayment')}
          description={getCurrencyValue(details.expected_amount)}
          totalPrice
          boldDescription
        />
        <TransactionInfoItem
          title={translate('giftShop:totalPrice')}
          description={getCurrencyValue(price)}
          style={{marginTop: convertUnit(5)}}
          details
        />
        <TransactionInfoItem
          title={translate('giftShop:paymentFee')}
          description={getCurrencyValue(paymentFee)}
          details
        />
        <TransactionInfoItem
          title={translate('giftShop:accountName')}
          description={name?.username!}
          boldDescription
        />
      </StyledSection>
    ),
    [
      details,
      name?.username,
      paymentFee,
      price,
      timeDiff,
      transactionDate,
      translate,
    ],
  )

  const typeDetails = useCallback(() => {
    switch (paymentType) {
      case 'Retail': {
        return {
          title: paymentMethod.charAt(0) + paymentMethod.slice(1).toLowerCase(),
          container: (
            <StyledDescriptionContainer>
              <StyledTitle>
                {translate('giftShop:retailDescription')}
              </StyledTitle>
              <StyledTitle>
                {translate('giftShop:retailDescription', {
                  context: 'second',
                  Amount: getCurrencyValue(retail.expected_amount),
                })}
              </StyledTitle>
              <StyledTitle>
                {translate('giftShop:retailDescription', {
                  context: 'third',
                })}
              </StyledTitle>
              <StyledTitle>
                {translate('giftShop:retailDescription', {
                  context: 'fourth',
                })}
              </StyledTitle>
            </StyledDescriptionContainer>
          ),
        }
      }
      case 'Virtual Account': {
        return {
          title: translate('giftShop:virtualAccountMethod', {
            method: paymentMethod,
          }),
          container: (
            <PaymentInstructions
              bank={paymentMethod}
              accountNumber={virtualAccount.account_number}
              totalPrice={virtualAccount.expected_amount}
            />
          ),
        }
      }
      default: {
        return {
          title: translate('giftShop:virtualAccountMethod', {
            method: paymentMethod,
          }),
          container: (
            <PaymentInstructions
              bank={paymentMethod}
              accountNumber={virtualAccount.account_number}
              totalPrice={virtualAccount.expected_amount}
            />
          ),
        }
      }
    }
  }, [paymentMethod, paymentType, retail, translate, virtualAccount])

  const logo = useMemo(() => {
    switch (paymentMethod) {
      case 'BCA':
        return IMAGE_ASSET('icons', 'bca-logo-bank.png')
      case 'BNI':
        return IMAGE_ASSET('icons', 'bank-bni.png')
      case 'BRI':
        return IMAGE_ASSET('icons', 'bri-logo.png')
      case 'MANDIRI':
        return IMAGE_ASSET('icons', 'bank-mandiri.png')
      case 'PERMATA':
        return IMAGE_ASSET('icons', 'permatabank.png')
      case 'Alfamart':
        return IMAGE_ASSET('icons', 'alfamart.png')
      default:
        return IMAGE_ASSET('icons', 'alfamart.png')
    }
  }, [paymentMethod])

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(details.code)
    showSnackbar(translate('global:numberCopied'))
  }, [details.code, translate])

  const handleAccountDetail = useMemo(
    () => (
      <StyledRightSection mode={mode}>
        <StyledTitleContainer>
          <StyledTitle fontSize="m" fontWeight="medium">
            {typeDetails().title}
          </StyledTitle>
          <StyledLogo src={logo} alt="Payment Method Logo" />
        </StyledTitleContainer>
        <StyledTitleContainer>
          <Paragraph fontSize="l" fontWeight="bold" color="primary_5">
            {details.code}
          </Paragraph>
          <StyledCopyParagraph
            fontSize="m"
            fontWeight="medium"
            color="primary_5"
            onClick={copyToClipboard}>
            {translate('global:copy')}
          </StyledCopyParagraph>
        </StyledTitleContainer>
        <StyledMessageContainer>
          <Icon
            type="info"
            size={16}
            style={{
              alignSelf: 'center',
              marginRight: convertUnit(16),
            }}
          />
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            style={{alignSelf: 'center', lineHeight: convertUnit(21)}}>
            {translate('giftShop:transactionVANumberReminder')}
          </Paragraph>
        </StyledMessageContainer>
        <StyledInstructionTitle fontSize="m" fontWeight="medium">
          {translate('giftShop:paymentInstruction')}
        </StyledInstructionTitle>
        {typeDetails().container}
      </StyledRightSection>
    ),
    [copyToClipboard, details.code, logo, mode, translate, typeDetails],
  )

  return (
    <StyledContainer mode={mode}>
      {handleRenderInfo}
      {handleAccountDetail}
      <ModalLoading visible={loading} />
    </StyledContainer>
  )
}
