import { useContext } from 'react'
import {
  WindowLayoutContext,
  WindowNetworkStatusContext,
  WindowModeContext,
} from './WindowContext'

export function useWindowLayout() {
  return useContext(WindowLayoutContext)
}

export function useNetworkStatus() {
  return useContext(WindowNetworkStatusContext)
}

export function useWindowMode() {
  return useContext(WindowModeContext)
}
