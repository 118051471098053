import {lazy} from 'react'

const TreeHostNotificationScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostNotification' */ './TreeHostNotificationScreen'
    ),
)

export {TreeHostNotificationScreen}
