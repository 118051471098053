import {MutableRefObject, useCallback, useEffect, useMemo, useRef} from 'react'
import {
  GIFTSHOP_UPLOAD_BULK_LIMIT,
  GIFTSHOP_UPLOAD_SINGLE_LIMIT,
  GIFTSHOP_UPLOAD_VIDEO_LIMIT,
  GIFT_SHOP_SEND_TO_FACE_MAX_CONTENT,
  GIFT_SHOP_UPLOAD_TYPE,
  GIFT_SHOP_UPLOAD_VIDEO_ZIP_TYPE,
} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopUploadFormDataMap,
  GiftshopUploadContentData,
  GiftShopUploadModalData,
  GiftShopUploadSeriesData,
  GiftShopUploadSeriesDataMap,
  GiftShopUploadType,
  ObjectState,
  GiftShopUploadVideoSendToFaceFormData,
} from 'types'
import {
  showSnackbar,
  useGiftshopUploadCompressResendSetting,
  getAndSupplyFileMime,
} from 'utils'
import {getUniqueId} from '../../../objects'
import {
  handleGiftshopReadFile,
  handleGiftshopReadFileVideoPreview,
} from '../GiftshopFileHelper'
import {getGiftshopUploadDefaultValues} from './GiftShopUploadContentPropertiesHelper'

export function useGiftShopSeriesHandler({
  stateSeries,
  stateModalStatus,
  mapForm,
  sendToFace = false,
  seriesUploadType,
}: {
  sendToFace?: boolean
  stateSeries: ObjectState<GiftShopUploadSeriesDataMap>
  stateModalStatus: ObjectState<GiftShopUploadModalData>
  mapForm: MutableRefObject<GiftShopUploadFormDataMap>
  seriesUploadType: GiftShopUploadType
}) {
  const [series, setSeries] = stateSeries
  const setIsLoading = stateModalStatus[1]
  const {translate} = useTranslation()
  const queue = useRef<[string, GiftshopUploadContentData][]>([])
  const {compressEnabled} = useGiftshopUploadCompressResendSetting()
  const isCompress = useMemo(() => sendToFace || compressEnabled, [
    compressEnabled,
    sendToFace,
  ])

  const data = useMemo(
    () =>
      Object.values(series)
        .filter((item) => item.images.length > 0)
        .reduce<GiftShopUploadSeriesDataMap>(
          (previous, item): GiftShopUploadSeriesDataMap => ({
            ...previous,
            [item.id]: {
              ...item,
              parent: (
                item.images.find((image) => image.id === item.parent) ||
                item.images[0]
              ).id,
            },
          }),
          {},
        ),
    [series],
  )

  const total = useMemo(
    () =>
      Object.values(data).reduce(
        (previous, current) => previous + current.images.length,
        0,
      ),
    [data],
  )

  const handleDeleteSeries = useCallback(
    (id: string) => {
      delete mapForm.current[id]
      setSeries((previous) => {
        const clone = {...previous}

        delete clone[id]
        return clone
      })
    },
    [mapForm, setSeries],
  )

  const handleDeleteVariant = useCallback(
    (set: GiftShopUploadSeriesData, variant?: GiftshopUploadContentData) =>
      setSeries((previous) => ({
        ...previous,
        [set.id]: {
          ...set,
          images: set.images.filter((item) =>
            variant ? item.id !== variant.id : item.id === set.parent,
          ),
        },
      })),
    [setSeries],
  )

  const handleSetParent = useCallback(
    (set: GiftShopUploadSeriesData, variant: GiftshopUploadContentData) =>
      setSeries((previous) => ({
        ...previous,
        [set.id]: {
          ...set,
          parent: variant.id,
        },
      })),
    [setSeries],
  )

  const handleUpdateSeries = useCallback(() => {
    if (queue.current.length) {
      setSeries((previous) => {
        for (const [seriesId, image] of queue.current) {
          if (!previous[seriesId]) {
            previous[seriesId] = {
              id: seriesId,
              parent: image.id,
              images: [image],
            }
          } else {
            previous[seriesId].images.push(image)
          }

          mapForm.current[seriesId] = getGiftshopUploadDefaultValues({
            data: previous[seriesId],
            sendToFace,
            uploadType: seriesUploadType,
          })
        }

        return {...previous}
      })
    }
  }, [mapForm, sendToFace, setSeries, seriesUploadType])

  const handleReadFiles = useCallback(
    async (
      files: File[],
      seriesId?: string,
      videoFaces?: GiftShopUploadVideoSendToFaceFormData[],
    ) => {
      queue.current = []

      let index = 0
      for (const file of files) {
        const needCompress = GIFT_SHOP_UPLOAD_VIDEO_ZIP_TYPE.includes(file.type)
          ? false
          : isCompress

        const face = videoFaces && videoFaces[index]

        let image: GiftshopUploadContentData | null = null
        try {
          if (face) {
            // eslint-disable-next-line no-await-in-loop
            image = await handleGiftshopReadFileVideoPreview(file, face)
          } else {
            // eslint-disable-next-line no-await-in-loop
            image = await handleGiftshopReadFile(file, needCompress)
          }
        } catch (e) {
          image = null
        }

        if (image === null) {
          setIsLoading((prev) => ({
            ...prev,
            isLoading: false,
          }))
          return
        }

        const id = seriesId ?? getUniqueId()
        queue.current.push([id, image])

        // eslint-disable-next-line no-loop-func
        setIsLoading(() => ({
          isLoading: true,
          total_file: files.length,
          file_loaded: index,
        }))
        index += 1
      }

      handleUpdateSeries()
      setIsLoading((prev) => ({
        ...prev,
        total_file: files.length,
        file_loaded: files.length,
      }))
    },
    [isCompress, handleUpdateSeries, setIsLoading],
  )

  useEffect(() => {
    setIsLoading((prev) => ({
      ...prev,
      isLoading: false,
    }))
  }, [series, setIsLoading])

  const handleLoadFileSeries = useCallback(
    (
      fileList: FileList | File[],
      seriesId?: string,
      uploadType?: GiftShopUploadType,
      videoFaces?: GiftShopUploadVideoSendToFaceFormData[],
    ) => {
      const upType = uploadType || seriesUploadType
      setIsLoading(() => ({
        isLoading: true,
        total_file: fileList.length,
        file_loaded: 0,
      }))
      let hasInvalidData = false
      const maxUpload = (() => {
        if (sendToFace) return GIFT_SHOP_SEND_TO_FACE_MAX_CONTENT
        if (uploadType === 'bulk') return GIFTSHOP_UPLOAD_BULK_LIMIT
        if (uploadType === 'video') return GIFTSHOP_UPLOAD_VIDEO_LIMIT
        return GIFTSHOP_UPLOAD_SINGLE_LIMIT
      })()

      const filterFile = (file: File) => {
        let {type} = file
        const validType =
          uploadType === 'video'
            ? GIFT_SHOP_UPLOAD_VIDEO_ZIP_TYPE
            : GIFT_SHOP_UPLOAD_TYPE

        type = getAndSupplyFileMime(validType, file)

        const isValid =
          uploadType === 'video'
            ? GIFT_SHOP_UPLOAD_VIDEO_ZIP_TYPE.includes(type)
            : GIFT_SHOP_UPLOAD_TYPE.includes(type)

        if (!isValid) {
          hasInvalidData = true
        }

        return isValid
      }

      const source = Array.isArray(fileList) ? fileList : Array.from(fileList)
      const filter = source.filter(filterFile)
      const files = filter.splice(0, maxUpload - total)

      if (filter.length) {
        showSnackbar(
          translate(
            uploadType === 'video'
              ? 'giftShop:uploadMaximumVideoMessage'
              : 'giftShop:uploadMaximumMessage',
            {
              max: maxUpload,
            },
          ),
        )
      } else if (hasInvalidData || source.length < 1) {
        setIsLoading((prev) => ({
          ...prev,
          isLoading: false,
        }))
        showSnackbar(
          translate(
            upType === 'video'
              ? 'giftShop:videoUploadErrorInvalidFormat'
              : 'giftShop:invalidFormatData',
          ),
        )
      }

      handleReadFiles(files, seriesId, videoFaces)
    },
    [
      seriesUploadType,
      setIsLoading,
      sendToFace,
      total,
      handleReadFiles,
      translate,
    ],
  )

  return useMemo(
    () => ({
      data,
      total,
      deleteSeries: handleDeleteSeries,
      deleteVariant: handleDeleteVariant,
      setParent: handleSetParent,
      loadFileSeries: handleLoadFileSeries,
    }),
    [
      data,
      total,
      handleDeleteSeries,
      handleDeleteVariant,
      handleSetParent,
      handleLoadFileSeries,
    ],
  )
}
