import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Icon, CheckboxItem, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AuthRegisterTermOfConditionProps} from './AuthRegisterTermOfConditionProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(420)};
  padding: 0 ${convertUnit(25)} ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  height: ${convertUnit(470)};
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(15)};
`

const StyledIcon = styled.div`
  margin: ${convertUnit(10)} 0;
  width: ${convertUnit(20)};
  height: ${convertUnit(20)};
  background-color: ${({theme}) => theme.gray_5};
  border-radius: ${convertUnit(6)};
  padding: ${convertUnit(3)} ${convertUnit(2)};
  align-self: flex-end;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${convertUnit(470)};
`
const StyledAgreement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${convertUnit(20)};
`
const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(10)};
`

export default function AuthRegisterTermOfCondition({
  showModalState,
  handleAgree,
  showCheckbox,
}: AuthRegisterTermOfConditionProps) {
  const {translate} = useTranslation()
  const [buttonVisible, setButtonVisible] = useState(false)
  const [showModal, setShowModal] = showModalState
  const [agree, setAgree] = useState(false)
  const {lang: currlang} = useSelector('languageState')
  const lang = currlang || 'id'

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  return (
    <StyledModal visible={showModal}>
      <StyledIcon onClick={handleClose}>
        <Icon type="close" size={14} color={'white_1'} />
      </StyledIcon>
      <StyledIframe
        src={`${process.env.HOST_TERMS_OF_USE_AND_PRIVACY}&lang=${lang}`}
        title="Legal"
        onLoad={() => setButtonVisible(true)}
      />
      {showCheckbox ? (
        <StyledAgreement>
          <StyledCheckbox
            isSelected={agree}
            onClick={() => setAgree((prev) => !prev)}
          />
          <Paragraph fontSize="l" color="gray_5">
            {translate('giftShop:kycAgreement')}
          </Paragraph>
        </StyledAgreement>
      ) : (
        <></>
      )}
      {buttonVisible && (
        <StyledButton
          onClick={handleAgree}
          label={translate('global:iAgree')}
          disabled={showCheckbox && !agree}
        />
      )}
    </StyledModal>
  )
}
