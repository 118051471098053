import {useCallback, useMemo} from 'react'
import {requestData} from 'services'
import {
  GiftshopUploadContentData,
  GiftShopUploadResendData,
  ImageExtensionType,
  ObjectState,
} from 'types'
import {handleUploadSingleFileToWasabi} from '../../../files'

export function useGiftShopResendHandler({
  stateContent,
}: {
  stateContent: ObjectState<GiftShopUploadResendData>
}) {
  const handleAddContent = useCallback(
    (image: GiftshopUploadContentData) => {
      const setContent = stateContent[1]
      setContent(() => ({image}))
    },
    [stateContent],
  )

  const handleGetLink = useCallback(
    async (image: GiftshopUploadContentData, contentId: string) => {
      const {height, width, size, extension} = image
      const imgExtension = extension as ImageExtensionType

      const response = await requestData(
        'giftshop_upload_resend_content_link',
        {
          data: {
            content: {
              size,
              height,
              width,
              extension: imgExtension,
            },
            content_id: contentId,
            series: {
              total_size: size,
            },
          },
        },
      )

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.resend_link
        : null
    },
    [],
  )

  const handlePublishToWasabi = useCallback(
    async (image: GiftshopUploadContentData, link: string) => {
      const {file, width, height, extension} = image
      const mime = `image/${extension}`

      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperty = useCallback(
    async (
      image: GiftshopUploadContentData,
      link: string,
      contentId: string,
    ) => {
      const {height, width, size, exif} = image

      const response = await requestData('giftshop_upload_resend_content', {
        data: {
          content: link,
          content_id: contentId,
          height,
          width,
          size,
          exif: JSON.stringify(exif),
        },
      })

      return typeof response !== 'string' && response.status === 200
    },
    [],
  )

  const handleUploadResend = useCallback(
    async (image: GiftshopUploadContentData, contentId: string) => {
      const link = await handleGetLink(image, contentId)
      const isSuccess =
        !!link &&
        (await handlePublishToWasabi(image, link)) &&
        (await handlePublishProperty(image, link, contentId))

      return isSuccess
    },
    [handleGetLink, handlePublishToWasabi, handlePublishProperty],
  )

  return useMemo(
    () => ({
      addContent: handleAddContent,
      uploadResend: handleUploadResend,
    }),
    [handleAddContent, handleUploadResend],
  )
}
