import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input} from 'common/components'
import {VALIDATION_TREE_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

const StyledLeftItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  margin-right: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: 0;
    width: 100%;
  }
`

export default function TreeRegistrationFormSubdistrict({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {subDistrict} = watch()

  return (
    <StyledLeftItemsContainer>
      <Input
        form={form}
        name="subDistrict"
        disabled={disabled}
        label={translate('tree:hostRegistrationFormSubDistrictLabel')}
        placeholder={translate(
          'tree:hostRegistrationFormSubDistrictPlaceholder',
        )}
        formRules={VALIDATION_TREE_REQUIRED}
        rightIcon={
          !disabled && subDistrict && subDistrict.length > 0 ? (
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={() => setValue('subdistrict', '')}
            />
          ) : undefined
        }
      />
    </StyledLeftItemsContainer>
  )
}
