import React, {CSSProperties, useMemo, useState, useEffect} from 'react'
import styled from 'styled-components'
import {POLICY_ROOT_MENU_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {LegalBlogsResponse, LegalNavBarKey, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {legalMock} from '__mocks__'
import {PolicyRootNavBar, PolicyTopNavBar} from '../../../legal/root/containers'
import {PolicyRootScreenTestProps} from './PolicyRootScreenTestProps'

interface StyledSectionChildrenProps {
  mode: WindowModeType
  header?: boolean
}

const StyledSectionContent = styled.div`
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`

const StyledSectionChildren = styled.div<StyledSectionChildrenProps>`
  ${({mode, header}) => ({
    paddingLeft:
      mode !== 'website' || !header
        ? undefined
        : convertUnit(POLICY_ROOT_MENU_WIDTH),
  })}
  margin-top: ${convertUnit(40)};
  display: flex;
  flex: 1;
`

const StyledChildrenBaseContainer = styled.div`
  flex: 1;
  position: relative;
`

const StyledChildrenContainer = styled.div<CSSProperties>`
  background-color: ${({theme, backgroundColor}) =>
    backgroundColor ? theme[backgroundColor] : theme.white_1};
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const StyledChildrenContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
`

export default function PolicyRootScreen({
  children,
  childrenContainerStyle,
  hideBottomNavbar = false,
  clickState,
  onClick,
  showHeader,
}: PolicyRootScreenTestProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [expand, setExpand] = useState(false)
  const [data, setData] = useState<LegalBlogsResponse[]>([])
  const currentState = clickState[0]

  const dummy = legalMock

  // const handleLoadData = useCallback(async () => {
  //   await requestDataPayload('legal_get_blogs', {
  //     actionType: 'fetch',
  //     onRequestSuccess: ({status, data: {result}}) => {
  //       if (status === 200) {
  //         const navKey = result.map((resultItem) => [
  //           ...navKey,
  //           resultItem.section,
  //         ])
  //         setNavbarKey(navKey)
  //       }
  //     },
  //   })
  // }, [])

  useEffect(() => {
    setData(dummy)
  }, [data, dummy])
  // useEffect(() => {
  //   handleLoadData
  // }, [handleLoadData])

  const navbarOptions = useMemo(
    () =>
      data.map((navbarKeyItem, index) => ({
        key: navbarKeyItem.section as LegalNavBarKey,
        label: translate('policy:navigation', {context: navbarKeyItem.section}),
        onClick: () => {
          onClick(index)
          setExpand((prev) => !prev)
        },
      })),
    [data, onClick, translate],
  )

  const handleRenderSideNavBar = useMemo(
    () =>
      mode === 'website' && (
        <>
          <PolicyRootNavBar select={currentState} data={navbarOptions} />
        </>
      ),
    [mode, currentState, navbarOptions],
  )

  const handleRenderTopNavBar = useMemo(
    () =>
      mode !== 'website' &&
      !hideBottomNavbar && (
        <PolicyTopNavBar
          select={currentState}
          data={navbarOptions}
          expand={expand}
          onExpand={() => setExpand((previous) => !previous)}
        />
      ),
    [currentState, expand, hideBottomNavbar, mode, navbarOptions],
  )

  return (
    <>
      <StyledSectionContent>
        {showHeader === true ? handleRenderTopNavBar : null}
        {showHeader === true ? handleRenderSideNavBar : null}
        <StyledSectionChildren
          header={showHeader}
          mode={mode}
          onClick={() => setExpand(false)}>
          <StyledChildrenBaseContainer>
            <StyledChildrenContainer style={childrenContainerStyle}>
              <StyledChildrenContentContainer>
                {children}
              </StyledChildrenContentContainer>
            </StyledChildrenContainer>
          </StyledChildrenBaseContainer>
        </StyledSectionChildren>
      </StyledSectionContent>
    </>
  )
}
