import React from 'react'
import styled from 'styled-components'
import {Button, Modal, Paragraph, Image} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'

const StyledImageContainer = styled(Image)`
  width: 100%;
  max-width: ${convertUnit(200)};
  aspect-ratio: 1;
  object-fit: contain;
  margin: ${convertUnit(20)} auto;
`

const StyledModal = styled(Modal)`
  width: ${convertUnit(300)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  text-align: center;
`
const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(12)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

interface ModalReprocessKycItemProps {
  onClickOk(): void
}

export default function ModalReprocessKycItem({
  onClickOk,
}: ModalReprocessKycItemProps) {
  const {translate} = useTranslation()
  return (
    <StyledModal visible>
      <StyledTitle fontWeight="bold" fontSize="xl">
        {translate('giftShop:roboyuActivationProcessingHeader')}
      </StyledTitle>
      <Paragraph fontSize="l">
        {translate('giftShop:similarWaitingApprovalSubtitle')}
      </Paragraph>
      <StyledImageContainer
        src={IMAGE_ASSET('giftshop', 'robopet-offer.png')}
        alt=""
      />
      <Paragraph fontSize="m" color="gray_5">
        {translate('giftShop:similarWaitingApprovalDesc')}
      </Paragraph>
      <StyledButton label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>
  )
}
