import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopReportFinishProps} from './GiftShopReportFinishProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(300)};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${convertUnit(24)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${convertUnit(24)};
`

const StyledModalBody = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(10)};
`

const StyledContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(30)};
`

const StyledButton = styled(Button)`
  padding-top: ${convertUnit(9)};
  padding-bottom: ${convertUnit(9)};
  padding-left: ${convertUnit(16)};
  padding-right: ${convertUnit(16)};
  width: ${convertUnit(300)};
`
export default function GiftShopReportFinish({
  isVerificationSuccessful,
  onConfirm,
}: GiftShopReportFinishProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible>
      <StyledIcon>
        <Icon
          type={isVerificationSuccessful ? 'check' : 'close'}
          size={48}
          color={isVerificationSuccessful ? 'success_5' : 'gray_3'}
        />
      </StyledIcon>
      <StyledTitle>
        <Paragraph fontSize="xl" fontWeight="bold">
          {isVerificationSuccessful
            ? translate('global:reportSuccess')
            : translate('global:reportFail')}
        </Paragraph>
      </StyledTitle>
      <StyledModalBody>
        <StyledParagraph fontSize="l" fontWeight="regular">
          {isVerificationSuccessful
            ? translate('global:reportSuccessMessage')
            : translate('global:reportFailMessage')}
        </StyledParagraph>
      </StyledModalBody>
      <StyledContainerButton>
        <StyledButton label={translate('global:ok')} onClick={onConfirm} />
      </StyledContainerButton>
    </StyledModal>
  )
}
