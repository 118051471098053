import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopGetCreatorResendApprovalResponse} from 'types'
import {useDidMount} from 'utils'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateRevisionModalProps} from './GiftShopTemplateRevisionModalProps'
import GiftShopTemplateRevisionModalYuserList from './GiftShopTemplateRevisionModalYuserList'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const StyledTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

const StyledEmptyText = styled(Paragraph)`
  margin-top: ${convertUnit(12)};
`

export default function GiftShopTemplateRevisionModal({
  contentId,
  onClose,
  onRevision,
}: GiftShopTemplateRevisionModalProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopGetCreatorResendApprovalResponse>({
    collector: [],
    content_id: '',
    hires_url: '',
    original_url: '',
    content_type: 'photo',
  })

  const handleLoadData = useCallback(() => {
    requestData('giftshop_get_creation_resend_revision', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {content_ids: contentId},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result[0])
      },
    })
  }, [contentId])

  const handleRenderTitle = useMemo(
    () => (
      <StyledTextContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:resendRevisionModalTitle')}
        </Paragraph>
        {data && data.collector.length > 0 ? (
          <Paragraph fontSize="m" color="gray_5">
            {translate('giftShop:resendRevisionModalSubtitle')}
          </Paragraph>
        ) : (
          <StyledEmptyText fontSize="m" fontWeight="bold" color="gray_3">
            {translate('giftShop:resendRevisionModalEmpty')}
          </StyledEmptyText>
        )}
        <Paragraph color="gray_5">
          {translate('giftShop:resendRevisionModalMessage')}
        </Paragraph>
      </StyledTextContainer>
    ),
    [data, translate],
  )

  const handleRenderYuserList = useMemo(
    () => (
      <StyledContainer>
        {data &&
          data.collector.map((item) => {
            const {
              content_id,
              content_type,
              original_url,
              original_video_url,
              hires_url,
              hires_video_url,
              original_size,
              hires_size,
              original_video_duration,
              hires_video_duration,
              hires_resolution,
              original_resolution,
            } = data
            const {id, nickname, invoice_id} = item
            return (
              <GiftShopTemplateRevisionModalYuserList
                key={item.id}
                item={item}
                onRevision={() =>
                  onRevision({
                    collectorId: id,
                    contentId: content_id,
                    contentType: content_type,
                    hiresUrl:
                      content_type === 'photo'
                        ? hires_url
                        : hires_video_url || '',
                    invoiceId: invoice_id,
                    nickname,
                    originalUrl:
                      content_type === 'photo'
                        ? original_url
                        : original_video_url || '',
                    hiresDuration: hires_video_duration,
                    hiresResolution: hires_resolution,
                    hiresSize: hires_size,
                    originalDuration: original_video_duration,
                    originalResolution: original_resolution,
                    originalSize: original_size,
                  })
                }
              />
            )
          })}
      </StyledContainer>
    ),
    [data, onRevision],
  )

  const handleRenderCloseButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:close')}
        color="primary_5"
        backgroundColor="white_2"
        onClick={onClose}
      />
    ),
    [onClose, translate],
  )

  useDidMount(handleLoadData)

  return (
    <StyledModal visible>
      {handleRenderTitle}
      {handleRenderYuserList}
      {handleRenderCloseButton}
    </StyledModal>
  )
}
