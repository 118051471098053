import {lazy} from 'react'

const GiftshopTransactionHistoryScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionHistory' */ './GiftshopTransactionHistoryScreen'
    ),
)

export {GiftshopTransactionHistoryScreen}
