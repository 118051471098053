import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getThemeColor} from 'utils'
import {useTranslation} from 'i18n'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateFilterOverlayProps} from './GiftShopTemplateFilterOverlayProps'

const StyledFilter = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.black};
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  ${({theme}) => ({
    backgroundColor: getThemeColor(theme, 'white_1', 0.25),
    ':hover': {
      backgroundColor: getThemeColor(theme, 'primary_5', 0.25),
    },
  })}
  margin-top: ${convertUnit(13.5)};
`

export default function GiftShopTemplateFilterOverlay({
  showMessage,
  onDelete,
  onClickOverlay,
}: GiftShopTemplateFilterOverlayProps) {
  const {translate} = useTranslation()

  const handleRenderMessage = useMemo(
    () =>
      showMessage && (
        <>
          <Paragraph color="white_1" fontSize="l" fontWeight="bold">
            {translate('giftShop:contentDetailFilterTitle')}
          </Paragraph>
          <Paragraph color="white_1">
            {translate('giftShop:contentDetailFilterDescription')}
          </Paragraph>
          <StyledButton onClick={onDelete}>
            {translate('giftShop:uploadDeleteContent')}
          </StyledButton>
        </>
      ),
    [showMessage, translate, onDelete],
  )

  return (
    <StyledFilter onClick={onClickOverlay}>
      <Icon type="report" size={48} color="white_1" />
      {handleRenderMessage}
    </StyledFilter>
  )
}
