import React, {useMemo} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {GiftShopTemplateUploadModal} from 'pages'
import animationData from '../../../../../assets/lottie/activity_indicator.json'
import {GiftShopUploadUploadModalLoadingProps} from './GiftShopUploadUploadModalLoadingProps'

const StyledModal = styled(Modal)`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(325)};
  padding: ${convertUnit(20)};
`

const StyledParagraph = styled(Paragraph)`
  line-height: ${convertUnit(26)};
  text-align: center;
`

const StyledLottie = styled(Lottie)`
  height: ${convertUnit(50)};
  width: ${convertUnit(50)};
  margin: ${convertUnit(20)};
  align-self: center;
`

export default function GiftShopUploadUploadModalLoading({
  visible,
  isCompress,
  success,
  total,
}: GiftShopUploadUploadModalLoadingProps) {
  const handleModalBottom = useMemo(
    () => (
      <StyledParagraph fontSize="m" color="gray_3">
        {translate('giftShop:modalLoadingDescription')}
      </StyledParagraph>
    ),
    [],
  )

  return (
    <StyledModal visible={visible}>
      <StyledLottie animationData={animationData} />
      <GiftShopTemplateUploadModal
        title={translate(
          isCompress
            ? 'giftShop:compressModalTitle'
            : 'giftShop:contentModalLoadingHeader',
        )}
        description={translate(
          isCompress
            ? 'giftShop:compressModalDescription'
            : 'giftShop:contentModalLoadingDescription',
          {
            success,
            total,
          },
        )}
        bottomElement={handleModalBottom}
        total={total}
        success={success}
        progressColor="primary_5"
      />
    </StyledModal>
  )
}
