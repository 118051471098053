import React, {useCallback} from 'react'
import {requestData} from 'services'
import {useLocation} from 'utils'
import {GiftShopTemplateDetailContent} from '../../../template'
import {GiftShopCartContentEmpty} from '../ContentEmpty'

export default function GiftShopCartContentDetail() {
  const {data, selectedItemId} = useLocation('giftshop_cart_detail').state

  const handleLoadData = useCallback(async () => {
    const response = await requestData('giftshop_get_cart', {
      actionType: 'fetch',
      useDefaultMessage: true,
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result.data.map((item) => ({...item, is_cart: true}))
      : []
  }, [])

  return (
    <GiftShopTemplateDetailContent
      data={data}
      selectedItemId={selectedItemId}
      loadData={handleLoadData}
      showPreviewBadge
      darkBackgroundImage
      redirectCreator
      requestType="giftshop_delete_cart"
      showWishlistButton={false}
      showFullOverlay
      listEmptyElement={<GiftShopCartContentEmpty />}
    />
  )
}
