import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, {useTheme} from 'styled-components'
import {Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {ManageFarmerSkeletonProps} from './FototreeContentSkeletonProps'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledLeftContainer = styled.div`
  display: flex;
  width: ${convertUnit(48)};
  height: ${convertUnit(48)};
  position: relative;
`

const StyledRightContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  width: 100%;
  height: ${convertUnit(15)};
`

export default function ManageFarmerSkeleton({
  rows,
}: ManageFarmerSkeletonProps) {
  const theme = useTheme()

  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledContainer key={index}>
            <StyledLeftContainer>
              <Icon
                type="person"
                size={24}
                color="gray_5"
                style={{position: 'absolute', zIndex: 2, left: '16%'}}
              />
              <Skeleton
                circle
                width={48}
                height={48}
                baseColor={theme.white_4}
                highlightColor={theme.white_3}
              />
            </StyledLeftContainer>
            <StyledRightContainer>
              <Skeleton
                borderRadius={17}
                count={1}
                baseColor={theme.gray_1}
                highlightColor={theme.white_4}
              />
            </StyledRightContainer>
          </StyledContainer>
        ))}
    </>
  )
}
