import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeRegistrationProcessModalProps} from './TreeRegistrationProcessModalProps'

const StyledModal = styled(Modal)`
  max-width: ${convertUnit(295)};
  width: 100%;
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  margin: ${convertUnit(8)} 0;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function TreeRegistrationProcessModal({
  visible,
  toggleModal,
}: TreeRegistrationProcessModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <Icon type="check" size={convertUnit(48)} color="success_5" />
      <StyledTitle fontSize="xl" fontWeight="bold">
        {translate('tree:hostRegistrationProcessModalTitle')}
      </StyledTitle>
      <Paragraph fontSize="l">
        {translate('tree:hostRegistrationProcessModalMessage')}
      </Paragraph>
      <StyledButton label={translate('global:ok')} onClick={toggleModal} />
    </StyledModal>
  )
}
