import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {ICON_SIZE, IMAGE_ASSET} from 'consts'
import {translate} from 'i18n'
import {ForkygramGetCommentResponse, ForkygramRecipientData} from 'types'
import {formatRelativeDateDiff, getCount, useDidUpdate, useHistory} from 'utils'
import {
  Icon,
  Image,
  IconVerifiedBadge,
  Modal,
  Paragraph,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramCommentInput} from '../Item'
import {ForkygramCommentModalContentProps} from './ForkygramCommentModalContentProps'
import ForkygramCommentContent from './ForkygramCommentContent'

const StyledModal = styled(Modal)`
  display: flex;
  width: ${convertUnit(480)};
  height: 90%;
  max-height: ${convertUnit(677)};
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
`

const StyledListContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
`

const StyledHeaderContainer = styled.div`
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  display: flex;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(20)};
`

const StyledIcon = styled(Icon)`
  user-select: none;
  cursor: pointer;
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  cursor: pointer;
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(StyledRowContainer)`
  display: flex;
  align-items: flex-start;
  padding: ${convertUnit(12)} ${convertUnit(20)};
`
const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`

const StyledFotoyuBadge = styled.img`
  width: ${convertUnit(ICON_SIZE)};
  height: ${convertUnit(ICON_SIZE)};
  margin-left: ${convertUnit(4)};
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledBorder = styled.div`
  border-bottom-width: ${convertUnit(1)};
  border-bottom-style: solid;
  border-bottom-color: ${({theme}) => theme.gray_1};
`

const StyledCommentContainer = styled(StyledColumnContainer)`
  display: flex;
  flex: 1;
  word-break: break-word;
  word-wrap: break-word;
`

const StyledCaptionHeaderContainer = styled.div`
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`

export default function ForkygramCommentModalContent({
  stateVisible,
  toggleModal,
  ownerId,
  postId,
  stateCommentCount,
  data,
  photo,
  username,
  member_id,
  creator_status,
}: ForkygramCommentModalContentProps) {
  const {caption, is_official} = data
  const {update} = useDispatch()
  const [commentCount, setCommentCount] = stateCommentCount
  const stateSearch = useState(0)
  const stateRecipient = useState<ForkygramRecipientData | undefined>(undefined)
  const history = useHistory()
  const {id} = useSelector('user') || {}
  const {isTokenExpired} = useSelector('lastUserState')
  const fotoyuBadge = IMAGE_ASSET('giftshop', 'fotoyu-badge.svg')
  const visible = stateVisible[0]
  const stateCommentData = useState<ReadonlyArray<ForkygramGetCommentResponse>>(
    [],
  )
  const stateRepliedToComment = useState('')

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: member_id === id,
        postData: {member_id, username, photo},
      },
      username,
    )
  }, [history, id, member_id, photo, username])

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('forkygram:commentHeader_plural', {
            comment_count: getCount(commentCount),
          })}
        </Paragraph>
        <StyledIcon
          type="close"
          style={{cursor: 'pointer'}}
          onClick={() => toggleModal()}
        />
      </StyledHeaderContainer>
    ),
    [commentCount, toggleModal],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledAvatar src={photo} alt="avatar" onClick={handleNavigateProfile} />
    ),
    [handleNavigateProfile, photo],
  )

  const handleRenderUsername = useMemo(
    () => (
      <Paragraph
        className="pressable"
        fontSize="s"
        fontWeight="bold"
        onClick={handleNavigateProfile}>
        {username}
      </Paragraph>
    ),
    [handleNavigateProfile, username],
  )

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  const handleFotoyuBadge = useMemo(
    () => (is_official ? <StyledFotoyuBadge src={fotoyuBadge} /> : null),
    [fotoyuBadge, is_official],
  )

  const handleRenderDate = useMemo(
    () => formatRelativeDateDiff(data.created_at),
    [data.created_at],
  )

  const handleRenderCaption = useMemo(
    () => (
      <>
        <StyledContainer>
          {handleRenderAvatar}
          <StyledCommentContainer>
            <StyledCaptionHeaderContainer>
              <StyledRowContainer>
                {handleRenderUsername}
                {handleRenderBadge}
                {handleFotoyuBadge}
              </StyledRowContainer>
              <Paragraph color="gray_5">{handleRenderDate}</Paragraph>
            </StyledCaptionHeaderContainer>
            {caption !== '' && (
              <StyledParagraph id="comment">
                {caption.replace(/\\n/g, '\n')}
              </StyledParagraph>
            )}
          </StyledCommentContainer>
        </StyledContainer>
        <StyledBorder />
      </>
    ),
    [
      caption,
      handleFotoyuBadge,
      handleRenderAvatar,
      handleRenderBadge,
      handleRenderDate,
      handleRenderUsername,
    ],
  )

  const handleRenderFooter = useMemo(
    () => (
      <ForkygramCommentInput
        postId={postId}
        stateCommentCount={stateCommentCount}
        stateRecipient={stateRecipient}
        stateSearch={stateSearch}
        stateVisible={stateVisible}
        stateCommentData={stateCommentData}
        stateRepliedToComment={stateRepliedToComment}
      />
    ),
    [
      postId,
      stateCommentCount,
      stateCommentData,
      stateRecipient,
      stateRepliedToComment,
      stateSearch,
      stateVisible,
    ],
  )

  const handleRefresh = useCallback(() => {
    const date = new Date().getTime()
    stateSearch[1](date)
  }, [stateSearch])

  const handleRemovedComment = useCallback(
    (deletedCount: number) => {
      setCommentCount((prev) => prev - deletedCount)
      handleRefresh()
    },
    [handleRefresh, setCommentCount],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramCommentContent
        ownerId={ownerId}
        postId={postId}
        stateRecipient={stateRecipient}
        stateSearch={stateSearch}
        onDelete={handleRemovedComment}
        onReport={handleRefresh}
        stateCommentData={stateCommentData}
        stateRepliedToComment={stateRepliedToComment}
      />
    ),
    [
      handleRefresh,
      handleRemovedComment,
      ownerId,
      postId,
      stateCommentData,
      stateRecipient,
      stateRepliedToComment,
      stateSearch,
    ],
  )

  useDidUpdate(() => {
    if (isTokenExpired) {
      stateVisible[1](false)
      update('lastUserState', {isTokenExpired: false})
    }
  }, [isTokenExpired, update])

  return (
    <StyledModal visible={visible}>
      {handleRenderHeader}
      {handleRenderCaption}
      <StyledListContainer>{handleRenderContent}</StyledListContainer>
      {handleRenderFooter}
    </StyledModal>
  )
}
