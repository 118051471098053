export const AUTH_DOB_MIN_AGE = 18
export const AUTH_DOB_MIN_YEAR = 1900
export const AUTH_USER_MIN_AGE_TEEN = 13
export const AUTH_USER_MIN_AGE = 17
export const AUTH_FORGOT_PASSWORD_PATH = '/settings/profile/reset'
export const AUTH_NICKNAME_MAX_LENGTH = 30
export const AUTH_NICKNAME_MIN_LENGTH = 6
export const AUTH_PASSWORD_MAX_LENGTH = 15
export const AUTH_PASSWORD_MIN_LENGTH = 6
export const AUTH_PHONE_MIN_LENGTH_OLD = 5
export const AUTH_PHONE_MIN_LENGTH = 9
export const AUTH_USERNAME_MAX_LENGTH = 30
export const AUTH_USERNAME_MIN_LENGTH = 6
export const EMAIL_COOLDOWN = 60
export const OTP_COOLDOWN = 300
export const AUTH_LOGIN_MAX_ATTEMPT = 5
export const AUTH_RESEND_COOLDOWN_MINUTE = 5
