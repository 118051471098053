import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  getBorder,
  getSellGuidelinesNonVerified,
  getSellGuidelinesVerified,
  useDidMount,
} from 'utils'
import {Button, Icon, Modal} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateProfileSellGuidelineModalProps} from './TemplateProfileSellGuidelineModalProps'

interface StyledIframeProps {
  isCreatorVerified: boolean
}

const StyledModal = styled(Modal)`
  width: ${convertUnit(408)};
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${convertUnit(16)};
  padding-left: ${convertUnit(16)};
  padding-right: ${convertUnit(16)};
`

const StyledCloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(6)};
  background-color: ${({theme}) => theme.gray_5};
  cursor: pointer;
`

const StyledIframe = styled.iframe<StyledIframeProps>`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${({isCreatorVerified}) =>
    convertUnit(isCreatorVerified ? 650 : 555)};
`

const StyledInfoContainer = styled.div`
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.white_5)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(10)};
  padding-top: 0;
  padding: ${convertUnit(10)} ${convertUnit(11)};
`

const StyledButton = styled(Button)`
  width: 100%;
  flex: 1;
`

export default function TemplateProfileSellGuidelineModal({
  isVerified,
  onContinue,
  onToggle,
}: TemplateProfileSellGuidelineModalProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {guidelineCount} = useSelector('giftshopState')
  const {lang} = useSelector('languageState')
  const [isLoaded, setIsLoaded] = useState(false)

  const handleLoadedGuidelineContent = useCallback(() => {
    setIsLoaded(true)
    update('giftshopState', {
      guidelineCount: guidelineCount + 1,
    })
    requestData('giftshop_post_creator_guidelines', {
      actionType: 'execute',
    })
  }, [guidelineCount, update])

  const handleRenderCloseBtn = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledCloseButton onClick={onToggle}>
          <Icon type="close" color="white_1" size={12} />
        </StyledCloseButton>
      </StyledHeaderContainer>
    ),
    [onToggle],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledInfoContainer>
        <StyledIframe
          src={
            isVerified
              ? `${getSellGuidelinesVerified()}&lang=${lang}`
              : `${getSellGuidelinesNonVerified()}&lang=${lang}`
          }
          isCreatorVerified={isVerified}
          title={translate('policy:guidelineSellTitle')}
          onLoad={handleLoadedGuidelineContent}
        />
      </StyledInfoContainer>
    ),
    [handleLoadedGuidelineContent, isVerified, lang, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledButton
          label={translate('global:cancel')}
          backgroundColor="white_3"
          color="primary_5"
          onClick={onToggle}
        />
        <StyledButton
          disabled={!isLoaded}
          label={translate('global:upload')}
          onClick={onContinue}
        />
      </StyledButtonsContainer>
    ),
    [isLoaded, onContinue, onToggle, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        {handleRenderCloseBtn}
        {handleRenderInfo}
        {handleRenderButton}
      </>
    ),
    [handleRenderButton, handleRenderCloseBtn, handleRenderInfo],
  )

  useDidMount(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true)
    }, 10000)

    return () => clearTimeout(timeout)
  })

  return <StyledModal visible>{handleRenderContent}</StyledModal>
}
