import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING,
  GIFT_SHOP_EXPLORE_EMPTY_MAX_WIDTH,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE,
} from 'consts'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {BankListRootEmptyProps} from './BankListRootEmptyProps'

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  padding: 0 ${convertUnit(GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING)};
  max-width: ${convertUnit(700)};
  width: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(GIFT_SHOP_EXPLORE_EMPTY_MAX_WIDTH)};
  padding: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING)} 0;
  align-items: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  height: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT)};
  max-width: 100%;
  margin: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE)} 0;
`

const StyledButton = styled(Button)`
  padding: ${convertUnit(11)} ${convertUnit(24)};
  margin-top: ${convertUnit(20)};
`

export default function BankListRootEmpty({
  image,
  imageAlt,
  message,
  subMessage,
  onClickButton,
}: BankListRootEmptyProps) {
  const {translate} = useTranslation()

  const handleRenderMessage = useMemo(
    () =>
      message ? (
        <Paragraph fontSize="l" fontWeight="bold" style={{textAlign: 'center'}}>
          {message}
        </Paragraph>
      ) : undefined,
    [message],
  )

  const handleRenderSubMessage = useMemo(
    () =>
      subMessage ? (
        <Paragraph
          color="gray_5"
          style={{
            textAlign: 'center',
            marginTop: convertUnit(5),
          }}>
          {subMessage}
        </Paragraph>
      ) : undefined,
    [subMessage],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={image} alt={imageAlt} />,
    [image, imageAlt],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('giftShop:addBankAccount')}
        fontSize="l"
        fontWeight="medium"
        onClick={onClickButton}
      />
    ),
    [translate, onClickButton],
  )

  return (
    <StyledContainer>
      <StyledContentContainer>
        {handleRenderImage}
        {handleRenderMessage}
        {handleRenderSubMessage}
        {handleRenderButton}
      </StyledContentContainer>
    </StyledContainer>
  )
}
