import {ThemeColor} from 'themes'
import {GiftshopUploadPublishStatus} from 'types'

export function uploadColorExtractor(
  st: GiftshopUploadPublishStatus | null,
): ThemeColor {
  switch (st) {
    case 'success':
      return 'primary_5'
    case 'duplicate':
      return 'warning_5'
    case 'not-started':
      return 'white_3'
    case 'failed':
      return 'danger_5'
    default:
      return 'danger_5'
  }
}
