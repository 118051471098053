import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {getFontFamily} from 'utils'

export interface GiftShopMessageRoomContentItemNotificationFollowGroupProps {
  payload: FirebaseMessageRoomItemPayload['notification_follow_group']
}

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

export default function GiftShopMessageRoomContentItemNotificationFollow({
  payload: {count},
}: GiftShopMessageRoomContentItemNotificationFollowGroupProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      <StyledBold>
        {count} {translate('global:yuser')}
      </StyledBold>
      {translate('messages:notificationFollow')}
    </Paragraph>
  )
}
