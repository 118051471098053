import heic2any from 'heic2any'

export interface ConversionResultData {
  file: File,
  blob: Blob
}

export async function convertImage(
  url: string | ArrayBuffer,
  toTypeMime: string,
  oldFileName: string,
) {

  return fetch(typeof url !== 'string' ? url.toString() : url)
    .then((res) => res.blob())
    .then((blob) =>
      heic2any({
        blob,
        toType: toTypeMime,
      }),
    )
    .then((convertedImage) => {
      const finalName = oldFileName.replace(
        /\.(.)+/g,
        `.${toTypeMime.split('/')[1]}`,
      )

      const file = new File([convertedImage as Blob], finalName, {
        type: toTypeMime,
      });

      return {
        file,
        blob: convertedImage
      } as ConversionResultData
    })
    .catch()
}