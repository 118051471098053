import React from 'react'
import convertUnit from 'lib/unit'
import {IconGoogleProps} from './IconGoogleProps'

export default function IconGoogle({
  width = convertUnit(24),
  height = convertUnit(24),
  fill = 'none',
  ...props
}: IconGoogleProps) {
  return (
    <svg {...props} width={width} height={height} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.64 12.2047C20.64 11.5665 20.5827 10.9529 20.4764 10.3638H12V13.8451H16.8436C16.635 14.9701 16.0009 15.9233 15.0477 16.5615V18.8197H17.9564C19.6582 17.2529 20.64 14.9456 20.64 12.2047Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 21C14.4298 21 16.467 20.1941 17.9561 18.8195L15.0475 16.5613C14.2416 17.1013 13.2107 17.4204 11.9998 17.4204C9.65567 17.4204 7.67158 15.8372 6.96385 13.71H3.95703V16.0418C5.43794 18.9831 8.48158 21 11.9998 21Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96409 13.7098C6.78409 13.1698 6.68182 12.593 6.68182 11.9998C6.68182 11.4066 6.78409 10.8298 6.96409 10.2898V7.95801H3.95727C3.34773 9.17301 3 10.5476 3 11.9998C3 13.4521 3.34773 14.8266 3.95727 16.0416L6.96409 13.7098Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 6.57955C13.3211 6.57955 14.5075 7.03364 15.4402 7.92545L18.0216 5.34409C16.4629 3.89182 14.4257 3 11.9998 3C8.48158 3 5.43794 5.01682 3.95703 7.95818L6.96385 10.29C7.67158 8.16273 9.65567 6.57955 11.9998 6.57955Z"
        fill="#EA4335"
      />
    </svg>
  )
}
