import {TranslationKeyAuth} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_AUTH_EN: TranslationResource<TranslationKeyAuth> = {
  translation: {
    'auth:askParentalConsent': 'Ask Parental Consent',
    'auth:banModalDescription':
      'Sorry, your account has been banned for violating the FotoYu Community guidelines. You can read our community guidelines again <guideline>here</guideline>.',
    'auth:banModalUnderageDescription':
      'Sorry, your account has been banned due to your insufficient age as specified in the Terms of User. You can read our Terms of Use again.',
    'auth:banModalTitle': 'Your account has been banned',
    'auth:confirmationPassword': "New password doesn't match",
    'auth:createPasswordSSOConfirmationPlaceholder': 'Confirm your password',
    'auth:createPasswordSSODesc':
      'Before we continue, please create a password for transaction purpose.',
    'auth:createPasswordSSOTitle': 'Create Password',
    'auth:deleteAccountAssociateHost':
      'Your account is still associated with a host, please demote your account from host.',
    'auth:deleteAccountOweResend':
      "You still haven't sent a Hi-Res file to the Yuser, please send the file first.",
    'auth:filterDateValidation': "From date can't be greater than To date",
    'auth:forgotPasswordEmailDescription':
      'A link has been sent to your email to continue the process. Please check your email inbox.',
    'auth:forgotPasswordHeader': 'Forgot Password',
    'auth:forgotPasswordHeaderDescription': 'Where can we contact you?',
    'auth:forgotPasswordInputLabel': 'Email or Mobile Number',
    'auth:forgotPasswordInputPlaceholder': 'Type here',
    'auth:forgotPasswordPhoneDidNotGet': "Didn't get the link?",
    'auth:forgotPasswordPhoneDescription':
      'A verification link is sent to your mobile number. Please check your SMS.',
    'auth:forgotPasswordPhoneDescription_whatsapp':
      'A verification link is sent to your mobile number. Please check your WhatsApp.',
    'auth:forgotPasswordPhoneResend': 'Resend link',
    'auth:forgotPasswordPhoneResendCountdown': 'Resend link ({{countdown}}s)',
    'auth:forgotPasswordResendMessage':
      'Sorry! Please wait about {{cooldown}} minutes before continuing.',
    'auth:forgotPasswordResendMessageLimit':
      'Sorry! Please wait <hourStamp>{{hour}}h</hourStamp><minuteStamp>{{minute}}m</minuteStamp><secondStamp>{{second}}s</secondStamp> before continuing.',
    'auth:generalValidationAlphanumeric': 'Consist of alphabets and numeric',
    'auth:generalValidationMinLength':
      'Consist of at least {{length}} characters',
    'auth:inputPasswordConfirmLabel': 'Confirm New Password',
    'auth:inputPasswordConfirmPlaceholder': 'Input your new password again',
    'auth:identifierOneAccount': 'Each person is only able to have 1 account.',
    'auth:linkExpiredHeader': 'Link Expired',
    'auth:linkExpiredHeaderDescription': 'The link has expired.',
    'auth:linkExpiredInformation': "Didn't get the link?",
    'auth:linkExpiredResend': 'Resend link',
    'auth:linkSuccessHeader': 'Success!',
    'auth:loginAs': 'Login as {{username}}',
    'auth:loginButtonAppleAuthAccess': 'Apple Login',
    'auth:loginButtonAppleTitle': 'Login with Apple',
    'auth:loginButtonGoogleAuthAccess': 'Google Login',
    'auth:loginButtonGoogleTitle': 'Login with Google',
    'auth:loginForgotPassword': 'Forgot / Create Password',
    'auth:loginHeader': 'Welcome Back',
    'auth:loginHeaderDescription': 'Login to enjoy FotoYu.',
    'auth:loginInputAccountLabel': 'Username, Email, or Mobile Number',
    'auth:loginInputAccountPlaceholder':
      'Input your username, email, or mobile no.',
    'auth:loginInputPasswordLabel': 'Password',
    'auth:loginInputPasswordPlaceholder': 'Input your password',
    'auth:loginNotHaveAccount': "Don't have an account?",
    'auth:loginUnderage': 'Parental Consent Required',
    'auth:loginUnderageHeader':
      'You are under {{age}} years old and identified as a Child Yuser.',
    'auth:loginUnderageDescription': 'You need a parental consent',
    'auth:logoutModalExpiredTokenDescription':
      'No worry, you just need to log in again.',
    'auth:logoutModalTitle': 'Your session has expired',
    'auth:logoutSaveLoginInfo': 'Save login information',
    'auth:messageBackToLogin': 'Back to Login',
    'auth:messageBackToProfile': 'Back to Profile',
    'auth:nicknameAllowedCharacters': 'Nickname should be alphanumeric',
    'auth:nicknameCreated': 'Your nickname has been created.',
    'auth:nicknameLabelDescription': 'For content purchases only',
    'auth:nicknameSublabel': 'Enter a nickname different than your username',
    'auth:registerDateOfBirthInputPlaceholder': 'DD/MM/YYYY',
    'auth:registerAppleButtonLabel': 'Sign Up with Apple',
    'auth:registerGoogleButtonLabel': 'Sign Up with Google',
    'auth:registerHeader': 'Create Account',
    'auth:registerHeaderDateOfBirth': 'When Were You Born?',
    'auth:registerHeaderPassword': 'Create Password',
    'auth:registerHeaderAccount': 'Create Account',
    'auth:registerCreateAccountThroughMobileApp':
      'Create your account through FotoYu mobile app.',
    'auth:registerDoBDescription': 'Age under {{age}} needs a parental consent',
    'auth:registerEasySignupChildDescription_1':
      'Parent need to take selfie with child yuser.',
    'auth:registerEasySignupChildDescription_2':
      'Next step will turn on and record your camera',
    'auth:registerEasySignupChildHeader': 'Wefie Parent and Child',
    'auth:registerEasySignupChildHeader_selfie': 'Child Selfie',
    'auth:registerEasySignupChildReapplyDesc':
      'Sorry! Looks like the process is not completed.',
    'auth:registerEasySignupChildReapplyTitle': 'Verification is Not Complete',
    'auth:registerEasySignupChildWefieDesc': 'Parent and child selfie',
    'auth:registerEasySignupChildWefieDesc_selfie': 'Take child selfie only',
    'auth:registerEasySignupCreatePassword':
      'You must create a password for your account',
    'auth:registerEasySignupCredentialConfirm':
      "Please ensure your WhatsApp number is correct. We'll send a verification link to your WhatsApp number.",
    'auth:registerEasySignupCredentialConfirm_email':
      "Please ensure your Email is correct. We'll send a verification link to your Email.",
    'auth:registerEasySignupDuplicateConfirmation':
      'I need to create a business account.',
    'auth:registerEasySignupDuplicateDesc':
      'Duplicate accounts can cause account suspensions.',
    'auth:registerEasySignupDuplicateTitle':
      "You already have an account.\nYou can't create a second account.",
    'auth:registerEasySignupResendLinkCooldownSnackbar':
      'Please check your WhatsApp or Email. <timestamp><hourStamp>{{hour}} hours </hourStamp><minuteStamp>{{minute}} minutes </minuteStamp><secondStamp>{{second}} seconds</secondStamp></timestamp> for resend.',
    'auth:registerEasySignupResendLinkLimitSnackbar':
      'The limit for resending has been reached. <timestamp><hourStamp>{{hour}} hours </hourStamp><minuteStamp>{{minute}} minutes </minuteStamp><secondStamp>{{second}} seconds</secondStamp></timestamp> for resend.',
    'auth:registerEasySignupSendLink': 'We will send a link to this email.',
    'auth:registerEasySignupSendLink_phone':
      'We will send a link to this WhatsApp number.',
    'auth:registerEasySignupParentDob': "Parent's Date of Birth",
    'auth:registerEasySignupParentDobRestriction': 'Must be {{age}} or older',
    'auth:registerEasySignupParentInputDob':
      "Before you start, please enter your parent's date of birth.",
    'auth:registerEasySignupUpdateCredentialDescription':
      'Please enter a valid email address for verification.',
    'auth:registerEasySignupUpdateCredentialDescription_whatsapp':
      'Please enter a valid WhatsApp number for verification.',
    'auth:registerEasySignupUpdateCredentialLabel': 'New Email',
    'auth:registerEasySignupUpdateCredentialLabel_whatsapp':
      'New WhatsApp Number',
    'auth:registerEasySignupUpdateCredentialSnackbar':
      'A verification link has been sent to the new email',
    'auth:registerEasySignupUpdateCredentialSnackbar_whatsapp':
      'A verification link has been sent to the new WhatsApp number',
    'auth:registerEasySignupUpdateCredentialTitle': 'Change Email',
    'auth:registerEasySignupUpdateCredentialTitle_whatsapp':
      'Change WhatsApp Number',
    'auth:registerEasySignupUploadWarning':
      "Please wait a moment, and don't close this page.",
    'auth:registerEasySignupUsernameNotMatch': 'Username does not match',
    'auth:registerEasySignupVerifMobileHeaderDescription':
      'We have sent you a verification message. Please check your SMS for confirmation link.',
    'auth:registerEasySignupVerifMobileHeaderDescription_whatsapp':
      'We have sent you a verification message. Please check your WhatsApp for confirmation link.',
    'auth:registerEasySignupWelcomeLinkExpired':
      'This login link has expired. Please log in using your password.',
    'auth:registerEasySignupWelcomeMessage':
      'Please enter your username to verify.',
    'auth:registerEasySignupWelcomePlaceholder': 'Input your username',
    'auth:registerEasySignupWelcomeTitle': 'Welcome to FotoYu',
    'auth:registerIdentifierAlreadyHaveAnAccount': 'Already have an account?',
    'auth:registerIdentifierCancelModalMessage':
      'Are you sure want to cancel? The data will not be saved',
    'auth:registerIdentifierInputEmailLabel': 'Email',
    'auth:registerIdentifierInputEmailPlaceholder': 'someone@domain.com',
    'auth:registerIdentifierInputPhoneLabel': 'Mobile Number',
    'auth:registerIdentifierInputPhonePlaceholder': 'e.g.: 878761234',
    'auth:registerIdentifierSearchCountryEmpty': `Oh no! We can't find country "{{search}}"`,
    'auth:registerIdentifierTabEmail': 'Email',
    'auth:registerIdentifierTabPhone': 'Mobile Number',
    'auth:registerLimitReached': 'Please try again in 24 hours',
    'auth:registerMinimumAgeErrorMessage':
      'Minimum age is {{minAge}} years old',
    'auth:registerPasswordConfirmationMessage': 'Input your password again',
    'auth:registerPasswordInputPlaceholder': 'Create your password',
    'auth:registerTermsOfService':
      'By signing up, you agree to the <tos>Terms of Use</tos> and <privacy>Privacy Policy</privacy>, including <cookie>Cookies Policy</cookie>. You may receive email notifications from us and can opt out at any time.',
    'auth:registerTermsOfService_phone':
      'By signing up, you agree to the <tos>Terms of Use</tos> and <privacy>Privacy Policy</privacy>, including <cookie>Cookies Policy</cookie>. You may receive SMS notifications from us and can opt out at any time.',
    'auth:registerTermsOfService_whatsapp':
      'By signing up, you agree to the <tos>Terms of Use</tos> and <privacy>Privacy Policy</privacy>, including <cookie>Cookies Policy</cookie>. You may receive WhatsApp notifications from us and can opt out at any time.',
    'auth:registerTakeSelfie': 'Take a Selfie',
    'auth:registerUsernameInputPlaceholderEasySignup':
      'Create and remember your username',
    'auth:resendLimitReached': 'Please try again in {{duration}}',
    'auth:registerUnderage':
      'Sorry, you are still underage and need a parental consent. Please download FotoYu mobile app.',
    'auth:registerUsernameInputPlaceholder': 'Create your username',
    'auth:resetPasswordButtonTitle': 'Reset Password',
    'auth:resetPasswordHeader': 'Reset Password',
    'auth:resetPasswordHeaderDescription':
      'Remember your password to enjoy FotoYu seamlessly!',
    'auth:resetPasswordSuccessDescription':
      'Now you can log in using your new password. Welcome!',
    'auth:resetPasswordSuccessSubtitle': 'Your password has been changed.',
    'auth:serverUnderMaintenance': 'Server Under Maintenance',
    'auth:serverUnderMaintenanceDescFirst':
      'Service will be restored in a short time.',
    'auth:serverUnderMaintenanceDescGeneral':
      'FotoYu is currently under maintenance, sorry for the inconvenience.',
    'auth:serverUnderMaintenanceDescSecond': 'Sorry for the inconvenience.',
    'auth:similarIdentityButton': 'Choose an Account',
    'auth:similarIdentityDescFirst':
      'We took privacy issues seriously and strictly against any illegal attempts to impersonate others or spoofing. We always try to protect the safety of users from identity theft.',
    'auth:similarIdentityDescSecond':
      'To continue using our app, you need to choose an account that you want to activate.',
    'auth:similarIdentitySnackbar': 'Thank you! We will contact you shortly.',
    'auth:successCreatePassword': 'Password successfully created.',
    'auth:suspendLoginModalDescription':
      'You have exceeded the login attempts limit. Please try again in 5 minutes.',
    'auth:suspendModalDescription':
      'Sorry, based on your last activities, your account has been temporarily suspended because it violates our community guidelines.',
    'auth:suspendModalTitle': 'Your Account Has Been Suspended',
    'auth:unverifiedEmailSnackbar': 'Please check your email inbox.',
    'auth:validationCreatePasswordSSO':
      'Password tidak bisa mengandung spasi, huruf non-Romawi, atau emoji',
    'auth:validationEmail': 'Please enter the correct email format',
    'auth:validationEmailEmpty': 'Please enter an email',
    'auth:validationEmailExpired': 'Link has expired. Please try again',
    'auth:validationEmailFormat':
      'Please enter your email address in format: name@example.com',
    'auth:validationEmailHasBeenTaken': 'Email has been registered',
    'auth:validationLoginMissMatch':
      "We couldn't find any account matched to your credential and password. Please check and try again.",
    'auth:validationLoginNoPasswordMissmatch':
      "No password set for this account. Click 'Forgot Password' to add a password.",
    'auth:validationLoginSSOMissMatch':
      'Your account is registered using Google/Apple, please try logging in by tapping login with Google or Apple.',
    'auth:validationForgotPasswordMissmatch':
      "We couldn't find any account matched to the credential. Please check and try again.",
    'auth:validationForgotPasswordSSOMissMatch':
      'Your account is registered with Google/Apple account.',
    'auth:validationLoginAttempt':
      'Sorry! Wrong password. You have {{attempt}} chances left.',
    'auth:validationMandatoryField': 'All mandatory fields must be filled',
    'auth:validationNicknameContainsFotoyu': 'Nickname cannot contain FotoYu',
    'auth:validationNicknameHasBeenTaken':
      'This nickname has already been taken by another user.',
    'auth:validationNicknameHasBeenUsed': 'Nickname has been used',
    'auth:validationNicknameIsEmpty': 'Nickname must be filled',
    'auth:validationNicknameMatchPrevious': 'Please add new nickname',
    'auth:validationNicknameMatchUsername':
      'Your nickname is the same as your username. Please change your nickname',
    'auth:validationNicknameMaxLength': 'Maximal char is {{count}}',
    'auth:validationNicknameMinLength':
      'Your nickname is too short. Minimal character is {{count}}',
    'auth:validationNicknameNoSpace': 'Nickname cannot contain spaces',
    'auth:validationNicknameOneLetterRequired':
      'Nickname must contain at least one letter',
    'auth:validationNicknameSingleDash':
      'Nickname can only contain one dash (-)',
    'auth:validationNicknameSinglePeriod':
      'Nickname can only contain one period (.)',
    'auth:validationNicknameSingleUnderscore':
      'Nickname can only contain one underscore (_)',
    'auth:validationNicknameStartCharacter': 'Nickname must start with letter',
    'auth:validationNicknameStartEndAlphanumeric':
      'Nickname cannot start or end with an underscore (_), dash (-), or period (.)',
    'auth:validationParentMinimumAge':
      'Parent must be {{age}} years or older to provide consent. Please enter a valid age',
    'auth:validationPassword': 'Consist of alphabets and numeric',
    'auth:validationPasswordConfirmation': 'Are you sure to use this password?',
    'auth:validationPasswordError':
      'Password must consist of alphabets and numeric',
    'auth:validationPasswordLength':
      'Consist of at least {{minLength}} characters',
    'auth:validationPasswordMaxLength': 'Maximum character is {{count}}',
    'auth:validationPasswordMinLength':
      'Password must be at least {{count}} character',
    'auth:validationPasswordMinLength_plural':
      'Password must be at least {{count}} characters',
    'auth:validationPasswordMix':
      'Password must consist of alphabets and numeric',
    'auth:validationPasswordNoDashUnderscorePeriod':
      'Password cannot contain underscore (_), dash (-), or period (.)',
    'auth:validationPasswordNoNonRomanCharacters':
      'Password cannot contain non-Roman letter or emoji',
    'auth:validationPasswordNoSpace': 'Password cannot contain spaces',
    'auth:validationPhone': 'Phone number must be numeric',
    'auth:validationPhoneEmpty': 'Please enter a phone number',
    'auth:validationPhoneHasBeenTaken': 'Phone number has been registered',
    'auth:validationPhoneMinLength':
      'Mobile number must contain a minimum {{count}} characters (excluding leading 0)',
    'auth:validationPhoneMinLengthWithoutLeadingZero':
      'Mobile number must contain a minimum of {{count}} characters',
    'auth:validationPhoneRegistered':
      "Sorry! Looks like this mobile number has been registered. Let's use another mobile number and try again.",
    'auth:validationRequired': 'This field is required',
    'auth:validationResetPasswordMustBeDifferent':
      'Must be different than old password',
    'auth:validationResetPasswordNotMatch': 'New password does not match',
    'auth:validationUsername': 'Username should be alphanumeric',
    'auth:validationUsernameContainsFotoyu': 'Username cannot contain FotoYu',
    'auth:validationUsernameHasBeenRegistered': 'Username has been registered',
    'auth:validationUsernameHasBeenTaken': 'Your username is already taken',
    'auth:validationUsernameHasBeenUsed': 'Username has been used',
    'auth:validationUsernameMatchPrevious':
      'Your new username should not match the previous one.',
    'auth:validationUsernameLength':
      'Username must be at least {{count}} character',
    'auth:validationUsernameMinLength': 'Minimum is {{count}} character',
    'auth:validationUsernameMinLength_plural':
      'Username must be at least {{count}} characters',
    'auth:validationUsernameNoSpaceAndSpecialCharacter':
      'Username cannot contain spaces or special character',
    'auth:validationUsernameNotOnlyNumbers':
      'Username cannot contain only numbers',
    'auth:validationUsernameSinglePeriod':
      'Username can only contain one underscore (_), dash (-)',
    'auth:validationUsernameStartEndAlphanumeric':
      'Username cannot start or end with an underscore (_) or dash (-)',
    'auth:validationUsernameStartEndNoSpecial':
      'Username cannot contain spaces',
    'auth:validationUsernameStartWithLetter': 'Username must start with letter',
    'auth:validationVerificationOTPInvalid':
      "We couldn't authenticate the OTP. Please try again.",
    'auth:validationWesbite':
      "Website must starts with 'https://' or 'http://'",
    'auth:validationWesbiteURL':
      'The link is not valid. Please check your link format.',
    'auth:verificationChangeCredential':
      ' or <change>Change WhatsApp number</change>',
    'auth:verificationChangeCredential_email':
      ' or <change>Change email</change>',
    'auth:verificationCheckBox':
      'By signing up, you agree to the Terms of Use and Privacy Policy, including Cookies Policy. You may receive SMS notifications from us and can opt out at any time.',
    'auth:verificationEmailCaution': 'Your verification link expires in',
    'auth:verificationEmailCautionTime': '1 hour.',
    'auth:verificationEmailConfirmHeaderDescription':
      'Your account has been verified.',
    'auth:verificationEmailConfirmInformation':
      'Now you can log in using your registered email. Enjoy!',
    'auth:verificationEmailDidNotGet': "Didn't get the email?",
    'auth:verificationEmailHeader': '{{email}}',
    'auth:verificationEmailHeaderDescription':
      'We have sent you a verification email. Please check your email for confirmation link.',
    'auth:verificationEmailInformation':
      'Account confirmation is required. Please check your email for confirmation link.',
    'auth:verificationEmailResend': 'Resend email',
    'auth:verificationEmailResendCountdown': 'Resend email ({{countdown}}s)',
    'auth:verificationEmailSent':
      'Confirmation email sent to this email address',
    'auth:verificationPhoneCaution': 'Your OTP expires in',
    'auth:verificationPhoneCautionTime': ' 5 minutes.',
    'auth:verificationPhoneConfirmHeader': 'Success!',
    'auth:verificationPhoneConfirmHeaderDescription':
      'Your mobile number has been verified.',
    'auth:verificationPhoneConfirmHeaderInformation':
      'Now you can log in using your registered mobile number. Enjoy!',
    'auth:verificationPhoneDidNotGet': "Didn't get the OTP?",
    'auth:verificationPhoneHeaderDescription':
      'An OTP is sent to your mobile number. Please check your SMS.',
    'auth:verificationPhoneHeaderDescription_whatsapp':
      'An OTP is sent to your mobile number. Please check your WhatsApp.',
    'auth:verificationPhoneInputPlaceholder': 'Your 4-digits OTP',
    'auth:verificationPhoneOTP': 'OTP has expired',
    'auth:verificationPhoneResend': 'Resend OTP',
    'auth:verificationPhoneResendCountdown': 'Resend OTP ({{countdown}}s)',
    'auth:verificationWhatsappDidNotGet': "Didn't get the message?",
    'auth:verificationWhatsappResend': 'Resend message',
    'auth:verificationWhatsappResendCountdown':
      'Resend message ({{countdown}}s)',
    'auth:verifyAccount': 'Please verify your account to continue',
    'auth:waitingModalInfo':
      'Due to high demand, we are increasing the capacity of our server.',
    'auth:waitingModalDescription':
      'Currently, you are on the waiting list. We will inform you when our capacity has increased. Thank you for your patience.',
    'auth:waitingModalTitle': 'Sorry!',
    'auth:wefieHeader': 'Wefie',
    'auth:wefieThroughMobileApp':
      'Login your account through FotoYu mobile app, and do wefie with your children.',
    'auth:wefieReapplyModalDescription':
      'Please make sure that all requirements are correct, and try again.',
    'auth:wefieReapplyModalTitle':
      "You can't log in, because we found your wefie doesn't meet the requirements.",
    'auth:wefieWaitingModalDescription':
      'RoboYu will inform you as soon as possible after the process is done.',
    'auth:wefieWaitingModalTitle':
      "You can't log in, because your wefie has not been approved by RoboYu.",
  },
}
