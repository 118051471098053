import { v4 as UUID } from 'uuid'

export function appendMultiFormData(
  formData: FormData,
  data: object,
) {
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }
}

export function getMultiformData(data: object | object[]) {
  const formData = new FormData()

  if (Array.isArray(data)) {
    for (const item of data) {
      appendMultiFormData(formData, item)
    }
  } else {
    appendMultiFormData(formData, data)
  }

  return formData
}

export function getUniqueId() {
  return UUID()
}
