import {Button, ListItemSelect, Modal, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import React from 'react'
import styled from 'styled-components'
import {TreeEditProfileModalImageOptionsProps} from './TreeEditProfileModalImageOptionsProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(325)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${convertUnit(16)};
`

const StyledModalTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(25)};
  margin-bottom: ${convertUnit(15)};
`

const StyledCloseButton = styled(Button)`
  margin: ${convertUnit(25)};
`

const StyledListItemSelect = styled(ListItemSelect)`
  padding-left: ${convertUnit(41.5)};
  height: ${convertUnit(30)};
  margin-top: ${convertUnit(10)};
  cursor: pointer;
`

export default function TreeEditProfileModalImageOptions({
  visible,
  onClickOption,
  toggleModal,
}: TreeEditProfileModalImageOptionsProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <StyledModalTitle fontSize="l" fontWeight="bold">
        {translate('global:options')}
      </StyledModalTitle>

      <StyledListItemSelect
        title={translate('tree:editHostImageChangePicture')}
        leftElement="pencil"
        onClick={onClickOption}
      />

      <StyledCloseButton
        color="primary_5"
        fontWeight="medium"
        onClick={toggleModal}
        backgroundColor="white_3"
        label={translate('global:close')}
      />
    </StyledModal>
  )
}
