import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {getContentURL, useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentItemSingleContent from '../GiftShopMessageRoomContentItemImage'

const StyledButton = styled(Button)`
  width: 100%;
  padding-top: ${convertUnit(16)};
`

export interface GiftShopMessageRoomContentItemResendRevisionProps {
  isSelf: boolean
  payload: FirebaseMessageRoomItemPayload['resend_revision']
}

export default function GiftShopMessageRoomContentItemResendRevision({
  isSelf,
  payload,
}: GiftShopMessageRoomContentItemResendRevisionProps) {
  const {translate} = useTranslation()
  const {content_id, invoice_id, message} = payload
  const history = useHistory()
  const [data, setData] = useState<GiftShopContentData>()
  const [loading, setLoading] = useState(true)
  const getUrl = getContentURL({url: data?.url || '', type: 'collection'})

  const handleLoadContent = useCallback(() => {
    requestData('giftshop_get_contents_detail', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {content_ids: content_id},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result[0])
        setLoading(false)
      },
    })
  }, [content_id])

  const handleNavigateCreationDetail = useCallback(() => {
    isSelf
      ? history.push('giftshop_collection_detail', {
          data: [{...data, type: 'collection'}],
          selectedItemId: content_id,
          limitContent: true,
        })
      : history.push(
          'giftshop_profile_creation_detail',
          {
            data: [data],
            selectedItemId: content_id,
            creationType: 'sold',
            limitContent: true,
          },
          data?.creator_name,
        )
  }, [content_id, data, history, isSelf])

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentItemSingleContent
        data={data}
        onClick={handleNavigateCreationDetail}
        src={getUrl}
        loading={loading}
      />
    ),
    [data, getUrl, handleNavigateCreationDetail, loading],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('messages:notificationResendApprovalInvoiceId', {
            invoice_id,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">{message}</Paragraph>
        <br />
      </>
    ),
    [invoice_id, message, translate],
  )

  const handleRenderButton = useMemo(
    () =>
      isSelf ? (
        <></>
      ) : (
        <StyledButton
          label={translate('giftShop:resendRevisionButton')}
          onClick={handleNavigateCreationDetail}
        />
      ),
    [handleNavigateCreationDetail, isSelf, translate],
  )

  useDidMount(handleLoadContent)

  return (
    <>
      {handleRenderContent}
      {handleRenderMessage}
      {handleRenderButton}
    </>
  )
}
