import React, {useCallback, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {ProgressBarProps} from './ProgressBarProps'

interface StyledFillerProps {
  width: number
  history: number
}

const StyledContainer = styled.div`
  height: ${convertUnit(2)};
  width: 100%;
  background-color: ${({theme}) => theme.gray_3};
`

const StyledFiller = styled.div<StyledFillerProps>`
  height: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: 100%;
  ${({width, history}) => ({
    width: `${width}%`,
    transition: width > history ? 'width 1s' : undefined,
  })}
`

export default function ProgressBar({progress}: ProgressBarProps) {
  const progressHistory = useRef(progress)

  const handleLayoutEffect = useCallback(() => {
    progressHistory.current = progress
  }, [progress])

  useLayoutEffect(handleLayoutEffect, [handleLayoutEffect])

  return (
    <StyledContainer>
      <StyledFiller width={progress} history={progressHistory.current} />
    </StyledContainer>
  )
}
