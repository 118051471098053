import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_HEADER_HEIGHT, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowLayout} from 'windows'
import {WefieType} from 'types'
import {getTextShadow, useTimer} from 'utils'
import convertUnit from 'lib/unit'
import {Image, Paragraph} from 'common/components'

interface AuthEasySignupChildrenOverlayCaptureProps {
  onClick(): void
  type: WefieType
  hasCounter?: boolean
}
interface StyledContentContainerProps {
  height: number
  width: number
}
const StyledContentContainer = styled.div<StyledContentContainerProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  ${({height, width}) => ({height, width})};
`
const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
const StyledOutlineOverlay = styled(Image)`
  width: 100%;
`
const StyledWefieOutlineOverlay = styled(Image)<StyledContentContainerProps>`
  ${({width}) => ({width})};
`
const StyledCountdownContainer = styled.div<StyledContentContainerProps>`
  flex-direction: row;
  position: absolute;
  top: ${convertUnit(20)};
  display: flex;
  ${({width}) => ({width})};
  z-index: 10;
  justify-content: space-between;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledBottomContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(20)};
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled.div`
  width: ${convertUnit(50)};
  height: ${convertUnit(50)};
  border-radius: ${convertUnit(50)};
  border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
  cursor: pointer;
  background-color: ${({theme}) => theme.white_1};
`
const StyledButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: ${convertUnit(8)};
  width: 100%;
  display: flex;
`
const StyledCountdown = styled(Paragraph)`
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
  margin-left: ${convertUnit(20)};
`
export default function AuthEasySignupChildrenOverlayCapture({
  onClick,
  type,
  hasCounter,
}: AuthEasySignupChildrenOverlayCaptureProps) {
  const {translate} = useTranslation()
  const faceOverlay = IMAGE_ASSET('giftshop', 'overlay-face.png')
  const overlayWefie = IMAGE_ASSET('giftshop', 'overlay-parent-consent.png')
  const {countdown, reset} = useTimer({duration: 4, countOnStart: false})
  const [showTimer, setShowTimer] = useState(false)
  const [capturing, setCapturing] = useState(false)
  const {height} = useWindowLayout()

  const handleOnClick = useCallback(() => {
    if (type === 'wefie') {
      reset()
      setCapturing(true)
      setShowTimer(true)
    } else {
      onClick()
    }
  }, [onClick, reset, type])

  useEffect(() => {
    if (hasCounter && countdown - 1 === 0) onClick()
  }, [countdown, hasCounter, onClick])

  return (
    <>
      {showTimer && (
        <StyledCountdownContainer
          height={height - GIFT_SHOP_HEADER_HEIGHT}
          width={(height - GIFT_SHOP_HEADER_HEIGHT) * 0.67}>
          <StyledCountdown color="white_1" fontWeight="medium" fontSize="xxxl">
            {countdown - 1}
          </StyledCountdown>
        </StyledCountdownContainer>
      )}
      <StyledContentContainer
        height={height - GIFT_SHOP_HEADER_HEIGHT}
        width={(height - GIFT_SHOP_HEADER_HEIGHT) * 0.67}>
        {type === 'selfie' ? (
          <StyledContainer>
            <StyledOutlineOverlay src={faceOverlay} alt="" />
          </StyledContainer>
        ) : (
          <StyledWefieOutlineOverlay
            src={overlayWefie}
            alt=""
            height={height - GIFT_SHOP_HEADER_HEIGHT}
            width={(height - GIFT_SHOP_HEADER_HEIGHT) * 0.67}
          />
        )}
        {capturing ? (
          <></>
        ) : (
          <StyledBottomContainer>
            <StyledParagraph fontSize="m" color="white_1">
              {translate('auth:registerEasySignupChildWefieDesc', {
                context: type,
              })}
            </StyledParagraph>
            <StyledButtonContainer>
              <StyledButton onClick={handleOnClick} />
            </StyledButtonContainer>
          </StyledBottomContainer>
        )}
      </StyledContentContainer>
    </>
  )
}
