import {
  GIFT_SHOP_RESEND_VIDEO_MAX_DURATION,
  GIFT_SHOP_UPLOAD_VIDEO_MAX_SECONDS,
  GIFT_SHOP_UPLOAD_VIDEO_UNSUPPORTED_OS,
} from 'consts'
import {VideoMetadata} from 'types'
import {UAParser} from 'ua-parser-js'

export function getVideoDuration(duration: number, isResend?: boolean) {
  const max = isResend
    ? GIFT_SHOP_RESEND_VIDEO_MAX_DURATION
    : GIFT_SHOP_UPLOAD_VIDEO_MAX_SECONDS
  const dur = Math.floor(duration > max ? duration / 1000 : duration)
  const second = dur >= 10 ? dur : `0${dur}`
  return `0:${second}`
}

export function loadVideoMetadata(file: File) {
  return new Promise<VideoMetadata | null>((resolve) => {
    const objectUrl = URL.createObjectURL(file)
    const video = document.createElement('video')

    const handleFinished = () => {
      URL.revokeObjectURL(objectUrl)
    }

    const handleError = () => {
      handleFinished()
      resolve(null)
    }

    const handleLoadedMetadata = () => {
      const {videoWidth: width, videoHeight: height, duration} = video
      handleFinished()
      resolve({width, height, duration})
    }
    video.src = objectUrl
    video.preload = 'metadata'
    video.onabort = handleError
    video.onerror = handleError
    video.onloadedmetadata = handleLoadedMetadata
  })
}

export function validateUnsupportedDevice() {
  const os = new UAParser(navigator.userAgent).getOS().name

  if (!os) return false

  return GIFT_SHOP_UPLOAD_VIDEO_UNSUPPORTED_OS.includes(os)
}
