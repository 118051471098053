import React, {useEffect, useMemo} from 'react'
import {requestData} from 'services'
import {FirebaseMessageRoomType} from 'types'
import {useDidMount} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import GiftShopMessageRoom from './GiftShopMessageRoom'
import GiftShopMessageRoomHeaderSystem from './GiftShopMessageRoomHeaderSystem'

export interface GiftShopMessageRoomSystemProps {
  roomId?: string
  roomType: FirebaseMessageRoomType
  participantId: string
}

export default function GiftShopMessageRoomSystem({
  roomId,
  roomType,
  participantId,
}: GiftShopMessageRoomSystemProps) {
  const {update} = useDispatch()
  const messageRoomState = Object.values(useSelector('messageRoomState'))

  useEffect(
    () =>
      function cleanup() {
        const currentTime = new Date().toISOString()
        let exist = false
        const newMessageRoomState =
          messageRoomState &&
          messageRoomState.map((obj) => {
            if (obj.room_id === roomId) {
              exist = true
              return {...obj, last_leave: currentTime}
            }
            return obj
          })
        if (exist) {
          update('messageRoomState', newMessageRoomState)
        } else
          messageRoomState &&
            roomId &&
            update(
              'messageRoomState',
              messageRoomState.concat([
                {room_id: roomId, last_leave: currentTime},
              ]),
            )
      },
    [messageRoomState, roomId, update],
  )

  const handleRenderHeader = useMemo(
    () =>
      roomId ? (
        <GiftShopMessageRoomHeaderSystem
          roomId={roomId}
          participantId={participantId}
        />
      ) : null,
    [participantId, roomId],
  )

  const handleRenderContent = useMemo(
    () =>
      roomId ? (
        <GiftShopMessageRoom
          participantId={participantId}
          roomId={roomId}
          roomType={roomType}
        />
      ) : null,
    [participantId, roomId, roomType],
  )

  useDidMount(() => {
    if (roomId)
      requestData('giftshop_dm_room_enter', {
        data: {
          room_id: roomId,
          room_type: roomType,
        },
      })
  })

  return (
    <>
      {handleRenderHeader}
      {handleRenderContent}
    </>
  )
}
