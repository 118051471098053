import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import {translate} from 'i18n'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_3})}
  height: ${convertUnit(64)};
  align-items: center;
  justify-content: center;
  display: flex;
`
export default function GiftShopMessageRoomInputSystem() {
  return (
    <StyledContainer>
      <Paragraph fontWeight="medium" fontSize="m" color="gray_3">
        {translate('messages:system_input')}
      </Paragraph>
    </StyledContainer>
  )
}
