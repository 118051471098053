import React from 'react'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {GiftShopRootEmpty} from 'pages'

export default function GiftShopCartContentEmpty() {
  const {translate} = useTranslation()
  const emptyListImage = IMAGE_ASSET('giftshop', 'empty-list.png')

  return (
    <GiftShopRootEmpty
      image={emptyListImage}
      imageAlt={emptyListImage}
      title={translate('giftShop:cartEmptyInformationTitle')}
      message={translate('giftShop:cartEmptyInformationDescription')}
    />
  )
}
