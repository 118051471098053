import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload, ForkygramPostData} from 'types'
import {useHistory} from 'utils'
import {Paragraph, Image} from 'common/components'
import convertUnit from 'lib/unit'

export interface GiftShopMessageRoomContentItemNotificationLikeProps {
  payload: FirebaseMessageRoomItemPayload['notification_like']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMessageContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

export default function GiftShopMessageRoomContentItemNotificationLike({
  payload: {username, item},
}: GiftShopMessageRoomContentItemNotificationLikeProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContiner>
        <Paragraph fontSize="m" fontWeight="bold">
          {`@${username}`}{' '}
        </Paragraph>
        <StyledParagraph fontSize="m">
          {translate('messages:notificationLike')}
        </StyledParagraph>
      </StyledMessageContiner>
    ),
    [translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImage
        src={item.url}
        alt="image"
        onClick={() => {
          history.push(
            'giftshop_profile_post_detail',
            {
              data,
              self: true,
              selectedItemId: item.id,
              memberId: item.member_id,
            },
            item.username,
          )
        }}
      />
    ),
    [data, history, item.id, item.member_id, item.url, item.username],
  )

  return (
    <StyledContainer>
      {handleRenderMessage}
      {handleRenderContent}
    </StyledContainer>
  )
}
