import React from 'react'
import styled from 'styled-components'
import {ICON_SIZE} from 'consts'
import {getTextShadow} from 'utils'
import convertUnit from 'lib/unit'
import {IconProps} from './IconProps'

const StyledIcon = styled.i<IconProps>`
  ${({
    size = convertUnit(ICON_SIZE),
    color = 'black',
    theme,
    hasShadow,
    hasOpacity,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    textShadow,
  }) => ({
    fontSize: size,
    color: color !== 'inherit' ? theme[color] : 'inherit',
    textShadow: hasShadow
      ? textShadow ||
        getTextShadow(theme, {opacity: hasOpacity ? 0.5 : undefined})
      : undefined,
    margin: `${marginTop || 0} ${marginRight || 0} ${marginBottom || 0} ${
      marginLeft || 0
    }`,
  })}
  letter-spacing: 0;
  align-self: center;
`

export default function Icon({prefix = 'icon-', type, ...props}: IconProps) {
  return (
    <StyledIcon
      {...props}
      // @ts-ignore
      // This class name using from fontello
      className={`${prefix}${type}`}
      type={type}
    />
  )
}
