import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getThemeColor} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopBankListContentItemProps} from './GiftShopBankListContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(12.5)} 0;
  padding: ${convertUnit(16)} ${convertUnit(24)};
  background-color: ${({theme}) => theme.white_1};
  align-self: center;
  border-style: solid;
  border-radius: ${convertUnit(8)};
  border-color: ${({theme}) => getThemeColor(theme, 'gray_1', 0.9)};
  border-width: 1px;
`

const StyledInfoContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`

const StyledAccountStatusContainer = styled.div`
  flex: 1;
  display: flex;
`

const StyledParagraph = styled(Paragraph)`
  margin-right: ${convertUnit(4)};
`

const StyledMainAccountBadge = styled.div`
  ${({theme}) => ({backgroundColor: theme.info_4})}
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  text-align: center;
`

export default function GiftShopBankListContentItem({
  bankNumber,
  accountName,
  isMainAccount,
  bankName,
  hideMainLabel,
}: GiftShopBankListContentItemProps) {
  const {translate} = useTranslation()

  const renderMainAccount = useMemo(
    () =>
      isMainAccount && !hideMainLabel ? (
        <StyledMainAccountBadge>
          <Paragraph
            fontSize="xs"
            fontWeight="medium"
            color="white_1"
            style={{alignSelf: 'center'}}>
            {translate('giftShop:bankAccountMain')}
          </Paragraph>
        </StyledMainAccountBadge>
      ) : null,
    [hideMainLabel, isMainAccount, translate],
  )

  const renderBankCode = useMemo(
    () => (
      <StyledParagraph fontSize="s" fontWeight="medium" color="primary_5">
        {bankName}
      </StyledParagraph>
    ),
    [bankName],
  )

  return (
    <StyledContainer>
      <StyledInfoContainer>
        <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
          {bankNumber}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="bold" color="black">
          {accountName.toUpperCase()}
        </Paragraph>
        <StyledAccountStatusContainer>
          {renderBankCode}
          {renderMainAccount}
        </StyledAccountStatusContainer>
      </StyledInfoContainer>
    </StyledContainer>
  )
}
