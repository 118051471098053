import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Icon, Image, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {
  getBorder,
  getBoxShadow,
  getFontFamily,
  getFontSize,
  parseDotsToNumber,
} from 'utils'
import {FototreeTallestTreeItemProps} from './FototreeTallestTreeProps'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(6)} ${convertUnit(8)};
  margin: ${convertUnit(2)} 0;
  gap: ${convertUnit(8)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      verticalOffset: 2,
      blurRadius: 4,
      opacity: 0.24,
    })};
  cursor: pointer;
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(2.5)};
  justify-content: center;
`

const StyledInfoBottomContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${convertUnit(4)};
`

const StyledImage = styled(Image)`
  border: ${({theme}) => getBorder(0.5, 'solid', theme.gray_4)};
  border-radius: ${convertUnit(4)};
  max-width: ${convertUnit(28)};
  width: 100%;
  aspect-ratio: 0.75;
  object-fit: contain;
`

const StyledBold = styled.span`
  font-family ${getFontFamily('bold')};
  font-size: ${getFontSize('xs')};
`

const StyledIconContainer = styled.div`
  position: absolute;
`

const StyledRankContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${convertUnit(32)};
  height: ${convertUnit(32)};
`

const StyledRankLabel = styled.span`
  font-family ${getFontFamily('bold')};
  font-size: ${getFontSize('xxs')};
  margin-left: ${convertUnit(2)};
  z-index: 2;
`

const StyledDivider = styled.div`
  height: ${convertUnit(8)};
  border: ${({theme}) => getBorder(0.5, 'solid', theme.primary_5)};
`

export default function FototreeTallestTreeItem({
  rank,
  data,
  onClickItem,
}: FototreeTallestTreeItemProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const {translate} = useTranslation()

  const handleRankLabel = useMemo(
    () => (
      <StyledRankContainer>
        <StyledIconContainer>
          <Icon type="leaf-rank" size={convertUnit(32)} color="warning_4" />
        </StyledIconContainer>
        <StyledRankLabel>{rank}</StyledRankLabel>
      </StyledRankContainer>
    ),
    [rank],
  )

  const handleRenderImage = useMemo(
    () => (
      <StyledImage src={data.url} fallbackSrc={defaultImage} alt={data.name} />
    ),
    [data.name, data.url, defaultImage],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledInfoContainer>
        <Paragraph fontWeight="medium">{data.name}</Paragraph>
        <StyledInfoBottomContainer>
          <Paragraph fontSize="xs" color="primary_5">
            <StyledBold>{parseDotsToNumber(data.leaf_count)}</StyledBold>{' '}
            {translate('forkygram:leafs')}
          </Paragraph>
          <StyledDivider />
          <Paragraph fontSize="xs" color="primary_5">
            <StyledBold>{data.height || 0}</StyledBold>{' '}
            {translate('forkygram:treeHeight')}
          </Paragraph>
        </StyledInfoBottomContainer>
      </StyledInfoContainer>
    ),
    [data.height, data.leaf_count, data.name, translate],
  )

  return (
    <StyledContainer onClick={() => onClickItem(data)}>
      {handleRankLabel}
      {handleRenderImage}
      {handleRenderInfo}
    </StyledContainer>
  )
}
