import React, {useCallback, useMemo, useRef, useState} from 'react'
import {translate} from 'i18n'
import {requestData} from 'services'
import {
  FORKYGRAM_FEED_QUERY_LIMIT,
  FORKYGRAM_FOOTPRINTS_QUERY_LIMIT,
} from 'consts'
import {TreeFotoTreeLeafFootprintTree} from 'types'
import {useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {ListNotLazy} from 'common/components'
import {GiftShopRootScreen} from 'pages/gift-shop'
import {ForkygramFootprintPagination} from '../../footprints'
import {
  ForkygramListGridItem,
  ForkygramTemplateListGridContent,
} from '../containers'

export default function ForkygramFootprintDetailScreen() {
  const mode = useWindowMode()
  const history = useHistory()
  const {memberId, treeId, treeName, treeAlias} = useLocation(
    'forkygram_footprint_detail',
  ).state
  const lastPage = useRef(1)
  const stateData = useState<ReadonlyArray<TreeFotoTreeLeafFootprintTree>>([])
  const statePage = useState(1)
  const data = stateData[0]
  const rawPage = statePage[0]
  const [count, setCount] = useState(0)
  const column = mode === 'website' ? 5 : 4

  const pageCount = useMemo(
    () => Math.ceil(count / FORKYGRAM_FOOTPRINTS_QUERY_LIMIT),
    [count],
  )

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => {
      const index = data.findIndex((post) => post.post_id === item.post_id)
      const basePage = Math.floor(index / FORKYGRAM_FEED_QUERY_LIMIT) + 1
      const currPage = basePage + (lastPage.current - 1) * 3

      history.push(
        'tree_fototree_feed',
        {
          treeAlias: item.alias,
          treeId: item.tree_id,
          selectedItemId: item.post_id,
          memberId,
          isPastMemory: true,
          initialPage: currPage,
        },
        item.alias,
      )
    },
    [data, history, memberId],
  )

  const handleClickHeader = useCallback(() => {
    history.push('tree_fototree_detail', {treeId, treeAlias}, treeAlias)
  }, [history, treeAlias, treeId])

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => (
      <ForkygramListGridItem
        borderColor="black"
        column={column}
        data={{
          contentType: item.post.content_type,
          url: item.url,
          thumbnail: item.url,
          isHidden: item.is_hidden,
        }}
        onClickItem={() => handleNavigateFotoTreeDetail(item)}
      />
    ),
    [column, handleNavigateFotoTreeDetail],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_get_leaf_footprints', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, member_id: memberId, tree_id: treeId},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setCount(result.count)
          }
        },
      })

      lastPage.current = page

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.footprints
        : []
    },
    [memberId, treeId],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplateListGridContent
        innerMargin={0}
        header={false}
        backgroundColor="black"
        containerHeight="100%">
        <ForkygramFootprintPagination
          pageCount={pageCount}
          statePage={statePage}
        />
        <ListNotLazy
          renderItem={handleRenderItem}
          stateData={stateData}
          loadData={handleLoadData}
          limitPerPage={FORKYGRAM_FOOTPRINTS_QUERY_LIMIT}
          defaultPage={rawPage}
          page={rawPage}
          pageCount={pageCount}
          keyExtractor={(item: TreeFotoTreeLeafFootprintTree) => item.url}
          numColumns={column}
          scrollbar={false}
        />
      </ForkygramTemplateListGridContent>
    ),
    [
      column,
      handleLoadData,
      handleRenderItem,
      pageCount,
      rawPage,
      stateData,
      statePage,
    ],
  )
  return (
    <GiftShopRootScreen
      showBackIcon
      showBadgeIcon={false}
      darkBar
      darkHeader
      headerTitle={treeName}
      headerBorderColor="gray_8"
      headerSubtitle={translate('tree:fototreeFootprintsLeafCount', {count})}
      onClickHeader={handleClickHeader}>
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
