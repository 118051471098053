import React, {CSSProperties} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getCurrencyValue, parseDate} from 'utils'
import {Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import GiftShopStorageHistoryContentItemProps from './GiftShopStorageHistoryContentItemProps'

const StyledCardContainer = styled.div`
  background-color: ${({theme}) => theme.white_1};
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(12)} ${convertUnit(20)};
  display: flex;
  align-items: stretch;
  flex-direction: row;
  min-height: ${convertUnit(71)};
`

const StyledLeftContainer = styled.div<CSSProperties>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  height: 100%;
  margin-bottom: ${convertUnit(5)};
`

const StyledInfo = styled.div<CSSProperties>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({justifyContent}) => justifyContent};
`

const StyledParagraph = styled(Paragraph)`
  text-transform: capitalize;
`

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  bottom: 0;
`

const StyledFlexItem = styled.div<CSSProperties>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  height: 100%;
`

export default function GiftShopStorageHistoryContentItem({
  data,
}: GiftShopStorageHistoryContentItemProps) {
  const {translate} = useTranslation()
  const user = useSelector('user')

  return (
    <StyledCardContainer>
      <StyledLeftContainer alignItems="start" justifyContent="left">
        <StyledInfo justifyContent="end">
          <Paragraph fontWeight="bold" fontSize="s" color="gray_5">
            {data.buyer_nickname === user?.nickname
              ? translate('global:fotoyuAdmin')
              : ` ${translate('giftShop:newYuser', {
                  yuser: data.buyer_nickname,
                })}`}
          </Paragraph>
        </StyledInfo>
        <StyledContainer>
          <StyledParagraph fontWeight="bold" fontSize="m">
            {` ${translate('giftShop:additionalStorage', {
              bonus: data.additional_storage,
            })}`}
          </StyledParagraph>
        </StyledContainer>
      </StyledLeftContainer>
      <StyledFlexItem alignItems="end" justifyContent="right">
        <StyledInfo justifyContent="end">
          <Paragraph fontWeight="regular" fontSize="s" color="gray_4">
            {parseDate(data.purchased_at, 'MMM D, YYYY')}
          </Paragraph>
        </StyledInfo>
        <StyledParagraph fontWeight="regular" fontSize="m">
          {getCurrencyValue(data.incentive)}
        </StyledParagraph>
      </StyledFlexItem>
    </StyledCardContainer>
  )
}
