import {useCallback, useState} from 'react'
import firebase from 'lib/firebase'
import {useSelector} from 'lib/redux'
import {FirestoreExploreRefreshPayload} from 'types'
import {useDidMount} from 'utils'

export function useFirestoreExploreRefresh() {
  const user = useSelector('user')
  const [lastUpdate, setLastUpdate] = useState<number>()

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_CONTENT!)
        .doc(user?.id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data() as FirestoreExploreRefreshPayload
          data && setLastUpdate(data.explore_last_update)
        }),
    [user?.id],
  )

  useDidMount(handleFirestore)

  return lastUpdate
}
