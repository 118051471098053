import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {POLICY_HEADER_HEIGHT} from 'consts'
import {requestData} from 'services'
import {LanguageData, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {DropdownItem, List, ListItemSelect, Menu} from 'common/components'
import {LANGUAGES} from 'consts/language'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {PolicyRootLanguageDropDownListProps} from './PolicyRootLanguageDropDownListProps'

interface StyledSectionChildrenProps {
  mode: WindowModeType
}

const StyledSectionHeader = styled.div<StyledSectionChildrenProps>`
  display: flex;
  box-sizing: border-box;
  ${({mode}) => ({
    marginTop: mode === 'website' ? convertUnit(50) : undefined,
    marginLeft: mode === 'website' ? convertUnit(340) : undefined,
    width: mode === 'website' ? '100%' : undefined,
  })}
  height: ${convertUnit(POLICY_HEADER_HEIGHT)};
`

export default function PolicyRootLanguageDropDownList({
  languageToggleState,
}: PolicyRootLanguageDropDownListProps) {
  const mode = useWindowMode()
  const [languageToggle, setLanguageToggle] = languageToggleState
  const {update} = useDispatch()
  const {lang} = useSelector('user')!
  const data = useState(LANGUAGES)[0]

  const handleSelectLanguage = useCallback(
    (item: LanguageData) => {
      const {languageTag} = item
      requestData('giftshop_profile_edit_language', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {lang: languageTag},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('languageState', {lang: languageTag})
            location.reload()
          }
        },
      })
      setLanguageToggle((previous) => !previous)
    },
    [setLanguageToggle, update],
  )

  const handleRenderLanguageDropdownItem = useCallback(
    (item: LanguageData) => (
      <ListItemSelect
        title={item.languageTag.toUpperCase()}
        onClick={() => {
          handleSelectLanguage(item)
        }}
        textColor="primary_5"
      />
    ),
    [handleSelectLanguage],
  )

  const handleLanguageLabel = useMemo(
    () =>
      data
        .find((language) => language.languageTag === lang)
        ?.languageTag.toUpperCase(),
    [data, lang],
  )
  return (
    <StyledSectionHeader mode={mode}>
      <Menu
        stateToggle={languageToggleState}
        contentContainerStyle={{
          height: convertUnit(48),
          borderRadius: convertUnit(8),
          width: convertUnit(85),
        }}
        renderContent={
          <List
            data={data.filter((language) => language.languageTag !== lang)}
            keyExtractor={(item) => item.languageTag}
            renderItem={handleRenderLanguageDropdownItem}
            scrollbar={false}
          />
        }>
        <DropdownItem
          label={handleLanguageLabel!}
          toggle={languageToggle}
          onClick={(e) => {
            e.preventDefault()
            setLanguageToggle((previous) => !previous)
          }}
          itemColor="primary_5"
          borderColor="primary_5"
          backgroundColor="white_1"
          backgroundToggleColor="white_1"
        />
      </Menu>
    </StyledSectionHeader>
  )
}
