import React, {useMemo} from 'react'
import styled from 'styled-components'
import {uploadColorExtractor} from 'utils'
import {Paragraph, IndicatorBar} from 'common/components'
import {GiftshopUploadPublishStatus} from 'types'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadElaborateModalProps} from './GiftShopTemplateUploadElaborateModalProps'

const StyledProgressContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.gray_1})}
  width: 100%;
  height: ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
  margin: ${convertUnit(20)} 0;
  overflow: hidden;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledNote = styled(StyledParagraph)`
  margin-top: ${convertUnit(5)};
`

export default function GiftShopTemplateUploadElaborateModal<
  S extends GiftshopUploadPublishStatus
>({
  title,
  description,
  note,
  statuses,
  colorExtractor = uploadColorExtractor,
  bottomElement,
}: GiftShopTemplateUploadElaborateModalProps<S>) {
  const handleRenderProgress = useMemo(
    () => (
      <StyledProgressContainer>
        <IndicatorBar statuses={statuses} colorExtractor={colorExtractor} />
      </StyledProgressContainer>
    ),
    [colorExtractor, statuses],
  )

  return (
    <>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {title}
      </StyledParagraph>
      {handleRenderProgress}
      <StyledParagraph fontSize="l" color="gray_5">
        {description}
      </StyledParagraph>
      {!!note && (
        <StyledNote fontSize="m" color="gray_3">
          {note}
        </StyledNote>
      )}
      {bottomElement}
    </>
  )
}
