import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ModalOnHoldItemProps} from './ModalOnHoldItemProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: ${convertUnit(20)} 0;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  width: 100%;
`

export default function ModalOnHoldItem({
  visible,
  onCancel,
  onChooseAccount,
}: ModalOnHoldItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <Icon type="user-lock" color="success_5" size={convertUnit(48)} />
      <StyledItem>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('auth:similarIdentityDescFirst')}
        </Paragraph>
      </StyledItem>

      <Paragraph fontSize="m">
        {translate('auth:similarIdentityDescSecond')}
      </Paragraph>

      <StyledButtonContainer>
        <StyledButton
          label={translate('auth:similarIdentityButton')}
          onClick={onChooseAccount}
        />
        <StyledButton
          color="primary_5"
          backgroundColor="white_3"
          label={translate('global:cancel')}
          onClick={onCancel}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
