import React from 'react'
import styled from 'styled-components'
import {HOST_COMPANY_MAX_LENGTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input} from 'common/components'
import {VALIDATION_HOST_REGISTRATION_COMPANY_NAME} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

const StyledLeftItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  margin-right: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: 0;
    width: 100%;
  }
`

export default function TreeRegistrationFormItemCompanyName({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {companyName} = watch()

  return (
    <StyledLeftItemsContainer>
      <Input
        form={form}
        name="companyName"
        disabled={disabled}
        label={translate('tree:hostRegistrationFormCompanyLabel')}
        placeholder={translate('tree:hostRegistrationFormCompanyPlaceholder')}
        maxLength={HOST_COMPANY_MAX_LENGTH + 1}
        formRules={VALIDATION_HOST_REGISTRATION_COMPANY_NAME}
        onChangeText={(text) => {
          setValue('companyName', text.toUpperCase())
        }}
        rightIcon={
          !disabled && companyName && companyName.length > 0 ? (
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={() => setValue('companyName', '')}
            />
          ) : undefined
        }
      />
    </StyledLeftItemsContainer>
  )
}
