import { createContext } from 'react'
import { LayoutDimension, NetworkStatus, WindowModeType } from 'types'
import { getWindowMode } from 'utils'

export const WindowLayoutContext = createContext<LayoutDimension>({
  width: window.innerWidth,
  height: window.innerHeight,
})

export const WindowNetworkStatusContext = createContext<NetworkStatus>(
  navigator.onLine ? 'online' : 'offline',
)

export const WindowModeContext = createContext<WindowModeType>(
  getWindowMode(),
)
