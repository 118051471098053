import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestDataPayload} from 'services'
import {GiftshopFollowResponse} from 'types'
import {Icon, ListLazy, Modal, Paragraph, View} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateFollowModalProps} from './TemplateFollowModalProps'
import TemplateFollowModalItem from './TemplateFollowModalItem'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(420)};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${convertUnit(500)};
  overflow: hidden;
`

const StyledContainer = styled(View)`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: ${({theme}) => theme.white_3};
  border-bottom-left-radius: ${convertUnit(14)};
  border-bottom-right-radius: ${convertUnit(14)};
`

const StyledClosedButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: ${convertUnit(8)};
  right: ${convertUnit(12)};
  transition: all 200ms;
  font-size: ${convertUnit(30)};
  font-weight: bold;
`

const StyledHeader = styled.div`
  position: relative;
  padding: ${convertUnit(16)} ${convertUnit(20)};
`

const StyledNoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function TemplateFollowModal({
  memberId,
  toggleModal,
  callback,
  isFollower = false,
}: TemplateFollowModalProps) {
  const {translate} = useTranslation()
  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestDataPayload(
        isFollower
          ? 'giftshop_member_follower_list'
          : 'giftshop_member_following_list',
        {
          useDefaultMessage: true,
          actionType: 'fetch',
          params: {
            page,
            limit,
            member_id: memberId,
          },
        },
      )

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : []
    },
    [memberId, isFollower],
  )

  const handleRenderItem = useCallback(
    (item: GiftshopFollowResponse) => (
      <TemplateFollowModalItem
        onClose={toggleModal}
        callback={callback}
        data={item}
      />
    ),
    [callback, toggleModal],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        scrollbar
        loadData={handleLoadData}
        renderItem={handleRenderItem}
        keyExtractor={(item) => item.id}
        listEmptyElement={
          <StyledNoDataContainer>
            <Paragraph fontSize="s" color="gray_3">
              {translate('giftShop:emptyDefaultMessage')}
            </Paragraph>
          </StyledNoDataContainer>
        }
      />
    ),
    [handleLoadData, handleRenderItem, translate],
  )

  return (
    <StyledModal visible>
      <StyledHeader>
        <Paragraph fontSize="l" fontWeight="bold">
          {isFollower
            ? translate('giftShop:followerTitle')
            : translate('giftShop:followingTitle')}
        </Paragraph>
        <StyledClosedButton onClick={toggleModal}>
          <Icon type="close" />
        </StyledClosedButton>
      </StyledHeader>

      <StyledContainer>{handleRenderList}</StyledContainer>
    </StyledModal>
  )
}
