import {FC} from 'react'
import {ROUTE_MAP_AUTH} from './auth'
import {ROUTE_MAP_FORKYGRAM} from './forkygram'
import {ROUTE_MAP_GIFT_SHOP} from './gift-shop'
import {ROUTE_MAP_TREE} from './tree'
import {RouteParamEmpty, RouteParam} from './RouteParam'
import {ROUTE_MAP_PAYMENT} from './payment'

export type RoutePermission = 'guest' | 'user' | 'all'

type CompleteRouteMapEntry<T> = {
  path: string
  component: FC<any>
  disabled?: boolean
  exact?: boolean
  nested?: RouteMap<T>
  permission?: RoutePermission
}

type DefaultState<T> = {
  defaultState: T | null
}

export type RouteMapEntry<T, K extends keyof T> = T[K] extends RouteParamEmpty
  ? CompleteRouteMapEntry<K>
  : CompleteRouteMapEntry<K> & DefaultState<T[K]>

export type RouteMap<T> = {
  [K in keyof T]: RouteMapEntry<T, K>
}

export const ROUTE_MAP: RouteMap<RouteParam> = {
  ...ROUTE_MAP_GIFT_SHOP,
  ...ROUTE_MAP_FORKYGRAM,
  ...ROUTE_MAP_PAYMENT,
  ...ROUTE_MAP_TREE,
  ...ROUTE_MAP_AUTH,
}
