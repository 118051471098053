import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_BULK_LIMIT, GIFTSHOP_UPLOAD_SINGLE_LIMIT} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopTemplateHostFeeModal,
  GiftShopTemplateZeroPriceModal,
} from 'pages'
import {getBorder, showSnackbar, useGiftshopUploadPublish} from 'utils'
import {TreeSearchFototreeResponse, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadPublishProps} from './GiftShopUploadPublishProps'
import GiftShopUploadPublishModalElaborate from './GiftShopUploadPublishModalElaborate'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledButtonProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    borderTop: getBorder(1, 'solid', theme.gray_1),
    padding: `${convertUnit(15)} ${convertUnit(mode === 'mobile' ? 25 : 50)}`,
    backgroundColor: theme.white_1,
    justifyContent: 'space-between',
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  flex: 1;
  margin-right: ${convertUnit(25)};
`

const StyledButton = styled(Button)<StyledButtonProps>`
  flex: 1;
`

export default function GiftShopUploadPublishSingle({
  total,
  data,
  formRef,
  mapRef,
  uploadType,
  videoFaceData,
  hideModal,
  onView,
}: GiftShopUploadPublishProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {publish, retry} = useGiftshopUploadPublish({
    data,
    mapRef,
    formRef,
    videoFaceData,
    uploadType,
  })
  const {active, mapForm} = mapRef.map.current
  const [priceModal, setPriceModal] = useState(false)
  const [hostFeeModal, setHostFeeModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCheckLocation = useCallback(
    async (location: string, tags: TreeSearchFototreeResponse[]) => {
      if (location === '' && tags.length === 0) {
        showSnackbar(
          translate('giftShop:uploadNeedLocationOrFototreeErrorMessage'),
        )
        return false
      }
      return true
    },
    [translate],
  )

  const handleCheckUploadRequirement = useCallback(async () => {
    let shouldShowPriceModal = false
    let shouldShowHostFeeModal = false

    const formData = Object.values(formRef.current)
    setLoading(true)

    for (const dt of formData) {
      if (shouldShowPriceModal && shouldShowHostFeeModal) return
      // eslint-disable-next-line no-await-in-loop
      const checkLocation = await handleCheckLocation(dt.location, dt.tags)
      const {price, tags} = dt
      if (price === '') {
        showSnackbar(translate('giftShop:uploadPriceEmpty'))
        setLoading(false)
        return
      }
      if (checkLocation) {
        if (parseInt(price, 10) === 0) {
          shouldShowPriceModal = true
        } else if (!!tags[0] && tags[0].host_fee) {
          shouldShowHostFeeModal = true
        }
      } else {
        setLoading(false)
        return
      }
    }

    if (shouldShowPriceModal) {
      setPriceModal(true)
      if (shouldShowHostFeeModal) {
        setHostFeeModal(true)
      }
    } else if (shouldShowHostFeeModal) {
      setHostFeeModal(true)
    } else {
      publish()
    }
  }, [formRef, handleCheckLocation, publish, translate])

  const handleRenderZeroPriceConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateZeroPriceModal
        visible={priceModal}
        onConfirm={() => {
          setLoading(true)
          if (hostFeeModal) {
            setPriceModal(false)
          } else {
            publish()
          }
        }}
        toggleModal={() => setPriceModal(false)}
        onCancel={() => {
          setLoading(false)
          if (hostFeeModal) {
            setHostFeeModal(false)
          }
        }}
      />
    ),
    [hostFeeModal, priceModal, publish],
  )

  const handleRenderHostFeeConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateHostFeeModal
        visible={hostFeeModal}
        onConfirm={() => {
          setLoading(true)
          if (priceModal) {
            setHostFeeModal(false)
          } else {
            publish()
          }
        }}
        onCancel={() => {
          setLoading(false)
          if (priceModal) {
            setPriceModal(false)
          }
        }}
        toggleModal={() => setHostFeeModal(false)}
      />
    ),
    [hostFeeModal, priceModal, publish],
  )

  return (
    <StyledContainer mode={mode}>
      {handleRenderZeroPriceConfirmationModal}
      {handleRenderHostFeeConfirmationModal}
      <GiftShopUploadPublishModalElaborate
        mapForm={mapForm}
        onRetry={retry}
        onView={(tab) => {
          if (onView) onView(tab)
        }}
        toggle={active && !hideModal}
      />
      {uploadType !== 'video' ? (
        <StyledParagraph fontSize="l" fontWeight="medium">
          {translate('giftShop:contentCount', {
            count: total,
            max:
              uploadType === 'single'
                ? GIFTSHOP_UPLOAD_SINGLE_LIMIT
                : GIFTSHOP_UPLOAD_BULK_LIMIT,
          })}
        </StyledParagraph>
      ) : (
        <></>
      )}
      <StyledButton
        mode={mode}
        label={translate(
          uploadType === 'video' ? 'global:upload' : 'global:publish',
        )}
        onClick={() => {
          handleCheckUploadRequirement()
        }}
        backgroundColor="primary_5"
        isLoading={loading}
        disabled={loading || active}
      />
    </StyledContainer>
  )
}
