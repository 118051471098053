import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_NAME_MAX_LENGTH,
  HOST_NAME_MIN_LENGTH,
  REGEX_ALPHANUMERIC_DOT_AND_SPACE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useDidMount} from 'utils'
import {Button, Input, ModalLoading} from 'common/components'
import {VALIDATION_TREE_HOST_NAME} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeEditProfileFormStringProps} from './TreeEditProfileFormProps'

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    padding: ${convertUnit(20)};
    border-radius: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeEditProfileFormHostName({
  defaultValue,
  stateActiveContent,
}: TreeEditProfileFormStringProps) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)

  const form = useForm<{hostName: string}>({
    defaultValues: {
      hostName: defaultValue || '',
    },
  })
  const {watch, errors, register, setError} = form
  const {hostName} = watch()
  const disabled = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      hostName.length < HOST_NAME_MIN_LENGTH ||
      hostName.length > HOST_NAME_MAX_LENGTH ||
      hostName === defaultValue ||
      !hostName.match(REGEX_ALPHANUMERIC_DOT_AND_SPACE),
    [defaultValue, errors, hostName],
  )

  const handlePatchHost = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_hosts', {
      actionType: 'execute',
      data: {
        tag_name: hostName,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status, data}) => {
        if (
          status === 400 &&
          data.detail?.tag_name === 'tag name already exists'
        ) {
          setError('hostName', {
            type: 'manual',
            message: translate('tree:addHostInfoErrorHashtagAlreadyExist'),
          })

          return
        }
        status === 200 && setActiveContent('profile')
      },
    })
  }, [hostName, setActiveContent, setError, translate])

  const handleRenderForm = useMemo(
    () => (
      <Input
        form={form}
        name="hostName"
        formRules={VALIDATION_TREE_HOST_NAME}
        label={translate('tree:editHostFormLabelHostName')}
        placeholder={translate('tree:editHostFormPlaceholderHostName')}
        maxLength={HOST_NAME_MAX_LENGTH + 1}
      />
    ),
    [form, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={disabled}
        label={translate('global:save')}
        onClick={handlePatchHost}
      />
    ),
    [disabled, handlePatchHost, translate],
  )

  useDidMount(() => {
    register('hostName')
  })

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderForm}
      {handleRenderButton}
    </StyledContainer>
  )
}
