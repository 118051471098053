import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateSorryModalContentProps} from './TemplateSorryModalContentProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`

const StyledParagraph = styled(Paragraph)`
  margin: ${convertUnit(15)} 0;
  padding: 0;
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const GiftShopRoboPetImage = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(200)};
  height: ${convertUnit(200)};
  flex-grow: 0;
`

const StyledImageContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function TemplateSorryModalContent({
  visible,
  upperDesc,
  bottomDesc,
  robopetImage = true,
  toggleModal,
}: TemplateSorryModalContentProps) {
  const robopetOfferImage = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const robopetConfusedImage = IMAGE_ASSET('giftshop', 'robopet-confused.png')
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="xl" fontWeight="bold">
        {translate('global:sorry')}
      </StyledTitle>
      <StyledParagraph fontSize="m" fontWeight="regular">
        {upperDesc}
      </StyledParagraph>
      <StyledImageContainer>
        <GiftShopRoboPetImage
          src={robopetImage ? robopetOfferImage : robopetConfusedImage}
          alt="RoboPetOffer"
        />
      </StyledImageContainer>
      <StyledParagraph fontSize="m" fontWeight="regular" color="gray_6">
        {bottomDesc}
      </StyledParagraph>
      <Button label={translate('global:ok')} onClick={toggleModal} />
    </StyledModal>
  )
}
