import {REGEX_THOUSANDS} from 'consts'
import {translate} from 'i18n'

export function getCount(num: number) {
  const PREFIX_SYMBOL = ['K', 'M', 'B']
  const exponent =
    num >= 10000
      ? Math.min(
          PREFIX_SYMBOL.length,
          Math.floor(Math.log(num) / Math.log(1000)),
        )
      : 0
  const multiplier = 1000 ** exponent
  const relativeValue = num / multiplier

  const count = parseFloat(
    relativeValue % 1 === 0
      ? relativeValue.toFixed(0)
      : relativeValue.toFixed(1),
  )
  const symbol = exponent > 0 ? PREFIX_SYMBOL[exponent - 1] : null

  return translate('forkygram:count', {
    context: symbol,
    count,
  })
}

export function getFixedNumber(num: number) {
  return num.toFixed(2)
}

export function parseDotsToNumber(num: number) {
  return num.toString().replace(REGEX_THOUSANDS, '.')
}
