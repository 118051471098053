import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopEditProfilePasswordStatusProps} from './GiftShopEditProfilePasswordStatusProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled.div<StyledContainerProps>`
  width: ${({mode}) => (mode === 'mobile' ? '100%' : convertUnit(451))};
  padding: ${convertUnit(25)};
`

const StyledHeaderDescription = styled(Paragraph)`
  margin: ${convertUnit(10)} 0;
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledIllustrationContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${convertUnit(200)};
  align-items: center;
  justify-content: center;
`

const StyledIllustration = styled(Image)`
  width: 100%;
  height: '100%';
  max-width: ${convertUnit(350)};
  object-fit: contain;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export default function GiftShopEditProfilePasswordStatus({
  buttonLabel,
  bottomDesc,
  route,
  success,
  onClickButton,
}: GiftShopEditProfilePasswordStatusProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const successImg = IMAGE_ASSET('giftshop', 'success.png')
  const expiredImg = IMAGE_ASSET('giftshop', 'expired.png')
  const history = useHistory()
  return (
    <StyledScreenContainer>
      <StyledContainer mode={mode}>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {success
            ? translate('auth:linkSuccessHeader')
            : translate('auth:linkExpiredHeader')}
        </StyledParagraph>
        <StyledHeaderDescription fontWeight="medium" color="gray_5">
          {success
            ? translate('auth:resetPasswordSuccessSubtitle')
            : translate('auth:linkExpiredHeaderDescription')}
        </StyledHeaderDescription>
        <StyledIllustrationContainer>
          <StyledIllustration
            src={success ? successImg : expiredImg}
            alt={
              success
                ? translate('global:success')
                : translate('auth:linkExpiredHeader')
            }
          />
        </StyledIllustrationContainer>
        <StyledBottomSection>{bottomDesc}</StyledBottomSection>

        {buttonLabel && route && (
          <StyledButton
            label={buttonLabel}
            onClick={() => {
              onClickButton && onClickButton()
              history.replace(route, {})
            }}
          />
        )}
      </StyledContainer>
    </StyledScreenContainer>
  )
}
