import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {isIOS, isSafari} from 'react-device-detect'
import {
  ButtonIcon,
  Icon,
  Input,
  InputPhone,
  LanguageToggle,
  Link,
  Modal,
  Paragraph,
  ParsedText,
  TabView,
} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {
  AuthCountryResponse,
  AuthCredentialType,
  AuthFormRegisterEasySignupData,
  parseShape,
} from 'types'
import {
  AUTH_COUNTRY_DEFAULT,
  AUTH_DOB_MIN_YEAR,
  AUTH_USERNAME_MAX_LENGTH,
  AUTH_USER_MIN_AGE,
  REGEX_ALLOWED_USERNAME,
  REGEX_EMOJI,
  REGEX_LEADING_ZERO,
  REGEX_PHONE_NUMBER,
  REGEX_USERNAME_CONTAINS_FOTOYU,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {
  VALIDATION_AUTH_EMAIL,
  VALIDATION_AUTH_PHONE,
  VALIDATION_AUTH_USERNAME,
} from 'common/validations'
import {useTheme} from 'themes'
import {
  formatPhoneNumber,
  getAuthCookie,
  getAuthPrivacy,
  getAuthToS,
  parseDate,
  showSnackbar,
  useDidMount,
  useHistory,
  useLocation,
  validateMinimumAge,
} from 'utils'
import {requestData} from 'services'
import {useDispatch, useSelector} from 'lib/redux'
import {AuthLoginApple, AuthLoginGoogle} from '../../login'
import {AuthRegisterWaitingModal} from '../../register'
import {
  TemplateAuthEasySignupCredentialConfirmModal,
  TemplateAuthEasySignupDuplicateModal,
} from '../../../template'

const StyledHeader = styled(Paragraph)`
  text-align: center;
`
const StyledTabContainer = styled.div`
  height: fit-content;
`
const StyledSectionLogin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const StyledAgreement = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

const StyledLogin = styled(Paragraph)`
  margin-left: ${convertUnit(9)};
`
const StyledButton = styled(ButtonIcon)`
  margin-top: ${convertUnit(25)};
  width: 100%;
`
const StyledModal = styled(Modal)`
  width: ${convertUnit(420)};
  padding: 0 ${convertUnit(25)} ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  height: ${convertUnit(470)};
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledIcon = styled.div`
  margin: ${convertUnit(10)} 0;
  width: ${convertUnit(20)};
  height: ${convertUnit(20)};
  background-color: ${({theme}) => theme.gray_5};
  border-radius: ${convertUnit(6)};
  padding: ${convertUnit(3)} ${convertUnit(2)};
  align-self: flex-end;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${convertUnit(470)};
`
const StyledKycWarning = styled(Paragraph)`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: ${convertUnit(10)};
`
const StyledSSOButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(25)};
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`
const StyledLanguageToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${convertUnit(20)};
`

const StyledBackContainer = styled.div`
  cursor: pointer;
`

interface AuthEasySignupProps {
  isSSO: boolean
}

export default function AuthEasySignup({isSSO}: AuthEasySignupProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const {update, reset} = useDispatch()
  const {hasRegistered: oldDuplicateState} = useSelector(
    'easySignupDuplicateState',
  )
  const {hasRegistered} = useSelector('easySignupState')
  const {token, ssoMethod, suggestions} =
    useLocation('auth_easy_signup_sso').state || {}
  const stateType = useState<AuthCredentialType | undefined>('phone')
  const method = stateType[0]
  const [dobError, setDobError] = useState(true)
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const dialCode = stateCountry[0].dial_code
  type LegalModalType = 'tos' | 'privacy' | 'cookies'
  const [legalModal, setLegalModal] = useState<LegalModalType>()
  const form = useForm<AuthFormRegisterEasySignupData>({
    defaultValues: {
      email: '',
      phone: '',
      username: suggestions && suggestions.length ? suggestions[0] : '',
      dob: undefined,
    },
  })
  const [loading, setLoading] = useState(false)
  const {watch, setValue, errors, setError} = form
  const values = watch()
  const {email, phone, username, dob} = values
  const {lang} = useSelector('languageState')
  const [modal, setModal] = useState(false)
  const stateDuplicateRegister = useState(false)
  const stateCredentialConfirmModal = useState(false)

  const disabled = useMemo(
    () =>
      username === '' ||
      (method === 'email' && (email === '' || errors.email !== undefined)) ||
      (method === 'phone' && (phone === '' || errors.phone !== undefined)) ||
      errors.username !== undefined ||
      dob === undefined ||
      dobError,
    [
      dob,
      dobError,
      email,
      errors.email,
      errors.phone,
      errors.username,
      method,
      phone,
      username,
    ],
  )

  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )
  const offset = useMemo(() => new Date().getTimezoneOffset(), [])

  const date = useMemo(() => new Date(new Date().getTime() - offset * 60000), [
    offset,
  ])
  const today = date.toISOString().substring(0, 10)
  const [focus, setFocus] = useState(false)

  const handleCheckUsername = useCallback(() => {
    requestData('auth_check_username', {
      data: {username},
      onRequestReceived: () => {
        setLoading(false)
      },
      onRequestSuccess: (value) => {
        if (value.status === 200) {
          if (dob) {
            if (
              !validateMinimumAge(
                parseDate(dob.toString(), 'DD/MM/YYYY'),
                AUTH_USER_MIN_AGE,
              )
            ) {
              history.push('auth_easy_signup_underage', {
                username,
                date_of_birth: parseDate(dob.toString(), 'DD/MM/YYYY'),
                email,
                phone_code: dialCode,
                phone_number: phone
                  ? phone.replace(REGEX_LEADING_ZERO, '')
                  : '',
                method: isSSO ? ssoMethod : method,
                token,
              })
            } else {
              history.push('auth_easy_signup_selfie', {
                username,
                date_of_birth: parseDate(dob.toString(), 'DD/MM/YYYY'),
                email,
                phone_code: dialCode,
                phone_number: phone
                  ? phone.replace(REGEX_LEADING_ZERO, '')
                  : '',
                method: isSSO ? ssoMethod : method,
                token,
              })
            }
          }
        } else {
          setError('username', {
            type: 'unavailable',
            message: translate('auth:validationUsernameHasBeenTaken'),
          })
        }
      },
    })
  }, [
    dialCode,
    dob,
    email,
    history,
    isSSO,
    method,
    phone,
    setError,
    ssoMethod,
    token,
    translate,
    username,
  ])

  const handleRenderKycUnsupportedWarning = useMemo(
    () =>
      isIOS &&
      !isSafari && (
        <StyledKycWarning color="danger_5" fontWeight="medium">
          {translate('giftShop:kycWarningUnsupported')}
        </StyledKycWarning>
      ),
    [translate],
  )

  const handleRenderWaitingModal = useMemo(
    () => (
      <AuthRegisterWaitingModal
        visible={modal}
        toggleModal={() => setModal(false)}
      />
    ),
    [modal],
  )

  const handleCheckCredential = useCallback(() => {
    requestData(method === 'email' ? 'auth_check_email' : 'auth_check_phone', {
      data:
        method === 'email'
          ? {email}
          : {
              phone_code: dialCode,
              phone_number: phone.replace(REGEX_LEADING_ZERO, ''),
            },
      onRequestSuccess: (value) => {
        if (value.status === 200) {
          handleCheckUsername()
        } else {
          setLoading(false)
          if (value.status === 409) {
            setError(method === 'email' ? 'email' : 'phone', {
              type: 'unavailable',
              message: translate(
                method === 'email'
                  ? 'auth:validationEmailHasBeenTaken'
                  : 'auth:validationPhoneHasBeenTaken',
              ),
            })
          } else if (value.status === 503) {
            setModal(true)
          }
        }
      },
    })
  }, [dialCode, email, handleCheckUsername, method, phone, setError, translate])

  const handleEasySignup = useCallback(() => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    if (dob) {
      setLoading(true)
      isSSO ? handleCheckUsername() : handleCheckCredential()
    }
  }, [dob, handleCheckCredential, handleCheckUsername, isSSO, translate])

  const handleCheckDuplicateRegister = useCallback(() => {
    if (hasRegistered) {
      stateDuplicateRegister[1](true)
    } else {
      isSSO ? handleEasySignup() : stateCredentialConfirmModal[1](true)
    }
  }, [
    isSSO,
    handleEasySignup,
    hasRegistered,
    stateDuplicateRegister,
    stateCredentialConfirmModal,
  ])

  const handleRenderTabTitle = useCallback(
    (key: AuthCredentialType) =>
      translate(
        key === 'phone' ? 'global:whatsapp' : 'auth:registerIdentifierTabEmail',
      ),
    [translate],
  )

  const handleRenderTabItem = useCallback(
    (key: AuthCredentialType) =>
      key === 'phone' ? (
        <InputPhone
          name="phone"
          loadData={handleLoadDataPhoneDummy}
          stateSelect={stateCountry}
          form={form}
          formRules={VALIDATION_AUTH_PHONE}
          allowedCharacters={REGEX_PHONE_NUMBER}
          emptyMessage={(search) =>
            translate('auth:registerIdentifierSearchCountryEmpty', {
              search,
            })
          }
          label={translate('global:whatsapp')}
          placeholder={translate(
            'auth:registerIdentifierInputPhonePlaceholder',
          )}
          maxLength={13}
          searchPlaceholder={translate('global:search')}
          autoComplete={false}
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="delete"
          inputDescription={translate('auth:registerEasySignupSendLink', {
            context: method,
          })}
        />
      ) : (
        <Input
          name="email"
          form={form}
          formRules={VALIDATION_AUTH_EMAIL}
          label={translate('auth:registerIdentifierInputEmailLabel')}
          placeholder={translate(
            'auth:registerIdentifierInputEmailPlaceholder',
          )}
          type="email"
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="delete"
          inputDescription={translate('auth:registerEasySignupSendLink', {
            context: method,
          })}
        />
      ),
    [handleLoadDataPhoneDummy, stateCountry, form, translate, method],
  )

  const handleRenderTabView = useMemo(
    () => (
      <StyledTabContainer>
        <TabView
          containerStyle={{marginTop: convertUnit(16)}}
          routes={['phone', 'email']}
          stateSelect={stateType}
          renderTitle={handleRenderTabTitle}
          renderItem={handleRenderTabItem}
        />
      </StyledTabContainer>
    ),
    [stateType, handleRenderTabTitle, handleRenderTabItem],
  )

  const handleClose = useCallback(() => {
    setLegalModal(undefined)
  }, [])

  const handleRenderLegalModal = useMemo(() => {
    let src: string | undefined = ''
    switch (legalModal) {
      case 'cookies':
        src = getAuthCookie()
        break
      case 'privacy':
        src = getAuthPrivacy()
        break
      case 'tos':
        src = getAuthToS()
        break
      default:
        return ''
    }
    return `${src}&lang=${lang}`
  }, [lang, legalModal])

  const handleAgreementText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<tos>.*?<\/tos>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<tos>|<\/tos>/g, ''),
        onClick: () => setLegalModal('tos'),
      },
      {
        pattern: /<privacy>.*?<\/privacy>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<privacy>|<\/privacy>/g, ''),
        onClick: () => setLegalModal('privacy'),
      },
      {
        pattern: /<cookie>.*?<\/cookie>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<cookie>|<\/cookie>/g, ''),
        onClick: () => setLegalModal('cookies'),
      },
    ],
    [theme.primary_3],
  )

  const handleCheckDob = useCallback(() => {
    if (values.dob) {
      const value = values.dob
      const dobStr = value.toString()
      const dobDate = new Date(value)
      const dobYear = dobStr.substring(0, 4)
      const intDobYear = parseInt(dobYear, 10)

      if ((!focus && dobDate > date) || dobStr.length > 10) {
        setValue('dob', today)
      }

      if (
        (!dobYear.startsWith('0') && intDobYear < AUTH_DOB_MIN_YEAR) ||
        (!focus && dobYear.startsWith('0'))
      ) {
        setValue('dob', `${AUTH_DOB_MIN_YEAR}${dobStr.substring(4, 10)}`)
      }

      if (!focus && intDobYear > new Date().getFullYear()) {
        setValue('dob', today)
      }
      return dob === undefined || dobYear.startsWith('0')
    }
    return true
  }, [date, dob, focus, setValue, today, values.dob])

  useEffect(() => {
    setDobError(handleCheckDob())
    if (REGEX_USERNAME_CONTAINS_FOTOYU.test(username.toLowerCase())) {
      setError('username', {
        type: 'unavailable',
        message: translate('auth:validationUsernameContainsFotoyu'),
      })
    }
  }, [handleCheckDob, setError, translate, username])

  // keep userHasRegisteredState after redux key update
  useDidMount(() => {
    if (oldDuplicateState) {
      update('easySignupState', {hasRegistered: oldDuplicateState})
      reset('easySignupDuplicateState')
    }
  })

  return (
    <>
      {handleRenderWaitingModal}
      <TemplateAuthEasySignupDuplicateModal
        stateVisible={stateDuplicateRegister}
        onContinue={() =>
          isSSO ? handleEasySignup() : stateCredentialConfirmModal[1](true)
        }
      />
      <TemplateAuthEasySignupCredentialConfirmModal
        stateModal={stateCredentialConfirmModal}
        credentialType={method}
        onAgree={handleEasySignup}
        credential={
          method === 'email'
            ? email
            : `+${dialCode} ${
                phone
                  ? formatPhoneNumber(phone.replace(REGEX_LEADING_ZERO, ''))
                  : ''
              }`
        }
      />
      <StyledModal visible={legalModal !== undefined}>
        <StyledIcon onClick={handleClose}>
          <Icon type="close" size={14} color={'white_1'} />
        </StyledIcon>
        <StyledIframe src={handleRenderLegalModal} title="Legal" />
      </StyledModal>
      <StyledLanguageToggleContainer>
        <StyledBackContainer>
          <Icon type="back" color="primary_5" onClick={history.goBack} />
        </StyledBackContainer>
        {isSSO ? <></> : <LanguageToggle />}
      </StyledLanguageToggleContainer>
      <StyledHeader fontSize="xl" fontWeight="bold">
        {translate('global:register')}
      </StyledHeader>
      <StyledSectionLogin>
        <Paragraph fontSize="m" color="gray_3">
          {translate('auth:registerIdentifierAlreadyHaveAnAccount')}
        </Paragraph>
        <Link to="auth_login">
          <StyledLogin
            fontSize="m"
            fontWeight="medium"
            className="pressable"
            color="primary_5">
            {translate('global:login')}
          </StyledLogin>
        </Link>
      </StyledSectionLogin>
      {handleRenderKycUnsupportedWarning}
      {isSSO ? <></> : handleRenderTabView}
      <Input
        name="username"
        form={form}
        autoComplete={false}
        formRules={VALIDATION_AUTH_USERNAME}
        label={translate('global:username')}
        placeholder={translate(
          'auth:registerUsernameInputPlaceholderEasySignup',
        )}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="delete"
        maxLength={AUTH_USERNAME_MAX_LENGTH}
        allowedCharacters={REGEX_ALLOWED_USERNAME}
        onChangeText={() => {
          setValue('username', username.toLowerCase().replace(REGEX_EMOJI, ''))
        }}
      />
      <Input
        name="dob"
        type="date"
        form={form}
        defaultValue={today}
        label={translate('global:dateOfBirth')}
        labelDescriptionDirection="column"
        labelDescriptionWeight="regular"
        labelDescriptionColor="gray_7"
        labelDescription={translate('auth:registerDoBDescription', {
          age: AUTH_USER_MIN_AGE,
        })}
        placeholder={translate('auth:registerDateOfBirthInputPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        min={`${AUTH_DOB_MIN_YEAR}-01-01`}
        max={today}
      />
      <StyledAgreement color="gray_5" fontSize="xs">
        <ParsedText parse={handleAgreementText}>
          {translate('auth:registerTermsOfService', {
            context: isSSO || method === 'email' ? 'email' : 'whatsapp',
          })}
        </ParsedText>
      </StyledAgreement>
      <StyledButton
        disabled={disabled}
        isLoading={loading}
        iconType="face-scan"
        iconColor={disabled ? 'gray_3' : 'white_1'}
        label={translate('auth:registerTakeSelfie')}
        onClick={handleCheckDuplicateRegister}
      />
      {isSSO ? (
        <></>
      ) : (
        <>
          <StyledSSOButtonContainer>
            <AuthLoginGoogle
              label={translate('auth:registerGoogleButtonLabel')}
            />
            <AuthLoginApple
              label={translate('auth:registerAppleButtonLabel')}
            />
          </StyledSSOButtonContainer>
        </>
      )}
    </>
  )
}
