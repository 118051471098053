import React from 'react'
import convertUnit from 'lib/unit'
import {useTheme} from 'themes'
import {Avatar} from '../Avatar'
import {AvatarHighlightProps} from './AvatarHighlightProps'

export default function AvatarHighlight({
  padding = convertUnit(2),
  ...props
}: AvatarHighlightProps) {
  const theme = useTheme()

  return (
    <Avatar
      {...props}
      size={convertUnit(40)}
      containerStyle={{
        padding,
        background: `linear-gradient(135deg, ${theme.primary_4}, ${theme.primary_5})`,
      }}
    />
  )
}
