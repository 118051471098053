import React, {useCallback, useMemo} from 'react'
import Dropzone, {DropzoneState} from 'react-dropzone'
import styled from 'styled-components'
import {getAndSupplyFileMime} from 'utils'
import {GIFT_SHOP_UPLOAD_TYPE} from 'consts'
import convertUnit from 'lib/unit'
import {DragDropProps} from './DragDropProps'
import {Paragraph} from '../Paragraph'
import {Icon} from '../Icon'

interface StyledContainerProps {
  isDragActive: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, isDragActive}) => ({
    color: isDragActive ? theme.gray_5 : theme.gray_3,
  })}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  transition: border 0.1s ease-out;
  line-height: 1.5;
  outline: none;
`

const StyledLabel = styled(Paragraph)`
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const StyledDesc = styled(Paragraph)`
  margin-bottom: ${convertUnit(5)};
  transition: all 0.1s ease-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function DragDrop({
  accept = GIFT_SHOP_UPLOAD_TYPE,
  header,
  description,
  icon,
  validator,
  subdescription,
  ...props
}: DragDropProps) {
  const validatorFunction = useMemo(
    () =>
      validator ??
      ((file: File) => {
        const acceptedFiles = typeof accept === 'string' ? [accept] : accept
        const type = getAndSupplyFileMime(acceptedFiles, file)
        if (acceptedFiles.includes(type)) return null
        return {
          code: 'invalid-format',
          message: 'Invalid format',
        }
      }),
    [accept, validator],
  )

  const handleRenderChildren = useCallback(
    ({isDragActive, getRootProps, getInputProps}: DropzoneState) => (
      <StyledContainer {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {icon && <Icon type={icon} color="primary_5" size={34} />}{' '}
        {typeof header === 'string' ? (
          <StyledLabel fontSize="xl" fontWeight="bold" color="primary_5">
            {header}
          </StyledLabel>
        ) : (
          header
        )}
        {!isDragActive && typeof description === 'string' && (
          <StyledDesc fontSize="m" fontWeight="medium" color="gray_5">
            {description}
          </StyledDesc>
        )}
        {!isDragActive && typeof description === 'object' && description}
        {!isDragActive && typeof subdescription === 'string' && (
          <StyledDesc fontSize="s" color="gray_5">
            {subdescription}
          </StyledDesc>
        )}
        {!isDragActive && typeof subdescription === 'object' && subdescription}
      </StyledContainer>
    ),
    [description, header, icon, subdescription],
  )

  return (
    <Dropzone {...props} validator={validatorFunction}>
      {handleRenderChildren}
    </Dropzone>
  )
}
