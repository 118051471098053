import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUnwishlistModalProps} from './GiftShopTemplateUnwishlistModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${convertUnit(20)};
  text-align: center;
`

const StyledItem = styled.div`
  margin-bottom: ${convertUnit(12)};
  text-align: center;
`

const StyledTitleContainer = styled(StyledItem)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(60)};
  height: ${convertUnit(60)};
`

const StyledButton = styled(Button)`
  width: 100%;
  height: ${convertUnit(42)};
`

const StyledConfirmButton = styled(StyledButton)`
  margin: ${convertUnit(20)} 0;
`

export default function GiftShopTemplateUnwishlistModal({
  visible,
  onConfirm,
  toggleModal,
}: GiftShopTemplateUnwishlistModalProps) {
  const {translate} = useTranslation()
  const roboyuImg = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <StyledModal visible={visible}>
      <StyledTitleContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:unwishlistModalTitle')}
        </Paragraph>
        <StyledImage src={roboyuImg} alt={roboyuImg} />
      </StyledTitleContainer>
      <StyledItem>
        <Paragraph fontSize="l">
          {translate('giftShop:unwishlistModalDesc')}
        </Paragraph>
      </StyledItem>

      <Paragraph fontSize="l">
        {translate('giftShop:unwishlistModalQuestion')}
      </Paragraph>

      <StyledConfirmButton
        label={translate('giftShop:unwishlistModalConfirmText')}
        onClick={onConfirm}
      />
      <StyledButton
        label={translate('global:cancel')}
        onClick={toggleModal}
        backgroundColor="white_3"
        color="primary_5"
      />
    </StyledModal>
  )
}
