import React, {useCallback, useMemo, useRef, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {
  PAYMENT_CURRENCY_ID_DEFAULT,
  PAYMENT_CURRENCY_NAME_DEFAULT,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopLocationModal,
  GiftShopTemplateUploadForm,
  GiftShopTemplateZeroPriceModal,
  GiftShopUploadContentItemVideoImage,
} from 'pages'
import {GiftShopUploadFormData} from 'types'
import {requestData} from 'services'
import {
  convertSearchTreeItemToTag,
  convertTagToSearchTreeItem,
  getContentURL,
  getGiftshopLocationName,
} from 'utils'
import {Button, ConfirmationModal, Icon, ModalLoading} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateDetailContentEditVideoProps} from './GiftShopTemplateDetailContentEditVideoProps'

const StyledEditContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_2};
  padding: ${convertUnit(25)} 0;
  z-index: 1;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${convertUnit(20)};
  height: 100%;
  background-color: ${({theme}) => theme.white_2};
  overflow-y: scroll;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  cursor: pointer;
  width: 100%;
  justify-content: flex-end;
`

const StyledSaveButton = styled(Button)`
  padding: 0 ${convertUnit(52)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    margin-right: ${convertUnit(25)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-right: ${convertUnit(0)};
  }
`

const StyledTrashIconContainer = styled.div`
  background-color: ${({theme}) => theme.white_2};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-left: ${convertUnit(25)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-left: ${convertUnit(0)};
  }
`

const StyledConfirmationModal = styled(ConfirmationModal)`
  width: ${convertUnit(275)};
`

export default function GiftShopTemplateDetailContentEditVideo({
  onDeleteCreation,
  stateData,
  stateEdit,
}: GiftShopTemplateDetailContentEditVideoProps) {
  const {translate} = useTranslation()
  const form = useRef<GiftShopUploadFormData>()
  const [currentMap, setCurrentMap] = useState<
    UseFormMethods<GiftShopUploadFormData>
  >()
  const setData = stateData[1]
  const [edit, setEdit] = stateEdit
  const priceState = useState(0)
  const userPrice = priceState[0]
  const [modal, setModal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState<'delete' | 'zero-price'>()

  const handleSave = useCallback(async () => {
    if (edit && form.current) {
      setLoading(true)
      const {
        description,
        price,
        date,
        time,
        tags,
        currencyId,
        location,
        peopleTag,
      } = form.current

      const tagNicknames = {}
      if (peopleTag) {
        peopleTag.forEach((item) => {
          tagNicknames[item.nickname] = item.id
        })
      }

      if (location !== '') {
        const dateTime = new Date(
          date.concat('T').concat(time).concat(':00.000+07:00'),
        ).toISOString()
        requestData('giftshop_edit_content', {
          actionType: 'execute',
          useDefaultMessage: true,
          data: {
            content_id: edit.content_id,
            currency_id: currencyId,
            nickname: tagNicknames,
            description,
            location,
            original_at: dateTime,
            price: +price,
            tag_ids: tags.map((tag) => tag.tag_id),
            title: edit.title,
          },
          onRequestReceived: () => setLoading(false),
          onRequestSuccess: ({status, data: {result}}) => {
            if (status === 200) {
              setEdit(undefined)
              setData((previous) =>
                previous.map((item) =>
                  item.content_id === edit.content_id
                    ? {
                        ...item,
                        description,
                        tags: tags.map((tag) =>
                          convertSearchTreeItemToTag(tag),
                        ),
                        location: JSON.parse(location),
                        price: price === '0' ? 0 : +userPrice,
                        original_price: +price,
                        original_at: dateTime,
                        nickname: peopleTag
                          .map((val) => val.nickname)
                          .join(','),
                        tag_nicknames: [
                          ...peopleTag,
                          ...(result?.tag_nicknames || []),
                        ].filter((tag) => tag.id !== null),
                      }
                    : item,
                ),
              )
            }
          },
        })
      }
    }
  }, [edit, setData, setEdit, userPrice])

  const handleToggleConfirmModal = useCallback(
    () => setConfirmModal(undefined),
    [],
  )

  const handleCheckZeroPrice = useCallback(() => {
    if (form.current?.price === '0') {
      setLoading(false)
      setConfirmModal('zero-price')
      return
    }
    handleSave()
  }, [handleSave])

  const handleRenderContent = useMemo(
    () =>
      edit ? (
        <GiftShopUploadContentItemVideoImage
          isEdit
          videoHeight={edit.resolution.height}
          videoSize={edit.size}
          videoSrc={getContentURL({url: edit.video_url, type: edit.type})}
          videoTitle={edit.title}
          videoWidth={edit.resolution.width}
        />
      ) : (
        <></>
      ),
    [edit],
  )

  const handleRenderForm = useMemo(
    () =>
      edit &&
      typeof edit.original_at === 'string' && (
        <GiftShopTemplateUploadForm
          defaultValues={{
            id: edit.content_id,
            peopleTag: edit.tag_nicknames,
            title: edit.title,
            tags: edit.tags.map((tag) => convertTagToSearchTreeItem(tag)),
            description: edit.description,
            price: edit.original_price.toString(),
            currencyId: edit.currency?.id ?? PAYMENT_CURRENCY_ID_DEFAULT,
            currencyName: edit.currency?.name ?? PAYMENT_CURRENCY_NAME_DEFAULT,
            location: getGiftshopLocationName({
              latitude: edit.location?.latitude.toString(),
              longitude: edit.location?.longitude.toString(),
              name: edit.location?.name,
            }),
            originalAt: edit.original_at,
            size: edit.size,
            width: 0,
            height: 0,
          }}
          priceState={priceState}
          showDeleteButton={false}
          onAddLocation={setCurrentMap}
          onFormUpdate={(values) => {
            form.current = values
          }}
          uploadType="video"
          toggleModal={() => setModal(true)}
        />
      ),
    [edit, priceState],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledTrashIconContainer onClick={() => setConfirmModal('delete')}>
          <Icon type="trash" size={convertUnit(20)} color="gray_3" />
        </StyledTrashIconContainer>
        <StyledSaveButton
          label={translate('global:saveChanges')}
          onClick={() => {
            setLoading(true)
            handleCheckZeroPrice()
          }}
        />
      </StyledButtonsContainer>
    ),
    [handleCheckZeroPrice, translate],
  )

  const handleRenderLocation = useMemo(
    () => (
      <GiftShopLocationModal
        mapForm={currentMap}
        visible={modal}
        showField="giftshop"
        toggleModal={() => setModal((prev) => !prev)}
        onSave={() => setCurrentMap(undefined)}
      />
    ),
    [currentMap, modal],
  )

  const handleRenderDeleteConfirmationModal = useMemo(
    () => (
      <StyledConfirmationModal
        visible
        title={translate('giftShop:uploadDeleteContent')}
        text={translate('giftShop:contentDetailEditDeleteDescription')}
        onConfirm={() => onDeleteCreation && onDeleteCreation()}
        toggleModal={handleToggleConfirmModal}
        onBackdropPress={handleToggleConfirmModal}
      />
    ),
    [handleToggleConfirmModal, onDeleteCreation, translate],
  )

  const handleRenderZeroPriceModal = useMemo(
    () => (
      <GiftShopTemplateZeroPriceModal
        visible
        onConfirm={handleSave}
        toggleModal={handleToggleConfirmModal}
      />
    ),
    [handleSave, handleToggleConfirmModal],
  )

  const handleRenderModalLoading = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  const handleRenderConfirmModal = useMemo(() => {
    switch (confirmModal) {
      case 'delete':
        return handleRenderDeleteConfirmationModal
      case 'zero-price':
        return handleRenderZeroPriceModal
    }
    return <></>
  }, [
    confirmModal,
    handleRenderDeleteConfirmationModal,
    handleRenderZeroPriceModal,
  ])

  return edit ? (
    <>
      {handleRenderLocation}
      {handleRenderModalLoading}
      {handleRenderConfirmModal}
      <StyledEditContainer>
        <StyledContentContainer>
          <StyledContainer>
            {handleRenderContent}
            <StyledFormContainer>
              {handleRenderForm}
              {handleRenderButtons}
            </StyledFormContainer>
          </StyledContainer>
        </StyledContentContainer>
      </StyledEditContainer>
    </>
  ) : null
}
