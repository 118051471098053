import {
  ReduxActionBase,
  ReduxReducerState,
  ReduxStateKey,
  REDUX_STORE,
} from 'lib/redux'

export function reduxUpdateDispatcher<K extends ReduxStateKey>(
  key: K,
  payload: Partial<ReduxReducerState[K]>,
) {
  // @ts-ignore
  REDUX_STORE.dispatch({
    key,
    payload,
    type: 'update',
  } as ReduxActionBase<K>)
}

export function reduxResetDispatcher<K extends ReduxStateKey>(key: K) {
  // @ts-ignore
  REDUX_STORE.dispatch({key, type: 'reset'} as ReduxActionBase<K>)
}
