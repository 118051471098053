import React from 'react'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootEmpty} from '../../../root'

export default function GiftShopErrorNotFound() {
  const {translate} = useTranslation()
  const roboImg = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <GiftShopRootEmpty
      image={roboImg}
      imageAlt={roboImg}
      title={translate('giftShop:pageNotFoundTitle')}
      message={translate('giftShop:pageNotFoundDescription')}
    />
  )
}
