import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {
  ListMasonryPaginationButtonPage,
  ListMasonryPaginationGapMarker,
  ListMasonryPaginationButtonControls,
  Icon,
} from 'common/components'
import {
  LIST_MASONRY_PAGINATION_BUTTON_GAP,
  WINDOW_MODE_TABLET_WIDTH,
  LIST_MASONRY_PAGINATION_BUTTON_GAP_MOBILE,
} from 'consts'
import convertUnit from 'lib/unit'
import {ListPaginationModeType} from 'types'
import {useWindowMode} from 'windows'
import {getBorder} from 'utils'
import {ForkygramFootprintPaginationProps} from './ForkygramFootprintPaginationProps'

const StyledPaginationContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  place-self: flex-end;
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.gray_8)};
  gap: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP)};
  padding: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    border-top: ${({theme}) => getBorder(1, 'solid', theme.gray_8)};
    gap: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_GAP_MOBILE)};
    order: 1;
  }
`
export default function ForkygramFootprintPagination({
  statePage,
  pageCount,
  onPageChange,
}: ForkygramFootprintPaginationProps) {
  const mode = useWindowMode()
  const [page, setPage] = statePage

  const paginationCalcs = useMemo(() => {
    const compactMaxPages = mode !== 'mobile' ? 9 : 7
    const pageDiff = mode !== 'mobile' ? 2 : 1
    const middleMinThreshold = compactMaxPages - (pageDiff + 1)
    const middleMaxThreshold = pageCount - (compactMaxPages - 4)
    const paginationMode: ListPaginationModeType = (() => {
      if (pageCount <= compactMaxPages) return 'compact'
      if (page < middleMinThreshold) return 'start'
      if (middleMaxThreshold - (mode !== 'mobile' ? 0 : 1) >= page)
        return 'middle'

      return 'end'
    })()

    return {
      compactMaxPages,
      pageDiff,
      middleMinThreshold,
      middleMaxThreshold,
      paginationMode,
    }
  }, [mode, page, pageCount])

  const {
    compactMaxPages,
    middleMaxThreshold,
    pageDiff,
    paginationMode,
  } = paginationCalcs

  const safePage = useCallback(
    (p: number) => {
      let value = p
      if (value < 1) value = 1
      if (value > pageCount) value = pageCount
      return value
    },
    [pageCount],
  )

  const handleMovePage = useCallback(
    (newPage: number) => {
      setPage(safePage(newPage))
      onPageChange && onPageChange()
    },
    [onPageChange, safePage, setPage],
  )

  const handleRenderPaginationPageButtons = useMemo(() => {
    switch (paginationMode) {
      case 'compact':
        return Array.from({length: pageCount}).map((_, idx) => (
          <ListMasonryPaginationButtonPage
            darkMode
            page={page}
            setPage={handleMovePage}
            destination={idx + 1}
          />
        ))
      case 'start':
        return (
          <>
            {Array.from({length: compactMaxPages - 2}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                darkMode
                page={page}
                setPage={handleMovePage}
                destination={idx + 1}
              />
            ))}
            <ListMasonryPaginationGapMarker darkMode />
            <ListMasonryPaginationButtonPage
              darkMode
              page={page}
              setPage={handleMovePage}
              destination={pageCount}
            />
          </>
        )
      case 'end':
        return (
          <>
            <ListMasonryPaginationButtonPage
              darkMode
              page={page}
              setPage={handleMovePage}
              destination={1}
            />
            <ListMasonryPaginationGapMarker />
            {Array.from({length: compactMaxPages - 2}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                darkMode
                page={page}
                setPage={handleMovePage}
                destination={idx + middleMaxThreshold - 1}
              />
            ))}
          </>
        )
      case 'middle':
        return (
          <>
            <ListMasonryPaginationButtonPage
              darkMode
              page={page}
              setPage={handleMovePage}
              destination={1}
            />
            <ListMasonryPaginationGapMarker />
            {Array.from({length: compactMaxPages - 4}).map((_, idx) => (
              <ListMasonryPaginationButtonPage
                darkMode
                page={page}
                setPage={handleMovePage}
                destination={idx + (page - pageDiff)}
              />
            ))}
            <ListMasonryPaginationGapMarker />
            <ListMasonryPaginationButtonPage
              darkMode
              page={page}
              setPage={handleMovePage}
              destination={pageCount}
            />
          </>
        )
    }
  }, [
    compactMaxPages,
    handleMovePage,
    middleMaxThreshold,
    page,
    pageCount,
    pageDiff,
    paginationMode,
  ])

  const handleRenderPaginationMenu = useMemo(
    () =>
      pageCount > 0 ? (
        <StyledPaginationContainer>
          <ListMasonryPaginationButtonControls
            darkMode
            onClick={() => handleMovePage(page - 5)}>
            <Icon
              type="double-arrow-left"
              color="white_1"
              size={convertUnit(mode === 'website' ? 16 : 12)}
            />
          </ListMasonryPaginationButtonControls>
          <ListMasonryPaginationButtonControls
            darkMode
            onClick={() => handleMovePage(page - 1)}>
            <Icon
              type="chevron-left"
              color="white_1"
              size={convertUnit(mode === 'website' ? 16 : 12)}
            />
          </ListMasonryPaginationButtonControls>
          {handleRenderPaginationPageButtons}
          <ListMasonryPaginationButtonControls
            darkMode
            onClick={() => handleMovePage(page + 1)}>
            <Icon
              type="chevron-right"
              color="white_1"
              size={convertUnit(mode === 'website' ? 16 : 12)}
            />
          </ListMasonryPaginationButtonControls>
          <ListMasonryPaginationButtonControls
            darkMode
            onClick={() => handleMovePage(page + 5)}>
            <Icon
              type="double-arrow-right"
              color="white_1"
              size={convertUnit(mode === 'website' ? 16 : 12)}
            />
          </ListMasonryPaginationButtonControls>
        </StyledPaginationContainer>
      ) : (
        <></>
      ),
    [handleMovePage, handleRenderPaginationPageButtons, mode, page, pageCount],
  )

  return handleRenderPaginationMenu
}
