import React from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS,
  GIFT_SHOP_CREATION_BADGE_ICONS,
} from 'consts'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {Icon, Paragraph} from 'common/components'
import {GiftShopTemplateContentBadgeProps} from './GiftShopTemplateContentBadgeProps'

interface StyledBadgeContainerProps {
  backgroundColor: ThemeColor
}

const StyledBadgeContainer = styled.div<StyledBadgeContainerProps>`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(6)};
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(8)};
`

const StyledLeftIconContainer = styled.div`
  margin-right: ${convertUnit(6)};
`

export default function GiftShopTemplateContentDetailBadge({
  type,
  isDeletable,
  badgeStyle,
}: GiftShopTemplateContentBadgeProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()

  return (
    <StyledBadgeContainer
      style={badgeStyle}
      backgroundColor={
        type
          ? GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS[type]
          : GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS.unwishlist
      }>
      {(type === 'resend' ||
        type === 'sold' ||
        type === 'resendpology' ||
        isDeletable) && (
        <StyledLeftIconContainer>
          <Icon
            type={
              type
                ? GIFT_SHOP_CREATION_BADGE_ICONS[type]
                : GIFT_SHOP_CREATION_BADGE_ICONS.unwishlist
            }
            size={convertUnit(16)}
            color="white_1"
          />
        </StyledLeftIconContainer>
      )}
      <Paragraph
        fontSize={mode === 'website' ? 'm' : 's'}
        fontWeight="medium"
        color="white_1">
        {type
          ? translate('giftShop:creationBadge', {context: type})
          : translate('giftShop:creationBadge_unwishlist')}
      </Paragraph>
    </StyledBadgeContainer>
  )
}
