import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {IconType} from 'types'
import convertUnit from 'lib/unit'
import {RatingProps} from './RatingProps'
import {Icon} from '../Icon'
import {Paragraph} from '../Paragraph'

const StyledRatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(6)};
  align-items: center;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(5)};
  align-items: center;
`

const StyledRatingStarContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export default function Rating({
  value = 0,
  iconColor = 'success_5',
  iconSize = 16,
  maxValue = 5,
  valueColor = 'gray_6',
  ratingCount = 0,
  countColor = 'gray_5',
}: RatingProps) {
  const handleIconType = useCallback((diff: number): IconType => {
    if (diff >= 1) return 'star-solid'
    if (diff > 0 && diff < 1) return 'half-star'
    return 'star-ol'
  }, [])

  const handleRenderStar = useMemo(
    () => (
      <StyledRatingStarContainer>
        {[...Array(maxValue)].map((_, idx) => {
          const diff = Math.round(value * 2) / 2 - idx
          return (
            <Icon
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              type={handleIconType(diff)}
              color={iconColor}
              size={iconSize}
            />
          )
        })}
      </StyledRatingStarContainer>
    ),
    [handleIconType, iconColor, iconSize, maxValue, value],
  )

  const handleRenderText = useMemo(
    () => (
      <Paragraph fontSize="s" fontWeight="medium" color={valueColor}>
        {value.toFixed(1)}
      </Paragraph>
    ),
    [value, valueColor],
  )

  const handleRenderCount = useMemo(
    () => (
      <Paragraph fontSize="s" color={countColor}>
        {`(${ratingCount})`}
      </Paragraph>
    ),
    [countColor, ratingCount],
  )

  return value > 0 ? (
    <StyledRatingContainer>
      {handleRenderStar}
      <StyledTextContainer>
        {handleRenderText}
        {handleRenderCount}
      </StyledTextContainer>
    </StyledRatingContainer>
  ) : (
    <></>
  )
}
