import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {v4 as UUID} from 'uuid'
import {ButtonIcon, Image, Input, Paragraph} from 'common/components'
import {
  FirebaseMessageRoomContentData,
  FirebaseMessageRoomItem,
  MessageSendMessageResponse,
  ObjectState,
} from 'types'
import {useHistory} from 'utils'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  isClickable: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(25)};
  padding-top: ${convertUnit(10)};
  padding-bottom: ${convertUnit(10)};
`

const StyledContentContainer = styled.div<StyledContainerProps>`
${({isClickable}) =>
  isClickable && {
    ':hover': {cursor: 'pointer'},
  }}
  background-color: ${({theme}) => theme.white_3};
  padding: ${convertUnit(12)} ${convertUnit(20)};
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  align-items: center;
  }
`

const StyledImage = styled(Image)`
  width: ${convertUnit(64)};
  height: ${convertUnit(64)};
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: ${convertUnit(4)};
`

const StyledContentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export interface MessageRoomInputProps {
  participantId: string
  roomId: string
  stateTempMessages: ObjectState<ReadonlyArray<FirebaseMessageRoomItem<'chat'>>>
  content?: FirebaseMessageRoomContentData
  onSendMessage(
    message: string,
    roomId: string,
    memberId: string,
    contentId?: string,
    invoiceId?: string,
  ): Promise<MessageSendMessageResponse | null>
  isContentClickable?: boolean
}

export default function GiftShopMessageRoomInput({
  participantId,
  roomId,
  stateTempMessages,
  isContentClickable = false,
  content,
  onSendMessage,
}: MessageRoomInputProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateMessage = useState('')
  const {id: userId} = useSelector('user')!
  const [message, setMessage] = stateMessage
  const setTempMessages = stateTempMessages[1]
  const [contentData, setContentData] = useState<
    FirebaseMessageRoomContentData | undefined
  >(content)

  const handleSendMessage = useCallback(async () => {
    const id = UUID()
    setContentData(undefined)
    const payload: FirebaseMessageRoomItem<'chat'> = {
      id,
      created_at: new Date().toISOString(),
      payload: {message},
      seen: false,
      from_local: true,
      sender: userId,
      type: 'chat',
    }

    setMessage('')
    setTempMessages((previous) => [...previous, payload])

    const response = await onSendMessage(
      message,
      roomId,
      participantId,
      contentData?.contentIds[0],
      contentData?.invoiceId,
    )

    if (response) {
      setTempMessages((previous) => [
        ...previous.filter((item) => !item.from_local || item.id !== id),
        {...payload, id: response.message_id, from_local: false},
      ])
    }
  }, [
    message,
    userId,
    setMessage,
    setTempMessages,
    onSendMessage,
    roomId,
    participantId,
    contentData,
  ])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (message.trim() !== '') {
          handleSendMessage()
        }
      }
    },
    [handleSendMessage, message],
  )

  const handleOnClickContent = useCallback(() => {
    history.push('giftshop_transaction_detail', {
      invoice_id: contentData?.invoiceId,
    })
  }, [contentData?.invoiceId, history])

  const handleRenderContent = useMemo(
    () =>
      contentData ? (
        <StyledContentContainer
          isClickable={isContentClickable}
          onClick={isContentClickable ? handleOnClickContent : undefined}>
          <StyledImage src={contentData.url} alt={contentData.title} />
          <StyledContentInfo>
            <Paragraph fontSize="m" fontWeight="bold">
              {contentData.title}{' '}
            </Paragraph>
            {contentData.contentIds ? (
              contentData.contentIds.length > 1 && (
                <Paragraph fontWeight="medium" color="gray_5">
                  {translate(
                    'messages:notificationResendApprovalAdditionalContent',
                    {
                      total: contentData.contentIds.length - 1,
                    },
                  )}
                </Paragraph>
              )
            ) : (
              <></>
            )}
            <Paragraph>
              {translate('messages:notificationResendApprovalInvoiceId', {
                invoice_id: contentData.invoiceId,
              })}
            </Paragraph>
          </StyledContentInfo>
        </StyledContentContainer>
      ) : (
        <></>
      ),
    [contentData, handleOnClickContent, isContentClickable, translate],
  )

  return (
    <>
      {handleRenderContent}
      <StyledContainer>
        <Input
          value={message}
          placeholder={translate('messages:inputPlaceholder')}
          containerStyle={{flex: 1, display: 'flex', marginRight: 16}}
          onChangeText={setMessage}
          onKeyPress={handleKeyPress}
        />
        <ButtonIcon
          iconType="send"
          hasShadow={false}
          color="white_1"
          backgroundColor={message ? 'primary_5' : 'gray_3'}
          backgroundHoverColor={message ? 'primary_5' : 'gray_3'}
          style={{
            cursor: message ? 'pointer' : 'default',
            maxWidth: convertUnit(42),
            maxHeight: convertUnit(42),
          }}
          onClick={handleSendMessage}
        />
      </StyledContainer>
    </>
  )
}
