import { API_LEGAL_BLOG } from "consts";
import { ServiceMap } from "types";
import { getAPIBaseUrl } from "utils";
import { ServiceParamLegal } from "./ServiceParamLegal";

export const SERVICE_MAP_LEGAL : ServiceMap<ServiceParamLegal> = {
    legal_get_blogs: {
        method: 'GET',
        url: getAPIBaseUrl() + API_LEGAL_BLOG
    }
}