import React, {CSSProperties, useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {ThemeColor} from 'themes'
import {maskPhone} from 'utils'
import {Modal, Paragraph, Icon, Button} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateAuthAccessResendOtpModalProps} from './TemplateAuthAccessResendOtpModalProps'

const StyledSelectionModalContainer = styled.div<React.CSSProperties>`
  width: ${({width = convertUnit(300)}) => width};
  padding: ${convertUnit(25)} ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
  flex: 1;
  align-self: center;
`

const StyledSelectionContent = styled.div<{
  borderColor: ThemeColor
}>`
  width: 100%;
  box-sizing: content-box;
  cursor: pointer;
  flex: 1;
  border-radius: ${convertUnit(8)};
  margin: ${convertUnit(10)} 0;
  padding: ${convertUnit(10)};
  display: flex;
  background-color: ${({theme}) => theme.white_2};
  border-width: ${convertUnit(2)};
  border-style: solid;
  border-color: ${({theme, borderColor}) => theme[borderColor]};
`

const StyledSectionIcon = styled.div<CSSProperties>`
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: ${({justifyContent}) => justifyContent};
`

const StyledSectionDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 5;
`

const StyledButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: ${convertUnit(20)};
  width: 100%;
`

const StyledIcon = styled(Icon)`
  margin-right: ${convertUnit(15)};
`

export default function TemplateAuthAccessResendOtpModal({
  onCancel,
  onConfirm,
  userInfo,
  stateSelectedMethod,
  isVisible,
}: TemplateAuthAccessResendOtpModalProps) {
  const [
    selectedMethod,
    setSelectedMethod,
  ] = stateSelectedMethod

  const phoneMask = useMemo(() => {
    if (userInfo.phoneCode) {
      return maskPhone(userInfo.phoneCode, userInfo.phoneNumber)
    }
    return undefined
  }, [userInfo.phoneCode, userInfo.phoneNumber])

  return (
    <Modal visible={isVisible}>
      <StyledSelectionModalContainer>
        <StyledTitle fontWeight="bold" fontSize="l">
          {translate('giftShop:selectVerificationMethod')}
        </StyledTitle>

        {(
          <StyledSelectionContent
            borderColor={selectedMethod === 'whatsapp' ? 'primary_5' : 'white_2'}
            onClick={() => setSelectedMethod('whatsapp')}>
            <StyledSectionIcon justifyContent="start">
              <StyledIcon type="whatsapp-ol" color="primary_5" size={50} />
            </StyledSectionIcon>
            <StyledSectionDescription>
              <Paragraph fontSize="m" fontWeight="bold">
                {translate('giftShop:whatsappTo')}
              </Paragraph>
              <Paragraph fontSize="s" color="gray_3">
                {phoneMask}
              </Paragraph>
            </StyledSectionDescription>
            <StyledSectionIcon justifyContent="end">
              <Icon
                type="check"
                color={selectedMethod === 'whatsapp' ? 'primary_5' : 'white_2'}
                size={25}
              />
            </StyledSectionIcon>
          </StyledSelectionContent>
        )}
        {(
          <StyledSelectionContent
            borderColor={selectedMethod === 'phone' ? 'primary_5' : 'white_2'}
            onClick={() => {
              setSelectedMethod('phone')
            }}>
            <StyledSectionIcon justifyContent="start">
              <StyledIcon type="message-ol" color="primary_5" size={50} />
            </StyledSectionIcon>
            <StyledSectionDescription>
              <Paragraph fontSize="m" fontWeight="bold">
                {translate('giftShop:textTo')}
              </Paragraph>
              <Paragraph fontSize="s" color="gray_3">
                {phoneMask}
              </Paragraph>
            </StyledSectionDescription>
            <StyledSectionIcon justifyContent="end">
              <Icon
                type="check"
                color={selectedMethod === 'phone' ? 'primary_5' : 'white_2'}
                size={25}
              />
            </StyledSectionIcon>
          </StyledSelectionContent>
        )}

        <StyledButtonContainer>
          <Button
            label={translate('global:cancel')}
            width={convertUnit(125)}
            backgroundHoverColor="white_2"
            backgroundColor="white_2"
            color="primary_5"
            onClick={onCancel}
          />
          <Button
            backgroundColor="primary_5"
            disabled={!selectedMethod}
            color="white_1"
            label={translate('global:select')}
            width={convertUnit(125)}
            onClick={() => onConfirm(selectedMethod)}
          />
        </StyledButtonContainer>
      </StyledSelectionModalContainer>
    </Modal>
  )
}
