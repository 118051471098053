import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  BRAND_LETTER_SPACE,
  BRAND_SIZE,
  GIFT_SHOP_HEADER_TITLE_WIDTH,
} from 'consts'
import {Link, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  width: ${convertUnit(GIFT_SHOP_HEADER_TITLE_WIDTH)};
  height: 100%;
  align-items: center;
  justify-content: center;
`

const StyledBrand = styled(Paragraph)`
  flex: 1;
  letter-spacing: ${convertUnit(BRAND_LETTER_SPACE)};
  text-align: center;
`

const StyledBrandDivider = styled.div`
  ${({theme}) => ({backgroundColor: theme.gray_3})}
  height: ${convertUnit(30)};
  width: ${convertUnit(3)};
`

export default function GiftShopRootBrand() {
  const {translate} = useTranslation()

  return (
    <Link to="giftshop_robopet">
      <StyledContainer>
        <StyledBrand fontSize={BRAND_SIZE} fontWeight="brand">
          {translate('global:fotoyu').toUpperCase()}
        </StyledBrand>
        <StyledBrandDivider />
      </StyledContainer>
    </Link>
  )
}
