import React, {useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {useTranslation} from 'i18n'
import {getFileSize, getVideoDuration, parseDate, useDidUpdate} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileResendVideoItemProps} from './GiftShopProfileResendVideoProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledVideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_3};
  position: relative;
`

const StyledHLS = styled(ReactHlsPlayer)`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 1;
`

const StyledVideo = styled.video`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 1;
`

const StyledVideoIconContainer = styled.div`
  position: absolute;
  align-self: center;
  z-index: 3;
  cursor: pointer;
`

const StyledNoSrc = styled(StyledContainer)`
  padding: ${convertUnit(16)};
  gap: ${convertUnit(4)};
  text-align: center;
`

const StyledTitleContainer = styled(StyledContainer)`
  text-align: center;
  gap: ${convertUnit(2)};
  background-color: ${({theme}) => theme.white_2};
  padding: ${convertUnit(12)};
`

export default function GiftShopProfileResendVideoItem({
  date,
  src,
  title,
  duration,
  size,
  isNewContent = false,
}: GiftShopProfileResendVideoItemProps) {
  const {translate} = useTranslation()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [paused, setPaused] = useState(true)

  const handleRenderPlayButton = useMemo(
    () => (
      <StyledVideoIconContainer
        onClick={() => {
          setPaused((prev) => !prev)
          paused ? videoRef.current?.play() : videoRef.current?.pause()
        }}>
        <Icon
          type={paused ? 'play' : 'pause'}
          size={convertUnit(40)}
          color="white_1"
        />
      </StyledVideoIconContainer>
    ),
    [paused],
  )

  const handleRenderPlaceholder = useMemo(
    () => (
      <StyledNoSrc>
        <Icon type="video-view" size={convertUnit(32)} color="gray_3" />
        <Paragraph fontSize="s" fontWeight="medium" color="gray_3">
          {translate('giftShop:videoResendPlaceholder')}
        </Paragraph>
      </StyledNoSrc>
    ),
    [translate],
  )

  const handleRenderStandardVideo = useMemo(
    () =>
      src ? (
        <>
          {handleRenderPlayButton}
          <StyledHLS
            playerRef={videoRef}
            src={src}
            onEnded={() => setPaused(true)}
          />
        </>
      ) : (
        <></>
      ),
    [handleRenderPlayButton, src],
  )

  const handleRenderHiresVideo = useMemo(
    () =>
      src && isNewContent ? (
        <>
          {handleRenderPlayButton}
          <StyledVideo
            autoPlay={false}
            ref={videoRef}
            onEnded={() => setPaused(true)}>
            <source src={src} type="video/mp4" />
          </StyledVideo>
        </>
      ) : (
        <></>
      ),
    [handleRenderPlayButton, isNewContent, src],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledVideoContainer>
        {src
          ? isNewContent
            ? handleRenderHiresVideo
            : handleRenderStandardVideo
          : handleRenderPlaceholder}
      </StyledVideoContainer>
    ),
    [
      handleRenderHiresVideo,
      handleRenderPlaceholder,
      handleRenderStandardVideo,
      isNewContent,
      src,
    ],
  )

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph
        fontSize="s"
        fontWeight="bold"
        color={title ? 'primary_5' : 'gray_5'}>
        {title || translate('giftShop:resendBatchNoFileName')}
      </Paragraph>
    ),
    [title, translate],
  )

  const handleRenderDurationSize = useMemo(
    () => (
      <Paragraph fontWeight="medium" color="gray_5">
        {duration && size
          ? `${getVideoDuration(duration, true)} • ${getFileSize(size)}`
          : `${translate('giftShop:videoResendNoDuration')} • ${translate(
              'giftShop:videoResendNoFileSize',
            )}`}
      </Paragraph>
    ),
    [duration, size, translate],
  )

  const handleRenderDate = useMemo(
    () => (
      <Paragraph fontSize="s" color="gray_4">
        {(date && parseDate(date, 'd MMMM, YYYY, hh:mm')) ||
          translate('giftShop:resendBatchNoDate')}
      </Paragraph>
    ),
    [date, translate],
  )

  useDidUpdate(() => {
    if (isNewContent) videoRef.current?.load()
  }, [src])

  return (
    <StyledContainer>
      {handleRenderContent}
      <StyledTitleContainer>
        {handleRenderTitle}
        {handleRenderDurationSize}
        {handleRenderDate}
      </StyledTitleContainer>
    </StyledContainer>
  )
}
