/**
 * @param  {String} prefix
 * @param  {Number} num
 * @return {String}
 */
const currency = (prefix: string, num: number = 0): string => {
  const res: string = num
    .toString() // to convert a number to string
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return `${prefix} ${res}`
}

export default currency
