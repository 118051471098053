import {lazy} from 'react'

const GiftShopLanguageSettingScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'LanguageSetting' */ './GiftShopLanguageSettingScreen'
    ),
)

const GiftShopRoboyuSettingScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'RoboyuSetting' */ './GiftShopRoboyuSettingScreen'
    ),
)

const GiftShopSettingScreen = lazy(
  () => import(/* webpackChunkName: 'Setting' */ './GiftShopSettingScreen'),
)

const GiftShopCompressSettingScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompressSetting' */ './GiftShopCompressSettingScreen'
    ),
)

const GiftShopManageAccountScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManageAccount' */ './GiftShopManageAccountScreen'
    ),
)

const GiftShopDeleteAccountSettingScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'DeleteAccount' */ './GiftShopDeleteAccountScreen'
    ),
)

const GiftShopStorageHistoryScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'StorageHistory' */ './GiftShopStorageHistoryScreen'
    ),
)

const GiftShopBlockedAccountSettingScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'BlockedAccount' */ './GiftShopBlockedAccountScreen'
    ),
)

const GIftShopSettingWholeSaleScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'Wholesale' */ './GIftShopSettingWholeSaleScreen'
    ),
)

const GIftShopSettingHostScreen = lazy(
  () =>
    import(/* webpackChunkName: 'SettingHost' */ './GiftShopSettingHostScreen'),
)

const GiftShopSettingShareScreen = lazy(
  () => import(/* webpackChunkName: 'Share' */ './GiftShopSettingShareScreen'),
)

export {
  GiftShopCompressSettingScreen,
  GiftShopLanguageSettingScreen,
  GiftShopRoboyuSettingScreen,
  GiftShopSettingScreen,
  GiftShopManageAccountScreen,
  GiftShopDeleteAccountSettingScreen,
  GiftShopStorageHistoryScreen,
  GiftShopBlockedAccountSettingScreen,
  GIftShopSettingWholeSaleScreen,
  GIftShopSettingHostScreen,
  GiftShopSettingShareScreen,
}
