import {useCallback, useMemo, useState} from 'react'
import {GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopUploadVideoSendToFaceFormData} from 'types'
import {showSnackbar, getAndSupplyFileMime} from 'utils'
import {handleGiftshopReadVideoFaceFile} from '../GiftshopFileHelper'

export function useGiftShopVideoFaceHandler() {
  const {translate} = useTranslation()
  const [
    data,
    setData,
  ] = useState<GiftShopUploadVideoSendToFaceFormData | null>()

  const handleLoadFile = useCallback(
    async (file: File) => {
      let {type} = file
      type = getAndSupplyFileMime(
        GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE,
        file,
      )

      if (!GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE.includes(type)) {
        showSnackbar(
          translate('giftShop:videoUploadSendToFaceErrorInvalidFormat'),
        )
        return
      }

      let image: GiftShopUploadVideoSendToFaceFormData | null = null
      try {
        image = await handleGiftshopReadVideoFaceFile(file)
      } catch (e) {
        image = null
      }
      setData(image)
      return image
    },
    [translate],
  )

  return useMemo(
    () => ({
      data,
      loadVideoFaceFile: handleLoadFile,
    }),
    [data, handleLoadFile],
  )
}
