import {lazy} from 'react'

const TreeTransactionHistoryScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionHistory' */ './TreeTransactionHistoryScreen'
    ),
)

export {TreeTransactionHistoryScreen}
