import {lazy} from 'react'

const AuthForgotPasswordPhoneScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthForgotPasswordPhone' */ './AuthForgotPasswordPhoneScreen'
    ),
)

const AuthForgotPasswordEmailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthForgotPasswordEmail' */ './AuthForgotPasswordEmailScreen'
    ),
)

const AuthForgotPasswordScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthForgotPassword' */ './AuthForgotPasswordScreen'
    ),
)

export * from './AuthForgotPasswordPhoneScreenParam'
export * from './AuthForgotPasswordEmailScreenParam'
export {
  AuthForgotPasswordPhoneScreen,
  AuthForgotPasswordEmailScreen,
  AuthForgotPasswordScreen,
}
