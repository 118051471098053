import React from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import animationData from 'assets/lottie/loading.json'
import {ModalLoadingProps} from './ModalLoadingProps'
import {Modal} from '../Modal'
import {Paragraph} from '../Paragraph'

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(25)};
  text-align: center;
`

export default function ModalLoading({
  progress,
  total,
  ...props
}: ModalLoadingProps) {
  const {translate} = useTranslation()

  return (
    <Modal {...props}>
      <Lottie animationData={animationData} />
      {progress ? (
        <StyledParagraph fontSize="m">
          {translate('giftShop:processImage', {
            count: progress + 1,
            total,
          })}
        </StyledParagraph>
      ) : (
        ''
      )}
    </Modal>
  )
}
