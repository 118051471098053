import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {TreePlantOptions} from 'types'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreePlantTypeContentItemProps} from './TreePlantTypeContentItemProps'

interface StyledContainerProps {
  isSelected: boolean
  color: ThemeColor
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, isSelected, color}) => ({
    backgroundColor: isSelected ? theme[color] : undefined,
  })}
  cursor: pointer;
  border-radius: ${convertUnit(16)};
  margin: ${convertUnit(8)};
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export default function TreePlantTypeContentItem({
  form,
  data,
}: TreePlantTypeContentItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {fotoTreeCategory} = watch()

  const isSelected = useMemo(
    () => fotoTreeCategory && fotoTreeCategory.id === data.id,
    [data.id, fotoTreeCategory],
  )

  const handleClick = useCallback(() => {
    setValue('fotoTreeCategory', data)
  }, [data, setValue])

  const handleContentDetails = useMemo<TreePlantOptions>(() => {
    switch (data.name) {
      case 'place':
        return {
          title: translate('global:places'),
          color: 'primary_5',
          icon: 'location',
        }
      case 'event':
        return {
          title: translate('global:events'),
          color: 'danger_5',
          icon: 'event',
        }
      case 'community':
        return {
          title: translate('global:communities'),
          color: 'success_5',
          icon: 'community',
        }
    }
  }, [data.name, translate])

  return (
    <StyledContainer
      isSelected={isSelected}
      color={handleContentDetails.color}
      onClick={handleClick}>
      <Icon
        color={isSelected ? 'white_1' : handleContentDetails.color}
        type={handleContentDetails.icon}
      />
      <Paragraph color={isSelected ? 'white_1' : handleContentDetails.color}>
        {handleContentDetails.title}
      </Paragraph>
    </StyledContainer>
  )
}
