import React, {useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {checkLaterThanToday, useDidUpdate} from 'utils'
import {Input} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormDateTimeProps} from './GiftShopTemplateUploadFormDateTimeProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

export default function GiftShopTemplateUploadFormDateTime({
  stateFileDate,
  stateFileTime,
  form,
}: GiftShopTemplateUploadFormDateTimeProps) {
  const {translate} = useTranslation()
  const [fileDate, setFileDate] = stateFileDate
  const [fileTime, setFileTime] = stateFileTime
  const {setValue} = form
  const timezoneOffset = useMemo(() => new Date().getTimezoneOffset() / 60, [])
  const today = useMemo(
    () =>
      new Date(
        new Date().setHours(new Date().getHours() - timezoneOffset),
      ).toISOString(),
    [timezoneOffset],
  )
  const todayDate = useMemo(() => today.substring(0, 10), [today])
  const todayTime = useMemo(() => today.substring(11, 16), [today])

  const fileOriginalAt = useMemo(
    () =>
      fileDate && fileTime
        ? new Date(
            fileDate.concat('T').concat(fileTime).concat(':00.000+07:00'),
          ).toISOString()
        : new Date(
            todayDate.concat('T').concat(todayTime).concat(':00.000+07:00'),
          ).toISOString(),
    [fileDate, fileTime, todayDate, todayTime],
  )

  useDidUpdate(() => {
    setValue('originalAt', fileOriginalAt)
  }, [fileOriginalAt, setValue])

  useDidUpdate(() => {
    const compare = checkLaterThanToday(fileDate)
    if (compare) {
      setFileDate(todayDate)
      setValue('date', todayDate)
    }
  }, [fileDate, todayDate, setValue])

  return (
    <StyledContainer>
      <Input
        name="date"
        label={translate('giftShop:uploadInputDateLabel')}
        defaultValue={fileDate}
        onChangeText={(text) => {
          if (text.length < 10 || text.length > 10) {
            setFileDate(todayDate)
            setValue('date', todayDate)
          } else {
            setFileDate(text)
            setValue('date', text)
          }
        }}
        form={form}
        autoComplete={false}
        type="date"
        containerStyle={{
          marginRight: convertUnit(25),
          marginTop: convertUnit(25),
          width: '100%',
        }}
      />
      <Input
        name="time"
        defaultValue={fileTime}
        onChangeText={(text) => {
          if (text.length < 5 || text.length > 5) {
            setFileTime(todayTime)
            setValue('time', todayTime)
          } else {
            setFileTime(text)
            setValue('time', text)
          }
        }}
        label={translate('giftShop:uploadInputTimeLabel')}
        form={form}
        autoComplete={false}
        type="time"
        containerStyle={{
          marginTop: convertUnit(25),
          width: '100%',
        }}
      />
    </StyledContainer>
  )
}
