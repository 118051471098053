import {useCallback, useMemo} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  GiftShopContentType,
  GiftshopUploadContentData,
  ImageExtensionType,
  VideoExtensionType,
} from 'types'
import {showSnackbar} from 'utils/components'
import {handleUploadSingleFileToWasabi} from 'utils/files'

export function useGiftShopResendRevisionHandler() {
  const {translate} = useTranslation()

  const handleGetLink = useCallback(
    async (
      image: GiftshopUploadContentData,
      contentId: string,
      contentType: GiftShopContentType,
    ) => {
      const {height, width, size, extension, duration} = image
      const ImgExtension = extension as ImageExtensionType
      const videExtension = extension as VideoExtensionType

      const response =
        contentType === 'photo'
          ? await requestData('giftshop_upload_resend_content_link', {
              data: {
                content: {
                  size,
                  height,
                  width,
                  extension: ImgExtension,
                },
                content_id: contentId,
                series: {
                  total_size: size,
                },
              },
            })
          : await requestData('giftshop_post_resend_video_link', {
              data: {
                content_id: contentId,
                video: {
                  duration: duration ?? 0,
                  extension: videExtension,
                  height,
                  size,
                  width,
                },
              },
            })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result.resend_link
        : null
    },
    [],
  )

  const handlePublishToWasabi = useCallback(
    async (
      image: GiftshopUploadContentData,
      link: string,
      contentType: GiftShopContentType,
    ) => {
      const {file, width, height, extension} = image
      const mime =
        contentType === 'photo' ? `image/${extension}` : `video/${extension}`
      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperty = useCallback(
    async (
      image: GiftshopUploadContentData,
      link: string,
      contentId: string,
      collectorMemberId: string,
      invoiceId: string,
      contentType: GiftShopContentType,
    ) => {
      const {height, width, size, exif, duration: vidDuration} = image
      const duration = vidDuration ? vidDuration * 1000 : undefined
      const customExif = {duration}

      const response =
        contentType === 'photo'
          ? await requestData('giftshop_post_resend_revision', {
              data: {
                collector_member_id: collectorMemberId,
                content: link,
                content_id: contentId,
                exif: JSON.stringify(exif),
                height,
                invoice_id: invoiceId,
                size,
                width,
              },
              onRequestSuccess: ({status}) => {
                status === 422 &&
                  showSnackbar(
                    translate(
                      'giftShop:resendRevisionErrorAlreadyRevisedMessage',
                    ),
                  )
              },
            })
          : await requestData('gitfshop_post_resend_revision_video', {
              data: {
                collector_member_id: collectorMemberId,
                content: link,
                content_id: contentId,
                exif: JSON.stringify(customExif),
                height,
                invoice_id: invoiceId,

                size,
                width,
              },
              onRequestSuccess: ({status}) => {
                status === 422 &&
                  showSnackbar(
                    translate(
                      'giftShop:resendRevisionErrorAlreadyRevisedMessage',
                    ),
                  )
              },
            })
      return typeof response !== 'string' && response.status === 200
    },
    [translate],
  )

  const handleUploadResendRevision = useCallback(
    async (
      image: GiftshopUploadContentData,
      contentId: string,
      collectorMemberId: string,
      invoiceId: string,
      contentType: GiftShopContentType,
    ) => {
      const link = await handleGetLink(image, contentId, contentType)
      const isSuccess =
        !!link &&
        (await handlePublishToWasabi(image, link, contentType)) &&
        (await handlePublishProperty(
          image,
          link,
          contentId,
          collectorMemberId,
          invoiceId,
          contentType,
        ))

      return isSuccess
    },
    [handleGetLink, handlePublishToWasabi, handlePublishProperty],
  )

  return useMemo(
    () => ({
      uploadRevision: handleUploadResendRevision,
    }),
    [handleUploadResendRevision],
  )
}
