import {lazy} from 'react'

const TreeTransactionWithdrawScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionWithdraw' */ './TreeTransactionWithdrawScreen'
    ),
)

export {TreeTransactionWithdrawScreen}
