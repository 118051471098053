import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getBorder, useHistory} from 'utils'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateSearchCreatorItemProps} from './GiftShopTemplateSearchCreatorItemProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    ':hover': {
      color: theme.white_1,
      backgroundColor: theme.primary_1,
    },
  })}
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(12)};
  gap: ${convertUnit(12)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(32)};
  aspect-ratio: 3/4;
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
  border-radius: ${convertUnit(6)};
  box-sizing: border-box;
  object-fit: contain;
  background-color: ${({theme}) => theme.white_1};
`

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  align-items: center;
  justify-content: flex-start;
`

export default function GiftShopTemplateSearchCreatorItem({
  item,
}: GiftShopTemplateSearchCreatorItemProps) {
  const {translate} = useTranslation()
  const history = useHistory()

  const handleClickItem = useCallback(() => {
    history.pushQuery({
      path: 'giftshop_search_by_creator_creation_content',
      state: {
        photo: item.profile_url,
        bio: item.biodata,
        creatorRating: item.creator_rating,
        ratingCount: item.creator_rating_count,
      },
      queryObject: {
        creator_id: item.creator_id,
        username: item.username,
      },
    })
  }, [history, item])

  return (
    <StyledContainer onClick={handleClickItem}>
      <StyledImage src={item.profile_url} alt={item.username} />
      <div>
        <Paragraph fontSize="m" fontWeight="bold">
          {item.username}
        </Paragraph>
        <StyledIconContainer>
          <Icon type="fg" color="primary_5" size={12} />
          <Paragraph>{translate('giftShop:creator')}</Paragraph>
        </StyledIconContainer>
      </div>
    </StyledContainer>
  )
}
