import React, {useMemo} from 'react'
import {useDispatch} from 'lib/redux'
import {useTranslation} from 'i18n'
import {GiftShopFloatingButton} from 'pages'
import {useRefresh} from '../GiftshopRefreshHooks'

export function useRefreshExplore({
  lastUpdate,
  showButton = true,
  onRefresh,
}: {
  lastUpdate?: number
  showButton?: boolean
  onRefresh: () => void
}) {
  const {update} = useDispatch()
  const {translate} = useTranslation()

  const {handleRefresh, shouldRenderButton} = useRefresh({
    lastUpdate,
    onRefresh: () => {
      onRefresh()
      update('giftshopState', {
        exploreRefresher: new Date(),
      })
    },
  })

  const handleRenderButton = useMemo(
    () => (
      <GiftShopFloatingButton
        title={translate('giftShop:exploreFloatingButtonTitle')}
        subtitle={translate('giftShop:exploreFloatingButtonSubtitle')}
        handleRefresh={handleRefresh}
        shouldRenderButton={shouldRenderButton && showButton}
      />
    ),
    [handleRefresh, shouldRenderButton, showButton, translate],
  )

  return useMemo(
    () => ({
      handleRefresh,
      handleRenderButton,
      shouldRenderButton,
    }),
    [handleRefresh, handleRenderButton, shouldRenderButton],
  )
}
