import {ModalReprocessKycHandler} from 'types'

let handler: ModalReprocessKycHandler | undefined

export function setModalReprocessKycHandler(instance: ModalReprocessKycHandler) {
  handler = instance
}

export function showModalReprocessKyc() {
  handler && handler.showModalReprocessKyc()
}
