import React from 'react'
import {useTranslation} from 'i18n'
import {convertFileSizeFromByte} from 'utils'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationCreatorStorageAdditionrProps {
  payload: FirebaseMessageRoomItemPayload['notification_creator_storage_addition']
}

export default function GiftShopMessageRoomContentItemNotificationCreatorStorageAddition({
  payload: {username, storage_addition_amount},
}: GiftShopMessageRoomContentItemNotificationCreatorStorageAdditionrProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationStorageRequestSuccess', {
        username,
        storage_addition_amount: convertFileSizeFromByte(
          storage_addition_amount,
          'GB',
        ).toFixed(0),
      })}
    </Paragraph>
  )
}
