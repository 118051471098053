import React, {useCallback, useMemo, useState} from 'react'
import {LIST_MASONRY_PAGINATION_LOAD_LIMIT} from 'consts'
import {useSelector} from 'lib/redux'
import {useTranslation} from 'i18n'
import {GiftShopTemplateDetailContent, TemplateProfileEmptyTab} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {useHistory, useLocation, useToggle} from 'utils'
import {GiftShopProfileCreationDetailProps} from './GiftShopProfileCreationDetailProps'
import {GiftshopProfileModalYuser} from '../ModalYuser'

export default function GiftShopProfileCreationDetail({
  stateEdit,
}: GiftShopProfileCreationDetailProps) {
  const location = useLocation('giftshop_profile_creation_detail')
  const {
    data,
    selectedItemId,
    creationType: creationTypeState,
    limitContent,
  } = location.state
  const {type: creationType, date, page} = location.query
  const {translate} = useTranslation()
  const stateTotalCount = useState(-1)
  const setTotalCount = stateTotalCount[1]
  const history = useHistory()
  const user = useSelector('user')
  const stateMaxPage = useState(-1)
  const [maxPage, setMaxPage] = stateMaxPage
  const [initPagination, setInitPagination] = useState(false)

  const isPaginated = useMemo(
    () =>
      typeof date !== 'undefined' &&
      date !== '0000-00-00' &&
      typeof page !== 'undefined',
    [date, page],
  )

  const safePage = useMemo(
    () => page && (maxPage > -1 ? Math.max(Math.min(page, maxPage), 1) : page),
    [maxPage, page],
  )

  const handleLoadData = useCallback(
    async (newPage: number, limit: number) => {
      if (limitContent) return []

      const response = await requestData('giftshop_get_creation', {
        params: {
          page: newPage,
          limit,
          filter:
            creationType === creationTypeState
              ? creationType
              : creationTypeState,
          sort_by: 'created_at',
          sort_dir: 'desc',
          date: date === '0000-00-00' ? undefined : date,
        },
      })

      if (!initPagination) {
        await requestData('giftshop_get_creation_count', {
          useDefaultMessage: true,
          actionType: 'fetch',
          params: {
            date: date === '0000-00-00' ? undefined : date,
            filter: 'all,available',
          },
          onRequestSuccess: (resultCount) => {
            const resultData = resultCount.data.result
            const {
              total_data,
              total_available,
              total_resend = 0,
              total_resendpology = 0,
              total_sold = 0,
            } = resultData

            let value = 0

            switch (creationType) {
              case 'all':
                value = total_data
                break
              case 'available':
                value = total_available
                break
              case 'resend':
                value = total_resend
                break
              case 'resendpology':
                value = total_resendpology
                break
              case 'sold':
                value = total_sold
                break
            }

            setTotalCount(value)
            setMaxPage(Math.ceil(value / LIST_MASONRY_PAGINATION_LOAD_LIMIT))

            if (value === 0) {
              history.replace('giftshop_profile', {self: true}, user?.username)
            }

            setMaxPage(Math.ceil(value / LIST_MASONRY_PAGINATION_LOAD_LIMIT))

            setInitPagination(true)
          },
        })
      }

      if (typeof response !== 'string' && response.status === 200) {
        return response.data.result.data.map(
          (datum) =>
            ({
              ...datum,
              creator_name: user?.username,
              profile_url: user?.photo,
              creator_id: user?.id,
            } as GiftShopContentData),
        )
      }

      return []
    },
    [
      creationType,
      creationTypeState,
      date,
      history,
      initPagination,
      limitContent,
      setMaxPage,
      setTotalCount,
      user?.id,
      user?.photo,
      user?.username,
    ],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <TemplateProfileEmptyTab
        buttonLabel={translate('forkygram:postEmptyButton')}
        title={translate('giftShop:myCreationEmptyTitle')}
        description={translate('giftShop:myCreationEmptyDesc')}
        onClickButton={() => history.push('giftshop_upload', {})}
      />
    ),
    [history, translate],
  )

  const [yuserId, setYuserId] = useState<string[]>([])

  const {value: yuserModal, toggle: toggleYuserModal} = useToggle(false)

  const handleRenderModalYuser = useMemo(
    () => (
      <GiftshopProfileModalYuser
        visible={yuserModal}
        yuserMemberId={yuserId}
        toggleModal={toggleYuserModal}
      />
    ),
    [toggleYuserModal, yuserId, yuserModal],
  )

  const handleGetBuyerDetails = useCallback(
    (item: GiftShopContentData) => {
      requestData('giftshop_get_buyers', {
        params: {content_id: item.content_id},
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            if (
              result.length > 1 ||
              result[0].collector_member_id === user?.id
            ) {
              setYuserId(
                result.map(({collector_member_id}) => collector_member_id),
              )
              toggleYuserModal()
            } else {
              history.push('giftshop_message_room', {
                participantId: result[0].collector_member_id,
                selfRole: 'seller',
                roomType: 'giftshop',
              })
            }
          }
        },
      })
    },
    [history, toggleYuserModal, user?.id],
  )

  return (
    <>
      <GiftShopTemplateDetailContent
        showUnwishlistBadge
        showPurchaseStatusBadge
        isEditable
        darkBackgroundImage
        fromCreation
        showPreviewInfo={false}
        showPreviewBadge
        showPrice={false}
        showFoundConfirmed={creationType === 'available'}
        data={data}
        selectedItemId={selectedItemId}
        hideOverlayWhenExpand
        loadData={handleLoadData}
        onSendDM={(item) => handleGetBuyerDetails(item)}
        showReportButton={false}
        stateEdit={stateEdit}
        listEmptyElement={handleRenderEmpty}
        showFullOverlay={false}
        showOverlayPrice={false}
        fetchOnToggle
        stateCount={stateTotalCount}
        isPaginated={isPaginated}
        page={safePage}
        maxPage={maxPage}
        setMaxPage={setMaxPage}
        limitContent={limitContent}
      />
      {handleRenderModalYuser}
    </>
  )
}
