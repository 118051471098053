import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {
  Button,
  Icon,
  IconVerifiedBadge,
  Image,
  Modal,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopMessageCreatorRatingModalProps} from './GiftShopMessageCreatorRatingModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(20)};
  text-align: center;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 100%;
    border-radius: 0;
  }
`

const StyledCloseIconContainer = styled.div`
  align-self: end;
  cursor: pointer;
`

const StyledCreatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  justify-content: center;
`

const StyledCreatorPhoto = styled(Image)`
  width: ${convertUnit(124)};
  height: ${convertUnit(124)};
  object-fit: cover;
  border-radius: 50%;
`

const SyledCreatorUsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  justify-content: center;
  align-items: center;
`

const StyledRatingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(5)};
`

const StyledStarContainer = styled.div`
  cursor: pointer;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopMessageCreatorRatingModal({
  visible,
  username,
  photo,
  loadingSubmit,
  onSubmit,
  toggleModal,
}: GiftShopMessageCreatorRatingModalProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [rating, setRating] = useState(0)

  const handleRenderCloseIcon = useMemo(
    () => (
      <StyledCloseIconContainer
        onClick={() => {
          setRating(0)
          toggleModal()
        }}>
        <Icon type="close" size={convertUnit(20)} color="primary_5" />
      </StyledCloseIconContainer>
    ),
    [toggleModal],
  )

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate('messages:creatorRatingModalTitle')}
      </Paragraph>
    ),
    [translate],
  )

  const handleRenderCreatorInfo = useMemo(
    () => (
      <StyledCreatorContainer>
        <StyledCreatorPhoto src={photo} alt={username} />
        <SyledCreatorUsernameContainer>
          <Paragraph fontSize="l" fontWeight="medium">
            {username}
          </Paragraph>
          <IconVerifiedBadge height={convertUnit(24)} width={convertUnit(24)} />
        </SyledCreatorUsernameContainer>
      </StyledCreatorContainer>
    ),
    [photo, username],
  )

  const handleRenderRating = useMemo(
    () => (
      <StyledRatingsContainer>
        {[...Array(5)].map((_, idx) => (
          <StyledStarContainer onClick={() => setRating(idx + 1)}>
            <Icon
              key={`star-${idx + 1}`}
              type={idx < rating ? 'star-solid' : 'star-ol'}
              size={convertUnit(40)}
              color={idx < rating ? 'success_5' : 'gray_3'}
            />
          </StyledStarContainer>
        ))}
      </StyledRatingsContainer>
    ),
    [rating],
  )

  const handleRenderInfo = useMemo(
    () => (
      <Paragraph color="gray_5" style={{textAlign: 'center'}}>
        {translate('messages:creatorRatingModalInfo')}
      </Paragraph>
    ),
    [translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:submit')}
        disabled={rating === 0 || loadingSubmit}
        onClick={() => onSubmit(rating)}
        isLoading={loadingSubmit}
      />
    ),
    [loadingSubmit, onSubmit, rating, translate],
  )

  return (
    <StyledModal visible={visible} isHalfScreen={mode === 'mobile'}>
      {handleRenderCloseIcon}
      {handleRenderTitle}
      {handleRenderCreatorInfo}
      {handleRenderRating}
      {handleRenderInfo}
      {handleRenderButton}
    </StyledModal>
  )
}
