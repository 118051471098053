import React, {useCallback, useMemo, useState} from 'react'
import { Paragraph } from 'common/components'
import { useTranslation } from 'i18n'
import { useSelector } from 'lib/redux'
import { AuthRegisterTermOfCondition } from 'pages/auth'
import { FirebaseMessageRoomItemPayload } from 'types'

export interface GiftShopMessageRoomContentItemReminderCreatorActivationProps {
  payload: FirebaseMessageRoomItemPayload['reminder_creator_activation']
}

export default function GiftShopMessageRoomContentItemReminderCreatorActivation() {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!
  const toggleToS = useState(false)
  const setToggleToS = toggleToS[1]

  const handleToggleToS = useCallback(
    () => setToggleToS((previous) => !previous),
    [setToggleToS],
  )

  const handleRenderToS = useMemo(
    () => (
      <AuthRegisterTermOfCondition
        showModalState={toggleToS}
        handleAgree={handleToggleToS}
      />
    ),
    [toggleToS, handleToggleToS],
  )

  const handleRenderContent = useMemo(
    () => (
      <Paragraph fontSize="m" onClick={handleToggleToS}>
        {translate('messages:reminderCreatorActivation', {
          username,
        })}
      </Paragraph>
    ),
    [handleToggleToS, translate, username],
  )

  return (
    <>
      {handleRenderToS}
      {handleRenderContent}
    </>
  )
}
