import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'i18n'
import styled from 'styled-components'
import {requestData} from 'services'
import {useWindowMode} from 'windows'
import {showSnackbar} from 'utils'
import {
  AvatarHighlight,
  Button,
  ButtonIcon,
  Image,
  Paragraph,
  Link,
  PriceTag,
} from 'common/components'
import convertUnit from 'lib/unit'
import currency from 'lib/currency'
import {GiftShopExploreContentItemProps} from './GiftShopExploreContentItemProps'

interface StyledButtonLinkProps {
  marginRight?: string
}

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin-right: ${convertUnit(10)};
  margin-bottom: ${convertUnit(25)};
  border-radius: ${convertUnit(16)};
  border: ${convertUnit(1)} solid;
  border-color: ${({theme}) => theme.gray_1};
`

const StyledCardImageContainer = styled.div<React.CSSProperties>`
  position: relative;
  background-color: ${({theme}) => theme.gray_1};
  width: 100%;
  height: ${({height}) => height};
  text-align: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  max-width: 100%;
`

const StyledPriceContainer = styled.div`
  background-color: ${({theme}) => theme.white_2};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  position: absolute;
  border-radius: ${convertUnit(8)};
  left: ${convertUnit(25)};
  bottom: ${convertUnit(25)};
`

const StyledCardFooterContainer = styled.div`
  position: static;
  bottom: 0;
  background-color: ${({theme}) => theme.white_1};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`

const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledUsername = styled(Paragraph)`
  margin-left: ${convertUnit(16)};
`

const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledButtonIcon = styled(ButtonIcon)<StyledButtonLinkProps>`
  margin-right: ${({marginRight}) => marginRight};

  :last-child {
    margin-right: 0;
  }
`

export default function GiftShopExploreContentItem({
  itemIndex,
  contentId,
  imgSrc,
  creator,
  profileUrl,
  currencyCode,
  price,
  isCart,
  isWhishlist,
  onDelete,
  ...props
}: GiftShopExploreContentItemProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [inCart, setInCart] = useState(isCart)
  const [inWishlist, setInWishlist] = useState(isWhishlist)

  const handleFailedExecute = useCallback(() => {
    showSnackbar(translate('giftShop:snackbarFailedExecute'))
  }, [translate])

  const handleSetExplored = useCallback(() => {
    requestData('giftshop_delete_explore', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {content_ids: [contentId]},
      onRequestFailed: handleFailedExecute,
      onRequestSuccess: () => {
        onDelete(itemIndex)
        showSnackbar(
          translate('giftShop:snackbarSuccessExecute', {
            where: translate('giftShop:explore'),
            context: 'remove',
          }),
        )
      },
    })
  }, [contentId, itemIndex, onDelete, translate, handleFailedExecute])

  const handleToggleWishlist = useCallback(() => {
    if (inWishlist) {
      requestData('giftshop_delete_wishlist', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {content_ids: [contentId]},
        onRequestFailed: handleFailedExecute,
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setInWishlist(!inWishlist)
            showSnackbar(translate('giftShop:snackbarSuccessExecute'))
          }
        },
      })
    } else {
      requestData('giftshop_post_wishlist', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {content_ids: [contentId]},
        onRequestFailed: handleFailedExecute,
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setInWishlist(!inWishlist)
            showSnackbar(
              translate('giftShop:snackbarSuccessExecute', {
                where: translate('giftShop:wishlist'),
                context: 'add',
              }),
            )
          }
        },
      })
    }
  }, [contentId, inWishlist, translate, handleFailedExecute])

  const handleToggleCart = useCallback(() => {
    if (inCart) {
      requestData('giftshop_delete_cart', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {content_ids: [contentId]},
        onRequestFailed: handleFailedExecute,
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setInCart(!inCart)
            showSnackbar(
              translate('giftShop:snackbarSuccessExecute', {
                where: translate('global:cart'),
                context: 'remove',
              }),
            )
          }
        },
      })
    } else {
      requestData('giftshop_post_cart', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {content_ids: [contentId]},
        onRequestFailed: handleFailedExecute,
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setInCart(!inCart)
            showSnackbar(
              translate('giftShop:snackbarSuccessExecute', {
                where: translate('global:cart'),
                context: 'add',
              }),
            )
          }
        },
      })
    }
  }, [contentId, inCart, translate, handleFailedExecute])

  const handleRenderImageViewer = useMemo(
    () => (
      <StyledCardImageContainer
        height={
          mode === 'website'
            ? convertUnit(518)
            : mode === 'tablet'
            ? convertUnit(393)
            : convertUnit(197)
        }>
        <Link to="giftshop_explore_detail" param={{id: contentId}}>
          <StyledImage useLazyLoad src={imgSrc} alt={contentId} height="100%" />
        </Link>
        <StyledPriceContainer>
          <PriceTag
            price={currency(currencyCode, price)}
            iconSize={mode === 'mobile' ? convertUnit(14) : convertUnit(21)}
            fontSize={mode === 'tablet' ? 'l' : mode === 'mobile' ? 's' : 'xl'}
            iconMarginRight={
              mode === 'mobile' ? convertUnit(9) : convertUnit(17.5)
            }
          />
        </StyledPriceContainer>
      </StyledCardImageContainer>
    ),
    [contentId, currencyCode, imgSrc, mode, price],
  )

  const handleRenderUserInfo = useMemo(
    () => (
      <StyledUserContainer>
        <AvatarHighlight
          src={profileUrl}
          alt={creator}
          size={mode === 'mobile' ? convertUnit(24) : convertUnit(32)}
        />
        <StyledUsername
          fontSize={mode === 'website' ? 'l' : mode === 'tablet' ? 'm' : 's'}
          fontWeight="bold">
          {creator}
        </StyledUsername>
      </StyledUserContainer>
    ),
    [profileUrl, creator, mode],
  )

  const handleRenderAction = useMemo(
    () => (
      <StyledActionContainer>
        <StyledButtonIcon
          iconType="close"
          iconColor="danger_5"
          marginRight={mode !== 'website' ? convertUnit(15) : convertUnit(25)}
          onClick={handleSetExplored}
        />
        <StyledButtonIcon
          iconType="heart"
          iconColor={inWishlist ? 'primary_5' : 'gray_1'}
          marginRight={mode !== 'website' ? convertUnit(15) : convertUnit(25)}
          onClick={handleToggleWishlist}
        />
        <StyledButtonIcon
          iconType={inCart ? 'minus' : 'cart'}
          iconColor="primary_5"
          marginRight={mode !== 'website' ? convertUnit(15) : convertUnit(25)}
          onClick={handleToggleCart}
        />
      </StyledActionContainer>
    ),
    [
      handleSetExplored,
      handleToggleCart,
      handleToggleWishlist,
      inCart,
      inWishlist,
      mode,
    ],
  )

  return (
    <StyledCardContainer {...props}>
      {handleRenderImageViewer}
      <StyledCardFooterContainer>
        {handleRenderUserInfo}
        {handleRenderAction}
        <Link
          to="giftshop_explore_detail"
          param={{id: contentId}}
          hidden={mode === 'mobile'}>
          <Button
            label={translate('giftShop:aboutContent')}
            backgroundColor="info_5"
          />
        </Link>
      </StyledCardFooterContainer>
    </StyledCardContainer>
  )
}
