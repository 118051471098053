import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  Button,
  Chips,
  Icon,
  Input,
  ModalLoading,
  Paragraph,
} from 'common/components'
import {
  TREE_MAX_NAME_ALIASES,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {TreeAliasesData, TreeEditFotoTreeData} from 'types'
import {useDidMount, useHistory} from 'utils'
import {TreeEditTreeFormProps} from './TreeEditFormProps'

const StyledContainer = styled.div`
  width: ${convertUnit(561)};
  height: max-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: ${convertUnit(20)} auto;
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin: auto;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(20)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeFototreeAliasForm({
  stateTreeInfo,
  stateActiveContent,
}: TreeEditTreeFormProps) {
  const history = useHistory()
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [treeInfo, setTreeInfo] = stateTreeInfo
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)

  const form = useForm<{aliases: TreeAliasesData[]}>({
    defaultValues: {aliases: treeInfo.tree_aliases || []},
  })
  const {setValue, register, watch, handleSubmit} = form
  const {aliases} = watch()

  const tempAliases = useMemo(() => treeInfo.tree_aliases || [], [treeInfo])

  const inputDisabled = useMemo(() => aliases.length >= TREE_MAX_NAME_ALIASES, [
    aliases,
  ])

  const buttonDisabled = useMemo(
    () =>
      loading ||
      (aliases.length === tempAliases?.length &&
        aliases.every((alias) =>
          tempAliases?.some((tempAlias) => tempAlias.name === alias.name),
        )),
    [aliases, loading, tempAliases],
  )

  const handlePatchAliases = useCallback(() => {
    const transformAliases = {}
    if (aliases) {
      aliases.forEach((item) => {
        transformAliases[item.name] = item.id
      })
    }
    setLoading(true)
    requestData('tree_patch_fototree', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        aliases: transformAliases,
        tree_id: treeInfo.id,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        const data: TreeEditFotoTreeData = {
          ...treeInfo,
          tree_aliases: aliases.length ? aliases : null,
        }
        if (status === 200) {
          setTreeInfo(data)
          history.replace(
            'tree_fototree_edit',
            {
              treeId: treeInfo.id,
              data,
            },
            treeInfo.alias,
          )
          setActiveContent('profile')
        }
      },
    })
  }, [aliases, history, setActiveContent, setTreeInfo, treeInfo])

  const handleAddAlias = useCallback(() => {
    if (aliases.some((alias) => alias.name === input)) return
    const existingAliases = tempAliases?.find((alias) => alias.name === input)
    const newAliases = existingAliases
      ? [...aliases, existingAliases]
      : [...aliases, {id: '', name: input}]
    setValue('aliases', newAliases)
    setInput('')
  }, [aliases, input, setValue, tempAliases])

  const handleDeleteAlias = useCallback(
    (value: string) => {
      setValue(
        'aliases',
        aliases.filter((item) => item.name !== value),
      )
    },
    [aliases, setValue],
  )

  useDidMount(() => register('aliases'))

  return (
    <>
      <ModalLoading visible={loading} />
      <StyledContainer>
        <Input
          value={input}
          label={translate('tree:alias')}
          placeholder={translate('tree:aliasPlaceholder')}
          disabled={inputDisabled}
          rightIcon={
            input ? (
              <Icon type="plus" color="gray_6" size={convertUnit(16)} />
            ) : undefined
          }
          rightIconStyle={{cursor: 'pointer'}}
          containerStyle={{marginBottom: convertUnit(4)}}
          onRightIconClick={handleAddAlias}
          onChangeText={setInput}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !inputDisabled) handleAddAlias()
          }}
        />
        <Paragraph fontWeight="medium" color="gray_5">
          {translate('tree:aliasDescription')}
        </Paragraph>
        <Chips
          data={aliases}
          backgroundColor="primary_1"
          fontColor="primary_5"
          keyExtractor={(item) => item.id + item.name}
          labelExtractor={(item) => item.name}
          onDelete={(item) => handleDeleteAlias(item.name)}
        />
        <StyledButton
          label={translate('global:save')}
          disabled={buttonDisabled}
          isLoading={loading}
          onClick={handleSubmit(handlePatchAliases)}
        />
      </StyledContainer>
    </>
  )
}
