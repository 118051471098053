import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {setModalReprocessKycHandler} from 'utils'
import {ModalReprocessKycContext} from './ModalReprocessKycContext'
import ModalReprocessKycItem from './ModalReprocessKycItem'

interface ModalReprocessKycProviderProps {
  children: ReactNode
}
export default function ModalReprocessKycProvider({
  children,
}: ModalReprocessKycProviderProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleShowReprocessKycModal = useCallback(() => {
    setOpenModal(true)
  }, [])

  const handleHideReprocessKycModal = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleRenderModal = useMemo(() => {
    if (!openModal) {
      return
    }

    return <ModalReprocessKycItem onClickOk={handleHideReprocessKycModal} />
  }, [openModal, handleHideReprocessKycModal])

  const handler = useMemo(
    () => ({
      showModalReprocessKyc: handleShowReprocessKycModal,
    }),
    [handleShowReprocessKycModal],
  )

  useEffect(() => {
    setModalReprocessKycHandler(handler)
  }, [handler])

  return (
    <ModalReprocessKycContext.Provider value={handler}>
      {handleRenderModal}
      {children}
    </ModalReprocessKycContext.Provider>
  )
}
