import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopWholesaleTierData} from 'types'
import {getBorder, useDidMount} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateWholesaleDiscountModalProps} from './GiftShopTemplateWholesaleDiscountModalProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: calc(100% - ${convertUnit(40)});
  }
`

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: ${convertUnit(20)};
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledCreatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(8)};
`

const StyledCreatorPhoto = styled(Image)`
  max-width: ${convertUnit(24)};
  width: 100%;
  aspect-ratio: 1;
  object-fif: cover;
`

const StyledDiscountInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_2};
  border: ${({theme}) => getBorder(2, 'solid', theme.gray_1)};
  box-sizing: border-box;
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
`

const StyledDiscountInfoTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(2)};
`

const StyledDiscountInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledDiscountInfoItemLeft = styled(StyledDiscountInfoItem)`
  align-items: flex-end;
`

const StyledDiscountInfoItemRight = styled(StyledDiscountInfoItem)`
  align-items: flex-start;
`

const StyledSeparator = styled.div`
  height: 100%;
  align-self: stretch;
  width: ${convertUnit(2)};
  background-color: ${({theme}) => theme.gray_1};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateWholesaleDiscountModal({
  creatorData: {photo, username, memberId},
  visible,
  toggleModal,
}: GiftShopTemplateWholesaleDiscountModalProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [data, setData] = useState<GiftShopWholesaleTierData[]>([])

  const handleLoadWholesaleData = useCallback(() => {
    requestData('giftshop_get_creator_wholesale', {
      actionType: 'fetch',
      params: {member_id: memberId},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result.wholesale_tier)
      },
    })
  }, [memberId])

  const handleRenderCreatorPhoto = useMemo(
    () => <StyledCreatorPhoto src={photo} alt={username} />,
    [photo, username],
  )

  const handleRenderCreator = useMemo(
    () => (
      <StyledCreatorContainer>
        <Paragraph>{translate('global:by')}</Paragraph>
        {handleRenderCreatorPhoto}
        <Paragraph fontWeight="bold" color="primary_5">
          {username}
        </Paragraph>
      </StyledCreatorContainer>
    ),
    [handleRenderCreatorPhoto, translate, username],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:wholesaleModalTitle')}
        </Paragraph>
        {handleRenderCreator}
      </StyledHeaderContainer>
    ),
    [handleRenderCreator, translate],
  )

  const handleRenderAmountItem = useMemo(
    () =>
      data.map((item, idx) => {
        const max = idx < data.length - 1 && data[idx + 1].min_contents - 1
        const maxContent = item.min_contents < max ? ` - ${max}` : ''
        const contents =
          idx === data.length - 1
            ? `≥ ${item.min_contents}`
            : `${item.min_contents}${maxContent}`
        return (
          <Paragraph fontSize="m" color="gray_5" key={item.tier}>
            {translate('giftShop:wholesaleModalContentAmountItem', {contents})}
          </Paragraph>
        )
      }),
    [data, translate],
  )

  const handleRenderAmount = useMemo(
    () => (
      <StyledDiscountInfoItemLeft>
        <StyledDiscountInfoTitle fontSize="m" fontWeight="bold">
          {translate('giftShop:wholesaleModalContentAmount')}
        </StyledDiscountInfoTitle>
        {handleRenderAmountItem}
      </StyledDiscountInfoItemLeft>
    ),
    [handleRenderAmountItem, translate],
  )

  const handleRenderDiscountItem = useMemo(
    () =>
      data.map((item) => (
        <Paragraph fontSize="m" color="gray_5" key={item.tier}>
          {translate('giftShop:wholesaleModalAllDiscountedItem', {
            discount: item.discount,
          })}
        </Paragraph>
      )),
    [data, translate],
  )

  const handleRenderDiscount = useMemo(
    () => (
      <StyledDiscountInfoItemRight>
        <StyledDiscountInfoTitle fontSize="m" fontWeight="bold">
          {translate('giftShop:allDiscounted')}
        </StyledDiscountInfoTitle>
        {handleRenderDiscountItem}
      </StyledDiscountInfoItemRight>
    ),
    [handleRenderDiscountItem, translate],
  )

  const handleRenderDiscountInfo = useMemo(
    () => (
      <StyledDiscountInfoContainer>
        {handleRenderAmount}
        <StyledDiscountInfoItem>
          <StyledSeparator />
        </StyledDiscountInfoItem>
        {handleRenderDiscount}
      </StyledDiscountInfoContainer>
    ),
    [handleRenderAmount, handleRenderDiscount],
  )

  const handleRenderCloseButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:close')}
        backgroundColor="white_2"
        color="primary_5"
        onClick={toggleModal}
      />
    ),
    [toggleModal, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        {handleRenderHeader}
        {handleRenderDiscountInfo}
        {handleRenderCloseButton}
      </StyledContainer>
    ),
    [handleRenderCloseButton, handleRenderDiscountInfo, handleRenderHeader],
  )

  useDidMount(handleLoadWholesaleData)

  return (
    <StyledModal visible={visible} isHalfScreen={mode === 'mobile'}>
      {handleRenderContent}
    </StyledModal>
  )
}
