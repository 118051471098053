import React, {useCallback, useMemo} from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {ButtonIcon, Paragraph} from 'common/components'
import {getBorder, getFontFamily, showSnackbar} from 'utils'
import {useTheme} from 'themes'
import {useTranslation} from 'i18n'
import {TreeMapFootprintOverlayType} from 'types'
import {FototreeFootprintProps} from './FototreeFootprintProps'

interface StyledIconProps {
  disabled?: boolean
}

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertUnit(8)};
`

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.white_1};
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.white_4)};
  width: 100%;
  z-index: 5;
  top: 0;
  padding: ${convertUnit(6)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: auto;
`

const StyledIcon = styled(ButtonIcon)<StyledIconProps>`
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  width: ${convertUnit(42)};
  height: ${convertUnit(42)};
  border-radius: ${convertUnit(22)};
  background-color: ${({theme}) => theme.white_1};
`

export default function FototreeFootprintHeader({
  stateOverlay,
  stateLoading,
  data,
  onLoadData,
}: FototreeFootprintProps) {
  const theme = useTheme()
  const {translate} = useTranslation()
  const [overlay, setOverlay] = stateOverlay
  const loading = stateLoading[0]

  const handleClickFootprintList = useCallback(() => {
    if (!data.length) {
      showSnackbar(translate('tree:leafEmpty'))
    } else {
      const changeOverlay: TreeMapFootprintOverlayType =
        overlay === 'full' ? 'header' : 'full'
      setOverlay(changeOverlay)
    }
  }, [data.length, overlay, setOverlay, translate])

  const handleRenderIconBack = useMemo(
    () => (
      <StyledIcon
        hasShadow={false}
        iconType="back"
        iconColor="primary_5"
        onClick={() => {
          setOverlay('hidden')
          onLoadData()
        }}
      />
    ),
    [onLoadData, setOverlay],
  )

  const handleRenderSkeleton = useMemo(
    () => (
      <Skeleton
        inline
        width={30}
        height={12}
        baseColor={theme.gray_1}
        highlightColor={theme.white_4}
      />
    ),
    [theme.gray_1, theme.white_4],
  )

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <Paragraph fontSize="l" fontWeight="medium" lineHeight={31}>
          {translate('tree:footprints')}
        </Paragraph>
        <StyledSubtitleContainer>
          <Paragraph color="primary_5">
            {loading ? (
              handleRenderSkeleton
            ) : (
              <StyledBold>{data.length}</StyledBold>
            )}{' '}
            {translate('global:trees')}
          </Paragraph>
        </StyledSubtitleContainer>
      </StyledTitleContainer>
    ),
    [data.length, handleRenderSkeleton, loading, translate],
  )

  const handleRenderIconFootprintList = useMemo(
    () => (
      <StyledIcon
        hasShadow={false}
        disabled={loading}
        iconColor={loading ? 'gray_3' : 'primary_5'}
        iconType={overlay === 'full' ? 'map-marked' : 'file-name'}
        onClick={handleClickFootprintList}
      />
    ),
    [handleClickFootprintList, loading, overlay],
  )

  return (
    <StyledContainer>
      {handleRenderIconBack}
      {handleRenderTitle}
      {handleRenderIconFootprintList}
    </StyledContainer>
  )
}
