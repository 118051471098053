import React, {MouseEvent, useCallback, useState} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {InputIconProps} from './InputIconProps'
import {Icon} from '../Icon'

const StyledContainer = styled.div`
  box-sizing: border-box;
  padding: ${convertUnit(10)};
`

export default function InputIcon<TFieldValues>({
  component,
  baseType,
  stateType,
  inputRef,
  onClick,
  form,
  iconColor,
  ...props
}: InputIconProps<TFieldValues>) {
  const [type, setType] = stateType
  const value = inputRef.current?.value
  const setLastUpdate = useState<number>()[1]

  const handleDelete = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClick && onClick(event)
      inputRef.current && (inputRef.current.value = '')
      form?.trigger()
      setLastUpdate(new Date().getTime())
    },
    [form, inputRef, onClick, setLastUpdate],
  )

  const handleVisibility = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClick && onClick(event)
      setType((previous) =>
        previous === 'password'
          ? baseType === 'password'
            ? 'text'
            : baseType
          : 'password',
      )
    },
    [baseType, onClick, setType],
  )

  if (typeof component === 'string') {
    if (component === 'delete' && value) {
      return (
        <StyledContainer {...props} onClick={handleDelete}>
          <Icon size={16} type="close" color={iconColor} />
        </StyledContainer>
      )
    }

    if (component === 'visibility') {
      return (
        <StyledContainer {...props} onClick={handleVisibility}>
          <Icon
            size={16}
            type={type === 'password' ? 'hide' : 'show'}
            color={iconColor}
          />
        </StyledContainer>
      )
    }

    return null
  }

  return component ? (
    <StyledContainer {...props} onClick={onClick}>
      {component}
    </StyledContainer>
  ) : null
}
