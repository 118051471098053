import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import Lottie from 'lottie-react'
import {isSafari} from 'react-device-detect'
import {
  GiftShopKYCRecordStatus,
  GiftShopKYCShootStatus,
  GiftShopKYCUploadLinkType,
  GiftShopKYCUserUploadLinkParam,
  GiftShopKYCVerificationStatus,
  GiftShopKYCVideoType,
  GiftshopKYCUserUploadParam,
  ObjectState
} from 'types'
import {
  blobToFile,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  urlToBlob,
  useHistory,
} from 'utils'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useWindowMode} from 'windows'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import animationData from 'assets/lottie/activity_indicator.json'
import {GiftShopTemplatePermissionDenied} from '../../../template'
import GiftShopRobopetActivationSelfieOverlay from './GiftShopRobopetActivationSelfieOverlay'
import GiftShopRobopetActivationSelfieOverlayRecord from './GiftShopRobopetActivationSelfieOverlayRecord'
import GiftShopRobopetActivationSelfieReview from './GiftShopRobopetActivationSelfieReview'
import GiftShopRobopetActivationLanding from './GiftShopRobopetActivationLanding'

interface ProgressProps {
  progress: GiftShopKYCVerificationStatus
  mediaError: boolean
}

const StyledContainer = styled.div<ProgressProps>`
  background-color: ${({theme, progress, mediaError}) => 
    progress === 'confirmation' ||
    progress === 'landing' ||
    mediaError ? theme.white_1 : theme.black_2};
  display: flex;
  flex: 1;
  justify-content: center;
`
const StyledLottie = styled(Lottie)`
  height: ${convertUnit(40)};
  width: ${convertUnit(40)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: 100%;
`

interface GiftShopRobopetActivationSelfieProps {
  stateVerifStatus: ObjectState<GiftShopKYCVerificationStatus>
}
export default function GiftShopRobopetActivationSelfie({
  stateVerifStatus,
}: GiftShopRobopetActivationSelfieProps) {
  const history = useHistory()
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const webcamRef = useRef<any>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [recordedChunks, setRecordedChunks] = useState([])
  const frontRecordedChunks = useRef([])
  const sideRecordedChunks = useRef([])
  const [isRecording, setIsRecording] = useState(false)
  const [shootStatus, setShootStatus] = useState<GiftShopKYCShootStatus>('tutorial')
  const [verifStatus, setVerifStatus] = stateVerifStatus
  const [recordProgress, setRecordProgress] = useState<GiftShopKYCRecordStatus>('front')
  const [selfie, setSelfie] = useState('')
  const [modal, setModal] = useState<'progress' | 'satisfied'>()
  const [uploadProgress, setUploadProgress] = useState('')
  const videoConstraint = useMemo(() => ({
    aspectRatio: mode === 'mobile' ? 1.5 : 0.67,
    facingMode: 'user',
  }), [mode])
  const uploadSuccess = useRef(0)
  const video = useRef<Record<GiftShopKYCVideoType, string>>({
    front: '',
    side: '',
  })
  const link = useRef<GiftshopKYCUserUploadParam>({
    selfie_link: '',
    video_front_link: '',
    video_side_link: '',
  });

  const [mediaError, setMediaError] = useState(false)

  const handleDataAvailable = useCallback(
    ({data}) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    []
  )

  const handleRecordProgress = useCallback(() => {
    setShootStatus('video')
    setTimeout(() => {
      setIsRecording(true)
      mediaRecorderRef.current.start()
      setTimeout(() => {
        mediaRecorderRef.current.state === 'recording' && mediaRecorderRef.current.stop()
        setIsRecording(false)
      }, (1000))
    }, (1000))
  }, [])

  const handleRecord = useCallback(() => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? 'video/mp4' : 'video/webm'
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    handleRecordProgress()
  }, [handleDataAvailable, handleRecordProgress])

  const handleProcessRecord = useCallback((nextProgress: GiftShopKYCRecordStatus) => {
    if (mediaRecorderRef.current) {
      if (nextProgress === 'done') {
        setTimeout(() => {
          setRecordProgress('done')
          setVerifStatus('confirmation')
        }, 1000)
      } else {
        setTimeout(() => {
          setRecordProgress(nextProgress)
          setTimeout(() => {
            setIsRecording(true)
            mediaRecorderRef.current.state === 'inactive' && mediaRecorderRef.current.start()
            setTimeout(() => {
              mediaRecorderRef.current.state === 'recording' && mediaRecorderRef.current.stop()
              setIsRecording(false)
            }, (1000))
          }, (1000))
        }, 1000)
      }
    }
  }, [setVerifStatus])

  const onAudioEnd = useCallback(() => {
    if (mediaRecorderRef.current) {
      setIsRecording(false)
      mediaRecorderRef.current.state === 'recording' && mediaRecorderRef.current.stop()
      if (recordProgress === 'front') {
        handleProcessRecord('side')
      } else if (recordProgress === 'side') {
        handleProcessRecord('done')
      }
    }
  }, [handleProcessRecord, recordProgress])

  const handleTakeSelfie = useCallback(() => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    if (webcamRef.current) {
     const image = webcamRef.current.getScreenshot()
     setSelfie(image)
     handleRecord()
    }
  }, [handleRecord, translate])

  const handleRetake = useCallback(() => {
    setVerifStatus('shoot')
    setShootStatus('tutorial')
    setRecordProgress('front')
    setRecordedChunks([])
    frontRecordedChunks.current = []
    sideRecordedChunks.current = []
    setSelfie('')
  }, [setVerifStatus])

  const handleUploadLink = useCallback(
    async (
      type: GiftShopKYCUploadLinkType,
      data: GiftShopKYCUserUploadLinkParam,
      file: File,
      extension: string,
    ) => {
      const response = await requestData('giftshop_register_user_link', {
        data: {...data, type},
      })
      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        if (type === 'front') {
          link.current = {
            ...link.current,
            video_front_link: result.video_front_link,
          }
        } else if (type === 'side') {
          link.current = {
            ...link.current,
            video_side_link: result.video_side_link,
          }
        } else if (type === 'selfie') {
          link.current = {...link.current, selfie_link: result.selfie_link}
        }
        const isSuccess = await handleUploadSingleFileToWasabi(
          file,
          type === 'selfie'
            ? result.selfie_link
            : type === 'front'
            ? result.video_front_link
            : result.video_side_link,
          extension,
          {
            'Content-Type': extension,
          },
        )
        if (isSuccess) {
          uploadSuccess.current += 1
          setUploadProgress(
            translate('giftShop:kycUploading', {
              current: uploadSuccess.current,
              total: 3,
            }),
          )
        }
        return isSuccess
      }
      return false
    },
    [translate],
  )

  const handleUpload = useCallback(async () => {
    setUploadProgress(translate('giftShop:kycUploading_preparing'))
    setModal('progress')
    frontRecordedChunks.current = [recordedChunks[0]]
    sideRecordedChunks.current = [recordedChunks[1]]
    const blobSide = new Blob(sideRecordedChunks.current, {
      type: 'video/mp4',
    })
    const urlSide = URL.createObjectURL(blobSide)
    const blobFront = new Blob(frontRecordedChunks.current, {
      type: 'video/mp4',
    })
    const urlFront = URL.createObjectURL(blobFront)

    video.current.front = urlFront
    video.current.side =  urlSide

    const fileFront = new File([blobFront], 'video_front', {type: blobFront.type, lastModified: new Date().getTime()})
    const fileSide = new File([blobSide], 'video_side', {type: blobSide.type, lastModified: new Date().getTime()})
    const selfieBlob = await urlToBlob(selfie)
    const fileSelfie = blobToFile(selfieBlob, 'kyc-selfie', new Date().getTime())

    const selfieExt = fileSelfie.type
    const videoExt = fileFront.type
    const uploadData: GiftShopKYCUserUploadLinkParam = {
      selfie: {
        size: fileSelfie.size,
        extension: fileSelfie.type.split('/')[1],
      },
      video_front: {
        size: fileFront.size,
        extension: fileFront.type.split('/')[1],
      },
      video_side: {
        size: fileSide.size,
        extension: fileSide.type.split('/')[1],
      },
    }

    for (let i = 0; i < Object.keys(uploadData).length; i += 1) {
      const key = Object.keys(uploadData)[i]
      const type = key.replace('video_', '') as GiftShopKYCUploadLinkType
      
      // eslint-disable-next-line no-await-in-loop
      await handleUploadLink(
        type,
        uploadData,
        type === 'front'
          ? fileFront
          : type === 'side'
          ? fileSide
          : fileSelfie,
        type === 'selfie' ? selfieExt : videoExt,
      )
    }

    await requestData('giftshop_register_user', {
      data: {
        selfie_link: link.current.selfie_link,
        video_front_link: link.current.video_front_link,
        video_side_link: link.current.video_side_link,
      },
      onRequestSuccess: (value) => {
        if (value.status === 200) {
          update('yuserActivationState', {kycStatus: 'processing'})
          setModal('satisfied')
        } else {
          setModal(undefined)
          showSnackbar(translate('giftShop:uploadFailed'))
        }
      },
    })
  }, [handleUploadLink, recordedChunks, selfie, translate, update])

  const handleRenderOverlay = useMemo(() => {
    switch (shootStatus) {
      case 'tutorial':
        return (
          <GiftShopRobopetActivationSelfieOverlay onClick={() => {
            handleTakeSelfie()
          }} />
        )
      case 'video':
        return (
          <GiftShopRobopetActivationSelfieOverlayRecord
            progress={recordProgress}
            isRecording={isRecording}
            onAudioEnd={onAudioEnd} />
        )
      default:
        return (
          <GiftShopRobopetActivationSelfieOverlay onClick={() => {
            handleRecord()
          }} />
        )
    }
  }, [handleRecord, handleTakeSelfie, isRecording, onAudioEnd, recordProgress, shootStatus])

  const handleRenderNeedPermission = useMemo(() => (
    <GiftShopTemplatePermissionDenied type="camera" />
  ), [])

  const handleRenderWebcam = useMemo(() => (
    <>
      <Webcam
        style={{height: '100%', position: 'absolute'}}
        mirrored
        onUserMediaError={() => setMediaError(true)}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraint} />
      {handleRenderOverlay}
    </>
  ), [handleRenderOverlay, videoConstraint])

  const handleRenderVerificationPage = useMemo(() => {
    switch (verifStatus) {
      case 'landing':
        return (
          <GiftShopRobopetActivationLanding
            onAgree={() => setVerifStatus('shoot')}
          />
        )
      case 'shoot':
        return mediaError ? handleRenderNeedPermission : handleRenderWebcam
      case 'confirmation':
        return selfie && (
          <GiftShopRobopetActivationSelfieReview
            selfie={selfie}
            onRetry={handleRetake}
            onAccept={handleUpload}
          />)
      default:
        return <></>
    }
  }, [handleRenderNeedPermission, handleRenderWebcam, handleRetake, handleUpload, mediaError, selfie, setVerifStatus, verifStatus])

  const renderModalUploading = useMemo(() => (
    <StyledModal visible>
      <StyledLottie animationData={animationData} />
      <StyledParagraph fontSize="m" fontWeight="bold">{uploadProgress}</StyledParagraph>
    </StyledModal>
  ), [uploadProgress])

  const renderModalSatisfied = useMemo(() => (
    <StyledModal visible>
      <Icon type="check" color="success_5" size={48} />
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('tree:hostRegistrationProcessModalTitle')}
        </Paragraph>
        <Paragraph fontSize="l" style={{marginTop: convertUnit(8)}}>
          {translate('giftShop:kycSatisfiedMessage')}
        </Paragraph>
        <StyledButton
          label={translate('global:ok')}
          onClick={() => {
            setModal(undefined)
            history.replace('giftshop_robopet', {afterKyc: true})
          }} />
    </StyledModal>
  ), [history, translate])

  const renderModal = useMemo(() => {
    switch (modal) {
      case 'progress':
        return renderModalUploading
      case 'satisfied':
        return renderModalSatisfied
      default:
        return <></>
    }
  }, [modal, renderModalSatisfied, renderModalUploading])

  return (
    <StyledContainer progress={verifStatus} mediaError={mediaError}>
      {renderModal}
      {handleRenderVerificationPage}
    </StyledContainer>
  )
}
