import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Icon, Input, List} from 'common/components'
import convertUnit from 'lib/unit'
import {useDebounce, useHistory} from 'utils'
import {useDispatch} from 'lib/redux'
import {useTranslation} from 'i18n'
import {useWindowLayout, useWindowMode} from 'windows'
import {TreeFotoTreeResponse} from 'types'
import {FototreeFootprintListProps} from './FototreeFootprintProps'
import FototreeFootprintListItem from './FototreeFootprintListItem'

const StyledSearchContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertUnit(8)};
`

const StyledContainer = styled.div`
  position: absolute;
  top: ${convertUnit(65)};
  height: calc(100% - ${convertUnit(65)});
  width: 100%;
  display: flex;
  background-color: ${({theme}) => theme.white_1};
  z-index: 5;
`

export default function FototreeFootprintList({
  data,
}: FototreeFootprintListProps) {
  const debounce = useDebounce()
  const history = useHistory()
  const mode = useWindowMode()
  const {update} = useDispatch()
  const {width} = useWindowLayout()
  const {translate} = useTranslation()
  const [searchData, setSearchData] = useState<TreeFotoTreeResponse[]>(data)

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFotoTreeResponse) => {
      update('lastTreeState', {videoIntro: true})
      history.push(
        'tree_fototree_detail',
        {
          treeId: item.id,
          treeAlias: item.alias,
          isPastMemory: true,
        },
        item.alias,
      )
    },
    [history, update],
  )

  const handleSearch = useCallback(
    (search: string) => {
      setSearchData(
        search
          ? data.filter(({name}) =>
              name.toLowerCase().includes(search.toLowerCase()),
            )
          : data,
      )
    },
    [data],
  )

  const handleRenderSearch = useMemo(
    () => (
      <StyledSearchContainer>
        <Input
          placeholder={translate('tree:eventSearchPlaceholder')}
          placeholderColor="gray_3"
          leftIcon={<Icon type="search" color="gray_3" size={16} />}
          rightIcon="delete"
          containerStyle={{margin: `${convertUnit(12)} ${convertUnit(20)}`}}
          onChangeText={(text) => debounce(() => handleSearch(text))}
          onRightIconClick={() => setSearchData(data)}
        />
      </StyledSearchContainer>
    ),
    [data, debounce, handleSearch, translate],
  )

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeResponse) => (
      <FototreeFootprintListItem
        data={item}
        onClickItem={handleNavigateFotoTreeDetail}
      />
    ),
    [handleNavigateFotoTreeDetail],
  )

  return (
    <StyledContainer>
      <List
        data={searchData}
        keyExtractor={(item) => item.id}
        renderItem={handleRenderItem}
        listHeaderElement={handleRenderSearch}
        numColumns={mode === 'website' ? Math.floor(width / 450) : 1}
      />
    </StyledContainer>
  )
}
