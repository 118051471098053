import React, {useMemo, useRef, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import {useParams} from 'react-router'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen, TreeUploadLeafForm} from 'pages'
import {ForkygramUploadData} from 'types'
import {useLocation, useForkygramUpload} from 'utils'
import {ConfirmationModal, ModalLoading} from 'common/components'

export default function TreeFototreeUploadScreen() {
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const {treeId, tagId, file} = useLocation('tree_fototree_upload').state
  const form = useRef<UseFormMethods<ForkygramUploadData>>()

  const stateSource = useState<string>()
  const stateLoading = useState(false)
  const loading = stateLoading[0]
  const src = stateSource[0]
  const [modal, setModal] = useState(false)

  const {publish, navigateFototreeDetail} = useForkygramUpload({
    stateLoading,
    stateSource,
    form,
    data: {file, treeId, treeAlias: alias},
  })

  const handleRenderContent = useMemo(
    () => (
      <TreeUploadLeafForm
        src={src}
        tag={{id: tagId, name: '', tree_id: treeId, alias: ''}}
        onFormUpdate={(item) => {
          form.current = item
        }}
        onSubmitForm={publish}
      />
    ),
    [publish, src, tagId, treeId],
  )

  const handleRenderModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modal}
        title={translate('global:cancel')}
        text={translate('giftShop:uploadModalMessage')}
        confirmText={translate('global:yesImSure')}
        cancelText={translate('global:stay')}
        onConfirm={navigateFototreeDetail}
        toggleModal={() => setModal((prev) => !prev)}
      />
    ),
    [modal, navigateFototreeDetail, translate],
  )

  return (
    <GiftShopRootScreen
      showBadgeIcon={false}
      headerTitle={translate('tree:leafAMemoryHeader')}
      onBackButtonClicked={() =>
        src !== undefined ? setModal(true) : navigateFototreeDetail()
      }>
      <ModalLoading visible={loading} />
      {handleRenderModal}
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
