import React from 'react'
import {useTranslation} from 'i18n'
import {AuthLoginWefieReapplyModalProps} from './AuthLoginWefieReapplyModalProps'
import {TemplateSorryModalContent} from '../../../../template'

export default function AuthLoginWefieReapplyModal({
  visible,
  toggleModal,
}: AuthLoginWefieReapplyModalProps) {
  const {translate} = useTranslation()

  return (
    <TemplateSorryModalContent
      visible={visible}
      upperDesc={translate('auth:wefieReapplyModalTitle')}
      bottomDesc={translate('auth:wefieReapplyModalDescription')}
      robopetImage={false}
      toggleModal={toggleModal}
    />
  )
}
