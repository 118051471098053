import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  FirebaseMessageRoomItemPayload,
  ForkygramPostData,
  parseShape,
  UserInfoBase,
} from 'types'
import {useHistory} from 'utils'
import {Paragraph, Image, ParsedText} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

export interface GiftShopMessageRoomContentItemNotificationLikeCommentProps {
  payload: FirebaseMessageRoomItemPayload['notification_like_comment']
}

export interface CreateParseShapeProps {
  member: UserInfoBase
  regex: RegExp
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMessageContainer = styled(StyledContainer)`
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  margin-left: ${convertUnit(16)};
  border-radius: ${convertUnit(4)};
  cursor: pointer;
`

export default function GiftShopMessageRoomContentItemNotificationLikeComment({
  payload: {comment, item, liker_member_id, member_params, username},
}: GiftShopMessageRoomContentItemNotificationLikeCommentProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const history = useHistory()
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]

  const handleNavigateProfile = useCallback(
    (member: UserInfoBase) => {
      history.push(
        'giftshop_profile',
        {
          self: member.id === id,
          postData: {
            member_id: member.id,
            username: member.username,
            photo: member.photo,
          },
        },
        member.username,
      )
    },
    [history, id],
  )

  const handleNavigatePost = useCallback(() => {
    history.push(
      'giftshop_profile_post_detail',
      {
        data,
        self: item.member_id === id,
        selectedItemId: item.id,
        memberId: item.member_id,
      },
      /**
       * Based on the response changes made on May 26th, 2023,
       * please note that the `item.username` doesn't always exist.
       */
      item.username || username,
    )
  }, [data, history, id, item.id, item.member_id, item.username, username])

  const createParseShape = useCallback(
    ({member, regex}: CreateParseShapeProps) => ({
      pattern: regex,
      style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
      renderText: (text: string) => text.replace(regex, `@${member.username}`),
      onClick: () => handleNavigateProfile(member),
    }),
    [handleNavigateProfile],
  )

  const patternsMention: parseShape[] = useMemo(
    () =>
      member_params?.map((member) => {
        const regex = new RegExp(`{{${member.id}}}`)
        return createParseShape({member, regex})
      }) ?? [],
    [createParseShape, member_params],
  )

  const patternsUsername: parseShape[] = useMemo(
    () =>
      member_params?.map((member) => {
        const regex = new RegExp(member.id)
        return createParseShape({member, regex})
      }) ?? [],
    [createParseShape, member_params],
  )

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContainer>
        <StyledParagraph fontSize="m">
          <ParsedText parse={patternsUsername}>{liker_member_id}</ParsedText>
          {translate('messages:notificationLikeComment')}
          <ParsedText parse={patternsMention}>{comment}</ParsedText>
        </StyledParagraph>
      </StyledMessageContainer>
    ),
    [comment, liker_member_id, patternsMention, patternsUsername, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImage src={item.url} alt="image" onClick={handleNavigatePost} />
    ),
    [handleNavigatePost, item.url],
  )

  return (
    <StyledContainer>
      {handleRenderMessage}
      {handleRenderContent}
    </StyledContainer>
  )
}
