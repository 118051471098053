import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {
  API_PROFILE_BACKGROUND,
  API_PROFILE_BACKGROUND_LINK,
  API_PROFILE_PHOTO,
  API_PROFILE_PHOTO_LINK,
} from 'consts'
import {ServiceParamMember} from './ServiceParamMember'

export const SERVICE_MAP_MEMBER: ServiceMap<ServiceParamMember> = {
  member_upload_profile_photo_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_PHOTO_LINK,
  },
  member_upload_profile_photo: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_PHOTO,
  },
  member_upload_background_photo_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_BACKGROUND_LINK,
  },
  member_upload_background_photo: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_BACKGROUND,
  },
}
