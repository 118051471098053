import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useWindowMode} from 'windows'
import {getUniqueId, setSnackbarHandler} from 'utils'
import {SnackbarHandler, SnackbarState, parseShape} from 'types'
import convertUnit from 'lib/unit'
import {SnackbarProviderProps} from './SnackbarProps'
import SnackbarItem from './SnackbarItem'
import {SnackbarContext} from './SnackbarContext'

const StyledSnackbarContainer = styled.div`
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: ${convertUnit(15)};
`

export default function SnackbarProvider({children}: SnackbarProviderProps) {
  const mode = useWindowMode()
  const [snackbars, setSnackbars] = useState<SnackbarState[]>([])

  const handleShowSnackbar = useCallback(
    (message: string, parseText?: parseShape[]) => {
      const maxSnackbar = mode === 'website' ? 5 : 1
      const states = [...snackbars, {id: getUniqueId(), message, parseText}]
      if (states.length > maxSnackbar) {
        states.splice(0, 1)
      }
      setSnackbars(states)
    },
    [mode, snackbars],
  )

  const handleRemoveSnackbar = useCallback(
    (id: string) =>
      setSnackbars((previous) => previous.filter((item) => item.id !== id)),
    [setSnackbars],
  )

  const handleRenderSnackbars = useMemo(
    () => (
      <StyledSnackbarContainer>
        {snackbars.map((item) => (
          <SnackbarItem
            {...item}
            key={item.id}
            onDelete={() => handleRemoveSnackbar(item.id)}
          />
        ))}
      </StyledSnackbarContainer>
    ),
    [snackbars, handleRemoveSnackbar],
  )

  const handler = useMemo<SnackbarHandler>(
    () => ({
      snackbars,
      showSnackbar: handleShowSnackbar,
      removeSnackbar: handleRemoveSnackbar,
    }),
    [snackbars, handleRemoveSnackbar, handleShowSnackbar],
  )

  useEffect(() => {
    setSnackbarHandler(handler)
  }, [handler])

  return (
    <SnackbarContext.Provider value={handler}>
      {handleRenderSnackbars}
      {children}
    </SnackbarContext.Provider>
  )
}
