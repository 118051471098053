import {
  MutableRefObject,
  UIEventHandler,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {CSSHtmlProperties, ListEndReachedInfo, ListMetrics} from 'types'

export function useListMasonryNotLazyAction<ItemT>({
  data,
  headerLength,
  footerLength,
  contentLength,
  empty,
  minLength,
  keyExtractor,
  onScroll,
  forceUpdate,
}: {
  ref: MutableRefObject<HTMLDivElement | null>
  data: ReadonlyArray<ItemT>
  headerLength: MutableRefObject<number>
  footerLength: MutableRefObject<number>
  contentLength: MutableRefObject<number>
  empty: ListMetrics
  minLength: number
  keyExtractor(item: ItemT): string
  forceUpdate(): void
  onScroll?: CSSHtmlProperties<HTMLDivElement>['onScroll']
  onEndReached?(info: ListEndReachedInfo): void
  onVisibilityIndexChange?(index: number): void
}) {
  const metrics = useRef<Record<string, ListMetrics>>({})
  const rehydrate = useRef(false)

  const handleGetMetrics = useCallback(
    (key: string | null) =>
      key !== null && metrics.current[key] ? metrics.current[key] : empty,
    [empty],
  )

  const handleUpdateMetrics = useCallback(
    (key: string, values: Partial<ListMetrics>) => {
      if (!metrics.current[key]) {
        metrics.current[key] = {
          length: minLength,
          offset: 0,
        }
      }

      const metric = metrics.current[key]

      for (const [k, v] of Object.entries(values)) {
        metric[k] = v
      }
    },
    [minLength],
  )

  const handleUpdateItem = useCallback(
    (item: ItemT, index: number) => {
      const key = keyExtractor(item)
      const keyPrevious =
        index && data.length ? keyExtractor(data[index - 1]) : null
      const metricPrevious = handleGetMetrics(keyPrevious)
      const {offset: offsetPrevious, length: lengthPrevious} = metricPrevious
      const offset = offsetPrevious + lengthPrevious

      handleUpdateMetrics(key, {offset})
    },
    [data, keyExtractor, handleGetMetrics, handleUpdateMetrics],
  )

  const handleUpdate = useCallback(() => {
    rehydrate.current = false
    data.forEach(handleUpdateItem)

    if (data.length) {
      const keyLastIndex = keyExtractor(data[data.length - 1])
      const {offset, length} = handleGetMetrics(keyLastIndex)
      contentLength.current = offset + length
    }

    forceUpdate()
  }, [
    contentLength,
    data,
    forceUpdate,
    handleGetMetrics,
    handleUpdateItem,
    keyExtractor,
  ])

  const handleItemLayout = useCallback(
    ({
      key,
      relativeIndex,
      length,
      relativeArray,
    }: {
      key: string
      relativeIndex: number
      length: number
      relativeArray: ItemT[]
    }) => {
      if (metrics.current[key]?.length !== length) {
        handleUpdateMetrics(key, {length})
        rehydrate.current = true
      }

      if (relativeIndex === relativeArray.length - 1 && rehydrate.current) {
        handleUpdate()
      }
    },
    [handleUpdate, handleUpdateMetrics],
  )

  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      onScroll && onScroll(event)
    },
    [onScroll],
  )

  const handleLayoutHeader = useCallback(
    (length: number) => {
      if (headerLength.current !== length) {
        rehydrate.current = true
      }

      headerLength.current = length
    },
    [headerLength],
  )

  const handleLayoutFooter = useCallback(
    (length: number) => {
      if (footerLength.current !== length) {
        rehydrate.current = true
      }

      footerLength.current = length
    },
    [footerLength],
  )

  return useMemo(
    () => ({
      handleGetMetrics,
      handleItemLayout,
      handleScroll,
      handleLayoutHeader,
      handleLayoutFooter,
    }),
    [
      handleGetMetrics,
      handleItemLayout,
      handleScroll,
      handleLayoutHeader,
      handleLayoutFooter,
    ],
  )
}
