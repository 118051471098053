import firebase from 'firebase/app'
import {FirebaseConfig} from 'types'
import 'firebase/firestore'

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.FIRESTORE_API_KEY!,
  authDomain: process.env.FIRESTORE_AUTH_DOMAIN!,
  databaseURL: process.env.FIRESTORE_DATABASE_URL!,
  projectId: process.env.FIRESTORE_PROJECT_ID!,
  storageBucket: process.env.FIRESTORE_STORAGE_BUCKET!,
  messagingSenderId: process.env.FIRESTORE_MESSAGING_SENDER_ID!,
  appId: process.env.FIRESTORE_APP_ID!,
  measurementId: process.env.FIRESTORE_MEASUREMENT_ID!,
}

export default firebase.initializeApp(firebaseConfig)
