import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE,
} from 'consts'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionRootEmptyProps} from './TransactionRootEmptyProps'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_2};
`

const StyledContentContainer = styled.div`
  display: flex;
  padding: 0 ${convertUnit(50)};
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  height: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT)};
  max-width: 100%;
  margin: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE)} 0;
`

const StyledSubMessage = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
`

export default function TranscationRootEmpty({
  image,
  imageAlt,
  message,
  subMessage,
}: TransactionRootEmptyProps) {
  const handleRenderMessage = useMemo(
    () =>
      message ? (
        <Paragraph color="black" fontSize="l" fontWeight="bold">
          {message}
        </Paragraph>
      ) : undefined,
    [message],
  )

  const handleRenderSubMessage = useMemo(
    () =>
      subMessage ? (
        <StyledSubMessage color="gray_5" fontSize="s" fontWeight="regular">
          {subMessage}
        </StyledSubMessage>
      ) : undefined,
    [subMessage],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={image} alt={imageAlt} />,
    [image, imageAlt],
  )

  return (
    <StyledContainer>
      <StyledContentContainer>
        {handleRenderImage}
        {handleRenderMessage}
        {handleRenderSubMessage}
      </StyledContentContainer>
    </StyledContainer>
  )
}
