import {lazy} from 'react'

const GiftShopCollectionScreen = lazy(
  () =>
    import(/* webpackChunkName: 'Collection' */ './GiftShopCollectionScreen'),
)

const GiftShopCollectionDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'CollectionDetail' */ './GiftShopCollectionDetailScreen'
    ),
)

export * from './GiftShopCollectionDetailScreenParam'

export {GiftShopCollectionScreen, GiftShopCollectionDetailScreen}
