import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationRejectInvitationAdminProps {
  payload: FirebaseMessageRoomItemPayload['notification_reject_invitation_admin']
}

export default function GiftShopMessageRoomContentItemNotificationRejectInvitationAdmin({
  payload: {username},
}: GiftShopMessageRoomContentItemNotificationRejectInvitationAdminProps) {
  const {translate} = useTranslation()

  return (
    <>
      <Paragraph fontSize="m">
        {translate('messages:hiUsername', {username})}
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        {translate('messages:notificationAdminInvitationRejected')}
      </Paragraph>
    </>
  )
}
