import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  GIFTSHOP_UPLOAD_SINGLE_LIMIT,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {getBorder, showSnackbar} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Button, Icon, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadSeriesPreviewModalProps} from './GiftShopUploadSeriesPreviewModalProps'

type MobileButtons = 'trash' | 'plus'

interface StyledButtonProps {
  marginRight?: boolean
  marginLeft?: boolean
  width?: number
}

interface StyledImageProps {
  index: number
  width: number
  mode: WindowModeType
  selected?: boolean
}

interface StyledMobileButtonsProps {
  type: MobileButtons
}

interface StyledFooterProps {
  mode: WindowModeType
}

const StyledModal = styled(Modal)`
  width: 100%;
  max-height: 90vh;
  bottom: 0;
  position: fixed;
  box-sizing: border-box;
  padding-top: ${convertUnit(25)};
  padding-bottom: ${convertUnit(25)};
  padding-left: ${convertUnit(50)};
  padding-right: ${convertUnit(25)};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(8)};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding-left: ${convertUnit(25)};
    padding-right: 0;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${convertUnit(50)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0 ${convertUnit(25)};
  }
`

const StyledIconContainer = styled.div`
  cursor: pointer;
`

const StyledSeriesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: ${convertUnit(15)};
  padding: ${convertUnit(25)} 0 ${convertUnit(55)} 0;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${convertUnit(20)};
  }
`

const StyledImage = styled(Image)<StyledImageProps>`
  ${({selected, width, theme}) => ({
    width: convertUnit(width),
    height: convertUnit(width),
    border: selected ? getBorder(4, 'solid', theme.primary_5) : undefined,
  })}
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
  aspect-ratio: 1;
  cursor: pointer;
  position: relative;
`

const StyledFixedFooter = styled.div<StyledFooterProps>`
  position: fixed;
  background-color: ${({theme}) => theme.white_1};
  bottom: 0;
  padding-bottom: ${convertUnit(25)};
  padding-top: ${convertUnit(10)};
  display: flex;
  width: ${({mode}) => mode !== 'mobile' && '100%'};
`

const StyledButton = styled(Button)<StyledButtonProps>`
  width: 100%;
  margin-right: ${({marginRight}) => marginRight && convertUnit(12.5)};
  margin-left: ${({marginLeft}) => marginLeft && convertUnit(12.5)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin: 0 ${convertUnit(25)};
    width: ${({width}) => width && convertUnit(width)};
  }
`

const StyledMobileButtons = styled.div<StyledMobileButtonsProps>`
  cursor: pointer;
  width: ${convertUnit(44)};
  aspect-ratio: 1;
  border-radius: ${convertUnit(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme, type}) =>
    type === 'plus' ? theme.primary_5 : theme.gray_1};
`

const StyledSeriesImageContainer = styled.div`
  position: relative;
`

const StyledParentIconContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(10)};
  right: ${convertUnit(10)};
  z-index: 5;
`

export default function GiftShopUploadSeriesPreviewModal({
  visible,
  select,
  data,
  total,
  onClose,
  onUpload,
  onDeleteVariant,
  onSelect,
  onSetParent,
}: GiftShopUploadSeriesPreviewModalProps) {
  const {translate} = useTranslation()
  const {width} = useWindowLayout()
  const mode = useWindowMode()
  const {parent} = data

  const handleSetImgWidth = useMemo(() => {
    switch (mode) {
      case 'website':
        return (width - 205) / 8
      case 'tablet':
        return (width - 160) / 4
      case 'mobile':
        return (width - 110) / 4
    }
  }, [mode, width])

  const handleRenderButtons = useMemo(
    () => (
      <StyledFixedFooter mode={mode}>
        <StyledHeaderContainer>
          <StyledButton
            marginRight
            disabled={parent === select.id}
            label={translate('global:remove')}
            onClick={() => onDeleteVariant(data, select)}
          />
          <StyledButton
            marginRight
            marginLeft
            disabled={parent === select.id}
            label={translate('giftShop:uploadSeriesSetAsParent')}
            onClick={() => onSetParent(select)}
          />
          <StyledButton
            marginLeft
            label={translate('giftShop:uploadAddSeriesButton')}
            onClick={
              total >= 100
                ? () =>
                    showSnackbar(
                      translate('giftShop:uploadIconMaxSnackbar', {
                        max: GIFTSHOP_UPLOAD_SINGLE_LIMIT,
                      }),
                    )
                : onUpload
            }
          />
        </StyledHeaderContainer>
      </StyledFixedFooter>
    ),
    [
      mode,
      parent,
      select,
      translate,
      total,
      onUpload,
      onDeleteVariant,
      data,
      onSetParent,
    ],
  )

  const handleRenderMobileButtons = useMemo(
    () => (
      <StyledFixedFooter mode={mode}>
        <StyledHeaderContainer>
          <StyledMobileButtons
            type="trash"
            onClick={() =>
              select.id === parent
                ? showSnackbar(translate('giftShop:deleteParentSeriesMessage'))
                : onDeleteVariant(data, select)
            }>
            <Icon type="trash" color="gray_5" />
          </StyledMobileButtons>
          <StyledButton
            marginRight
            marginLeft
            disabled={parent === select.id}
            label={translate('giftShop:uploadSeriesSetAsParent')}
            onClick={() => onSetParent(select)}
            width={width - 188}
          />
          <StyledMobileButtons type="plus" onClick={onUpload}>
            <Icon type="plus" color="white_1" />
          </StyledMobileButtons>
        </StyledHeaderContainer>
      </StyledFixedFooter>
    ),
    [
      mode,
      parent,
      width,
      data,
      select,
      translate,
      onDeleteVariant,
      onSetParent,
      onUpload,
    ],
  )

  return (
    <StyledModal visible={visible}>
      <StyledContainer>
        <StyledHeaderContainer>
          <Paragraph
            fontSize={mode === 'mobile' ? 'l' : 'xl'}
            fontWeight="bold">
            {translate('giftShop:uploadSeriesModalTitle', {
              count: data.images.length,
            })}
          </Paragraph>
          <StyledIconContainer>
            <Icon type="close" size={convertUnit(24)} onClick={onClose} />
          </StyledIconContainer>
        </StyledHeaderContainer>
        <StyledSeriesContainer>
          {data.images.map((image, index) => (
            <StyledSeriesImageContainer key={image.id}>
              {parent === image.id && (
                <StyledParentIconContainer>
                  <Icon
                    type="star-solid"
                    color="success_3"
                    size={convertUnit(16)}
                  />
                </StyledParentIconContainer>
              )}
              <StyledImage
                selected={image.id === select.id}
                index={index}
                width={handleSetImgWidth}
                mode={mode}
                src={image.src}
                alt="series"
                onClick={() => onSelect(image)}
              />
            </StyledSeriesImageContainer>
          ))}
        </StyledSeriesContainer>
        {mode === 'mobile' ? handleRenderMobileButtons : handleRenderButtons}
      </StyledContainer>
    </StyledModal>
  )
}
