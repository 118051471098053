import {TranslationKey} from '../keys'
import {TRANSLATION_AUTH_EN} from './auth'
import {TRANSLATION_FORKYGRAM_EN} from './forkygram'
import {TRANSLATION_GIFT_SHOP_EN} from './gift-shop'
import {TRANSLATION_GLOBAL_EN} from './global'
import {TRANSLATION_MESSAGES_EN} from './messages'
import {TRANSLATION_POLICY_EN} from './policy'
import {TRANSLATION_TREE_EN} from './tree'
import {TranslationResource} from '../TranslationType'
import {TRANSLATION_TRANSACTION_EN} from './transaction'

export const TRANSLATION_EN: TranslationResource<TranslationKey> = {
  translation: {
    ...TRANSLATION_GLOBAL_EN.translation,
    ...TRANSLATION_AUTH_EN.translation,
    ...TRANSLATION_GIFT_SHOP_EN.translation,
    ...TRANSLATION_FORKYGRAM_EN.translation,
    ...TRANSLATION_MESSAGES_EN.translation,
    ...TRANSLATION_POLICY_EN.translation,
    ...TRANSLATION_TRANSACTION_EN.translation,
    ...TRANSLATION_TREE_EN.translation,
  },
}
