import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  Button,
  Modal,
  Paragraph,
  Icon,
  IconVerifiedBadge,
} from 'common/components'
import convertUnit from 'lib/unit'
import {BannerReferralModalProps} from './BannerReferralModalProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: column;
`

const StyledMessage = styled(Paragraph)`
  margin: ${convertUnit(15)} 0;
  padding: 0;
  text-align: center;
  line-height: 1.5;
`
const StyledFooter = styled(Paragraph)`
  margin: ${convertUnit(15)} 0;
  padding: 0;
  text-align: center;
  line-height: 1.5;
`
const StyledTitle = styled(Paragraph)`
  text-align: center;
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  transform: translateY(4px);
`

export default function BannerReferralModal({
  onClickOk,
  messageOne,
  messageTwo = '',
  footerOne,
  footerTwo = '',
  title,
  visible,
  isCreator,
}: BannerReferralModalProps) {
  const {translate} = useTranslation()

  const handleMessageRenderIcon = useMemo(
    () => isCreator && <Icon type="check" />,
    [isCreator],
  )

  const handleFooterRenderIcon = useMemo(
    () => !isCreator && <Icon type="check" />,
    [isCreator],
  )

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {title} {isCreator ? <StyledIconVerifiedBadge /> : ''}
      </StyledTitle>
      <StyledMessage fontSize="m" fontWeight="regular">
        {messageOne}
        {handleMessageRenderIcon}
        {messageTwo}
      </StyledMessage>
      <StyledFooter fontSize="m" fontWeight="regular">
        {footerOne}
        {handleFooterRenderIcon}
        {footerTwo}
      </StyledFooter>
      <Button label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>
  )
}
