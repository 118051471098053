import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopTemplateUploadForm} from 'pages'
import {GiftShopUploadSeriesData, LayoutView} from 'types'
import {
  ConfirmationModal,
  Flatlist,
  Icon,
  Paragraph,
  View,
} from 'common/components'
import convertUnit from 'lib/unit'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {useTheme} from 'themes'
import {GiftShopUploadContentItemBulkProps} from './GiftShopUploadContentItemBulkProps'
import GiftShopUploadContentItemBulkItem from './GiftShopUploadContentItemBulkItem'

interface StyledDeleteContainerProps {
  isDisabled: boolean
}

const StyledContainer = styled(View)`
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_2};
`

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${convertUnit(20)};
  padding-top: ${convertUnit(20)};
`

const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledFormContainer = styled.div`
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit(20)};
  border-bottom-color: ${({theme}) => theme.white_2};
`

const StyledDeleteContainer = styled.div<StyledDeleteContainerProps>`
  ${({isDisabled}) => ({
    cursor: isDisabled ? 'unset' : 'pointer',
  })}
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  align-items: center;
  padding-right: ${convertUnit(20)};
`

const StyledDeleteParagraph = styled(Paragraph)`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const StyledListContent = styled.div`
  display: flex;
  flex: 1;
`

const StyledSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

export default function GiftShopUploadContentItemBulk<
  ItemT extends GiftShopUploadSeriesData,
  S
>({
  data,
  stateSelected,
  defaultValues,
  onAddLocation,
  onDelete,
  onFormUpdate,
  toggleModal,
  uploadType,
  ...props
}: GiftShopUploadContentItemBulkProps<ItemT, S>) {
  const {translate} = useTranslation()
  const [layout, setLayout] = useState<LayoutView>()
  const [selected, setSelected] = stateSelected
  const stateImageData = useState<ReadonlyArray<ItemT>>(data)
  const setImgData = stateImageData[1]
  const priceState = useState(0)
  const [modal, setModal] = useState(false)
  const deleteValid = useMemo(() => selected.length > 0, [selected.length])

  const numColumns = useMemo(
    () => (layout ? Math.ceil(layout.clientWidth / 200) : 1),
    [layout],
  )

  const numRows = useMemo(() => Math.ceil(data.length / numColumns), [
    data,
    numColumns,
  ])

  const numberArray = Array.from({length: numRows}, (_, i) => i)
  const elementWidth = useMemo(() => `${100 / numColumns}%`, [numColumns])

  const handleKeyColumnExtractor = useCallback(
    (indexRow: number) => data[indexRow * numColumns].id,
    [data, numColumns],
  )
  const handleClickItem = useCallback(
    (id: string) => {
      if (selected.includes(id))
        setSelected((prev) => prev.filter((item) => item !== id))
      else setSelected((prev) => [...prev, id])
    },
    [selected, setSelected],
  )

  const handleRenderColumns = useCallback(
    (indexRow: number) => {
      const indexStart = indexRow * numColumns
      const indexEnd = (indexRow + 1) * numColumns
      const indexLimit = Math.min(indexEnd, data.length)
      const length = indexLimit - indexStart

      return (
        <StyledSectionItem key={`row_${data[indexStart]}`}>
          {Array.from({length}, (_, indexColumn) => {
            const index = indexStart + indexColumn
            const item = data[index]

            return (
              <GiftShopUploadContentItemBulkItem
                key={item.id}
                item={item}
                width={elementWidth}
                onClick={() => handleClickItem(item.id)}
                isSelected={selected.includes(item.id)}
                isVideo={uploadType === 'video'}
              />
            )
          })}
        </StyledSectionItem>
      )
    },
    [data, elementWidth, handleClickItem, numColumns, selected, uploadType],
  )

  const handleDelete = useCallback(() => {
    onDelete && onDelete(selected)
    setImgData((prev) => prev.filter((item) => !selected.includes(item.id)))
    setSelected([])
  }, [onDelete, selected, setImgData, setSelected])

  const handleRenderDeleteModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modal}
        title={translate('giftShop:uploadBulkDeleteConfirmationHeader')}
        text={translate('giftShop:uploadBulkDeleteConfirmationDescription')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handleDelete}
        toggleModal={() => setModal((prev) => !prev)}
      />
    ),
    [handleDelete, modal, translate],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeader>
        <Paragraph fontSize="m" fontWeight="medium">
          {deleteValid
            ? translate('global:selectedCount', {count: selected.length})
            : translate('global:allContents')}
        </Paragraph>
        <StyledDeleteContainer
          isDisabled={!deleteValid}
          onClick={() => (deleteValid ? setModal((prev) => !prev) : undefined)}>
          <Icon
            type="trash"
            size={convertUnit(16)}
            color={deleteValid ? 'danger_5' : 'gray_3'}
          />
          <StyledDeleteParagraph
            fontSize="s"
            fontWeight="bold"
            color={deleteValid ? 'danger_5' : 'gray_3'}>
            {translate('global:deleteContent')}
          </StyledDeleteParagraph>
        </StyledDeleteContainer>
      </StyledHeader>
    ),
    [deleteValid, selected.length, translate],
  )

  const handleRenderForm = useMemo(
    () => (
      <>
        <StyledFormContainer>
          <GiftShopTemplateUploadForm
            defaultValues={defaultValues}
            priceState={priceState}
            uploadType="bulk"
            onAddLocation={onAddLocation}
            onFormUpdate={onFormUpdate}
            toggleModal={toggleModal}
          />
        </StyledFormContainer>
        {handleRenderHeader}
      </>
    ),
    [
      defaultValues,
      handleRenderHeader,
      onAddLocation,
      onFormUpdate,
      priceState,
      toggleModal,
    ],
  )

  const theme = useTheme()

  const handleRenderListContent = useCallback(
    ({height, width}: Size) => (
      <Flatlist
        {...props}
        listHeaderElement={handleRenderForm}
        height={height}
        width={width}
        data={numberArray}
        chunkLength={height}
        keyExtractor={handleKeyColumnExtractor}
        renderItem={handleRenderColumns}
        contentContainerStyle={{
          padding: convertUnit(20),
          boxSizing: 'border-box',
          backgroundColor: theme.white_2,
        }}
      />
    ),
    [
      props,
      handleRenderForm,
      numberArray,
      handleKeyColumnExtractor,
      handleRenderColumns,
      theme.white_2,
    ],
  )

  const handleRenderList = useMemo(
    () => (
      <StyledListContent>
        <AutoSizer>{handleRenderListContent}</AutoSizer>
      </StyledListContent>
    ),
    [handleRenderListContent],
  )

  return (
    <StyledContainer onLayout={setLayout}>
      {handleRenderDeleteModal}
      <StyledContentContainer>{handleRenderList}</StyledContentContainer>
    </StyledContainer>
  )
}
