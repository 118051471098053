import React from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({mode}) =>
    mode === 'website' ? {
      height: '100%',
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    } : {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    }
  }
`

const StyledIconContainer = styled.div`
  margin-bottom: ${convertUnit(20)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export default function GiftShopMessageRoomEmpty() {
  const mode = useWindowMode()
  return (
    <StyledContainer mode={mode}>
      <StyledIconContainer>
        <Icon type="message" color="primary_5" size={40} />
      </StyledIconContainer>
      <StyledParagraph fontSize="l" fontWeight="bold">
        {translate('messages:emptyTitle')}
      </StyledParagraph>
      <StyledParagraph fontSize="s" color="gray_5">
        {translate('messages:emptyDesc')}
      </StyledParagraph>
    </StyledContainer>
  )
}
