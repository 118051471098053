import {Button, Modal, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import React from 'react'
import styled from 'styled-components'
import {GiftShopEditProfilePasswordWrongOTPModalProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(8)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(12)};
  width: 100%;
`

export default function GiftShopEditProfilePasswordWrongOTPModal({
  toggleModal,
}: GiftShopEditProfilePasswordWrongOTPModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:changePasswordLimitTitle')}
      </Paragraph>
      <Paragraph fontSize="m">
        {translate('giftShop:changePasswordLimitDesc')}
      </Paragraph>
      <StyledButton label={translate('global:ok')} onClick={toggleModal} />
    </StyledModal>
  )
}
