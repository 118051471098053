import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {showModalReprocessKyc, useHistory} from 'utils'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TemplateAuthAccessModal} from 'pages'
import {
  Button,
  ButtonOutline,
  Image,
  Paragraph,
  IconVerifiedBadge,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {TemplateFollowModalItemProps} from './TemplateFollowModalItemProps'

const StyledListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  margin: 0;
`

const StyledParagraph = styled(Paragraph)`
  font-size: ${convertUnit(16)};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledButton = styled(Button)`
  height: ${convertUnit(28)};
  padding: ${convertUnit(4)} ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: fit-content;
  }
`

const StyledAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${convertUnit(36)};
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  width: ${convertUnit(36)};
  height: ${convertUnit(36)};
  object-fit: cover;
  cursor: pointer;
  flex: none;
`

const StyledInfoWrapper = styled.div`
  display: flex;
  gap: ${convertUnit(10)};
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledButtonOutline = styled(ButtonOutline)`
  height: ${convertUnit(28)};
  padding: ${convertUnit(4)} ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: fit-content;
  }
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
`

export default function TemplateFollowModalItem({
  data,
  callback,
  onClose,
}: TemplateFollowModalItemProps) {
  const {translate} = useTranslation()
  const [isFollowed, setIsFollowed] = useState<boolean>(data.is_followed)
  const history = useHistory()
  const {access_token, id: userId} = useSelector('user') || {}
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const [loginModal, setLoginModal] = useState(false)

  const handleFeatureDisabled = useCallback(() => {
    onClose()
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, onClose, similarStatus])

  const handleUnfollow = useCallback(() => {
    setIsFollowed(false)
    callback(false)
    requestData('giftshop_delete_follow', {
      data: {member_id: data.id},
      onRequestFailed: () => {
        setIsFollowed(true)
        callback(true)
      },
    })
  }, [callback, data.id])

  const handleFollow = useCallback(() => {
    setIsFollowed(true)
    callback(true)
    requestData('giftshop_post_follow', {
      data: {member_id: data.id},
      onRequestFailed: () => {
        setIsFollowed(false)
        callback(false)
      },
    })
  }, [callback, data.id])

  const goToProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: data.id === userId,
        postData: {
          member_id: data.id,
          username: data.username,
          photo: data.photo,
        },
      },
      data.username,
    )

    onClose()
  }, [history, data.id, data.username, data.photo, userId, onClose])

  const handleClickFollow = useCallback(() => {
    if (access_token) {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      handleFollow()
    } else setLoginModal(true)
  }, [access_token, handleFeatureDisabled, handleFollow, kycStatus])

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  return (
    <StyledListItem>
      {handleRenderLoginModal}
      <StyledInfoWrapper onClick={goToProfile}>
        <StyledAvatarContainer>
          <StyledAvatar src={data.photo} alt={data.username} />
        </StyledAvatarContainer>
        <StyledParagraph fontWeight="medium">{data.username}</StyledParagraph>
        {data.creator_status === 'verified' && <StyledIconVerifiedBadge />}
      </StyledInfoWrapper>
      {data.id !== userId &&
        (isFollowed ? (
          <StyledButtonOutline
            isLoading={false}
            borderColor="gray_5"
            color="gray_6"
            label={translate('forkygram:following')}
            onClick={() => {
              if (
                !(kycStatus === 'verified' || kycStatus === 'verified_check')
              ) {
                handleFeatureDisabled()
                return
              }
              handleUnfollow()
            }}
          />
        ) : (
          <StyledButton
            isLoading={false}
            label={translate('forkygram:follow')}
            onClick={handleClickFollow}
          />
        ))}
    </StyledListItem>
  )
}
