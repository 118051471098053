import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledModal = styled(Modal)`
  max-width: ${convertUnit(335)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: ${convertUnit(20)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(8)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function PaymentCheckoutErrorModal({
  visible,
  toggleModal,
}: {
  visible: boolean
  toggleModal: () => void
}) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="xl" fontWeight="bold">
        {translate('global:failed')}
      </StyledTitle>
      <StyledParagraph fontSize="l">
        {translate('transaction:paymentFailedModalMessage')}
      </StyledParagraph>
      <StyledButton label={translate('global:ok')} onClick={toggleModal} />
    </StyledModal>
  )
}
