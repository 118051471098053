import {TOptions} from 'i18next'
import {translate, TranslationKey} from 'i18n'
import {InputFormValidationMessageOptions} from 'types'

export function getValidation(key: TranslationKey, options?: TOptions) {
  return (props: InputFormValidationMessageOptions) =>
    translate(key, {
      ...props,
      ...options,
    })
}
