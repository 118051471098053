import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {ForkygramUploadData} from 'types'
import {useDidMount} from 'utils'
import {ForkygramTemplateUploadContent} from '../Content'
import {ForkygramTemplateUploadFormProps} from './ForkygramTemplateUploadFormProps'

export default function ForkygramTemplateUploadForm({
  src,
  caption = '',
  tag = {id: '', name: '', tree_id: '', alias: ''},
  height = 0,
  size = 0,
  width = 0,
  contentType,
  editContent,
  thumbnail,
  stateFormError,
  onFormUpdate,
}: ForkygramTemplateUploadFormProps) {
  const form = useForm<ForkygramUploadData>({
    defaultValues: {
      caption,
      tag,
      height,
      size,
      width,
    },
  })
  const {register} = form

  const handleRenderContent = useMemo(
    () =>
      src ? (
        <ForkygramTemplateUploadContent
          contentType={contentType}
          form={form}
          src={src}
          editContent={editContent}
          thumbnail={thumbnail}
          stateFormError={stateFormError}
        />
      ) : null,
    [contentType, editContent, form, src, stateFormError, thumbnail],
  )

  useDidMount(() => {
    register('extension')
    register('height')
    register('width')
    register('size')
    register('mime')
    register('file')
    register('caption')
    register('tag')
    register('duration')
  })

  useEffect(() => {
    onFormUpdate && onFormUpdate(form)
  }, [form, onFormUpdate])

  return handleRenderContent
}
