import {TranslationKeyTransaction} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_TRANSACTION_EN: TranslationResource<TranslationKeyTransaction> = {
  translation: {
    'transaction:cancelTransactionModalMessage':
      'Are you sure you want to cancel this transaction?',
    'transaction:cartDoubleBookedDescription':
      'Do you want to check out this content again and cancel the previous invoice?',
    'transaction:cartDoubleBookedTitle': 'Double Booked',
    'transaction:checkoutContentCollabRepost':
      'Collab Repost • Your username will be displayed.',
    'transaction:checkoutContentDoNotAutopost': 'Do not repost the content',
    'transaction:checkoutContentExpand': 'Show All',
    'transaction:checkoutContentPrice': 'Price',
    'transaction:creditCardLastFour': '(******{{number}})',
    'transaction:checkoutTransactionDate': 'Transaction Date',
    'transaction:checkoutTransactionTitle': 'Transaction Detail',
    'transaction:checkoutTransactionType': 'Transaction Type',
    'transaction:checkoutWaitForHiresInfoDesc1':
      'The Hi-Res will appears in the “Collections” at “FotoYu” tab.',
    'transaction:checkoutWaitForHiresInfoDesc2':
      'You can also chat the creator seller by clicking the message icon below each content you purchased.',
    'transaction:checkoutWaitForHiresInfoDesc3':
      'Creators need to resend Hi-Res in 48 hours, or you will get a refund.',
    'transaction:checkoutWaitForHiresInfoTitle':
      'Please wait for the Hi-Res version.',
    'transaction:checkoutUseAnotherBrowser':
      'If there is a problem, try using another browser.',
    'transaction:paymentFailedModalMessage':
      'Payment cannot be processed this time, please try again later',
  },
}
