import {useCallback} from 'react'
import {parseShape, TextExtractionProps} from 'types'

export default function TextExtraction(text: string, patterns: parseShape[]) {
  const getMatchedPart = useCallback(
    (
      matchedPattern: parseShape,
      matchText: string,
      matches: string[],
      index: number,
    ) => {
      const props = {}

      Object.keys(matchedPattern).forEach((key) => {
        if (
          key === 'pattern' ||
          key === 'renderText' ||
          key === 'nonExhaustiveModeMaxMatchCount'
        )
          return

        if (typeof matchedPattern[key] === 'function') {
          props[key] = () => matchedPattern[key](matchText, index)
        } else {
          props[key] = matchedPattern[key]
        }
      })

      let children = matchText
      if (
        matchedPattern.renderText &&
        typeof matchedPattern.renderText === 'function'
      ) {
        children = matchedPattern.renderText(matchText, matches)
      }

      return {
        ...props,
        children,
        matched: true,
      }
    },
    [],
  )

  const parse = useCallback(() => {
    let parsedTexts: TextExtractionProps[] = [{children: text, matched: false}]

    patterns.forEach((pattern) => {
      const newParts: TextExtractionProps[] = []

      const tmp = pattern.nonExhaustiveModeMaxMatchCount || 0
      const numberOfMatchesPermitted = Math.min(
        Math.max(Number.isInteger(tmp) ? tmp : 0, 0) ||
          Number.POSITIVE_INFINITY,
        Number.POSITIVE_INFINITY,
      )

      let currentMatches = 0

      parsedTexts.forEach((parsedText) => {
        if (parsedText.matched) {
          newParts.push(parsedText)
          return
        }

        const parts: TextExtractionProps[] = []
        let textLeft = parsedText.children
        let indexOfMatchedString = 0

        if (typeof pattern.pattern === 'object') {
          let matches = pattern.pattern.exec(textLeft)
          pattern.pattern.lastIndex = 0
          while (textLeft && matches) {
            const previousText = textLeft.substring(0, matches.index)
            indexOfMatchedString = matches.index

            currentMatches += 1
            if (currentMatches > numberOfMatchesPermitted) {
              break
            }

            parts.push({children: previousText})

            parts.push(
              getMatchedPart(
                pattern,
                matches[0],
                matches,
                indexOfMatchedString,
              ),
            )

            textLeft = textLeft.substring(matches.index + matches[0].length)
            indexOfMatchedString += matches[0].length - 1
            pattern.pattern.lastIndex = 0
            matches = pattern.pattern.exec(textLeft)
          }
        }

        parts.push({children: textLeft})
        newParts.push(...parts)
      })

      parsedTexts = newParts
    })

    parsedTexts.forEach((parsedText) => delete parsedText.matched)

    return parsedTexts.filter((t) => !!t.children)
  }, [getMatchedPart, patterns, text])

  return parse()
}
