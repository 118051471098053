import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_DEFAULT_FEE_INDEPENDENT,
  HOST_MAX_FEE_INDEPENDENT,
  REGEX_NUMBER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {Button, Icon, Input, ModalLoading} from 'common/components'
import {VALIDATION_TREE_HOST_FEE_INDEPENDENT} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeEditProfileFormStringProps} from './TreeEditProfileFormProps'

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    padding: ${convertUnit(20)};
    border-radius: ${convertUnit(0)};
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeEditProfileFormHostFeeIndependent({
  defaultValue,
  stateActiveContent,
}: TreeEditProfileFormStringProps) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)

  const form = useForm<{independentFee: string}>({
    defaultValues: {
      independentFee: defaultValue || '0',
    },
  })
  const {watch, errors} = form
  const {independentFee} = watch()
  const disabled = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      parseInt(independentFee, 10) > HOST_MAX_FEE_INDEPENDENT ||
      independentFee === defaultValue,
    [errors, independentFee, defaultValue],
  )

  const handlePatchHost = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_hosts', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        independent_creator_fee:
          independentFee.length === 0
            ? HOST_DEFAULT_FEE_INDEPENDENT
            : parseInt(independentFee, 10),
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        status === 200 && setActiveContent('profile')
      },
    })
  }, [independentFee, setActiveContent])

  const handleRenderForm = useMemo(
    () => (
      <Input
        form={form}
        name="independentFee"
        allowedCharacters={REGEX_NUMBER}
        formRules={VALIDATION_TREE_HOST_FEE_INDEPENDENT}
        label={translate('tree:editHostFormLabelHostFeeIndependent')}
        placeholder={translate('tree:editHostFormPlaceholderHostFee')}
        rightIcon={<Icon type="percentage" size={convertUnit(16)} />}
        max={HOST_MAX_FEE_INDEPENDENT}
      />
    ),
    [form, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={disabled}
        label={translate('global:save')}
        onClick={handlePatchHost}
      />
    ),
    [disabled, handlePatchHost, translate],
  )

  return (
    <StyledContainer>
      <ModalLoading visible={loading} />
      {handleRenderForm}
      {handleRenderButton}
    </StyledContainer>
  )
}
