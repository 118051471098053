import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {LogoutModalItemProps} from './LogoutModalItemProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(300)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`

const StyledParagraph = styled(Paragraph)`
  margin: ${convertUnit(15)} 0;
  padding: 0;
  text-align: center;
`

const StyledRobopetImage = styled(Image)`
  width: 100%;
  max-width: ${convertUnit(120)};
  aspect-ratio: 1;
  object-fit: contain;
  margin: ${convertUnit(20)} auto;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

export default function LogoutModalItem({onClickOk}: LogoutModalItemProps) {
  const {translate} = useTranslation()
  const img = IMAGE_ASSET('giftshop', 'robopet-offer.png')

  return (
    <StyledModal visible>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('auth:logoutModalTitle')}
      </StyledTitle>

      <StyledRobopetImage src={img} alt={img} />

      <StyledParagraph fontSize="m" fontWeight="regular">
        {translate('auth:logoutModalExpiredTokenDescription')}
      </StyledParagraph>

      <Button label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>
  )
}
