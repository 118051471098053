import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Button, Input, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {fototreeMaxEventDate, useDidMount} from 'utils'
import {VALIDATION_TREE_PLANT_FOTOTREE_EVENT_DATE} from 'common/validations'
import {FotoTreeEditProfileEventDateModalProps} from './FototreeEditProfileEventDateModalProps'

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(335)};
  gap: ${convertUnit(20)};
  padding: ${convertUnit(20)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledSubtitle = styled(Paragraph)`
  padding: ${convertUnit(4)} 0;
`

const StyledEventDateContainer = styled.div`
  display: flex;
  gap: ${convertUnit(16)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

export default function FotoTreeEditProfileEventDateModal({
  form,
  showState,
  onSubmit,
}: FotoTreeEditProfileEventDateModalProps) {
  const {translate} = useTranslation()
  const [eventDateModal, setEventDateModal] = showState
  const {errors, setValue, register, watch, handleSubmit} = form
  const {eventStartDate, eventEndDate} = watch()

  const isValidEventDate = useMemo(
    () =>
      eventStartDate &&
      eventEndDate &&
      !errors.eventStartDate &&
      !errors.eventEndDate,
    [errors.eventEndDate, errors.eventStartDate, eventEndDate, eventStartDate],
  )

  const handleRenderEventDateStart = useMemo(
    () => (
      <StyledColumn>
        <StyledSubtitle fontWeight="medium" color="gray_6">
          {translate('tree:eventDateStart')}
        </StyledSubtitle>
        <Input
          name="eventStartDate"
          form={form}
          type="date"
          onChangeText={(text) => {
            setValue('eventStartDate', text)
            setValue('eventEndDate', text)
          }}
        />
      </StyledColumn>
    ),
    [form, setValue, translate],
  )

  const handleRenderEventDateEnd = useMemo(
    () => (
      <StyledColumn>
        <StyledSubtitle fontWeight="medium" color="gray_6">
          {translate('tree:eventDateEnd')}
        </StyledSubtitle>
        <Input
          name="eventEndDate"
          form={form}
          type="date"
          min={eventStartDate}
          max={fototreeMaxEventDate(eventStartDate)}
          disabled={!eventStartDate}
          formRules={
            eventStartDate
              ? VALIDATION_TREE_PLANT_FOTOTREE_EVENT_DATE(eventStartDate)
              : undefined
          }
          onChangeText={(text) => setValue('eventEndDate', text)}
        />
      </StyledColumn>
    ),
    [eventStartDate, form, setValue, translate],
  )

  useDidMount(() => {
    register('eventEndDate')
    register('eventStartDate')
  })

  return (
    <StyledModal visible={eventDateModal}>
      <StyledTitle fontWeight="bold" fontSize="l">
        {translate('tree:eventDate')}
      </StyledTitle>
      <StyledEventDateContainer>
        {handleRenderEventDateStart}
        {handleRenderEventDateEnd}
      </StyledEventDateContainer>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          onClick={() => setEventDateModal((prev) => !prev)}
          color="primary_5"
          backgroundColor="white_2"
          backgroundHoverColor="white_3"
        />
        <StyledButton
          label={translate('global:save')}
          disabled={!isValidEventDate}
          onClick={handleSubmit(onSubmit)}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
