import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Avatar, Icon, InputArea, Paragraph} from 'common/components'
import {IMAGE_ASSET, TREE_CHAT_MAX_LENGTH} from 'consts'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {useTranslation} from 'i18n'
import {getBorder, getFontFamily} from 'utils'
import {FototreeGlobalChatInputProps} from './FototreeGlobalChatProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${convertUnit(12)};
  padding: ${convertUnit(8)} ${convertUnit(20)};
  border-top: ${convertUnit(1)} solid ${({theme}) => theme.gray_8};
  background-color: ${({theme}) => theme.black_2};
`

const StyledToast = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.black_2,
    borderTop: getBorder(1, 'solid', theme.black_1),
  })}
  display: flex;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  gap: ${convertUnit(12)};
`

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

export default function FototreeGlobalChatInput({
  stateLoginModal,
  stateLoading,
  stateMessage,
  stateRecipient,
  onSend,
}: FototreeGlobalChatInputProps) {
  const defaultImg = IMAGE_ASSET('giftshop', 'users/default.png')
  const {translate} = useTranslation()
  const {access_token, username, photo} = useSelector('user') || {}
  const [message, setMessage] = stateMessage
  const [recipient, setRecipient] = stateRecipient
  const setLoginModal = stateLoginModal[1]
  const loading = stateLoading[0]

  const isDisabled = useMemo(() => message === '' || loading, [
    loading,
    message,
  ])

  const handleRenderToast = useMemo(
    () =>
      recipient.username && (
        <StyledToast>
          <Icon
            type="close"
            color="white_1"
            size={14}
            style={{cursor: 'pointer'}}
            onClick={() =>
              setRecipient({
                comment_id: '',
                username: '',
              })
            }
          />
          <Paragraph color="white_1">
            {translate('forkygram:replying')}{' '}
            <StyledBold>@{recipient.username}</StyledBold>
          </Paragraph>
        </StyledToast>
      ),
    [recipient, setRecipient, translate],
  )

  const handleRenderInput = useMemo(
    () => (
      <InputArea
        autosize
        backgroundColor="black_2"
        borderColor="black_2"
        hoverBorderColor="black_2"
        focusBackgroundColor="black_2"
        focusBorderColor="black_2"
        color="white_1"
        placeholder={translate('forkygram:commentPlaceholder', {
          context: !access_token && 'GUEST',
          username,
        })}
        containerStyle={{
          display: 'flex',
          flex: 1,
        }}
        inputStyle={{
          resize: 'none',
          padding: `${convertUnit(10)} 0`,
          height: convertUnit(50),
        }}
        value={message}
        maxLength={TREE_CHAT_MAX_LENGTH}
        onChangeText={(text) => {
          if (!access_token) {
            setLoginModal(true)
            setMessage('')
          } else setMessage(text)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !isDisabled) {
            onSend()
          }
        }}
        onClick={() => {
          if (!access_token) setLoginModal(true)
        }}
      />
    ),
    [
      access_token,
      isDisabled,
      message,
      onSend,
      setLoginModal,
      setMessage,
      translate,
      username,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderToast}
      <StyledInputContainer>
        <Avatar src={photo || defaultImg} alt="avatar" />
        {handleRenderInput}
        <Icon
          type="send"
          color={isDisabled ? 'gray_6' : 'white_1'}
          onClick={() => (isDisabled ? undefined : onSend())}
          style={{cursor: isDisabled ? 'default' : 'pointer'}}
        />
      </StyledInputContainer>
    </StyledContainer>
  )
}
