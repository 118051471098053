import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {
  AUTH_DOB_MIN_YEAR,
  AUTH_PASSWORD_MAX_LENGTH,
  AUTH_USERNAME_MAX_LENGTH,
  AUTH_USER_MIN_AGE,
  REGEX_ALLOWED_USERNAME,
  REGEX_EMOJI,
} from 'consts'
import {useTranslation} from 'i18n'
import {AuthFormRegisterData, AuthFormSimpleRegisterData, ObjectState} from 'types'
import {Button, Input, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {parseDate, validateMinimumAge} from 'utils'
import {VALIDATION_AUTH_USERNAME, VALIDATION_CREATE_PASSWORD} from 'common/validations'

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
  align-self: flex-start;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledDisclaimer = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(25)};
`

interface TemplateRegisterFormProps {
  form: UseFormMethods<AuthFormSimpleRegisterData>
  stateValue: ObjectState<AuthFormRegisterData>
  stateLoading: ObjectState<boolean>
  stateError: ObjectState<string | undefined>
  hidePasswordField?: boolean
  onClick(): void
}
export default function TemplateRegisterForm({
  form,
  stateValue,
  stateLoading,
  stateError,
  hidePasswordField,
  onClick,
}: TemplateRegisterFormProps) {
  const {translate} = useTranslation()
  const [savedValues, setSavedValues] = stateValue
  const [errorMessage, setErrorMessage] = stateError
  const [dobError, setDobError] = useState('')
  const [isDobError, setIsDobError] = useState(true);
  const loading = stateLoading[0]
  const {watch, errors, setValue} = form
  const values = watch()
  const {username, password, confirm, dateOfBirth} = values
  const offset = useMemo(() => new Date().getTimezoneOffset(), [])
  const date = useMemo(() => new Date(new Date().getTime() - offset * 60000), [
    offset,
  ])
  const today = date.toISOString().substring(0, 10)
  const [focus, setFocus] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(true)
  const isDisabled = useMemo(() => 
    username === '' ||
    password === '' ||
    confirm === '' ||
    !passwordMatch ||
    dateOfBirth === undefined ||
    Object.keys(errors).length > 0 ||
    errorMessage !== undefined || isDobError,
    [confirm, dateOfBirth, errorMessage, errors, isDobError, password, passwordMatch, username])

  const handleCheckDob = useCallback(() => {
    if (values.dateOfBirth) {
      const dob = values.dateOfBirth
      const dobStr = dob.toString()
      const dobDate = new Date(dob)
      const dobYear = dobStr.substring(0, 4)
      const intDobYear = parseInt(dobYear, 10)

      if ((!focus && dobDate > date) || dobStr.length > 10) {
        setValue('dateOfBirth', today)
      }

      if (
        (!dobYear.startsWith('0') && intDobYear < AUTH_DOB_MIN_YEAR) ||
        (!focus && dobYear.startsWith('0'))
      ) {
        setValue(
          'dateOfBirth',
          `${AUTH_DOB_MIN_YEAR}${dobStr.substring(4, 10)}`,
        )
      }

      if (!focus && intDobYear > new Date().getFullYear()) {
        setValue('dateOfBirth', today)
      }
      if (!validateMinimumAge(parseDate(dobStr, 'DD/MM/YYYY'), AUTH_USER_MIN_AGE)) {
        setDobError(translate('auth:registerUnderage'))
      } else {
        setDobError('')
      }
      return dobYear.startsWith('0') || !validateMinimumAge(parseDate(dobStr, 'DD/MM/YYYY'), AUTH_USER_MIN_AGE)
    }
    return true
  }, [values.dateOfBirth, focus, date, setValue, today, translate])

  const handleRenderError = useMemo(
    () =>
      errorMessage ? (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage}
        </StyledError>
      ) : null,
    [errorMessage],
  )

  const handleRenderDobError = useMemo(() => (
    <StyledError color="danger_5" fontWeight="medium">
      {dobError}
    </StyledError>
  ), [dobError])

  useEffect(() => setPasswordMatch(confirm === '' || password === confirm), [confirm, password])
  useEffect(() => {
    if (focus) {
      setIsDobError(handleCheckDob)
    }
  }, [focus, handleCheckDob])

  return (
    <>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {translate('auth:registerHeaderAccount')}
      </StyledParagraph>
      <Input
        name="username"
        form={form}
        autoComplete={false}
        formRules={VALIDATION_AUTH_USERNAME}
        label={translate('global:username')}
        placeholder={translate('auth:registerUsernameInputPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="delete"
        maxLength={AUTH_USERNAME_MAX_LENGTH}
        allowedCharacters={REGEX_ALLOWED_USERNAME}
        onChangeText={() => {
          setValue('username', username.toLowerCase().replace(REGEX_EMOJI, ''))
          setErrorMessage(undefined)
        }}
      />
      {handleRenderError}
      {hidePasswordField
        ? <></>
        : <>
          <Input
            name="password"
            type="password"
            form={form}
            maxLength={AUTH_PASSWORD_MAX_LENGTH}
            formRules={VALIDATION_CREATE_PASSWORD}
            label={translate('global:password')}
            placeholder={translate('auth:registerPasswordInputPlaceholder')}
            containerStyle={{marginTop: convertUnit(25)}}
            rightIcon="visibility"
          />
          <Input
            name="confirm"
            type="password"
            form={form}
            maxLength={AUTH_PASSWORD_MAX_LENGTH}
            label={translate('global:passwordConfirm')}
            placeholder={translate('auth:registerPasswordConfirmationMessage')}
            containerStyle={{marginTop: convertUnit(25)}}
            rightIcon="visibility"
          />
          {!passwordMatch &&
            <StyledError color="danger_5" fontWeight="medium">
              {translate('auth:confirmationPassword')}
            </StyledError>}
        </>
      }
      <Input
        name="dateOfBirth"
        type="date"
        labelDescriptionDirection="column"
        labelDescriptionWeight="regular"
        labelDescriptionColor="gray_7"
        labelDescription={translate('auth:registerDoBDescription', {age: AUTH_USER_MIN_AGE})}
        defaultValue={today}
        form={form}
        label={translate('global:dateOfBirth')}
        placeholder={translate('auth:registerDateOfBirthInputPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        min={`${AUTH_DOB_MIN_YEAR}-01-01`}
        max={today}
        onChange={() => setSavedValues({...savedValues, ...values})}
      />
      {handleRenderDobError}
      <StyledDisclaimer color="gray_5">{translate('auth:identifierOneAccount')}</StyledDisclaimer>
      <StyledButtonLogin
        disabled={isDisabled}
        isLoading={loading}
        label={translate('global:next')}
        onClick={onClick}
      />
    </>
  )
}
