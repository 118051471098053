import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import GiftShopUploadDropzoneSingle from './GiftShopUploadDropzoneSingle'
import GiftShopUploadDropzoneBulk from './GiftShopUploadDropzoneBulk'
import {GiftShopUploadDropzoneProps} from './GiftShopUploadDropzoneProps'
import GiftShopUploadDropzoneVideo from './GiftShopUploadDropzoneVideo'

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: row;
  height: 100vh;
  gap: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledUploadPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  flex: 2;
`

const StyledUploadVideoContainer = styled.div`
  display: flex;
  flex: 1;
`

export default function GiftShopUploadDropzone({
  onUploadBulk,
  onUploadSingle,
  validator,
  videoValidator,
  onUploadVideo,
  onClickBulk,
  onClickSingle,
  onClickVideo,
  onNonVerifiedCreatorUpload,
}: GiftShopUploadDropzoneProps) {
  return (
    <StyledContainer>
      <StyledUploadPhotoContainer>
        <GiftShopUploadDropzoneSingle
          onUploadSingle={onUploadSingle}
          validator={validator}
          onClickSingle={onClickSingle}
        />
        <GiftShopUploadDropzoneBulk
          onUploadBulk={onUploadBulk}
          validator={validator}
          onClickBulk={onClickBulk}
        />
      </StyledUploadPhotoContainer>
      <StyledUploadVideoContainer>
        <GiftShopUploadDropzoneVideo
          onUploadVideo={onUploadVideo}
          videoValidator={videoValidator}
          onClickVideo={onClickVideo}
          onNonVerifiedCreatorUpload={onNonVerifiedCreatorUpload}
        />
      </StyledUploadVideoContainer>
    </StyledContainer>
  )
}
