import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {SeparatorProps} from './SeparatorProps'

const StyledSeparatorContainer = styled.div`
  box-sizing: border-box;
`

const StyledSeparator = styled.div`
  padding: ${convertUnit(16)} 0;
  font-weight: 'bold';
`

const StyledHr = styled.hr`
  height: 1px;
  border: none;
  background-color: ${({theme}) => theme.gray_1};
  margin: 0;
`

export default function Separator({horizontalRule, children}: SeparatorProps) {
  return (
    <StyledSeparatorContainer>
      {(horizontalRule === 'both' || horizontalRule === 'top') && <StyledHr />}
      <StyledSeparator>{children}</StyledSeparator>
      {(horizontalRule === 'both' || horizontalRule === 'bottom') && (
        <StyledHr />
      )}
    </StyledSeparatorContainer>
  )
}
