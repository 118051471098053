import React from 'react'
import styled from 'styled-components'
import {useWindowLayout} from 'windows'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostProgressBarProps} from './ForkygramTemplatePostProgressBarProps'

interface StyledContainerProps {
  width: number
}

const StyledContainer = styled.div<StyledContainerProps>`
  bottom: ${convertUnit(3)};
  right: ${convertUnit(5)};
  position: absolute;
  width: ${({width}) => convertUnit(width)};
`

const StyledProgressBarContainer = styled.div`
  width: calc(100%- (${convertUnit(2)}));
  background-color: ${({theme}) => theme.gray_3};
  height: ${convertUnit(2)};
`

const StyledProgressBar = styled.div<StyledContainerProps>`
  background-color: ${({theme}) => theme.white_1};
  height: ${convertUnit(2)};
  width: ${({width}) => convertUnit(width)};
`

export default function ForkygramTemplatePostProgressBar({
  duration,
  progress,
}: ForkygramTemplatePostProgressBarProps) {
  const {width} = useWindowLayout()
  const progressWidth = (progress / duration) * 1000

  return (
    <StyledContainer width={width - 10}>
      <StyledProgressBarContainer>
        <StyledProgressBar width={progressWidth} />
      </StyledProgressBarContainer>
    </StyledContainer>
  )
}
