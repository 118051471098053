import {
  HOST_ACCOUNT_HOLDER_MAX_LENGTH,
  HOST_BANK_NUMBER_MAX_LENGTH,
  HOST_BANK_NUMBER_MIN_LENGTH,
  HOST_BIO_MAX_LENGTH,
  HOST_COMPANY_MAX_LENGTH,
  HOST_COMPANY_MIN_LENGTH,
  HOST_HASHTAG_MAX_LENGTH,
  HOST_HASHTAG_MIN_LENGTH,
  HOST_MAX_FEE,
  HOST_MAX_FEE_INDEPENDENT,
  HOST_MAX_FEE_INTERNAL,
  HOST_MAX_PRICE,
  HOST_NAME_MAX_LENGTH,
  HOST_NAME_MIN_LENGTH,
  REGEX_ALPHABET_AND_SPACE,
  REGEX_ALPHANUMERIC_AND_UNDERSCORE,
  REGEX_ALPHANUMERIC_DOT_AND_SPACE,
  REGEX_NUMBER,
  TREE_MAX_BIO_CHARACTER,
  TREE_MAX_EVENT_DATE,
  TREE_MAX_NAME_CHARACTER,
  TREE_MIN_NAME_CHARACTER,
} from 'consts'
import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {getCurrencyValue, getValueOfCurrency} from 'utils'
import {getValidation} from '../Validation'

export const VALIDATION_HOST_REGISTRATION_COMPANY_NAME: InputFormRules = {
  required: true,
  maxLength: HOST_COMPANY_MAX_LENGTH,
  minLength: HOST_COMPANY_MIN_LENGTH,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_COMPANY_MAX_LENGTH,
    }),
    minLength: getValidation('tree:hostValidateMinCharacter', {
      count: HOST_COMPANY_MIN_LENGTH,
    }),
  },
  validate: {
    rule: (text) =>
      !text.match(REGEX_ALPHANUMERIC_DOT_AND_SPACE)
        ? translate('tree:hostRegistrationInvalidCompanyNameSpecialChar')
        : true,
  },
}

export const VALIDATION_TREE_REQUIRED: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('tree:validationRequired'),
  },
}

export const VALIDATION_TREE_MAX_PRICE = (
  minPrice: number,
): InputFormRules => ({
  validate: {
    maxReached: (text) =>
      getValueOfCurrency(text) > HOST_MAX_PRICE
        ? translate('tree:addHostInfoErrorMaxPrice', {
            price: getCurrencyValue(HOST_MAX_PRICE),
          })
        : true,
    moreThanMinPrice: (text) =>
      getValueOfCurrency(text) < minPrice
        ? translate('tree:addHostInfoErrorMaxPriceMoreThanMinPrice')
        : true,
  },
})

export const VALIDATION_TREE_MIN_PRICE = (
  maxPrice: number,
): InputFormRules => ({
  validate: {
    lessThanMaxPrice: (text) =>
      getValueOfCurrency(text) > maxPrice
        ? translate('tree:addHostInfoErrorMinPriceLessThanMaxPrice')
        : true,
  },
})

export const VALIDATION_TREE_HASHTAG: InputFormRules = {
  required: true,
  maxLength: HOST_HASHTAG_MAX_LENGTH,
  minLength: HOST_HASHTAG_MIN_LENGTH,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_HASHTAG_MAX_LENGTH,
    }),
    minLength: getValidation('tree:hostValidateMinCharacter', {
      count: HOST_HASHTAG_MIN_LENGTH,
    }),
  },
  validate: {
    specialChar: (text) =>
      !text.match(REGEX_ALPHANUMERIC_AND_UNDERSCORE)
        ? translate('tree:addHostInfoErrorHashtagContainSpecialChar')
        : true,
  },
}

export const VALIDATION_TREE_HOST_FEE: InputFormRules = {
  validate: {
    maxFee: (text) =>
      parseInt(text, 10) > HOST_MAX_FEE
        ? translate('tree:addHostInfoErrorMaxFee', {
            fee: HOST_MAX_FEE,
          })
        : true,
  },
}

export const VALIDATION_TREE_HOST_FEE_INDEPENDENT: InputFormRules = {
  validate: {
    maxFee: (text) =>
      parseInt(text, 10) > HOST_MAX_FEE_INDEPENDENT
        ? translate('tree:addHostInfoErrorMaxFee', {
            fee: HOST_MAX_FEE_INDEPENDENT,
          })
        : true,
  },
}

export const VALIDATION_TREE_HOST_FEE_INTERNAL: InputFormRules = {
  validate: {
    maxFee: (text) =>
      parseInt(text, 10) > HOST_MAX_FEE_INTERNAL
        ? translate('tree:addHostInfoErrorMaxFee', {
            fee: HOST_MAX_FEE_INTERNAL,
          })
        : true,
  },
}

export const VALIDATION_TREE_HOST_ACCOUNT_HOLDER: InputFormRules = {
  required: true,
  maxLength: HOST_ACCOUNT_HOLDER_MAX_LENGTH,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_ACCOUNT_HOLDER_MAX_LENGTH,
    }),
  },
  validate: {
    alphabet: (text) =>
      !text.match(REGEX_ALPHABET_AND_SPACE)
        ? translate('tree:hostRegistrationInvalidAccountHolderMustAlphabet')
        : true,
  },
}

export const VALIDATION_TREE_HOST_ACCOUNT_NUMBER: InputFormRules = {
  required: true,
  maxLength: HOST_BANK_NUMBER_MAX_LENGTH,
  minLength: HOST_BANK_NUMBER_MIN_LENGTH,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_BANK_NUMBER_MAX_LENGTH,
    }),
    minLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_BANK_NUMBER_MIN_LENGTH,
    }),
  },
}

export const VALIDATION_TREE_HOST_BIODATA: InputFormRules = {
  maxLength: HOST_BIO_MAX_LENGTH,
  messages: {
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_BIO_MAX_LENGTH,
    }),
  },
}

export const VALIDATION_TREE_HOST_NAME: InputFormRules = {
  required: true,
  maxLength: HOST_NAME_MAX_LENGTH,
  minLength: HOST_NAME_MIN_LENGTH,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: HOST_NAME_MAX_LENGTH,
    }),
    minLength: getValidation('tree:hostValidateMinCharacter', {
      count: HOST_NAME_MIN_LENGTH,
    }),
  },
  validate: {
    specialChar: (text) =>
      !text.match(REGEX_ALPHANUMERIC_DOT_AND_SPACE)
        ? translate('tree:addHostInfoErrorHostNameContainSpecialChar')
        : true,
  },
}

export const VALIDATION_TREE__PRICE_WITHDRAW: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('tree:validationWithdrawField'),
  },
  validate: {
    numeric: (text) =>
      !REGEX_NUMBER.test(text)
        ? translate('tree:validationWithdrawField')
        : true,
  },
}

export const VALIDATION_TREE_PLANT_FOTOTREE_NAME: InputFormRules = {
  required: true,
  maxLength: TREE_MAX_NAME_CHARACTER,
  minLength: TREE_MIN_NAME_CHARACTER,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: TREE_MAX_NAME_CHARACTER,
    }),
    minLength: getValidation('tree:hostValidateMinCharacter', {
      count: TREE_MIN_NAME_CHARACTER,
    }),
  },
}

export const VALIDATION_TREE_PLANT_FOTOTREE_BIO: InputFormRules = {
  required: true,
  maxLength: TREE_MAX_BIO_CHARACTER,
  messages: {
    required: getValidation('tree:validationRequired'),
    maxLength: getValidation('tree:hostValidateMaxCharacter', {
      count: TREE_MAX_BIO_CHARACTER,
    }),
  },
}

export const VALIDATION_TREE_PLANT_FOTOTREE_EVENT_DATE = (
  eventStartDate: string,
): InputFormRules => ({
  validate: {
    moreThanMaxDate: (text: string) => {
      const currDate = new Date(text)
      const maxDate = new Date(eventStartDate)
      maxDate.setDate(maxDate.getDate() + TREE_MAX_EVENT_DATE - 1)
      return currDate > maxDate
        ? translate('tree:eventDateEndErrorMoreThanMaxDate', {
            max: TREE_MAX_EVENT_DATE,
          })
        : true
    },
    lessThanStartDate: (text: string) => {
      const currDate = new Date(text)
      const startDate = new Date(eventStartDate)
      return currDate < startDate
        ? translate('tree:eventDateEndErrorLessThanStartDate')
        : true
    },
  },
})
