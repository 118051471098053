import {useContext} from 'react'
import {ExplorePaginationContext} from './ExplorePaginationContext'

export function useExplorePaginationContext() {
  return useContext(ExplorePaginationContext)!
}

export function useExplorePagination() {
  const statePagination = useExplorePaginationContext()

  return statePagination.stateExplorePagination
}
