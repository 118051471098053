import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ModalOnHoldHandler} from 'types'
import {setModalOnHoldHandler} from 'utils'
import {ModalOnHoldContext} from './ModalOnHoldContext'
import ModalOnHoldItem from './ModalOnHoldItem'
import {ModalOnHoldProviderProps} from './ModalOnHoldProps'

export default function ModalOnHoldProvider({
  children,
}: ModalOnHoldProviderProps) {
  const [visible, setVisible] = useState(false)

  const handleShowModalOnHold = useCallback((modal: boolean) => {
    setVisible(modal)
  }, [])

  const handleChooseAccount = useCallback(() => {
    setVisible((prev) => !prev)
    window.location.replace('/similar-choose-account')
  }, [])

  const handleCancel = useCallback(() => setVisible((prev) => !prev), [])

  const handleRenderModalOnHold = useMemo(
    () => (
      <ModalOnHoldItem visible={visible} onChooseAccount={handleChooseAccount} onCancel={handleCancel} />
    ),
    [handleCancel, handleChooseAccount, visible],
  )

  const handler = useMemo<ModalOnHoldHandler>(
    () => ({
      visible,
      showModalOnHold: handleShowModalOnHold,
    }),
    [handleShowModalOnHold, visible],
  )

  useEffect(() => setModalOnHoldHandler(handler), [handler])

  return (
    <ModalOnHoldContext.Provider value={handler}>
      {handleRenderModalOnHold}
      {children}
    </ModalOnHoldContext.Provider>
  )
}
