import React, {useCallback, useRef} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {GiftShopSharingPlatform} from 'types'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopShareContentModalProps} from './GiftShopShareContentPropsModal'

const StyledModal = styled(Modal)`
  width: ${convertUnit(325)};
  height: ${convertUnit(350)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: ${convertUnit(320)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    justify-content: center;
    align-items: center;
    line-height: 1.72;
    text-align: center;
    height: ${convertUnit(330)};
  }
`

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(35)};
    align-self: stretch;
    line-height: 1.77;
    text-align: center;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(25)};
    align-self: stretch;
    font-size: ${convertUnit(20)};
    line-height: 1;
    text-align: center;
  }
`

const StyledButtonContainer = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  border-radius: 0;
  height: ${convertUnit(60)};
  ${({theme}) => ({backgroundColor: theme.white_1})};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(50)};
    align-self: stretch;
    line-height: 1.72;
    text-align: center;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(27)};
    align-self: stretch;
    line-height: 1.72;
    text-align: center;
    margin-bottom: ${convertUnit(40)};
  }
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: ${convertUnit(270)};
  ${({theme}) => ({backgroundColor: theme.white_3})};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${convertUnit(290)};
    border-radius: ${convertUnit(30)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${convertUnit(260)};
    border-radius: ${convertUnit(30)};
    margin-bottom: ${convertUnit(30)};
  }
`

const StyledParagraph = styled(Paragraph)`
  padding-left: ${convertUnit(20)};
`

export default function GiftShopShareContentModal({
  onClickBackDrop,
  onCancel,
  dataContent,
}: GiftShopShareContentModalProps) {
  const contentUrl = useRef('')
  const {content_id} = dataContent
  const handleShare = useCallback(
    (platform: GiftShopSharingPlatform) => {
      requestData('giftshop_get_collection_share', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {content_ids: content_id},
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            contentUrl.current = data.result[0].url
          }
          switch (platform) {
            case 'facebook':
              window.open(
                `https://www.facebook.com/sharer/sharer.php?quote=${translate(
                  'giftShop:shareCollectionTextTemplateTwitterAndFacebook',
                )}&u=${contentUrl.current}`,
                '_blank',
                'toolbar=yes,scrollbars=yes, resizable=yes,top=500,left=500,width=800, height=600',
              )
              break

            case 'whatsapp':
              window.open(
                `https://wa.me/?text=${translate(
                  'giftShop:shareCollectionTextTemplateWhatsapp',
                )}${contentUrl.current}`,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800, height=600',
              )
              break

            case 'twitter':
              window.open(
                `https://twitter.com/intent/tweet?text=${translate(
                  'giftShop:shareCollectionTextTemplateTwitterAndFacebook',
                )}&url=${contentUrl.current}`,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800, height=600',
              )
              break
          }
        },
      })
    },
    [content_id],
  )

  return (
    <StyledModal visible onBackdropPress={onClickBackDrop}>
      <StyledTitle>
        <Paragraph fontWeight="bold" fontSize="l">
          {translate('giftShop:shareTo')}
        </Paragraph>
      </StyledTitle>
      <StyledButtonContainer onClick={() => handleShare('facebook')}>
        <StyledInnerContainer>
          <Icon type="facebook" />
          <StyledParagraph fontWeight="regular" fontSize="l">
            {translate('giftShop:facebook')}
          </StyledParagraph>
        </StyledInnerContainer>
      </StyledButtonContainer>
      <StyledButtonContainer onClick={() => handleShare('twitter')}>
        <StyledInnerContainer>
          <Icon type="twitter" />
          <StyledParagraph fontWeight="regular" fontSize="l">
            {translate('giftShop:twitter')}
          </StyledParagraph>
        </StyledInnerContainer>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <StyledInnerContainer onClick={() => handleShare('whatsapp')}>
          <Icon type="whatsapp" />
          <StyledParagraph fontWeight="regular" fontSize="l">
            {translate('giftShop:whatsapp')}
          </StyledParagraph>
        </StyledInnerContainer>
      </StyledButtonContainer>
      <StyledButton
        label={translate('global:close')}
        color="primary_5"
        onClick={onCancel}
      />
    </StyledModal>
  )
}
