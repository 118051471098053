import React from 'react'
import {useListLazyAction} from 'utils'
import {FlatlistLazyBase} from '../FlatlistLazyBase'
import {FlatlistLazyProps} from './FlatlistLazyProps'

export default function FlatlistLazy<ItemT, S>({
  data,
  limitPerPage,
  search,
  stateData,
  cache,
  loadData,
  keyExtractor,
  ...props
}: FlatlistLazyProps<ItemT, S>) {
  const {
    items,
    status,
    handleEndReached,
    handleLayoutDimension,
    handleContentSizeChange,
  } = useListLazyAction<ItemT, S>({
    data,
    cache,
    stateData,
    search,
    limitPerPage,
    loadData,
    keyExtractor,
  })

  return (
    <FlatlistLazyBase
      {...props}
      data={items}
      status={status}
      keyExtractor={keyExtractor}
      onEndReached={handleEndReached}
      onLayoutDimension={handleLayoutDimension}
      onContentSizeChange={handleContentSizeChange}
    />
  )
}
