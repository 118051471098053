import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {AvatarHighlight, Icon, Link, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopContentDetailTemplateHeaderProps} from './GiftShopContentDetailTemplateHeaderProps'

interface StyledParagraphProps {
  marginLeft: string
}

const StyledHeaderContainer = styled.div<React.CSSProperties>`
  position: static;
  display: flex;
  align-items: center;
  padding: ${({padding}) => padding};
  border-bottom: ${convertUnit(1)} solid;
  border-color: ${({theme}) => theme.gray_1};
`

const StyledBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledArtistInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledArtistInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)<StyledParagraphProps>`
  margin-left: ${({marginLeft}) => marginLeft};
`

export default function GiftShopContentDetailTemplateHeader({
  prevPage,
  profileUrl,
  username,
  chatButton,
  ...props
}: GiftShopContentDetailTemplateHeaderProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const history = useHistory()

  const handleRenderBackButton = useMemo(
    () => (
      <StyledBackButtonContainer>
        <Link to="giftshop_explore">
          <Icon type="back" size={convertUnit(16)} />
        </Link>
        <StyledParagraph
          fontSize="m"
          fontWeight="medium"
          marginLeft={convertUnit(15)}>
          {translate('global:backTo', {page: prevPage})}
        </StyledParagraph>
      </StyledBackButtonContainer>
    ),
    [prevPage, translate],
  )
  const handleRenderUserInfo = useMemo(
    () => (
      <StyledArtistInfoContainer>
        <StyledArtistInfo>
          <AvatarHighlight
            src={profileUrl}
            alt={username}
            size={mode === 'mobile' ? convertUnit(24) : convertUnit(32)}
          />
          <StyledParagraph
            fontSize="l"
            fontWeight="bold"
            marginLeft={mode === 'mobile' ? convertUnit(10) : convertUnit(16)}>
            {username}
          </StyledParagraph>
        </StyledArtistInfo>
        <Icon
          type="message"
          size={mode === 'mobile' ? convertUnit(14) : convertUnit(25)}
          marginRight={mode === 'mobile' ? 0 : convertUnit(10)}
          hidden={!chatButton}
          onClick={() => history.push('giftshop_message', {})}
        />
      </StyledArtistInfoContainer>
    ),
    [chatButton, history, mode, profileUrl, username],
  )

  return (
    <StyledHeaderContainer
      padding={
        mode === 'mobile'
          ? `${convertUnit(16)} ${convertUnit(25)} ${convertUnit(16)}`
          : `${convertUnit(16)} ${convertUnit(50)} ${convertUnit(16)}`
      }
      {...props}>
      {mode !== 'website' ? handleRenderUserInfo : handleRenderBackButton}
    </StyledHeaderContainer>
  )
}
