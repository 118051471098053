import {LogoutModalHandler} from 'types'

let handler: LogoutModalHandler | undefined

export function setLogoutModalHandler(instance: LogoutModalHandler) {
  handler = instance
}

export function showLogoutModal() {
  handler && handler.showLogoutModal()
}
