import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {clearUserCache} from 'utils'
import {
  CheckboxItem,
  ConfirmationModal,
  Icon,
  Paragraph,
  View,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopCartContentHeaderProps} from './GiftShopCartContentHeaderProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    borderBottom: `solid ${convertUnit(1)} ${theme.gray_1}`,
  })}
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(25)};
  align-items: center;
`

const StyledSelectAll = styled(Paragraph)`
  flex: 1;
  margin: 0 ${convertUnit(25)};
`

const StyledDeleteContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
`

export default function GiftShopCartContentHeader({
  stateData,
  stateSelect,
}: GiftShopCartContentHeaderProps) {
  const {translate} = useTranslation()
  const [data, setData] = stateData
  const [select, setSelect] = stateSelect
  const [modalVisible, setModalVisible] = useState(false)

  const isAllSelected = useMemo(() => {
    if (data.length) {
      for (const item of data) {
        if (!select.includes(item)) {
          return false
        }
      }

      return true
    }

    return false
  }, [data, select])

  const handleSelect = useCallback(() => setSelect(isAllSelected ? [] : data), [
    data,
    isAllSelected,
    setSelect,
  ])

  const handleDelete = useCallback(() => {
    requestData('giftshop_delete_cart', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {content_ids: select.map((item) => item.content_id)},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          clearUserCache('cart')
          setData(data.filter((item) => !select.includes(item)))
          setSelect([])
          location.reload()
        }
      },
    })
  }, [data, select, setData, setSelect])

  const handleRenderDelete = useMemo(
    () =>
      select.length ? (
        <StyledDeleteContainer
          className="pressable"
          onClick={() => setModalVisible((previous) => !previous)}>
          <Icon type="trash" color="gray_1" size={24} />
        </StyledDeleteContainer>
      ) : null,
    [select],
  )

  return (
    <>
      <ConfirmationModal
        title={translate('giftShop:removeContents')}
        text={translate('giftShop:removeContentsMessage', {
          count: select.length,
        })}
        onConfirm={handleDelete}
        visible={modalVisible}
        confirmText={translate('global:remove')}
        cancelText={translate('global:cancel')}
        toggleModal={() => setModalVisible((previous) => !previous)}
      />
      <StyledContainer>
        <StyledCheckboxItem isSelected={isAllSelected} onClick={handleSelect} />
        <StyledSelectAll color="gray_3" fontSize="m" fontWeight="medium">
          {translate('global:selectAll')}
        </StyledSelectAll>
        {handleRenderDelete}
      </StyledContainer>
    </>
  )
}
