import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {Icon, Image, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {IMAGE_ASSET} from 'consts'
import {getThemeColor} from 'utils'

interface ExampleImageContainerProps {
  goodExample: boolean
}

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledExampleTitle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledExample = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(10)};
`
const StyledExampleImage = styled(Image)`
  width: ${convertUnit(75)};
`
const StyledExampleImageContainer = styled.div<ExampleImageContainerProps>`
  background-color: ${({theme, goodExample}) => 
    goodExample ? theme.success_5 : theme.danger_5};
  border-radius: ${convertUnit(8)};
`
const StyledExampleContainer = styled.div`
  margin-top: ${convertUnit(20)};
`
const StyledExampleItemContainer = styled.div`
  margin-top: ${convertUnit(12)};
`
const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 ${convertUnit(4)} 0;
`
const StyledTitleContainer = styled.div`
  max-width: ${convertUnit(325)};
  flex-direction: column;
  margin: ${convertUnit(20)} auto;
  z-index: 1;
`

export interface GiftShopRobopetRetakeSelfieOverlayProps {
  onClick(): void
}
export default function GiftShopRobopetRetakeSelfieOverlay({
  onClick,
}: GiftShopRobopetRetakeSelfieOverlayProps) {
  const {translate} = useTranslation()
  const exampleGood1 = IMAGE_ASSET('giftshop', 'ex-do-1.png')
  const exampleGood2 = IMAGE_ASSET('giftshop', 'ex-do-2.png')
  const exampleGood3 = IMAGE_ASSET('giftshop', 'ex-do-3.png')
  const exampleGood4 = IMAGE_ASSET('giftshop', 'ex-do-4.png')
  const exampleGood5 = IMAGE_ASSET('giftshop', 'ex-do-5.png')
  const exampleBad = IMAGE_ASSET('giftshop', 'ex-dont-3.png')

  const renderExampleItem = useCallback((src: string, goodExample: boolean) => (
    <StyledExampleImageContainer goodExample={goodExample}>
      <StyledExampleImage src={src} alt='' />
      <StyledTextContainer>
        <Icon type={goodExample ? 'check' : 'close'} color="white_1" size={12} />
        <StyledParagraph color="white_1" fontWeight="bold">{translate(goodExample ? 'global:good' : 'global:bad')}</StyledParagraph>
      </StyledTextContainer>
    </StyledExampleImageContainer>
  ), [translate])

  const renderExample = useMemo(() => (
    <StyledExampleContainer>
      <StyledExampleTitle>
        <StyledParagraph fontWeight="bold" fontSize="l" color="white_1">
          {translate('giftShop:retakeKYCExample')}
        </StyledParagraph>
      </StyledExampleTitle>
      <StyledExampleItemContainer>
        <StyledExample>
          {renderExampleItem(exampleBad, false)}
          {renderExampleItem(exampleGood1, true)}
          {renderExampleItem(exampleGood2, true)}
        </StyledExample>
      </StyledExampleItemContainer>
      <StyledExampleItemContainer>
        <StyledExample>
          {renderExampleItem(exampleGood3, true)}
          {renderExampleItem(exampleGood4, true)}
          {renderExampleItem(exampleGood5, true)}
        </StyledExample>
      </StyledExampleItemContainer>
    </StyledExampleContainer>
  ), [exampleBad, exampleGood1, exampleGood2, exampleGood3, exampleGood4, exampleGood5, renderExampleItem, translate])
  return (
    <StyledContainer onClick={onClick}>
      <StyledTitleContainer>
        <StyledParagraph color="white_1" fontWeight="medium" fontSize="xl">
          {translate('giftShop:kycFollowInstruction')}
        </StyledParagraph>
        <StyledParagraph color="white_1">
          {translate('giftShop:retakeKYCInstruction')}
        </StyledParagraph>
      </StyledTitleContainer>
      {renderExample}
    </StyledContainer>
  )
}
