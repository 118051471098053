import {useCallback, useMemo} from 'react'
import {
  GiftShopUploadModalData,
  GiftShopUploadType,
  GiftShopUploadVideoSendToFaceFormData,
  ObjectState,
} from 'types'
import {showSnackbar} from 'utils'
import {
  extractVideoFrameBlobs,
  getVideoTimeFrames,
  loadVideoMetadata,
} from 'utils/video'
import {
  GIFTSHOP_UPLOAD_VIDEO_LIMIT,
  GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME,
} from 'consts'
import {useTranslation} from 'i18n'
import {zipBlobs} from 'lib/zip'

export function useGiftShopVideoAutoPreview({
  stateModalStatus,
  stateVideoProcessStatus,
  stateUploadType,
  onLoadFileSeries,
  loadVideoFaceFile,
}: {
  stateVideoProcessStatus: ObjectState<GiftShopUploadModalData>
  stateModalStatus: ObjectState<GiftShopUploadModalData>
  stateUploadType: ObjectState<GiftShopUploadType>
  loadVideoFaceFile: (
    file: File,
  ) => Promise<GiftShopUploadVideoSendToFaceFormData | null | undefined>
  onLoadFileSeries: (
    file: FileList | File[],
    seriesId?: string,
    uploadType?: GiftShopUploadType,
    videoFaces?: GiftShopUploadVideoSendToFaceFormData[],
  ) => void
}) {
  const {translate} = useTranslation()
  const setVideoProcessStatus = stateVideoProcessStatus[1]
  const setModalStatus = stateModalStatus[1]
  const setUploadType = stateUploadType[1]

  const handleUploadVideo = useCallback(
    (
      fileList: File[],
      videoFaces: GiftShopUploadVideoSendToFaceFormData[],
      seriesId?: string,
    ) => {
      setVideoProcessStatus((prev) => ({
        ...prev,
        isLoading: false,
      }))
      setModalStatus((prev) => ({
        ...prev,
        isLoading: true,
      }))
      setUploadType('video')
      onLoadFileSeries(fileList, seriesId, 'video', videoFaces)
    },
    [onLoadFileSeries, setModalStatus, setUploadType, setVideoProcessStatus],
  )

  const handlePreprocessVideos = useCallback(
    async (
      uploadedVideos: File[],
      seriesId?: string,
      available: number = GIFTSHOP_UPLOAD_VIDEO_LIMIT,
    ) => {
      if (uploadedVideos.length > available) {
        showSnackbar(
          translate('giftShop:uploadMaximumVideoMessage', {
            max: GIFTSHOP_UPLOAD_VIDEO_LIMIT,
          }),
        )
      }

      if (available === 0) return

      const videos = uploadedVideos.slice(0, available)

      setVideoProcessStatus((prev) => ({
        ...prev,
        isLoading: true,
        file_loaded: 0,
        total_file: videos.length,
      }))
      setModalStatus((prev) => ({
        ...prev,
        file_loaded: 0,
        total_file: 0,
      }))

      const previews: File[] = []
      const sendToFaces: GiftShopUploadVideoSendToFaceFormData[] = []

      let index = 0
      for (const vid of videos) {
        // eslint-disable-next-line no-await-in-loop
        const metadata = await loadVideoMetadata(vid)

        if (metadata) {
          const {duration} = metadata
          const timestamps = getVideoTimeFrames({
            duration,
          })
          // eslint-disable-next-line no-await-in-loop
          const result = await extractVideoFrameBlobs({
            file: vid,
            timestamps,
            metadata,
            format: 'image/jpeg',
            maxDimension: 1440,
            quality: 0.8,
          })
          if (result) {
            const stfFile = new File([result[0]], 'sendToFace.jpeg', {
              type: 'image/jpeg',
            })

            // eslint-disable-next-line no-await-in-loop
            const sendToFace = await loadVideoFaceFile(stfFile)

            if (sendToFace) {
              // eslint-disable-next-line no-await-in-loop
              const zipContents = await zipBlobs({
                blobs: result,
                nameExtractor: (_, i) => `${i}.jpeg`,
              })
              const zipFile = new File(
                [zipContents],
                `${vid.name.split('.')[0]}.mp4`,
                {
                  type: GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME,
                },
              )

              previews.push(zipFile)
              sendToFaces.push(sendToFace)
              // eslint-disable-next-line no-loop-func
              setVideoProcessStatus((prev) => ({
                ...prev,
                file_loaded: index,
              }))
              // eslint-disable-next-line no-loop-func
              setModalStatus((prev) => ({
                ...prev,
                file_loaded: 0,
                total_file: index,
              }))
              index += 1
            }
          }
        }
      }

      handleUploadVideo(previews, sendToFaces, seriesId)
    },
    [
      handleUploadVideo,
      loadVideoFaceFile,
      setModalStatus,
      setVideoProcessStatus,
      translate,
    ],
  )

  return useMemo(
    () => ({
      handlePreprocessVideos,
    }),
    [handlePreprocessVideos],
  )
}
