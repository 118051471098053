import React, {useState} from 'react'
import styled from 'styled-components'
import {LANGUAGES} from 'consts'
import {useDidUpdate} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Icon} from '../Icon'
import {ToggleSwitch} from '../ToggleSwitch'

const StyledLanguageToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  align-items: center;
`

export default function LanguageToggle() {
  const idLang = LANGUAGES[0]
  const enLang = LANGUAGES[1]
  const {update} = useDispatch()
  const {lang} = useSelector('languageState')
  const [isEnLang, setIsEnLang] = useState(lang === 'en')

  useDidUpdate(() => {
    const newLang = isEnLang ? enLang.languageTag : idLang.languageTag
    update('languageState', {
      lang: newLang,
    })
  }, [isEnLang, enLang.languageTag, idLang.languageTag, update])

  return (
    <StyledLanguageToggleContainer>
      <Icon type="globe" color="primary_5" size={convertUnit(20)} />
      <ToggleSwitch
        onSwitch={() => setIsEnLang((prev) => !prev)}
        selected={isEnLang}
        alwaysOn
        isOffBackgroundColor="primary_1"
        isOnBackgroundColor="primary_1"
        toggleColor="primary_5"
        textOff={idLang.languageTag.toUpperCase()}
        textOn={enLang.languageTag.toUpperCase()}
      />
    </StyledLanguageToggleContainer>
  )
}
