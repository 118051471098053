import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateVerificationMethodItemProps} from './GiftShopTemplateVerificationMethodModalProps'

interface StyledContainerProps {
  selected: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, selected}) => ({
    borderColor: selected ? theme.primary_5 : theme.white_1,
  })}
  border-style: solid;
  border-width: ${convertUnit(2)};
  box-sizing: border-box;
  padding: ${convertUnit(16)} ${convertUnit(20)};
  border-radius: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const StyledLeftItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export default function GiftShopTemplateVerificationMethodItem({
  icon,
  info,
  selected,
  text,
  onClick,
}: GiftShopTemplateVerificationMethodItemProps) {
  return (
    <StyledContainer selected={selected} onClick={onClick}>
      <StyledLeftItems>
        <Icon type={icon} size={convertUnit(40)} color="primary_5" />
        <StyledTextContainer>
          <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
            {text}
          </Paragraph>
          <Paragraph fontWeight="medium" color="gray_3">
            {info}
          </Paragraph>
        </StyledTextContainer>
      </StyledLeftItems>
    </StyledContainer>
  )
}
