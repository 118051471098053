import {lazy} from 'react'

const GiftShopSearchCreatorAndFotoTreeScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'SearchCreatorAndFotoTree' */ './GiftShopSearchCreatorAndFotoTreeScreen'
    ),
)

export {GiftShopSearchCreatorAndFotoTreeScreen}
