import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export interface GiftShopMessageRoomContentItemNotificationHostCreatorPromotionProps {
  payload: FirebaseMessageRoomItemPayload['notification_promotion_host_creator']
}

export default function GiftShopMessageRoomContentItemNotificationHostCreatorPromotion({
  payload: {host_tag, username, host_leader_username, creator_type},
}: GiftShopMessageRoomContentItemNotificationHostCreatorPromotionProps) {
  const {translate} = useTranslation()
  const history = useHistory()

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('messages:openInvitationButton')}
        onClick={() => history.push('tree_host_notification', {})}
      />
    ),
    [history, translate],
  )

  return (
    <StyledContainer>
      <div>
        <Paragraph fontSize="m">
          {translate('messages:hiUsername', {username})}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationCreatorOfficialCreatorInvitation', {
            host_leader_username,
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          })}
        </Paragraph>
      </div>
      {handleRenderButton}
    </StyledContainer>
  )
}
