import React from 'react'
import styled from 'styled-components'
import {getBoxShadow} from 'utils'
import {
  GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {ThemeColor} from 'themes'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {GiftShopRootBottomNavBarProps} from './GiftShopRootBottomNavBarProps'
import {GiftShopRootBottomNavBarItem} from '../BottomNavBarItem'

interface StyledSectionNavBarProps {
  darkBar?: boolean
  darkBarColor?: ThemeColor
}

const StyledSectionNavBar = styled.div<StyledSectionNavBarProps>`
  display: flex;
  position: sticky;
  width: 100%;
  height: ${convertUnit(GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT)};
  justify-content: center;
  box-shadow: ${({theme}) => getBoxShadow(theme, {verticalOffset: -4})};
  background-color: ${({theme, darkBar, darkBarColor = 'gray_9'}) =>
    darkBar ? theme[darkBarColor] : theme.white_1};
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(WINDOW_MODE_MOBILE_WIDTH)};
  align-self: center;
`

export default function GiftShopRootBottomNavBar({
  select,
  data,
  darkBar,
  darkBarColor,
}: GiftShopRootBottomNavBarProps) {
  const mode = useWindowMode()

  return (
    <StyledSectionNavBar darkBar={darkBar} darkBarColor={darkBarColor}>
      <StyledContainer>
        {data.primary.map(({key, icon, label, onClick}) => (
          <GiftShopRootBottomNavBarItem
            key={key}
            icon={icon}
            label={label}
            isSelected={select === key}
            darkBar={darkBar}
            onClick={() => (select === key ? undefined : onClick && onClick())}
          />
        ))}
        {mode === 'website' &&
          data.secondary.map(({key, icon, label, onClick}) => (
            <GiftShopRootBottomNavBarItem
              key={key}
              icon={icon}
              label={label}
              isSelected={select === key}
              darkBar={darkBar}
              onClick={() =>
                select === key ? undefined : onClick && onClick()
              }
            />
          ))}
      </StyledContainer>
    </StyledSectionNavBar>
  )
}
