import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {
  Button,
  Icon,
  Image,
  ModalFullscreen,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopEditProfilePasswordVerifyModalProps} from './GiftShopEditProfileModalProps'

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledContainer = styled(StyledColumnContainer)`
  width: 100%;
  max-width: ${convertUnit(375)};
  margin: 0 auto;
  padding: ${convertUnit(20)};
`

const StyledHeaderContainer = styled(StyledColumnContainer)`
  gap: ${convertUnit(8)};
`

const StyledMainContainer = styled(StyledColumnContainer)`
  text-align: center;
  gap: ${convertUnit(20)};
`

const StyledIconContainer = styled.div`
  cursor: pointer;
  padding: ${convertUnit(20)} 0;
  margin-right: auto;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  width: 100%;
  max-width: ${convertUnit(200)};
  aspect-ratio: 1;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopEditProfilePasswordVerifyModal({
  visible,
  item,
  onCancel,
  onConfirm,
}: GiftShopEditProfilePasswordVerifyModalProps) {
  const {translate} = useTranslation()
  const image = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <ModalFullscreen visible={visible}>
      <StyledContainer>
        <StyledIconContainer>
          <Icon type="back" color="primary_5" onClick={onCancel} />
        </StyledIconContainer>
        <StyledMainContainer>
          <StyledHeaderContainer>
            <Paragraph fontSize="xl" fontWeight="bold">
              {translate('global:sorry')}
            </Paragraph>
            <Paragraph fontSize="m">
              {translate('giftShop:editProfileVerifyPasswordCaption')}
            </Paragraph>
          </StyledHeaderContainer>
          <StyledImage src={image} alt={image} />
          <Paragraph fontSize="m">
            {translate('giftShop:editProfileVerifyPasswordDescription', {
              context: item,
            })}
          </Paragraph>
          <StyledButton
            label={translate('giftShop:managePasswordHeader')}
            onClick={onConfirm}
          />
          <StyledButton
            label={translate('global:cancel')}
            color="primary_5"
            backgroundColor="white_3"
            onClick={onCancel}
          />
        </StyledMainContainer>
      </StyledContainer>
    </ModalFullscreen>
  )
}
