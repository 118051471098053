import { LANGUAGES } from 'consts/language'
import { LanguageData } from 'types'

export function getLanguages(search = ''): LanguageData[] {
  const loweredSearch = search.toLowerCase()
  const languages: LanguageData[] = []

  LANGUAGES.forEach((language) => {
    const tag = language.languageTag.toLowerCase()
    const race = language.race?.toLowerCase()
    const title = language.title.toLowerCase()

    if (
      search === '' ||
      tag.includes(loweredSearch) ||
      title.includes(loweredSearch) ||
      race.includes(loweredSearch)
    ) {
      languages.push(language)
    }
  })

  return languages
}
