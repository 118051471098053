import React from 'react'
import styled from 'styled-components'
import {BUTTON_SIZE_MAP} from 'consts'
import convertUnit from 'lib/unit'
import {ButtonIconProps} from './ButtonIconProps'
import {Icon} from '../Icon'
import {Button} from '../Button'
import {Paragraph} from '../Paragraph'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLabel = styled(Paragraph)`
  margin-left: ${convertUnit(12)};
`

export default function ButtonIcon({
  type = 'normal',
  iconType,
  iconColor = 'gray_1',
  iconStyle,
  hasIconShadow,
  hasShadow = true,
  label,
  disabled = false,
  disableColor = 'gray_3',
  color = 'white_1',
  ...props
}: ButtonIconProps) {
  return (
    <StyledButton
      {...props}
      type={type}
      width={label ? 'fit-content' : BUTTON_SIZE_MAP[type]}
      disabled={disabled}
      hasShadow={hasShadow}>
      <Icon
        type={iconType}
        size={convertUnit(BUTTON_SIZE_MAP[type] * 0.5 - 1)}
        color={iconColor}
        style={iconStyle}
        hasShadow={hasIconShadow}
      />
      {label && (
        <StyledLabel
          fontSize="s"
          fontWeight="bold"
          color={disabled ? disableColor : color}>
          {label}
        </StyledLabel>
      )}
    </StyledButton>
  )
}
