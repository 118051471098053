import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  Button,
  ButtonOutline,
  Icon,
  List,
  ModalLoading,
  Paragraph,
} from 'common/components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {
  ObjectState,
  UserSimilarAccountData,
  UserSimilarAccountPayload,
} from 'types'
import {showSnackbar, useDidMount, useDidUpdate, useHistory} from 'utils'
import {
  GiftShopTemplateModalOtherAccount,
  GiftShopTemplateSimilarAccountItem,
} from '../../template'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  background-color: ${({theme}) => theme.white_3};
`
const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${convertUnit(560)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    height: 100%;
  }
  background-color: ${({theme}) => theme.white_1};
`
const StyledHeader = styled.div`
  padding: ${convertUnit(20)} ${convertUnit(20)} 0 ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledButtonOutline = styled(ButtonOutline)`
  width: 100%;
`
const StyledDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
  align-items: center;
`
const StyledSelfSelectContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
`
const StyledSelfSelectContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`
const StyledDeleteButtonContainer = styled.div`
  display: flex;
  gap: ${convertUnit(4)};
`

interface GiftShopSimilarChooseAccountSelfProps {
  stateFinished: ObjectState<boolean>
}

export default function GiftShopSimilarChooseAccountSelf({
  stateFinished,
}: GiftShopSimilarChooseAccountSelfProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const [finished, setFinished] = stateFinished
  const [headerData, setHeaderData] = useState<UserSimilarAccountData>()
  const [data, setData] = useState<ReadonlyArray<UserSimilarAccountData>>([])
  const [selectedData, setSelectedData] = useState<UserSimilarAccountData>()
  const {update} = useDispatch()
  const stateErrorMessage = useState('')
  const setErrorMessage = stateErrorMessage[1]
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const history = useHistory()
  const [isDeleteAll, setIsDeleteAll] = useState(false)
  const deleteCount = useRef(0)
  const skipCount = useRef(0)
  const [modalLoading, setModalLoading] = useState(false)

  const handleContinue = useCallback(() => {
    setModalLoading(true)
    requestData('giftshop_similar_patch_account', {
      onRequestReceived: () => {
        setModalLoading(false)
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          update('similarAccountStatus', {status: 'CHOOSE_SELF'})
          setFinished(true)
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
      onRequestFailed: (reason) => {
        showSnackbar(translate('global:messageError', {context: reason}))
      },
    })
  }, [setFinished, translate, update])

  const handleLoadData = useCallback(async () => {
    deleteCount.current = 0
    skipCount.current = 0
    const response = await requestData('giftshop_similar_get_account')
    if (typeof response !== 'string' && response.status === 200) {
      const getSelfData = response.data.result.filter((x) => x.id === id)[0]
      setHeaderData(getSelfData)
      response.data.result.splice(response.data.result.indexOf(getSelfData), 1)
      response.data.result.map((x) => {
        if (x.is_removed) deleteCount.current += 1
        if (x.is_skipped) skipCount.current += 1
      })
      setData(response.data.result)
      return response.data.result
    }
    return null
  }, [id])

  const handleDelete = useCallback((item: UserSimilarAccountData) => {
    setSelectedData(item)
  }, [])

  const handleSubmitNotMe = useCallback(
    (item: UserSimilarAccountData) => {
      setModalLoading(true)
      requestData('giftshop_similar_skip_account', {
        data: {id: item.id},
        onRequestReceived: () => {
          setModalLoading(false)
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) =>
              prev.map((x) =>
                x.id === item.id ? {...x, is_skipped: true} : x,
              ),
            )
            skipCount.current += 1
          } else {
            showSnackbar(translate('global:messageError', {context: status}))
          }
        },
      })
    },
    [translate],
  )

  const handleSubmit = useCallback(
    (item: UserSimilarAccountPayload) => {
      setLoading(true)
      requestData('giftshop_similar_delete_other', {
        data: {id: item.id, identifier: item.identifier},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestSuccess: ({status, data: result}) => {
          if (status === 200) {
            setData((prev) =>
              prev.map((x) =>
                x.id === item.id ? {...x, is_removed: true} : x,
              ),
            )
            deleteCount.current += 1
            setSelectedData(undefined)
            showSnackbar(translate('giftShop:similarAccountDeleteSuccess'))
          } else if (status === 400 && result.detail !== undefined) {
            if (result.detail.identifier === 'invalid') {
              setErrorMessage(
                translate('giftShop:similarOtherAccountFailedMessage', {
                  context: item.method,
                }),
              )
            } else {
              showSnackbar(
                translate('global:messageError', {context: `code-${status}`}),
              )
            }
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
        onRequestFailed: (reason) => {
          showSnackbar(translate('global:messageError', {context: reason}))
        },
      })
    },
    [setErrorMessage, setLoading, translate],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeader>
        <Paragraph fontSize="l" fontWeight="medium">
          {translate('giftShop:similarAccountDescSelf')}
        </Paragraph>
        {headerData && (
          <GiftShopTemplateSimilarAccountItem
            data={headerData}
            showCurrentLabel
            rightComponent={
              <Paragraph fontWeight="bold" color="success_5">
                {translate('global:activated')}
              </Paragraph>
            }
          />
        )}
        <Paragraph fontSize="l" fontWeight="medium">
          {translate('giftShop:similarAccountDeleteOthersDesc')}
        </Paragraph>
      </StyledHeader>
    ),
    [headerData, translate],
  )

  const handleRenderItem = useCallback(
    (item: UserSimilarAccountData) => (
      <GiftShopTemplateSimilarAccountItem
        data={item}
        showCurrentLabel={false}
        rightComponent={
          item.is_removed ? (
            <Paragraph fontWeight="bold" color="danger_5">
              {translate('global:deleted')}
            </Paragraph>
          ) : item.is_skipped ? (
            <Paragraph fontWeight="bold" color="gray_5">
              {translate('global:waitingAdmin')}
            </Paragraph>
          ) : (
            <StyledDeleteContainer>
              <Paragraph fontWeight="medium" color="gray_5">
                {translate('giftShop:similarAccountSelfConfirm')}
              </Paragraph>
              <StyledDeleteButtonContainer>
                <StyledButtonOutline
                  label={translate('global:notMe')}
                  borderColor="gray_5"
                  borderWidth={2}
                  color="gray_5"
                  onClick={() => handleSubmitNotMe(item)}
                />
                <StyledButton
                  label={translate('global:yesDelete')}
                  onClick={() => handleDelete(item)}
                />
              </StyledDeleteButtonContainer>
            </StyledDeleteContainer>
          )
        }
      />
    ),
    [handleDelete, handleSubmitNotMe, translate],
  )

  const handleBack = useCallback(() => {
    if (isDeleteAll) {
      update('yuserActivationState', {kycStatus: 'verified'})
    }
    history.push('giftshop_explore', {})
  }, [history, isDeleteAll, update])

  const handleFinishModal = useMemo(
    () =>
      finished ? (
        <StyledSelfSelectContainer>
          <StyledSelfSelectContentContainer>
            <Icon type="check" color="success_5" size={48} />
            <Paragraph fontWeight="bold" fontSize="xl">
              {translate('tree:hostRegistrationProcessModalTitle')}
            </Paragraph>
            <Paragraph fontSize="l">
              {translate(
                isDeleteAll
                  ? 'giftShop:similarAccountSelfVerified'
                  : 'giftShop:similarChooseAccountSelfText',
              )}
            </Paragraph>
          </StyledSelfSelectContentContainer>
          <Button onClick={handleBack}>{translate('global:ok')}</Button>
        </StyledSelfSelectContainer>
      ) : (
        <></>
      ),
    [finished, handleBack, isDeleteAll, translate],
  )

  const handleRenderContent = useMemo(
    () =>
      !finished && (
        <StyledContainer>
          <StyledContentContainer>
            <List
              data={data}
              keyExtractor={(item) => item.id}
              renderItem={handleRenderItem}
              listHeaderElement={handleRenderHeader}
              scrollbar={false}
              contentContainerStyle={{
                paddingLeft: convertUnit(20),
                paddingRight: convertUnit(20),
              }}
            />
          </StyledContentContainer>
        </StyledContainer>
      ),
    [data, finished, handleRenderHeader, handleRenderItem],
  )

  const handleRenderVerifModal = useMemo(
    () =>
      selectedData !== undefined && (
        <GiftShopTemplateModalOtherAccount
          data={selectedData}
          onCancel={() => {
            setSelectedData(undefined)
            setErrorMessage('')
          }}
          stateError={stateErrorMessage}
          stateLoading={stateLoading}
          onConfirm={handleSubmit}
        />
      ),
    [
      handleSubmit,
      selectedData,
      setErrorMessage,
      stateErrorMessage,
      stateLoading,
    ],
  )
  const handleRenderModalLoading = useMemo(() => (
    <ModalLoading visible={modalLoading} />
  ), [modalLoading])

  useDidUpdate(() => {
    if (deleteCount.current + skipCount.current === data.length) {
      setIsDeleteAll(deleteCount.current === data.length)
      handleContinue()
    }
  }, [data])

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderModalLoading}
      {handleFinishModal}
      {handleRenderVerifModal}
      {handleRenderContent}
    </>
  )
}
