import React from 'react'
import styled from 'styled-components'
import {getThemeColor} from 'utils'
import convertUnit from 'lib/unit'

const StyledMarker = styled.div`
  position: relative;
  width: ${convertUnit(37)};
  height: ${convertUnit(37)};
`

const StyledOuterCircle = styled.div`
  position: absolute;
  border-radius: ${convertUnit(37)};
  width: ${convertUnit(37)};
  height: ${convertUnit(37)};
  filter: blur(1.5px);
  background-color: ${({theme}) => getThemeColor(theme, 'primary_5', 0.4)};
`

const StyledInnerCircle = styled.div`
  position: absolute;
  border-radius: ${convertUnit(12)};
  left: calc(50% - (${convertUnit(6)}));
  top: calc(50% - (${convertUnit(6)}));
  width: ${convertUnit(12)};
  height: ${convertUnit(12)};
  border: solid ${convertUnit(1.5)} ${({theme}) => theme.white_1};
  background-color: ${({theme}) => theme.primary_5};
`

export default function TreeSelfMarker() {
  return (
    <StyledMarker>
      <StyledOuterCircle />
      <StyledInnerCircle />
    </StyledMarker>
  )
}
