import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useHistory} from 'utils'

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const StyledDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(25)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateNotVerifiedUserModal() {
  const {translate} = useTranslation()
  const [edit, setEdit] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (edit) {
      window.location.pathname = '/settings/profile'
    }
  }, [edit, history])

  return (
    <StyledModal visible>
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate('global:additionalDataModalTitle')}
      </Paragraph>
      <StyledDescription fontSize="l">
        {translate('global:additionalDataModalDescription')}
      </StyledDescription>
      <StyledButton
        label={translate('global:additionalDataModalButton')}
        onClick={() => setEdit((prev) => !prev)}
      />
    </StyledModal>
  )
}
