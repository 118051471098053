import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUTH_LOGIN_MAX_ATTEMPT} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {logout, showSnackbar} from 'utils'
import {Button, Input, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {DeleteAccountConfirmationModalProps} from './DeleteAccountConfirmationModalProps'

const StyledButtonsContainer = styled.div`
  width: fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${convertUnit(20)};
  margin-top: ${convertUnit(12)};
`

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`
const StyledButton = styled(Button)`
  width: fill-available;
`

export default function DeleteAccountConfirmationModal({
  statePassword,
  modal,
  onCleanup,
}: DeleteAccountConfirmationModalProps) {
  const [password, setPassword] = statePassword
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {update} = useDispatch()
  const {has_password} = useSelector('user') || {}

  const handleSubmitForm = useCallback(() => {
    if (!has_password || password) {
      setLoading(true)
      requestData('giftshop_delete_account', {
        data: {password},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestFailed: () => {
          showSnackbar(translate('giftshop:deleteAccountFailed'))
        },
        onRequestSuccess: ({status, data: {message, detail}}) => {
          if (status === 200) {
            onCleanup()
            logout()
            update('lastUserState', {
              username: undefined,
              photo: undefined,
              refreshToken: undefined,
            })
            showSnackbar(translate('giftshop:deleteAccountSuccess'))
          } else if (status === 400 && detail) {
            if (message.includes('mismatch')) {
              setErrorMessage(
                translate('auth:validationLoginAttempt', {
                  attempt: AUTH_LOGIN_MAX_ATTEMPT - Number(detail.attempt),
                }),
              )
            }
          } else if (status === 403 && detail) {
            setErrorMessage('')
            if (detail.member_id.includes('host'))
              showSnackbar(translate('auth:deleteAccountAssociateHost'))
            else if (detail.member_id.includes('resend'))
              showSnackbar(translate('auth:deleteAccountOweResend'))
          } else if (status === 423) {
            onCleanup()
          }
        },
      })
    }
  }, [has_password, onCleanup, password, update])

  const handleClickEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && password !== '') {
        handleSubmitForm()
      }
    },
    [handleSubmitForm, password],
  )

  const handleRenderError = useMemo(
    () =>
      errorMessage && (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage}
        </StyledError>
      ),
    [errorMessage],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('giftshop:passwordConfirmation')}
        </StyledTitle>
        <Paragraph fontSize="m">
          {translate('giftshop:deleteAccountPrompt')}
        </Paragraph>
        <StyledParagraph fontWeight="medium" color="danger_5">
          {translate('giftshop:deleteAccountWarning')}
        </StyledParagraph>
        {has_password ? (
          <>
            <Input
              type="password"
              value={password}
              label={translate('global:password')}
              placeholder={translate('auth:inputPasswordConfirmPlaceholder')}
              rightIcon="visibility"
              onChangeText={setPassword}
              onKeyPress={handleClickEnter}
            />
            {handleRenderError}
          </>
        ) : (
          <></>
        )}
        <StyledButtonsContainer>
          <StyledButton
            label={translate('global:cancel')}
            color="primary_5"
            backgroundColor="white_3"
            onClick={onCleanup}
            width={convertUnit(125)}
          />
          <StyledButton
            label={translate('global:delete')}
            isLoading={loading}
            width={convertUnit(125)}
            onClick={handleSubmitForm}
            disabled={has_password ? password === '' : false}
          />
        </StyledButtonsContainer>
      </>
    ),
    [
      handleClickEnter,
      handleRenderError,
      handleSubmitForm,
      has_password,
      loading,
      onCleanup,
      password,
      setPassword,
    ],
  )

  return <StyledModal visible={modal}>{handleRenderContent}</StyledModal>
}
