import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {translate} from 'i18n'
import {TreeHostTemplateListHostMember, TreeHostTemplateIcon} from 'pages'
import {TreeHostCreatorData} from 'types'
import {showSnackbar, useDebounce} from 'utils'
import {Icon, Input, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeHostPromoteTemplateProps} from './TreeHostPromoteTemplateProps'

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
  width: 100%;
  height: ${convertUnit(570)};
  max-width: ${convertUnit(560)};
  background-color: ${({theme}) => theme.white_1};
  display: flex;
  border-radius: ${convertUnit(16)};
  justify-content: center;
  flex-direction: column;
  align-self: center;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    height: 100%;
  }
`

const StyledNotFoundContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })};
  height: ${convertUnit(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  padding-left: ${convertUnit(20)};
`

const StyledInputContainer = styled.div`
  margin-bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0 ${convertUnit(20)};
  }
`

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;

  overflow-x: scroll;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_1};
    width: ${convertUnit(5)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(7)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }
`

export default function TreeHostPromoteTemplate({
  stateSelectedAdmin,
  stateSelectedCreator,
  loadData,
  totalAdmin,
  type,
}: TreeHostPromoteTemplateProps) {
  const [search, setSearch] = useState('')
  const selectedAdmin = stateSelectedAdmin && stateSelectedAdmin[0]
  const setSelectedAdmin = stateSelectedAdmin && stateSelectedAdmin[1]
  const selectedCreator = stateSelectedCreator && stateSelectedCreator[0]
  const setSelectedCreator = stateSelectedCreator && stateSelectedCreator[1]
  const totalItem = useMemo(
    () =>
      (selectedAdmin && selectedAdmin.length) ||
      (selectedCreator && selectedCreator.length) ||
      0,
    [selectedAdmin, selectedCreator],
  )
  const debounce = useDebounce()

  const renderNotFound = useMemo(
    () => (
      <StyledNotFoundContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
          {translate('tree:hostManageAdminUsernameNotFound')}
        </Paragraph>
      </StyledNotFoundContainer>
    ),
    [],
  )

  const handleUncheckAdmin = useCallback(
    (item: TreeHostCreatorData) => {
      setSelectedAdmin &&
        setSelectedAdmin((previous) =>
          previous.find((value) => value.account_id === item.account_id)
            ? previous.filter(
                (selectedItem) => selectedItem.account_id !== item.account_id,
              )
            : [...previous, item],
        )
    },
    [setSelectedAdmin],
  )

  const handleUncheckCreator = useCallback(
    (item: TreeHostCreatorData) => {
      setSelectedCreator &&
        setSelectedCreator((previous) =>
          previous.find((value) => value.creator_id === item.creator_id)
            ? previous.filter(
                (selectedItem) => selectedItem.creator_id !== item.creator_id,
              )
            : [...previous, item],
        )
    },
    [setSelectedCreator],
  )

  const handleSelect = useCallback(
    (item: TreeHostCreatorData) => {
      switch (type) {
        case 'admin':
          if (selectedAdmin && selectedAdmin.length + totalAdmin >= 5) {
            if (
              selectedAdmin.find(
                (value) => value.account_id === item.account_id,
              )
            )
              handleUncheckAdmin(item)
            else showSnackbar(translate('tree:hostManageAdminCaution'))
          } else {
            handleUncheckAdmin(item)
          }
          break
        case 'creator': {
          if (selectedCreator && selectedCreator.length === 5) {
            if (
              selectedCreator.find(
                (value) => value.account_id === item.account_id,
              )
            )
              handleUncheckCreator(item)
            else showSnackbar(translate('tree:hostManageCreatorCaution'))
          } else {
            handleUncheckCreator(item)
          }
        }
      }
    },
    [
      handleUncheckAdmin,
      handleUncheckCreator,
      selectedAdmin,
      selectedCreator,
      totalAdmin,
      type,
    ],
  )

  const renderAdminListItem = useCallback(
    (item) => (
      <TreeHostTemplateListHostMember
        data={item}
        rightComponentType="select"
        onRightComponentPress={() => handleSelect(item)}
        isSelected={
          selectedAdmin
            ? !!selectedAdmin.find(
                (value) => value.account_id === item.account_id,
              )
            : false
        }
      />
    ),
    [handleSelect, selectedAdmin],
  )
  const renderCreatorListItem = useCallback(
    (item) => (
      <TreeHostTemplateListHostMember
        data={item}
        rightComponentType="select"
        onRightComponentPress={() => handleSelect(item)}
        isSelected={
          selectedCreator
            ? !!selectedCreator.find(
                (value) => value.creator_id === item.creator_id,
              )
            : false
        }
      />
    ),
    [handleSelect, selectedCreator],
  )

  const handleRenderEmpty = useMemo(() => (search ? renderNotFound : <></>), [
    renderNotFound,
    search,
  ])

  const handleRenderSelectedPerson = useMemo(
    () =>
      totalItem > 0 ? (
        <>
          <StyledParagraph fontSize="s" fontWeight="bold" color="gray_5">
            {translate('tree:hostManageAdminPeopleSelected', {
              qty: totalItem,
            })}
          </StyledParagraph>
          <StyledIconContainer>
            {selectedAdmin
              ? selectedAdmin.map((data) => (
                  <TreeHostTemplateIcon data={data} key={data.account_id} />
                ))
              : selectedCreator
              ? selectedCreator.map((data) => (
                  <TreeHostTemplateIcon data={data} key={data.creator_id} />
                ))
              : undefined}
          </StyledIconContainer>
        </>
      ) : (
        <></>
      ),
    [selectedAdmin, selectedCreator, totalItem],
  )

  return (
    <StyledContainer>
      <StyledInputContainer>
        <Input
          leftIcon={<Icon type="search" color="gray_3" />}
          onChangeText={(text) => debounce(() => setSearch(text))}
          placeholder={translate('forkygram:uploadSearchPeoplePlaceholder')}
          defaultValue={''}
          rightIcon="delete"
          onRightIconClick={() => setSearch('')}
        />
      </StyledInputContainer>
      {handleRenderSelectedPerson}
      <ListLazy
        loadData={loadData}
        search={search}
        renderItem={
          type === 'admin' ? renderAdminListItem : renderCreatorListItem
        }
        keyExtractor={(item) => item.account_id}
        listEmptyElement={handleRenderEmpty}
        scrollbar={false}
      />
    </StyledContainer>
  )
}
