import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorProps {
  payload: FirebaseMessageRoomItemPayload['notification_reject_invitation_creator']
}

export default function GiftShopMessageRoomContentItemNotificationRejectInvitationCreator({
  payload: {username},
}: GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorProps) {
  const {translate} = useTranslation()

  return (
    <>
      <Paragraph fontSize="m">
        {translate('messages:hiUsername', {username})}
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        {translate(
          'messages:notificationCreatorOfficialCreatorInvitationRejected',
        )}
      </Paragraph>
    </>
  )
}
