import React, {useState, useCallback} from 'react'
import {useSelector} from 'lib/redux'
import {requestData} from 'services'
import {maskEmail, maskPhone} from 'utils'
import {GiftShopTemplateOTPModal} from '../../../template'
import {GiftShopAddBankAccountVerificationModalProps} from './GiftShopAddBankAccountModalProps'

export default function GiftShopAddBankAccountVerificationModal({
  verificationMethod,
  onCancel,
  onSuccess,
  onResend,
}: GiftShopAddBankAccountVerificationModalProps) {
  const {email, phone_code, phone_number} = useSelector('user') || {}
  const {phoneOTPTime} = useSelector('lastUserState')

  const stateError = useState(false)
  const setError = stateError[1]

  const handleVerify = useCallback(
    (codes: string) => {
      requestData('giftshop_post_bank_verify_otp', {
        data: {verification_method: verificationMethod, otp: codes},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setError(false)
            onSuccess()
          } else setError(true)
        },
      })
    },
    [onSuccess, setError, verificationMethod],
  )

  return (
    <GiftShopTemplateOTPModal
      stateError={stateError}
      dataToVerify={
        verificationMethod === 'email' && email
          ? maskEmail(email)
          : phone_code && phone_number && maskPhone(phone_code, phone_number)
      }
      verificationMethod={verificationMethod}
      timeRemaining={phoneOTPTime}
      onCancel={onCancel}
      onVerify={handleVerify}
      onResend={onResend}
    />
  )
}
