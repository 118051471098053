import React from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {Modal, Icon, Paragraph, Button} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopAddBankAccountConfirmationModalProps} from './GiftShopAddBankAccountModalProps'

const StyledHeader = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
`

const StyledContentContainer = styled.div`
  gap: ${convertUnit(8)};
`

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

export default function GiftShopAddBankAccountConfirmationModal({
  onConfirm,
  isVerificationSuccessful,
}: GiftShopAddBankAccountConfirmationModalProps) {
  return (
    <StyledModal visible>
      <StyledContentContainer>
        <Icon
          type={isVerificationSuccessful ? 'check' : 'close'}
          color={isVerificationSuccessful ? 'success_5' : 'gray_3'}
          size={48}
        />
        <StyledHeader fontSize="xl" fontWeight="bold">
          {translate(
            isVerificationSuccessful ? 'global:success' : 'global:failed',
          )}
        </StyledHeader>
        <Paragraph fontSize="l" lineHeight={31}>
          {translate(
            isVerificationSuccessful
              ? 'giftShop:addAccountSuccess'
              : 'giftShop:addAccountFailed',
          )}
        </Paragraph>
      </StyledContentContainer>
      <Button
        label={translate(
          isVerificationSuccessful ? 'global:ok' : 'global:tryAgain',
        )}
        onClick={onConfirm}
      />
    </StyledModal>
  )
}
