import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  max-height: ${convertUnit(610)};
  max-width: ${convertUnit(601)};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-height: 100%;
    max-width: 100%;
    gap: ${convertUnit(20)};
  }
`

const StyledTextContainter = styled.div`
  text-align: center;
`

export default function TreeHostNotificationEmpty() {
  const {translate} = useTranslation()
  return (
    <StyledContainer>
      <Icon type="email" size={convertUnit(40)} color="primary_5" />
      <StyledTextContainter>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('tree:hostNotification')}
        </Paragraph>
        <Paragraph fontSize="s" color="gray_5">
          {translate('tree:hostNotificationEmptyInfo')}
        </Paragraph>
      </StyledTextContainter>
    </StyledContainer>
  )
}
