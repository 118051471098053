import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {setDownTimeModalHandler} from 'utils'
import {DownTimeModalProviderProps} from './DownTimeModalProps'
import {DownTimeModalContext} from './DownTimeModalContext'
import DownTimeModalItem from './DownTimeModalItem'

export default function DownTimeModalProvider({
  children,
}: DownTimeModalProviderProps) {
  const [visible, setVisible] = useState<boolean>(false)

  const handleShowDownTimeModal = useCallback((modal: boolean) => {
    setVisible(modal)
  }, [])

  const handleRemoveDownTimeModal = useCallback(() => {
    setVisible(false)
  }, [])

  const handleRenderDownTimeModal = useMemo(
    () => (
      <DownTimeModalItem
        visible={visible}
        onClickOk={handleRemoveDownTimeModal}
      />
    ),
    [handleRemoveDownTimeModal, visible],
  )

  const handler = useMemo(
    () => ({
      visible,
      showDownTimeModal: handleShowDownTimeModal,
    }),
    [handleShowDownTimeModal, visible],
  )

  useEffect(() => {
    setDownTimeModalHandler(handler)
  }, [handler])

  return (
    <DownTimeModalContext.Provider value={handler}>
      {handleRenderDownTimeModal}
      {children}
    </DownTimeModalContext.Provider>
  )
}
