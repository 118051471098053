import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionCheckoutInfoItemProps} from './TransactionInfoItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
`

const StyledTitle = styled(Paragraph)`
  text-align: left;
`

const StyledDescription = styled(Paragraph)`
  text-align: right;
`

export default function TransactionInfoItem({
  title,
  description,
  totalPrice,
  boldDescription,
  details,
  ...props
}: TransactionCheckoutInfoItemProps) {
  return details ? (
    <StyledContainer {...props}>
      <StyledTitle color="gray_4">{title}</StyledTitle>
      <StyledDescription color="gray_4">{description}</StyledDescription>
    </StyledContainer>
  ) : (
    <StyledContainer style={{marginTop: convertUnit(10)}} {...props}>
      <StyledTitle fontSize="m" fontWeight="medium">
        {title}
      </StyledTitle>
      <StyledDescription
        fontSize="m"
        fontWeight={boldDescription ? 'bold' : 'regular'}
        color={totalPrice ? 'primary_5' : 'black'}>
        {description}
      </StyledDescription>
    </StyledContainer>
  )
}
