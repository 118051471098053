import React, {useMemo} from 'react'
import {
  LIST_MASONRY_MAX_ITEM,
  LIST_MASONRY_MAX_ITEM_DIVIDER,
  LIST_MASONRY_MAX_RATIO,
  LIST_MASONRY_MAX_RATIO_DIVIDER,
  LIST_MASONRY_MAX_RATIO_PER_ITEM,
  LIST_MASONRY_MIN_ITEM,
  LIST_MASONRY_MIN_ITEM_DIVIDER,
  LIST_MASONRY_MIN_RATIO_PER_ITEM,
} from 'consts'
import {ListMasonryData, ListMasonryDataParam} from 'types'

export function useListMasonryData({width = 0}: ListMasonryDataParam = {}) {
  return useMemo<Required<ListMasonryData>>(
    () => ({
      maxItem: Math.max(
        LIST_MASONRY_MAX_ITEM,
        width / LIST_MASONRY_MAX_ITEM_DIVIDER,
      ),
      maxRatio: Math.max(
        LIST_MASONRY_MAX_RATIO,
        width / LIST_MASONRY_MAX_RATIO_DIVIDER,
      ),
      minItem: Math.max(
        LIST_MASONRY_MIN_ITEM,
        width / LIST_MASONRY_MIN_ITEM_DIVIDER,
      ),
      maxRatioPerItem: LIST_MASONRY_MAX_RATIO_PER_ITEM,
      minRatioPerItem: LIST_MASONRY_MIN_RATIO_PER_ITEM,
    }),
    [width],
  )
}

export function ListMasonryHookTest() {
  const listMasonry = useListMasonryData()
  return (
    <>
      {listMasonry.maxItem}
      {listMasonry.maxRatio}
      {listMasonry.minItem}
      {listMasonry.maxRatioPerItem}
      {listMasonry.minRatioPerItem}
    </>
  )
}
