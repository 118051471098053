import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export default function GiftShopFeedEmpty() {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <Paragraph fontSize="l" fontWeight="bold" color="white_3">
        {translate('forkygram:emptyFeedTitle')}
      </Paragraph>
      <Paragraph fontSize="s" color="gray_3">
        {translate('forkygram:emptyFeedMessage')}
      </Paragraph>
    </StyledContainer>
  )
}
