import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {InformationModalProps} from './InformationModalProps'
import {Icon} from '../Icon'
import {Modal} from '../Modal'
import {Paragraph} from '../Paragraph'

const StyledClosedButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
  transition: all 200ms;
  font-size: ${convertUnit(30)};
  font-weight: bold;
`

const StyledModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledModalBody = styled.div`
  padding: ${convertUnit(25)};
`

const StyledModal = styled(Modal)`
  padding: ${convertUnit(25)};
  width: ${convertUnit(275)};
`

export default function InformationModal({
  title,
  children,
  toggleModal,
  ...props
}: InformationModalProps) {
  return (
    <StyledModal {...props}>
      <StyledModalHeader>
        <StyledClosedButton onClick={toggleModal}>
          <Icon type="close" />
        </StyledClosedButton>
        <Paragraph fontSize="xl" fontWeight="medium">
          {title}
        </Paragraph>
      </StyledModalHeader>
      <StyledModalBody>{children}</StyledModalBody>
    </StyledModal>
  )
}
