import React from 'react'
import { useTranslation } from 'i18n'
import { FirebaseMessageRoomItemPayload } from 'types'
import { useSelector } from 'lib/redux'
import { Paragraph } from 'common/components'

export interface GiftShopMessageRoomContentItemReminderRobopetProps {
  payload: FirebaseMessageRoomItemPayload['reminder_robopet']
}

export default function GiftShopMessageRoomContentItemReminderRobopet() {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!

  return (
    <Paragraph fontSize="m">
      {translate('messages:reminderRobopet', {
        username,
      })}
    </Paragraph>
  );
}
