import React, {useCallback, useMemo} from 'react'
import {
  SERVICE_CANCELLATION_SEARCH_CREATOR,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {requestData} from 'services'
import {TreeHostManageCreatorPromoteProps} from './TreeHostManageCreatorPromoteProps'
import {TreeHostPromoteTemplate} from '../../../template'

export default function TreeHostManageCreatorPromote({
  stateSelected,
}: TreeHostManageCreatorPromoteProps) {
  const handleLoadData = useCallback(
    async (page: number, limit: number, query: string) => {
      const responses = await requestData('tree_host_search_creator', {
        cancelId: SERVICE_CANCELLATION_SEARCH_CREATOR,
        params: {
          page,
          limit,
          q: query,
        },
      })

      if (
        typeof responses === 'string' &&
        responses === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof responses !== 'string' && responses.status === 200
        ? responses.data.result
        : []
    },
    [],
  )

  const handleRenderContent = useMemo(
    () => (
      <TreeHostPromoteTemplate
        stateSelectedCreator={stateSelected}
        loadData={handleLoadData}
        totalAdmin={0}
        type="creator"
      />
    ),
    [handleLoadData, stateSelected],
  )

  return handleRenderContent
}
