import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestDataPayload} from 'services'
import {TreeSearchFototreeResponse} from 'types'
import {formatDateRange, useDebounce} from 'utils'
import {
  Chips,
  Input,
  ListItemSelect,
  ListLazy,
  Menu,
  Icon,
  IconVerifiedBadge,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopExploreFilterModalFormProps} from './GiftShopExploreFilterModalFormProps'

const StyledInputContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertUnit(20)};
`

const StyledTagListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  line-height: ${convertUnit(12)};
`

export default function GiftShopExploreFilterModalFormTags({
  stateTempFilter,
}: GiftShopExploreFilterModalFormProps) {
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const [tempFilter, setTempFilter] = stateTempFilter
  const {tags: selectedTags} = tempFilter
  const [searchTag, setSearchTag] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const stateTagToggle = useState(false)
  const setTagToggle = stateTagToggle[1]
  const stateTags = useState<readonly TreeSearchFototreeResponse[]>([])
  const [tags, setTags] = stateTags
  const falseState = useState(false)

  const handleLoadTags = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestDataPayload('tree_search_fototree', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {page, limit, name: q},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result.filter(
            (item) =>
              selectedTags.findIndex((tag) => tag.id === item.id) === -1,
          )
        : null
    },
    [selectedTags],
  )

  const handleSelectTags = useCallback(
    (item: TreeSearchFototreeResponse) => {
      setSearchTag('')
      setTagToggle((prev) => !prev)
      setTags((previous) =>
        previous.filter((value) => value.tag_id !== item.tag_id),
      )
      setTempFilter((prev) => ({
        ...prev,
        tags: prev.tags ? [...prev.tags, item] : [item],
      }))
    },
    [setTagToggle, setTags, setTempFilter],
  )

  const handleRenderTagChips = useMemo(
    () =>
      selectedTags ? (
        <Chips
          data={selectedTags}
          showHost
          showCloseButton
          keyExtractor={(item) => item.id}
          labelExtractor={(item) => item.name}
          typeExtractor={(item) => item.type || ''}
          onDelete={(item) =>
            setTempFilter((prev) => ({
              ...prev,
              tags: prev.tags?.filter((tag) => tag.id !== item.id),
            }))
          }
          backgroundColor="primary_1"
          fontColor="primary_5"
          fontSize="xs"
        />
      ) : null,
    [setTempFilter, selectedTags],
  )

  const handleRenderDropdownItem = useCallback(
    (item: TreeSearchFototreeResponse) => (
      <ListItemSelect
        title={item.name}
        titleRighIcon={
          item.status === 'VERIFIED' ? <StyledIconVerifiedBadge /> : <></>
        }
        overflowSubtitle={`
        ${
          item.event_end_date && item.event_start_date
            ? `${formatDateRange(item.event_start_date, item.event_end_date)}`
            : ''
        }
        ${
          (item.event_end_date || item.event_start_date) && item.bio
            ? ' | '
            : ''
        }
        ${item.bio ? `${item.bio}` : ''}`}
        hoverColor="white_3"
        titleHoverColor="black"
        onClick={() => handleSelectTags(item)}
      />
    ),
    [handleSelectTags],
  )

  const handleRenderTagList = useMemo(
    () => (
      <StyledTagListContainer>
        <ListLazy
          stateData={stateTags}
          loadData={handleLoadTags}
          search={searchQuery}
          data={tags}
          keyExtractor={(tag) => tag.tag_id}
          renderItem={handleRenderDropdownItem}
        />
      </StyledTagListContainer>
    ),
    [handleLoadTags, handleRenderDropdownItem, searchQuery, stateTags, tags],
  )

  return (
    <StyledInputContainer>
      <Menu
        stateToggle={selectedTags.length < 8 ? stateTagToggle : falseState}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={
          selectedTags.length < 8 ? handleRenderTagList : undefined
        }>
        <Input
          value={searchTag}
          label={translate('giftShop:exploreFilterTagsLabel')}
          placeholder={translate('giftShop:exploreFilterTagsPlaceholder')}
          onChangeText={(text) => {
            setSearchTag(text)
            debounce(() => setSearchQuery(text), 300)
            setTagToggle(true)
          }}
          rightIcon={
            searchTag.length > 0 ? (
              <Icon
                type="close"
                size={convertUnit(24)}
                color="gray_5"
                onClick={() => setSearchTag('')}
              />
            ) : undefined
          }
          disabled={selectedTags.length >= 8}
        />
      </Menu>
      {handleRenderTagChips}
    </StyledInputContainer>
  )
}
