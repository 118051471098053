import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopEditProfileSuccessStatusProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(8)};
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(12)};
`

export default function GiftShopEditProfileSuccessStatus({
  success,
  desc,
  onClickButton,
  backToPage,
}: GiftShopEditProfileSuccessStatusProps) {
  const {translate} = useTranslation()

  const handleRenderIcon = useMemo(
    () => (
      <Icon
        type={success ? 'check' : 'close'}
        color={success ? 'success_5' : 'gray_3'}
        size={convertUnit(48)}
      />
    ),
    [success],
  )

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate(success ? 'global:success' : 'global:failed')}
      </Paragraph>
    ),
    [success, translate],
  )

  const handleRenderDesc = useMemo(
    () => (
      <Paragraph fontSize="l" lineHeight={31}>
        {desc}
      </Paragraph>
    ),
    [desc],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate(success ? 'global:backTo' : 'global:tryAgain', {
          page: backToPage ?? translate('global:profile'),
        })}
        onClick={onClickButton}
      />
    ),
    [backToPage, onClickButton, success, translate],
  )

  return (
    <StyledModal visible>
      {handleRenderIcon}
      {handleRenderTitle}
      {handleRenderDesc}
      {handleRenderButton}
    </StyledModal>
  )
}
