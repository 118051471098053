import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled, {CSSProperties} from 'styled-components'
import {ListMetrics, ListToken} from 'types'
import {LIST_INITIAL_NUM_RENDERING} from 'consts'
import {useDidUpdate, useElementSize, useListAction} from 'utils'
import convertUnit from 'lib/unit'
import {FlatlistItem} from '../FlatlistItem'
import {FlatlistProps} from './FlatlistProps'

interface StyledContainerProps {
  height: number
  width: number
  scrollbar?: boolean
  scrollSnapType?: CSSProperties['scrollSnapType']
}

interface StyledContentContainerProps {
  height: number
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({height, width, scrollSnapType}) => ({height, width, scrollSnapType})};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: ${({scrollbar}) => (scrollbar ? 'auto' : 'none')};
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${({scrollbar}) => convertUnit(scrollbar ? 25 : 0)};
  }

  ::-webkit-scrollbar-thumb {
    min-height: ${convertUnit(40)};
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledContentContainer = styled.div`
  width: 100%;
`

const StyledContenInnerContainer = styled.div`
  position: relative;
  width: 100%;
`

const StyledInnerContainer = styled.div<StyledContentContainerProps>`
  ${({height}) => ({height})}
  width: 100%;
`

export default function Flatlist<ItemT>({
  height,
  width,
  data: dataBase,
  itemMinLength,
  contentContainerStyle,
  scrollbar = true,
  initialNumRendering = LIST_INITIAL_NUM_RENDERING,
  scrollSnapType = 'none',
  chunkLength,
  chunkRadius,
  visibilityIndexThreshold,
  listHeaderElement,
  listFooterElement,
  listEmptyElement,
  onEndReachedThreshold,
  onEndReached,
  onScroll,
  onVisibilityIndexChange,
  keyExtractor,
  renderItem,
  onContentSizeChange,
  onLayoutDimension,
}: FlatlistProps<ItemT>) {
  const contentLength = useRef(0)
  const headerLength = useRef(0)
  const footerLength = useRef(0)
  const token = useRef<ListToken>({start: 0, size: initialNumRendering})
  const ref = useRef<HTMLDivElement | null>(null)
  const [timestamp, setTimestamp] = useState(new Date().getTime())
  const {start, size} = token.current
  const totalLength =
    headerLength.current + contentLength.current + footerLength.current

  const forceUpdate = useCallback(() => setTimestamp(new Date().getTime()), [
    setTimestamp,
  ])

  const refContent = useRef<HTMLDivElement | null>(null)
  const {clientWidth, clientHeight} = useElementSize(refContent)
  const {clientWidth: layoutWidth, clientHeight: layoutHeight} = useElementSize(
    ref,
  )
  const emptyData = useMemo(() => [], [])

  const data = useMemo(() => dataBase ?? emptyData, [dataBase, emptyData])

  const minLength = useMemo(
    () => itemMinLength ?? Math.ceil(height / (initialNumRendering || 1)),
    [height, initialNumRendering, itemMinLength],
  )

  const dataRelative = useMemo(() => data.slice(start, start + size), [
    data,
    start,
    size,
  ])

  const empty = useMemo<ListMetrics>(() => ({length: 0, offset: 0}), [])

  const {
    handleGetMetrics,
    handleItemLayout,
    handleScroll,
    handleLayoutHeader,
    handleLayoutFooter,
    handleUpdateVisibility,
  } = useListAction({
    ref,
    data,
    token,
    headerLength,
    footerLength,
    contentLength,
    empty,
    minLength,
    chunkRadius,
    chunkLength,
    onEndReachedThreshold,
    visibilityIndexThreshold,
    keyExtractor,
    forceUpdate,
    onScroll,
    onEndReached,
    onVisibilityIndexChange,
  })

  const handleDidUpdate = useCallback(() => {
    if (ref.current) {
      const {scrollTop} = ref.current

      handleUpdateVisibility(scrollTop, true)
    }
  }, [handleUpdateVisibility])

  const handleRenderItem = useCallback(
    (item: ItemT, relativeIndex: number, relativeArray: ItemT[]) => {
      const key = keyExtractor(item)
      const {offset} = handleGetMetrics(key)
      const index = start + relativeIndex
      return (
        <FlatlistItem
          key={key}
          offset={offset + headerLength.current}
          onLayout={(length) =>
            handleItemLayout({
              key,
              relativeIndex,
              length,
              relativeArray,
            })
          }>
          {renderItem(item, index)}
        </FlatlistItem>
      )
    },
    [keyExtractor, handleGetMetrics, start, renderItem, handleItemLayout],
  )

  const handleRenderHeader = useMemo(
    () => (
      <FlatlistItem onLayout={handleLayoutHeader}>
        {listHeaderElement}
      </FlatlistItem>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timestamp, listHeaderElement, handleLayoutHeader],
  )

  const handleRenderFooter = useMemo(
    () => (
      <FlatlistItem
        offset={contentLength.current + headerLength.current}
        onLayout={handleLayoutFooter}>
        {listFooterElement}
      </FlatlistItem>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timestamp, listFooterElement, handleLayoutFooter],
  )

  useEffect(() => {
    onLayoutDimension &&
      onLayoutDimension({width: layoutWidth, height: layoutHeight})
  }, [layoutHeight, layoutWidth, onLayoutDimension])

  useEffect(() => {
    onContentSizeChange && onContentSizeChange(clientWidth, clientHeight)
  }, [clientHeight, clientWidth, onContentSizeChange])

  const handleRenderChildren = useMemo(
    () =>
      data.length ? (
        <StyledContentContainer style={contentContainerStyle}>
          <StyledContenInnerContainer>
            <StyledInnerContainer ref={refContent} height={totalLength}>
              {handleRenderHeader}
              {dataRelative.map(handleRenderItem)}
              {handleRenderFooter}
            </StyledInnerContainer>
          </StyledContenInnerContainer>
        </StyledContentContainer>
      ) : (
        <>
          {listHeaderElement}
          {listEmptyElement}
          {listFooterElement}
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      listHeaderElement,
      listEmptyElement,
      listFooterElement,
      contentContainerStyle,
      timestamp,
      data.length,
      dataRelative,
      totalLength,
      handleRenderHeader,
      handleRenderFooter,
      handleRenderItem,
    ],
  )

  useDidUpdate(handleDidUpdate, [handleDidUpdate])

  return (
    <StyledContainer
      ref={ref}
      height={height}
      width={width}
      scrollbar={scrollbar}
      scrollSnapType={scrollSnapType}
      onScroll={handleScroll}>
      {handleRenderChildren}
    </StyledContainer>
  )
}
