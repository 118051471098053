import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {isSafari} from 'react-device-detect'
import styled from 'styled-components'
import {GIFT_SHOP_MAX_BIO_CHARACTER, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {BioForm} from 'types'
import {showPrompt, showSnackbar} from 'utils'
import {Button, InputArea, ModalLoading} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopFormPropsBase} from './GiftShopFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(50)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(770)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(25)};
`

export default function GiftShopBioForm({
  defaultValue,
  stateActiveContent,
}: GiftShopFormPropsBase) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)
  const {update} = useDispatch()
  const [prompt, setPrompt] = useState(true)
  const website = useSelector('user')?.website
  const [maxBioSnackbar, setMaxBioSnackbar] = useState(true)

  const form = useForm<BioForm>({
    defaultValues: {
      bio: defaultValue,
    },
  })
  const {watch, setValue, handleSubmit} = form
  const {bio} = watch()

  const handlePatchBio = useCallback(async () => {
    setLoading(true)
    await requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {bio, website},
      dataType: 'form-data',
      onRequestReceived: () => {
        setPrompt(false)
        setLoading(false)
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          update('user', {biodata: bio})
          setActiveContent('profile')
        }
      },
    })
  }, [bio, setActiveContent, update, website])

  const handleInputBio = useCallback(
    (text: string) => {
      if (bio.length >= GIFT_SHOP_MAX_BIO_CHARACTER) {
        if (maxBioSnackbar) {
          showSnackbar(
            translate('giftShop:bioMaxMessage', {
              count: GIFT_SHOP_MAX_BIO_CHARACTER,
            }),
          )
          setMaxBioSnackbar(false)
        }
        setValue('bio', text.slice(0, GIFT_SHOP_MAX_BIO_CHARACTER))
      } else {
        setValue('bio', text)
        setMaxBioSnackbar(true)
      }
    },
    [bio.length, maxBioSnackbar, setValue, translate],
  )

  const handleEnterNewLine = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        const newLine = bio.match(/\n/gm)
        if (newLine && newLine.length >= 4) {
          e.preventDefault()
        }
      }
    },
    [bio],
  )

  useEffect(() => {
    showPrompt(
      bio !== defaultValue && prompt,
      translate('giftShop:uploadModalMessage'),
    )
  }, [bio, defaultValue, prompt, translate])

  return (
    <>
      <ModalLoading visible={loading} />
      <StyledContainer>
        <StyledScreenContainer>
          <InputArea
            name="bio"
            multiline
            onKeyDown={handleEnterNewLine}
            form={form}
            placeholder={translate('giftShop:changeBioPlaceholder')}
            label={translate('giftShop:bio')}
            labelDescription={translate('global:charCount', {
              count: bio.length,
              max: GIFT_SHOP_MAX_BIO_CHARACTER,
            })}
            labelDescriptionColor={
              bio.length === GIFT_SHOP_MAX_BIO_CHARACTER
                ? 'danger_5'
                : undefined
            }
            inputStyle={{height: convertUnit(89)}}
            onChangeText={handleInputBio}
            maxLength={isSafari ? undefined : GIFT_SHOP_MAX_BIO_CHARACTER}
          />
          <StyledButton
            disabled={bio === defaultValue}
            label={translate('global:save')}
            onClick={handleSubmit(handlePatchBio)}
            isLoading={loading}
          />
        </StyledScreenContainer>
      </StyledContainer>
    </>
  )
}
