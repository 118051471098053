import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {ListItemSelect, Menu} from 'common/components'
import convertUnit from 'lib/unit'
import {FototreeOptionsMenuProps} from './TreeFototreeModalProps'

const StyledMenu = styled(Menu)`
  display: block;
  height: 100%;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 100;
`
const StyledManageListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default function FototreeOptionsMenu({
  status,
  stateToggle,
  children,
  onClickEdit,
  onClickManageFarmer,
  onClickTreatment,
  onClickPreview,
  onClickShare,
}: FototreeOptionsMenuProps) {
  const {translate} = useTranslation()
  const setToggle = stateToggle[1]

  const handleRenderOwnerOption = useMemo(
    () => (
      <StyledManageListContainer onClick={() => setToggle((prev) => !prev)}>
        <ListItemSelect
          title={translate('tree:editTree')}
          onClick={onClickEdit}
        />
        <ListItemSelect
          title={translate('tree:manageFarmer')}
          onClick={onClickManageFarmer}
        />
        <ListItemSelect
          title={translate('tree:treeTreatment')}
          onClick={onClickTreatment}
        />
        <ListItemSelect
          title={translate('tree:shareFototree')}
          onClick={onClickShare}
        />
        <ListItemSelect
          title={translate('tree:previewQrCode')}
          onClick={onClickPreview}
        />
      </StyledManageListContainer>
    ),
    [
      onClickEdit,
      onClickManageFarmer,
      onClickPreview,
      onClickShare,
      onClickTreatment,
      setToggle,
      translate,
    ],
  )

  const handleRenderFarmerOption = useMemo(
    () => (
      <StyledManageListContainer onClick={() => setToggle((prev) => !prev)}>
        <ListItemSelect
          title={translate('tree:treeTreatment')}
          onClick={onClickTreatment}
        />
        <ListItemSelect
          title={translate('tree:shareFototree')}
          onClick={onClickShare}
        />
        <ListItemSelect
          title={translate('tree:previewQrCode')}
          onClick={onClickPreview}
        />
      </StyledManageListContainer>
    ),
    [onClickPreview, onClickShare, onClickTreatment, setToggle, translate],
  )

  const handleRenderVisitorOption = useMemo(
    () => (
      <StyledManageListContainer onClick={() => setToggle((prev) => !prev)}>
        <ListItemSelect
          title={translate('tree:shareFototree')}
          onClick={onClickShare}
        />
      </StyledManageListContainer>
    ),
    [onClickShare, setToggle, translate],
  )

  return (
    <StyledMenu
      stateToggle={stateToggle}
      renderContent={
        status === 'farmer'
          ? handleRenderFarmerOption
          : status === 'owner'
          ? handleRenderOwnerOption
          : handleRenderVisitorOption
      }
      contentContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
        minWidth: convertUnit(215),
        right: 0,
        borderRadius: convertUnit(8),
      }}>
      {children}
    </StyledMenu>
  )
}
