import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {useHistory} from 'utils'
import {Paragraph, Image} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'

export interface GiftShopMessageRoomContentItemNotificationFollowProps {
  payload: FirebaseMessageRoomItemPayload['notification_follow']
}

const StyledAvatar = styled(Image)`
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border-radius: ${convertUnit(48)};
  margin-right: ${convertUnit(16)};
  cursor: pointer;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMessageContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

export default function GiftShopMessageRoomContentItemNotificationFollow({
  payload: {username, userId, profilePhoto},
}: GiftShopMessageRoomContentItemNotificationFollowProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {id} = useSelector('user') || {}

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: userId === id,
        postData: {
          member_id: userId,
          username,
          photo: profilePhoto,
        },
      },
      username,
    )
  }, [history, id, profilePhoto, userId, username])

  const handleRenderProfilePicture = useMemo(
    () => (
      <StyledAvatar
        src={profilePhoto}
        alt="avatar"
        onClick={handleNavigateProfile}
      />
    ),
    [handleNavigateProfile, profilePhoto],
  )

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContiner>
        <Paragraph
          className="pressable"
          fontSize="m"
          fontWeight="bold"
          onClick={handleNavigateProfile}>
          {`@${username}`}{' '}
        </Paragraph>
        <StyledParagraph fontSize="m">
          {translate('messages:notificationFollow')}
        </StyledParagraph>
      </StyledMessageContiner>
    ),
    [handleNavigateProfile, translate, username],
  )

  return (
    <StyledContainer>
      {handleRenderProfilePicture}
      {handleRenderMessage}
    </StyledContainer>
  )
}
