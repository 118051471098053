import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useTheme} from 'themes'
import {parseShape} from 'types'
import {getCommunityGuideline} from 'utils'
import {useSelector} from 'lib/redux'
import {BanModalItemProps} from './BanModalItemProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: column;
`

const StyledParagraph = styled(Paragraph)`
  margin: ${convertUnit(15)} 0;
  padding: 0;
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

export default function BanModalItem({
  onClickOk,
  status,
  message,
}: BanModalItemProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const {lang} = useSelector('languageState')

  const handleBannedText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<guideline>.*?<\/guideline>/,
        style: {
          color: theme.primary_5,
          fontFamily: 'roboto-bold',
          cursor: 'pointer',
        },
        renderText: (text) => text.replace(/<guideline>|<\/guideline>/g, ''),
        onClick: () =>
          window.open(`${getCommunityGuideline()}&lang=${lang || 'id'}`),
      },
    ],
    [lang, theme.primary_5],
  )

  return (
    <StyledModal visible>
      <StyledTitle fontSize="l" fontWeight="bold">
        {status === 403 && translate('auth:banModalTitle')}
        {status === 423 && translate('auth:suspendModalTitle')}
      </StyledTitle>
      <StyledParagraph fontSize="m">
        {status === 423 &&
          (message === 'Suspended' || message === 'Locked') &&
          translate('auth:suspendModalDescription')}
        {status === 423 &&
          message === 'Attempt Login Suspended' &&
          translate('auth:suspendLoginModalDescription')}
        {status === 403 && message === 'Banned' && (
          <ParsedText parse={handleBannedText}>
            {translate('auth:banModalDescription')}
          </ParsedText>
        )}
        {status === 403 &&
          message === 'Underage' &&
          translate('auth:banModalUnderageDescription')}
      </StyledParagraph>
      <Button label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>
  )
}
