import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Modal, Button, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopEditProfileLimitPasswordModalProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  margin: 0 ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopEditProfileLimitPasswordModal({
  cooldownTimer,
  onConfirm,
}: GiftShopEditProfileLimitPasswordModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:editProfileInputPasswordLimit')}
      </Paragraph>
      <Paragraph fontSize="m">
        <ParsedText
          parse={[
            {
              pattern: new RegExp(cooldownTimer),
              style: {fontFamily: 'roboto-bold'},
            },
          ]}>
          {translate('auth:resendLimitReached', {
            duration: cooldownTimer,
          })}
        </ParsedText>
      </Paragraph>
      <StyledButton label={translate('global:ok')} onClick={onConfirm} />
    </StyledModal>
  )
}
