import React from 'react'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

export default function GiftShopRobopetActivationProcessing() {
  const {translate} = useTranslation()

  return (
    <GiftShopRobopetActivationTemplate
      backgroundColor="primary_1"
      robopetImg="roboyu-waiting.png"
      title={translate('giftShop:roboyuActivationProcessingHeader')}
      titleColor="primary_5">
      <Paragraph fontSize="m" fontWeight="regular" color="primary_5">
        {translate('giftShop:roboyuActivationProcessingDescription')}
      </Paragraph>
    </GiftShopRobopetActivationTemplate>
  )
}
