import React, {useMemo} from 'react'
import {Flatlist} from '../Flatlist'
import {ListIndicator} from '../ListIndicator'
import {FlatlistLazyBaseProps} from './FlatlistLazyBaseProps'

export default function FlatlistLazyBase<ItemT>({
  status,
  listEmptyElement,
  listEndPageElement,
  listFooterElement,
  listInitializeElement,
  listLoadingElement,
  listLoadingElementStyle,
  ...props
}: FlatlistLazyBaseProps<ItemT>) {
  const handleRenderFooter = useMemo(
    () => (
      <>
        <ListIndicator
          status={status}
          listEmptyElement={listEmptyElement}
          listEndPageElement={listEndPageElement}
          listInitializeElement={listInitializeElement}
          listLoadingElement={listLoadingElement}
          listLoadingElementStyle={listLoadingElementStyle}
        />
        {listFooterElement}
      </>
    ),
    [
      status,
      listEmptyElement,
      listEndPageElement,
      listInitializeElement,
      listLoadingElement,
      listLoadingElementStyle,
      listFooterElement,
    ],
  )

  return <Flatlist {...props} listFooterElement={handleRenderFooter} />
}
