import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionBankAccountListItemProps} from './TransactionBankAccountListItemProps'

const StyledContainer = styled.div`
  border-radius: ${convertUnit(8)};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
const StyledBankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMainContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.info_4})}
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  margin-left: ${convertUnit(4)};
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-right: ${convertUnit(4)};
`

export default function TransactionBankAccountListItem({
  accountName,
  accountNumber,
  bankName,
  isMainAccount,
  hideMainLabel,
}: TransactionBankAccountListItemProps) {
  const {translate} = useTranslation()

  const handleRenderBankCode = useMemo(
    () => (
      <StyledParagraph fontSize="s" fontWeight="bold" color="primary_5">
        {`${bankName} • `}
      </StyledParagraph>
    ),
    [bankName],
  )

  const handleRenderMain = useMemo(
    () =>
      isMainAccount && !hideMainLabel ? (
        <StyledMainContainer>
          <Paragraph fontSize="xs" fontWeight="medium" color="white_1">
            {translate('giftShop:bankAccountMain')}
          </Paragraph>
        </StyledMainContainer>
      ) : null,
    [hideMainLabel, isMainAccount, translate],
  )

  return (
    <StyledContainer>
      <StyledBankContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="black">
          {accountName.toUpperCase()}
        </Paragraph>
        <StyledStatusContainer>
          {handleRenderBankCode}
          {handleRenderMain}
          <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
            {accountNumber}
          </Paragraph>
        </StyledStatusContainer>
      </StyledBankContainer>
    </StyledContainer>
  )
}
