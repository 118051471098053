import {Button, InputArea, Modal, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {requestData} from 'services'
import styled from 'styled-components'
import {ForkygramReportCategoriesResponse} from 'types'
import {useDidMount} from 'utils'
import GiftShopFeedReportItem from './GiftShopTemplateReportItem'
import {GiftShopTemplateReportModalProps} from './GiftShopTemplateReportProps'

const StyledModal = styled(Modal)`
  width: 100%;
  padding: ${convertUnit(20)};
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  justify-content: center;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateReportModal({
  type,
  onSubmit,
  toggleModal,
}: GiftShopTemplateReportModalProps) {
  const {translate} = useTranslation()
  const [select, setSelect] = useState<
    ForkygramReportCategoriesResponse | undefined
  >(undefined)
  const [report, setReport] = useState<ForkygramReportCategoriesResponse[]>([])
  const form = useForm<{description: string}>({
    defaultValues: {
      description: '',
    },
  })
  const {watch} = form
  const {description} = watch()

  const handleGetReportReason = useCallback(() => {
    requestData(
      type === 'post'
        ? 'forkygram_get_report_posts_categories'
        : 'forkygram_get_report_yuser_categories',
      {
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setReport(result)
        },
      },
    )
  }, [type])

  useDidMount(() => {
    handleGetReportReason()
  })

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={toggleModal}
        />
        <StyledButton
          disabled={
            !select || (select.name === 'Other' && description.length === 0)
          }
          label={translate('global:submit')}
          onClick={() => select && onSubmit(select, description)}
        />
      </StyledButtonContainer>
    ),
    [description, onSubmit, select, toggleModal, translate],
  )

  return (
    <StyledModal visible>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('global:reportContent')}
      </StyledTitle>

      <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
        {translate('global:reportReason')}
      </Paragraph>

      {report.map((item) => (
        <GiftShopFeedReportItem
          key={item.id}
          reason={translate('giftShop:reportReason', {
            context: item.name.toUpperCase(),
          })}
          isSelected={select?.id === item.id}
          onSelect={() => setSelect(item)}
        />
      ))}
      {select?.name === 'Other' && (
        <InputArea
          form={form}
          name="description"
          placeholder={translate('forkygram:reportOtherPlaceholder')}
          maxLength={300}
          inputContainerStyle={{height: convertUnit(100)}}
        />
      )}

      {handleRenderButtons}
    </StyledModal>
  )
}
