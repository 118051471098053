import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'i18n'
import styled from 'styled-components'
import {useWindowMode} from 'windows'
import {ButtonIcon, Paragraph, PriceTag} from 'common/components'
import currency from 'lib/currency'
import convertUnit from 'lib/unit'
import {GiftShopContentDetailTemplateInfoProps} from './GiftShopContentDetailTemplateInfoProps'

const StyledInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  flex: 822;
  padding: ${({padding}) => padding};
  padding-top: ${({paddingTop}) => paddingTop};
  padding-bottom: ${convertUnit(25)};
  padding-left: ${({paddingLeft}) => paddingLeft};
  padding-right: ${({paddingRight}) => paddingRight};
`

const StyledPriceWishlistContainer = styled.div`
  display: ${({hidden}) => (hidden ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${convertUnit(10)};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(10)};
`

export default function GiftShopContentDetailTemplateInfo({
  currencyCode,
  price,
  priceTag,
  title,
  createdAt,
  location,
  tags,
  resolution,
  size,
  description,
  wishlistButton,
  isWishlist,
  toggleWishlist,
}: GiftShopContentDetailTemplateInfoProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [inWishlist, setInWishlist] = useState(isWishlist)

  const handleToggleWishlist = useCallback(() => {
    if (toggleWishlist) {
      toggleWishlist()
      setInWishlist(!inWishlist)
    }
  }, [inWishlist, toggleWishlist])

  const handleRenderPriceWishlist = useMemo(
    () => (
      <StyledPriceWishlistContainer hidden={!priceTag && !wishlistButton}>
        <PriceTag
          price={currency(currencyCode, price)}
          iconSize={mode === 'mobile' ? convertUnit(14) : convertUnit(21)}
          fontSize={mode === 'tablet' ? 'l' : mode === 'mobile' ? 's' : 'xl'}
          iconMarginRight={
            mode === 'mobile' ? convertUnit(9) : convertUnit(17.5)
          }
          hidden={!priceTag}
        />
        <ButtonIcon
          iconType="heart"
          iconColor={inWishlist ? 'primary_5' : 'gray_1'}
          hidden={!wishlistButton}
          onClick={handleToggleWishlist}
        />
      </StyledPriceWishlistContainer>
    ),
    [
      currencyCode,
      handleToggleWishlist,
      inWishlist,
      mode,
      price,
      priceTag,
      wishlistButton,
    ],
  )

  return (
    <StyledInfoContainer
      padding={
        mode === 'mobile'
          ? `${convertUnit(10)} ${convertUnit(25)} ${convertUnit(
              25,
            )} ${convertUnit(25)}`
          : `${convertUnit(25)} ${convertUnit(50)} ${convertUnit(
              25,
            )} ${convertUnit(50)}`
      }>
      {handleRenderPriceWishlist}
      <Paragraph fontSize={mode === 'mobile' ? 'l' : 'xxl'} fontWeight="bold">
        {title}
      </Paragraph>
      <StyledParagraph
        fontSize={mode === 'mobile' ? 's' : 'l'}
        fontWeight="regular"
        color="gray_3">
        {`${createdAt} `}
        &bull;
        {` ${location}`}
      </StyledParagraph>
      <StyledParagraph
        fontSize={mode === 'mobile' ? 's' : 'l'}
        fontWeight="regular">
        <span>{`${translate('giftShop:tag')}: `}</span>
        {tags}
      </StyledParagraph>
      <StyledParagraph
        fontSize={mode === 'mobile' ? 's' : 'l'}
        fontWeight="regular"
        style={{marginBottom: convertUnit(10)}}>
        <span>{`${translate('giftShop:resolution')}: `}</span>
        {`${resolution}, ${size}`}
      </StyledParagraph>
      <Paragraph fontSize={mode === 'mobile' ? 's' : 'l'} fontWeight="regular">
        {description}
      </Paragraph>
    </StyledInfoContainer>
  )
}
