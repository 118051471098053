import React from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_NAVBAR_ICON_SIZE,
  GIFT_SHOP_NAVBAR_ITEM_PADDING_HORIZONTAL,
  GIFT_SHOP_ROOT_MENU_HEIGHT,
  GIFT_SHOP_ROOT_MENU_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import {Icon, Paragraph} from 'common/components'
import {GiftShopRootNavBarItemProps} from './GiftShopRootNavBarItemProps'

interface StyledContainerProps {
  isSelected: boolean
  showLabel?: boolean
  darkBar?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({isSelected, theme, darkBar}) =>
    isSelected
      ? {
          color: `${darkBar ? theme.primary_4 : theme.primary_5} !important`,
        }
      : {
          color: `${darkBar ? theme.gray_1 : theme.gray_6} !important`,
          ':hover': {color: `${theme.gray_2} !important`},
        }}
  ${({showLabel}) =>
    !showLabel
      ? {width: convertUnit(GIFT_SHOP_ROOT_MENU_WIDTH)}
      : {
          padding: `0 ${convertUnit(GIFT_SHOP_NAVBAR_ITEM_PADDING_HORIZONTAL)}`,
        }}
  display: flex;
  box-sizing: border-box;
  height: ${convertUnit(GIFT_SHOP_ROOT_MENU_HEIGHT)};
  direction: ltr;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  transition: all 0.15s ease-out;
`

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: ${convertUnit(60)};
`

const StyledIconContainer = styled.div`
  display: flex;
  width: ${convertUnit(
    GIFT_SHOP_ROOT_MENU_WIDTH - GIFT_SHOP_NAVBAR_ITEM_PADDING_HORIZONTAL * 2,
  )};
  align-items: center;
  justify-content: center;
  text-align: center;
`

export default function GiftShopRootNavBarItem({
  icon,
  isSelected,
  label,
  darkBar,
  onClick,
  isSendToFace,
}: GiftShopRootNavBarItemProps) {
  return (
    <StyledContainer
      isSelected={isSelected}
      darkBar={darkBar}
      onClick={onClick}>
      <StyledItemContainer>
        <StyledIconContainer>
          <Icon type={icon} size={GIFT_SHOP_NAVBAR_ICON_SIZE} color="inherit" style={isSendToFace ? {marginRight: convertUnit(6)} : undefined} />
        </StyledIconContainer>
        <Paragraph
          fontSize="xs"
          lineHeight={17}
          fontWeight={isSelected ? 'quicksandBold' : 'quicksandMedium'}
          color="inherit">
          {label}
        </Paragraph>
      </StyledItemContainer>
    </StyledContainer>
  )
}
