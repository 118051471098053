import React, {CSSProperties, useCallback} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopNewYuserNoticeModalProps} from './GiftShopNewYuserNoticeModalProps'

const StyledModal = styled.div<CSSProperties>`
  width: ${({width = convertUnit(275)}) => width};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 5;
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${convertUnit(25)};
  justify-content: right;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(12)};
`

const StyledText = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(120)};
`

export default function GiftShopNewYuserNoticeModal({
  onConfirm,
  toggleModal,
  visible,
}: GiftShopNewYuserNoticeModalProps) {
  const {translate} = useTranslation()

  const roboImg = IMAGE_ASSET('giftshop', 'robopet-offer.png')

  const handleConfirm = useCallback(() => {
    toggleModal()
    onConfirm()
  }, [onConfirm, toggleModal])

  return (
    <Modal visible={visible}>
      <StyledModal>
        <StyledImageContainer>
          <StyledImage src={roboImg} alt={roboImg} />
        </StyledImageContainer>
        <StyledTitle fontWeight="bold" fontSize="xl">
          {translate('giftShop:newYuserNoticeTitle')}
        </StyledTitle>
        <StyledText fontWeight="regular" fontSize="l">
          {translate('giftShop:newYuserNoticeDescriptionFirst')}
        </StyledText>
        <StyledText fontWeight="bold" fontSize="l">
          {translate('giftShop:newYuserNoticeDescriptionSecond')}
        </StyledText>
        <StyledText fontWeight="regular" fontSize="l">
          {translate('giftShop:newYuserNoticeDescriptionThird')}
        </StyledText>
        <StyledModalButtonContainer>
          <StyledButton
            backgroundColor="primary_5"
            color="white_1"
            label={translate('global:ok')}
            onClick={handleConfirm}
            fontWeight="medium"
            fontSize="m"
          />
        </StyledModalButtonContainer>
      </StyledModal>
    </Modal>
  )
}
