import {
  AUTH_NICKNAME_MAX_LENGTH,
  AUTH_NICKNAME_MIN_LENGTH,
  REGEX_USERNAME,
} from 'consts'
import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {getCurrencyValue} from 'utils'
import {getValidation} from '../Validation'

export const VALIDATION_GIFTSHOP_UPLOAD_TITLE: InputFormRules = {
  required: true,
  minLength: 3,
  messages: {
    required: getValidation('global:validationRequired'),
    minLength: getValidation('global:validationMinLength', {
      count: 3,
    }),
  },
}

export const VALIDATION_GIFTSHOP_EDIT_BULK_PRICE = (
  minimum_price: number,
  maximum_price: number,
): InputFormRules => ({
  max: maximum_price,
  min: minimum_price,
  messages: {
    max: getValidation('global:validationMaxPrice', {
      price: getCurrencyValue(maximum_price),
    }),
    min: getValidation('global:validationMinPrice', {
      price: getCurrencyValue(minimum_price),
    }),
  },
})

export const VALIDATION_GIFTSHOP_UPLOAD_REQUIRED_PRICE = (
  minimum_price: number,
  maximum_price: number,
): InputFormRules => ({
  required: true,
  max: maximum_price,
  min: minimum_price,
  messages: {
    required: getValidation('global:validationRequired'),
    max: getValidation('global:validationMaxPrice', {
      price: getCurrencyValue(maximum_price),
    }),
    min: getValidation('global:validationMinPrice', {
      price: getCurrencyValue(minimum_price),
    }),
  },
})

export const VALIDATION_GIFTSHOP_UPLOAD_REQUIRED: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('global:validationRequired'),
  },
}

export const VALIDATION_GIFTSHOP_NICKNAME_UPLOAD: InputFormRules = {
  minLength: AUTH_NICKNAME_MIN_LENGTH,
  maxLength: AUTH_NICKNAME_MAX_LENGTH,
  messages: {
    minLength: getValidation('auth:validationNicknameMinLength', {
      count: AUTH_NICKNAME_MIN_LENGTH,
    }),
    maxLength: getValidation('auth:validationNicknameMaxLength', {
      count: AUTH_NICKNAME_MAX_LENGTH,
    }),
  },
  validate: {
    nicknameAllowedChar: (text) =>
      !text.match(REGEX_USERNAME)
        ? translate('auth:nicknameAllowedCharacters')
        : true,
  },
}
