import React, {useMemo} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {useFirebaseUser} from 'utils'
import {translate} from 'i18n'
import {Avatar, Button, Paragraph} from 'common/components'
import {ModalYuserListComponentProps} from './GiftshopModalYuserProps'
import {useSelector} from 'lib/redux'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${convertUnit(60)};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: ${convertUnit(10)};
  padding-bottom: ${convertUnit(10)};
`

const StyledAvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  padding-left: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: ${convertUnit(50)};
  height: ${convertUnit(30)};
`

export default function GiftshopProfileModalYuserListComponent({
  yuserMemberId,
  onClickChat,
}: ModalYuserListComponentProps) {
  const {data} = useFirebaseUser({id: yuserMemberId}) || {}
  const {id} = useSelector('user') || {}
  const {photo, nickname} = data || {}

  const handleRenderAvatar = useMemo(
    () => (
      <StyledAvatarContainer>
        <Avatar src={photo || ''} alt="Avatar" />
        <StyledParagraph fontSize="m" fontWeight="medium">
          {nickname}
        </StyledParagraph>
      </StyledAvatarContainer>
    ),
    [nickname, photo],
  )

  const handleRenderChatButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:chat')}
        onClick={onClickChat}
        disabled={yuserMemberId === id}
      />
    ),
    [id, onClickChat, yuserMemberId],
  )

  return (
    <StyledContainer>
      {handleRenderAvatar}
      {handleRenderChatButton}
    </StyledContainer>
  )
}
