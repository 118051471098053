import React, {useMemo} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {
  GIFT_SHOP_USER_BANK_ACCOUNT_LIMIT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import animationData from '../../../../../assets/lottie/activity_indicator.json'
import {GiftshopBankListContentProps} from './GiftShopBankListContentProps'
import {GiftShopBankListContentItem} from '../ContentItem'

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(25)};
  max-width: ${convertUnit(700)};
  background-color: ${({theme}) => theme.white_1};
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin: 0;
  }
`

const StyledContentItem = styled.div`
  cursor: pointer;
  width: 100%;
`

const StyledLottie = styled(Lottie)`
  width: ${convertUnit(100)};
  height: ${convertUnit(100)};
`

const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
`

export default function GiftShopBankListContent({
  accounts,
  stateSelectedBank,
  contentLoaded,
}: GiftshopBankListContentProps) {
  const {translate} = useTranslation()
  const setSelectedBank = stateSelectedBank[1]

  const renderAccounts = useMemo(
    () =>
      contentLoaded ? (
        accounts.map((account) => (
          <StyledContentItem
            onClick={() => setSelectedBank(account)}
            aria-hidden="true"
            role="button"
            key={account.accountNumber}>
            <GiftShopBankListContentItem
              bankName={account.bankDetail.name}
              bankNumber={account.accountNumber}
              accountName={account.accountName}
              isMainAccount={account.isMainAccount}
              hideMainLabel={accounts.length === 1}
            />
          </StyledContentItem>
        ))
      ) : (
        <StyledLottie
          animationData={animationData}
          height={convertUnit(100)}
          width={convertUnit(100)}
        />
      ),
    [accounts, contentLoaded, setSelectedBank],
  )

  const handleRenderBankAccountLimitInfo = useMemo(
    () => (
      <StyledParagraph color="gray_5">
        {translate('giftShop:bankAccountMaxMessage', {
          max: GIFT_SHOP_USER_BANK_ACCOUNT_LIMIT,
        })}
      </StyledParagraph>
    ),
    [translate],
  )

  return (
    <StyledContainer>
      {renderAccounts}
      {handleRenderBankAccountLimitInfo}
    </StyledContainer>
  )
}
