import React from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import convertUnit from 'lib/unit'
import {ToggleSwitchProps} from './ToggleSwitchProps'
import {Paragraph} from '../Paragraph'

interface StyledToggleProps {
  disabled?: boolean
  alwaysOn?: boolean
  isOffBackgroundColor?: ThemeColor
  isOnBackgroundColor?: ThemeColor
  toggleColor?: ThemeColor
}

const StyledContainer = styled.div`
  position: relative;
  margin-right: ${convertUnit(15)};
  align-self: center;
`

const StyledLabel = styled.label<StyledToggleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${convertUnit(36)};
  height: ${convertUnit(20)};
  border-radius: ${convertUnit(50)};
  background-color: ${({
    theme,
    disabled,
    alwaysOn,
    isOffBackgroundColor = 'gray_3',
  }) => (disabled && !alwaysOn ? theme.gray_1 : theme[isOffBackgroundColor])};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: ${convertUnit(16)};
    height: ${convertUnit(16)};
    margin: ${convertUnit(2)};
    background-color: ${({theme, disabled, toggleColor = 'white_1'}) =>
      disabled ? theme.gray_3 : theme[toggleColor]};
    transition: 0.2s;
  }
`

const StyledInput = styled.input<StyledToggleProps>`
  opacity: 0;
  z-index: 1;
  border-radius: ${convertUnit(50)};
  width: ${convertUnit(16)};
  height: ${convertUnit(16)};
  &:checked + ${StyledLabel} {
    background-color: ${({
      theme,
      disabled,
      alwaysOn,
      isOnBackgroundColor = 'primary_5',
    }) => (disabled && !alwaysOn ? theme.gray_1 : theme[isOnBackgroundColor])};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: ${convertUnit(16)};
      height: ${convertUnit(16)};
      margin-left: ${convertUnit(18)};
      transition: 0.2s;
    }
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 3;
  width: ${convertUnit(32)};
  height: 100%;
  margin: 0 ${convertUnit(2)};
`

const StyledParagraph = styled(Paragraph)`
  padding: 0;
  margin-left: ${convertUnit(1)};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export default function ToggleSwitch({
  toggleKey = 'toggle',
  disabled,
  selected,
  alwaysOn,
  isOffBackgroundColor,
  isOnBackgroundColor,
  toggleColor,
  style,
  textOff,
  textOn,
  onSwitch,
}: ToggleSwitchProps) {
  return (
    <StyledContainer style={style}>
      <StyledInput
        id={toggleKey}
        type="checkbox"
        checked={selected}
        disabled={disabled}
        alwaysOn={alwaysOn}
        isOnBackgroundColor={isOnBackgroundColor}
        onChange={onSwitch}
      />
      <StyledLabel
        disabled={disabled}
        htmlFor={toggleKey}
        toggleColor={toggleColor}
        isOffBackgroundColor={isOffBackgroundColor}>
        <StyledTextContainer>
          <StyledParagraph
            fontSize="xs"
            fontWeight="bold"
            color={selected ? 'black' : 'white_1'}>
            {textOff}
          </StyledParagraph>
          <StyledParagraph
            fontSize="xs"
            fontWeight="bold"
            color={selected ? 'white_1' : 'black'}>
            {textOn}
          </StyledParagraph>
        </StyledTextContainer>
      </StyledLabel>
    </StyledContainer>
  )
}
