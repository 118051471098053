import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {Icon} from '../Icon'
import {ButtonHeaderProps} from './ButtonHeaderProps'

interface StyledContainerProps {
  useDefaultMargin: boolean
  useDefaultPadding: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, useDefaultMargin, useDefaultPadding}) => ({
    color: theme.primary_5,
    margin: useDefaultMargin ? `0 ${convertUnit(4)}` : undefined,
    ':hover': {color: theme.primary_4},
    padding: useDefaultPadding ? convertUnit(12) : undefined,
  })}
  transition: all 0.15s ease-out;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export default function ButtonHeader({
  icon,
  useDefaultMargin = true,
  useDefaultPadding = true,
  size = 24,
  color,
  ...props
}: ButtonHeaderProps) {
  return (
    <StyledContainer
      {...props}
      useDefaultMargin={useDefaultMargin}
      useDefaultPadding={useDefaultPadding}>
      <Icon type={icon} size={convertUnit(size)} color={color || 'inherit'} />
    </StyledContainer>
  )
}
