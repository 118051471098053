import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TemplateAuthAccessModal} from 'pages'
import {
  ForkygramGetCommentResponse,
  ForkygramSelectedComment,
  ObjectState,
  parseShape,
} from 'types'
import {formatRelativeDateDiff, useDebounce, useHistory} from 'utils'
import {
  Icon,
  IconVerifiedBadge,
  Image,
  ListItemSelect,
  Menu,
  Paragraph,
  ParsedText,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(20)};
  cursor: pointer;
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
`
const StyledContentContainer = styled(StyledColumnContainer)`
  display: flex;
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
`

const StyledHeaderContainer = styled(StyledRowContainer)`
  align-items: center;
  gap: ${convertUnit(4)};
  width: 100%;
  justify-content: space-between;
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: ${convertUnit(2)};
  gap: ${convertUnit(16)};
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
  align-self: center;
`
const StyledLikeCommentWrapper = styled(StyledRowContainer)`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const StyledMenu = styled(Menu)`
  display: flex;
  height: 100%;
  align-items: center;
`

interface ForkygramCommentItemTextProps {
  item: ForkygramGetCommentResponse
  ownerId: string
  extraElement?: ReactElement
  stateSelectedComment: ObjectState<ForkygramSelectedComment>
  onReply(): void
  onDelete(): void
  onReport(): void
}
export default function ForkygramCommentItemText({
  item,
  ownerId,
  extraElement,
  stateSelectedComment,
  onReply,
  onDelete,
  onReport,
}: ForkygramCommentItemTextProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const debounce = useDebounce()
  const {access_token, id: userId} = useSelector('user') || {}
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const setSelectedComment = stateSelectedComment[1]
  const {
    member: {username, creator_status, id, photo},
    comment,
    mentions,
    is_creator,
    like_count,
    likes_count,
    is_liked,
    created_at,
    comment_id,
    reply_id,
  } = item
  const [isLikeComment, setIsLikeComment] = useState(is_liked)
  const [likeCommentCount, setLikeCommentCount] = useState(
    like_count || likes_count || 0,
  )
  const [loginModal, setLoginModal] = useState(false)
  const isCommentAuthor = useMemo(() => userId === item.member.id, [
    item.member.id,
    userId,
  ])
  const isPicOwner = useMemo(() => userId === ownerId, [ownerId, userId])
  const canDelete = useMemo(() => isCommentAuthor || isPicOwner, [
    isCommentAuthor,
    isPicOwner,
  ])

  const handleRenderBadge = useMemo(
    () =>
      (creator_status === 'verified' || is_creator === 'verified') && (
        <StyledIconVerifiedBadge />
      ),
    [creator_status, is_creator],
  )

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: id === userId,
        postData: {member_id: id, username, photo},
      },
      username,
    )
  }, [history, id, photo, userId, username])

  const handleRenderOptions = useMemo(
    () => (
      <StyledColumnContainer>
        {!isCommentAuthor && (
          <ListItemSelect
            title={translate('forkygram:reportComment')}
            onClick={() => {
              setToggle((prev) => !prev)
              setSelectedComment({
                comment_id: item.comment_id || item.reply_id,
                member_id: item.member.id,
              })
              onReport()
            }}
          />
        )}
        {canDelete && (
          <ListItemSelect
            title={translate('forkygram:deletePostComment')}
            onClick={() => {
              setToggle((prev) => !prev)
              setSelectedComment({
                comment_id: item.comment_id || item.reply_id,
                member_id: item.member.id,
              })
              onDelete()
            }}
          />
        )}
      </StyledColumnContainer>
    ),
    [
      isCommentAuthor,
      translate,
      canDelete,
      setToggle,
      setSelectedComment,
      item.comment_id,
      item.reply_id,
      item.member.id,
      onReport,
      onDelete,
    ],
  )

  const handleLikeComment = useCallback(() => {
    debounce(() => {
      setIsLikeComment((prev) => !prev)
      if (isLikeComment) {
        setLikeCommentCount((prev) => prev - 1)
        requestData('forkygram_delete_like_comment', {
          data: {comment_id: comment_id || reply_id},
          onRequestFailed: () => {
            setIsLikeComment((prev) => !prev)
            setLikeCommentCount((prev) => prev + 1)
          },
        })
      } else {
        setLikeCommentCount((prev) => prev + 1)
        requestData('forkygram_post_like_comment', {
          data: {comment_id: comment_id || reply_id},
          onRequestFailed: () => {
            setIsLikeComment((prev) => !prev)
            setLikeCommentCount((prev) => prev - 1)
          },
        })
      }
    })
  }, [comment_id, debounce, isLikeComment, reply_id])

  const handlePressLikeComment = useCallback(() => {
    if (access_token) handleLikeComment()
    else setLoginModal(true)
  }, [access_token, handleLikeComment])

  const patterns: parseShape[] = useMemo(
    () =>
      mentions &&
      mentions.map((mention) => {
        const regex = new RegExp(`{{${mention.id}}}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${mention.username}`),
          onClick: () => {
            history.push(
              'giftshop_profile',
              {
                self: mention.id === userId,
                postData: {
                  member_id: mention.id,
                  username: mention.username,
                  photo: mention.photo,
                },
              },
              mention.username,
            )
          },
        }
      }),
    [history, mentions, userId],
  )

  const handleRenderLikeComment = useMemo(
    () => (
      <StyledLikeCommentWrapper>
        <Icon
          size={16}
          type={isLikeComment ? 'like-filled' : 'like-outlined'}
          color={isLikeComment ? 'primary_5' : 'gray_5'}
          onClick={handlePressLikeComment}
        />
        <Paragraph
          color="primary_5"
          fontWeight={isLikeComment ? 'medium' : 'regular'}>
          {likeCommentCount || ''}
        </Paragraph>
      </StyledLikeCommentWrapper>
    ),
    [handlePressLikeComment, isLikeComment, likeCommentCount],
  )

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledAvatar src={photo} alt="avatar" onClick={handleNavigateProfile} />
    ),
    [handleNavigateProfile, photo],
  )

  const handleRenderUsername = useMemo(
    () => (
      <Paragraph
        className="pressable"
        fontWeight="bold"
        onClick={handleNavigateProfile}>
        {username}
      </Paragraph>
    ),
    [handleNavigateProfile, username],
  )

  const handleRenderDate = useMemo(() => formatRelativeDateDiff(created_at), [
    created_at,
  ])

  const handleRenderActions = useMemo(
    () => (
      <StyledMenu
        stateToggle={stateToggle}
        renderContent={handleRenderOptions}
        contentContainerStyle={{
          width: '100%',
          minWidth: convertUnit(185),
          right: 0,
          top: convertUnit(-60),
          borderRadius: convertUnit(8),
          zIndex: 1,
        }}>
        <Icon
          size={16}
          color="gray_5"
          type="more-filled"
          style={{cursor: 'pointer'}}
          onClick={() =>
            access_token ? setToggle((prev) => !prev) : setLoginModal(true)
          }
        />
      </StyledMenu>
    ),
    [stateToggle, handleRenderOptions, access_token, setToggle],
  )

  return (
    <>
      {handleRenderLoginModal}
      {handleRenderAvatar}
      <StyledContentContainer>
        <StyledHeaderContainer>
          <StyledRowContainer>
            {handleRenderUsername}
            {handleRenderBadge}
          </StyledRowContainer>
          <Paragraph color="gray_5">{handleRenderDate}</Paragraph>
        </StyledHeaderContainer>
        <Paragraph id="comment">
          <ParsedText parse={patterns}>{comment}</ParsedText>
        </Paragraph>
        <StyledDescriptionContainer>
          {handleRenderLikeComment}
          <Paragraph
            fontWeight="bold"
            color="gray_5"
            style={{cursor: 'pointer'}}
            onClick={() => (access_token ? onReply() : setLoginModal(true))}>
            {translate('forkygram:reply')}
          </Paragraph>
          {handleRenderActions}
        </StyledDescriptionContainer>
        {extraElement}
      </StyledContentContainer>
    </>
  )
}
