import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AuthCountryResponse} from 'types'
import convertUnit from 'lib/unit'
import {Input} from '../Input'
import {InputPhoneProps} from './InputPhoneProps'
import {List} from '../List'
import {Paragraph} from '../Paragraph'
import {Image} from '../Image'
import {Menu} from '../Menu'
import {DropdownItem} from '../DropdownItem'
import {ListItemSelect} from '../ListItemSelect'

const StyledContentListContainer = styled.div`
  ${({theme}) => ({
    borderTop: `${convertUnit(1)} solid ${theme.gray_1}`,
  })}
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledItemDialCode = styled(Paragraph)`
  margin-left: ${convertUnit(5)};
`

const StyledDropdownItem = styled(DropdownItem)`
  margin-right: ${convertUnit(16)};
`

const StyledEmptyContent = styled.div`
  display: flex;
  padding: ${convertUnit(10)} ${convertUnit(20)};
  align-items: center;
  justify-content: center;
`

const StyledImageFlag = styled(Image)`
  ${({theme}) => ({borderColor: theme.black})}
  width: ${convertUnit(24)};
  height: ${convertUnit(16)};
  border-style: solid;
  border-width: ${convertUnit(1)};
  margin-right: ${convertUnit(8)};
`

export default function InputPhone<TFieldValues extends object = object>({
  searchPlaceholder,
  stateSelect,
  emptyMessage,
  loadData,
  ...props
}: InputPhoneProps<TFieldValues>) {
  const [search, setSearch] = useState('')
  const stateToggle = useState(false)
  const [toggle, setToggle] = stateToggle
  const [select, setSelect] = stateSelect
  const data = useMemo(() => loadData(search), [search, loadData])

  const handleSelect = useCallback(
    (item: AuthCountryResponse) => {
      setSelect(item)
      setToggle((previous) => !previous)
    },
    [setSelect, setToggle],
  )

  const handleRenderDropdownItem = useCallback(
    (item: AuthCountryResponse) => (
      <ListItemSelect
        title={item.country_name}
        leftElement={
          <StyledImageFlag
            src={item.flag_url}
            alt={`flag: ${item.country_id}`}
          />
        }
        rightElement={
          <StyledItemDialCode fontSize="m" fontWeight="medium" color="gray_5">
            (+{item.dial_code})
          </StyledItemDialCode>
        }
        onClick={() => handleSelect(item)}
      />
    ),
    [handleSelect],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <Input
          containerStyle={{
            padding: convertUnit(8),
          }}
          onChangeText={setSearch}
          placeholder={searchPlaceholder}
        />
        <StyledContentListContainer>
          {data.length ? (
            <List
              data={data}
              keyExtractor={(item) => item.country_id}
              renderItem={handleRenderDropdownItem}
            />
          ) : emptyMessage ? (
            <StyledEmptyContent>
              <Paragraph fontSize="m" color="gray_3">
                {emptyMessage(search)}
              </Paragraph>
            </StyledEmptyContent>
          ) : null}
        </StyledContentListContainer>
      </>
    ),
    [searchPlaceholder, data, search, handleRenderDropdownItem, emptyMessage],
  )

  return (
    <Menu
      stateToggle={stateToggle}
      contentContainerStyle={{
        height: convertUnit(250),
        borderRadius: convertUnit(8),
      }}
      renderContent={handleRenderContent}>
      <Input
        {...props}
        leftElement={
          <StyledDropdownItem
            label={`${select.country_id} +${select.dial_code}`}
            toggle={toggle}
            onClick={() => setToggle((previous) => !previous)}
          />
        }
      />
    </Menu>
  )
}
