import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateFullStorageProps} from './TemplateFullStorageProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(420)};
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(20)};
`

const StyledIcon = styled.div`
  margin-top: ${convertUnit(10)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(10)};
`

export default function TemplateFullStorageModal({
  toggleModal,
}: TemplateFullStorageProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible onBackdropPress={toggleModal}>
      <StyledIcon onClick={toggleModal}>
        <Icon type="info-ol" size={convertUnit(50)} />
      </StyledIcon>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {translate('giftShop:storageFullTitle')}
      </StyledParagraph>
      <StyledParagraph fontSize="m" fontWeight="medium">
        {translate('giftShop:storageFullMessage')}
      </StyledParagraph>
      <StyledButton label={translate('global:ok')} onClick={toggleModal} />
    </StyledModal>
  )
}
