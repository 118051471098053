import React from 'react'
import {useTranslation} from 'i18n'
import {AuthLoginWefieWaitingModalProps} from './AuthLoginWefieWaitingModalProps'
import {TemplateSorryModalContent} from '../../../../template'

export default function AuthLoginWefieWaitingModal({
  visible,
  toggleModal,
}: AuthLoginWefieWaitingModalProps) {
  const {translate} = useTranslation()

  return (
    <TemplateSorryModalContent
      visible={visible}
      upperDesc={translate('auth:wefieWaitingModalTitle')}
      bottomDesc={translate('auth:wefieWaitingModalDescription')}
      toggleModal={toggleModal}
    />
  )
}
