import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {ForkygramGetCommentResponse} from 'types'
import {ListLazy, Paragraph} from 'common/components'
import {ForkygramCommentItem} from '../ForkygramComment'
import {ForkygramCommentContentProps} from './ForkygramCommentModalContentProps'

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function ForkygramCommentContent({
  postId,
  ownerId,
  stateRecipient,
  stateSearch,
  onDelete,
  onReport,
  stateCommentData,
  stateRepliedToComment,
}: ForkygramCommentContentProps) {
  const {translate} = useTranslation()
  const search = stateSearch[0]

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('forkygram_get_comment', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, post_id: postId},
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : null
    },
    [postId],
  )

  const handleKeyExtractor = useCallback(
    (item: ForkygramGetCommentResponse) => item.comment_id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramGetCommentResponse) => (
      <ForkygramCommentItem
        data={item}
        ownerId={ownerId}
        stateRecipient={stateRecipient}
        onDelete={onDelete}
        onReport={onReport}
        stateRepliedToComment={stateRepliedToComment}
      />
    ),
    [onDelete, onReport, ownerId, stateRecipient, stateRepliedToComment],
  )

  const handleRenderContent = useMemo(
    () => (
      <ListLazy
        loadData={handleLoadData}
        stateData={stateCommentData}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        search={search}
        listEmptyElement={
          <StyledEmptyContainer>
            <Paragraph fontSize="s" color="gray_3">
              {translate('forkygram:emptyComment')}
            </Paragraph>
          </StyledEmptyContainer>
        }
      />
    ),
    [
      handleKeyExtractor,
      handleLoadData,
      handleRenderItem,
      search,
      stateCommentData,
      translate,
    ],
  )

  return handleRenderContent
}
