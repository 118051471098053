import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  AUTH_DOB_MIN_AGE,
  AUTH_DOB_MIN_YEAR,
  ONE_YEAR_MILISECONDS,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {parseDate} from 'utils'
import {Button, Icon, Input, Paragraph} from 'common/components'
import {VALIDATION_GIFTSHOP_EDITPROFILE_DOB} from 'common/validations'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopFormPropsBase} from './GiftShopFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(50)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(770)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`
const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(25)};
  border-radius: ${convertUnit(30)};
`

const StyledWarningContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_3,
  })}
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(15)};
  align-items: center;
  margin-top: ${convertUnit(20)};
  height: ${convertUnit(46)};
`

const StyledParagraphInfo = styled(Paragraph)`
  margin-left: ${convertUnit(15)};
`

export default function GiftShopDateOfBirthForm({
  defaultValue,
  stateActiveContent,
}: GiftShopFormPropsBase) {
  const setActiveContent = stateActiveContent[1]
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const offset = useMemo(() => new Date().getTimezoneOffset(), [])
  const date = useMemo(() => new Date(new Date().getTime() - offset * 60000), [
    offset,
  ])
  const today = date.toISOString().substring(0, 10)
  const defVal = defaultValue?.split('/').reverse().join('-')
  const userData = useSelector('user')

  const form = useForm<{dateOfBirth: Date}>({
    defaultValues: {
      dateOfBirth: defVal || today,
    },
  })

  const {handleSubmit, watch, setValue, errors} = form
  const {dateOfBirth} = watch()
  const [loading, setLoading] = useState(false)
  const maxYear = parseInt(today.substring(0, 4), 10) - 1
  const [focus, setFocus] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [yearDif, setYearDif] = useState(0)
  const [dobValue, setDobValue] = useState('')

  const handleCheckValidity = useCallback(() => {
    const dobDate = new Date(dateOfBirth)
    const dobStr = dateOfBirth.toString()
    const dobYear = dobStr.substring(0, 4)
    const intDobYear = parseInt(dobYear, 10)
    setYearDif((date.getTime() - dobDate.getTime()) / ONE_YEAR_MILISECONDS)

    if ((!focus && dobDate > date) || dobStr.length > 10) {
      setValue('dateOfBirth', today)
    }

    if (
      (!dobYear.startsWith('0') && intDobYear < AUTH_DOB_MIN_YEAR) ||
      (!focus && dobYear.startsWith('0'))
    ) {
      setValue('dateOfBirth', `${AUTH_DOB_MIN_YEAR}${dobStr.substring(4, 10)}`)
    }

    if (!focus && intDobYear > maxYear) {
      setValue('dateOfBirth', `${maxYear}${dobStr.substring(4, 10)}`)
    }

    setDisabled(
      dateOfBirth === undefined ||
        dobStr === defVal ||
        dobStr === '' ||
        yearDif < AUTH_DOB_MIN_AGE ||
        Object.keys(errors).length > 0 ||
        dobYear.startsWith('0'),
    )

    setDobValue(parseDate(dobStr, 'DD/MM/YYYY'))
  }, [
    date,
    dateOfBirth,
    defVal,
    errors,
    focus,
    maxYear,
    setValue,
    today,
    yearDif,
  ])

  const handleFormSubmit = useCallback(async () => {
    setLoading(true)

    await requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      dataType: 'form-data',
      data: {date_of_birth: dobValue, bio: userData?.biodata},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          update('user', {date_of_birth: dobValue})
          setActiveContent('profile')
        }
      },
    })
  }, [dobValue, setActiveContent, update, userData?.biodata])

  useEffect(() => {
    handleCheckValidity()
  }, [handleCheckValidity])

  const handleRenderWarning = useMemo(
    () => (
      <StyledWarningContainer>
        <Icon type="info" size={16} />
        <StyledParagraphInfo fontSize="m" fontWeight="medium">
          {translate('giftShop:changeDateOfBirthMessage')}
        </StyledParagraphInfo>
      </StyledWarningContainer>
    ),
    [translate],
  )

  return (
    <StyledContainer>
      <StyledScreenContainer>
        <Input
          name="dateOfBirth"
          type="date"
          form={form}
          formRules={VALIDATION_GIFTSHOP_EDITPROFILE_DOB(yearDif)}
          label={translate('global:dateOfBirth')}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          min={`${AUTH_DOB_MIN_YEAR}-01-01`}
          max={`${maxYear}-12-01`}
        />
        {handleRenderWarning}
        <StyledButton
          disabled={disabled}
          isLoading={loading}
          label={translate('global:save')}
          onClick={handleSubmit(handleFormSubmit)}
        />
      </StyledScreenContainer>
    </StyledContainer>
  )
}
