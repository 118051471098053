import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {formatHours, getCurrencyValue, parseDate} from 'utils'
import {TransactionInfoItem} from '../InfoItem'
import {PaymentCheckoutUserInfoProps} from './PaymentUserInfoProps'

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledInfoItem = styled(TransactionInfoItem)`
  margin-top: ${convertUnit(10)};
`

const StyledDurationText = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
`

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function PaymentUserInfo({
  currentDate,
  expirationDate,
  timeDiff,
  expectedAmount,
  totalPrice,
  extraFee,
  username,
}: PaymentCheckoutUserInfoProps) {
  return (
    <StyledSection>
      <StyledInfoItem
        title={translate('giftShop:checkoutTransactionDate')}
        description={parseDate(
          currentDate.toISOString(),
          'MMMM D, YYYY, HH:mm',
        )}
      />
      <StyledDateContainer>
        <StyledDurationText fontSize="m" fontWeight="medium">
          {translate('giftShop:checkoutDurationEnds')}
        </StyledDurationText>
        <StyledDurationText fontSize="m" fontWeight="medium">
          {translate('giftShop:checkoutTimeLeft')}
        </StyledDurationText>
      </StyledDateContainer>
      <StyledDateContainer>
        <Paragraph fontSize="m">
          {parseDate(expirationDate, 'MMMM D, YYYY, HH:mm')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="medium" color="primary_5">
          {formatHours(timeDiff.hours, timeDiff.minutes, timeDiff.seconds)}
        </Paragraph>
      </StyledDateContainer>
      <StyledInfoItem
        title={translate('giftShop:totalPayment')}
        description={getCurrencyValue(expectedAmount)}
        totalPrice
        boldDescription
      />
      <StyledInfoItem
        title={translate('giftShop:totalPrice')}
        description={getCurrencyValue(totalPrice)}
        details
      />
      <TransactionInfoItem
        title={translate('giftShop:paymentFee')}
        description={getCurrencyValue(extraFee)}
        details
      />
      <StyledInfoItem
        title={translate('giftShop:accountName')}
        description={username}
        boldDescription
      />
    </StyledSection>
  )
}
