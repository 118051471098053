import React from 'react'
import {Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'

export interface GiftShopMessageRoomContentItemNotificationHostCreatorDemotionProps {
  payload: FirebaseMessageRoomItemPayload['notification_demotion_host_creator']
}

export default function GiftShopMessageRoomContentItemNotificationHostCreatorDemotion({
  payload: {host_tag, username, creator_type},
}: GiftShopMessageRoomContentItemNotificationHostCreatorDemotionProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:hostCreatorDemotion', {
        username,
        host_tag,
        creator_type: translate('tree:hostCreatorType', {
          context: creator_type,
        }).toLowerCase(),
      })}
    </Paragraph>
  )
}
