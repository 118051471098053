import React, {useMemo} from 'react'
import styled from 'styled-components'
import {formatDateRange, getBorder} from 'utils'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {IMAGE_ASSET} from 'consts'
import {TreeEventsContentItemProps} from './TreeEventsContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(17)};
  flex: 1;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const StyledImage = styled(Image)`
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
  border-radius: ${convertUnit(8)};
  max-width: ${convertUnit(56)};
  width: 100%;
  aspect-ratio: 0.75;
  object-fit: contain;
`

const StyledItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(2.5)};
  justify-content: center;
`

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
`

const StyledTitle = styled(Paragraph)`
  word-break: break-word;
  margin-left: ${convertUnit(6)};
`

export default function TreeEventsContentItem({
  data,
  onClickItem,
}: TreeEventsContentItemProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const {event_end_date, event_start_date, url, name} = data
  const parsedDate = useMemo(
    () =>
      event_start_date && event_end_date
        ? formatDateRange(event_start_date, event_end_date)
        : '',
    [event_end_date, event_start_date],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={url} fallbackSrc={defaultImage} alt={name || ''} />,
    [defaultImage, name, url],
  )

  const handleRenderDate = useMemo(
    () =>
      event_start_date ? (
        <StyledDateContainer>
          <Icon type="calendar-event" size={16} color="gray_5" />
          <Paragraph fontWeight="medium" color="gray_5">
            {parsedDate}
          </Paragraph>
        </StyledDateContainer>
      ) : (
        <></>
      ),
    [event_start_date, parsedDate],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledItemInfoContainer>
        <StyledTitle fontSize="m" fontWeight="bold">
          {name}
        </StyledTitle>
        {handleRenderDate}
      </StyledItemInfoContainer>
    ),
    [handleRenderDate, name],
  )

  return (
    <StyledContainer onClick={onClickItem}>
      {handleRenderImage}
      {handleRenderInfo}
    </StyledContainer>
  )
}
