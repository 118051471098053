import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootEmpty, GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import {GiftShopGetCollectorResendApprovalResponse} from 'types'
import {showSnackbar, useHistory} from 'utils'
import {ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopResendApprovalContent} from '../containers'

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: ${convertUnit(12)} 0;
  text-align: center;
`

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export default function GiftShopCollectorResendApprovalScreen() {
  const {translate} = useTranslation()
  const stateData = useState<
    readonly GiftShopGetCollectorResendApprovalResponse[]
  >([])
  const [data, setData] = stateData
  const emptyImg = IMAGE_ASSET('giftshop', 'empty.png')
  const history = useHistory()

  const handleLoadData = useCallback(async () => {
    const response = await requestData(
      'gitfshop_get_collection_resend_approval',
      {
        actionType: 'fetch',
        useDefaultMessage: true,
      },
    )

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleRemoveContent = useCallback(
    (content_id: string, invoice_id: string) => {
      setData((prev) =>
        prev.filter(
          (item) =>
            item.content_id + item.invoice_id !== content_id + invoice_id,
        ),
      )
      if (data.length === 1) {
        history.replace('giftshop_collection', {})
      }
    },
    [data.length, history, setData],
  )

  const handleClickComplete = useCallback(
    (content_id: string, invoice_id: string) => {
      requestData('gitfshop_patch_collection_resend_approval', {
        actionType: 'execute',
        useDefaultMessage: true,
        data: {content_id, status: 'COMPLETED'},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('giftShop:hiresConfirmationSuccessSnackbar'))
            handleRemoveContent(content_id, invoice_id)
          }
        },
      })
    },
    [data.length, handleRemoveContent, history, translate],
  )

  const handleRenderHeader = useMemo(
    () =>
      data.length > 0 ? (
        <StyledHeader>
          <StyledTitle>
            <Paragraph fontWeight="bold" color="primary_5">
              {translate('giftShop:notHires')}
            </Paragraph>
          </StyledTitle>
          <StyledTitle>
            <Paragraph fontWeight="bold" color="primary_5">
              {translate('global:hires')}
            </Paragraph>
          </StyledTitle>
        </StyledHeader>
      ) : (
        <></>
      ),
    [data.length, translate],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopGetCollectorResendApprovalResponse) => (
      <GiftShopResendApprovalContent
        data={item}
        onComplete={() => handleClickComplete(item.content_id, item.invoice_id)}
        onChatExpired={handleRemoveContent}
      />
    ),
    [handleClickComplete, handleRemoveContent],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImg}
        imageAlt={emptyImg}
        title={translate('giftShop:hiresConfirmationEmptyTitle')}
        message={translate('giftShop:hiresConfirmationEmptyInfo')}
      />
    ),
    [emptyImg, translate],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        loadData={handleLoadData}
        stateData={stateData}
        keyExtractor={(item) => item.content_id}
        renderItem={handleRenderItem}
        listHeaderElement={handleRenderHeader}
        listEmptyElement={handleRenderEmpty}
      />
    ),
    [
      handleLoadData,
      handleRenderEmpty,
      handleRenderHeader,
      handleRenderItem,
      stateData,
    ],
  )

  return (
    <GiftShopRootScreen
      headerTitle={translate('giftShop:hiresConfirmationHeader')}
      showBadgeIcon={false}>
      {handleRenderList}
    </GiftShopRootScreen>
  )
}
