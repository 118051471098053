import daysjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/id'
import {DATE_MONTH_LIST, OTP_COOLDOWN} from 'consts'
import {translate} from 'i18n'
import {DateFormat} from 'types'
import {REDUX_STORE} from 'lib/redux'

export function formatDate(ISOString: string, locale: string = 'en') {
  return Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(ISOString))
}

export function parseDate(d: string, format: string) {
  const {lang} = REDUX_STORE.getState().user || REDUX_STORE.getState().languageState
  return d ? daysjs(d).locale(`${lang}`).format(format) : ''
}

export function getLocalDate(timestamp: Date | string | number) {
  const date = typeof timestamp === 'object' ? timestamp : new Date(timestamp)
  const offset = date.getTimezoneOffset() * 60 * 1000

  return new Date(date.getTime() - offset)
}

export function formatHours(
  h: number,
  m: number,
  s: number,
  format: 'HH:MM:SS' | 'HHhMMm' = 'HH:MM:SS',
) {
  if (h < 0 && m < 0 && s < 0) {
    return format === 'HHhMMm'
      ? translate('global:dateFormat', {hour: 0, minute: 0})
      : '00:00:00'
  }
  const padZero = (val: number) => (val < 10 ? `0${val}` : val)
  const formattedTime = `${padZero(h)}:${padZero(m)}:${padZero(s)}`
  return format === 'HHhMMm'
    ? translate('global:dateFormat', {hour: h, minute: m})
    : formattedTime
}

export function calcTimeDiff(dateA: Date, dateB: string) {
  const expDate = new Date(dateB).getTime()
  const currDate = dateA.getTime()
  const hours = Math.floor((expDate - currDate) / 3600000)
  const minutes = Math.floor(((expDate - currDate) / 60000) % 60)
  const seconds = Math.floor(((expDate - currDate) / 1000) % 60)
  return {hours, minutes, seconds}
}

export function calcAddMinutes(dateA: string, currDate: Date, minAdd: number) {
  const expiryDate = new Date(dateA).getTime() + minAdd * 60000
  const currentDate = currDate.getTime()
  const hours = Math.floor((expiryDate - currentDate) / 3600000)
  const minutes = Math.floor(((expiryDate - currentDate) / 60000) % 60)
  const seconds = Math.floor(((expiryDate - currentDate) / 1000) % 60)
  return {hours, minutes, seconds}
}

export function parseShortDate(date: string, format?: DateFormat) {
  const day = date.slice(-2).startsWith('0') ? date.slice(-1) : date.slice(-2)
  const mo = date.substring(5, 7).startsWith('0')
    ? date.substring(6, 7)
    : date.substring(5, 7)
  const moNum = parseInt(mo, 10)
  const year = date.substring(0, 4)

  if (format === 'dd MMMM yyyy') {
    return `${day} ${translate('global:month', {
      context: DATE_MONTH_LIST[moNum - 1],
    })} ${year}`
  }

  return `${day} ${translate('global:month', {
    context: DATE_MONTH_LIST[moNum - 1],
  }).substring(0, 3)} ${year}`
}

export function formatExploreFilterDate(startDate?: string, endDate?: string) {
  let startTimestamp: number | undefined
  let endTimestamp: number | undefined

  if (startDate) {
    startTimestamp = new Date(startDate).getTime() / 1000
    endTimestamp = endDate ? new Date(endDate).getTime() / 1000 : undefined
  }
  return {startTimestamp, endTimestamp}
}

export function getCurrentRelativeISOString() {
  const offset = new Date().getTimezoneOffset()
  const date = new Date(new Date().getTime() - offset * 60000)
  const today = date.toISOString()

  return today
}

export function formatRelativeDateDiff(date: string) {
  const currentDate = new Date().toISOString()

  const {hours, minutes, seconds} = calcTimeDiff(new Date(date), currentDate)

  if (hours > 0) {
    const day = hours / 24

    if (day >= 30) return parseShortDate(date.substring(0, 10), 'dd MMMM yyyy')

    return hours >= 24
      ? translate('forkygram:countUploadDuration', {
          context: 'day',
          count: Math.floor(day),
        })
      : translate('forkygram:countUploadDuration', {
          context: 'hour',
          count: hours,
        })
  }

  if (minutes > 0) {
    return translate('forkygram:countUploadDuration', {
      context: 'minute',
      count: minutes,
    })
  }

  return translate('forkygram:countUploadDuration', {
    count: seconds > 0 ? seconds : 0,
  })
}

export function secondToHms(totalSecond: number) {
  let remainingTime = totalSecond
  const hour = Math.floor(remainingTime / 3600)
  remainingTime %= 3600
  const minute = Math.floor(remainingTime / 60)
  remainingTime %= 60
  const second = remainingTime

  return {hour, minute, second}
}

export function extracthmsTime(timestring: string, defaultValue?: number) {
  if (timestring.length > 0) {
    let temp = 0
    let hour = 0
    let minute = 0
    let second = 0
    let totalSec = 0
    for (let i = 0; i < timestring.length; i += 1) {
      const digit = timestring[i]
      if (digit >= '0' && digit <= '9') {
        if (i > 0) temp *= 10
        temp += parseInt(digit, 36)
      } else {
        switch (digit) {
          case 'h' || 'H':
            hour = temp
            break
          case 'm' || 'M':
            minute = temp
            break
          default:
            second = temp
            break
        }
        temp = 0
      }
    }
    totalSec = hour * 3600 + minute * 60 + second
    return {hour, minute, second, totalSec}
  }
  return {
    hour: undefined,
    minute: undefined,
    second: defaultValue || 0,
    totalSec: defaultValue || 0,
  }
}

export function getAge(text: string) {
  let age = -1
  if (text.length === 10) {
    const textSplit = text.split('/')
    const year = parseInt(textSplit[2], 10)
    const month = parseInt(textSplit[1], 10) - 1
    const day = parseInt(textSplit[0], 10)

    const currDate = new Date()
    const birthDate = new Date()
    birthDate.setFullYear(year, month, day)

    age = currDate.getFullYear() - birthDate.getFullYear()
    const m = currDate.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && currDate.getDate() < birthDate.getDate())) {
      age -= 1
    }
  }
  return age
}

export function validateMinimumAge(text: string, minimumAge: number) {
  if (text.length === 10) {
    const age = getAge(text)
    if (age < minimumAge) {
      return false
    }
  }
  return true
}

export function checkLaterThanToday(compare: string) {
  const offset = new Date().getTimezoneOffset()
  const today = new Date(new Date().getTime() - offset * 60000)
  const todayYear = today.getFullYear()
  const todayMonth = today.getMonth() + 1
  const todayDay = today.getDate()
  const compareDate = new Date(compare)
  const compareYear = compareDate.getFullYear()
  const compareMonth = compareDate.getMonth() + 1
  const compareDay = compareDate.getDate()

  if (compareYear > todayYear) return true
  if (compareYear === todayYear && compareMonth > todayMonth) return true
  if (
    compareYear === todayYear &&
    compareMonth === todayMonth &&
    compareDay > todayDay
  )
    return true
  return false
}

export function checkFutureTime(compare: string) {
  const today = new Date()
  const todayHour = today.getHours()
  const todayMin = today.getMinutes()
  const compareHour = parseInt(compare.substring(0, 2), 10)
  const compareMin = parseInt(compare.substring(3, 5), 10)

  if (compareHour > todayHour) return true
  if (compareHour === todayHour && compareMin > todayMin) return true
  return false
}

export function formatDateRange(dateA: number, dateB: number) {
  const lang = REDUX_STORE.getState().user?.lang
  const startDate = daysjs.unix(dateA).locale(`${lang}`)
  const endDate = daysjs.unix(dateB).locale(`${lang}`)

  let date: string
  if (startDate.isSame(endDate, 'day')) {
    date = startDate.format('D MMMM YYYY')
  } else if (startDate.isSame(endDate, 'month')) {
    date = `${startDate.format('D')} - ${endDate.format('D MMMM YYYY')}`
  } else if (startDate.isSame(endDate, 'year')) {
    date = `${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`
  } else {
    date = `${startDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`
  }
  return date
}

export function extractNumberFromString(
  str: string,
  expired: number = OTP_COOLDOWN,
) {
  const currentTime = new Date()
  const matches = str.match(/\d+/g)
  const time = matches ? matches.map(Number) : []
  const expiredInMilliseconds = expired * 1000
  let [hours = 0, minutes = 0, seconds = 0] = time

  hours = time.length >= 3 ? time[0] : 0
  minutes = time.length >= 2 ? time[time.length - 2] : 0
  seconds = time.length >= 1 ? time[time.length - 1] : 0

  const remainingTime = (hours * 3600 + minutes * 60 + seconds) * 1000
  const elapsedTime = currentTime.setTime(
    currentTime.getTime() - (expiredInMilliseconds - remainingTime),
  )

  return time.length <= 0
    ? new Date().getTime()
    : new Date(elapsedTime).getTime()
}

export function formatTimestampToDate(timestamp: number) {
  const date = new Date(timestamp * 1000)
  const lpad2 = (val: number) => String(val).padStart(2, '0')

  const year = date.getFullYear()
  const month = lpad2(date.getMonth() + 1)
  const day = lpad2(date.getDate())

  return `${year}-${month}-${day}`
}

export function formatCooldownTime(
  time: string,
  includeDay: boolean = false,
  showUnit: boolean = false,
) {
  const lang = REDUX_STORE.getState().user?.lang
  let hour = time.includes('h') ? time.split('h')[0] : ''
  let minute = time.includes('m')
    ? hour !== ''
      ? time.split('h')[1].split('m')[0]
      : time.split('m')[0]
    : ''
  let second = time.includes('s')
    ? minute !== ''
      ? time.split('m')[1].split('s')[0]
      : time.split('s')[0]
    : ''
  let day = ''
  if (includeDay && parseInt(hour, 10) >= 24) {
    day = Math.floor(parseInt(hour, 10) / 24).toString()
    hour = (parseInt(hour, 10) % 24).toString()
  }
  if (showUnit) {
    if (lang === 'en') {
      day = day ? `${day}d` : ''
      hour = hour ? `${hour}h` : ''
      minute = minute ? `${minute}m` : ''
      second = second ? `${second}s` : ''
    } else {
      day = day ? `${day}h` : ''
      hour = hour ? `${hour}j` : ''
      minute = minute ? `${minute}m` : ''
      second = second ? `${second}d` : ''
    }
  }
  return {day, hour, minute, second}
}

export function getWithdrawInfoDate(date: string) {
  const dateObject = new Date(date)
  const dotw = dateObject.getDay()
  const dotm = dateObject.getDate()
  const hour = dateObject.getHours()
  const year = dateObject.getFullYear()
  const month = dateObject.getMonth()

  let ddCheck = 0
  let ddTransfer = 0

  if (
    ((dotw >= 1 && hour >= 12) || dotw > 1) &&
    ((dotw <= 4 && hour <= 11) || dotw < 4)
  ) {
    ddCheck = 4 - dotw
    ddTransfer = 8 - dotw
  } else {
    ddCheck = dotw <= 1 ? (8 - dotw) % 7 : 8 - dotw
    ddTransfer = dotw <= 1 ? (10 - dotw) % 7 : 10 - dotw
  }

  const checkDateObject = new Date(year, month, dotm, 12, 0)
  checkDateObject.setDate(dotm + ddCheck)
  const transferDateObject = new Date(year, month, dotm, 15, 0)
  transferDateObject.setDate(dotm + ddTransfer)

  return {
    checkDate: checkDateObject.toISOString(),
    transferDate: transferDateObject.toISOString(),
  }
}
