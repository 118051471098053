import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Input} from 'common/components'
import {VALIDATION_GIFTSHOP_UPLOAD_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {GiftShopUploadContentDateTimeFormProps} from './GiftShopUploadContentFormProps'

const StyledDateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

export default function GiftShopUploadContentDateTimeForm({
  stateFileDate,
  stateFileTime,
  form,
}: GiftShopUploadContentDateTimeFormProps) {
  const {translate} = useTranslation()
  const [fileDate, setFileDate] = stateFileDate
  const [fileTime, setFileTime] = stateFileTime
  const {setValue} = form

  return (
    <StyledDateTimeContainer>
      <Input
        name="date"
        label={translate('global:date')}
        defaultValue={fileDate}
        onChangeText={(text) => {
          if (text.length <= 10) {
            setFileDate(text)
          } else {
            setValue('date', fileDate)
            setFileDate(fileDate)
          }
        }}
        form={form}
        formRules={VALIDATION_GIFTSHOP_UPLOAD_REQUIRED}
        autoComplete={false}
        type="date"
        containerStyle={{
          marginRight: convertUnit(25),
          marginTop: convertUnit(25),
          width: '100%',
        }}
      />
      <Input
        name="time"
        defaultValue={fileTime}
        onChangeText={setFileTime}
        label={translate('global:time')}
        form={form}
        formRules={VALIDATION_GIFTSHOP_UPLOAD_REQUIRED}
        autoComplete={false}
        type="time"
        containerStyle={{
          marginTop: convertUnit(25),
          width: '100%',
        }}
      />
    </StyledDateTimeContainer>
  )
}
