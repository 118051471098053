export const API_AUTH_CHECK_CHANGE_PASSWORD_TOKEN =
  '/member/v2/auth/password/verify'
export const API_AUTH_CHECK_EMAIL = '/member/v1/check/email'
export const API_AUTH_CHECK_PHONE = '/member/v1/check/phone'
export const API_AUTH_CHECK_USERNAME = '/member/v1/check/username'
export const API_AUTH_FORGOT_PASSWORD = '/member/v2/forgot/token'
export const API_AUTH_LOGIN = '/gs/v2/auth/login'
export const API_AUTH_LOGIN_CHECK_ACCOUNT_STATUS = '/oauth/v3/login'
export const API_AUTH_OAUTH_LOGIN = '/oauth/v1/login'
export const API_AUTH_RESEND_EMAIL = '/member/v1/resend/email'
export const API_AUTH_RESEND_FORGOT_TOKEN = '/member/v2/resend/forgot/token'
export const API_AUTH_RESEND_PHONE = '/member/v2/resend/phone'
export const API_AUTH_RESET_PASSWORD = '/member/v2/forgot/password'
export const API_AUTH_VERIFY_EMAIL = '/member/v1/verify/email'
export const API_AUTH_VERIFY_PHONE = '/member/v2/verify/phone'
export const API_EASY_SIGNUP_EMAIL = '/oauth/v3/auth/register/email'
export const API_EASY_SIGNUP_PHONE = '/oauth/v4/auth/register/phone'
export const API_EASY_SIGNUP_CHILDREN_KYC_VERIFICATION =
  '/parental/v2/children/registrations/verifications'
export const API_EASY_SIGNUP_CHILDREN_KYC_VERIFICATION_LINKS =
  '/parental/v2/children/registrations/verifications/links'
export const API_EASY_SIGNUP_KYC_VERIFICATION = '/oauth/v1/kyc/verification'
export const API_EASY_SIGNUP_KYC_VERIFICATION_LINKS =
  '/oauth/v1/kyc/verification/links'
export const API_EASY_SIGNUP_VERIFY = '/oauth/v1/register/verification'
export const API_EASY_SIGNUP_GOOGLE = '/sso/v4/google/oauth'
export const API_EASY_SIGNUP_APPLE = '/sso/v4/apple/oauth'
export const API_EASY_SIGNUP_RESEND = '/oauth/v1/register/resend'
export const API_EASY_SIGNUP_UPDATE_EMAIL = '/oauth/v1/register/email'
export const API_EASY_SIGNUP_UPDATE_PHONE = '/oauth/v1/register/phone'
export const API_EASY_SIGNUP_RESEND_BY_CODE = '/oauth/v1/register/resend/code'
export const API_EASY_SIGNUP_CODE_STATUS = '/oauth/v1/register/code/status'
export const API_FORKYGRAM_ADMIN_STATUS = '/post/v1/administrators/status'
export const API_FORKYGRAM_MESSAGE = '/dm/v1/messages'
export const API_FORKYGRAM_MESSAGE_ROOM = '/dm/v1/rooms'
export const API_GIFTSHOP_ACCOUNT = '/gs/v1/accounts'
export const API_GIFTSHOP_ACCOUNT_SENSITIVITY = '/gs/v1/accounts/sensitivity'
export const API_GIFTSHOP_ACCOUNT_STATUS = '/gs/v1/accounts/status'
export const API_GIFTSHOP_ARCHIVE = '/gs/v1/archives'
export const API_GIFTSHOP_BALANCE = '/payment/v2/withdraw/balance'
export const API_GIFTSHOP_CART = '/gs/v1/carts'
export const API_GIFTSHOP_CART_COUNT = '/gs/v1/carts/count'
export const API_GIFTSHOP_CART_DETAIL = '/gs/v1/carts/details'
export const API_GIFTSHOP_CART_WHOLESALES = '/gs/v1/carts/wholesales'
export const API_GIFTSHOP_COLLECTION = '/payment/v1/collections'
export const API_GIFTSHOP_COLLECTION_DELETE = '/payment/v2/collections'
export const API_GIFTSHOP_COLLECTION_RESEND_APPROVAL =
  '/gs/v1/collections/resend/approvals'
export const API_GIFTSHOP_COLLECTION_RESEND_APPROVAL_COUNT =
  '/gs/v1/collections/resend/approvals/count'
export const API_GIFTSHOP_COLLECTION_SHARE = '/payment/v1/collections/shares'
export const API_GIFTSHOP_CONTENTS_DETAIL = '/gs/v1/contents/details'
export const API_GIFTSHOP_CREATION = '/gs/v2/creations'
export const API_GIFTSHOP_CREATION_COUNT = '/gs/v1/creations/counts'
export const API_GIFTSHOP_CREATION_DATE = '/gs/v5/creations'
export const API_GIFTSHOP_CREATION_EDIT_BULK = '/gs/v1/creations/groups'
export const API_GIFTSHOP_CREATION_GROUP = '/gs/v4/creations/groups'
export const API_GIFTSHOP_CREATION_GROUP_FOLDER =
  '/gs/v1/creations/groups/folders'
export const API_GIFTSHOP_CREATION_DETAIL = '/gs/v1/creations/details'
export const API_GIFTSHOP_CREATION_DETAIL_NEW = '/gs/v4/creations/details'
export const API_GIFTSHOP_CREATION_LATEST = '/gs/v1/creations/details/latest'
export const API_GIFTSHOP_CREATION_LINK = '/gs/v2/creations/link'
export const API_GIFTSHOP_CREATION_LINK_OLD = '/gs/v1/creations/link'
export const API_GIFTSHOP_CREATION_RESEND_REVISION =
  '/gs/v1/creations/resend/approvals'
export const API_GIFTSHOP_CREATION_UPLOAD = '/gs/v3/creations'
export const API_GIFTSHOP_CREATION_VIDEO = '/gs/v2/creations/videos'
export const API_GIFTSHOP_CREATION_VIDEO_LINK = '/gs/v2/creations/videos/link'
export const API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE =
  '/gs/v1/creations/videos/face'
export const API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE_LINK =
  '/gs/v1/creations/videos/face/link'
export const API_GIFTSHOP_CREATION_VIDEO_RESEND =
  '/gs/v1/creations/videos/resend'
export const API_GIFTSHOP_CREATION_VIDEO_RESEND_LINK =
  '/gs/v1/creations/videos/resend/link'
export const API_GIFTSHOP_CREATION_VIDEO_RESEND_REVISION_UPLOAD =
  '/gs/v1/creations/videos/resend/revisions'
export const API_GIFTSHOP_CREATOR_COMPRESS_RESEND = '/gs/v1/creators'
export const API_GIFTSHOP_CREATOR_DATA = '/gs/v2/creators'
export const API_GIFTSHOP_CREATOR_RATING = '/gs/v1/creators/ratings'
export const API_GIFTSHOP_CREATOR_RATING_CHECK =
  '/gs/v1/creators/ratings/checks'
export const API_GIFTSHOP_CREATOR_SHARE = '/gs/v1/creators/share/link'
export const API_GIFTSHOP_CREATOR_STATUS = '/gs/v1/creators/status'
export const API_GIFTSHOP_CREATOR_WHOLESALE = '/gs/v1/creators/wholesales'
export const API_GIFTSHOP_CREATOR_GUIDELINES = '/gs/v1/creators/guidelines'
export const API_GIFTSHOP_RESEND = '/gs/v2/creations/resend'
export const API_GIFTSHOP_RESEND_LINK = '/gs/v2/creations/resend/link'
export const API_GIFTSHOP_CREATOR_VERIFICATION_STATUS =
  '/gs/v1/creators/verification/status'
export const API_GIFTSHOP_DOWNLOAD_COLLECTION =
  '/payment/v2/collections/downloads'
export const API_GIFTSHOP_USER = '/gs/v3/accounts/verification'
export const API_GIFTSHOP_USER_UPLOAD_LINK = '/gs/v3/accounts/verification/link'
export const API_GIFTSHOP_USER_RETAKE = '/gs/v1/accounts/verification/retake'
export const API_GIFTSHOP_USER_RETAKE_LINK =
  '/gs/v1/accounts/verification/retake/link'
export const API_GIFTSHOP_DELETE_EXPLORE = '/gs/v1/explores'
export const API_GIFTSHOP_EXPLORE = '/gs/v4/explores'
export const API_GIFTSHOP_EXPLORE_MONTH = '/gs/v4/explores/month'
export const API_GIFTSHOP_EXPLORE_BIB = '/gs/v1/explores/bibs'
export const API_GIFTSHOP_EXPLORE_CREATOR = '/gs/v3/explores/creators'
export const API_GIFTSHOP_EXPLORE_FILTER = '/gs/v1/explores/filter'
export const API_GIFTSHOP_EXPLORE_FILTER_STATUS =
  '/gs/v3/explores/filter/status'
export const API_GIFTSHOP_EXPLORE_RECOVER = '/gs/v1/explores/recover'
export const API_GIFTSHOP_EXPLORE_TRASH = '/gs/v3/explores/trash'
export const API_GIFTSHOP_FAVORITES = '/gs/v1/favorites'
export const API_GIFTSHOP_IFM_TAGS = '/gs/v1/tags/filters'
export const API_GIFTSHOP_LIVE_LOCATION = '/gs/v1/locations/live'
export const API_GIFTSHOP_LIVE_LOCATION_SHARE = '/gs/v1/locations/live/share'
export const API_GIFTSHOP_LIVE_LOCATION_STATUS = '/gs/v1/locations/live/status'
export const API_GIFTSHOP_ONBOARDING = '/gs/v1/accounts/onboarding'
export const API_POST_LIKE = '/post/v1/likes'
export const API_POST_LIKE_COMMENT = '/post/likes/v1/comments'
export const API_GIFTSHOP_MESSAGE_ENTER = '/gs/dm/v1/rooms/enter'
export const API_GIFTSHOP_MESSAGE_MESSAGE = '/gs/dm/v2/messages'
export const API_GIFTSHOP_MESSAGE_ROOM = '/gs/dm/v2/rooms'
export const API_GIFTSHOP_MESSAGE_ROOM_DELETE = '/gs/dm/v1/rooms'
export const API_GIFTSHOP_NICKNAME = '/member/v1/gs/accounts/nicknames'
export const API_MEMBER_SEARCH_MESSAGE = '/member/v1/search/dm'
export const API_GIFTSHOP_REPORT = '/report/v1/contents'
export const API_GIFTSHOP_ROBOPET = '/gs/v1/robopets'
export const API_GIFTSHOP_SEND_TO_FACE = '/gs/v3/creations/directs'
export const API_GIFTSHOP_STORAGE = '/gs/v1/creators/storage'
export const API_GIFTSHOP_STORAGE_HISTORY = '/gs/v1/creators/storage/history'
export const API_GIFTSHOP_STORAGE_REQUEST = '/gs/v1/creators/storage/request'
export const API_GIFTSHOP_TAGS = '/gs/v2/tags'
export const API_GIFTSHOP_TAGS_POST = '/gs/v1/tags/posts'
export const API_GIFTSHOP_TRANSACTION_HISTORY =
  '/payment/v2/transactions/history'
export const API_GIFTSHOP_TRANSACTION_REPORT =
  '/payment/v1/transactions/reports'
export const API_GIFTSHOP_UPLOAD_RESEND_REVISION =
  '/gs/v1/creations/resend/revisions'
export const API_GIFTSHOP_GOPAY_CANCEL = '/payment/v1/transactions/gopay/cancel'
export const API_GIFTSHOP_MAKE_IT_FREE = '/payment/v1/transactions/refunds'
export const API_GIFTSHOP_RESENDPOLOGY_CANCEL =
  '/gs/v1/creations/resendpology/cancel'
export const API_GIFTSHOP_WISHLIST = '/gs/v1/wishlists'
export const API_GIFTSHOP_WISHLIST_DETAIL = '/gs/v1/wishlists/details'
export const API_NOTIFICATION_PARTNERSHIP = '/notification/v1/partnership'
export const API_APPLE_LOGIN_SSO = '/sso/v1/apple/oauth'
export const API_GOOGLE_LOGIN_SSO = '/sso/v2/google/oauth'
export const API_LEGAL_BLOG = ''
export const API_LOCATION_SEARCH = '/location/v1/location/search'
export const API_MEMBER_PROFILE = '/member/v1/members'
export const API_MEMBER_PROFILE_SHARE = '/member/v1/profile/share'
export const API_MEMBER_BANK_ACCOUNT = '/member/v1/bank-accounts'
export const API_MEMBER_BANK_RESEND_OTP = '/member/v1/resend/bank-accounts'
export const API_MEMBER_BANK_VERIFY_OTP = '/member/v1/verify/bank-accounts'
export const API_MEMBER_CHECK_DOB = '/member/v2/check/dob'
export const API_MEMBER_DELETE_ACCOUNT = '/member/v1/auth/self'
export const API_MEMBER_DEVICE = '/member/v2/devices/clients'
export const API_MEMBER_EDIT_PASSWORD = '/member/v2/auth/password'
export const API_MEMBER_EDIT_PROFILE = '/member/v1/profile'
export const API_MEMBER_EDIT_PROFILE_NICKNAME = '/member/v1/profile/nickname'
export const API_MEMBER_EDIT_PROFILE_USERNAME = '/member/v1/profile/username'
export const API_MEMBER_FOLLOW = '/member/v1/follow'
export const API_MEMBER_FOLLOW_COUNT = '/member/v1/follow/count'
export const API_MEMBER_FOLLOWER = '/member/v1/follower'
export const API_MEMBER_FOLLOWING = '/member/v1/following'
export const API_MEMBER_GSINFO = '/member/v1/gs/auth/self'
export const API_MEMBER_LANGUAGE = '/member/v1/lang'
export const API_MEMBER_NICKNAMES = '/member/v1/gs/accounts/nicknames'
export const API_MEMBER_REGISTER_EMAIL_RESEND = '/member/v1/resend/email/code'
export const API_MEMBER_SELF_INFO = '/member/v1/gs/auth/self'
export const API_MEMBER_SEARCH = '/member/v1/search'
export const API_MEMBER_SIMILAR = '/member/v1/similar'
export const API_MEMBER_SIMILAR_SKIP = '/member/v1/similar/skip'
export const API_MEMBER_SIMILAR_STATUS = '/member/v1/similar/status'
export const API_MEMBER_OTHER_INFO = '/member/v1/profile'
export const API_MUSICS = '/file/v1/musics'
export const API_NOTIFICATION_MAINTENANCE = '/notification/v1/maintenance'
export const API_OAUTH_LOGOUT = '/oauth/v1/logout'
export const API_OAUTH_REFRESH_TOKEN = '/oauth/v1/token'
export const API_OAUTH_REGISTER_EMAIL = '/oauth/v1/auth/register/email'
export const API_OAUTH_REGISTER_PHONE = '/oauth/v2/auth/register/phone'
export const API_PASSWORD_SSO = '/member/v1/auth/password/sso'
export const API_PAYMENT_BANK = '/payment/v1/banks'
export const API_PAYMENT_BUYER = '/payment/v1/buyers'
export const API_PAYMENT_CHECKOUT_CHECK_DOUBLE = '/payment/v1/checkout/check'
export const API_PAYMENT_CHECKOUT_TOKEN = '/payment/v2/checkout/token'
export const API_PAYMENT_TRANSACTION = '/payment/v1/transactions'
export const API_PAYMENT_TRANSACTION_WAITING =
  '/payment/v1/transactions/waiting'
export const API_PAYMENT_TRANSACTION_COUNT = '/payment/v1/transactions/count'
export const API_PAYMENT_TRANSACTION_CANCEL = '/payment/v1/transactions/cancel'
export const API_PAYMENT_TRANSACTION_DETAIL = '/payment/v2/transactions/details'
export const API_PAYMENT_TRANSACTION_WAITING_DETAIL =
  '/payment/v2/transactions/waiting/details'
export const API_PAYMENT_TRANSACTION_FEE = '/payment/v2/transactions/fee'
export const API_PAYMENT_WITHDRAW_AVAILABILITY =
  '/payment/v1/withdraw/availability'
export const API_PROFILE_ADD_EMAIL = '/member/v3/email'
export const API_PROFILE_ADD_PHONE = '/member/v3/phone'
export const API_PROFILE_RESEND_ADD_EMAIL = '/member/v3/resend/profile/email'
export const API_PROFILE_RESEND_ADD_PHONE = '/member/v3/resend/profile/phone'
export const API_PROFILE_VERIFY_ADD_EMAIL = '/member/v3/verify/profile/email'
export const API_PROFILE_VERIFY_ADD_PHONE = '/member/v3/verify/profile/phone'
export const API_PROFILE_BACKGROUND = '/member/v1/profile/background'
export const API_PROFILE_BACKGROUND_LINK = '/member/v1/profile/background/link'
export const API_PROFILE_PHOTO = '/member/v1/profile/photo'
export const API_PROFILE_PHOTO_LINK = '/member/v1/profile/photo/link'
export const API_DELETE_COMMENT = '/post/v1/comments'
export const API_POST_COMMENT = '/post/v1/comments'
export const API_POST_POSTS = '/post/v1/posts'
export const API_POST_EDIT_POSTS = '/post/v2/posts'
export const API_POST_PIN_POSTS = '/post/v1/posts/pins'
export const API_POST_POSTS_DETAIL = '/post/v1/posts/details'
export const API_POST_POSTS_LINK = '/post/v1/posts/link'
export const API_POST_POSTS_SHARE = '/post/v1/posts/share'
export const API_POST_REPLY = '/post/v1/replies'
export const API_POST_SELF = '/post/v1/posts/self'
export const API_POST_TIMELINE = '/post/v1/timeline'
export const API_POST_UGC = '/post/v1/timeline/ugc'
export const API_REPORT_POSTS = '/report/v1/posts'
export const API_REPORT_COMMENTS = '/report/v1/comments'
export const API_REPORT_BLOCK_ACCOUNT = '/report/v1/blocks/account'
export const API_REPORT_BLOCKED_ACCOUNT_LIST = '/report/v1/blocks/list'
export const API_REPORT_YUSER = '/report/v1/accounts'
export const API_TRANSACTION_CURRENCY = '/payment/v1/currencies'
export const API_TRANSACTION_WITHDRAWAL = '/payment/v1/withdraw'
export const API_TRANSACTION_WITHDRAW_FEE = '/payment/v1/withdraw/fee'
export const API_TREE_EVENTS = '/tree/v1/trees/events'
export const API_TREE_FARMER = '/tree/v1/farmers'
export const API_TREE_SEARCH_FARMER = '/tree/v1/farmers/search'
export const API_TREE_HOSTS = '/gs/v2/hosts'
export const API_TREE_HOSTS_INFO = '/gs/v3/hosts/details'
export const API_TREE_HOST_BANK = '/gs/v1/hosts/banks'
export const API_TREE_HOST_CREATION = '/gs/v2/hosts/creations'
export const API_TREE_HOST_CREATION_GROUP = '/gs/v1/hosts/creations/groups'
export const API_TREE_LEAF = '/tree/v1/leaves'
export const API_TREE_LEAF_FOOTPRINTS = '/tree/v1/leaves/footprints'
export const API_TREE_LEAF_SELF = '/tree/v1/leaves/self'
export const API_TREE_LEAF_SHARE = '/tree/v1/leaves/share'
export const API_TREE_LEAF_SHARE_GENEREATE = '/tree/v2/leaves/share'
export const API_TREE_POST_COVER_LINK = '/gs/v1/hosts/covers/links'
export const API_TREE_POST_DOCUMENT_LINK = '/gs/v1/hosts/verifications/links'
export const API_TREE_POST_LOGO_LINK = '/gs/v1/hosts/logos/links'
export const API_TREE_REGISTER_HOST = '/gs/v2/hosts/verifications'
export const API_TREE_HOST_ADMINS = '/gs/v1/hosts/admins'
export const API_TREE_HOST_CREATORS = '/gs/v1/hosts/creators'
export const API_TREE_HOST_INVITATION = '/gs/v2/hosts/invitations'
export const API_TREE_HOST_CREATORS_INVITE = '/gs/v2/hosts/creators'
export const API_TREE_HOST_SEARCH_ADMINS = '/gs/v1/hosts/admins/searchs'
export const API_TREE_HOST_SEARCH_CREATORS = '/gs/v1/hosts/creators/searchs'
export const API_TREE_PLANT_FOTOTREE_PROFILE_LINK = '/tree/v1/trees/link'
export const API_TREE_PLANT_FOTOTREE_CATEGORY = '/tree/v1/categories'
export const API_TREE_POST_PLANT_FOTOTREE = '/tree/v2/trees'
export const API_TREE_SEARCH_FOTOTREE = '/tree/v1/trees/search'
export const API_TREE_FOOTPRINT = '/tree/v1/footprints'
export const API_TREE_GET_FOTOTREE = '/tree/v1/trees'
export const API_TREE_GET_FOTOTREE_DETAIL = '/tree/v1/trees/detail'
export const API_TREE_GENERATE_QR = '/tree/v1/trees/qr'
export const API_TREE_SHARE_FOTOTREE = '/tree/v2/trees/share'
export const API_TREE_CHECK = '/tree/v1/trees/check'
export const API_TREE_COMMENT = '/tree/v1/trees/comments'
export const API_TREE_COMMENT_LIKE = '/tree/v1/trees/comments/likes'
export const API_TREE_COMMENT_REPLY = '/tree/v1/trees/replies'
export const API_TREE_LEADERBOARD = '/tree/v1/trees/leaderboards'
export const API_TREE_LOCATION = '/tree/v1/locations'
