import {
  TreeManageAdminScreens,
  TreeHostProfileContentDetailScreens,
  TreeMyBalanceScreen,
  TreeProfileScreens,
  TreeRegistrationAddHostInformationScreen,
  TreeRegistrationFormScreen,
  TreeTransactionHistoryScreen,
  TreeTransactionWithdrawScreen,
  TreeHostPromoteAdminScreens,
  TreeEditProfileScreen,
  TreeHostSuccessPromoteAdminScreens,
  TreeFototreeScreen,
  TreePlantFotoTreeScreen,
  TreeFototreeDetailScreen,
  TreeFototreeUploadScreen,
  TreeFototreeFeedScreen,
  TreeFototreeEditScreen,
  TreeFototreeManageFarmerScreen,
  TreeFototreeAddFarmerScreen,
  TreeHostManageCreatorScreen,
  TreeHostProfileCreationGroupDetailScreen,
  TreeHostNotificationScreen,
  TreeHostManageCreatorPromoteScreen,
  TreeEventsScreen,
  TreeEventsBIBMasonryScreen,
  TreeEventsBIBContentDetailScreen,
  TreeFototreePreviewQrScreen,
} from 'pages'
import {RouteMap} from '../RouteMap'
import {RouteParamTree} from './RouteParamTree'

export const ROUTE_MAP_TREE: RouteMap<RouteParamTree> = {
  tree_add_host_information: {
    component: TreeRegistrationAddHostInformationScreen,
    path: '/host/add-info',
    permission: 'user',
  },
  tree_events: {
    component: TreeEventsScreen,
    path: '/tree/events',
    permission: 'user',
    exact: true,
  },
  tree_events_bib_content_detail: {
    component: TreeEventsBIBContentDetailScreen,
    path: '/event/tree/detail',
    permission: 'user',
    defaultState: {
      data: [],
      selectedItemId: '',
      tag_id: '',
      bib: '',
    },
  },
  tree_events_bib_masonry: {
    component: TreeEventsBIBMasonryScreen,
    path: '/event/tree',
    defaultState: {
      tree_name: '',
      tree_url: '',
      fromScan: true,
    },
  },
  tree_host_creation_group: {
    component: TreeHostProfileCreationGroupDetailScreen,
    path: '/host/creation/group',
    defaultState: {
      date: '',
      creationType: 'all',
    },
    permission: 'user',
  },
  tree_host_content_detail: {
    component: TreeHostProfileContentDetailScreens,
    path: '/host/content-detail',
    defaultState: {
      data: [],
      selectedItemId: '',
      creationType: 'all',
      date: '',
    },
    permission: 'user',
  },
  tree_host_manage_admin: {
    component: TreeManageAdminScreens,
    path: '/host/manage-admin',
    permission: 'user',
  },
  tree_host_manage_creator: {
    component: TreeHostManageCreatorScreen,
    path: '/host/manage-creator',
    permission: 'user',
  },
  tree_host_notification: {
    path: '/host/notification',
    component: TreeHostNotificationScreen,
    permission: 'user',
  },
  tree_host_promote_admin: {
    component: TreeHostPromoteAdminScreens,
    path: '/host/promote-admin',
    defaultState: {
      totalAdmin: 0,
    },
    permission: 'user',
  },
  tree_host_promote_creator: {
    component: TreeHostManageCreatorPromoteScreen,
    path: '/host/promote-creator',
    defaultState: {
      creatorType: 'internal',
    },
    permission: 'user',
  },
  tree_host_promote_success: {
    component: TreeHostSuccessPromoteAdminScreens,
    path: '/host/promote-success',
    defaultState: {
      context: 'admin',
    },
    permission: 'user',
  },
  tree_edit_profile: {
    component: TreeEditProfileScreen,
    path: '/host/profile/edit',
    permission: 'user',
  },
  tree_host_profile: {
    component: TreeProfileScreens,
    path: '/host/profile',
    permission: 'user',
  },
  tree_host_registration: {
    component: TreeRegistrationFormScreen,
    path: '/host/register',
    permission: 'user',
  },
  tree_balance: {
    component: TreeMyBalanceScreen,
    path: '/host/balance',
    permission: 'user',
  },
  tree_plant_fototree: {
    component: TreePlantFotoTreeScreen,
    path: '/plant-tree',
    permission: 'user',
  },
  tree_transaction_history: {
    component: TreeTransactionHistoryScreen,
    path: '/host/history',
    permission: 'user',
  },
  tree_withdraw: {
    component: TreeTransactionWithdrawScreen,
    path: '/host/withdraw',
    permission: 'user',
  },
  tree_fototree_add_farmer: {
    component: TreeFototreeAddFarmerScreen,
    path: '/tree/:alias/farmer/add',
    defaultState: null,
    permission: 'user',
  },
  tree_fototree: {
    component: TreeFototreeScreen,
    path: '/tree',
    defaultState: {initialData: undefined},
  },
  tree_fototree_detail: {
    component: TreeFototreeDetailScreen,
    path: '/tree/:alias',
    defaultState: {
      treeId: '',
      treeAlias: '',
    },
  },
  tree_fototree_edit: {
    component: TreeFototreeEditScreen,
    path: '/tree/:alias/edit',
    defaultState: null,
    permission: 'user',
  },
  tree_fototree_feed: {
    component: TreeFototreeFeedScreen,
    path: '/tree/:alias/memory',
    defaultState: {},
  },
  tree_fototree_manage_farmer: {
    component: TreeFototreeManageFarmerScreen,
    path: '/tree/:alias/farmer',
    defaultState: null,
    permission: 'user',
  },
  tree_fototree_preview_qr: {
    component: TreeFototreePreviewQrScreen,
    path: '/tree/:alias/preview-qr',
    defaultState: null,
    permission: 'user',
  },
  tree_fototree_upload: {
    component: TreeFototreeUploadScreen,
    path: '/tree/:alias/upload',
    defaultState: null,
    permission: 'user',
  },
}
