import {RefObject} from 'react'
import AvatarEditor from 'react-avatar-editor'
import heic2any from 'heic2any'
import download from 'downloadjs'
import {FileImageUrlOption, LayoutDimension} from 'types'
import {parseDate} from '../dates'

window.URL = window.URL || window.webkitURL

export function urlToBlob(url: string) {
  return fetch(url).then((response) => response.blob())
}

export function blobToFile(
  blob: Blob,
  fileName: string,
  lastModified?: number,
) {
  return new File([blob], fileName, {type: 'image/jpeg', lastModified})
}

export async function downloadImages(data: FileImageUrlOption[]) {
  data.forEach(async (d) => {
    const blob = await urlToBlob(d.url)
    const fileName =
      d.name !== ''
        ? d.name
        : parseDate(new Date().toISOString(), 'YYYYMMDDHHmmss')
    download(blob, `FotoYu-${fileName}`, 'image/png,image/jpg')
  })
}

export function getFileSize(byteSize: number, decimals = 2) {
  if (byteSize === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(byteSize) / Math.log(k))

  return `${parseFloat((byteSize / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function convertFileSizeFromByte(
  byteSize: number,
  target: 'KB' | 'MB' | 'GB',
) {
  return byteSize / 1024 ** (target === 'KB' ? 1 : target === 'MB' ? 2 : 3)
}

export async function handleUploadSingleFileToWasabi(
  file: File,
  uploadUrl: string,
  contentType: string,
  // eslint-disable-next-line no-undef
  headers?: HeadersInit,
) {
  const buffer = await file.arrayBuffer()

  return fetch(uploadUrl, {
    method: 'PUT',
    headers: headers || {
      'Content-Type': contentType,
    },
    body: buffer,
  })
    .then(({status}) => status === 200)
    .catch(() => false)
}

export function cropImage(
  canvas: RefObject<AvatarEditor> | null,
  fileName: string,
  onCrop: (image?: File) => void,
) {
  if (canvas?.current) {
    const cropRes = canvas.current.getImage()
    const cropped = cropRes.toDataURL()

    const blobBin = atob(cropped.split(',')[1])
    const arr: number[] = []
    for (let i = 0; i < blobBin.length; i += 1) {
      arr.push(blobBin.charCodeAt(i))
    }
    const blob = new Blob([new Uint8Array(arr)], {})
    const image = new File([blob], fileName)

    onCrop(image)
  }
}

export async function getFileImageDimension(
  file: File,
): Promise<LayoutDimension | null> {
  const {type} = file

  if (!/image/i.test(type)) {
    return null
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onabort = () => resolve(null)
    reader.onerror = () => resolve(null)
    reader.onload = async ({target}) => {
      if (target?.result) {
        const {result} = target
        const image = new Image()

        let blob = new Blob([result])

        if (type === 'image/heic' || type === 'image/heif') {
          try {
            blob = (await heic2any({blob, toType: 'image/jpg'})) as Blob
          } catch (e) {
            reject()
            return
          }
        }

        image.onerror = () => resolve(null)
        image.onabort = () => resolve(null)
        image.onload = () => {
          window.URL.revokeObjectURL(image.src)
          resolve({
            height: image.height,
            width: image.width,
          })
        }
        image.src = window.URL.createObjectURL(blob)
      } else {
        resolve(null)
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export async function getVideoMetadata(
  video: HTMLVideoElement,
): Promise<{width: number; height: number; duration: number} | null> {
  return new Promise((resolve) => {
    video.onabort = () => resolve(null)
    video.onerror = () => resolve(null)
    video.onloadedmetadata = ({target}) => {
      if (target) {
        const {videoWidth, videoHeight, duration} = video

        resolve({
          height: videoHeight,
          width: videoWidth,
          duration,
        })
      } else {
        resolve(null)
      }
    }
  })
}

export function getFileName(oriFileName: string, maxLength?: number) {
  if (maxLength && oriFileName.length > maxLength) {
    const splitFileNameByDot = oriFileName.split('.')
    const extension = splitFileNameByDot[splitFileNameByDot.length - 1]

    const trimmedFileName = oriFileName.substring(
      0,
      maxLength - extension.length - 1,
    )

    return `${trimmedFileName}.${extension}`
  }
  return oriFileName
}

export function getFileExtension(oriFileName: string) {
  return (
    oriFileName.substring(oriFileName.lastIndexOf('.') + 1).toLowerCase() ?? ''
  )
}

export function getAndSupplyFileMime(fileMimeList: string[], file: File) {
  let detectedMime = file.type
  const {name} = file

  if (detectedMime === '') {
    const extension =
      name.substring(name.lastIndexOf('.') + 1).toLowerCase() ?? ''

    fileMimeList.some((currentType) => {
      const typeMatchesWithExtension = currentType.includes(extension)
      if (typeMatchesWithExtension) detectedMime = currentType
      return typeMatchesWithExtension
    })
  }

  return detectedMime
}
