import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {showSnackbar, useHistory} from 'utils'
import {Modal} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import GiftShopUploadPublishModalLoading from './GiftShopUploadPublishModalLoading'
import GiftShopUploadPublishModalRetry from './GiftShopUploadPublishModalRetry'
import GiftShopUploadPublishModalFinished from './GiftShopUploadPublishModalFinished'
import {GiftShopUploadPublishModalProps} from './GiftShopUploadPublishModalProps'

const StyledModal = styled(Modal)`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(325)};
  padding: ${convertUnit(25)};
  margin: ${convertUnit(25)};
`

export default function GiftShopUploadPublishModal({
  toggle,
  mapForm,
  onRetry,
}: GiftShopUploadPublishModalProps) {
  const {username} = useSelector('user') || {}
  const total = useMemo(() => Object.keys(mapForm).length, [mapForm])
  const history = useHistory()
  const stateLoadingRetryButton = useState(false)
  const setLoading = stateLoadingRetryButton[1]
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const {translate} = useTranslation()

  const handleOnline = useCallback(() => {
    setIsOnline(true)
  }, [])

  const handleOffline = useCallback(() => {
    setIsOnline(false)
  }, [])

  const success = useMemo(
    () =>
      Object.values(mapForm).filter(({status}) => status === 'success').length,
    [mapForm],
  )

  const progress = useMemo(
    () =>
      Object.values(mapForm).filter(({status}) => status !== 'not-started')
        .length,
    [mapForm],
  )

  const status = useMemo(() => {
    if (progress !== total) {
      return 'loading'
    }
    if (success !== total) {
      setLoading(false)
      return 'retry'
    }
    return 'finished'
  }, [progress, setLoading, success, total])

  const handleClose = useCallback(
    () => history.push('giftshop_profile', {self: true}, username),
    [history, username],
  )

  const handleRetry = useCallback(() => {
    if (isOnline) {
      onRetry()
      setLoading(true)
    } else {
      showSnackbar(translate('global:messageError', {context: 'no-connection'}))
    }
  }, [isOnline, onRetry, setLoading, translate])

  const handleRenderContent = useMemo(() => {
    switch (status) {
      case 'loading':
        return (
          <GiftShopUploadPublishModalLoading total={total} success={success} />
        )
      case 'retry':
        return (
          <GiftShopUploadPublishModalRetry
            total={total}
            success={success}
            onClose={handleClose}
            onRetry={handleRetry}
            stateLoadingRetryButton={stateLoadingRetryButton}
          />
        )
      default:
        return (
          <GiftShopUploadPublishModalFinished total={total} success={success} />
        )
    }
  }, [
    status,
    total,
    success,
    handleClose,
    handleRetry,
    stateLoadingRetryButton,
  ])

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [handleOffline, handleOnline])

  return <StyledModal visible={toggle}>{handleRenderContent}</StyledModal>
}
