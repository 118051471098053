import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input} from 'common/components'
import {VALIDATION_TREE_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

export const StyledItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeRegistrationFormItemProvince({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {province} = watch()

  return (
    <StyledItemsContainer>
      <Input
        form={form}
        formRules={VALIDATION_TREE_REQUIRED}
        name="province"
        label={translate('tree:hostRegistrationFormProvinceLabel')}
        placeholder={translate('tree:hostRegistrationFormProvincePlaceholder')}
        disabled={disabled}
        rightIcon={
          !disabled && province && province.length > 0 ? (
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={() => setValue('province', '')}
            />
          ) : undefined
        }
      />
    </StyledItemsContainer>
  )
}
