import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export interface GiftShopMessageRoomContentItemNotificationHostAdminPromotionProps {
  payload: FirebaseMessageRoomItemPayload['notification_promotion_host_admin']
}

export default function GiftShopMessageRoomContentItemNotificationHostAdminPromotion({
  payload: {host_tag, username, host_leader_username},
}: GiftShopMessageRoomContentItemNotificationHostAdminPromotionProps) {
  const {translate} = useTranslation()
  const history = useHistory()

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('messages:openInvitationButton')}
        onClick={() => history.push('tree_host_notification', {})}
      />
    ),
    [history, translate],
  )

  return (
    <StyledContainer>
      <div>
        <Paragraph fontSize="m">
          {translate('messages:hiUsername', {username})}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationAdminInvitation', {
            host_leader_username,
            host_tag,
          })}
        </Paragraph>
      </div>
      {handleRenderButton}
    </StyledContainer>
  )
}
