import React, {useCallback} from 'react'
import styled from 'styled-components'
import {Paragraph, Image} from 'common/components'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {FirebaseMessageRoomItemPayload} from 'types'
import {getFontFamily, useHistory} from 'utils'

export interface GiftShopMessageRoomContentItemNotificationLikeCommentGroupProps {
  payload: FirebaseMessageRoomItemPayload['notification_like_comment_group']
}

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  margin-left: ${convertUnit(16)};
  border-radius: ${convertUnit(4)};
  cursor: pointer;
`

export default function GiftShopMessageRoomContentItemNotificationLikeCommentGroup({
  payload: {count, item},
}: GiftShopMessageRoomContentItemNotificationLikeCommentGroupProps) {
  const history = useHistory()
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}

  const handleNavigatePost = useCallback(() => {
    history.push(
      'giftshop_profile_post_detail',
      {
        data: [],
        self: item.member_id === id,
        selectedItemId: item.id,
        memberId: item.member_id,
      },
      item.username,
    )
  }, [history, id, item.id, item.member_id, item.username])

  return (
    <StyledContainer>
      <Paragraph fontSize="m">
        <StyledBold>
          {count} {translate('global:yuser')}
        </StyledBold>
        {translate('messages:notificationLikeCommentGroup')}
      </Paragraph>
      <StyledImage src={item.url} alt="image" onClick={handleNavigatePost} />
    </StyledContainer>
  )
}
