import React, {Fragment, useMemo} from 'react'
import styled from 'styled-components'
import {FONT_FAMILY_MAP, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'
import {getFontFamily, getFontSize} from 'utils'
import {Image, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'

export interface LegalYuserContentProps {
  scrollTo: (childRef: number | undefined) => void
}

const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`

const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
  padding-top: ${convertUnit(35)};
`

const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`

const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

const StyledImage = styled(Image)`
  height: 100%;
  max-width: 100%;
  max-height: 300px;
  margin: ${convertUnit(10)} ${convertUnit(5)};
  object-fit: contain;
  border-radius: 5px;
`

const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`

const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`

const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`

export default function LegalYuserContent({scrollTo}: LegalYuserContentProps) {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regexPatterns = [
      {
        regex: /\*\*\*(.+?)\*\*\*/,
        style: {fontFamily: FONT_FAMILY_MAP.boldItalic},
      },
      {regex: /\*\*(.+?)\*\*/, style: {fontFamily: FONT_FAMILY_MAP.bold}},
      {regex: /\*(.+?)\*/, style: {fontFamily: FONT_FAMILY_MAP.italic}},
    ]

    return regexPatterns.map(({regex, style}) => ({
      pattern: regex,
      style,
      renderText: (text) => text.replace(regex, '$1'),
    }))
  }, [])

  const handleRenderYuser = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:yuser')}
        </StyledTitle>
        <StyledDescription>
          {Array.from({length: 4}).map((_, index) => (
            <Fragment key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate(`policy:yuser`, {context: `${index + 1}`})}
              </ParsedText>
              {index === 0 && (
                <StyledLink href="#" onClick={() => scrollTo(13)}>
                  {translate('policy:privacyPolicyChild')}
                </StyledLink>
              )}
              {index === 1 && (
                <StyledLink href="#" onClick={() => scrollTo(4)}>
                  {translate('policy:privacyPolicy')}
                </StyledLink>
              )}
              {index === 2 && (
                <StyledLink href="#" onClick={() => scrollTo(2)}>
                  {translate('policy:creator')}.
                </StyledLink>
              )}
            </Fragment>
          ))}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, scrollTo, translate],
  )

  const handleRenderSummary = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserSummary')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 10}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate(`policy:yuserSummary`, {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderYuserCategory = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserCategory')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserCategoryDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:yuserCategoryCommonYuser')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserCategoryCommonYuserDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:yuserCategoryChildYuser')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserCategoryChildYuserDesc')}
          <StyledLink href="#" onClick={() => scrollTo(13)}>
            {translate('policy:privacyPolicyChild')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [scrollTo, translate],
  )

  const handleRenderIdentity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserIdentity')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 9}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate(`policy:yuserIdentity`, {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 5 && (
                <StyledLink
                  onClick={() =>
                    document
                      .getElementById('pengenalanWajah')
                      ?.scrollIntoView({behavior: 'smooth'})
                  }>
                  {translate('global:here')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderAnonymity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserAnonymity')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserAnonymityDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledImage
          src={IMAGE_ASSET('howitworks', '11.png')}
          alt={translate('policy:yuserAnonymity')}
        />
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderCreator = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:yuserFotoyuCreator')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserFotoyuCreatorDesc')}
        </StyledDescription>
        <>
          <StyledImage
            src={IMAGE_ASSET('howitworks', '9.png')}
            alt={translate('policy:yuserFotoyuCreator') + 1}
          />
          <StyledImage
            src={IMAGE_ASSET('howitworks', '10.png')}
            alt={translate('policy:yuserFotoyuCreator') + 2}
          />
        </>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderChoice = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserChoice')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserChoiceDesc')}
          </ParsedText>
          <StyledLink href="#" onClick={() => scrollTo(0)}>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, scrollTo, translate],
  )

  const handleRenderAntiSpoofing = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserAntiSpoofing')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserAntiSpoofingDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderEncryption = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserEncryption')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserEncryptionDesc')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderBuyContent = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserBuyContent')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserBuyContentDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:yuserBuyContentDesc', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:yuserBuyContentDescBottom')}
        </StyledDescription>
        <ParsedText parse={patterns} />
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderDiscount = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserDiscount')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserDiscountDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:yuserDiscountClassification')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserDiscountClassificationDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(`policy:yuserDiscountClassificationDesc`, {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderDontBuyOther = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserDontBuyOther')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserDontBuyOtherDesc')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderCopyright = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserCopyright')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserCopyrightDesc')}
          </ParsedText>
          <StyledLink href="#" onClick={() => scrollTo(8)}>
            {translate('global:here')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, scrollTo, translate],
  )

  const handleRenderStorage = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserStorage')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserStorageDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderPayment = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserPayment')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:yuserPaymentDesc')}
          <StyledLink
            onClick={() =>
              document
                .getElementById('pembayaran')
                ?.scrollIntoView({behavior: 'smooth'})
            }>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleRenderYuser}
      {handleRenderSummary}
      {handleRenderYuserCategory}
      {handleRenderIdentity}
      {handleRenderAnonymity}
      {handleRenderCreator}
      {handleRenderChoice}
      {handleRenderAntiSpoofing}
      {handleRenderEncryption}
      {handleRenderBuyContent}
      {handleRenderDiscount}
      {handleRenderDontBuyOther}
      {handleRenderCopyright}
      {handleRenderStorage}
      {handleRenderPayment}
    </>
  )
}
