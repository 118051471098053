import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Paragraph, ParsedText} from 'common/components'
import {TranslationKeyMessages, useTranslation} from 'i18n'
import {
  FirebaseMessageRoomItemPayload,
  FirebaseMessageTreePlantedType,
  parseShape,
} from 'types'
import {getFontFamily} from 'utils'

export interface GiftShopMessageRoomContentItemNotificationTreePlantedProps {
  payload:
    | FirebaseMessageRoomItemPayload['notification_non_public_fototree_planted']
    | FirebaseMessageRoomItemPayload['notification_tree_approved']
    | FirebaseMessageRoomItemPayload['notification_tree_removed']
  type?: FirebaseMessageTreePlantedType
}

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`

export default function GiftShopMessageRoomContentItemNotificationTreePlanted({
  payload: {tree_name, username},
  type = 'planted',
}: GiftShopMessageRoomContentItemNotificationTreePlantedProps) {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(
    () => [
      {
        pattern: /<username>.*?<\/username>/,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(/<username>|<\/username>/g, ''),
      },
      {
        pattern: /<tree_name>.*?<\/tree_name>/,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(/<tree_name>|<\/tree_name>/g, ''),
      },
    ],
    [],
  )

  const message = useMemo(() => {
    let text: TranslationKeyMessages
    switch (type) {
      case 'approved':
        text = 'messages:notificationTreeApproved'
        break
      case 'removed':
        text = 'messages:notificationTreeRemoved'
        break
      default:
        text = 'messages:notificationTreeNonPublic'
        break
    }
    return text
  }, [type])

  return (
    <StyledParagraph fontSize="m">
      <ParsedText parse={patterns}>
        {translate(message, {
          username,
          tree_name,
        })}
      </ParsedText>
    </StyledParagraph>
  )
}
