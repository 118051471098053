import React, {useRef} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useWindowMode} from 'windows'
import {Icon, Image, Modal} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplateUploadPreviewModalProps} from './ForkygramTemplateUploadPreviewModalProps'

const StyledModal = styled(Modal)`
  max-height: 75vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: ${convertUnit(15)};
  padding-left: ${convertUnit(40)};
  padding-right: ${convertUnit(40)};
  padding-bottom: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(20)};
  }
`

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: ${convertUnit(5)};
`

const StyledContentContainer = styled.div`
  display: block;
`

const StyledImage = styled(Image)`
  max-height: 70vh;
  object-fit: scale-down;
  width: 100%;
`

const StyledVideo = styled.video`
  max-height: 70vh;
  object-fit: scale-down;
  width: 100%;
`

const StyledHls = styled(ReactHlsPlayer)`
  max-height: 70vh;
  object-fit: scale-down;
  width: 100%;
`

export default function ForkygramTemplateUploadPreviewModal({
  src,
  visible,
  contentType,
  editContent,
  toggleModal,
}: ForkygramTemplateUploadPreviewModalProps) {
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <StyledModal visible={visible} onBackdropPress={toggleModal}>
      <StyledIconContainer>
        {mode !== 'mobile' && (
          <Icon type="close" onClick={toggleModal} size={convertUnit(20)} />
        )}
      </StyledIconContainer>
      <StyledContentContainer>
        {(src.includes('image') || contentType === 'photo') && (
          <StyledImage src={src} alt="" />
        )}
        {(src.includes('video') || contentType === 'video') &&
          (editContent ? (
            <StyledHls
              autoPlay={false}
              controls
              playerRef={videoRef}
              src={src}
            />
          ) : (
            <StyledVideo autoPlay={false} controls>
              <source src={src} type="video/mp4" />
              <source src={src} type="video/mov" />
              <source src={src} type="video/heiv" />
            </StyledVideo>
          ))}
      </StyledContentContainer>
    </StyledModal>
  )
}
