import React from 'react'
import styled from 'styled-components'
import {Button} from 'common/components'
import {ButtonOutlineProps} from './ButtonOutlineProps'

const StyledButtonOutline = styled(Button)<ButtonOutlineProps>`
  ${({
    theme,
    disabled,
    disabledBorderColor = 'gray_2',
    backgroundColor,
    borderWidth = 2,
    borderColor = 'gray_5',
    borderHoverColor = 'gray_3',
  }) => ({
    backgroundColor: backgroundColor ?? 'transparent',
    ...(!disabled && !disabledBorderColor
      ? {border: `${borderWidth}px solid ${theme[borderColor]}`}
      : {border: `${borderWidth}px solid ${theme[disabledBorderColor]}`}),
    ':hover': {
      backgroundColor: backgroundColor ?? 'transparent',
      ...(!disabled ? {borderColor: theme[borderHoverColor]} : undefined),
    },
  })};
`

export default function ({...props}: ButtonOutlineProps) {
  return <StyledButtonOutline {...props} />
}
