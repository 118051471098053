import React, {useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_TAB_HEIGHT} from 'consts'
import {translate} from 'i18n'
import {useDidMount, useFirebaseUser, useHistory} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {Paragraph} from 'common/components'
import {GiftShopExploreTabViewProps} from './GiftShopExploreTabViewProps'

interface StyledTabItemContainerProps {
  isSelected: boolean
}
const StyledTabView = styled.div`
  position: relative;
  width: fit-content;
  display: block;
  transition: top 0.3s;
`

const StyledTabContainer = styled.div`
  ${({theme}) => ({
    background: theme.white_1,
  })}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(20)};
  width: 100%;
  height: ${convertUnit(GIFT_SHOP_TAB_HEIGHT)};
  margin: 0 ${convertUnit(20)};
`
const StyledTabItemContainer = styled.div<StyledTabItemContainerProps>`
  ${({theme, isSelected}) => ({
    height: isSelected
      ? `calc(100% - ${convertUnit(24)} - ${convertUnit(3)})`
      : undefined,
    color: isSelected ? theme.primary_5 : theme.gray_6,
    borderBottomStyle: isSelected ? 'solid' : undefined,
    borderBottomColor: isSelected ? theme.primary_5 : undefined,
    borderBottomWidth: isSelected ? convertUnit(3) : 0,
  })}
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(12)};
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
  box-sizing: content-box;
`
const StyledTitle = styled(Paragraph)`
  color: inherit;
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
`

const StyledIndicator = styled.div`
  width: ${convertUnit(6)};
  height: ${convertUnit(6)};
  border-radius: 50%;
  background-color: ${({theme}) => theme.danger_5};
  position: absolute;
  right: ${convertUnit(-7)};
`

export default function GiftShopExploreTabView({
  selectedTab,
}: GiftShopExploreTabViewProps) {
  const history = useHistory()
  const {id: yuserId} = useSelector('user') || {}
  const {lastFavoritePriceUpdate} = useSelector('giftshopState') || {}
  const {showCollectionMark, showFavoriteMark} = useSelector('giftshopState')
  const {update} = useDispatch()
  const {data} = useFirebaseUser({id: yuserId || ''}) || {}

  const handleLoadHiresConfirmationCount = useCallback(() => {
    if (selectedTab !== 'collection') {
      requestData('gitfshop_get_collection_resend_approval_count', {
        onRequestSuccess: (response) => {
          if (response.status === 200) {
            const hiresCount = response.data.result.total
            if (hiresCount > 0) {
              update('giftshopState', {showCollectionMark: true})
            }
          }
        },
      })
    }
  }, [selectedTab, update])
  const handleRenderTabView = useMemo(
    () => (
      <StyledTabView>
        <StyledTabContainer>
          <StyledTabItemContainer
            isSelected={selectedTab === 'all'}
            onClick={() => history.replace('giftshop_explore', {})}>
            <StyledTitle fontSize="m" fontWeight="bold">
              {translate('global:all')}
            </StyledTitle>
          </StyledTabItemContainer>
          <StyledTabItemContainer
            isSelected={selectedTab === 'favorite'}
            onClick={() => {
              update('giftshopState', {showFavoriteMark: false})
              history.replace('giftshop_favorite', {})
            }}>
            <StyledTitle fontSize="m" fontWeight="bold">
              {showFavoriteMark ? <StyledIndicator /> : <></>}

              {translate('giftShop:favorite')}
            </StyledTitle>
          </StyledTabItemContainer>
          <StyledTabItemContainer
            isSelected={selectedTab === 'collection'}
            onClick={() => {
              update('giftshopState', {showCollectionMark: false})
              history.push('giftshop_collection', {})
            }}>
            <StyledTitle fontSize="m" fontWeight="bold">
              {showCollectionMark ? <StyledIndicator /> : <></>}
              {translate('giftShop:collection')}
            </StyledTitle>
          </StyledTabItemContainer>
        </StyledTabContainer>
      </StyledTabView>
    ),
    [history, selectedTab, showCollectionMark, showFavoriteMark, update],
  )

  useDidMount(() => {
    if (selectedTab === 'favorite') {
      update('giftshopState', {lastFavoritePriceUpdate: new Date().getTime()})
    }
  })

  useEffect(() => {
    if (
      data &&
      typeof data.last_favorite_pricedrop_update === 'number' &&
      data.last_favorite_pricedrop_update * 1000 >
        (lastFavoritePriceUpdate ?? 0)
    ) {
      update('giftshopState', {showFavoriteMark: true})
    }
  }, [data, lastFavoritePriceUpdate, update])
  useDidMount(handleLoadHiresConfirmationCount)

  return handleRenderTabView
}
