import React, {useRef, useCallback, useMemo} from 'react'
import {TextExtraction} from 'utils'
import {ParsedTextProps} from './ParsedTextProps'

export default function ParsedText({
  children,
  parse,
  ...otherProps
}: ParsedTextProps) {
  const ref = useRef<HTMLSpanElement>(null)
  const patterns = useMemo(() => (parse ? parse.map((option) => option) : []), [
    parse,
  ])

  const extractText = useCallback(
    (childrenParam) => TextExtraction(childrenParam, patterns),
    [patterns],
  )

  const getParsedText = useCallback(() => {
    if (!parse) {
      return children
    }
    if (typeof children !== 'string') {
      return children
    }
    const textExtraction = extractText(children)
    return textExtraction.map((text, index) => {
      const parentStyle = otherProps.style
      const {style, ...remainder} = text
      return (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`parsedText-${index}`}
          style={style || parentStyle}
          {...remainder}>
          {text.children}
        </span>
      )
    })
  }, [parse, children, extractText, otherProps.style])

  return (
    <span ref={ref} {...otherProps}>
      {getParsedText()}
    </span>
  )
}
