import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button, Icon, Input, Paragraph} from 'common/components'
import {
  VALIDATION_AUTH_LOGIN_PASSWORD,
  VALIDATION_GIFTSHOP_CHANGE_USERNAME,
} from 'common/validations'
import {
  AUTH_USERNAME_MAX_LENGTH,
  GIFT_SHOP_PROFILE_CHANGE_USERNAME_LIMIT,
  REGEX_ALLOWED_USERNAME,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {GiftShopPatchUsernameBody} from 'types'
import {formatCooldownTime, showPrompt, showSnackbar} from 'utils'
import {GiftShopFormPropsBase} from './GiftShopFormProps'
import {GiftShopEditProfileLimitPasswordModal} from '../Modal'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${convertUnit(20)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`
const StyledDisclaimerContainer = styled.div`
  background-color: ${({theme}) => theme.white_3};
  display: flex;
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
  border-radius: ${convertUnit(30)};
`

export default function GiftShopUsernameForm({
  stateActiveContent,
}: GiftShopFormPropsBase) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {username: currentUsername} = useSelector('user') || {}
  const [loading, setLoading] = useState(false)
  const [passwordLimitModal, setPasswordLimitModal] = useState(false)
  const [cooldownTimer, setCooldownTimer] = useState('0')
  const setActiveContent = stateActiveContent[1]
  const [publish, setPublish] = useState(false)

  const form = useForm<GiftShopPatchUsernameBody>({
    defaultValues: {
      username: '',
      password: '',
    },
    reValidateMode: 'onChange',
  })
  const {errors, handleSubmit, setError, watch} = form
  const {username, password} = watch()

  const handleChangeUsername = useCallback(() => {
    setLoading(true)
    setPublish((prev) => !prev)
    requestData('giftshop_patch_username', {
      data: {username, password},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({data: {detail}, status}) => {
        if (status === 200) {
          update('user', {username})
          setActiveContent('profile')
          showSnackbar(translate('giftShop:changeUsernameSuccess'))
        } else if (status === 400) {
          if (detail?.username === 'has been taken by another user') {
            setError('username', {
              type: 'manual',
              message: translate('auth:validationUsernameHasBeenUsed'),
            })
          } else if (detail?.attempt_left) {
            setError('password', {
              type: 'manual',
              message: translate('auth:validationLoginAttempt', {
                attempt: detail?.attempt_left,
              }),
            })
          }
        } else if (status === 423 && typeof detail !== 'undefined') {
          setCooldownTimer(detail?.wait_time)
          setPasswordLimitModal(true)
        } else if (status === 429 && typeof detail !== 'undefined') {
          const {day, hour, minute, second} = formatCooldownTime(
            detail.wait_time.split(' ')[2],
            true,
            true,
          )
          showSnackbar(
            translate('auth:resendLimitReached', {
              duration: day + hour + minute + second,
            }),
          )
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
    })
  }, [password, setActiveContent, setError, translate, update, username])

  const isButtonDisabled = useMemo(() => {
    const isValidEmail = errors.username !== undefined || username === ''
    const isValidPassword = errors.password !== undefined || password === ''

    return isValidEmail || isValidPassword
  }, [errors.password, errors.username, password, username])

  const handleRenderPasswordLimitModal = useMemo(
    () =>
      passwordLimitModal && (
        <GiftShopEditProfileLimitPasswordModal
          cooldownTimer={cooldownTimer}
          onConfirm={() => setPasswordLimitModal((prev) => !prev)}
        />
      ),
    [cooldownTimer, passwordLimitModal],
  )

  const handleRenderUsername = useMemo(
    () => (
      <Input
        name="username"
        form={form}
        formRules={VALIDATION_GIFTSHOP_CHANGE_USERNAME(currentUsername)}
        autoComplete={false}
        allowedCharacters={REGEX_ALLOWED_USERNAME}
        maxLength={AUTH_USERNAME_MAX_LENGTH}
        rightIcon="delete"
        label={translate('giftShop:newUsername')}
        placeholder={translate('giftShop:newUsernamePlaceholder')}
      />
    ),
    [currentUsername, form, translate],
  )

  const handleRenderPassword = useMemo(
    () => (
      <Input
        type="password"
        name="password"
        form={form}
        formRules={VALIDATION_AUTH_LOGIN_PASSWORD}
        label={translate('global:password')}
        placeholder={translate('auth:loginInputPasswordPlaceholder')}
        containerStyle={{marginTop: convertUnit(20)}}
        rightIcon="visibility"
      />
    ),
    [form, translate],
  )

  const handleRenderDisclaimer = useMemo(
    () => (
      <StyledDisclaimerContainer>
        <Icon type="info" size={convertUnit(16)} />
        <Paragraph fontWeight="medium" lineHeight={21}>
          {translate('giftShop:changeUsernameWarning', {
            limit: GIFT_SHOP_PROFILE_CHANGE_USERNAME_LIMIT,
          })}
        </Paragraph>
      </StyledDisclaimerContainer>
    ),
    [translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={isButtonDisabled || loading}
        isLoading={loading}
        label={translate('global:save')}
        onClick={handleSubmit(handleChangeUsername)}
      />
    ),
    [handleChangeUsername, handleSubmit, isButtonDisabled, loading, translate],
  )

  useEffect(() => {
    const prompt = username !== '' || password !== ''
    showPrompt(!publish && prompt, translate('giftShop:uploadModalMessage'))
  }, [password, publish, translate, username])

  return (
    <StyledContainer>
      {handleRenderPasswordLimitModal}
      <StyledScreenContainer>
        {handleRenderUsername}
        {handleRenderPassword}
        {handleRenderDisclaimer}
        {handleRenderButton}
      </StyledScreenContainer>
    </StyledContainer>
  )
}
