import React, {CSSProperties, useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_TRANSACTION_STATUS_COLORS} from 'consts'
import {getCurrencyValue, parseDate} from 'utils'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateWaitingTransactionHistoryContentItemprops} from './TemplateTransactionHistoryContentItemProps'

const StyledCardContainer = styled.div`
  background-color: ${({theme}) => theme.white_1};
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(20)};
  display: flex;
  align-items: stretch;
  flex-direction: row;
  min-height: ${convertUnit(107)};
  border-bottom: ${convertUnit(1)} solid ${({theme}) => theme.white_4};
  cursor: pointer;
`

const StyledFlexItem = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  height: 100%;
  padding: 0 ${convertUnit(25)};
`

const StyledLeftContainer = styled(StyledFlexItem)`
  flex: 1;
  padding: 0;
  margin-bottom: ${convertUnit(5)};
`

const StyledInfo = styled.div<CSSProperties>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({justifyContent}) => justifyContent};
`

const StyledIconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const StyledTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  align-items: center;
`

export default function TemplateTransactionWaitingHistoryContentItem({
  data: {status, amount, transaction_date: date, transaction_partner},
  onClick,
}: TemplateWaitingTransactionHistoryContentItemprops) {
  const {translate} = useTranslation()
  const transactionPartner = transaction_partner

  const handleTransactionStatus = useMemo(
    () => (
      <StyledIconContainer>
        <Paragraph
          fontSize="s"
          fontWeight="medium"
          color={GIFT_SHOP_TRANSACTION_STATUS_COLORS[status]}>
          {translate('giftShop:transactionStatus_PROCESSED')}
        </Paragraph>
      </StyledIconContainer>
    ),
    [status, translate],
  )

  const handleTransactionType = useMemo(
    () => (
      <StyledTypeContainer>
        <Paragraph fontWeight="bold" fontSize="m">
          {translate('giftShop:transactionType_PURCHASE')}
        </Paragraph>
      </StyledTypeContainer>
    ),
    [translate],
  )

  const handleTransactionPartner = useMemo(
    () => (
      <StyledInfo justifyContent="end">
        <Paragraph fontSize="xs" fontWeight="bold" color="gray_5">
          {transactionPartner.length > 15
            ? `${transactionPartner.slice(0, 15)}...`
            : transactionPartner.join(', ')}
        </Paragraph>
      </StyledInfo>
    ),
    [transactionPartner],
  )

  const handleAmount = useMemo(
    () => (
      <StyledInfo justifyContent="start">
        <Paragraph fontSize="m">{getCurrencyValue(amount)}</Paragraph>
      </StyledInfo>
    ),
    [amount],
  )

  const handleDate = useMemo(
    () => (
      <StyledInfo justifyContent="end">
        <Paragraph fontSize="s" color="gray_4">
          {parseDate(date, 'MMM D, YYYY')}
        </Paragraph>
      </StyledInfo>
    ),
    [date],
  )

  return (
    <StyledCardContainer onClick={onClick}>
      <StyledLeftContainer alignItems="flex-start" justifyContent="center">
        {handleTransactionStatus}
        {handleTransactionType}
        {handleTransactionPartner}
      </StyledLeftContainer>
      <StyledFlexItem alignItems="flex-end" justifyContent="center">
        {handleDate}
        {handleAmount}
      </StyledFlexItem>
    </StyledCardContainer>
  )
}
