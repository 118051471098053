import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(24)};
  text-align: center;
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImg = styled(Image)`
  width: 100%;
  max-width: ${convertUnit(254)};
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const StyledTitle = styled(StyledRow)`
  justify-content: center;
`

const StyledMidContainer = styled.div`
  padding: 0 ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledMidItems = styled(StyledRow)`
  gap: ${convertUnit(20)};
  justify-content: flex-start;
  text-align: left;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopProfileUpgradeCreator() {
  const {translate} = useTranslation()
  const creatorImg = IMAGE_ASSET('giftshop', 'selfie-boy.png')
  const history = useHistory()

  const handleRenderImage = useMemo(
    () => (
      <StyledImgContainer>
        <StyledImg src={creatorImg} alt={creatorImg} />
      </StyledImgContainer>
    ),
    [creatorImg],
  )

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitle>
        <Paragraph fontSize="l" fontWeight="bold" color="primary_7">
          {translate('giftShop:upgradeCreator')}
        </Paragraph>
        <Icon type="check" color="success_3" size={convertUnit(25)} />
      </StyledTitle>
    ),
    [translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <StyledMidContainer>
        <StyledMidItems>
          <Icon type="cash" color="success_5" size={convertUnit(32)} />
          <Paragraph fontSize="s" fontWeight="medium" color="gray_6">
            {translate('giftShop:upgradeCreatorPrice')}
          </Paragraph>
        </StyledMidItems>
        <StyledMidItems>
          <Icon type="data" color="primary_5" size={convertUnit(32)} />
          <Paragraph fontSize="s" fontWeight="medium" color="gray_6">
            {translate('giftShop:upgradeCreatorStorage')}
          </Paragraph>
        </StyledMidItems>
        <StyledMidItems>
          <Icon type="video-view" color="primary_5" size={convertUnit(32)} />
          <Paragraph fontSize="s" fontWeight="medium" color="gray_6">
            {translate('giftShop:upgradeCreatorVideo')}
          </Paragraph>
        </StyledMidItems>
      </StyledMidContainer>
    ),
    [translate],
  )

  const handleRenderBottom = useMemo(
    () => (
      <>
        <Paragraph fontSize="s" color="gray_5">
          {translate('giftShop:upgradeCreatorDescription')}
        </Paragraph>
        <StyledButton
          label={translate('global:ok')}
          onClick={() => history.goBack()}
        />
      </>
    ),
    [history, translate],
  )

  return (
    <StyledContainer>
      {handleRenderImage}
      {handleRenderTitle}
      {handleRenderMidSection}
      {handleRenderBottom}
    </StyledContainer>
  )
}
