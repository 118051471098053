import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {showSnackbar} from 'utils'
import {
  CheckboxItem,
  ConfirmationModal,
  ModalLoading,
  Paragraph,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledFirstBuyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
`

export default function TreeEditProfileFirstBuy() {
  const {translate} = useTranslation()
  const [modal, setModal] = useState<'confirmation' | 'loading'>()
  const {update} = useDispatch()
  const {isFirstBuyEnabled} = useSelector('hostState')

  const handlePatchFreeFirstBuy = useCallback(() => {
    setModal('loading')
    requestData('tree_patch_hosts', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {is_first_buy_enabled: !isFirstBuyEnabled},
      onRequestReceived: () => setModal(undefined),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setModal(undefined)
          update('hostState', {isFirstBuyEnabled: !isFirstBuyEnabled})
          showSnackbar(
            translate(
              isFirstBuyEnabled
                ? 'tree:addHostInfoFirstBuyUnchecked'
                : 'tree:addHostInfoFirstBuyChecked',
            ),
          )
        }
      },
    })
  }, [isFirstBuyEnabled, translate, update])

  const handleRenderModalLoading = useMemo(() => <ModalLoading visible />, [])

  const handleRenderConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('tree:addHostInfoFirstBuyTitle')}
        text={translate('tree:editHostFirstBuyModalDescription')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handlePatchFreeFirstBuy}
        toggleModal={() => setModal(undefined)}
      />
    ),
    [handlePatchFreeFirstBuy, translate],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'confirmation':
        return handleRenderConfirmationModal
      case 'loading':
        return handleRenderModalLoading
    }
    return null
  }, [handleRenderConfirmationModal, handleRenderModalLoading, modal])

  return (
    <>
      {handleRenderModal}
      <StyledFirstBuyContainer>
        <Paragraph fontSize="m" fontWeight="medium" color="gray_5">
          {translate('tree:addHostInfoFirstBuyTitle')}
        </Paragraph>
        <StyledCheckboxItem
          isSelected={
            isFirstBuyEnabled !== undefined ? isFirstBuyEnabled : true
          }
          description={translate('tree:addHostInfoFirstBuyDescription')}
          onClick={() => setModal('confirmation')}
          fontSize="m"
          color="black"
        />
      </StyledFirstBuyContainer>
    </>
  )
}
