import React, {useCallback, useState, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {
  FONT_FAMILY_MAP,
  FORKYGRAM_COMMENT_LIMIT_DAY,
  FORKYGRAM_COMMENT_MAX_LENGTH,
  IMAGE_ASSET,
} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {
  FontSizeType,
  FontWeightType,
  ForkygramGetMentionMemberResponse,
} from 'types'
import {ThemeColor} from 'themes'
import {
  getFontSize,
  showSnackbar,
  useKeywordExtract,
  useHistory,
  showModalReprocessKyc,
} from 'utils'
import {Icon, Image, ListLazy, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateAuthAccessModal} from 'pages'
import ForkygramCommentMentionItem from './ForkygramCommentMentionItem'
import {ForkygramCommentInputProps} from './ForkygramCommentInputProps'

interface StyledInputProps {
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  placeholderColor?: ThemeColor
  disabled?: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledWrapper = styled.div`
  display: flex;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  column-gap: ${convertUnit(12)};
  width: 100%;
  box-sizing: border-box;
  border-top: solid ${convertUnit(1)} ${({theme}) => theme.white_4};
  align-items: center;
`

const StyledListContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${convertUnit(236)};
  position: absolute;
  left: ${convertUnit(0)};
  top: ${convertUnit(-236)};
`

const StyledInput = styled.textarea<StyledInputProps>`
  display: flex;
  flex: 1;
  resize: none;
  border: none;
  outline: none;
  padding: 0;
  flex-wrap: 'wrap';
  word-break: 'break-word';
  ${({
    theme,
    color = 'black',
    placeholderColor = 'gray_3',
    fontSize = 'm',
    fontWeight = 'regular',
    disabled,
  }) => ({
    fontSize: getFontSize(fontSize),
    fontFamily: FONT_FAMILY_MAP[fontWeight],
    color: theme[color],
    backgroundColor: disabled ? theme.white_3 : 'inherit',
    '::placeholder': {
      color: theme[placeholderColor],
    },
    ':disabled': {
      backgroundColor: 'inherit',
    },
  })}

  scrollbar-width: auto;
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(20)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
  box-sizing: content-box;
`

const StyledPhantomSpan = styled.span`
  display: block;
  max-width: ${convertUnit(346)};
  width: 100%;
  word-wrap: break-word;
  position: absolute;
  min-height: ${convertUnit(24)};
  visibility: hidden;
  font-size: ${convertUnit(15)};
  font-family: 'Roboto-regular';
`

const StyledToast = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_1,
    borderBottom: `solid ${convertUnit(1)} ${theme.gray_1}`,
    borderTop: `solid ${convertUnit(1)} ${theme.gray_1}`,
  })}
  display: flex;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  flex: 0 1;
  gap: ${convertUnit(12)};
`

const StyledInputContainer = styled.div`
  display: flex;
  flex: 1 auto;
`

const StyledBold = styled.span`
  font-family: 'Roboto-Bold';
`

export default function ForkygramCommentInput({
  stateCommentCount,
  stateRecipient,
  stateSearch,
  postId,
  stateVisible,
  stateCommentData,
  stateRepliedToComment,
}: ForkygramCommentInputProps) {
  const defaultImg = IMAGE_ASSET('giftshop', 'users/default.png')
  const {access_token, username, photo} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const setCommentCount = stateCommentCount[1]
  const [recipient, setRecipient] = stateRecipient
  const setSearch = stateSearch[1]
  const setVisible = stateVisible[1]
  const ref = useRef<HTMLSpanElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const formMessage = useRef('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const showSearchState = useState(false)
  const [showSearch, setShowSearch] = showSearchState
  const timeState = useState(0)
  const timeStamp = timeState[0]
  const setCommentData = stateCommentData[1]
  const setRepliedToComment = stateRepliedToComment[1]
  const {handleKeyDown, handleChange, extractedText} = useKeywordExtract({
    ref: inputRef,
    listSearchPropsState: timeState,
    searchState: showSearchState,
  })

  const mentionedUser = useMemo((): Record<string, string> => ({}), [])
  const {kycStatus} = useSelector('yuserActivationState')
  const history = useHistory()

  const handleFeatureDisabled = useCallback(() => {
    setVisible(false)
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, setVisible, similarStatus])

  const handlePostComment = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    setCommentCount((prev) => prev + 1)
    setLoading(true)
    const date = new Date().getTime()
    formMessage.current = message
    for (const user in mentionedUser) {
      if (Object.hasOwnProperty.call(mentionedUser, user)) {
        formMessage.current = formMessage.current.replace(
          `@${user}`,
          `{{${mentionedUser[user]}}}`,
        )
      }
    }
    if (recipient === undefined) {
      requestData('forkygram_post_comment', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {comment: formMessage.current, post_id: postId},
        onRequestFailed: () => {
          setLoading(false)
          setCommentCount((prev) => prev - 1)
        },
        onRequestSuccess: ({data: {detail}}) => {
          setLoading(false)
          setMessage('')
          formMessage.current = ''
          setSearch(date)
          if (detail)
            showSnackbar(
              translate('forkygram:commentLimitPerDay', {
                comment_limit: FORKYGRAM_COMMENT_LIMIT_DAY,
              }),
            )
        },
      })
    } else
      requestData('forkygram_post_reply', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {comment: formMessage.current, comment_id: recipient.comment_id},
        onRequestFailed: () => {
          setLoading(false)
          setCommentCount((prev) => prev - 1)
        },
        onRequestSuccess: ({data: {detail}}) => {
          setLoading(false)
          setMessage('')
          setRepliedToComment(recipient.comment_id)
          setRecipient(undefined)
          setSearch(date)
          setCommentData((prev) =>
            prev.map((x) =>
              x.comment_id === recipient.comment_id
                ? {...x, reply_count: x.reply_count + 1}
                : x,
            ),
          )
          if (detail)
            showSnackbar(
              translate('forkygram:commentLimitPerDay', {
                comment_limit: FORKYGRAM_COMMENT_LIMIT_DAY,
              }),
            )
        },
      })
  }, [
    kycStatus,
    setCommentCount,
    message,
    recipient,
    handleFeatureDisabled,
    mentionedUser,
    postId,
    setSearch,
    setRepliedToComment,
    setRecipient,
    setCommentData,
  ])

  const handleSearchMention = useCallback(
    async (page: number) => {
      const response = await requestData('forkygram_get_member', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit: 10, q: extractedText.current},
      })
      return typeof response !== 'string' && response.data.result
        ? response.data.result
        : []
    },
    [extractedText],
  )

  const handleAutoResize = useCallback(() => {
    if (inputRef.current) {
      ref.current && ref.current.offsetHeight < inputRef.current.scrollHeight
        ? (inputRef.current.style.height = convertUnit(
            ref.current.offsetHeight,
          ))
        : (inputRef.current.style.height = convertUnit(
            inputRef.current.scrollHeight,
          ))
    }
    return {}
  }, [])

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderInput = useMemo(
    () => (
      <StyledInputContainer>
        <StyledPhantomSpan ref={ref}>{message}</StyledPhantomSpan>
        <StyledInput
          ref={inputRef}
          value={message}
          style={
            access_token
              ? {height: convertUnit(24)}
              : {minHeight: convertUnit(24)}
          }
          maxLength={FORKYGRAM_COMMENT_MAX_LENGTH}
          placeholder={translate('forkygram:commentPlaceholder', {
            context: !access_token && 'GUEST',
            username,
          })}
          onClick={() => !access_token && setLoginModal(true)}
          onChange={(e) => {
            if (!access_token) {
              setLoginModal(true)
              setMessage('')
              return
            }
            const text = e.target.value
            setMessage(text)
            handleChange(e)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && message !== '' && !loading) {
              e.preventDefault()
              handlePostComment()
              inputRef.current?.blur()
            }
            handleKeyDown(e)
            access_token && handleAutoResize()
          }}
        />
      </StyledInputContainer>
    ),
    [
      access_token,
      handleAutoResize,
      handleChange,
      handleKeyDown,
      handlePostComment,
      loading,
      message,
      username,
    ],
  )

  const handleRenderToast = useMemo(
    () =>
      recipient && (
        <StyledToast>
          <Icon
            type="close"
            color="black"
            size={12}
            style={{cursor: 'pointer'}}
            onClick={() => setRecipient(undefined)}
          />
          <Paragraph fontSize="s" fontWeight="regular">
            {translate('forkygram:replying')}{' '}
            <StyledBold>@{recipient.username}</StyledBold>
          </Paragraph>
        </StyledToast>
      ),
    [recipient, setRecipient],
  )

  const handleKeyExtractor = useCallback(
    (item: ForkygramGetMentionMemberResponse) => item.id,
    [],
  )

  const handleClickItem = useCallback(
    (item: ForkygramGetMentionMemberResponse) => {
      const regex = new RegExp(`@${extractedText.current}\\b`)
      mentionedUser[item.username] = item.id
      setMessage((prev) => prev.replace(regex, `@${item.username} `))
      inputRef.current?.focus()
      setShowSearch((prev) => !prev)
      handleAutoResize()
    },
    [extractedText, handleAutoResize, mentionedUser, setShowSearch],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramGetMentionMemberResponse) => (
      <ForkygramCommentMentionItem
        onClickItem={() => handleClickItem(item)}
        data={item}
      />
    ),
    [handleClickItem],
  )

  const handleRenderSearchList = useMemo(
    () =>
      showSearch && (
        <StyledListContainer>
          <ListLazy
            backgroundColor="white_1"
            keyExtractor={handleKeyExtractor}
            loadData={handleSearchMention}
            renderItem={handleRenderItem}
            scrollbar
            search={timeStamp}
          />
        </StyledListContainer>
      ),
    [
      handleKeyExtractor,
      handleRenderItem,
      handleSearchMention,
      showSearch,
      timeStamp,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderLoginModal}
      {handleRenderToast}
      {handleRenderSearchList}
      <StyledWrapper>
        <StyledAvatar src={photo || defaultImg} alt="avatar" />
        {handleRenderInput}
        <Icon
          type="send"
          color={message !== '' && !loading ? 'primary_5' : 'gray_3'}
          onClick={message === '' || loading ? undefined : handlePostComment}
          style={{cursor: message === '' || loading ? 'default' : 'pointer'}}
        />
      </StyledWrapper>
    </StyledContainer>
  )
}
