import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeSearchFototreeResponse} from 'types'
import {formatDateRange, useDebounce} from 'utils'
import {
  IconVerifiedBadge,
  Input,
  ListItemSelect,
  ListLazy,
  Menu,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileEditBulkModalFormProps} from './GiftShopProfileEditBulkModalProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContentListContainer = styled.div`
  border-style: solid;
  border-radius: ${convertUnit(16)};
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  text-align: center;
`

const StyledBold = styled.span`
  font-family: 'Roboto-Bold';
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  line-height: ${convertUnit(12)};
`

export default function GiftShopProfileEditBulkModalFormTags({
  form,
}: GiftShopProfileEditBulkModalFormProps) {
  const {translate} = useTranslation()
  const debounce = useDebounce()
  const stateTags = useState<readonly TreeSearchFototreeResponse[]>([])
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const {setValue, watch} = form
  const {tags} = watch()
  const [searchTag, setSearchTag] = useState(tags[0] ? tags[0].name : '')
  const [searchQuery, setSearchQuery] = useState(searchTag)
  const [error, setError] = useState<string>()

  const handleLoadData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestData('tree_search_fototree', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {page, limit, name: q, is_upload: true},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [],
  )

  const handleSelectTag = useCallback(
    (item: TreeSearchFototreeResponse) => {
      setError(undefined)
      setSearchTag(item.name)
      setValue('tags', [item])
      setToggle((previous) => !previous)
    },
    [setToggle, setValue],
  )

  const handleDeleteTag = useCallback(() => {
    setSearchTag('')
    setSearchQuery('')
    setValue('tags', [])
    setToggle((previous) => !previous)
  }, [setToggle, setValue])

  const handleRenderDropdownItem = useCallback(
    (item: TreeSearchFototreeResponse) => (
      <ListItemSelect
        title={item.name}
        titleRighIcon={
          item.status === 'VERIFIED' ? <StyledIconVerifiedBadge /> : <></>
        }
        overflowSubtitle={`${
          item.host_fee
            ? `${translate('giftShop:uploadTagHostFeeFototreeDesc', {
                hostFee: item.host_fee,
              })}`
            : ''
        }
      ${
        item.host_fee && (item.event_end_date || item.event_start_date)
          ? ' | '
          : ''
      }
      ${
        item.event_end_date && item.event_start_date
          ? `${formatDateRange(item.event_start_date, item.event_end_date)}`
          : ''
      }
      ${
        (item.host_fee || item.event_end_date || item.event_start_date) &&
        item.bio
          ? ' | '
          : ''
      }
      ${item.bio ? `${item.bio}` : ''}`}
        hoverColor="white_3"
        titleHoverColor="black"
        onClick={() => {
          handleSelectTag(item)
        }}
      />
    ),
    [handleSelectTag, translate],
  )

  const handleRenderTagListEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="m">
          {translate('giftShop:uploadInputTagsNotFound')}
          <StyledBold>{`"${searchTag}"`}</StyledBold>
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [searchTag, translate],
  )

  const handleRenderTagList = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateTags}
          loadData={handleLoadData}
          search={searchQuery}
          keyExtractor={(item) => item.id}
          renderItem={handleRenderDropdownItem}
          listEmptyElement={handleRenderTagListEmpty}
        />
      </StyledContentListContainer>
    ),
    [
      handleLoadData,
      handleRenderDropdownItem,
      handleRenderTagListEmpty,
      searchQuery,
      stateTags,
    ],
  )

  const handleRenderInput = useMemo(
    () => (
      <Menu
        stateToggle={stateToggle}
        contentContainerStyle={{
          height: convertUnit(130),
          borderRadius: convertUnit(16),
        }}
        renderContent={handleRenderTagList}>
        <Input
          value={searchTag}
          label={translate('giftShop:uploadTagLabel')}
          placeholder={translate('giftShop:uploadInputTagsPlaceholder')}
          borderColor={error ? 'danger_5' : undefined}
          hoverBorderColor={error ? 'danger_5' : undefined}
          onChangeText={(text) => {
            setSearchTag(text)
            debounce(() => setSearchQuery(text), 500)
            setError(undefined)
            if (!text) handleDeleteTag()
          }}
          rightIcon="delete"
          onRightIconClick={handleDeleteTag}
          rightIconStyle={{cursor: 'pointer'}}
          onBlur={() => {
            if (searchTag && !tags.length) {
              setError(translate('giftShop:uploadInputTagsNotSelected'))
            }
          }}
          onFocus={() => {
            setError(undefined)
            setToggle(true)
          }}
        />
      </Menu>
    ),
    [
      debounce,
      error,
      handleDeleteTag,
      handleRenderTagList,
      searchTag,
      setToggle,
      stateToggle,
      tags.length,
      translate,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderInput}
      {error ? (
        <Paragraph color="danger_5" fontWeight="medium">
          {error}
        </Paragraph>
      ) : (
        <></>
      )}
      {!!tags[0] && tags[0].host_fee ? (
        <Paragraph color="gray_5">
          {translate('giftShop:uploadTagHostFee', {
            hostFee: tags[0].host_fee,
          })}
        </Paragraph>
      ) : (
        <></>
      )}
    </StyledContainer>
  )
}
