import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {translate} from 'i18n'
import {WindowModeType} from 'types'
import {useHistory, useLocation} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useWindowMode} from 'windows'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    padding: mode === 'website' ? convertUnit(125) : 0,
    width: mode === 'website' ? convertUnit(600) : '100%',
    height: mode === 'website' ? convertUnit(610) : '100%',
  })};
  display: flex;
  border-radius: ${convertUnit(16)};
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-self: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
export default function TreeHostPromoteAdmin() {
  const {context} = useLocation('tree_host_promote_success').state
  const history = useHistory()
  const mode = useWindowMode()

  const handleSuccessScreen = useMemo(
    () => (
      <StyledContainer mode={mode}>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('auth:linkSuccessHeader')}
        </StyledParagraph>
        <StyledParagraph fontSize="m" fontWeight="regular">
          {context === 'admin'
            ? translate('tree:hostManageAdminSuccessInviteSubtitle')
            : translate('tree:hostManageCreatorSuccessInviteSubtitle')}
        </StyledParagraph>
        <StyledImage
          src={IMAGE_ASSET('giftshop', 'success.png')}
          alt="success"
        />
        <Button
          label={
            context === 'admin'
              ? translate('tree:profileManageAdmin')
              : translate('tree:profileManageOfficialCreator')
          }
          onClick={() =>
            context === 'admin'
              ? history.replace('tree_host_manage_admin', {})
              : history.replace('tree_host_manage_creator', {})
          }
        />
      </StyledContainer>
    ),
    [context, history, mode],
  )

  return handleSuccessScreen
}
