import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthCredentialType, ObjectState} from 'types'
import {IMAGE_ASSET} from 'consts'
import {Button, Icon, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(8)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`
const StyledImage = styled(Image)`
  max-width: ${convertUnit(40)};
`

interface GiftShopTemplateModalPasswordProps {
  stateModal: ObjectState<boolean>
  credential?: AuthCredentialType
  onAgree(): void
}

export default function GiftShopTemplateModalPassword({
  stateModal,
  credential,
  onAgree,
}: GiftShopTemplateModalPasswordProps) {
  const {translate} = useTranslation()
  const [modalCredential, setModalCredential] = stateModal

  const handleCancel = useCallback(() => {
    setModalCredential(false)
  }, [setModalCredential])

  return (
    <StyledModal visible={modalCredential}>
      <StyledImageContainer>
        {credential === 'phone' ? (
          <StyledImage
            src={IMAGE_ASSET('giftshop', 'whats-app.png')}
            alt="whatsapp"
          />
        ) : (
          <Icon type="email-add-ol" size={40} color="primary_5" />
        )}
      </StyledImageContainer>
      <StyledTitle fontSize="l" fontWeight="medium">
        {translate('giftShop:profileAddCredential', {context: credential})}
      </StyledTitle>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:later')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={handleCancel} />
        <StyledButton
          label={translate('global:addNow')}
          onClick={onAgree} />
      </StyledButtonContainer>
    </StyledModal>
  )
}
