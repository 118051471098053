import {lazy} from 'react'

const GiftShopAddBankAccountScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddBankAccount' */ './GiftShopAddBankAccountScreen'
    ),
)

const GiftShopBankListScreen = lazy(
  () => import(/* webpackChunkName: 'BankList' */ './GiftShopBankListScreen'),
)

const GiftShopMyBalanceScreen = lazy(
  () => import(/* webpackChunkName: 'MyBalance' */ './GiftShopMyBalanceScreen'),
)

const GiftShopCreatePasswordSSOModal = lazy(
  () =>
    import(
      /* webpackChunkName: 'CreatePasswordSSOModal' */ './GiftShopCreatePasswordSSOModal'
    ),
)

const GiftShopWaitingForPaymentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'GiftShopWaitingForPaymentScreen' */ './GiftShopWaitingForPaymentScreen'
    ),
)

export {
  GiftShopAddBankAccountScreen,
  GiftShopBankListScreen,
  GiftShopMyBalanceScreen,
  GiftShopCreatePasswordSSOModal,
  GiftShopWaitingForPaymentScreen,
}
