import {useCallback, useState} from 'react'
import firebase from 'lib/firebase'
import {useSelector} from 'lib/redux'
import {FirestorePaymentBalanceRefreshPayload} from 'types'
import {useDidMount} from 'utils'

export function useFirestorePaymentBalanceRefresh() {
  const user = useSelector('user')
  const [lastUpdate, setLastUpdate] = useState<number>()

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIREBASE_COLLECTION_USER!)
        .doc(user?.id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data() as FirestorePaymentBalanceRefreshPayload
          data && setLastUpdate(data.balance_last_update)
        }),
    [user?.id],
  )

  useDidMount(handleFirestore)

  return lastUpdate
}
