import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Image, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {getBorder, getFontFamily} from 'utils'
import {FototreeFootprintListItemProps} from './FototreeFootprintProps'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(2.5)};
  justify-content: center;
`

const StyledImage = styled(Image)`
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
  border-radius: ${convertUnit(8)};
  max-width: ${convertUnit(56)};
  width: 100%;
  aspect-ratio: 0.75;
  object-fit: contain;
`

const StyledBold = styled.span`
font-family ${getFontFamily('bold')}
`

export default function FototreeFootprintListItem({
  data,
  onClickItem,
}: FototreeFootprintListItemProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const {translate} = useTranslation()

  const handleRenderImage = useMemo(
    () => (
      <StyledImage
        src={data.photo}
        fallbackSrc={defaultImage}
        alt={data.name}
      />
    ),
    [data.name, data.photo, defaultImage],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledItemInfoContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {data.name}
        </Paragraph>
        <Paragraph color="primary_5">
          <StyledBold>{data.leaf_count}</StyledBold>{' '}
          {translate('forkygram:leafs')}
        </Paragraph>
      </StyledItemInfoContainer>
    ),
    [data.leaf_count, data.name, translate],
  )

  return (
    <StyledContainer onClick={() => onClickItem(data)}>
      {handleRenderImage}
      {handleRenderInfo}
    </StyledContainer>
  )
}
