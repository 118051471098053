import React from 'react'
import styled from 'styled-components'
import {useTheme} from 'themes'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  line-height: 1.5;
  padding: ${convertUnit(25)};
  align-items: center;
  justify-content: center;
`

const StyledSVG = styled.svg`
  padding: ${convertUnit(50)};
  height: 80px;
  width: 145px;
`

export default function GiftShopUploadEmpty() {
  const {translate} = useTranslation()
  const theme = useTheme()

  return (
    <StyledContainer>
      <StyledSVG viewBox="0 0 145 80" fill="none">
        <path
          d="M41.7987 0C45.0193 1.42101e-07 47.6332 1.08363 49.6403 3.25088C51.6474 5.46525 52.6509 8.22144 52.6509 11.5194C52.6509 14.8645 51.6474 17.6207 49.6403 19.788C47.6332 22.0024 45.0193 23.1095 41.7987 23.1095C38.6247 23.1095 36.0341 22.0259 34.027 19.8587C32.0666 17.6914 31.0864 14.9117 31.0864 11.5194C31.0864 8.17432 32.0666 5.41814 34.027 3.25088C36.0341 1.08363 38.6247 -1.40041e-07 41.7987 0ZM99.1405 2.53001e-06C102.361 2.67211e-06 104.975 1.08363 106.982 3.25089C108.989 5.46526 109.993 8.22144 109.993 11.5194C109.993 14.8645 108.989 17.6207 106.982 19.788C104.975 22.0024 102.361 23.1095 99.1405 23.1095C95.9665 23.1095 93.376 22.0259 91.3689 19.8587C89.4085 17.6914 88.4283 14.9117 88.4283 11.5194C88.4283 8.17432 89.4085 5.41814 91.3689 3.25089C93.376 1.08363 95.9665 2.38997e-06 99.1405 2.53001e-06Z"
          fill={theme.gray_3}
        />
        <path
          d="M73.3052 40.7774C83.994 40.7774 94.1695 42.2144 103.831 45.0883C113.493 47.9623 122.035 52.2026 129.457 57.8092C136.925 63.4158 142.106 69.4935 145 76.0424L134.078 80C129.037 73.1213 121.242 67.7503 110.693 63.8869C100.144 60.0236 87.8215 58.0919 73.7253 58.0919H71.5548C57.4119 58.0919 45.0427 60 34.4471 63.8162C23.8516 67.6325 15.94 73.0271 10.7122 80L0 76.0424C2.84726 69.6349 7.8883 63.6513 15.1231 58.0919C22.3113 52.5795 30.6197 48.3628 40.0483 45.4417C49.4769 42.5206 59.3256 40.9658 69.5944 40.7774H73.3052Z"
          fill={theme.gray_3}
        />
      </StyledSVG>
      <Paragraph fontSize="xl" fontWeight="medium" color="gray_3">
        {translate('giftShop:uploadEmptyTitle')}
      </Paragraph>
      <StyledParagraph fontSize="l" color="gray_3">
        {translate('giftShop:uploadEmptyDesc')}
      </StyledParagraph>
    </StyledContainer>
  )
}
