import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  AUTH_COUNTRY_DEFAULT,
  REGEX_ALPHABET_AND_SPACE,
  REGEX_PHONE_NUMBER,
} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {AuthCountryResponse} from 'types'
import {showSnackbar} from 'utils'
import {
  Button,
  Input,
  InputArea,
  InputPhone,
  Modal,
  Paragraph,
} from 'common/components'
import {
  VALIDATION_AUTH_EMAIL,
  VALIDATION_AUTH_PHONE,
  VALIDATION_AUTH_REQUIRED,
  VALIDATION_GIFTSHOP_ADDBANKACCOUNT_FULLNAME,
} from 'common/validations'
import convertUnit from 'lib/unit'
import {PartnershipModalProps} from './PartnershipModalProps'

const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${convertUnit(20)};
`

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
`

export default function PartnershipModal({stateModal}: PartnershipModalProps) {
  const [modal, setModal] = stateModal
  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const country = stateCountry[0]
  const defaultValues = {
    name: '',
    email: '',
    phone_number: '',
    message: '',
  }
  const form = useForm({defaultValues})
  const [loading, setLoading] = useState(false)
  const {errors, watch} = form
  const {name, email, phone_number, message} = watch()

  const handleClickSend = useCallback(() => {
    setLoading(true)
    requestData('giftshop_post_partnership', {
      data: {
        name,
        email,
        phone_code: country.dial_code,
        phone_number,
        message,
      },
      onRequestReceived: () => {
        setLoading(false)
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('giftShop:partnershipSuccess'))
        } else {
          showSnackbar(translate('global:messageError', {context: status}))
        }
        setModal(false)
      },
    })
  }, [country.dial_code, email, message, name, phone_number, setModal])

  const isDisabled = useMemo(
    () =>
      loading ||
      errors.name !== undefined ||
      errors.email !== undefined ||
      errors.phone_number !== undefined ||
      name === '' ||
      email === '' ||
      phone_number === '' ||
      message?.length <= 0,
    [
      email,
      errors.email,
      errors.name,
      errors.phone_number,
      loading,
      message?.length,
      name,
      phone_number,
    ],
  )

  return (
    <StyledModal visible={modal}>
      <StyledParagraph fontSize="l" fontWeight="bold">
        {translate('giftShop:partnership')}
      </StyledParagraph>
      <Input
        name="name"
        form={form}
        value={name}
        containerStyle={{
          marginBottom: convertUnit(20),
        }}
        formRules={VALIDATION_GIFTSHOP_ADDBANKACCOUNT_FULLNAME}
        allowedCharacters={REGEX_ALPHABET_AND_SPACE}
        label={translate('global:name')}
        placeholder={translate('giftShop:partnershipNamePlaceholder')}
        rightIcon="delete"
      />
      <Input
        name="email"
        form={form}
        value={email}
        containerStyle={{
          marginBottom: convertUnit(20),
        }}
        formRules={VALIDATION_AUTH_EMAIL}
        label={translate('global:email')}
        placeholder={translate('giftShop:partnershipNamePlaceholder')}
        rightIcon="delete"
      />
      <InputPhone
        name="phone_number"
        loadData={handleLoadDataPhoneDummy}
        stateSelect={stateCountry}
        form={form}
        formRules={VALIDATION_AUTH_PHONE}
        allowedCharacters={REGEX_PHONE_NUMBER}
        emptyMessage={(search) =>
          translate('auth:registerIdentifierSearchCountryEmpty', {
            search,
          })
        }
        label={translate('global:phoneNumber')}
        placeholder={translate('auth:registerIdentifierInputPhonePlaceholder')}
        maxLength={13}
        searchPlaceholder={translate('global:search')}
        autoComplete={false}
        containerStyle={{marginBottom: convertUnit(20)}}
        rightIcon="delete"
      />
      <InputArea
        name="message"
        form={form}
        value={message}
        containerStyle={{
          marginBottom: convertUnit(20),
        }}
        formRules={VALIDATION_AUTH_REQUIRED}
        label={translate('global:message')}
        placeholder={translate('giftShop:partnershipMessagePlaceholder')}
        maxLength={300}
        inputContainerStyle={{height: convertUnit(100)}}
      />
      <StyledButtonsContainer>
        <Button
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={() => setModal(false)}
          width={convertUnit(120)}
        />
        <Button
          isLoading={loading}
          label={translate('global:send')}
          width={convertUnit(120)}
          onClick={handleClickSend}
          disabled={isDisabled}
        />
      </StyledButtonsContainer>
    </StyledModal>
  )
}
