import {Workbox} from 'workbox-window'

export default function registerServiceWorker() {
  if (process.env.NODE_ENV !== 'production') return

  if ('serviceWorker' in navigator) {
    const wb = new Workbox('./service-worker.js')

    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) window.location.reload()
    })

    wb.register()
      .then((swReg) => {
        console.log('[SW] registration successfully', swReg)
      })
      .catch((error) => {
        console.error('[SW] registration failed', error)
      })
  } else console.log('[SW] not support in your browser!')
}
