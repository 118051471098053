import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {Button, Image, Link, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AuthLinkSuccessProps} from './AuthLinkSuccessProps'

const StyledHeaderDescription = styled(Paragraph)`
  margin: ${convertUnit(10)} 0;
  text-align: center;
`

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledIllustrationContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${convertUnit(200)};
  align-items: center;
  justify-content: center;
`

const StyledIllustration = styled(Image)`
  width: 100%;
  height: '100%';
  max-width: ${convertUnit(350)};
  object-fit: contain;
`

const StyledInformation = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export default function AuthLinkSuccess({
  title,
  description,
  information,
}: AuthLinkSuccessProps) {
  const {translate} = useTranslation()
  const {access_token} = useSelector('user') || {}
  const successImage = IMAGE_ASSET('giftshop', 'success.png')

  return (
    <>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {title}
      </StyledParagraph>
      <StyledHeaderDescription fontSize="m" color="gray_5">
        {description}
      </StyledHeaderDescription>
      <StyledIllustrationContainer>
        <StyledIllustration src={successImage} alt="" />
      </StyledIllustrationContainer>
      <StyledInformation color="gray_3">{information}</StyledInformation>
      <Link to={access_token ? 'giftshop_edit_profile' : 'auth_login'} replace>
        <StyledButtonLogin
          label={
            access_token
              ? translate('auth:messageBackToProfile')
              : translate('auth:messageBackToLogin')
          }
        />
      </Link>
    </>
  )
}
