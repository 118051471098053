import React, {PointerEventHandler, useCallback, useMemo, useState} from 'react'
import {ThemeColor} from 'themes'
import {IconType} from 'types'
import styled, {CSSProperties} from 'styled-components'
import convertUnit from 'lib/unit'
import {ListItemSelectProps} from './ListItemSelectProps'
import {Paragraph} from '../Paragraph'
import {Icon} from '../Icon'

interface StyledContainerProps {
  hoverEffect?: boolean
  hoverColor?: ThemeColor
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, hoverEffect, hoverColor = 'primary_5'}) => ({
    ':hover': {
      color: theme.white_1,
      backgroundColor: hoverEffect ? theme[hoverColor] : theme.white_3,
    },
  })}
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(13)} ${convertUnit(24)};
  align-items: center;
  cursor: default;
`
const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const StyledTitleIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(6)};
`

const StyledOverflowSubtitle = styled(Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export default function ListItemSelect({
  title,
  subtitle,
  selected = false,
  leftElement,
  rightElement,
  hoverEffect = true,
  textColor = undefined,
  titleRighIcon,
  overflowSubtitle,
  hoverColor,
  titleHoverColor = 'white_1',
  onPointerEnter,
  onPointerLeave,
  ...props
}: ListItemSelectProps) {
  const [hover, setHover] = useState(false)

  const handlePointerEnter = useCallback<PointerEventHandler<HTMLDivElement>>(
    (event) => {
      onPointerEnter && onPointerEnter(event)
      setHover(true)
    },
    [onPointerEnter],
  )

  const handlePointerLeave = useCallback<PointerEventHandler<HTMLDivElement>>(
    (event) => {
      onPointerLeave && onPointerLeave(event)
      setHover(false)
    },
    [onPointerLeave],
  )

  const handleRenderIcon = useCallback(
    (type: IconType, style?: CSSProperties) => (
      <Icon style={style} type={type} size={16} color="inherit" />
    ),
    [],
  )

  const handleRenderLeftElement = useMemo(
    () =>
      typeof leftElement === 'string'
        ? handleRenderIcon(leftElement, {
            marginRight: convertUnit(8),
          })
        : leftElement,
    [leftElement, handleRenderIcon],
  )

  const handleRenderRightElement = useMemo(
    () =>
      typeof rightElement === 'string'
        ? handleRenderIcon(rightElement, {
            marginLeft: convertUnit(8),
          })
        : rightElement,
    [rightElement, handleRenderIcon],
  )

  return (
    <StyledContainer
      data-testid="list"
      hoverColor={hoverColor}
      {...props}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      hoverEffect={hoverEffect}>
      {handleRenderLeftElement}
      <StyledTitleContainer>
        <StyledTitleIconContainer>
          <Paragraph
            fontSize="m"
            fontWeight={
              hoverEffect ? (hover || selected ? 'bold' : 'medium') : 'medium'
            }
            color={
              hoverEffect
                ? hover
                  ? titleHoverColor
                  : selected
                  ? 'primary_5'
                  : textColor
                : textColor
            }>
            {title}
          </Paragraph>
          {titleRighIcon}
        </StyledTitleIconContainer>
        {subtitle && (
          <Paragraph fontSize="s" color="gray_5">
            {subtitle}
          </Paragraph>
        )}
        {overflowSubtitle && (
          <StyledOverflowSubtitle fontSize="s" color="gray_5">
            {overflowSubtitle}
          </StyledOverflowSubtitle>
        )}
      </StyledTitleContainer>
      <StyledRightContainer>{handleRenderRightElement}</StyledRightContainer>
    </StyledContainer>
  )
}
