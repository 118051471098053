import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {GiftShopTemplateShareItemProps} from './GiftShopTemplateShareModalProps'

interface StyledContainerProps {
  loading?: number
}

const StyledContainer = styled.a<StyledContainerProps>`
  display: flex;
  padding: ${convertUnit(16)} ${convertUnit(20)};
  column-gap: ${convertUnit(20)};
  cursor: ${({loading}) => (loading ? 'wait' : 'pointer')};
`

export default function GiftShopTemplateShareItem({
  stateLoading,
  icon,
  label,
  onClick,
}: GiftShopTemplateShareItemProps) {
  const {translate} = useTranslation()
  const loading = stateLoading[0]

  return (
    <StyledContainer
      loading={loading ? 1 : 0}
      onClick={!loading ? onClick : undefined}>
      <Icon size={24} type={icon} />
      <Paragraph fontSize="m" fontWeight="medium">
        {translate(label)}
      </Paragraph>
    </StyledContainer>
  )
}
