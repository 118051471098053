import React, {useCallback, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {
  HOST_REGISTRATION_DOCUMENT_TYPE,
  HOST_REGISTRATION_MAX_DOCUMENT_SIZE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {TreeUploadDocumentExtensionType} from 'types'
import {showSnackbar} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

const StyledFileContainer = styled.div`
  width: 100%;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_2};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(40)};
  }
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`

const StyledInput = styled.input`
  display: none;
`

const StyledUploadDocBtnContainer = styled.div`
  width: 100%;
  margin-top: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-bottom: ${convertUnit(40)};
  }
`

const StyledPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeRegistrationFormItemDocument({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {documentName} = watch()
  const uploadRef = useRef<HTMLInputElement>(null)

  const handleLoadFiles = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      const file = uploadRef.current.files[0]
      if (!HOST_REGISTRATION_DOCUMENT_TYPE.includes(file.type)) {
        showSnackbar(
          translate('tree:hostRegistrationInvalidDocumentTypeErrorMessage'),
        )
        return
      }
      if (file.size > HOST_REGISTRATION_MAX_DOCUMENT_SIZE) {
        showSnackbar(
          translate('tree:hostRegistrationInvalidDocumentSizeErrorMessage'),
        )
        return
      }
      const extension: TreeUploadDocumentExtensionType = file.type.includes(
        'pdf',
      )
        ? 'pdf'
        : 'docx'
      setValue('document', file)
      setValue('documentName', file.name)
      setValue('documentSize', file.size)
      setValue('documentType', extension)
      setValue('mime', file.type)
    }
  }, [setValue, translate])

  const handleRenderInputFile = useMemo(
    () => (
      <StyledInput
        ref={uploadRef}
        type="file"
        onChange={handleLoadFiles}
        accept={HOST_REGISTRATION_DOCUMENT_TYPE.join(',')}
      />
    ),
    [handleLoadFiles],
  )

  const handleRenderUploadDocButton = useMemo(
    () => (
      <StyledUploadDocBtnContainer>
        <Button
          label={translate('tree:hostRegistrationUploadDocumentButton')}
          color="primary_5"
          backgroundColor="white_2"
          disabled={disabled}
          onClick={() => uploadRef.current?.click()}
        />
      </StyledUploadDocBtnContainer>
    ),
    [disabled, translate],
  )

  return (
    <StyledPartContainer>
      <StyledFileContainer>
        {handleRenderInputFile}
        <Icon type="file" size={convertUnit(40)} color="primary_5" />
        <StyledColumnContainer>
          <Paragraph
            fontSize="s"
            fontWeight={
              documentName && documentName !== '' ? 'regular' : 'bold'
            }>
            {translate(
              documentName && documentName !== ''
                ? 'tree:hostRegistrationUploadDocumentDescription'
                : 'tree:hostRegistrationUploadDocumentButton',
            )}
          </Paragraph>
          <Paragraph
            fontSize="m"
            fontWeight="bold"
            color={documentName ? 'black' : 'gray_3'}>
            {documentName && documentName !== ''
              ? documentName
              : translate('tree:hostRegistrationUploadDocumentPlaceholder')}
          </Paragraph>
          {documentName === '' && (
            <Paragraph fontSize="s" color="gray_3">
              {translate('tree:hostRegistrationUploadDocumentSubtitle')}
            </Paragraph>
          )}
        </StyledColumnContainer>
      </StyledFileContainer>
      {handleRenderUploadDocButton}
    </StyledPartContainer>
  )
}
