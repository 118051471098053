import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSearchByCreatorContentItemProps} from './GiftShopSearchByCreatorContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  flex: 1;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const StyledImage = styled(Image)`
  border-radius: 50%;
  max-width: ${convertUnit(64)};
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
`

const StyledItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledParagraph = styled(Paragraph)`
  word-break: break-word;
`

export default function GiftShopSearchByCreatorContentItem({
  data,
  onClickItem,
}: GiftShopSearchByCreatorContentItemProps) {
  const {profile_url, biodata, username} = data

  const handleRenderImage = useMemo(
    () => <StyledImage src={profile_url} alt={username || ''} />,
    [profile_url, username],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledItemInfoContainer>
        <StyledParagraph fontSize="m" fontWeight="bold">
          {username}
        </StyledParagraph>
        <StyledParagraph fontSize="s" fontWeight="medium">
          {biodata}
        </StyledParagraph>
      </StyledItemInfoContainer>
    ),
    [biodata, username],
  )

  return (
    <StyledContainer onClick={onClickItem}>
      {handleRenderImage}
      {handleRenderInfo}
    </StyledContainer>
  )
}
