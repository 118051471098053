import React from 'react'
import styled from 'styled-components'
import {parseDate} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import TemplateSearchCreationItemProps from './TemplateSearchCreationItemProps'

const StyledImage = styled(Image)`
  width: ${convertUnit(48)};
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: ${convertUnit(8)}; ;
`

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  overflow: unset;
  align-items: center;
  margin: ${convertUnit(0)} ${convertUnit(20)};
  padding: ${convertUnit(8)} ${convertUnit(0)};
  border-bottom: solid 1px ${({theme}) => theme.white_4};
  &:hover {
    cursor: pointer;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${convertUnit(20)};
  width: 100%;
`

export default function TemplateSearchCreationItem({
  data,
  onClickItem,
}: TemplateSearchCreationItemProps) {
  return (
    <StyledContainer onClick={() => onClickItem(data)}>
      <StyledImage alt="thumbnail" src={data.url ? data.url.toString() : ''} />
      <StyledContentContainer>
        <Paragraph fontSize="s" fontWeight="medium" color="black">
          {data.title}
        </Paragraph>
        <Paragraph fontSize="xs" fontWeight="medium" color="gray_5">
          {data.original_at &&
            parseDate(data.original_at.toString(), 'DD MMM YYYY, hh:mm:ss')}
        </Paragraph>
      </StyledContentContainer>
    </StyledContainer>
  )
}
