import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTheme} from 'themes'
import {getFontSize} from 'utils'
import {useTranslation} from 'i18n'
import {MaintenanceData, parseShape} from 'types'
import {Button, Icon, Modal, Paragraph, ParsedText} from 'common/components'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`
const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(15)};
`
const StyledMaintenanceDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: fill-available;
  gap: ${convertUnit(8)};
  padding: ${convertUnit(8)} ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_3};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
  border-radius: ${convertUnit(8)};
  margin-top: ${convertUnit(8)};
`
const StyledMaintenanceDate = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledDate = styled(Paragraph)`
  text-align: left;
`

interface ModalMaintenanceItemProps {
  visible: boolean
  data: MaintenanceData
  onClick(): void
}

export default function ModalMaintenanceItem({
  visible,
  data,
  onClick,
}: ModalMaintenanceItemProps) {
  const theme = useTheme()
  const {description, end_date, start_date, title} = data
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const handleRenderText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<date>.*?<\/date>/,
        style: {
          color: theme.primary_5,
          fontFamily: 'roboto-bold',
          fontSize: getFontSize('m'),
        },
        renderText: (text) => text.replace(/<date>|<\/date>/g, ''),
      },
    ],
    [theme.primary_5],
  )
  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {title}
      </StyledTitle>
      <Paragraph fontSize="m">{description}</Paragraph>
      {start_date || end_date ? (
        <StyledMaintenanceDateContainer>
          <Icon type="clock" />
          <StyledMaintenanceDate>
            {start_date ? (
              <StyledDate fontSize="m">
                <ParsedText parse={handleRenderText}>
                  {translate('global:maintenanceStart', {
                    date: start_date,
                  })}
                </ParsedText>
              </StyledDate>
            ) : (
              <></>
            )}
            {end_date ? (
              <StyledDate fontSize="m">
                <ParsedText parse={handleRenderText}>
                  {translate('global:maintenanceEnd', {
                    date: end_date,
                  })}
                </ParsedText>
              </StyledDate>
            ) : (
              <></>
            )}
          </StyledMaintenanceDate>
        </StyledMaintenanceDateContainer>
      ) : (
        <></>
      )}
      <StyledButton
        onClick={() => {
          update('maintenanceModalStatus', {showMaintenanceModal: false})
          onClick()
        }}>
        {translate('global:ok')}
      </StyledButton>
    </StyledModal>
  )
}
