export const AVATAR_SIZE = 40
export const AVATAR_SIZE_CHAT = 48

export const ICON_SIZE = 20

export const BRAND_SIZE = 28
export const BRAND_LETTER_SPACE =5

export const FORKY_SIZE = 35
export const FORKY_SIZE_MINIMIZE = 30
export const FORKY_MESSAGE_SIZE = 40
export const FORKY_MESSAGE_SIZE_MINIMIZE = 35

export const BUTTON_PADDING_VERTICAL = 10
export const BUTTON_PADDING_HORIZONTAL = 16

export const BOUNDING_BOX_BORDER_RADIUS = 8
