import React, {useState, useCallback, useMemo} from 'react'
import {useParams} from 'react-router'
import styled from 'styled-components'
import {TranslationKey, useTranslation} from 'i18n'
import {GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import {TreeSettingMenus, TreeEditFotoTreeData} from 'types'
import {useDidMount, useLocation} from 'utils'
import {
  TreeEditTreeForm,
  TreeFototreeAliasForm,
  TreeFototreeBioForm,
} from '../containers'

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.white_3};
`

export default function TreeFototreeEditScreen() {
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const stateActiveContent = useState<TreeSettingMenus>('profile')
  const activeContent = stateActiveContent[0]
  const {data, treeId} = useLocation('tree_fototree_edit').state
  const stateTreeInfo = useState<TreeEditFotoTreeData>(data)
  const setTreeInfo = stateTreeInfo[1]

  const handleLoadData = useCallback(() => {
    requestData('tree_get_fototree_detail', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {tree_id: treeId},
      onRequestSuccess: ({data: {result}, status}) => {
        if (status === 200) {
          setTreeInfo({
            alias,
            bio: result.bio,
            id: treeId,
            name: result.name,
            url: result.url,
            video_intro_url: result.video_intro_url,
            event_end_date: result.event_end_date,
            event_start_date: result.event_start_date,
            category_name: result.category_name,
            tree_aliases: result.tree_aliases,
          })
        }
      },
    })
  }, [alias, setTreeInfo, treeId])

  const handleRenderTitle = useMemo(() => {
    const labels: Record<string, TranslationKey> = {
      aliases: 'tree:alias',
      bio: 'tree:bio',
      profile: 'tree:bio',
    }
    const label = labels[activeContent] ?? 'global:fototree'
    const action = activeContent === 'profile' ? 'global:edit' : 'global:change'

    return `${translate(action)} ${translate(label)}`
  }, [activeContent, translate])

  const handleRenderContent = useMemo(() => {
    switch (activeContent) {
      case 'profile':
        return (
          <TreeEditTreeForm
            stateTreeInfo={stateTreeInfo}
            stateActiveContent={stateActiveContent}
          />
        )
      case 'bio':
        return (
          <TreeFototreeBioForm
            stateTreeInfo={stateTreeInfo}
            stateActiveContent={stateActiveContent}
          />
        )
      case 'aliases':
        return (
          <TreeFototreeAliasForm
            stateTreeInfo={stateTreeInfo}
            stateActiveContent={stateActiveContent}
          />
        )
    }
  }, [activeContent, stateActiveContent, stateTreeInfo])

  useDidMount(handleLoadData)

  return (
    <GiftShopRootScreen
      headerTitle={handleRenderTitle}
      showBackIcon
      showBadgeIcon={false}
      onBackButtonClicked={() =>
        history.go(activeContent === 'profile' ? -1 : 0)
      }>
      <StyledContainer>{handleRenderContent}</StyledContainer>
    </GiftShopRootScreen>
  )
}
