import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTheme} from 'themes'
import {GiftShopContentData, LayoutView} from 'types'
import {ListLazy, View} from 'common/components'
import convertUnit from 'lib/unit'
import {useWindowMode} from 'windows'
import {GiftShopTemplateListContentProps} from './GiftShopTemplateListContentProps'
import GiftShopTemplateListContentItem from '../ListContentItem/GiftShopTemplateListContentItem'

const StyledContainer = styled(View)`
  display: flex;
  flex: 1;
  width: 100%;
  background: 'yellow';
`

export default function GiftShopTemplateListContent({
  stateData,
  refresh,
  loadData,
  listEmptyElement,
  onClickItem,
  isSelected,
  selectable,
  selected,
  toggleSelect,
}: GiftShopTemplateListContentProps) {
  const theme = useTheme()
  const windowMode = useWindowMode()
  const [layout, setLayout] = useState<LayoutView>()

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) =>
      layout ? (
        <GiftShopTemplateListContentItem
          item={item}
          onClickItem={onClickItem}
          isSelected={
            isSelected ? isSelected(item.content_id) : selected?.includes(item)
          }
          selectable={selectable}
          toggleSelect={toggleSelect}
        />
      ) : (
        <></>
      ),
    [isSelected, layout, onClickItem, selectable, selected, toggleSelect],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        stateData={stateData}
        refresh={refresh}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        listEmptyElement={listEmptyElement}
        loadData={loadData}
        cache="creation"
        style={{
          padding: windowMode !== 'mobile' ? `${convertUnit(32)} 0` : '0',
          backgroundColor: theme.white_2,
        }}
        contentContainerStyle={{
          width: `min(480px, 100%)`,
          margin: '0 auto',
          padding: `${convertUnit(16)}`,
          borderRadius: `${convertUnit(8)}`,
          backgroundColor: theme.white_1,
        }}
      />
    ),
    [
      stateData,
      refresh,
      handleKeyExtractor,
      handleRenderItem,
      listEmptyElement,
      loadData,
      theme.white_2,
      theme.white_1,
      windowMode,
    ],
  )

  return (
    <StyledContainer onLayout={setLayout}>{handleRenderList}</StyledContainer>
  )
}
