import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import Lottie from 'lottie-react'
import {isSafari} from 'react-device-detect'
import {
  AuthEasySignupRegister,
  AuthEasySignupUploadLinkParam,
  AuthEasySignupUploadStatus,
  AuthLoginResponse,
  GiftShopKYCRecordStatus,
  GiftShopKYCShootStatus,
  GiftShopKYCUploadLinkType,
  GiftShopKYCVerificationStatus,
  GiftShopKYCVideoType,
  GiftshopKYCUserUploadParam,
  ObjectState,
} from 'types'
import {
  blobToFile,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  urlToBlob,
  useHistory,
  useLocation,
} from 'utils'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {requestData} from 'services'
import {Modal, Paragraph} from 'common/components'
import {reduxUpdateDispatcher, useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import animationData from 'assets/lottie/activity_indicator.json'
import {
  GiftShopRobopetActivationSelfieOverlay,
  GiftShopRobopetActivationSelfieOverlayRecord,
  GiftShopRobopetActivationSelfieReview,
  GiftShopTemplatePermissionDenied,
  GiftShopTemplateUploadModal,
} from '../../../gift-shop'
import {AuthRegisterTermOfCondition} from '../../register'

interface ProgressProps {
  progress: GiftShopKYCVerificationStatus
  mediaError: boolean
}

const StyledContainer = styled.div<ProgressProps>`
  background-color: ${({theme, progress, mediaError}) =>
    progress === 'confirmation' || progress === 'landing' || mediaError
      ? theme.white_1
      : theme.black_2};
  display: flex;
  flex: 1;
  justify-content: center;
`
const StyledLottie = styled(Lottie)`
  height: ${convertUnit(40)};
  width: ${convertUnit(40)};
  margin-bottom: ${convertUnit(25)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
`

interface GiftShopRobopetActivationSelfieProps {
  stateVerifStatus: ObjectState<GiftShopKYCVerificationStatus>
}
export default function AuthEasySignupSelfie({
  stateVerifStatus,
}: GiftShopRobopetActivationSelfieProps) {
  const history = useHistory()
  const {
    date_of_birth = '',
    method,
    username = '',
    email,
    phone_code,
    phone_number,
    token,
  } = useLocation('auth_easy_signup_selfie').state
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {tag_id, tree_id} = useSelector('treeQrState')
  const showTermsState = useState(false)
  const setShowTerms = showTermsState[1]
  const webcamRef = useRef<any>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [recordedChunks, setRecordedChunks] = useState([])
  const frontRecordedChunks = useRef([])
  const leftRecordedChunks = useRef([])
  const [isRecording, setIsRecording] = useState(false)
  const [shootStatus, setShootStatus] = useState<GiftShopKYCShootStatus>(
    'tutorial',
  )
  const [verifStatus, setVerifStatus] = stateVerifStatus
  const [recordProgress, setRecordProgress] = useState<GiftShopKYCRecordStatus>(
    'front',
  )
  const [selfie, setSelfie] = useState('')
  const [modal, setModal] = useState(false)
  const [uploadProgress, setUploadProgress] = useState('')
  const uploadState = useRef<AuthEasySignupUploadStatus>('form')
  const videoConstraint = useMemo(
    () => ({
      aspectRatio: mode === 'mobile' ? 1.5 : 0.67,
      facingMode: 'user',
    }),
    [mode],
  )
  const uploadSuccess = useRef(0)
  const memberId = useRef('')
  const video = useRef<Record<GiftShopKYCVideoType, string>>({
    front: '',
    side: '',
  })
  const link = useRef<GiftshopKYCUserUploadParam>({
    selfie_link: '',
    video_front_link: '',
    video_side_link: '',
  })
  const {lang} = useSelector('languageState')
  const [mediaError, setMediaError] = useState(false)

  const handleDataAvailable = useCallback(({data}) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }
  }, [])

  const handleRecordProgress = useCallback(() => {
    setShootStatus('video')
    setTimeout(() => {
      setIsRecording(true)
      mediaRecorderRef.current.start()
      setTimeout(() => {
        mediaRecorderRef.current.state === 'recording' &&
          mediaRecorderRef.current.stop()
        setIsRecording(false)
      }, 1000)
    }, 1000)
  }, [])

  const handleRecord = useCallback(() => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? 'video/mp4' : 'video/webm',
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    )
    handleRecordProgress()
  }, [handleDataAvailable, handleRecordProgress])

  const handleProcessRecord = useCallback(
    (nextProgress: GiftShopKYCRecordStatus) => {
      if (mediaRecorderRef.current) {
        if (nextProgress === 'done') {
          setTimeout(() => {
            setRecordProgress('done')
            setVerifStatus('confirmation')
          }, 1000)
        } else {
          setTimeout(() => {
            setRecordProgress(nextProgress)
            setTimeout(() => {
              setIsRecording(true)
              mediaRecorderRef.current.state === 'inactive' &&
                mediaRecorderRef.current.start()
              setTimeout(() => {
                mediaRecorderRef.current.state === 'recording' &&
                  mediaRecorderRef.current.stop()
                setIsRecording(false)
              }, 1000)
            }, 1000)
          }, 1000)
        }
      }
    },
    [setVerifStatus],
  )

  const onAudioEnd = useCallback(() => {
    if (mediaRecorderRef.current) {
      setIsRecording(false)
      mediaRecorderRef.current.state === 'recording' &&
        mediaRecorderRef.current.stop()
      if (recordProgress === 'front') {
        handleProcessRecord('side')
      } else if (recordProgress === 'side') {
        handleProcessRecord('done')
      }
    }
  }, [handleProcessRecord, recordProgress])

  const handleTakeSelfie = useCallback(() => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot()
      setSelfie(image)
      handleRecord()
    }
  }, [handleRecord, translate])

  const handleRetake = useCallback(() => {
    setVerifStatus('shoot')
    setShootStatus('tutorial')
    setRecordProgress('front')
    setRecordedChunks([])
    frontRecordedChunks.current = []
    leftRecordedChunks.current = []
    setSelfie('')
  }, [setVerifStatus])

  const handleLoginSSO = useCallback(() => {
    if (token) {
      requestData(
        method === 'gosso'
          ? 'auth_easy_signup_login_sso_google'
          : 'auth_easy_signup_login_sso_apple',
        {
          data: {token},
          onRequestSuccess: ({data, status}) => {
            if (status === 200) {
              const userInfo = data.result as AuthLoginResponse
              reduxUpdateDispatcher('user', userInfo)
              if (tree_id && tag_id) {
                history.pushQuery({
                  path: 'tree_events_bib_masonry',
                  state: {
                    tree_url: '',
                    tree_name: '',
                    fromScan: true,
                  },
                  queryObject: {
                    tree_id,
                    tag_id,
                    search: '',
                  },
                })
              } else {
                history.push('giftshop_explore', {})
              }
            } else {
              showSnackbar(
                translate('global:messageError', {
                  context: `code-${status}`,
                }),
              )
            }
          },
        },
      )
    }
  }, [history, method, tag_id, token, translate, tree_id])

  const handleUploadData = useCallback(async () => {
    const payload: AuthEasySignupRegister = {
      date_of_birth,
      username,
      email,
      phone_code,
      phone_number,
      token,
      language: lang || 'id',
    }
    if (token) {
      const response = await requestData(
        method === 'gosso'
          ? 'auth_easy_signup_sso_google'
          : 'auth_easy_signup_sso_apple',
        {
          data: payload,
        },
      )
      if (typeof response !== 'string') {
        if (response.status === 201) {
          uploadState.current = 'kyc'
          const {result} = response.data
          memberId.current = result.member_id
        } else {
          setModal(false)
          showSnackbar(
            translate('global:messageError', {
              context: `code-${response.status}`,
            }),
          )
        }
      } else {
        setModal(false)
        showSnackbar(
          translate(
            !navigator.onLine
              ? 'global:messageError_no-connection'
              : 'giftShop:uploadFailed',
          ),
        )
      }
    } else {
      const response = await requestData(
        method === 'email'
          ? 'auth_easy_signup_email'
          : 'auth_easy_signup_phone',
        {
          data: payload,
        },
      )
      if (typeof response !== 'string') {
        if (response.status === 200) {
          uploadState.current = 'kyc'
          const {result} = response.data
          memberId.current = result.member_id
        } else {
          setModal(false)
          showSnackbar(
            translate('global:messageError', {
              context: `code-${response.status}`,
            }),
          )
        }
      } else {
        setModal(false)
        showSnackbar(
          translate(
            !navigator.onLine
              ? 'global:messageError_no-connection'
              : 'giftShop:uploadFailed',
          ),
        )
      }
    }
  }, [
    date_of_birth,
    email,
    lang,
    method,
    phone_code,
    phone_number,
    token,
    translate,
    username,
  ])

  const handleUploadLink = useCallback(
    async (
      type: GiftShopKYCUploadLinkType,
      data: AuthEasySignupUploadLinkParam,
      file: File,
      extension: string,
    ) => {
      const response = await requestData('auth_easy_signup_kyc_links', {
        data: {...data, type, member_id: memberId.current},
      })
      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        if (type === 'front') {
          link.current = {
            ...link.current,
            video_front_link: result.video_front_link,
          }
        } else if (type === 'side') {
          link.current = {
            ...link.current,
            video_side_link: result.video_side_link,
          }
        } else if (type === 'selfie') {
          link.current = {...link.current, selfie_link: result.selfie_link}
        }
        const isSuccess = await handleUploadSingleFileToWasabi(
          file,
          type === 'selfie'
            ? link.current.selfie_link
            : type === 'front'
            ? link.current.video_front_link
            : link.current.video_side_link,
          extension,
          {
            'Content-Type': extension,
          },
        )
        if (isSuccess) {
          uploadSuccess.current += 1
          if (uploadSuccess.current === 3) uploadState.current = 'link'
          setUploadProgress(
            translate('giftShop:uploadPublishLoadingDescription', {
              success: uploadSuccess.current,
              total: 3,
            }),
          )
        }
        return isSuccess
      }
      return false
    },
    [translate],
  )

  const handleUpload = useCallback(async () => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    setUploadProgress(translate('giftShop:kycUploading_preparing'))
    setModal(true)
    frontRecordedChunks.current = [recordedChunks[0]]
    leftRecordedChunks.current = [recordedChunks[1]]

    const blobLeft = new Blob(leftRecordedChunks.current, {
      type: 'video/mp4',
    })
    const urlLeft = URL.createObjectURL(blobLeft)
    const blobFront = new Blob(frontRecordedChunks.current, {
      type: 'video/mp4',
    })
    const urlFront = URL.createObjectURL(blobFront)

    video.current.front = urlFront
    video.current.side = urlLeft

    const fileFront = new File([blobFront], 'video_front', {
      type: blobFront.type,
      lastModified: new Date().getTime(),
    })
    const fileLeft = new File([blobLeft], 'video_left', {
      type: blobLeft.type,
      lastModified: new Date().getTime(),
    })
    const selfieBlob = await urlToBlob(selfie)
    const fileSelfie = blobToFile(
      selfieBlob,
      'kyc-selfie',
      new Date().getTime(),
    )

    const selfieExt = fileSelfie.type
    const videoExt = fileFront.type
    const uploadData: AuthEasySignupUploadLinkParam = {
      selfie: {
        size: fileSelfie.size,
        extension: fileSelfie.type.split('/')[1],
      },
      video_front: {
        size: fileFront.size,
        extension: fileFront.type.split('/')[1],
      },
      video_side: {
        size: fileLeft.size,
        extension: fileLeft.type.split('/')[1],
      },
    }

    uploadSuccess.current = 0

    if (uploadState.current === 'form') await handleUploadData()

    for (let i = 0; i < Object.keys(uploadData).length; i += 1) {
      const key = Object.keys(uploadData)[i]
      const type = key.replace('video_', '') as GiftShopKYCUploadLinkType

      if (uploadState.current === 'kyc') {
        if (
          // eslint-disable-next-line no-await-in-loop
          !(await handleUploadLink(
            type,
            uploadData,
            type === 'front'
              ? fileFront
              : type === 'side'
              ? fileLeft
              : fileSelfie,
            type === 'selfie' ? selfieExt : videoExt,
          ))
        ) {
          setModal(false)
          showSnackbar(
            translate(
              !navigator.onLine
                ? 'global:messageError_no-connection'
                : 'giftShop:uploadFailed',
            ),
          )
          break
        }
      }
    }
    if (uploadState.current === 'link') {
      await requestData('auth_easy_signup_kyc_verif', {
        data: {
          selfie_link: link.current.selfie_link,
          video_front_link: link.current.video_front_link,
          video_side_link: link.current.video_side_link,
          tag_id: tag_id ?? '',
          tree_id: tree_id ?? '',
        },
        onRequestSuccess: (value) => {
          setModal(false)
          if (value.status === 200) {
            update('easySignupState', {
              hasRegistered: true,
              showChangeCredentialButton: true,
            })
            if (token) {
              handleLoginSSO()
            } else {
              update(
                'lastUserState',
                method === 'email'
                  ? {emailTime: new Date().getTime()}
                  : {phoneOTPTime: new Date().getTime()},
              )
              history.replace('auth_easy_signup_verification', {
                method: method === 'phone' ? 'whatsapp' : method,
                email,
                phone_code,
                phone_number,
                shouldCensor: false,
                memberId: memberId.current,
                tagId: tag_id ?? '',
                treeId: tree_id ?? '',
              })
            }
          } else {
            showSnackbar(
              translate(
                !navigator.onLine
                  ? 'global:messageError_no-connection'
                  : 'giftShop:uploadFailed',
              ),
            )
          }
        },
      })
    }
  }, [
    email,
    handleLoginSSO,
    handleUploadData,
    handleUploadLink,
    history,
    method,
    phone_code,
    phone_number,
    recordedChunks,
    selfie,
    tag_id,
    token,
    translate,
    tree_id,
    update,
  ])

  const handleRenderOverlay = useMemo(() => {
    switch (shootStatus) {
      case 'tutorial':
        return (
          <GiftShopRobopetActivationSelfieOverlay onClick={handleTakeSelfie} />
        )
      case 'video':
        return (
          <GiftShopRobopetActivationSelfieOverlayRecord
            progress={recordProgress}
            isRecording={isRecording}
            onAudioEnd={onAudioEnd}
            isEasySignup
          />
        )
      default:
        return (
          <GiftShopRobopetActivationSelfieOverlay onClick={handleTakeSelfie} />
        )
    }
  }, [handleTakeSelfie, isRecording, onAudioEnd, recordProgress, shootStatus])

  const handleRenderNeedPermission = useMemo(
    () => <GiftShopTemplatePermissionDenied type="camera" />,
    [],
  )

  const handleRenderWebcam = useMemo(
    () => (
      <>
        <Webcam
          style={{height: '100%', position: 'absolute'}}
          mirrored
          onUserMediaError={() => setMediaError(true)}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraint}
        />
        {handleRenderOverlay}
      </>
    ),
    [handleRenderOverlay, videoConstraint],
  )

  const handleRenderVerificationPage = useMemo(() => {
    switch (verifStatus) {
      case 'landing':
        return <></>
      case 'shoot':
        return mediaError ? handleRenderNeedPermission : handleRenderWebcam
      case 'confirmation':
        return (
          selfie && (
            <GiftShopRobopetActivationSelfieReview
              selfie={selfie}
              onRetry={handleRetake}
              onAccept={() => (token ? setShowTerms(true) : handleUpload())}
            />
          )
        )
      default:
        return <></>
    }
  }, [
    handleRenderNeedPermission,
    handleRenderWebcam,
    handleRetake,
    handleUpload,
    mediaError,
    selfie,
    setShowTerms,
    token,
    verifStatus,
  ])

  const renderModalUploading = useMemo(
    () => (
      <StyledModal visible={modal}>
        {uploadProgress === translate('giftShop:kycUploading_preparing') ? (
          <>
            <StyledLottie animationData={animationData} />
            <Paragraph fontSize="xl" fontWeight="bold">
              {uploadProgress}
            </Paragraph>
          </>
        ) : (
          <GiftShopTemplateUploadModal
            title={translate('giftShop:uploadPublishLoadingTitle')}
            description={uploadProgress}
            progressColor="primary_5"
            success={uploadSuccess.current}
            total={3}
          />
        )}
        <StyledParagraph fontSize="m" color="gray_3">
          {translate('auth:registerEasySignupUploadWarning')}
        </StyledParagraph>
      </StyledModal>
    ),
    [modal, translate, uploadProgress],
  )

  const renderModalToS = useMemo(
    () => (
      <AuthRegisterTermOfCondition
        showModalState={showTermsState}
        handleAgree={() => {
          setShowTerms(false)
          handleUpload()
        }}
        showCheckbox
      />
    ),
    [handleUpload, setShowTerms, showTermsState],
  )

  return (
    <StyledContainer progress={verifStatus} mediaError={mediaError}>
      {renderModalToS}
      {renderModalUploading}
      {handleRenderVerificationPage}
    </StyledContainer>
  )
}
