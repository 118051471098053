import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, Icon, Input, Modal, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AuthVerifStatusType} from 'types'
import {requestData} from 'services'
import {
  AUTH_USERNAME_MAX_LENGTH,
  AUTH_USERNAME_MIN_LENGTH,
  REGEX_NO_SPACE,
  REGEX_ONE_LETTER,
  REGEX_START_END_ALPHANUMERIC,
  REGEX_START_WITH_CHAR,
  REGEX_USERNAME,
} from 'consts'
import {useTheme} from 'themes'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`
const StyledParagraph = styled(Paragraph)`
  text-align: left;
`
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(5)};
`
const StyledBottomContainer = styled.div`
  margin-top: ${convertUnit(15)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(15)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(15)};
`

export default function GiftShopTemplateModalNickname() {
  const {translate} = useTranslation()
  const theme = useTheme()
  const {nickname, username} = useSelector('user') || {}
  const generatedNicknameList = useRef<string[]>([])
  const [visible, setVisible] = useState(!nickname)
  const [chosenNickname, setChosenNickname] = useState('')
  const [nicknameStatus, setNicknameStatus] = useState<AuthVerifStatusType>('pending')
  const [error, setError] = useState('')
  const {update} = useDispatch()

  const handleSubmitNickname = useCallback(() => {
    requestData('giftshop_post_nickname', {
      data: {nickname: chosenNickname.toLowerCase()},
      onRequestSuccess: (value) => {
        if (value.status === 200) {
          update('user', {nickname: chosenNickname.toLowerCase()})
          setNicknameStatus('success')
          setVisible(false)
        } else if (value.status === 409) {
          if (value.data.detail) {
            const errorDetail = value.data.detail
            if (errorDetail.nickname === 'your nickname is already taken') {
              setError(translate('auth:validationNicknameHasBeenTaken'))
            } else if (errorDetail.nickname === 'your nickname cannot be the same as your username') {
              setError(translate('auth:validationNicknameMatchUsername'))
            }
          }
        } else {
          setNicknameStatus('failed')
          setVisible(false)
        }
      }
    })
  }, [chosenNickname, translate, update])

  const handleNicknameValidation = useCallback((text: string) => {
    if (text.length === 0) {
      setError(translate('auth:validationNicknameIsEmpty'))
      return
    }
    if (text.length < AUTH_USERNAME_MIN_LENGTH) {
      setError(translate('auth:validationNicknameMinLength', {count: AUTH_USERNAME_MIN_LENGTH}))
      return
    }
    if (!text.charAt(0).match(REGEX_START_WITH_CHAR)) {
      setError(translate('auth:validationNicknameStartCharacter'))
      return
    }
    if (!text.match(REGEX_NO_SPACE)) {
      setError(translate('auth:validationNicknameNoSpace'))
      return
    }
    if (!text.match(REGEX_ONE_LETTER)) {
      setError(translate('auth:validationNicknameOneLetterRequired'))
      return
    }
    if (!text.match(REGEX_START_END_ALPHANUMERIC)) {
      setError(translate('auth:validationNicknameStartEndAlphanumeric'))
      return
    }
    if (username === text) {
      setError(translate('auth:validationNicknameMatchUsername'))
      return
    }
    if (!text.match(REGEX_USERNAME)) {
      setError(translate('auth:nicknameAllowedCharacters'))
      return
    }
    if (text.split('-').length >= 3) {
      setError(translate('auth:validationNicknameSingleDash'))
      return
    }
    if (text.split('.').length >= 3) {
      setError(translate('auth:validationNicknameSinglePeriod'))
      return
    }
    if (text.split('_').length >= 3) {
      setError(translate('auth:validationNicknameSingleUnderscore'))
      return
    }
    setError('')
  }, [translate, username])

  const handleGetNickname = useCallback(async () => {
    generatedNicknameList.current.length > 0 && setChosenNickname(generatedNicknameList.current.pop()!)
    const currList = generatedNicknameList.current
    if (currList.length <= 5) {
      await requestData('giftshop_get_nickname', {
        onRequestSuccess: (value) => {
          const {status} = value
          if (status === 200) {
            const nicknames = value.data.result
            generatedNicknameList.current = currList.concat(nicknames)
            currList.length === 0 && setChosenNickname(generatedNicknameList.current.pop()!)
          } else if (status === 401) {
            setTimeout(() => {
              handleGetNickname()
            }, 1000)
          }
        }
      })
    }
    setError('')
  }, [setChosenNickname])

  const renderModalStatus = useMemo(() => (
    <StyledModal visible={nicknameStatus !== 'pending'}>
      <Icon
        type={nicknameStatus === 'success' ? 'check' : 'close'}
        size={48}
        color={nicknameStatus === 'success' ? 'success_5' : 'gray_3'} />
      <Paragraph fontSize="xl" fontWeight="bold" style={{marginBottom: convertUnit(10)}}>
        {translate(nicknameStatus === 'success' ? 'global:success' : 'global:failed')}
      </Paragraph>
      <Paragraph fontSize="l" style={{marginBottom: convertUnit(10)}}>
        {translate(nicknameStatus === 'success' ? 'auth:nicknameCreated' : 'global:createnicknameFail')}
      </Paragraph>
      <StyledButton
        label={translate(nicknameStatus === 'success' ? 'global:ok' : 'global:tryAgain')}
        onClick={() => {
          nicknameStatus === 'failed' && setVisible(true)
          setNicknameStatus('pending')
        }} />
    </StyledModal>
  ), [nicknameStatus, translate])

  const renderNicknameModal = useMemo(() => (
    <StyledModal visible={visible}>
      <Paragraph fontSize="l" fontWeight="bold" style={{marginBottom: convertUnit(10)}}>
        {translate('global:createnickname')}
      </Paragraph>
      <Paragraph fontSize="m" style={{marginBottom: convertUnit(25)}}>
        {translate('global:createnicknameDesc')}
      </Paragraph>
      <StyledInputContainer>
        <StyledParagraph fontSize="m" fontWeight="bold">{translate('global:nickname')}</StyledParagraph>
        <StyledParagraph fontSize="m">{translate('auth:nicknameSublabel')}</StyledParagraph>
        <Input
          value={chosenNickname}
          maxLength={AUTH_USERNAME_MAX_LENGTH}
          onChangeText={(text) => {
            setChosenNickname(text)
            handleNicknameValidation(text)
          }}
          inputContainerStyle={{borderColor: error ? theme.danger_5 : undefined}}
          rightIcon={<Icon type="refresh" />}
          onRightIconClick={handleGetNickname}
        />
        {error && <StyledParagraph fontWeight="medium" color="danger_5">{error}</StyledParagraph>}
      </StyledInputContainer>
      <StyledBottomContainer>
        <Paragraph fontSize="m" color="gray_3">{translate('auth:nicknameLabelDescription')}</Paragraph>
        <Button
          label={translate('global:createnickname')}
          disabled={!!error || chosenNickname === ''}
          onClick={handleSubmitNickname}
          />
      </StyledBottomContainer>
    </StyledModal>
  ), [chosenNickname, error, handleGetNickname, handleNicknameValidation, handleSubmitNickname, theme.danger_5, translate, visible])

  useEffect(() => {visible && handleGetNickname()}, [handleGetNickname, visible])
  return (
    <>
      {renderNicknameModal}
      {renderModalStatus}
    </>)
}
