import {useMemo, useCallback} from 'react'
import {requestData} from 'services'
import {TreePatchTreeParams} from 'types'
import {handleTreeReadFile} from './TreeFileHelper'
import {handleUploadSingleFileToWasabi} from '../../files'
import {useDidMount} from '../../objects'

export function usePatchTree({
  imageForm,
  videoForm,
  eventDateForm,
  stateTreeInfo,
  stateLoading,
}: TreePatchTreeParams) {
  const {
    setValue: setVidValue,
    register: registerVid,
    getValues: getVidValue,
  } = videoForm

  const {watch: watchEventDate} = eventDateForm
  const {eventEndDate, eventStartDate} = watchEventDate()

  const {getValues: getImgValue} = imageForm

  const handleLoadVideo = useCallback(
    async (inputFile: File) => {
      if (inputFile) {
        const metadata = await handleTreeReadFile(inputFile)
        if (metadata) {
          setVidValue('extension', metadata?.extension)
          setVidValue('height', metadata?.height)
          setVidValue('width', metadata?.width)
          setVidValue('size', metadata?.size)
          setVidValue('file', metadata?.file)
          setVidValue('mime', metadata?.type)
          return true
        }
        return false
      }
      return false
    },
    [setVidValue],
  )

  const getVideoLink = useCallback(async () => {
    const {height, extension, size, width} = getVidValue()
    const response = await requestData(
      'tree_post_plant_fototree_profile_link',
      {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          is_intro: true,
          video_intro: {
            height,
            extension,
            size,
            width,
          },
        },
      },
    )
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.video_intro_url
      : null
  }, [getVidValue])

  const getProfileLink = useCallback(async () => {
    const imgValues = getImgValue()
    const {imgExtension, imgHeight, imgSize, imgWidth} = imgValues
    const response = await requestData(
      'tree_post_plant_fototree_profile_link',
      {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          profile: {
            extension: imgExtension,
            height: imgHeight,
            size: imgSize,
            width: imgWidth,
          },
        },
      },
    )
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.profile_url
      : null
  }, [getImgValue])

  const handlePublishToWasabi = useCallback(
    async (
      link: string,
      width: number,
      height: number,
      file?: File,
      mime?: string,
    ) => {
      if (file && mime) {
        try {
          return handleUploadSingleFileToWasabi(file, link, mime, {
            'Content-Type': mime,
            'x-amz-meta-imagewidth': width.toString(),
            'x-amz-meta-imageheight': height.toString(),
          })
        } catch (error) {
          return false
        }
      }
      return false
    },
    [],
  )

  const handlePublishVideoToWasabi = useCallback(async () => {
    const videoLink = await getVideoLink()
    const {width, height, file, mime} = getVidValue()

    if (videoLink) {
      const videoWasabiStatus = await handlePublishToWasabi(
        videoLink,
        width,
        height,
        file,
        mime,
      )
      if (videoWasabiStatus) {
        return videoLink
      }
    }
    stateLoading && stateLoading[1](false)
    return undefined
  }, [getVidValue, getVideoLink, handlePublishToWasabi, stateLoading])

  const handlePublishProfile = useCallback(async () => {
    const imgLink = await getProfileLink()
    const imgValues = getImgValue()
    const {imgFile, imgHeight, imgMime, imgWidth} = imgValues
    if (imgLink) {
      const imgWasabiStatus = await handlePublishToWasabi(
        imgLink,
        imgWidth,
        imgHeight,
        imgFile,
        imgMime,
      )
      if (imgWasabiStatus) {
        return imgLink
      }
    }

    stateLoading && stateLoading[1](false)
    return undefined
  }, [getImgValue, getProfileLink, handlePublishToWasabi, stateLoading])

  const handleSubmitImage = useCallback(async () => {
    stateLoading && stateLoading[1](true)
    const {imgSize} = getImgValue()
    const imgLink = await handlePublishProfile()

    if (imgLink) {
      const response = await requestData('tree_patch_fototree', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          profile_size: imgSize,
          profile_url: imgLink,
          tree_id: stateTreeInfo[0].id,
        },
      })

      stateLoading && stateLoading[1](false)

      return typeof response !== 'string'
        ? {status: response.status, data: response.data}
        : {status: undefined, data: undefined}
    }

    return {status: undefined, detail: undefined}
  }, [getImgValue, handlePublishProfile, stateLoading, stateTreeInfo])

  const handleSubmitVideo = useCallback(
    async (file: File) => {
      const videoLoaded = await handleLoadVideo(file)

      const {height, size, width} = getVidValue()
      if (videoLoaded) {
        stateLoading && stateLoading[1](true)
        const vidLink = await handlePublishVideoToWasabi()

        if (vidLink) {
          const response = await requestData('tree_patch_fototree', {
            useDefaultMessage: true,
            actionType: 'execute',
            data: {
              tree_id: stateTreeInfo[0].id,
              video_intro: {
                height,
                meta: undefined,
                size,
                url: vidLink,
                width,
              },
            },
          })
          stateLoading && stateLoading[1](false)

          return typeof response !== 'string'
            ? {status: response.status, data: response.data}
            : {status: undefined, data: undefined}
        }
        stateLoading && stateLoading[1](false)
      }
      return {status: undefined, detail: undefined}
    },
    [
      getVidValue,
      handleLoadVideo,
      handlePublishVideoToWasabi,
      stateLoading,
      stateTreeInfo,
    ],
  )

  const handleDeleteVideo = useCallback(async () => {
    stateLoading && stateLoading[1](true)

    const response = await requestData('tree_patch_fototree', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        tree_id: stateTreeInfo[0].id,
        video_intro: {},
      },
    })
    stateLoading && stateLoading[1](false)

    return typeof response !== 'string'
      ? {status: response.status, data: response.data}
      : {status: undefined, data: undefined}
  }, [stateLoading, stateTreeInfo])

  const handleUpdateEventDate = useCallback(async () => {
    stateLoading && stateLoading[1](true)

    const [sYear, sMonth, sDay] = eventStartDate.split('-') ?? []
    const [eYear, eMonth, eDay] = eventEndDate.split('-') ?? []

    const startDate = `${sDay}/${sMonth}/${sYear}`
    const endDate = `${eDay}/${eMonth}/${eYear}`

    const response = await requestData('tree_patch_fototree', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        tree_id: stateTreeInfo[0].id,
        event_start_date: startDate,
        event_end_date: endDate,
      },
    })
    stateLoading && stateLoading[1](false)

    return typeof response !== 'string'
      ? {status: response.status}
      : {status: undefined}
  }, [eventEndDate, eventStartDate, stateLoading, stateTreeInfo])

  useDidMount(() => {
    registerVid('extension')
    registerVid('height')
    registerVid('width')
    registerVid('size')
    registerVid('file')
    registerVid('mime')
  })

  return useMemo(
    () => ({
      handleDeleteVideo,
      handleSubmitImage,
      handleSubmitVideo,
      handleUpdateEventDate,
    }),
    [
      handleDeleteVideo,
      handleSubmitImage,
      handleSubmitVideo,
      handleUpdateEventDate,
    ],
  )
}
