import React, {Fragment, useCallback, useMemo} from 'react'
import {
  FirebaseMessageRoomItem,
  FirebaseMessageRoomItemPayload,
  FirebaseMessageRoomType,
  MessageContent,
  ObjectState,
} from 'types'
import {useDidUpdate, useFirebaseMessageRoom} from 'utils'
import {List} from 'common/components'
import GiftShopMessageRoomContentItem from './GiftShopMessageRoomContentItem'
import GiftShopMessageRoomContentSeparator from './GiftShopMessageRoomContentSeparator'

export interface GiftShopMessageRoomContentProps {
  roomId: string
  roomType: FirebaseMessageRoomType
  stateTempMessages: ObjectState<ReadonlyArray<FirebaseMessageRoomItem<'chat'>>>
  roomLastDelete?: string
}

export default function GiftShopMessageRoomContent({
  roomId,
  roomType,
  stateTempMessages,
  roomLastDelete,
}: GiftShopMessageRoomContentProps) {
  const tempMessages = stateTempMessages[0]
  const {messages: firebaseMessages} = useFirebaseMessageRoom({
    roomId,
    roomType,
  })

  const handleKeyExtractor = useCallback(
    (item: {
      key: string
      messages: FirebaseMessageRoomItem<keyof FirebaseMessageRoomItemPayload>[]
    }) => item.key,
    [],
  )

  const messages = useMemo(() => {
    const mergedMessages = [
      ...tempMessages.filter(
        (tempMessage) =>
          !firebaseMessages.find((message) => message.id === tempMessage.id),
      ),
      ...firebaseMessages,
    ]

    return roomLastDelete
      ? mergedMessages
          .filter((message) => message.created_at > roomLastDelete)
          .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
      : mergedMessages.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
  }, [firebaseMessages, roomLastDelete, tempMessages])

  const sections = useMemo(() => {
    const map: Record<string, FirebaseMessageRoomItem[]> = {}
    for (const message of messages) {
      const {created_at} = message
      const time = new Date(created_at)

      time.setHours(0)
      time.setMinutes(0)
      time.setSeconds(0)

      const key = time.toString()

      if (!map[key]) map[key] = []
      map[key].push(message)
    }
    return Object.entries(map)
      .map(([key, sectionMessages]) => ({
        key,
        messages: sectionMessages,
      }))
      .sort((a, b) => (new Date(a.key) > new Date(b.key) ? 1 : -1))
  }, [messages])

  const handleRenderList = useCallback(
    ({key, messages: sectionMessages}: MessageContent) => (
      <Fragment key={key}>
        <GiftShopMessageRoomContentSeparator
          key={`section: ${key}`}
          time={key}
        />
        {sectionMessages.map((message) => (
          <GiftShopMessageRoomContentItem
            key={`message: ${message.id}`}
            data={message}
          />
        ))}
      </Fragment>
    ),
    [],
  )

  const handleRenderListFooter = useMemo(() => <div id="footer" />, [])

  useDidUpdate(() => {
    const elem = document.getElementById('footer')
    elem?.scrollIntoView({block: 'end'})
  })

  return (
    <List
      data={sections}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderList}
      contentContainerStyle={{flexGrow: 1}}
      listFooterElement={handleRenderListFooter}
    />
  )
}
