import React, {useCallback} from 'react'
import {requestData} from 'services'
import {ListLazy} from 'common/components'
import {GiftShopStorageHistoryResponse} from 'types'
import {GiftShopStorageHistoryContentItem} from '../StorageHistoryContentItem'

export default function GiftShopStorageHistoryContent() {
  const loadStorageData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('giftshop_get_storage_history', {
      params: {page, limit},
    })
    return typeof response !== 'string' && response?.status === 200
      ? response.data.result
      : null
  }, [])

  const handleKeyExtractor = useCallback(
    (item: GiftShopStorageHistoryResponse) => item.id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopStorageHistoryResponse) => (
      <GiftShopStorageHistoryContentItem data={item} />
    ),
    [],
  )

  return (
    <ListLazy
      loadData={loadStorageData}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderItem}
      scrollbar={false}
    />
  )
}
