import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {requestData} from 'services'
import {useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemReminderResendProps {
  payload: FirebaseMessageRoomItemPayload['reminder_resend_content']
}

const StyledExtraText = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function GiftShopMessageRoomContentItemReminderResend({
  payload: {contents, collector_nickname, username},
}: GiftShopMessageRoomContentItemReminderResendProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const shouldRenderButton = useMemo(
    () => data.some(({purchase_status}) => purchase_status === 'resend'),
    [data],
  )

  const handleLoadData = useCallback(
    () =>
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <Paragraph fontSize="m">
        {translate('messages:reminderResendContent', {
          username,
          collector_nickname,
        })}
      </Paragraph>
    ),
    [translate, username, collector_nickname],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        showFooter
        data={data}
        onClick={(item) =>
          history.push(
            'giftshop_profile_creation_detail',
            {
              data,
              selectedItemId: item.content_id,
              limitContent: true,
            },
            item.creator_name,
          )
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderButton = useMemo(
    () => (
      <>
        <StyledExtraText fontSize="m">
          {translate('messages:reminderResendContentExtra')}
        </StyledExtraText>
        {shouldRenderButton && (
          <StyledButton
            label={translate('messages:buttonResendHires')}
            onClick={() =>
              history.push(
                'giftshop_profile',
                {
                  self: true,
                  creationType: 'resend',
                },
                username,
              )
            }
          />
        )}
      </>
    ),
    [history, shouldRenderButton, translate, username],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderButton}
    </>
  )
}
