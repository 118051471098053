import React, {CSSProperties, useCallback} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

export interface GiftShopIncompleteKYCModalProps {
  visible: boolean
  onConfirm: () => void
  toggleModal: () => void
}

const StyledModal = styled.div<CSSProperties>`
  width: ${({width = convertUnit(325)}) => width};
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 5;
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(10)};
  width: 100%;
  margin-top: ${convertUnit(25)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(12)};
`

const StyledText = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(120)};
`

export default function GiftShopIncompleteKYCModal({
  onConfirm,
  toggleModal,
  visible,
}: GiftShopIncompleteKYCModalProps) {
  const {translate} = useTranslation()

  const roboImg = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  const handleConfirm = useCallback(() => {
    toggleModal()
    onConfirm()
  }, [onConfirm, toggleModal])

  return (
    <Modal visible={visible}>
      <StyledModal>
        <StyledTitle fontWeight="bold" fontSize="xl">
          {translate('global:sorry')}
        </StyledTitle>
        <StyledText fontSize="l">
          {translate('giftShop:roboyuIncompleteSubtitle')}
        </StyledText>
        <StyledImageContainer>
          <StyledImage src={roboImg} alt={roboImg} />
        </StyledImageContainer>
        <StyledText fontSize="m" color="gray_5">
          {translate('giftShop:roboyuIncompleteDesc')}
        </StyledText>
        <StyledModalButtonContainer>
          <StyledButton
            label={translate('global:tryAgain')}
            onClick={handleConfirm}
          />
          <StyledButton
            label={translate('global:later')}
            backgroundColor="white_3"
            color="primary_5"
            onClick={toggleModal}
          />
        </StyledModalButtonContainer>
      </StyledModal>
    </Modal>
  )
}
