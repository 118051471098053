import {
  TREE_UPLOAD_VIDEO_TYPE,
  FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  TREE_MIN_VIDEO_INTRO_DURATION,
  TREE_MAX_VIDEO_INTRO_DURATION,
} from 'consts'
import {translate} from 'i18n'
import {
  GiftShopUploadImageParams,
  TreeVideoMetadata,
  VideoExtensionType,
} from 'types'
import {getAndSupplyFileMime, getVideoMetadata} from 'utils'
import {showSnackbar} from '../../components'

export async function convertVideo(
  url: string | ArrayBuffer,
  toTypeMime: string,
  oldFileName: string,
) {
  return fetch(typeof url !== 'string' ? url.toString() : url)
    .then((res) => res.blob())
    .then((blob) => {
      const finalName = oldFileName.replace(
        /\.(.)+/g,
        `.${FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE.split('/')[1]}`,
      )
      return new File([blob], finalName, {
        type: toTypeMime,
      })
    })
    .catch()
}

export async function handleTreeLoadFile({
  event: {target},
  file,
}: GiftShopUploadImageParams): Promise<TreeVideoMetadata | null> {
  return new Promise((resolve) => {
    let result = target?.result
    const reader = new FileReader()
    const vid = document.createElement('video')

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = async ({target: targetFile}) => {
      const videoBuffer = targetFile?.result
      const detectedType = getAndSupplyFileMime(TREE_UPLOAD_VIDEO_TYPE, file)

      if (
        FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE.includes(detectedType) &&
        result
      ) {
        file = await convertVideo(
          result,
          FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
          file.name,
        )
        result = URL.createObjectURL(file)
        vid.src = result
      } else if (result) vid.src = result?.toString()

      const {size, type} = file

      if (!(typeof result === 'string' && videoBuffer instanceof ArrayBuffer)) {
        resolve(null)
        return
      }

      const metadata = await getVideoMetadata(vid)

      if (metadata) {
        const {width, height, duration} = metadata

        if (
          duration > TREE_MAX_VIDEO_INTRO_DURATION ||
          duration < TREE_MIN_VIDEO_INTRO_DURATION
        ) {
          showSnackbar(translate('tree:plantFototreeVideoDuration'))
          resolve(null)
          return
        }
        if (width > FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH) {
          showSnackbar(translate('forkygram:uploadMaxVideoWidthMessage'))
          resolve(null)

          return
        }

        const video: TreeVideoMetadata = {
          file,
          src: result,
          width,
          height,
          extension:
            (file.type.split('/').length > 1 &&
              (file.type.split('/')[1] as VideoExtensionType)) ||
            'mp4',
          size,
          type,
        }

        resolve(video)
      }

      resolve(null)
    }

    reader.readAsArrayBuffer(file)
  })
}

export function handleTreeReadFile(
  file: File,
  compress?: boolean,
  checkResolution?: boolean,
): Promise<TreeVideoMetadata | null> {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = (event) =>
      resolve(
        handleTreeLoadFile({
          event,
          file,
          compress,
          checkResolution,
        }),
      )

    reader.readAsDataURL(file)
  })
}
