import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledParagraph = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalSupergigaContent() {
  const {translate} = useTranslation()

  const handleMaintenance = useMemo(
    () => (
      <StyledTextContainer>
        <StyledParagraph fontSize="xxl" fontWeight="bold" color="primary_5">
          {translate('policy:supergiga')}
        </StyledParagraph>
        <StyledParagraph>
          <StyledLink href="https://www.supergiga.com" target="_blank">
            www.supergiga.com
          </StyledLink>
          <br />
          <br />
        </StyledParagraph>
      </StyledTextContainer>
    ),
    [translate],
  )

  return <>{handleMaintenance}</>
}
