import {lazy} from 'react'

const TreeRegistrationFormScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostRegistration' */ './TreeRegistrationFormScreen'
    ),
)

const TreeRegistrationAddHostInformationScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostInformation' */ './TreeRegistrationAddHostInformationScreen'
    ),
)

export {TreeRegistrationFormScreen, TreeRegistrationAddHostInformationScreen}
