import React, {useMemo} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import animationData from '../../../assets/lottie/activity_indicator.json'
import {ListPaginationBase} from '../ListPaginationBase'
import {ListPaginationBasicProps} from './ListPaginationProps'

interface StyledLoadingContainerProps {
  width?: number
  height?: number
}

const StyledLoadingContainer = styled.div<StyledLoadingContainerProps>`
  ${({height, width}) => ({
    width: width ?? '100%',
    height: height ?? undefined,
  })}
  display: flex;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

const StyledLottie = styled(Lottie)`
  width: ${convertUnit(100)};
  height: ${convertUnit(100)};
`

export default function ListPagination<ItemT>({
  status,
  listLoadingElement,
  listLoadingElementStyle,
  ...props
}: ListPaginationBasicProps<ItemT>) {
  const {width, height} = props

  const handleRenderIndicator = useMemo(
    () =>
      listLoadingElement ? (
        <StyledLoadingContainer style={listLoadingElementStyle}>
          {listLoadingElement}
        </StyledLoadingContainer>
      ) : (
        <StyledLoadingContainer width={width} height={height}>
          <StyledLottie
            animationData={animationData}
            height={convertUnit(100)}
            width={convertUnit(100)}
          />
        </StyledLoadingContainer>
      ),
    [height, listLoadingElement, listLoadingElementStyle, width],
  )

  if (status === 'loading') {
    return handleRenderIndicator
  }

  return <ListPaginationBase {...props} />
}
