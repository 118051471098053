import React, {CSSProperties, useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {parseDate, useDebounce, useLocation} from 'utils'
import {useWindowLayout} from 'windows'
import {Button, ButtonOutline} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopResendRevisionImageItem from '../ImageItem/GiftShopResendRevisionImageItem'
import {GiftShopResendRevisionContentProps} from './GiftShopResendRevisionContentProps'

const StyledOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${convertUnit(20)};
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-between;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    gap: 0;
  }
`

const StyledContentContainer = styled.div<CSSProperties>`
  width: 100%;
  max-width: 32%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    max-width: 49%;
  }
`

const StyledPanthom = styled.div`
  height: ${convertUnit(70)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(85)};
  }
`

const StyledFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  align-items: center;
  position: sticky;
  gap: ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledButtonOutline = styled(ButtonOutline)`
  width: 100%;
`

export default function GiftShopResendRevisionContent({
  revisionData,
  loadingImg,
  loadingBtn,
  onAddPhoto,
  onUpload,
}: GiftShopResendRevisionContentProps) {
  const {translate} = useTranslation()
  const {
    hiresUrl,
    originalAt,
    originalUrl,
    title,
    contentType,
    originalDuration,
    hiresDuration,
    originalSize,
    hiresSize,
    originalResolution,
    hiresResolution,
  } = useLocation('giftshop_resend_revision').state
  const parsedDate = useMemo(
    () => parseDate(originalAt.toString(), 'MMMM D, YYYY, HH:mm'),
    [originalAt],
  )
  const {width} = useWindowLayout()
  const fileDate = useMemo(() => {
    if (revisionData)
      return (
        revisionData?.image.exif?.CreateDate?.toISOString() ||
        new Date(revisionData?.image.file.lastModified).toISOString() ||
        new Date().toISOString()
      )
    return undefined
  }, [revisionData])
  const newDate = useMemo(
    () =>
      revisionData && fileDate
        ? parseDate(fileDate, 'MMMM D, YYYY, HH:mm')
        : undefined,
    [fileDate, revisionData],
  )
  const debounce = useDebounce()

  const handleRenderStandardVersion = useMemo(
    () => (
      <StyledContentContainer maxWidth={(width - 4) / 2}>
        <GiftShopResendRevisionImageItem
          versionTitle={translate('giftShop:notHires')}
          date={parsedDate}
          src={originalUrl}
          title={title}
          isNewContent={false}
          contentType={contentType}
          duration={originalDuration}
          size={originalSize}
          resolution={originalResolution}
        />
      </StyledContentContainer>
    ),
    [
      contentType,
      originalDuration,
      originalResolution,
      originalSize,
      originalUrl,
      parsedDate,
      title,
      translate,
      width,
    ],
  )

  const handleRenderCurrentHiresVersion = useMemo(
    () => (
      <StyledContentContainer maxWidth={(width - 4) / 2}>
        <GiftShopResendRevisionImageItem
          versionTitle={`${translate('global:hires')} (${translate(
            'global:current',
          )})`}
          date={parsedDate}
          src={hiresUrl}
          title={title}
          contentType={contentType}
          isNewContent={false}
          duration={hiresDuration}
          size={hiresSize}
          resolution={hiresResolution}
        />
      </StyledContentContainer>
    ),
    [
      contentType,
      hiresDuration,
      hiresResolution,
      hiresSize,
      hiresUrl,
      parsedDate,
      title,
      translate,
      width,
    ],
  )

  const handleRenderRevisionHiresVersion = useMemo(
    () => (
      <StyledContentContainer maxWidth={(width - 4) / 2}>
        <GiftShopResendRevisionImageItem
          isNewContent
          versionTitle={`${translate('global:hires')} (${translate(
            'global:revision',
          )})`}
          date={newDate}
          src={revisionData?.image.src}
          title={revisionData?.image.file.name}
          contentType={contentType}
          duration={revisionData?.image.duration}
          size={revisionData?.image.size}
          resolution={{
            width: revisionData?.image.width || 0,
            height: revisionData?.image.height || 0,
          }}
          loadingImg={loadingImg}
        />
      </StyledContentContainer>
    ),
    [
      contentType,
      loadingImg,
      newDate,
      revisionData?.image.duration,
      revisionData?.image.file.name,
      revisionData?.image.height,
      revisionData?.image.size,
      revisionData?.image.src,
      revisionData?.image.width,
      translate,
      width,
    ],
  )

  const handleButtonLabel = useMemo(() => {
    if (revisionData) {
      return translate(
        contentType === 'photo'
          ? 'giftShop:changePhotoButton'
          : 'giftShop:videoResendChangeButton',
      )
    }
    return translate(
      contentType === 'photo'
        ? 'giftShop:addPhoto'
        : 'giftShop:videoResendAddButton',
    )
  }, [contentType, revisionData, translate])

  const handleRenderButtons = useMemo(
    () => (
      <StyledFooter>
        <StyledButtonsContainer>
          <StyledButtonOutline
            borderColor="gray_5"
            color="gray_5"
            label={handleButtonLabel}
            backgroundHoverColor="white_1"
            onClick={loadingBtn ? undefined : onAddPhoto}
          />
          <StyledButton
            disabled={!revisionData}
            onClick={() =>
              loadingBtn ? undefined : debounce(() => onUpload(), 300)
            }
            isLoading={loadingBtn}
            label={translate('global:upload')}
          />
        </StyledButtonsContainer>
      </StyledFooter>
    ),
    [
      debounce,
      handleButtonLabel,
      loadingBtn,
      onAddPhoto,
      onUpload,
      revisionData,
      translate,
    ],
  )

  return (
    <StyledOuterContainer>
      <StyledContainer>
        {handleRenderStandardVersion}
        {handleRenderCurrentHiresVersion}
        {handleRenderRevisionHiresVersion}
      </StyledContainer>
      <StyledPanthom />
      {handleRenderButtons}
    </StyledOuterContainer>
  )
}
