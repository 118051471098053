import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_UPLOAD_VIDEO_TYPE, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {GiftshopTemplateUploadResendModal} from 'pages'
import {
  GiftshopResendDataType,
  GiftshopResendModalState,
  WindowModeType,
} from 'types'
import {
  clearUserCache,
  getBorder,
  getContentURL,
  handleGiftshopReadFile,
  showSnackbar,
  useDebounce,
  useDidMount,
  useDidUpdate,
  useGiftShopResendVideoHandler,
  useHistory,
} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  ButtonOutline,
  Icon,
  InputPicker,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileResendVideoProps} from './GiftShopProfileResendVideoProps'
import GiftShopProfileResendVideoItem from './GiftShopProfileResendVideoItem'

interface StyledFooterContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: ${convertUnit(25)};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }
`

const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(12)} 0;
  margin-bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-bottom: ${convertUnit(1)};
  }
`

const StyledHeaderItem = styled(Paragraph)`
  width: 50%;
  text-align: center;
`

const StyledRequirementInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin: ${convertUnit(20)};
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_4};
`

const StyledContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    gap: ${convertUnit(4)};
    padding: 0;
    margin-bottom: ${convertUnit(4)};
  }
`

const StyledFooterContainer = styled.div<StyledFooterContainerProps>`
  ${({theme, mode}) => ({
    borderTop: getBorder(1, 'solid', theme.gray_1),
    padding: `${convertUnit(15)} ${convertUnit(mode === 'mobile' ? 25 : 50)}`,
    backgroundColor: theme.white_1,
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  flex: 1;
  width: 100%;
`

const StyledAddVideoButton = styled(ButtonOutline)`
  flex: 1;
  width: 100%;
`

const StyledTryAgain = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`

export default function GiftShopProfileResendVideo({
  data,
  source,
}: GiftShopProfileResendVideoProps) {
  const {translate} = useTranslation()
  const {
    video_url,
    title,
    original_at,
    size,
    duration,
    creator_name,
    type,
  } = data
  const [resendData, setResendData] = useState<GiftshopResendDataType>()
  const newDate = useMemo(
    () =>
      resendData && new Date(resendData.image.file.lastModified).toISOString(),
    [resendData],
  )
  const mode = useWindowMode()
  const resendRef = useRef<HTMLInputElement>(null)
  const resendModalState = useState<GiftshopResendModalState>('none')
  const setResendModal = resendModalState[1]
  const history = useHistory()
  const [success, setSuccess] = useState(0)
  const debounce = useDebounce()
  const {uploadResend} = useGiftShopResendVideoHandler()

  const handleResend = useCallback(async () => {
    setResendModal('loading')
    const isSuccess =
      resendData && (await uploadResend(resendData.image, resendData.id))
    if (isSuccess) {
      setSuccess((prev) => prev + 1)
    } else {
      setResendModal('failed')
    }
  }, [resendData, setResendModal, uploadResend])

  const handleReadFile = useCallback(
    async (file: File) => {
      if (!GIFT_SHOP_UPLOAD_VIDEO_TYPE.includes(file.type)) {
        showSnackbar(translate('giftShop:videoUploadErrorInvalidFormat'))
        return
      }
      const image = await handleGiftshopReadFile(file, undefined, false, true)

      if (image !== null) {
        setResendData({id: data.content_id, image})
      }
    },
    [data.content_id, translate],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:standard')}
        </StyledHeaderItem>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:hires')}
        </StyledHeaderItem>
      </StyledHeaderContainer>
    ),
    [mode, translate],
  )

  const handleRenderRequirementInfo = useMemo(
    () => (
      <StyledRequirementInfoContainer>
        <Icon type="info" size={convertUnit(16)} />
        <Paragraph fontWeight="medium">
          {translate('giftShop:videoResendRequirementInfo')}
        </Paragraph>
      </StyledRequirementInfoContainer>
    ),
    [translate],
  )

  const handleRenderExistingContent = useMemo(
    () =>
      typeof original_at === 'string' ? (
        <GiftShopProfileResendVideoItem
          date={original_at}
          duration={duration}
          size={size}
          src={getContentURL({url: video_url, type})}
          title={title}
          isNewContent={false}
        />
      ) : (
        <></>
      ),
    [duration, original_at, size, title, type, video_url],
  )

  const handleRenderNewContent = useMemo(
    () => (
      <GiftShopProfileResendVideoItem
        isNewContent
        date={newDate}
        duration={resendData?.image.duration}
        size={resendData?.image.size}
        src={resendData?.image.src}
        title={resendData?.image.file.name}
      />
    ),
    [
      newDate,
      resendData?.image.duration,
      resendData?.image.file.name,
      resendData?.image.size,
      resendData?.image.src,
    ],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContentsContainer>
        {handleRenderExistingContent}
        {handleRenderNewContent}
      </StyledContentsContainer>
    ),
    [handleRenderExistingContent, handleRenderNewContent],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledFooterContainer mode={mode}>
        <StyledAddVideoButton
          label={
            resendData?.image.src
              ? translate('giftShop:videoResendChangeButton')
              : translate('giftShop:videoResendAddButton')
          }
          borderColor="gray_5"
          color="gray_5"
          onClick={() => resendRef.current?.click()}
        />
        <StyledButton
          disabled={resendData === undefined}
          label={translate('global:upload')}
          backgroundColor="primary_5"
          onClick={handleResend}
        />
      </StyledFooterContainer>
    ),
    [handleResend, mode, resendData, translate],
  )

  const handleRenderTryAgain = useMemo(
    () => (
      <StyledTryAgain>
        <StyledButton
          label={translate('global:close')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={() =>
            history.replace('giftshop_profile', {self: true}, creator_name)
          }
        />
        <StyledButton
          label={translate('global:tryAgain')}
          onClick={() => setResendModal('none')}
        />
      </StyledTryAgain>
    ),
    [creator_name, history, setResendModal, translate],
  )

  const handleRenderModalResendStatus = useMemo(
    () => (
      <GiftshopTemplateUploadResendModal
        resendModalState={resendModalState}
        bottomElement={handleRenderTryAgain}
        success={success}
        total={1}
      />
    ),
    [handleRenderTryAgain, resendModalState, success],
  )

  const handleRenderInputPicker = useMemo(
    () => (
      <InputPicker
        inputRef={resendRef}
        onLoadFiles={(files) => handleReadFile(files[0])}
        accept={GIFT_SHOP_UPLOAD_VIDEO_TYPE.join(',')}
      />
    ),
    [handleReadFile],
  )

  useDidUpdate(() => {
    if (success === 1) {
      setResendModal('success')
      clearUserCache('creation')
      debounce(() => {
        if (source === 'host') {
          history.replace('tree_host_profile', {})
        } else history.replace('giftshop_profile', {self: true}, creator_name)
      }, 500)
    }
  }, [success, history])

  useDidMount(() => {
    resendRef.current && resendRef.current.click()
  })

  return (
    <>
      {handleRenderModalResendStatus}
      {handleRenderInputPicker}
      <StyledContainer>
        {handleRenderHeader}
        {handleRenderRequirementInfo}
        {handleRenderContent}
      </StyledContainer>
      {handleRenderButton}
    </>
  )
}
