import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileModalContentProps} from './TemplateProfileModalContentProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
  display: flex;
  gap: ${convertUnit(20)};
  text-align: center;
  flex-direction: column;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(12.5)};
`

export default function TemplateProfileModalContent({
  children,
  visible,
  toggleModal,
}: TemplateProfileModalContentProps) {
  const {translate} = useTranslation()
  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize={'l'} fontWeight="bold">
        {translate('global:options')}
      </Paragraph>
      {children}
      <StyledButton
        backgroundColor="white_3"
        color="primary_5"
        label={translate('global:close')}
        onClick={toggleModal}
      />
    </StyledModal>
  )
}
