import React from 'react'
import styled from 'styled-components'
import {
  HOST_BANK_NUMBER_MAX_LENGTH,
  REGEX_NUMBER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input} from 'common/components'
import {VALIDATION_TREE_HOST_ACCOUNT_NUMBER} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

export const StyledItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeRegistrationFormItemAccountNumber({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {accountNumber} = watch()

  return (
    <StyledItemsContainer>
      <Input
        form={form}
        formRules={VALIDATION_TREE_HOST_ACCOUNT_NUMBER}
        name="accountNumber"
        label={translate('tree:hostRegistrationFormAccountNumberLabel')}
        placeholder={translate(
          'tree:hostRegistrationFormAccountNumberPlaceholder',
        )}
        maxLength={HOST_BANK_NUMBER_MAX_LENGTH + 1}
        allowedCharacters={REGEX_NUMBER}
        disabled={disabled}
        rightIcon={
          !disabled && accountNumber && accountNumber.length > 0 ? (
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={() => setValue('accountNumber', '')}
            />
          ) : undefined
        }
      />
    </StyledItemsContainer>
  )
}
