import React, {useCallback, useMemo, useState, CSSProperties} from 'react'
import styled from 'styled-components'
import {LIST_END_REACHED_THRESHOLD} from 'consts'
import {CSSHtmlProperties, LayoutView} from 'types'
import {useDidUpdate} from 'utils'
import convertUnit from 'lib/unit'
import {View} from '../View'
import {ListProps} from './ListProps'

interface StyledItemContainerProps extends CSSHtmlProperties<HTMLDivElement> {
  width: string
  height?: number
}

interface StyledListContainerProps {
  $scrollbar: boolean
  scrollSnapType?: CSSProperties['scrollSnapType']
}

const StyledBaseContainer = styled.div<CSSProperties>`
  background-color: ${({backgroundColor, theme}) =>
    backgroundColor && theme[backgroundColor]};
  position: relative;
  flex: 1;
`

const StyledListContainer = styled(View)<StyledListContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;

  overflow-y: scroll;

  scroll-snap-type: ${({scrollSnapType}) => scrollSnapType};
  scrollbar-width: ${({$scrollbar}) => ($scrollbar ? 'auto' : 'none')};
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${({$scrollbar}) => ($scrollbar ? convertUnit(25) : 0)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledContainer = styled(View)`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const StyledSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const StyledItemContainer = styled.div<StyledItemContainerProps>`
  ${({width, height}) => ({
    width,
    height,
  })}
  display: flex;
  flex-direction: column;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: none;
`

export default function List<T>({
  data: baseItems,
  numColumns = 1,
  contentContainerStyle,
  emptyContainerStyle,
  listEmptyElement,
  listHeaderElement,
  listFooterElement,
  onEndReachedThreshold = LIST_END_REACHED_THRESHOLD,
  onEndReached,
  onLayout,
  onContentSizeChange,
  keyExtractor,
  renderItem,
  onScrollList,
  backgroundColor,
  scrollbar = true,
  scrollSnapType = 'none',
  ...props
}: ListProps<T>) {
  const [endZone, setEndZone] = useState(false)
  const items = useState<ReadonlyArray<T>>([])[0]
  const width = useMemo(() => `${100 / numColumns}%`, [numColumns])
  const [height, setHeight] = useState(0)
  const data = useMemo(() => (baseItems ? [...baseItems, ...items] : items), [
    baseItems,
    items,
  ])
  const numRows = useMemo(() => Math.ceil(data.length / numColumns), [
    data,
    numColumns,
  ])

  const handleRenderColumns = useCallback(
    (indexRow: number) => {
      const indexStart = indexRow * numColumns
      const indexEnd = (indexRow + 1) * numColumns
      const indexLimit = Math.min(indexEnd, data.length)
      const length = indexLimit - indexStart

      return Array.from({length}, (_, indexColumn) => {
        const index = indexStart + indexColumn
        const item = data[index]

        return (
          <StyledItemContainer key={keyExtractor(item)} width={width}>
            {renderItem(item, index)}
          </StyledItemContainer>
        )
      })
    },
    [data, width, numColumns, keyExtractor, renderItem],
  )

  const handleContentSizeChange = useCallback(
    ({clientHeight, clientWidth}: LayoutView) => {
      onContentSizeChange && onContentSizeChange(clientWidth, clientHeight)
      setHeight(clientHeight)
    },
    [onContentSizeChange],
  )

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      onScrollList && onScrollList(event)
      if (onEndReached) {
        const {offsetHeight, scrollHeight, scrollTop} = event.currentTarget
        const dif = scrollHeight - (scrollTop + offsetHeight)

        if (!endZone && dif <= onEndReachedThreshold) {
          onEndReached({distanceFromEnd: dif})
          setEndZone(true)
        } else if (endZone && dif > onEndReachedThreshold) {
          setEndZone(false)
        }
      }
    },
    [onScrollList, onEndReached, endZone, onEndReachedThreshold],
  )

  const handleRenderContent = useMemo(
    () =>
      data.length ? (
        <StyledContentContainer style={contentContainerStyle}>
          {Array.from({length: numRows}, (_, index) => (
            <StyledSectionItem
              key={index}
              style={
                scrollSnapType !== 'none'
                  ? {scrollSnapAlign: 'center'}
                  : undefined
              }>
              {handleRenderColumns(index)}
            </StyledSectionItem>
          ))}
        </StyledContentContainer>
      ) : null,
    [data, contentContainerStyle, numRows, scrollSnapType, handleRenderColumns],
  )

  const handleRenderEmpty = useMemo(
    () =>
      data.length === 0 &&
      listEmptyElement && (
        <StyledEmptyContainer style={emptyContainerStyle}>
          {listEmptyElement}
        </StyledEmptyContainer>
      ),
    [data.length, emptyContainerStyle, listEmptyElement],
  )

  useDidUpdate(() => {
    endZone && setEndZone(false)
  }, [height])

  return (
    <StyledBaseContainer backgroundColor={backgroundColor}>
      <StyledListContainer
        data-testid="list"
        onScroll={handleScroll}
        onLayout={onLayout}
        $scrollbar={scrollbar}
        scrollSnapType={scrollSnapType}>
        <StyledContainer {...props} onLayout={handleContentSizeChange}>
          {listHeaderElement}
          {handleRenderContent}
          {handleRenderEmpty}
          {listFooterElement}
        </StyledContainer>
      </StyledListContainer>
    </StyledBaseContainer>
  )
}
