import React from 'react'
import styled from 'styled-components'
import {
  LIST_MASONRY_PAGINATION_BUTTON_SIZE,
  LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import {getBorder, getFontFamily} from 'utils'
import {Button} from '../Button'
import {
  ListMasonryPaginationButtonControlsProps,
  ListMasonryPaginationButtonPageProps,
} from './ListMasonryPaginationPageButtonProps'
import {Paragraph} from '../Paragraph'

interface StyledPaginationButtonProps {
  active?: boolean
  darkMode?: boolean
}

interface StyledPaginationGapMarkerProps {
  darkMode?: boolean
}

const StyledPaginationPageButton = styled(Button)<StyledPaginationButtonProps>`
  ${({theme, active, darkMode}) =>
    active
      ? {
          color: theme.white_1,
        }
      : {
          color: darkMode ? theme.white_1 : theme.gray_5,
          backgroundColor: darkMode ? theme.black_2 : theme.white_1,
          border: getBorder(1, 'solid', darkMode ? theme.gray_8 : theme.gray_1),
        }}
  width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  font-family: ${getFontFamily('medium')};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    font-size: ${convertUnit(12)};
  }
`

const StyledPaginationGapMarker = styled.div<StyledPaginationGapMarkerProps>`
  ${({theme, darkMode}) => ({
    color: darkMode ? theme.gray_3 : theme.gray_5,
  })}
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  font-family: ${getFontFamily('medium')};
  letter-spacing: ${convertUnit(1)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    font-size: ${convertUnit(12)};
  }
`

export function ListMasonryPaginationButtonPage({
  darkMode,
  destination,
  label,
  page,
  setPage,
}: ListMasonryPaginationButtonPageProps) {
  return (
    <StyledPaginationPageButton
      darkMode={darkMode}
      active={page === destination}
      onClick={() => (page !== destination ? setPage(destination) : undefined)}>
      {label ? (
        <Paragraph color="inherit" fontWeight="medium">
          {label}
        </Paragraph>
      ) : (
        destination
      )}
    </StyledPaginationPageButton>
  )
}

export function ListMasonryPaginationButtonControls({
  darkMode,
  onClick,
  children,
}: ListMasonryPaginationButtonControlsProps) {
  return (
    <StyledPaginationPageButton darkMode={darkMode} onClick={onClick}>
      {children}
    </StyledPaginationPageButton>
  )
}

export function ListMasonryPaginationGapMarker({
  darkMode,
}: StyledPaginationGapMarkerProps) {
  return (
    <StyledPaginationGapMarker darkMode={darkMode}>
      ...
    </StyledPaginationGapMarker>
  )
}
