import React from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_MAX_DESC_CHARACTER, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {InputArea} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormBaseProps} from './GiftShopTemplateUploadFormProps'

const StyledDescription = styled.div`
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(0)};
    margin-left: ${convertUnit(0)};
  }
`

export default function GiftShopTemplateUploadFormDescription({
  form,
}: GiftShopTemplateUploadFormBaseProps) {
  const {translate} = useTranslation()
  const {watch} = form
  const {description} = watch()

  return (
    <StyledDescription>
      <InputArea
        name="description"
        form={form}
        defaultValue={description}
        label={translate('giftShop:uploadDescriptionLabel')}
        placeholder={translate('giftShop:uploadInputDescriptionPlaceholder')}
        autoComplete={false}
        containerStyle={{
          marginTop: convertUnit(25),
        }}
        maxLength={GIFT_SHOP_MAX_DESC_CHARACTER}
        contentContainerStyle={{height: '100%'}}
        inputContainerStyle={{height: convertUnit(69)}}
      />
    </StyledDescription>
  )
}
