import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_CREATION_CONTENT,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopGetExploreCreatorResponse} from 'types'
import {
  Icon,
  IconVerifiedBadge,
  Input,
  ListItemSelect,
  ListLazy,
  Menu,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDebounce} from 'utils'
import {GiftShopExploreFilterModalFormProps} from './GiftShopExploreFilterModalFormProps'

const StyledInputContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertUnit(20)};
`

const StyledListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  line-height: ${convertUnit(12)};
`

const StyledChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledChips = styled.div`
  background-color: ${({theme}) => theme.primary_1};
  height: ${convertUnit(25)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${convertUnit(12)};
  margin-right: ${convertUnit(5)};
  margin-top: ${convertUnit(5)};
  border-radius: ${convertUnit(25)};
`

const StyledDeleteChipIconContainer = styled.div`
  margin-left: ${convertUnit(9.5)};
  justify-content: center;
  display: flex;
`

export default function GiftShopExploreFilterModalFormTags({
  stateTempFilter,
}: GiftShopExploreFilterModalFormProps) {
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const [tempFilter, setTempFilter] = stateTempFilter
  const {creators: selectedCreators} = tempFilter
  const [searchCreator, setSearchCreator] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const stateCreators = useState<readonly GiftShopGetExploreCreatorResponse[]>(
    [],
  )
  const [creators, setCreators] = stateCreators
  const falseState = useState(false)

  const handleLoadData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestData('giftshop_get_explore_creator', {
        cancelId: SERVICE_CANCELLATION_SEARCH_CREATION_CONTENT,
        params: {page, limit, q},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.filter(
            (item) =>
              selectedCreators.findIndex(
                (val) => item.creator_id === val.creator_id,
              ) === -1,
          )
        : []
    },
    [selectedCreators],
  )

  const handleSelectCreators = useCallback(
    (item: GiftShopGetExploreCreatorResponse) => {
      setSearchCreator('')
      setToggle((prev) => !prev)
      setCreators((previous) =>
        previous.filter((value) => value.creator_id !== item.creator_id),
      )
      setTempFilter((prev) => ({
        ...prev,
        creators: prev.creators ? [...prev.creators, item] : [item],
      }))
    },
    [setCreators, setTempFilter, setToggle],
  )

  const handleRenderChips = useCallback(
    (item: GiftShopGetExploreCreatorResponse) => (
      <StyledChips key={item.creator_id}>
        <Paragraph color={'primary_5'} fontWeight="medium" fontSize="xs">
          {item.username}
        </Paragraph>
        <StyledDeleteChipIconContainer>
          <Icon
            data-testid="icon"
            type="close"
            color="gray_6"
            size={9}
            onClick={() =>
              setTempFilter((prev) => ({
                ...prev,
                creators: prev.creators?.filter(
                  (ids) => ids.creator_id !== item.creator_id,
                ),
              }))
            }
          />
        </StyledDeleteChipIconContainer>
      </StyledChips>
    ),
    [setTempFilter],
  )

  const handleRenderCreatorChips = useMemo(
    () =>
      selectedCreators ? (
        <StyledChipsContainer>
          {selectedCreators.map(handleRenderChips)}
        </StyledChipsContainer>
      ) : (
        <></>
      ),
    [handleRenderChips, selectedCreators],
  )

  const handleRenderDropdownItem = useCallback(
    (item: GiftShopGetExploreCreatorResponse) => (
      <ListItemSelect
        title={item.username}
        titleRighIcon={<StyledIconVerifiedBadge />}
        overflowSubtitle={item.biodata}
        hoverColor="white_3"
        titleHoverColor="black"
        onClick={() => handleSelectCreators(item)}
      />
    ),
    [handleSelectCreators],
  )

  const handleRenderList = useMemo(
    () => (
      <StyledListContainer>
        <ListLazy
          stateData={stateCreators}
          loadData={handleLoadData}
          search={searchQuery}
          data={creators}
          keyExtractor={(creator) => creator.creator_id}
          renderItem={handleRenderDropdownItem}
        />
      </StyledListContainer>
    ),
    [
      creators,
      handleLoadData,
      handleRenderDropdownItem,
      searchQuery,
      stateCreators,
    ],
  )

  return (
    <StyledInputContainer>
      <Menu
        stateToggle={selectedCreators.length < 8 ? stateToggle : falseState}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={
          selectedCreators.length < 8 ? handleRenderList : undefined
        }>
        <Input
          defaultValue={searchCreator}
          label={translate('giftShop:exploreFilterCreatorLabel')}
          placeholder={translate('giftShop:exploreFilterCreatorPlaceholder')}
          onChangeText={(text) => {
            setSearchCreator(text)
            debounce(() => setSearchQuery(text), 500)
            setToggle(true)
          }}
          rightIcon={
            searchCreator.length > 0 ? (
              <Icon
                type="close"
                size={convertUnit(24)}
                color="gray_5"
                onClick={() => setSearchCreator('')}
              />
            ) : undefined
          }
          disabled={selectedCreators.length >= 8}
        />
      </Menu>
      {handleRenderCreatorChips}
    </StyledInputContainer>
  )
}
