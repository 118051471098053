import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {FooterTemplate} from 'pages'
import {LegalTemplateProps} from './LegalTemplateProps'

const StyledContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }
`

const StyledButtonContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(44)};
  height: ${convertUnit(44)};
  width: 100%;
  display: flex;
  justify-content: center;
`

export default function LegalTemplate({children}: LegalTemplateProps) {
  const scrolled = useRef<HTMLDivElement | null>(null)

  const [showButton, setShowButton] = useState(false)

  const toggleVisible = useCallback(() => {
    const scrollTop = scrolled.current?.scrollTop || 0
    const scrollHeight = scrolled.current?.scrollHeight || 0
    if (scrollTop >= scrollHeight / 1.5) {
      setShowButton(true)
    } else if (scrollTop <= 300) {
      setShowButton(false)
    }
  }, [])

  useEffect(() => {
    scrolled.current?.addEventListener('scroll', toggleVisible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => scrolled.current?.removeEventListener('scroll', toggleVisible)
  }, [toggleVisible])

  const scrollToTop = useCallback(() => {
    scrolled.current?.scrollTo({top: 0, behavior: 'smooth'})
  }, [])

  return (
    <StyledContainer ref={scrolled}>
      {children}
      <StyledButtonContainer>
        {showButton && (
          <Button
            onClick={scrollToTop}
            label={translate('policy:backToTop')}
            rightIcon={<Icon type="chevron-up" color="white_1" />}
          />
        )}
      </StyledButtonContainer>
      <FooterTemplate />
    </StyledContainer>
  )
}
