import React, {useCallback, useMemo, useRef, useState} from 'react'
import QRCode from 'react-qr-code'
import {isSafari} from 'react-device-detect'
import {domToJpeg} from 'modern-screenshot'
import styled from 'styled-components'
import download from 'downloadjs'
import {useTranslation} from 'i18n'
import {getTextShadow, showSnackbar, useDidMount, useLocation} from 'utils'
import {GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import convertUnit from 'lib/unit'
import history from 'lib/history'
import {useSelector} from 'lib/redux'
import {Button, Image, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import {useTheme} from 'themes'

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContainer = styled(StyledColumn)`
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: center;
  background-color: ${({theme}) => theme.white_3};
  box-sizing: border-box;
  padding: ${convertUnit(20)};
`

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(335)};
  max-height: ${convertUnit(475)};
  box-shadow: ${({theme}) =>
    getTextShadow(theme, {blurRadius: 8, opacity: 0.14})};
  box-sizing: border-box;
  border-radius: ${convertUnit(15)};
  background-color: ${({theme}) => theme.white_1};
  overflow: hidden;
`

const StyledContentContainer = styled(StyledColumn)`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${convertUnit(25)} ${convertUnit(16)} 0;
`

const StyledQrCodeContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(134)};
  margin: ${convertUnit(6)} auto ${convertUnit(12)};
`

const StyledQrCode = styled(QRCode)`
  width: 100%;
  height: 100%;
`

const StyledDescriptionListContainer = styled.div<{locale: string}>`
  width: $(({locale}) => (locale === 'id' ? '66%': '60%'));
  text-align: left;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(560)};
  margin-top: ${convertUnit(20)};
`

const StyledContentBottomContainer = styled.div`
  margin: auto ${convertUnit(-20)} ${convertUnit(-8)};
`

const StyledImage = styled(Image)`
  width: 100%;
`

export default function TreeFototreePreviewQrScreen() {
  const theme = useTheme()
  const {translate} = useTranslation()
  const {lang = 'id'} = useSelector('user') || {}
  const {treeId, treeName} = useLocation('tree_fototree_preview_qr').state
  const ref = useRef<HTMLDivElement>(null)
  const [url, setUrl] = useState('')

  const handleGenerateQrCode = useCallback(() => {
    requestData('tree_post_generate_qr', {
      data: {tree_id: treeId},
      onRequestSuccess: ({status, data}) => {
        if (status === 200) setUrl(data.result.url)
      },
    })
  }, [treeId])

  const handleCaptureQrCode = useCallback(async () => {
    if (ref.current === null) return

    const capture = await domToJpeg(ref.current, {
      backgroundColor: theme.white_1,
      scale: 7,
      quality: 1,
    })
    return capture
  }, [theme.white_1])

  const handleDownloadQrCode = useCallback(async () => {
    try {
      let canvas = await handleCaptureQrCode()

      if (!canvas) return

      const encodedCanvas = atob(canvas.split(',')[1])
      const name = treeName.replace(/\s/g, '-')
      const filename = `${name}-QR-Code.jpg`

      /**
       * Check if the canvas size is less than 600kb,
       * which indicates that the canvas does not include the footer image.
       * Therefore, we need to re-capture the canvas.
       */
      if (encodedCanvas.length > 600000) {
        download(canvas, filename, 'image/jpeg')
      } else {
        canvas = await handleCaptureQrCode()
        download(canvas, filename, 'image/jpeg')
      }
      showSnackbar(translate('giftShop:successDownloadMessageHires'))
    } catch {
      showSnackbar(translate('giftShop:snackbarFailedDownloadMyCollection'))
    }
  }, [handleCaptureQrCode, translate, treeName])

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          label={translate('tree:previewQrCodeDownload')}
          disabled={!url}
          onClick={handleDownloadQrCode}
        />
      </StyledButtonContainer>
    ),
    [handleDownloadQrCode, translate, url],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContentContainer ref={ref}>
        <Paragraph fontWeight="bold" fontSize="xl" lineHeight={38}>
          {translate('tree:previewQrCodeTitle')}
        </Paragraph>
        <Paragraph
          fontWeight="kalam"
          fontSize="l"
          color="primary_5"
          lineHeight={28}>
          {treeName}
        </Paragraph>
        <StyledQrCodeContainer>
          {url && <StyledQrCode value={url} />}
        </StyledQrCodeContainer>
        <StyledDescriptionListContainer locale={lang}>
          {Array.from({length: 4}).map((_, i) => (
            <Paragraph fontSize="xs" key={i.toString()}>
              {`${i + 1}. `}
              {translate('tree:previewQrCodeDescription', {
                context: String(i + 1),
              })}
            </Paragraph>
          ))}
        </StyledDescriptionListContainer>
        <StyledContentBottomContainer>
          <StyledImage
            alt="privacy-with-face-recognition-ai"
            src={IMAGE_ASSET(
              'giftshop',
              isSafari ? 'footer-qrcode.png' : 'footer-qrcode.svg',
            )}
          />
        </StyledContentBottomContainer>
      </StyledContentContainer>
    ),
    [lang, translate, treeName, url],
  )

  useDidMount(handleGenerateQrCode)

  return (
    <GiftShopRootScreen
      headerTitle={translate('tree:previewQrCode')}
      showBadgeIcon={false}
      onBackButtonClicked={history.goBack}>
      <StyledContainer>
        <StyledContentWrapper>{handleRenderContent}</StyledContentWrapper>
        {handleRenderButton}
      </StyledContainer>
    </GiftShopRootScreen>
  )
}
