import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {parseDate, useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(15)};
`

export interface GiftShopMessageRoomContentItemNotificationResendpologyProps {
  payload: FirebaseMessageRoomItemPayload['notification_resendpology']
}

export default function GiftShopMessageRoomContentItemNotificationResendpology({
  payload,
}: GiftShopMessageRoomContentItemNotificationResendpologyProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const {contents, creator_username, purchased_at, username} = payload

  const handleLoadData = useCallback(() => {
    requestData('giftshop_get_contents_detail', {
      params: {content_ids: contents.toString()},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result)
        setLoading(false)
      },
    })
  }, [contents])

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:hiUsername', {username})}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationResendpologyFirst', {
            creator_username,
            purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY, HH:mm:ss'),
          })}
        </Paragraph>
        <Paragraph fontSize="m">
          {translate('messages:notificationResendpologySecond')}
        </Paragraph>
      </>
    ),
    [creator_username, purchased_at, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data: [{...item, type: 'collection'}],
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('giftShop:myCollection')}
        onClick={() => history.push('giftshop_collection', {})}
      />
    ),
    [history, translate],
  )

  useDidMount(handleLoadData)

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderButton}
    </>
  )
}
