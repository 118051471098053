import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {Paragraph, RadioItem} from 'common/components'
import {GiftShopReportContentItemProps} from './GiftShopReportContentItemProps'

const StyledOption = styled.div<{selected?: boolean}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: ${convertUnit(16)};
  margin-bottom: ${convertUnit(5)};
  background-color: ${({theme, selected}) =>
    selected ? theme.primary_1 : theme.white_1};
  border-radius: ${convertUnit(16)};

  :last-child {
    margin-bottom: 0;
  }
`

export default function GiftShopReportContentItem({
  reportName,
  selected,
  onClick,
}: GiftShopReportContentItemProps) {
  return (
    <StyledOption>
      <Paragraph fontSize="l" fontWeight="medium">
        {reportName}
      </Paragraph>
      <RadioItem isSelected={selected} onClick={onClick} />
    </StyledOption>
  )
}
