import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {getThemeColor} from 'utils'
import {useTheme} from 'themes'

const StyledContainer = styled.div`
  width: fill-available;
  max-width: ${convertUnit(600)};
  position: absolute;
  left: ${convertUnit(20)};
  right: 0;
  bottom: ${convertUnit(12)};
  margin: 0 auto;
`

const StyledTopContaier = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: ${convertUnit(8)};
  margin-bottom: ${convertUnit(2)};
`

const StyledSkeletonAvatar = styled(Skeleton)`
  height: ${convertUnit(60)};
  aspect-ratio: 0.75;
`

const StyledSkeletonUsername = styled(Skeleton)`
  width: ${convertUnit(120)};
  height: ${convertUnit(12)};
`

const StyledSkeletonCaption = styled(Skeleton)`
  width: ${convertUnit(400)};
  max-width: calc(100% - (${convertUnit(70)}));
  height: ${convertUnit(11)};
`

export default function TemplateProfileDetailContentSkeleton() {
  const theme = useTheme()

  return (
    <StyledContainer>
      <StyledTopContaier>
        <StyledSkeletonAvatar
          baseColor={getThemeColor(theme, 'gray_1', 0.5)}
          highlightColor={getThemeColor(theme, 'white_4', 0.1)}
        />
        <StyledSkeletonUsername
          baseColor={getThemeColor(theme, 'gray_1', 0.5)}
          highlightColor={getThemeColor(theme, 'white_4', 0.1)}
        />
      </StyledTopContaier>
      <StyledSkeletonCaption
        baseColor={getThemeColor(theme, 'gray_1', 0.5)}
        highlightColor={getThemeColor(theme, 'white_4', 0.1)}
      />
    </StyledContainer>
  )
}
