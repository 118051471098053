import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_WHOLESALE_DISCOUNT_INCREMENT,
  GIFT_SHOP_WHOLESALE_MAX_CONTENT,
  GIFT_SHOP_WHOLESALE_MAX_DISCOUNT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopWholesaleTierData} from 'types'
import {showSnackbar, useDidMount} from 'utils'
import {Button, ListFailed, ModalLoading, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSettingWholesaleItem} from '../WholesaleItem'

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(480)};
  border-radius: ${convertUnit(16)};
  padding: ${convertUnit(40)};
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.white_1};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(20)} 0;
  }
`

const StyledDisclaimerContainer = styled.div`
  margin: ${convertUnit(20)} 0;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledSection = styled.div`
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0 ${convertUnit(20)};
  }
`

export default function GiftShopSettingWholesaleContent() {
  const {translate} = useTranslation()
  const [loading, setLoading] = useState(false)
  const prevDatas = useRef<GiftShopWholesaleTierData[]>()
  const stateData = useState<GiftShopWholesaleTierData[]>([])
  const [data, setData] = stateData

  const handleCheckValidity = useCallback(
    async (max?: string) => {
      const lastIdx = data.length - 1
      const lastDisc = data[lastIdx].discount
      const prevDisc = data.length === 1 ? -1 : data[lastIdx - 1].discount

      if (lastDisc > GIFT_SHOP_WHOLESALE_MAX_DISCOUNT) {
        showSnackbar(translate('giftShop:wholesaleDiscountHigherMessage'))
        return false
      }
      if (isNaN(lastDisc) || lastDisc <= prevDisc) {
        showSnackbar(translate('giftShop:wholesaleDiscountLowerMessage'))
        return false
      }
      if (max) {
        const lastMax = parseInt(max, 10)
        const prevMax = data[lastIdx].min_contents - 1

        if (lastMax > GIFT_SHOP_WHOLESALE_MAX_CONTENT) {
          showSnackbar(translate('giftShop:wholesaleMaximumHigherMessage'))
          return false
        }
        if (lastMax <= prevMax) {
          showSnackbar(translate('giftShop:wholesaleMaximumLowerMessage'))
          return false
        }
      }

      return true
    },
    [data, translate],
  )

  const handleLoadData = useCallback(() => {
    setLoading(true)
    requestData('giftshop_get_creator_wholesale', {
      actionType: 'fetch',
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          prevDatas.current = result.wholesale_tier
          setData(result.wholesale_tier)
          setLoading(false)
        }
      },
    })
  }, [setData])

  const handlePatchWholesale = useCallback(async () => {
    const valid = await handleCheckValidity()
    if (valid) {
      requestData('giftshop_patch_creator_wholesale', {
        actionType: 'execute',
        data: {wholesale_tier: data},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('giftShop:wholesaleChangesSuccess'))
          } else showSnackbar(translate('giftShop:wholesaleChangesFailed'))
        },
      })
    }
  }, [data, handleCheckValidity, translate])

  const handleAddTier = useCallback(
    async (inputMax: string, inputDisc: string) => {
      const valid = await handleCheckValidity(inputMax)

      if (valid) {
        const {tier: prevTier, min_contents: prevMin} = data[data.length - 1]
        const intMax = parseInt(inputMax, 10)
        const intDisc = parseInt(inputDisc, 10)
        const newDisc =
          intDisc + GIFT_SHOP_WHOLESALE_DISCOUNT_INCREMENT >
          GIFT_SHOP_WHOLESALE_MAX_DISCOUNT
            ? GIFT_SHOP_WHOLESALE_MAX_DISCOUNT
            : intDisc + GIFT_SHOP_WHOLESALE_DISCOUNT_INCREMENT
        const newMin = inputMax.length === 0 ? prevMin + 1 : intMax + 1
        setData((prev) => [
          ...prev,
          {
            discount: newDisc,
            min_contents: newMin,
            tier: prevTier + 1,
          },
        ])
      }
    },
    [data, handleCheckValidity, setData],
  )

  const handleDeleteTier = useCallback(() => {
    setData((prev) => prev.slice(0, -1))
  }, [setData])

  const handleRenderDisclaimer = useMemo(
    () => (
      <StyledDisclaimerContainer>
        <Paragraph fontSize="s" color="gray_5">
          {translate('giftShop:settingWholesaleDisclaimer')}
        </Paragraph>
      </StyledDisclaimerContainer>
    ),
    [translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:save')}
        onClick={handlePatchWholesale}
        disabled={!data || prevDatas.current === data}
        isLoading={loading}
      />
    ),
    [data, handlePatchWholesale, loading, translate],
  )

  const handleRenderRetry = useMemo(
    () => <ListFailed onRetry={handleLoadData} />,
    [handleLoadData],
  )

  const handleRenderModalLoading = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  const handleRenderContents = useMemo(
    () =>
      data.map((item, idx) => (
        <GiftShopSettingWholesaleItem
          key={item.tier}
          data={item}
          dataLength={data.length}
          idx={idx}
          isLastTier={idx === data.length - 1}
          stateData={stateData}
          onAddTier={handleAddTier}
          onDeleteTier={handleDeleteTier}
        />
      )),
    [data, stateData, handleAddTier, handleDeleteTier],
  )

  useDidMount(handleLoadData)

  return (
    <>
      {handleRenderModalLoading}
      {data || loading ? (
        <StyledContainer>
          <StyledSection>
            {handleRenderContents}
            {handleRenderDisclaimer}
            {handleRenderButton}
          </StyledSection>
        </StyledContainer>
      ) : (
        handleRenderRetry
      )}
    </>
  )
}
