import React, {useCallback, useMemo} from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopContentData} from 'types'
import {getContentURL, getCurrencyValue, parseDate} from 'utils'
import {Paragraph, Image} from 'common/components'
import convertUnit from 'lib/unit'
import {useTheme} from 'themes'

export interface GiftShopMessageRoomContentListProps {
  data: GiftShopContentData[]
  dataCount?: number
  onClick?(item: GiftShopContentData): void
  loading?: boolean
  showFooter?: boolean
  showRefundAmount?: boolean
}

const StyledContainer = styled.div`
  margin-top: ${convertUnit(10)};
`

const StyledSectionItemInfo = styled.div`
  flex: 1;
  margin-left: ${convertUnit(10)};
`

const StyledDescription = styled.div`
  ${({theme}) => ({backgroundColor: theme.gray_1})}
  height: ${convertUnit(1)};
  display: flex;
  width: 100%;
  margin-top: ${convertUnit(5)};
  margin-bottom: ${convertUnit(5)};
`

const StyledItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(5)};
  margin-bottom: ${convertUnit(5)};
  cursor: pointer;
`

const StyledImage = styled(Image)`
  height: ${convertUnit(75)};
  aspect-ratio: 1;
`

const StyledSkeleton = styled(Skeleton)`
  margin-top: ${convertUnit(5)};
  margin-bottom: ${convertUnit(5)};
`

export default function GiftShopMessageRoomContentList({
  data,
  dataCount,
  loading,
  onClick,
  showFooter = false,
  showRefundAmount = false,
}: GiftShopMessageRoomContentListProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <>
        <StyledItemContainer
          onClick={() => !loading && onClick && onClick(item)}
          key={item.content_id}>
          <StyledImage
            src={getContentURL({url: item.url, type: item.type})}
            alt={`content-item:${item.title}`}
          />
          <StyledSectionItemInfo>
            <Paragraph fontSize="m" fontWeight="bold">
              {item.title}
            </Paragraph>
            <StyledDescription />
            <Paragraph fontSize="m">
              {showRefundAmount && (
                <Paragraph fontSize="m">
                  {translate('messages:notificationRefundAmount')}
                </Paragraph>
              )}
              {getCurrencyValue(item.price)}
            </Paragraph>
          </StyledSectionItemInfo>
        </StyledItemContainer>
        {showFooter && typeof item.original_at === 'string' && (
          <Paragraph>
            {parseDate(item.original_at, 'DD/MM/YYYY, HH:mm:ss')}
          </Paragraph>
        )}
      </>
    ),
    [showRefundAmount, translate, showFooter, loading, onClick],
  )

  const handleRenderList = useMemo(() => data.map(handleRenderItem), [
    data,
    handleRenderItem,
  ])

  const handleRenderSkeleton = useMemo(
    () =>
      dataCount ? (
        Array.from({length: dataCount}).map((_, i) => (
          <StyledSkeleton
            // eslint-disable-next-line react/no-array-index-key
            key={`Skeleton_${i}`}
            width={76}
            height={76}
            baseColor={theme.gray_1}
            highlightColor={theme.white_4}
          />
        ))
      ) : (
        <></>
      ),
    [dataCount, theme.gray_1, theme.white_4],
  )

  const handleRenderContent = useMemo(
    () => (loading && dataCount ? handleRenderSkeleton : handleRenderList),
    [dataCount, handleRenderList, handleRenderSkeleton, loading],
  )

  return <StyledContainer>{handleRenderContent}</StyledContainer>
}
