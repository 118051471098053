import React, {useMemo} from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import {
  WEBSITE_NAME,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
  COMPANY_NAME,
  AUTH_USER_MIN_AGE,
} from 'consts'
import {getFontFamily, getFontSize} from 'utils'
import {Icon, Image, Paragraph, ParsedText} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'
import animationData from '../../../../../assets/lottie/flow_fotoyu.json'
import {LegalServiceFee} from '../ServiceFee'
import {LegalFototreeContent} from '../FototreeContent'
import {LegalLikeAndComment} from '../LikeAndCommentContent'
import {LegalSendToFaceContent} from '../SendToFaceContent'
import {LegalSellVideoContent} from '../SellVideoContent'

export interface LegalHowItWorksContentProps {
  scrollTo: (childRef: number | undefined) => void
}

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledHeaderContainer = styled.div`
  margin-top: ${convertUnit(20)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledTextContainer = styled(StyledHeaderContainer)`
  margin-top: ${convertUnit(63)};
`
const StyledFullImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`
const StyledImageContainer = styled.div`
  padding: ${convertUnit(10)};
  max-width: 350px;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
  margin-top: 0;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledParagraph = styled(Paragraph)`
  line-height: ${convertUnit(31)};
  margin: auto;
  white-space: pre-wrap;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`
const StyledImage = styled(Image)`
  height: 100%;
  max-width: 100%;
  max-height: 300px;
  margin: ${convertUnit(10)} ${convertUnit(5)};
  object-fit: contain;
  border-radius: 5px;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`
const StyledRotateIcon = styled.div`
  margin: auto 0;
  transform: rotate(90deg);

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin: auto;
  }
`

export default function HowItWorksContent({
  scrollTo,
}: LegalHowItWorksContentProps) {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regexPatterns = [
      {regex: /\*\*(.+?)\*\*/, style: {fontFamily: getFontFamily('bold')}},
      {regex: /\*(.+?)\*/, style: {fontFamily: getFontFamily('italic')}},
    ]

    return regexPatterns.map(({regex, style}) => ({
      pattern: regex,
      style,
      renderText: (text) => text.replace(regex, '$1'),
    }))
  }, [])

  const handleRenderHowItWorks = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:howItWorks')}
        </StyledTitle>
        <StyledSubTitle>{translate('policy:howItWorksWelcome')}</StyledSubTitle>
        <br />
        <Lottie animationData={animationData} />
        <br />
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:howItWorksDesc', {name: COMPANY_NAME})}
          </ParsedText>
        </StyledDescription>
      </StyledHeaderContainer>
    ),
    [patterns, translate],
  )

  const handleRenderFotoyu = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksFotoyu', {name: COMPANY_NAME})}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:howItWorksFotoyuDesc', {name: COMPANY_NAME})}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderFotoyuTechnology = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:fotoyuTech', {name: COMPANY_NAME})}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:fotoyuTechDesc', {name: COMPANY_NAME})}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderDataEncryption = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:dataEncryption')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:dataEncryptionDesc', {name: COMPANY_NAME})}
          </ParsedText>
        </StyledDescription>
        <StyledImage src={IMAGE_ASSET('howitworks', '1.png')} alt={'img'} />
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderFaceRecognition = useMemo(
    () => (
      <StyledTextContainer id="pengenalanWajah">
        <StyledSubTitle>{translate('policy:faceRecognition')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:faceRecognitionDesc')}
        </StyledDescription>
        <StyledFullImageContainer>
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '2.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:faceRecognitionImg_1')}
            </StyledParagraph>
          </StyledImageContainer>
          <Icon type="double-arrow-right" size={50} />
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '3.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:faceRecognitionImg_2', {
                name: COMPANY_NAME,
              })}
            </StyledParagraph>
          </StyledImageContainer>
        </StyledFullImageContainer>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:faceRecognitionDescBottom', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:faceRecognitionDescBottom', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderRoboyuAccuracy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksRoboyuAccuracy')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksRoboyuAccuracyDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howItWorksRoboyuAccuracyStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksRoboyuAccuracyStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:howItWorksRoboyuAccuracyDescBottom')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderLocationTracker = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:locationData')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:locationTrackerDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledFullImageContainer>
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '4.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:locationTrackerImg_1')}
            </StyledParagraph>
          </StyledImageContainer>
          <StyledRotateIcon>
            <Icon type="pause" size={50} />
          </StyledRotateIcon>
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '5.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:locationTrackerImg_2')}
            </StyledParagraph>
          </StyledImageContainer>
        </StyledFullImageContainer>
        <StyledDescription>
          {translate('policy:locationTrackerTips')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderSearchBib = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:howToSearchBIB')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:howToSearchBIBDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howToSearchBIBStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howToSearchBIBStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:howToSearchBIBDescBottom')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderFilter = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:filterImage')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:filterImageDesc')}
        </StyledDescription>
        <StyledImage src={IMAGE_ASSET('howitworks', '6.png')} alt={'img'} />
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderWatermark = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:watermark')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:watermarkDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:watermarkPrivacy')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:watermarkPrivacyDesc')}
        </StyledDescription>
        <StyledImage src={IMAGE_ASSET('howitworks', '7.png')} alt={'img'} />
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:watermarkPrivacyDescBottom', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:watermarkPaid')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:watermarkPaidDesc')}
        </StyledDescription>
        <StyledImage src={IMAGE_ASSET('howitworks', '8.png')} alt={'img'} />
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:watermarkPaidDescBottom', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderContentResolution = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksContentResolution')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:howItWorksContentResolutionDesc')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderCopyright = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksCopyright')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:howItWorksCopyrightDesc', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
          <StyledLink href="#" onClick={() => scrollTo(8)}>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, scrollTo, translate],
  )

  const handleRenderOwnership = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksOwnership')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksOwnershipDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksOwnershipCategory')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksOwnershipCategoryDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledBold>
              {translate('policy:howItWorksOwnershipSingle')}
            </StyledBold>
            <br />
            {translate('policy:howItWorksOwnershipSingleDesc')}
          </StyledListItem>
          <StyledListItem>
            <StyledBold>
              {translate('policy:howItWorksOwnershipGroup')}
            </StyledBold>
            <br />
            {translate('policy:howItWorksOwnershipGroupDesc')}
          </StyledListItem>
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksAutopost')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksAutopostDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderCompetition = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksPhotoCompetition')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksPhotoCompetitionDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderFeed = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksHomefeed')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksHomefeedDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderEasySignUp = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:howToRegisterEasy')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:howToRegisterEasyDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howToRegisterEasyStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howToRegisterEasyStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderRegister = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:howToRegister')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 9}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:howToRegister', {
                  context: `${index + 1}`,
                  age: AUTH_USER_MIN_AGE,
                })}
              </ParsedText>
              {index === 0 && (
                <StyledLink href={WEBSITE_NAME}>{WEBSITE_NAME}</StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderFavoriteContent = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksContentFavorite')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksContentFavoriteDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howItWorksContentFavoriteStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksContentFavoriteStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleRenderHowItWorks}
      {handleRenderFotoyu}
      {handleRenderFotoyuTechnology}
      {handleRenderDataEncryption}
      {handleRenderFaceRecognition}
      {handleRenderRoboyuAccuracy}
      {handleRenderLocationTracker}
      {handleRenderSearchBib}
      {handleRenderFilter}
      {handleRenderWatermark}
      {handleRenderContentResolution}
      <LegalServiceFee />
      {handleRenderCopyright}
      {handleRenderOwnership}
      {handleRenderCompetition}
      {handleRenderEasySignUp}
      {handleRenderRegister}
      {handleRenderFeed}
      <LegalLikeAndComment scrollTo={scrollTo} />
      <LegalSendToFaceContent />
      {handleRenderFavoriteContent}
      <LegalFototreeContent />
      <LegalSellVideoContent scrollTo={scrollTo} />
    </>
  )
}
