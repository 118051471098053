import exifr from 'exifr'
import FileResizer from 'react-image-file-resizer'
import {
  GIFT_SHOP_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
  GIFT_SHOP_RESEND_VIDEO_MAX_DURATION,
  GIFT_SHOP_RESEND_VIDEO_MAX_RESOLUTION,
  GIFT_SHOP_RESEND_VIDEO_MAX_SIZE,
  GIFT_SHOP_SPECIAL_IMAGE_UPLOAD_TYPE,
  GIFT_SHOP_UPLOAD_COMPRESS_LIMIT,
  GIFT_SHOP_UPLOAD_COMPRESS_QUALITY,
  GIFT_SHOP_UPLOAD_MAX_RESOLUTION,
  GIFT_SHOP_UPLOAD_MAX_SIZE,
  GIFT_SHOP_UPLOAD_TYPE,
  GIFT_SHOP_UPLOAD_VIDEO_MAX_RESOLUTION,
  GIFT_SHOP_UPLOAD_VIDEO_MAX_SECONDS,
  GIFT_SHOP_UPLOAD_VIDEO_MAX_SIZE,
  GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_MAX_SIZE,
  GIFT_SHOP_UPLOAD_VIDEO_TYPE,
} from 'consts'
import {translate} from 'i18n'
import {
  ImageExtensionType,
  GiftshopUploadContentData,
  ImageExif,
  GiftShopUploadValidationType,
  GiftShopUploadImageParams,
  VideoExtensionType,
  GiftShopUploadVideoSendToFaceFormData,
  GiftShopUploadVideoPreviewParams,
  LayoutDimension,
} from 'types'
import {
  blobToFile,
  getFileImageDimension,
  getAndSupplyFileMime,
  getUniqueId,
  urlToBlob,
  getVideoMetadata,
  convertFileSizeFromByte,
} from 'utils'
import {
  ConversionResultData,
  convertImage,
} from 'utils/lib/heic2any/heic2anyHelper'
import {showSnackbar} from '../../components'

const resizeFile = (file: File, width: number, height: number) =>
  new Promise((resolve, reject) => {
    try {
      FileResizer.imageFileResizer(
        file,
        width,
        height,
        'JPEG',
        GIFT_SHOP_UPLOAD_COMPRESS_QUALITY,
        0,
        (uri) => {
          resolve(uri)
        },
      )
    } catch (e) {
      reject()
    }
  })

async function convertSpecialImage({
  result,
  name,
}: {
  result: string | ArrayBuffer
  name: string
}) {
  return new Promise<ConversionResultData>((resolve, reject) => {
    convertImage(result, GIFT_SHOP_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE, name)
      .then((conversionResult) => {
        resolve(conversionResult)
      })
      .catch(() => reject())
  })
}

function getCompressedDimension({
  file,
  compressed,
}: {
  file: File
  compressed: boolean
}) {
  return new Promise<LayoutDimension>((resolve, reject) => {
    getFileImageDimension(file)
      .then((fileDimension) => {
        let compressedWidth = 0
        let compressedHeight = 0

        if (fileDimension && compressed) {
          const {width, height} = fileDimension
          const limit = GIFT_SHOP_UPLOAD_COMPRESS_LIMIT
          const scale = Math.min(1, limit / Math.max(height, width))
          compressedWidth = Math.round(width * scale)
          compressedHeight = Math.round(height * scale)
        }

        resolve({
          width: compressedWidth,
          height: compressedHeight,
        })
      })
      .catch(() => {
        reject()
      })
  })
}

async function compressContent({
  compressedWidth,
  compressedHeight,
  file,
}: {
  compressedWidth: number
  compressedHeight: number
  file: File
}) {
  let result:
    | {
        blob: Blob
        file: File
        uri: string
      }
    | undefined

  const compressedImageURI = (await resizeFile(
    file,
    compressedWidth,
    compressedHeight,
  )) as string
  const compressedImageBlob = await urlToBlob(compressedImageURI)
  const compressedImageFile = blobToFile(
    compressedImageBlob,
    file.name,
    file.lastModified,
  )

  if (compressedImageURI && compressedImageBlob && compressedImageFile) {
    result = {
      blob: compressedImageBlob,
      file: compressedImageFile,
      uri: compressedImageURI,
    }
  }

  return result
}

async function handleLoadCommonImage({
  result,
  file,
  targetFile,
  compress,
  maxSize,
  checkResolution,
  onFailCheckSize,
  onFailCheckResolution,
}: {
  result: string | ArrayBuffer | null | undefined
  file: File
  targetFile: FileReader | null
  compress: boolean
  maxSize: number
  checkResolution: boolean
  onFailCheckSize: () => void
  onFailCheckResolution: () => void
}) {
  const imageBuffer = targetFile?.result

  const detectedType = getAndSupplyFileMime(GIFT_SHOP_UPLOAD_TYPE, file)

  if (GIFT_SHOP_SPECIAL_IMAGE_UPLOAD_TYPE.includes(detectedType) && result) {
    try {
      const {
        blob: convertedBlob,
        file: convertedFile,
      } = await convertSpecialImage({result, name: file.name})

      result = URL.createObjectURL(convertedBlob)
      file = convertedFile
    } catch (e) {
      throw Error()
    }
  }

  const {size} = file

  if (!(typeof result === 'string' && imageBuffer instanceof ArrayBuffer)) {
    throw Error()
  }

  let compressedWidth = 0
  let compressedHeight = 0

  try {
    const {width, height} = await getCompressedDimension({
      file,
      compressed: compress,
    })
    compressedWidth = width
    compressedHeight = height
  } catch (e) {
    throw Error()
  }

  let compressedImageData:
    | {
        blob: Blob
        file: File
        uri: string
      }
    | undefined

  try {
    compressedImageData = await compressContent({
      compressedWidth,
      compressedHeight,
      file,
    })
  } catch (e) {
    throw Error()
  }

  if (!compressedImageData) {
    throw Error()
  }

  const {
    file: compressedImageFile,
    uri: compressedImageURI,
  } = compressedImageData

  if (size > maxSize) {
    onFailCheckSize()
    return
  }

  let dimension: LayoutDimension | null = null

  try {
    dimension = await getFileImageDimension(
      compress ? compressedImageFile : file,
    )
  } catch (e) {
    throw Error()
  }

  if (dimension) {
    const {width, height} = dimension

    if (checkResolution && width * height > GIFT_SHOP_UPLOAD_MAX_RESOLUTION) {
      onFailCheckResolution()
      return null
    }

    const exif = (await exifr.parse(imageBuffer)) as ImageExif | undefined
    const {latitude, longitude} = exif || {}
    const id = getUniqueId()
    const image: GiftshopUploadContentData = {
      id,
      file: compress ? compressedImageFile : file,
      src: compress ? compressedImageURI : result,
      width: compress ? compressedWidth : width,
      height: compress ? compressedHeight : height,
      exif,
      extension:
        (file.type.split('/').length > 1 &&
          (file.type.split('/')[1] as ImageExtensionType)) ||
        'png',
      size: compress ? compressedImageFile.size : size,
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
    }

    return image
  }

  return null
}
export async function handleGiftshopLoadFile({
  event: {target},
  compress = false,
  checkResolution = true,
  file,
}: GiftShopUploadImageParams): Promise<GiftshopUploadContentData | null> {
  return new Promise((resolve, reject) => {
    const result = target?.result
    const reader = new FileReader()
    const validation: GiftShopUploadValidationType = checkResolution
      ? 'DEFAULT'
      : 'SIZE_ONLY'

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = async ({target: targetFile}) => {
      try {
        const resolvedImage = await handleLoadCommonImage({
          file,
          maxSize: GIFT_SHOP_UPLOAD_MAX_SIZE,
          onFailCheckResolution: () => {
            showSnackbar(
              translate('giftShop:unsatisfiedUploadFormat', {
                context: validation,
              }),
            )
          },
          onFailCheckSize: () => {
            showSnackbar(
              translate('giftShop:unsatisfiedUploadFormat', {
                context: validation,
              }),
            )
          },
          result,
          targetFile,
          checkResolution,
          compress,
        })

        // cases that can be intentionally failed (which are existing test cases) return null
        resolve(resolvedImage ?? null)
      } catch (e) {
        reject(e)
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export function handleGiftShopLoadVideoFile({
  event: {target},
  file,
  checkVideoResend,
}: GiftShopUploadImageParams): Promise<GiftshopUploadContentData | null> {
  return new Promise((resolve, reject) => {
    const result = target?.result
    const maxResolution = checkVideoResend
      ? GIFT_SHOP_RESEND_VIDEO_MAX_RESOLUTION
      : GIFT_SHOP_UPLOAD_VIDEO_MAX_RESOLUTION
    const maxDuration = checkVideoResend
      ? GIFT_SHOP_RESEND_VIDEO_MAX_DURATION
      : GIFT_SHOP_UPLOAD_VIDEO_MAX_SECONDS
    const maxSize = checkVideoResend
      ? GIFT_SHOP_RESEND_VIDEO_MAX_SIZE
      : GIFT_SHOP_UPLOAD_VIDEO_MAX_SIZE
    const video = document.createElement('video')
    const reader = new FileReader()
    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = async ({target: targetFile}) => {
      try {
        const videoBuffer = targetFile?.result
        const {size, type} = file

        if (result) {
          video.src = result as string
        }

        if (
          !(typeof result === 'string' && videoBuffer instanceof ArrayBuffer)
        ) {
          throw Error()
        }

        let metadata: {
          width: number
          height: number
          duration: number
        } | null = null

        try {
          metadata = await getVideoMetadata(video)
        } catch (e) {
          throw Error()
        }

        if (size > maxSize) {
          showSnackbar(
            translate('giftShop:videoUploadErrorMaxSize', {
              maxSize: convertFileSizeFromByte(maxSize, 'MB'),
            }),
          )
          resolve(null)
          return
        }

        if (metadata) {
          const {width, height, duration} = metadata
          if (duration > maxDuration) {
            showSnackbar(
              translate('giftShop:videoUploadErrorMaxDuration', {
                duration: Math.floor(maxDuration),
              }),
            )
            resolve(null)
            return
          }
          if (width > maxResolution || height > maxResolution) {
            showSnackbar(
              translate('giftShop:videoUploadErrorMaxResolution', {
                maxRes: maxResolution,
              }),
            )
            resolve(null)
            return
          }
          const id = getUniqueId()
          const images: GiftshopUploadContentData = {
            id,
            file,
            src: result,
            width,
            height,
            extension:
              (type.split('/').length > 1 &&
                (type.split('/')[1] as VideoExtensionType)) ||
              'mp4',
            size,
            duration: Math.floor(duration),
          }
          resolve(images)
          return
        }
        resolve(null)
      } catch (e) {
        reject(e)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

export function handleGiftShopLoadVideoPreviewFile({
  event: {target},
  file,
  sendToFace,
}: GiftShopUploadVideoPreviewParams): Promise<GiftshopUploadContentData | null> {
  return new Promise((resolve, reject) => {
    const result = target?.result
    const reader = new FileReader()
    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = ({target: targetFile}) => {
      try {
        const videoBuffer = targetFile?.result
        const {size, type} = file

        if (
          !(typeof result === 'string' && videoBuffer instanceof ArrayBuffer)
        ) {
          reject()
          return
        }
        const {width, height} = sendToFace

        const id = getUniqueId()
        const images: GiftshopUploadContentData = {
          id,
          file,
          src: result,
          width,
          height,
          extension:
            (type.split('/').length > 1 &&
              (type.split('/')[1] as VideoExtensionType)) ||
            'zip',
          size,
          videoFace: sendToFace,
        }

        resolve(images)
      } catch (e) {
        reject()
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

export async function handleGiftshopReadFile(
  file: File,
  compress?: boolean,
  checkResolution?: boolean,
  checkVideoResend?: boolean,
): Promise<GiftshopUploadContentData | null> {
  function load<T>(
    func: Promise<T>,
    onSuccess: (result: T) => void,
    onFail: (result: T) => void,
  ) {
    func.then((result) => onSuccess(result)).catch((e) => onFail(e))
  }

  let result: GiftshopUploadContentData | null = null
  try {
    result = await new Promise<GiftshopUploadContentData | null>(
      (resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = () => resolve(null)
        reader.onabort = () => resolve(null)
        reader.onload = (event) => {
          load(
            GIFT_SHOP_UPLOAD_VIDEO_TYPE.includes(file.type)
              ? handleGiftShopLoadVideoFile({
                  event,
                  file,
                  checkVideoResend,
                })
              : handleGiftshopLoadFile({
                  event,
                  file,
                  compress,
                  checkResolution,
                }),
            resolve,
            reject,
          )
        }

        reader.readAsDataURL(file)
      },
    )
  } catch (e) {
    result = null
  }

  return result
}

export function handleGiftshopReadFileVideoPreview(
  file: File,
  sendToFace: GiftShopUploadVideoSendToFaceFormData,
): Promise<GiftshopUploadContentData | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = (event) => {
      handleGiftShopLoadVideoPreviewFile({
        event,
        file,
        sendToFace,
      })
        .then((result) => resolve(result))
        .catch(() => reject())
    }
    reader.readAsDataURL(file)
  })
}

export async function handleGiftshopLoadVideoFaceFile({
  event: {target},
  file,
}: GiftShopUploadImageParams): Promise<GiftShopUploadVideoSendToFaceFormData | null> {
  return new Promise((resolve, reject) => {
    const result = target?.result
    const reader = new FileReader()

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = async ({target: targetFile}) => {
      try {
        const resolvedImage = await handleLoadCommonImage({
          file,
          maxSize: GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_MAX_SIZE,
          onFailCheckResolution() {
            showSnackbar(translate('giftShop:unsatisfiedUploadFormat', {}))
          },
          onFailCheckSize() {
            showSnackbar(
              translate('giftShop:videoUploadSendToFaceErrorMaxSize', {
                maxSize: convertFileSizeFromByte(
                  GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_MAX_SIZE,
                  'MB',
                ),
              }),
            )
          },
          result,
          targetFile,
          checkResolution: true,
          compress: true,
        })

        let output: GiftShopUploadVideoSendToFaceFormData | null = null

        if (resolvedImage) {
          output = {
            extension:
              (file.type.split('/').length > 1 &&
                (file.type.split('/')[1] as ImageExtensionType)) ||
              'png',
            file: resolvedImage.file,
            filename: resolvedImage.file.name,
            height: resolvedImage.height,
            size: resolvedImage.file.size,
            src: resolvedImage.src,
            width: resolvedImage.width,
          }
        }

        resolve(output)
      } catch (e) {
        reject(e)
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export function handleGiftshopReadVideoFaceFile(
  file: File,
): Promise<GiftShopUploadVideoSendToFaceFormData | null> {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onerror = () => resolve(null)
    reader.onabort = () => resolve(null)
    reader.onload = (event) => {
      resolve(
        handleGiftshopLoadVideoFaceFile({
          event,
          file,
        }),
      )
    }

    reader.readAsDataURL(file)
  })
}
