import React, {useCallback, useState} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {ForkygramPostData, ObjectState} from 'types'
import {FORKYGRAM_FEED_QUERY_LIMIT} from 'consts'
import {requestData} from 'services'
import {FlatlistLazy} from 'common/components'
import {GiftShopFeedEmpty} from '../Empty'
import {GiftShopFeedContent} from '../Content'

interface GiftShopFeedTabAllProps {
  stateEdit: ObjectState<ForkygramPostData | undefined>
  onVisibilityIndexChange(index?: number): void
  visibilityIndex: number
  stateVideoPause: ObjectState<boolean>
  stateMute: ObjectState<boolean>
  setVideoPlaying?: (state: React.SetStateAction<boolean>) => void
}
export default function GiftShopFeedTabAll({
  stateEdit,
  onVisibilityIndexChange,
  visibilityIndex,
  stateMute,
  stateVideoPause,
  setVideoPlaying,
}: GiftShopFeedTabAllProps) {
  const setEdit = stateEdit[1]
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const setData = stateData[1]
  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('forkygram_get_feeds', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit},
      })
      if (typeof response !== 'string' && response.status === 200) {
        /** after change tab, there's an issue if the current active tab's very first content is video,
         * the video is paused while BGM keeps playing.
         * this checks if the very first content is video, it will be played and pauses BGM. */
        if (page === 1 && response.data.result[0].content_type === 'video') {
          setVideoPlaying && setVideoPlaying(true)
        }
        return response.data.result
      }
      return []
    },
    [setVideoPlaying],
  )
  const handleKeyExtractor = useCallback(
    ({id, loop_number}: ForkygramPostData) => `${id}[${loop_number}]`,
    [],
  )
  const handleFollow = useCallback(
    (item: ForkygramPostData) => {
      setData((prev) =>
        prev.map((x) =>
          x.member_id === item.member_id ? {...x, is_followed: true} : x,
        ),
      )
    },
    [setData],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramPostData, index: number) => (
      <GiftShopFeedContent
        index={index}
        visibilityIndex={visibilityIndex}
        data={item}
        stateVideoPause={stateVideoPause}
        stateMute={stateMute}
        setVideoPlaying={setVideoPlaying}
        onBlocked={(blockedId) =>
          setData((prev) => prev.filter((post) => post.member_id !== blockedId))
        }
        onReported={(reportedId) =>
          setData((prev) => prev.filter((post) => post.id !== reportedId))
        }
        onDeleted={(deletedId) =>
          setData((prev) => prev.filter((post) => post.id !== deletedId))
        }
        onEdited={() => setEdit(item)}
        onFollow={() => handleFollow(item)}
      />
    ),
    [
      handleFollow,
      setData,
      setEdit,
      setVideoPlaying,
      stateMute,
      stateVideoPause,
      visibilityIndex,
    ],
  )

  const handleRenderList = useCallback(
    ({height, width}: Size) => (
      <FlatlistLazy
        height={height}
        width={width}
        cache="feed"
        loadData={handleLoadData}
        limitPerPage={FORKYGRAM_FEED_QUERY_LIMIT}
        renderItem={handleRenderItem}
        keyExtractor={handleKeyExtractor}
        onVisibilityIndexChange={onVisibilityIndexChange}
        stateData={stateData}
        scrollbar={false}
        scrollSnapType="y mandatory"
        listEmptyElement={<GiftShopFeedEmpty />}
      />
    ),
    [
      handleLoadData,
      handleRenderItem,
      handleKeyExtractor,
      onVisibilityIndexChange,
      stateData,
    ],
  )
  return <AutoSizer>{handleRenderList}</AutoSizer>
}
