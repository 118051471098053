import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {ThemeColor} from 'themes'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeHostWarningBoxProps} from './TreeHostWarningBoxProps'

interface StyledInfoContainerProps {
  bgColor: ThemeColor
}

const StyledInfoContainer = styled.div<StyledInfoContainerProps>`
  ${({theme, bgColor}) => ({
    backgroundColor: theme[bgColor],
  })}
  border-radius: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${convertUnit(42)};
  padding: ${convertUnit(8)} 0;
  justify-content: center;
  margin: 0 ${convertUnit(20)};
  text-align: center;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(37)};
  }
`

const StyledIconInfo = styled(Icon)`
  align-self: 'center';
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  align-self: center;
  margin-left: ${convertUnit(16)};
`

export default function TreeHostWarningBox({
  backgroundColor,
  fontColor,
  label,
}: TreeHostWarningBoxProps) {
  return (
    <StyledInfoContainer bgColor={backgroundColor}>
      <StyledIconInfo type="info" size={16} color={fontColor} />
      <StyledParagraph fontSize="s" fontWeight="medium" color={fontColor}>
        {label}
      </StyledParagraph>
    </StyledInfoContainer>
  )
}
