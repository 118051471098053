import {lazy} from 'react'

const ForkygramUploadContentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'ForkygramUploadContent' */ './ForkygramUploadContentScreen'
    ),
)

export {ForkygramUploadContentScreen}
export * from './ForkygramUploadContentScreenParams'
