import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentDoubleBookedModalProps} from './PaymentDoubleBookedModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(290)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  justify-content: center;
  align-items: center;
  text-align: center;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function PaymentDoubleBookedModal({
  visible,
  onContinue,
  toggleModal,
}: PaymentDoubleBookedModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate('transaction:cartDoubleBookedTitle')}
      </Paragraph>
      <Paragraph fontSize="l">
        {translate('transaction:cartDoubleBookedDescription')}
      </Paragraph>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:no')}
          backgroundHoverColor="white_3"
          backgroundColor="white_2"
          color="primary_5"
          onClick={toggleModal}
        />
        <StyledButton
          label={translate('global:yes')}
          backgroundColor="primary_5"
          color="white_1"
          onClick={onContinue}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
