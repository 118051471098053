import React from 'react'
import { Paragraph } from 'common/components'
import { FirebaseMessageRoomItemPayload } from 'types'

export interface GiftShopMessageRoomContentItemChatProps {
  payload: FirebaseMessageRoomItemPayload['chat']
}

export default function GiftShopMessageRoomContentItemChat({
  payload: {message},
}: GiftShopMessageRoomContentItemChatProps) {
  return <Paragraph fontSize="m" >{message}</Paragraph>
}
