import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {
  FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE,
  FORKYGRAM_UPLOAD_TYPE,
  TREE_UPLOAD_VIDEO_TYPE,
} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder, showSnackbar} from 'utils'
import {DragDrop, Icon, ConfirmationModal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeEditUploadVideoProps} from './TreeEditFormProps'
import {FullscreenVideoIntroModal} from '../Modal'

const StyledVideo = styled(ReactHlsPlayer)`
  width: ${convertUnit(113)};
  height: ${convertUnit(136)};
  object-fit: scale-down;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.gray_1};
  cursor: pointer;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledDragContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  min-height: ${convertUnit(165)};
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`

const StyledUl = styled.ul`
  margin: 0;
`

const StyledConfirmationModal = styled(ConfirmationModal)`
  display: flex;
`

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledIconContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${convertUnit(40)};
  left: ${convertUnit(30)};
  cursor: pointer;
`

export default function TreeEditProfileVideo({
  stateTreeInfo,
  onFileAccept,
  onDeleteVideo,
}: TreeEditUploadVideoProps) {
  const [treeInfo, setTreeInfo] = stateTreeInfo
  const {video_intro_url} = treeInfo
  const {translate} = useTranslation()
  const stateVideoModal = useState(false)
  const setVideoModal = stateVideoModal[1]
  const [confirmationModal, setConfirmationModal] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleCheckVideo = useCallback(
    (inputFile: File) => {
      if (inputFile) {
        let {type} = inputFile
        const {size, name} = inputFile

        if (type === '') {
          type = name.substring(name.lastIndexOf('.') + 1).toLowerCase() ?? ''
          FORKYGRAM_UPLOAD_TYPE.some((currentType) => {
            const typeMatchesWithExtension = currentType.includes(type)
            if (typeMatchesWithExtension) type = currentType
            return typeMatchesWithExtension
          })
        }

        if (!TREE_UPLOAD_VIDEO_TYPE.includes(type)) {
          showSnackbar(translate('tree:plantFototreeVideoInvalidFormat'))
          return false
        }

        if (
          type.startsWith('video') &&
          size > FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE
        ) {
          showSnackbar(translate('forkygram:uploadMaxVideoSizeMessage'))
          return false
        }

        return true
      }
      return false
    },
    [translate],
  )

  const handleRenderContent = useMemo(
    () =>
      video_intro_url ? (
        <StyledVideoContainer>
          <StyledIconContainer>
            <Icon type="play" size={40} color="white_1" />
          </StyledIconContainer>
          <StyledVideo
            src={video_intro_url}
            playerRef={videoRef}
            autoPlay={false}
            onClick={() => setVideoModal(true)}
          />
          <Paragraph
            fontWeight="bold"
            color="primary_5"
            onClick={() => setConfirmationModal(true)}
            style={{cursor: 'pointer'}}>
            {translate('global:remove')}
          </Paragraph>
        </StyledVideoContainer>
      ) : (
        <StyledDragContainer>
          <DragDrop
            icon="upload"
            accept={TREE_UPLOAD_VIDEO_TYPE}
            header={
              <Paragraph fontWeight="medium" color="gray_5">
                {translate('global:selectVideo')}
              </Paragraph>
            }
            description={
              <StyledUl>
                <li>
                  <Paragraph fontSize="xs" color="gray_5">
                    {translate('tree:plantFototreeVideoExtension')}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph fontSize="xs" color="gray_5">
                    {translate('tree:plantFototreeVideoMaxWidth')}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph fontSize="xs" color="gray_5">
                    {translate('tree:plantFototreeVideoMaxSize')}
                  </Paragraph>
                </li>
              </StyledUl>
            }
            multiple={false}
            onDrop={(acceptedFiles) => {
              handleCheckVideo(acceptedFiles[0])
            }}
            onDropRejected={() => {
              showSnackbar(translate('tree:plantFototreeVideoInvalidFormat'))
            }}
            onDropAccepted={(file) => onFileAccept && onFileAccept(file[0])}
          />
        </StyledDragContainer>
      ),
    [handleCheckVideo, onFileAccept, setVideoModal, translate, video_intro_url],
  )

  const handleRenderVideo = useMemo(
    () => (
      <FullscreenVideoIntroModal
        stateModal={stateVideoModal}
        src={video_intro_url}
      />
    ),
    [stateVideoModal, video_intro_url],
  )

  const handleRenderConfirmationModal = useMemo(
    () => (
      <StyledConfirmationModal
        title={translate('global:delete')}
        confirmText={translate('global:yesImSure')}
        cancelText={translate('global:stay')}
        text={translate('tree:deleteVideoConfirmation')}
        toggleModal={() => setConfirmationModal((prev) => !prev)}
        onConfirm={() => {
          onDeleteVideo()
          setTreeInfo((prev) => ({...prev, video_intro_url: ''}))
        }}
        visible={confirmationModal}
      />
    ),
    [translate, confirmationModal, onDeleteVideo, setTreeInfo],
  )

  return (
    <StyledContainer>
      {handleRenderConfirmationModal}
      {handleRenderVideo}
      <Paragraph fontSize="m" color="gray_5">
        {translate('global:uploadVideo')}
      </Paragraph>
      {handleRenderContent}
    </StyledContainer>
  )
}
