import React from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import animationData from '../../../assets/lottie/activity_indicator.json'
import {ActivityIndicator} from '../ActivityIndicator'
import {ListIndicatorProps} from './ListIndicatorProps'

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

const StyledInitializeContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto;
`

const StyledLottie = styled(Lottie)`
  height: ${convertUnit(100)};
  width: ${convertUnit(100)};
`

export default function ListIndicator({
  status,
  listInitializeElement,
  listEmptyElement,
  listLoadingElement,
  listLoadingElementStyle,
  listEndPageElement,
}: ListIndicatorProps) {
  if (status === 'initialize') {
    return (
      listInitializeElement || (
        <StyledInitializeContainer>
          <StyledLottie animationData={animationData} />
        </StyledInitializeContainer>
      )
    )
  }
  if (status === 'empty' && listEmptyElement) {
    return listEmptyElement
  }
  if (status === 'end-page' && listEndPageElement) {
    return listEndPageElement
  }
  if (status === 'loading') {
    return (
      <StyledLoadingContainer style={listLoadingElementStyle}>
        {listLoadingElement || <ActivityIndicator iconColor="primary_5" />}
      </StyledLoadingContainer>
    )
  }

  return null
}
