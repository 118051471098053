const CONVERSION_VALUE : number = 0.0625 // 1px = 0.0625rem
const CONVERSION_UNIT : string = 'rem'
/**
 * @param  {number} convertFrom
 * @param  {string} dpi?
 * @return {string}
 */
const convertUnit = (
  convertFrom: number,
  dpi?: string,
): string => {
  if (!dpi) dpi = 'mdpi'

  const result : number = convertFrom * CONVERSION_VALUE
  return `${result}${CONVERSION_UNIT}`
}

export default convertUnit;
