import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationPromotionHostAdminCancelProps {
  payload: FirebaseMessageRoomItemPayload['notification_promotion_host_admin_cancel']
}

export default function GiftShopMessageRoomContentItemNotificationPromotionHostAdminCancel({
  payload: {host_tag},
}: GiftShopMessageRoomContentItemNotificationPromotionHostAdminCancelProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationPromotionHostCancel', {host_tag})}
    </Paragraph>
  )
}
