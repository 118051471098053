import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  ROBOYU_SENSITIVITY_LEVEL_DEFAULT,
  ROBOYU_SENSITIVITY_LEVEL_MAX,
  ROBOYU_SENSITIVITY_LEVEL_MIN,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopSearchByCreatorNotFound, GiftShopTemplateMasonry} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {
  showSnackbar,
  useDidMount,
  useDidUpdate,
  useHistory,
  useLocation,
  useQueryParamValue,
} from 'utils'
import {useWindowMode} from 'windows'
import {Image, InputRange, Paragraph, Rating} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateProfilePhotoModal} from 'pages/template'

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${convertUnit(8)};
  padding-right: ${convertUnit(20)};
  padding-bottom: ${convertUnit(20)};
  padding-left: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${convertUnit(16)};
`

const StyledProfileInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledProfilePhotoContainer = styled.div`
  position: relative;
  cursor: pointer;
`

const StyledProfilePhoto = styled(Image)`
  border-radius: 50%;
  width: ${convertUnit(76)};
  object-fit: cover;
  aspect-ratio: 1;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(20)};
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledEmptyImage = styled(Image)`
  object-fit: contain;
  height: ${convertUnit(130)};
  max-width: 100%;
`

const StyledAccuracyDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSliderContainer = styled.div`
  width: ${convertUnit(600)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
  }
`

const StyledSLiderDescInfo = styled(Paragraph)`
  white-space: nowrap;
`

const StyledProfileName = styled(Paragraph)`
  cursor: pointer;
`

export default function GiftShopSearchByCreatorCreationContent() {
  const {translate} = useTranslation()
  const emptyImg = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const defaultProfileImg = IMAGE_ASSET('giftshop', 'users/default.png')
  const location = useLocation('giftshop_search_by_creator_creation_content')
  const {
    photo = defaultProfileImg,
    bio,
    shouldFetchData,
    creatorRating,
    ratingCount,
  } = location.state
  const {creator_id: creatorId, username: paramUsername} = location.query
  const [biodata, setBiodata] = useState(bio)
  const [profilePhoto, setProfilePhoto] = useState(photo)
  const stateData = useState<ReadonlyArray<GiftShopContentData>>([])
  const data = stateData[0]
  const history = useHistory()
  const {accuracy} = useQueryParamValue(['accuracy'])
  const stateRangeValue = useState(
    accuracy ? Number(accuracy) : ROBOYU_SENSITIVITY_LEVEL_DEFAULT,
  )
  const sensitivity = stateRangeValue[0]
  const mode = useWindowMode()
  const [previewModal, setPreviewModal] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [rating, setRating] = useState({
    value: creatorRating,
    count: ratingCount,
  })
  const {username: selfUsername} = useSelector('user')!
  const [username, setUsername] = useState(paramUsername)

  const handleFetchCreator = useCallback(() => {
    requestData('giftshop_get_explore_creator', {
      params: {
        page: 1,
        limit: 1,
        creator_id: creatorId,
        q: username,
      },
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          const response = result[0]
          setIsNotFound(result.length < 1)
          setBiodata(response.biodata)
          paramUsername && setProfilePhoto(response.profile_url)
          setRating({
            count: response.creator_rating_count,
            value: response.creator_rating,
          })
          username !== response.username && setUsername(response.username)
        } else {
          setIsNotFound(true)
        }
      },
    })
  }, [creatorId, paramUsername, username])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('giftshop_post_explore', {
        useDefaultMessage: true,
        actionType: 'fetch',
        data: {
          page,
          limit,
          is_filter: true,
          creator_ids: [creatorId],
          sensitivity_level: sensitivity,
          type: 'all',
        },
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [creatorId, sensitivity],
  )

  const handleRenderInputRangeLeftDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'left'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'white_1'}>
          {translate('giftShop:roboyuAccuracyMediumDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledSLiderDescInfo fontWeight="medium" color="gray_5">
            {translate('global:medium')}
          </StyledSLiderDescInfo>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  const handleRenderInputRangeRightDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'right'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'white_1'}>
          {translate('giftShop:roboyuAccuracyHighDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledSLiderDescInfo fontWeight="medium" color="gray_5">
            {translate('global:high')}
          </StyledSLiderDescInfo>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  const handleRenderInputRange = useMemo(
    () => (
      <StyledSliderContainer>
        <InputRange
          showTick
          darkMode
          stateValue={stateRangeValue}
          min={ROBOYU_SENSITIVITY_LEVEL_MIN}
          max={ROBOYU_SENSITIVITY_LEVEL_MAX}
          leftDesc={handleRenderInputRangeLeftDesc}
          rightDesc={handleRenderInputRangeRightDesc}
          onSlide={() =>
            showSnackbar(
              translate('giftShop:roboyuAccuracySuccessSnackbar_content'),
            )
          }
          descPosition="side"
        />
      </StyledSliderContainer>
    ),
    [
      handleRenderInputRangeLeftDesc,
      handleRenderInputRangeRightDesc,
      stateRangeValue,
      translate,
    ],
  )

  const handleRenderProfileInfo = useMemo(
    () => (
      <StyledProfileInfoContainer>
        <StyledProfileName
          fontSize="l"
          fontWeight="bold"
          color="white_1"
          onClick={() =>
            history.push(
              'giftshop_profile',
              {self: selfUsername === username},
              username || paramUsername,
            )
          }>
          {username || paramUsername}
        </StyledProfileName>
        <Rating
          value={rating.value}
          valueColor="white_5"
          ratingCount={rating.count}
          countColor="gray_1"
        />
        {biodata ? <Paragraph color="white_4">{biodata}</Paragraph> : <></>}
      </StyledProfileInfoContainer>
    ),
    [biodata, history, paramUsername, rating, selfUsername, username],
  )

  const handleRenderImageModal = useMemo(
    () =>
      previewModal && (
        <TemplateProfilePhotoModal
          src={photo}
          toggleModal={() => setPreviewModal((prev) => !prev)}
        />
      ),
    [photo, previewModal],
  )

  const handleRenderProfileSection = useMemo(
    () => (
      <StyledProfileContainer>
        <StyledProfilePhotoContainer onClick={() => setPreviewModal(true)}>
          <StyledProfilePhoto src={profilePhoto} alt={username} />
        </StyledProfilePhotoContainer>
        {handleRenderProfileInfo}
      </StyledProfileContainer>
    ),
    [handleRenderProfileInfo, profilePhoto, username],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        {handleRenderInputRange}
        {handleRenderProfileSection}
      </StyledHeaderContainer>
    ),
    [handleRenderInputRange, handleRenderProfileSection],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <StyledEmptyImage src={emptyImg} alt="" />
        <Paragraph fontSize="m" fontWeight="bold" color="gray_5">
          {translate('tree:eventBIBEmptyMessageFirstHalf')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_5">
          {translate('tree:eventBIBEmptyMessageSecondHalf')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [emptyImg, translate],
  )

  useDidMount(() => {
    if (shouldFetchData || paramUsername) handleFetchCreator()
  })

  useDidUpdate(() => {
    const {pathname} = window.location
    window.history.replaceState(
      null,
      '',
      `${pathname}?creator_id=${creatorId}&username=${username}&accuracy=${sensitivity}`,
    )
  }, [sensitivity])

  return isNotFound ? (
    <GiftShopSearchByCreatorNotFound />
  ) : (
    <>
      {handleRenderImageModal}
      <GiftShopTemplateMasonry
        showResBadge
        search={sensitivity.toString()}
        stateData={stateData}
        loadData={handleLoadData}
        listEmptyElement={handleRenderEmpty}
        listHeaderElement={handleRenderHeader}
        onClickItem={(item) =>
          history.push('giftshop_search_by_creator_creation_content_detail', {
            data: [...data],
            selectedItemId: item.content_id,
            creatorId,
          })
        }
      />
    </>
  )
}
