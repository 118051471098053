import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm, UseFormMethods} from 'react-hook-form'
import {
  HOST_REGISTRATION_MAX_DOCUMENT_SIZE,
  HOST_REGISTRATION_DOCUMENT_TYPE,
  HOST_BANK_NUMBER_MIN_LENGTH,
  HOST_COMPANY_MAX_LENGTH,
  HOST_ACCOUNT_HOLDER_MAX_LENGTH,
  HOST_BANK_NUMBER_MAX_LENGTH,
  REGEX_ALPHABET_AND_SPACE,
  HOST_COMPANY_MIN_LENGTH,
  HOST_REGISTER_COUNTRY,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopLocationModal} from 'pages'
import {requestData} from 'services'
import {TreeHostRegistrationForm} from 'types'
import {
  handleUploadSingleFileToWasabi,
  showSnackbar,
  useDidMount,
  useHistory,
} from 'utils'
import {Button, Icon, ModalLoading, Paragraph} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeRegistrationProcessModal} from '../RegisterProcessModal'
import {
  TreeRegistrationFormItemAccountHolder,
  TreeRegistrationFormItemAccountNumber,
  TreeRegistrationFormItemBankName,
  TreeRegistrationFormItemBusinessField,
  TreeRegistrationFormItemCompanyName,
  TreeRegistrationFormItemCountry,
  TreeRegistrationFormItemDocument,
  TreeRegistrationFormItemDistrict,
  TreeRegistrationFormItemNumber,
  TreeRegistrationFormItemPostalCode,
  TreeRegistrationFormItemProvince,
  TreeRegistrationFormItemRegency,
  TreeRegistrationFormItemStreet,
  TreeRegistrationFormItemSubdistrict,
} from '../FormItem'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledTitleContainer = styled.div`
  margin-bottom: ${convertUnit(20)};
  text-align: center;
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`

const StyledItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledSubmitBtnContainer = styled(StyledItemsContainer)`
  margin-top: ${convertUnit(45)};
`

const StyledUploadLocationContainer = styled.div`
  width: 50%;
  margin-right: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: 0;
    width: 100%;
  }
`

const StyledFormPartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledSelectedLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
`

export default function TreeRegistrationForm() {
  const history = useHistory()
  const {translate} = useTranslation()
  const {
    accountHolderCache,
    accountNumberCache,
    addressNumberCache,
    bankNameCache,
    businessFieldCache,
    companyNameCache,
    districtCache,
    documentNameCache,
    latitudeCache,
    longitudeCache,
    postalCodeCache,
    provinceCache,
    regencyCache,
    status: hostStatus,
    streetCache,
    subDistrictCache,
  } = useSelector('hostRegistrationState')
  const {username} = useSelector('user') || {}

  const form = useForm<TreeHostRegistrationForm>({
    defaultValues: {
      documentName: documentNameCache || '',
      accountHolder: accountHolderCache || '',
      accountNumber: accountNumberCache || '',
      bankName: bankNameCache || '',
      companyName: companyNameCache || '',
      bankId: '',
      countryId: HOST_REGISTER_COUNTRY.id,
      countryName: HOST_REGISTER_COUNTRY.name,
      addressNumber: addressNumberCache || '',
      businessField: businessFieldCache || '',
      district: districtCache || '',
      latitude: latitudeCache || 0,
      longitude: longitudeCache || 0,
      postalCode: postalCodeCache || '',
      province: provinceCache || '',
      regency: regencyCache || '',
      street: streetCache || '',
      subDistrict: subDistrictCache || '',
    },
  })
  const {watch, register, errors} = form
  const {update} = useDispatch()
  const values = watch()
  const {
    addressNumber,
    accountNumber,
    bankId,
    bankName,
    businessField,
    document,
    documentName,
    documentSize,
    documentType,
    companyName,
    district,
    latitude,
    longitude,
    postalCode,
    province,
    regency,
    street,
    subDistrict,
    mime,
    accountHolder,
    countryId,
    countryName,
  } = values
  const [loading, setLoading] = useState(false)
  const [processed, setProcessed] = useState(false)

  const notValid = useMemo(
    () =>
      Object.keys(errors).length > 0 ||
      companyName.length < HOST_COMPANY_MIN_LENGTH ||
      companyName.length > HOST_COMPANY_MAX_LENGTH ||
      accountHolder === '' ||
      accountHolder.match(REGEX_ALPHABET_AND_SPACE) === null ||
      accountNumber.length < HOST_BANK_NUMBER_MIN_LENGTH ||
      accountNumber.length > HOST_BANK_NUMBER_MAX_LENGTH ||
      accountHolder.length > HOST_ACCOUNT_HOLDER_MAX_LENGTH ||
      bankId === '' ||
      documentSize > HOST_REGISTRATION_MAX_DOCUMENT_SIZE ||
      !HOST_REGISTRATION_DOCUMENT_TYPE.includes(mime) ||
      hostStatus === 'processing' ||
      businessField === '' ||
      street === '' ||
      addressNumber === '' ||
      longitude === 0 ||
      latitude === 0 ||
      subDistrict === '' ||
      district === '' ||
      regency === '' ||
      province === '' ||
      postalCode === '',
    [
      accountHolder,
      accountNumber.length,
      addressNumber,
      bankId,
      businessField,
      companyName.length,
      district,
      documentSize,
      errors,
      hostStatus,
      latitude,
      longitude,
      mime,
      postalCode,
      province,
      regency,
      street,
      subDistrict,
    ],
  )
  const [currentMap, setCurrentMap] = useState<
    UseFormMethods<TreeHostRegistrationForm>
  >()

  const [modal, setModal] = useState(true)

  const handleGenerateDocumentLink = useCallback(async () => {
    setLoading(true)
    const response = await requestData('tree_post_document_link', {
      actionType: 'execute',
      data: {
        document: {
          extension: documentType,
          size: documentSize,
        },
      },
      onRequestSuccess: ({status, data}) => {
        if (status !== 200) {
          setLoading(false)
          if (
            status === 400 &&
            data.detail?.company_name === 'company name already exists'
          ) {
            showSnackbar(
              translate('tree:hostRegistrationInvalidCompanyAlreadyExist'),
            )
          }
        }
      },
      onRequestFailed: () => setLoading(false),
    })
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.document
      : null
  }, [documentSize, documentType, translate])

  const handlePublishToWasabi = useCallback(
    async (link: string) => {
      try {
        return handleUploadSingleFileToWasabi(document, link, mime)
      } catch (error) {
        return false
      }
    },
    [document, mime],
  )

  const handleUploadForm = useCallback(
    async (link: string) => {
      const response = await requestData('tree_register_host', {
        actionType: 'execute',
        data: {
          bank_account_name: accountHolder,
          bank_account_number: accountNumber,
          bank_id: bankId,
          business_field: businessField,
          company_name: companyName,
          country_id: countryId,
          country_name: countryName,
          district,
          document_link: link,
          document_name: documentName,
          document_size: documentSize,
          latitude,
          longitude,
          number: addressNumber,
          postal_code: postalCode,
          province,
          regency,
          street,
          subdistrict: subDistrict,
        },
        onRequestSuccess: ({status, data}) => {
          if (status !== 200) {
            setLoading(false)
            if (
              status === 400 &&
              data.detail?.company_name === 'company name already exists'
            ) {
              showSnackbar(
                translate('tree:hostRegistrationInvalidCompanyAlreadyExist'),
              )
            }
          }
          if (status === 200) {
            update('hostRegistrationState', {
              accountHolderCache: accountHolder,
              accountNumberCache: accountNumber,
              addressNumberCache: addressNumber,
              bankNameCache: bankName,
              businessFieldCache: businessField,
              companyNameCache: companyName,
              countryIdCache: countryId,
              countryNameCache: countryName,
              districtCache: district,
              documentNameCache: documentName,
              latitudeCache: latitude,
              longitudeCache: longitude,
              postalCodeCache: postalCode,
              provinceCache: province,
              regencyCache: regency,
              status: 'processing',
              streetCache: street,
              subDistrictCache: subDistrict,
            })
          }
        },
        onRequestReceived: () => setLoading(false),
      })
      return typeof response !== 'string' && response.status === 200
    },
    [
      bankName,
      accountNumber,
      bankId,
      businessField,
      companyName,
      countryId,
      countryName,
      district,
      documentName,
      documentSize,
      latitude,
      longitude,
      addressNumber,
      postalCode,
      province,
      regency,
      street,
      subDistrict,
      translate,
      update,
      accountHolder,
    ],
  )

  const handleRegister = useCallback(async () => {
    const link = await handleGenerateDocumentLink()
    if (link) {
      const wasabiStatus = await handlePublishToWasabi(link)
      if (wasabiStatus) {
        const uploadStatus = await handleUploadForm(link)
        if (uploadStatus) {
          setProcessed((prev) => !prev)
        }
      }
    }
  }, [handleGenerateDocumentLink, handlePublishToWasabi, handleUploadForm])

  const handleRenderRegistrationFormTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('tree:hostRegistrationFormTitle')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="regular" color="gray_5">
          {translate('tree:hostRegistrationFormSubtitle')}
        </Paragraph>
      </StyledTitleContainer>
    ),
    [translate],
  )

  const handleClickLocation = useCallback(() => {
    setModal(true)
    setCurrentMap(form)
  }, [form])

  const handleRenderUploadLocButton = useMemo(
    () => (
      <StyledUploadLocationContainer>
        <StyledSelectedLocationContainer>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('tree:hostRegistrationSelectLocationDescription')}
          </Paragraph>
          <Paragraph fontSize="m" color="gray_5">
            {`${latitude}, ${longitude}` || '0, 0'}
          </Paragraph>
        </StyledSelectedLocationContainer>
        <Button
          label={translate('tree:hostRegistrationSelectLocationButton')}
          color="primary_5"
          leftIcon={
            <Icon color="primary_5" type="mark" style={{marginRight: '8px'}} />
          }
          backgroundColor="white_2"
          disabled={hostStatus === 'processing'}
          onClick={handleClickLocation}
        />
      </StyledUploadLocationContainer>
    ),
    [handleClickLocation, hostStatus, latitude, longitude, translate],
  )

  const handleRenderUploadDocumentSection = useMemo(
    () => (
      <TreeRegistrationFormItemDocument
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderCompanyNameForm = useMemo(
    () => (
      <TreeRegistrationFormItemCompanyName
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderBusinessFieldForm = useMemo(
    () => (
      <TreeRegistrationFormItemBusinessField
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderStreetForm = useMemo(
    () => (
      <TreeRegistrationFormItemStreet
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderSubDistrictForm = useMemo(
    () => (
      <TreeRegistrationFormItemSubdistrict
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderRegencyForm = useMemo(
    () => (
      <TreeRegistrationFormItemRegency
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderPostalCodeForm = useMemo(
    () => (
      <TreeRegistrationFormItemPostalCode
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )
  const handleRenderBankNameForm = useMemo(
    () => (
      <TreeRegistrationFormItemBankName
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderAddressNumberForm = useMemo(
    () => (
      <TreeRegistrationFormItemNumber
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderDistrictForm = useMemo(
    () => (
      <TreeRegistrationFormItemDistrict
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderProvinceForm = useMemo(
    () => (
      <TreeRegistrationFormItemProvince
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderAccountHolderForm = useMemo(
    () => (
      <TreeRegistrationFormItemAccountHolder
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderCountryForm = useMemo(
    () => <TreeRegistrationFormItemCountry form={form} disabled />,
    [form],
  )

  const handleRenderAccountNumberForm = useMemo(
    () => (
      <TreeRegistrationFormItemAccountNumber
        form={form}
        disabled={hostStatus === 'processing'}
      />
    ),
    [form, hostStatus],
  )

  const handleRenderSubmitButton = useMemo(
    () => (
      <StyledSubmitBtnContainer>
        <Button
          disabled={notValid}
          label={translate('global:submit')}
          onClick={handleRegister}
        />
      </StyledSubmitBtnContainer>
    ),
    [notValid, handleRegister, translate],
  )

  const handleRenderLocationDocument = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderUploadLocButton}
        {handleRenderUploadDocumentSection}
      </StyledFormPartContainer>
    ),
    [handleRenderUploadDocumentSection, handleRenderUploadLocButton],
  )

  const handleRenderFormPartOne = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderCompanyNameForm}
        {handleRenderBusinessFieldForm}
      </StyledFormPartContainer>
    ),
    [handleRenderBusinessFieldForm, handleRenderCompanyNameForm],
  )

  const handleRenderFormPartTwo = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderStreetForm}
        {handleRenderAddressNumberForm}
      </StyledFormPartContainer>
    ),
    [handleRenderAddressNumberForm, handleRenderStreetForm],
  )

  const handleRenderFormPartThree = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderSubDistrictForm}
        {handleRenderDistrictForm}
      </StyledFormPartContainer>
    ),
    [handleRenderDistrictForm, handleRenderSubDistrictForm],
  )

  const handleRenderFormPartFour = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderRegencyForm}
        {handleRenderProvinceForm}
      </StyledFormPartContainer>
    ),
    [handleRenderProvinceForm, handleRenderRegencyForm],
  )

  const handleRenderFormPartFive = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderPostalCodeForm}
        {handleRenderCountryForm}
      </StyledFormPartContainer>
    ),
    [handleRenderCountryForm, handleRenderPostalCodeForm],
  )

  const handleRenderFormPartSix = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderBankNameForm}
        {handleRenderAccountNumberForm}
      </StyledFormPartContainer>
    ),
    [handleRenderAccountNumberForm, handleRenderBankNameForm],
  )

  const handleRenderFormPartSeven = useMemo(
    () => (
      <StyledFormPartContainer>
        {handleRenderAccountHolderForm}
        {handleRenderSubmitButton}
      </StyledFormPartContainer>
    ),
    [handleRenderAccountHolderForm, handleRenderSubmitButton],
  )

  const handleRenderLocationModal = useMemo(
    () => (
      <GiftShopLocationModal
        mapForm={currentMap}
        messageField={translate('giftShop:pinpointTheLocation')}
        toggleModal={() => setModal((prev) => !prev)}
        visible={modal}
        onSave={() => setCurrentMap(undefined)}
      />
    ),
    [currentMap, modal, translate],
  )

  const handleRenderProcessModal = useMemo(
    () => (
      <TreeRegistrationProcessModal
        visible={processed}
        toggleModal={() => {
          setProcessed((prev) => !prev)
          history.push('giftshop_profile', {self: true}, username)
        }}
      />
    ),
    [history, processed, username],
  )

  useDidMount(() => {
    register('documentName')
    register('bankId')
    register('bankName')
    register('documentSize')
    register('documentType')
    register('mime')
    register('document')
    register('countryId')
    register('countryName')
    register('latitude')
    register('longitude')
  })

  return (
    <StyledContainer>
      <StyledColumnContainer>
        <ModalLoading visible={loading} />
        {handleRenderLocationModal}
        {handleRenderProcessModal}
        {handleRenderRegistrationFormTitle}
        {handleRenderLocationDocument}
        {handleRenderFormPartOne}
        {handleRenderFormPartTwo}
        {handleRenderFormPartThree}
        {handleRenderFormPartFour}
        {handleRenderFormPartFive}
        {handleRenderFormPartSix}
        {handleRenderFormPartSeven}
      </StyledColumnContainer>
    </StyledContainer>
  )
}
