import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeRegistrationBeAHostModalContentItemProps} from './TreeRegistrationBeAHostModalContentItemProps'

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${convertUnit(16)};
`

const StyledItemIconContainer = styled.div`
  margin-right: ${convertUnit(20)};
`

export default function TreeRegistrationBeAHostModalContentItem({
  description,
  icon,
  iconColor,
}: TreeRegistrationBeAHostModalContentItemProps) {
  return (
    <StyledItemContainer>
      <StyledItemIconContainer>
        <Icon type={icon} size={convertUnit(32)} color={iconColor} />
      </StyledItemIconContainer>
      <Paragraph fontSize="m" fontWeight="medium" color="gray_7">
        {description}
      </Paragraph>
    </StyledItemContainer>
  )
}
