import React, {
  createElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import {
  BrowserRouter,
  BrowserRouterProps,
  Redirect,
  Router as RouterOrigin,
  Route,
  Switch,
} from 'react-router-dom'
import {PromptProvider} from 'context'
import {GiftShopTemplateNotVerifiedUserModal} from 'pages'
import {Routes} from 'types'
import {getRoutePath} from 'utils'
import {requestData} from 'services'
import history from 'lib/history'
import {useDispatch, useSelector} from 'lib/redux'
import {RouteMap, RouteMapEntry, ROUTE_MAP} from './RouteMap'
import Middleware from './Middleware'

interface RouteSwitchProps<T> {
  map: RouteMap<T>
  relativePath?: string
  redirectPath?: string
}

interface RouterProps<T> extends BrowserRouterProps {
  basename: Routes
  map: RouteMap<T>
}

function RouteSwitch<T>({
  relativePath = '',
  redirectPath,
  map,
}: RouteSwitchProps<T>) {
  const user = useSelector('user')

  const loggedIn = useMemo(() => !!(user && user.access_token), [user])

  const handleDisableRoute = useCallback(
    ([, {disabled = false}]: [string, RouteMapEntry<any, any>]) => !disabled,
    [],
  )

  const handleFilterAllowedRoutes = useCallback(
    ([, {permission = 'all'}]: [string, RouteMapEntry<any, any>]) =>
      (loggedIn && permission !== 'guest') ||
      (!loggedIn && permission !== 'user'),
    [loggedIn],
  )

  const handleRenderRoute = useCallback(
    ([route, routeMapEntry]: [string, RouteMapEntry<any, any>]) => {
      const param: any = {
        route,
        exact: true,
        relativePath,
        ...routeMapEntry,
      }

      const {path, exact, component, nested, defaultState} = param

      return defaultState === undefined ? (
        <Route key={route} path={`${relativePath}${path}`} exact={exact}>
          {createElement(
            component,
            undefined,
            nested ? (
              <RouteSwitch map={nested} relativePath={path} />
            ) : undefined,
          )}
        </Route>
      ) : (
        createElement(Middleware, param)
      )
    },
    [relativePath],
  )

  const handleRenderRoutes = useMemo(
    () =>
      Object.entries<RouteMapEntry<any, any>>(map)
        .filter(handleFilterAllowedRoutes)
        .filter(handleDisableRoute)
        .map(handleRenderRoute),

    [handleDisableRoute, handleFilterAllowedRoutes, handleRenderRoute, map],
  )

  return (
    <RouterOrigin history={history}>
      <PromptProvider>
        <Switch>
          {handleRenderRoutes}
          {redirectPath && <Redirect to={redirectPath} />}
        </Switch>
      </PromptProvider>
    </RouterOrigin>
  )
}

function Router<T>({basename, map}: RouterProps<T>) {
  const base = useMemo(() => getRoutePath(basename), [basename])

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <RouteSwitch map={map} redirectPath={base} />
      </BrowserRouter>
    </Suspense>
  )
}

export default function RouteStack() {
  const user = useSelector('user')
  const device = useSelector('userDevice')
  const {update} = useDispatch()

  const allowRenderModal = useMemo(
    () =>
      user &&
      user.access_token &&
      !user.is_verified &&
      !window.location.pathname.includes('/settings/profile'),
    [user],
  )

  const deviceIdIsValid = useMemo(
    () =>
      device.device_client_id !== null &&
      device.stage &&
      device.stage === process.env.APP_ENV,
    [device.device_client_id, device.stage],
  )

  const handleGetDeviceClientId = useCallback(() => {
    if (deviceIdIsValid) return

    requestData('giftshop_member_device', {
      data: {},
      onRequestSuccess: ({
        status,
        data: {
          result: {device_client_id},
        },
      }) => {
        if (status === 200 || status === 201)
          update('userDevice', {
            device_client_id,
            stage: process.env.APP_ENV,
          })
      },
    })
  }, [deviceIdIsValid, update])

  useEffect(() => {
    handleGetDeviceClientId()
  }, [handleGetDeviceClientId])

  return (
    <>
      {allowRenderModal && <GiftShopTemplateNotVerifiedUserModal />}
      <Router basename="giftshop_feed" map={ROUTE_MAP} />
    </>
  )
}
