import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  FORKYGRAM_UPLOAD_CAPTION_MAX_CHARACTER,
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {ForkygramPostTagData, parseShape} from 'types'
import {useWindowMode} from 'windows'
import {formatDateRange, useDebounce, useDidMount, useDidUpdate} from 'utils'
import {useTheme} from 'themes'
import {
  IconVerifiedBadge,
  Image,
  Input,
  InputArea,
  ListLazy,
  Menu,
  Paragraph,
  ParsedText,
} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplateUploadContentProps} from './ForkygramTemplateUploadContentProps'
import {ForkygramTemplateUploadPreviewModal} from '../PreviewModal'

interface StyledImageProps {
  width: number
  height: number
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.white_1};
  z-index: 1;
  padding: ${convertUnit(20)};
`

const StyledDesktopContentContainter = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${convertUnit(20)};
`

const StyledImageContainter = styled.div`
  width: ${convertUnit(244)};
  aspect-ratio: 1;
  cursor: pointer;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: ${convertUnit(120)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: ${convertUnit(80)};
  }
`

const StyledImage = styled(Image)<StyledImageProps>`
  ${({width, height}) => ({
    width: convertUnit(width),
    height: convertUnit(height),
  })}
  object-fit: cover;
  border-radius: ${convertUnit(8)};
`

const StyledImageInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${convertUnit(25)};
`

const StyledVideo = styled.video`
  object-fit: cover;
  border-radius: ${convertUnit(8)};
`

const StyledContentListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  align-self: center;
  padding: ${convertUnit(20)};
`

const StyledError = styled(Paragraph)`
  ${({theme}) => ({color: theme.danger_5})}
  box-sizing: border-box;
  margin-top: ${convertUnit(7)};
  margin-bottom: ${convertUnit(2)};
`

const StyledTagItemContainer = styled.div`
  padding: ${convertUnit(6)} ${convertUnit(16)};
  &:hover {
    background-color: ${({theme}) => theme.primary_5};
  }
`

const StyledTagItemNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${convertUnit(6)};
`

const StyledTagItemName = styled(Paragraph)`
  ${StyledTagItemContainer}:hover & {
    color: ${({theme}) => theme.white_1};
  }
`

const StyledTagItemBio = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledTagItemContainer}:hover & {
    color: ${({theme}) => theme.white_3};
  }
`

export default function ForkygramTemplateUploadContent({
  src,
  form,
  contentType,
  thumbnail,
  editContent,
  stateFormError,
}: ForkygramTemplateUploadContentProps) {
  const theme = useTheme()
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const defaultImg = IMAGE_ASSET('giftshop', 'broken-image-explore.png')
  const mode = useWindowMode()
  const {setValue, watch} = form
  const {caption, tag} = watch()
  const [preview, setPreview] = useState(false)
  const stateTags = useState<readonly ForkygramPostTagData[]>([])
  const [searchTag, setSearchTag] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const [error, setError] = stateFormError

  const patterns: parseShape[] = useMemo(() => {
    const regex = new RegExp(searchTag)

    return [
      {
        pattern: regex,
        style: {fontFamily: 'roboto-bold', color: theme.black},
        renderText: (text) => text.replace(regex, `"${text}"`),
      },
    ]
  }, [searchTag, theme.black])

  const handleError = useCallback(() => {
    if (tag.name !== searchTag) {
      setError('missmatch')
      return
    }
    if (tag?.event_start_date && tag.event_start_date > Date.now() / 1000) {
      setError('future')
      return
    }
    setError('none')
  }, [searchTag, setError, tag?.event_start_date, tag.name])

  const handleSetImgSize = useMemo(() => {
    switch (mode) {
      case 'mobile':
        return 80
      case 'tablet':
        return 120
      case 'website':
        return 244
    }
  }, [mode])

  const handleRenderCaption = useMemo(
    () => (
      <InputArea
        label={translate('forkygram:caption')}
        name="caption"
        value={caption}
        placeholder={translate('forkygram:captionInputPlaceholder')}
        autoComplete={false}
        inputContainerStyle={{height: convertUnit(129)}}
        labelDescription={translate('global:charCount', {
          count: caption.length,
          max: FORKYGRAM_UPLOAD_CAPTION_MAX_CHARACTER,
        })}
        onChangeText={(text) => setValue('caption', text)}
        maxLength={FORKYGRAM_UPLOAD_CAPTION_MAX_CHARACTER}
      />
    ),
    [caption, setValue, translate],
  )

  const handleLoadTagData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestData('giftshop_get_tags_post', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {page, limit, q},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [],
  )

  const handleSelectTag = useCallback(
    (item: ForkygramPostTagData) => {
      setSearchTag(item.name)
      setValue('tag', item)
      setToggle((previous) => !previous)
    },
    [setSearchTag, setToggle, setValue],
  )

  const handleDeleteTag = useCallback(() => {
    setSearchTag('')
    setSearchQuery('')
    setValue('tag', {id: '', name: '', tree_id: ''})
    setToggle((previous) => !previous)
  }, [setSearchTag, setToggle, setValue])

  const handleRenderDropdownItem = useCallback(
    (item: ForkygramPostTagData) => (
      <StyledTagItemContainer onClick={() => handleSelectTag(item)}>
        <StyledTagItemNameContainer>
          <StyledTagItemName fontWeight="bold" lineHeight={21}>
            {item.name}
          </StyledTagItemName>
          {item.status === 'VERIFIED' && (
            <IconVerifiedBadge width={12} height={12} />
          )}
        </StyledTagItemNameContainer>
        <StyledTagItemBio fontSize="xs" color="gray_5">
          {item.location &&
            item.location.name !== '-' &&
            `${item.location.name} | `}
          {item.event_start_date &&
            item.event_end_date &&
            `${formatDateRange(item.event_start_date, item.event_end_date)} | `}
          {item.bio}
        </StyledTagItemBio>
      </StyledTagItemContainer>
    ),
    [handleSelectTag],
  )

  const handleRendederEmptyTag = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph color="gray_5" fontSize="m">
          {translate('forkygram:fototreeTagNotFound')}
          <ParsedText parse={patterns}>{searchTag}</ParsedText>
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [patterns, searchTag, translate],
  )

  const handleRenderTagList = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateTags}
          loadData={handleLoadTagData}
          search={searchQuery}
          keyExtractor={(item) => item.id}
          renderItem={handleRenderDropdownItem}
          listEmptyElement={handleRendederEmptyTag}
          emptyContainerStyle={{flex: 0}}
        />
      </StyledContentListContainer>
    ),
    [
      handleLoadTagData,
      handleRendederEmptyTag,
      handleRenderDropdownItem,
      searchQuery,
      stateTags,
    ],
  )

  const handleRenderErrorMessage = useMemo(
    () => (
      <StyledError fontWeight="medium">
        {translate('forkygram:fototreeTagMismatch')}
      </StyledError>
    ),
    [translate],
  )

  const handleRenderTagInput = useMemo(
    () => (
      <Menu
        stateToggle={stateToggle}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={handleRenderTagList}>
        <Input
          value={searchTag}
          label={translate('giftShop:uploadTagLabel')}
          placeholder={translate('giftShop:uploadInputTagsPlaceholder')}
          borderColor={error === 'missmatch' ? 'danger_5' : undefined}
          rightIcon="delete"
          rightIconStyle={{cursor: 'pointer'}}
          containerStyle={{marginTop: convertUnit(25)}}
          onChangeText={(text) => {
            if (text.length < 1) {
              setValue('tag', {id: '', name: '', tree_id: ''})
            }
            setSearchTag(text)
            debounce(() => setSearchQuery(text), 500)
          }}
          onFocus={() => setToggle(true)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              setToggle((previous) => !previous)
            }
          }}
          onRightIconClick={handleDeleteTag}
        />
      </Menu>
    ),
    [
      debounce,
      error,
      handleDeleteTag,
      handleRenderTagList,
      searchTag,
      setToggle,
      setValue,
      stateToggle,
      translate,
    ],
  )

  useDidMount(() => {
    if (editContent && tag) {
      setSearchTag(tag.name)
      setValue('tag', tag)
    }
  })

  useDidUpdate(handleError, [handleError])

  return (
    <>
      {src && (
        <ForkygramTemplateUploadPreviewModal
          src={src}
          visible={preview}
          contentType={contentType}
          toggleModal={() => setPreview((prev) => !prev)}
          editContent={editContent}
        />
      )}
      <StyledContainer>
        <StyledDesktopContentContainter>
          <StyledImageContainter onClick={() => setPreview(true)}>
            {(src?.includes('image') ||
              contentType === 'photo' ||
              (editContent && contentType === 'video')) && (
              <StyledImage
                src={contentType === 'video' ? thumbnail : src || defaultImg}
                alt=""
                width={handleSetImgSize}
                height={handleSetImgSize}
              />
            )}
            {(src?.includes('video') || contentType === 'video') &&
              !editContent && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <StyledVideo
                  width={handleSetImgSize}
                  height={handleSetImgSize}
                  autoPlay={false}>
                  <source src={src} type="video/mp4" />
                  <source src={src} type="video/mov" />
                  <source src={src} type="video/heiv" />
                </StyledVideo>
              )}
          </StyledImageContainter>
          <StyledImageInfo>
            {mode === 'website' && handleRenderCaption}
            {mode === 'website' && (
              <>
                {handleRenderTagInput}
                {error === 'missmatch' ? handleRenderErrorMessage : <></>}
              </>
            )}
          </StyledImageInfo>
        </StyledDesktopContentContainter>
        {mode !== 'website' && handleRenderCaption}
        {mode !== 'website' && (
          <>
            {handleRenderTagInput}
            {error === 'missmatch' ? handleRenderErrorMessage : <></>}
          </>
        )}
      </StyledContainer>
    </>
  )
}
