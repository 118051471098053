import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import Lottie from 'lottie-react'
import {isSafari} from 'react-device-detect'
import {
  AuthEasySignupChildWefieShootStatus,
  AuthEasySignupChildrenRegisterUserPayload,
  AuthEasySignupChildrenUploadLinkRequestParam,
  AuthEasySignupChildrenUploadLinkType,
  AuthEasySignupRegister,
  AuthEasySignupUploadStatus,
  AuthLoginResponse,
  GiftShopKYCRecordStatus,
  GiftShopKYCVerificationStatus,
  ObjectState,
  WefieType,
} from 'types'
import {
  blobToFile,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  urlToBlob,
  useHistory,
  useLocation,
} from 'utils'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {Modal, Paragraph} from 'common/components'
import {reduxUpdateDispatcher, useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import animationData from 'assets/lottie/activity_indicator.json'
import {
  GiftShopRobopetActivationSelfieOverlay,
  GiftShopRobopetActivationSelfieReview,
  GiftShopTemplatePermissionDenied,
  GiftShopTemplateUploadModal,
} from '../../../gift-shop'
import {AuthRegisterTermOfCondition} from '../../register'
import AuthEasySignupChildrenOverlayRecord from './AuthEasySignupChildrenOverlayRecord'
import AuthEasySignupChildrenOverlayCapture from './AuthEasySignupChildrenOverlayCapture'

interface ProgressProps {
  progress: GiftShopKYCVerificationStatus
  mediaError: boolean
}

const StyledContainer = styled.div<ProgressProps>`
  background-color: ${({theme, progress, mediaError}) =>
    progress === 'confirmation' || progress === 'landing' || mediaError
      ? theme.white_1
      : theme.black_2};
  display: flex;
  flex: 1;
  justify-content: center;
`
const StyledLottie = styled(Lottie)`
  height: ${convertUnit(40)};
  width: ${convertUnit(40)};
  margin-bottom: ${convertUnit(25)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
`

interface GiftShopRobopetActivationSelfieProps {
  stateVerifStatus: ObjectState<GiftShopKYCVerificationStatus>
  currentWefieProgress: React.MutableRefObject<WefieType>
}
export default function AuthEasySignupChildrenWefie({
  stateVerifStatus,
  currentWefieProgress,
}: GiftShopRobopetActivationSelfieProps) {
  const history = useHistory()
  const {
    date_of_birth = '',
    method,
    username = '',
    email,
    phone_code,
    phone_number,
    token,
    parentDob,
    child_consent_code = '',
  } = useLocation('auth_easy_signup_children_wefie').state
  const {
    parentDob: reapplyParentDob,
    child_consent_code: reapplyChildConsentCode = '',
    isChildReapply = false,
  } = useLocation('giftshop_robopet_incomplete_child_wefie').state
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {tag_id, tree_id} = useSelector('treeQrState')
  const showTermsState = useState(false)
  const setShowTerms = showTermsState[1]
  const webcamRef = useRef<any>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [recordedChunks, setRecordedChunks] = useState([])
  const wefieRecordedChunks = useRef([])
  const [isRecording, setIsRecording] = useState(false)
  const [
    shootStatus,
    setShootStatus,
  ] = useState<AuthEasySignupChildWefieShootStatus>('tutorial')
  const [verifStatus, setVerifStatus] = stateVerifStatus
  const [recordProgress, setRecordProgress] = useState<GiftShopKYCRecordStatus>(
    'front',
  )
  const [selfie, setSelfie] = useState('')
  const [wefie, setWefie] = useState('')
  const [modal, setModal] = useState(false)
  const [uploadProgress, setUploadProgress] = useState('')
  const uploadState = useRef<AuthEasySignupUploadStatus>('form')
  const videoConstraint = useMemo(
    () => ({
      aspectRatio: 0.67,
      facingMode: 'user',
    }),
    [],
  )
  const uploadSuccess = useRef(0)
  const childConsentCode = useRef(child_consent_code || reapplyChildConsentCode)
  const video = useRef('')
  const link = useRef<AuthEasySignupChildrenRegisterUserPayload>({
    selfie: '',
    wefie: '',
    wefie_video: '',
  })
  const {lang} = useSelector('languageState')
  const [mediaError, setMediaError] = useState(false)

  const handleDataAvailable = useCallback(({data}) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }
  }, [])

  const handleRecordProgress = useCallback(() => {
    setShootStatus('wefie_video')
    setTimeout(() => {
      setIsRecording(true)
      mediaRecorderRef.current.start()
      setTimeout(() => {
        mediaRecorderRef.current.state === 'recording' &&
          mediaRecorderRef.current.stop()
        setIsRecording(false)
      }, 1000)
    }, 1000)
  }, [])

  const handleRecord = useCallback(() => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? 'video/mp4' : 'video/webm',
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    )
    handleRecordProgress()
  }, [handleDataAvailable, handleRecordProgress])

  const handleProcessRecord = useCallback(() => {
    if (mediaRecorderRef.current) {
      setTimeout(() => {
        setRecordProgress('done')
        setShootStatus('wefie')
      }, 1000)
    }
  }, [])

  const onAudioEnd = useCallback(() => {
    if (mediaRecorderRef.current) {
      setIsRecording(false)
      mediaRecorderRef.current.state === 'recording' &&
        mediaRecorderRef.current.stop()
      handleProcessRecord()
    }
  }, [handleProcessRecord])

  const handleTakeSelfie = useCallback(() => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot()
      if (currentWefieProgress.current === 'selfie') {
        setSelfie(image)
      } else {
        setWefie(image)
      }
      setVerifStatus('confirmation')
    }
  }, [currentWefieProgress, setVerifStatus, translate])

  const handleRetake = useCallback(() => {
    if (currentWefieProgress.current === 'selfie') {
      setVerifStatus('shoot')
      setShootStatus('selfie')
      setSelfie('')
    } else {
      setVerifStatus('shoot')
      setShootStatus('tutorial')
      setRecordProgress('front')
      setRecordedChunks([])
      wefieRecordedChunks.current = []
      setWefie('')
    }
  }, [currentWefieProgress, setVerifStatus])

  const handleLoginSSO = useCallback(() => {
    if (token) {
      requestData(
        method === 'gosso'
          ? 'auth_easy_signup_login_sso_google'
          : 'auth_easy_signup_login_sso_apple',
        {
          data: {token},
          onRequestSuccess: ({data, status}) => {
            if (status === 200) {
              const userInfo = data.result as AuthLoginResponse
              reduxUpdateDispatcher('user', userInfo)
              if (tree_id && tag_id) {
                history.pushQuery({
                  path: 'tree_events_bib_masonry',
                  state: {
                    tree_url: '',
                    tree_name: '',
                    fromScan: true,
                  },
                  queryObject: {
                    tree_id,
                    tag_id,
                    search: '',
                  },
                })
              } else {
                history.push('giftshop_explore', {})
              }
            } else {
              showSnackbar(
                translate('global:messageError', {
                  context: `code-${status}`,
                }),
              )
            }
          },
        },
      )
    }
  }, [history, method, tag_id, token, translate, tree_id])

  const handleUploadData = useCallback(async () => {
    const payload: AuthEasySignupRegister = {
      date_of_birth,
      username,
      email,
      phone_code,
      phone_number,
      token,
      language: lang || 'id',
    }
    if (token) {
      const response = await requestData(
        method === 'gosso'
          ? 'auth_easy_signup_sso_google'
          : 'auth_easy_signup_sso_apple',
        {
          data: payload,
        },
      )
      if (typeof response !== 'string') {
        if (response.status === 202) {
          uploadState.current = 'kyc'
          const {result} = response.data
          childConsentCode.current = result.child_consent_code || ''
        } else {
          setModal(false)
          showSnackbar(
            translate('global:messageError', {
              context: `code-${response.status}`,
            }),
          )
        }
      } else {
        setModal(false)
        showSnackbar(
          translate(
            !navigator.onLine
              ? 'global:messageError_no-connection'
              : 'giftShop:uploadFailed',
          ),
        )
      }
    } else {
      const response = await requestData(
        method === 'email'
          ? 'auth_easy_signup_email'
          : 'auth_easy_signup_phone',
        {
          data: payload,
        },
      )
      if (typeof response !== 'string') {
        if (response.status === 200) {
          uploadState.current = 'kyc'
          const {result} = response.data
          childConsentCode.current = result.child_consent_code || ''
        } else {
          setModal(false)
          showSnackbar(
            translate('global:messageError', {
              context: `code-${response.status}`,
            }),
          )
        }
      } else {
        setModal(false)
        showSnackbar(
          translate(
            !navigator.onLine
              ? 'global:messageError_no-connection'
              : 'giftShop:uploadFailed',
          ),
        )
      }
    }
  }, [
    date_of_birth,
    email,
    lang,
    method,
    phone_code,
    phone_number,
    token,
    translate,
    username,
  ])

  const handleUploadLink = useCallback(
    async (
      type: AuthEasySignupChildrenUploadLinkType,
      data: AuthEasySignupChildrenUploadLinkRequestParam,
      file: File,
      extension: string,
    ) => {
      const response = await requestData('auth_easy_signup_children_links', {
        headers: {
          'X-Child-Consent-Code': childConsentCode.current,
        },
        data: {...data, type},
      })
      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        if (type === 'wefie_video') {
          link.current = {
            ...link.current,
            wefie_video: result.wefie_video,
          }
        } else if (type === 'wefie') {
          link.current = {
            ...link.current,
            wefie: result.wefie,
          }
        } else if (type === 'selfie') {
          link.current = {...link.current, selfie: result.selfie}
        }
        const isSuccess = await handleUploadSingleFileToWasabi(
          file,
          type === 'selfie'
            ? link.current.selfie
            : type === 'wefie_video'
            ? link.current.wefie_video
            : link.current.wefie,
          extension,
          {
            'Content-Type': extension,
          },
        )
        if (isSuccess) {
          uploadSuccess.current += 1
          if (uploadSuccess.current === 3) uploadState.current = 'link'
          setUploadProgress(
            translate('giftShop:uploadPublishLoadingDescription', {
              success: uploadSuccess.current,
              total: 3,
            }),
          )
        }
        return isSuccess
      }
      return false
    },
    [translate],
  )

  const handleUpload = useCallback(async () => {
    if (!navigator.onLine) {
      showSnackbar(translate('global:messageError_no-connection'))
      return
    }
    setUploadProgress(translate('giftShop:kycUploading_preparing'))
    setModal(true)
    wefieRecordedChunks.current = [recordedChunks[0]]
    const blobVideo = new Blob(wefieRecordedChunks.current, {
      type: 'video/mp4',
    })
    const urlVideo = URL.createObjectURL(blobVideo)
    video.current = urlVideo
    const fileFront = new File([blobVideo], 'video_front', {
      type: blobVideo.type,
      lastModified: new Date().getTime(),
    })
    const selfieBlob = await urlToBlob(selfie)
    const fileSelfie = blobToFile(
      selfieBlob,
      'kyc-selfie',
      new Date().getTime(),
    )
    const wefieBlob = await urlToBlob(wefie)
    const fileWefie = blobToFile(wefieBlob, 'kyc-wefie', new Date().getTime())
    const selfieExt = fileSelfie.type
    const videoExt = fileFront.type
    const uploadData: AuthEasySignupChildrenUploadLinkRequestParam = {
      selfie: {
        size: fileSelfie.size,
        extension: fileSelfie.type.split('/')[1],
      },
      wefie: {
        size: fileWefie.size,
        extension: fileWefie.type.split('/')[1],
      },
      wefie_video: {
        size: fileFront.size,
        extension: fileFront.type.split('/')[1],
      },
    }
    uploadSuccess.current = 0
    if (child_consent_code || reapplyChildConsentCode)
      uploadState.current = 'kyc'
    if (uploadState.current === 'form') await handleUploadData()
    for (let i = 0; i < Object.keys(uploadData).length; i += 1) {
      const key = Object.keys(uploadData)[
        i
      ] as AuthEasySignupChildrenUploadLinkType
      if (uploadState.current === 'kyc') {
        if (
          // eslint-disable-next-line no-await-in-loop
          !(await handleUploadLink(
            key,
            uploadData,
            key === 'wefie_video'
              ? fileFront
              : key === 'selfie'
              ? fileSelfie
              : fileWefie,
            key === 'wefie_video' ? videoExt : selfieExt,
          ))
        ) {
          setModal(false)
          showSnackbar(
            translate(
              !navigator.onLine
                ? 'global:messageError_no-connection'
                : 'giftShop:uploadFailed',
            ),
          )
          break
        }
      }
    }
    if (uploadState.current === 'link') {
      await requestData('auth_easy_signup_children_verif', {
        headers: {
          'X-Child-Consent-Code': childConsentCode.current,
        },
        data: {
          selfie_link: link.current.selfie,
          wefie_video_link: link.current.wefie_video,
          wefie_link: link.current.wefie,
          parent_date_of_birth: parentDob || reapplyParentDob,
          is_retake: isChildReapply,
        },
        onRequestSuccess: (value) => {
          setModal(false)
          if (value.status === 200) {
            if (isChildReapply) {
              history.replace('giftshop_explore', {})
            } else {
              update('easySignupDuplicateState', {hasRegistered: true})
              if (token) {
                handleLoginSSO()
              } else {
                if (child_consent_code) {
                  history.replace('auth_login', {})
                  return
                }
                if (email || (phone_code && phone_number)) {
                  update(
                    'lastUserState',
                    method === 'email'
                      ? {emailTime: new Date().getTime()}
                      : {phoneOTPTime: new Date().getTime()},
                  )
                }
                history.replace('auth_easy_signup_verification', {
                  method: method === 'phone' ? 'whatsapp' : method,
                  email,
                  phone_code,
                  phone_number,
                  shouldCensor: false,
                  showResend: !!(email || (phone_code && phone_number)) || false,
                })
              }
            }
          } else {
            showSnackbar(
              translate(
                !navigator.onLine
                  ? 'global:messageError_no-connection'
                  : 'giftShop:uploadFailed',
              ),
            )
          }
        },
      })
    }
  }, [
    child_consent_code,
    email,
    handleLoginSSO,
    handleUploadData,
    handleUploadLink,
    history,
    isChildReapply,
    method,
    parentDob,
    phone_code,
    phone_number,
    reapplyChildConsentCode,
    reapplyParentDob,
    recordedChunks,
    selfie,
    token,
    translate,
    update,
    wefie,
  ])

  const handleRenderOverlay = useMemo(() => {
    switch (shootStatus) {
      case 'tutorial':
        return <GiftShopRobopetActivationSelfieOverlay onClick={handleRecord} />
      case 'wefie_video':
        return (
          <AuthEasySignupChildrenOverlayRecord
            progress={recordProgress}
            isRecording={isRecording}
            onAudioEnd={onAudioEnd}
            isEasySignup
          />
        )
      case 'wefie':
        return (
          <AuthEasySignupChildrenOverlayCapture
            onClick={handleTakeSelfie}
            type="wefie"
            hasCounter
          />
        )
      case 'selfie':
        return (
          <AuthEasySignupChildrenOverlayCapture
            onClick={handleTakeSelfie}
            type="selfie"
          />
        )
      default:
        return <GiftShopRobopetActivationSelfieOverlay onClick={handleRecord} />
    }
  }, [
    handleRecord,
    handleTakeSelfie,
    isRecording,
    onAudioEnd,
    recordProgress,
    shootStatus,
  ])

  const handleRenderNeedPermission = useMemo(
    () => <GiftShopTemplatePermissionDenied type="camera" />,
    [],
  )

  const handleRenderWebcam = useMemo(
    () => (
      <>
        <Webcam
          style={{height: '100%', position: 'absolute'}}
          mirrored
          id="webcam"
          onUserMediaError={(e) => e && setMediaError(true)}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraint}
        />
        {handleRenderOverlay}
      </>
    ),
    [handleRenderOverlay, videoConstraint],
  )

  const handleRenderVerificationPage = useMemo(() => {
    switch (verifStatus) {
      case 'landing':
        return <></>
      case 'shoot':
        return mediaError ? handleRenderNeedPermission : handleRenderWebcam
      case 'confirmation':
        return (
          (wefie || selfie) && (
            <GiftShopRobopetActivationSelfieReview
              selfie={
                currentWefieProgress.current === 'selfie' ? selfie : wefie
              }
              onRetry={handleRetake}
              onAccept={() => {
                if (currentWefieProgress.current === 'selfie') {
                  token ? setShowTerms(true) : handleUpload()
                } else {
                  currentWefieProgress.current = 'selfie'
                  setVerifStatus('shoot')
                  setShootStatus('selfie')
                }
              }}
            />
          )
        )
      default:
        return <></>
    }
  }, [
    currentWefieProgress,
    handleRenderNeedPermission,
    handleRenderWebcam,
    handleRetake,
    handleUpload,
    mediaError,
    selfie,
    setShowTerms,
    setVerifStatus,
    token,
    verifStatus,
    wefie,
  ])

  const renderModalUploading = useMemo(
    () => (
      <StyledModal visible={modal}>
        {uploadProgress === translate('giftShop:kycUploading_preparing') ? (
          <>
            <StyledLottie animationData={animationData} />
            <Paragraph fontSize="xl" fontWeight="bold">
              {uploadProgress}
            </Paragraph>
          </>
        ) : (
          <GiftShopTemplateUploadModal
            title={translate('giftShop:uploadPublishLoadingTitle')}
            description={uploadProgress}
            progressColor="primary_5"
            success={uploadSuccess.current}
            total={3}
          />
        )}
        <StyledParagraph fontSize="m" color="gray_3">
          {translate('auth:registerEasySignupUploadWarning')}
        </StyledParagraph>
      </StyledModal>
    ),
    [modal, translate, uploadProgress],
  )

  const renderModalToS = useMemo(
    () => (
      <AuthRegisterTermOfCondition
        showModalState={showTermsState}
        handleAgree={() => {
          setShowTerms(false)
          handleUpload()
        }}
        showCheckbox
      />
    ),
    [handleUpload, setShowTerms, showTermsState],
  )

  return (
    <StyledContainer progress={verifStatus} mediaError={mediaError}>
      {renderModalToS}
      {renderModalUploading}
      {handleRenderVerificationPage}
    </StyledContainer>
  )
}
