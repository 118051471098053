import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ExplorePaginationHandler} from 'types'
import {setExplorePaginationHandler} from 'utils'
import {ExplorePaginationProviderProps} from './ExplorePaginationProviderProps'
import {ExplorePaginationContext} from './ExplorePaginationContext'

export default function ExplorePaginationProvider({
  children,
}: ExplorePaginationProviderProps) {
  const stateExplorePagination = useState(1)
  const setStateExplorePagination = stateExplorePagination[1]

  const handleResetStateExplorePagination = useCallback(() => {
    setStateExplorePagination(1)
  }, [setStateExplorePagination])

  const handler = useMemo<ExplorePaginationHandler>(
    () => ({
      stateExplorePagination,
      resetStateExplorePagination: handleResetStateExplorePagination,
    }),
    [handleResetStateExplorePagination, stateExplorePagination],
  )

  useEffect(() => {
    setExplorePaginationHandler(handler)
  }, [handler])

  return (
    <ExplorePaginationContext.Provider value={handler}>
      {children}
    </ExplorePaginationContext.Provider>
  )
}
