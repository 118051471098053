import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input} from 'common/components'
import {VALIDATION_TREE_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

export const StyledItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeRegistrationFormItemNumber({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {addressNumber} = watch()

  return (
    <StyledItemsContainer>
      <Input
        form={form}
        name="addressNumber"
        formRules={VALIDATION_TREE_REQUIRED}
        label={translate('tree:hostRegistrationFormAddressNumberLabel')}
        placeholder={translate(
          'tree:hostRegistrationFormAddressNumberPlaceholder',
        )}
        disabled={disabled}
        rightIcon={
          !disabled && addressNumber && addressNumber.length > 0 ? (
            <Icon
              type="close"
              size={convertUnit(16)}
              onClick={() => setValue('addressNumber', '')}
            />
          ) : undefined
        }
      />
    </StyledItemsContainer>
  )
}
