import {TranslationKey} from '../keys'
import {TRANSLATION_AUTH_ID} from './auth'
import {TRANSLATION_FORKYGRAM_ID} from './forkygram'
import {TRANSLATION_GIFT_SHOP_ID} from './gift-shop'
import {TRANSLATION_GLOBAL_ID} from './global'
import {TRANSLATION_MESSAGES_ID} from './messages'
import {TRANSLATION_POLICY_ID} from './policy'
import {TRANSLATION_TREE_ID} from './tree'
import {TranslationResource} from '../TranslationType'
import {TRANSLATION_TRANSACTION_ID} from './transaction'

export const TRANSLATION_ID: TranslationResource<TranslationKey> = {
  translation: {
    ...TRANSLATION_GLOBAL_ID.translation,
    ...TRANSLATION_AUTH_ID.translation,
    ...TRANSLATION_GIFT_SHOP_ID.translation,
    ...TRANSLATION_FORKYGRAM_ID.translation,
    ...TRANSLATION_MESSAGES_ID.translation,
    ...TRANSLATION_POLICY_ID.translation,
    ...TRANSLATION_TRANSACTION_ID.translation,
    ...TRANSLATION_TREE_ID.translation,
  },
}
