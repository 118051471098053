import React from 'react'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

export default function GiftShopRobopetActivationRejected() {
  const {translate} = useTranslation()

  return (
    <GiftShopRobopetActivationTemplate
      backgroundColor="white_3"
      robopetImg="robopet-confused.png"
      title={translate('giftShop:roboyuActivationRejectedHeader')}
      titleColor="gray_6">
      <Paragraph fontSize="m" fontWeight="regular" color="gray_6">
        {translate('giftShop:roboyuActivationRejectedDescription')}
      </Paragraph>
    </GiftShopRobopetActivationTemplate>
  )
}
