import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import {
  GiftShopKYCRetakeStatus,
  GiftShopKYCVerificationStatus,
  GiftShopUploadStatus,
  ObjectState
} from 'types'
import {
  blobToFile,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  urlToBlob,
  useHistory,
} from 'utils'
import {Button, Icon, Modal, ModalLoading, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useDispatch} from 'lib/redux'
import {useWindowMode} from 'windows'
import {GiftShopTemplatePermissionDenied} from '../../../template'
import {
  GiftShopRobopetActivationSelfieCapture,
  GiftShopRobopetActivationSelfieReview,
} from '../ActivationKYC'
import GiftShopRobopetRetakeSelfieOverlay from './GiftShopRobopetRetakeSelfieOverlay'

interface ProgressProps {
  progress: GiftShopKYCVerificationStatus
  mediaError: boolean
}

const StyledContainer = styled.div<ProgressProps>`
  background-color: ${({theme, progress, mediaError}) => 
    progress === 'confirmation' ||
    progress === 'landing' ||
    mediaError ? theme.white_1 : theme.black_2};
  display: flex;
  flex: 1;
  justify-content: center;
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: 100%;
`

interface GiftShopRobopetRetakeSelfieProps {
  stateVerifStatus: ObjectState<GiftShopKYCVerificationStatus>
}
export default function GiftShopRobopetRetakeSelfie({
  stateVerifStatus,
}: GiftShopRobopetRetakeSelfieProps) {
  const history = useHistory()
  const mode = useWindowMode()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const webcamRef = useRef<any>(null)
  const [shootStatus, setShootStatus] = useState<GiftShopKYCRetakeStatus>('tutorial')
  const [verifStatus, setVerifStatus] = stateVerifStatus
  const [selfie, setSelfie] = useState('')
  const [modal, setModal] = useState<'progress' | 'satisfied'>()
  const videoConstraint = useMemo(() => ({
    aspectRatio: mode === 'mobile' ? 1.5 : 0.67,
    facingMode: 'user',
  }), [mode])
  const link = useRef('')
  const uploadState = useRef<GiftShopUploadStatus>('form')

  const [mediaError, setMediaError] = useState(false)

  const handleTakeSelfie = useCallback(() => {
    if (webcamRef.current) {
     const image = webcamRef.current.getScreenshot()
     setSelfie(image)
     setVerifStatus('confirmation')
    }
  }, [setVerifStatus])

  const handleRetake = useCallback(() => {
    setVerifStatus('shoot')
    setShootStatus('tutorial')
    setSelfie('')
  }, [setVerifStatus])

  const handleUpload = useCallback(async () => {
    setModal('progress')
    const selfieBlob = await urlToBlob(selfie)
    const fileSelfie = blobToFile(selfieBlob, 'kyc-selfie', new Date().getTime())
    const selfieExt = fileSelfie.type
    if (uploadState.current === 'form') {
      const response = await requestData('giftshop_user_retake_link', {
        data: {
          selfie: {
            size: fileSelfie.size,
            extension: fileSelfie.type.split('/')[1],
          }
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        link.current = result.selfie
        uploadState.current = 'kyc'
      } else {
        setModal(undefined)
        showSnackbar(translate('giftShop:uploadFailed'))
      }
    }
    if (uploadState.current === 'kyc') {
      const isSuccess = await handleUploadSingleFileToWasabi(
        fileSelfie,
        link.current,
        selfieExt,
        {
          'Content-Type': selfieExt,
        }
      )
      if (isSuccess) {
        uploadState.current = 'link'
      } else {
        setModal(undefined)
        showSnackbar(translate('giftShop:uploadFailed'))
      }
    }
    
    if (uploadState.current === 'link') {
      await requestData('giftshop_user_retake', {
        data: {selfie_link: link.current},
        onRequestReceived: () => {
          setModal(undefined)
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('yuserActivationState', {retakeStatus: 'processing'})
            history.goBack()
          } else {
            showSnackbar(translate('global:messageError', {context: status}))
          }
        },
      })
    }
  }, [history, selfie, translate, update])

  const handleRenderOverlay = useMemo(() => {
    switch (shootStatus) {
      case 'selfie':
        return (
          <GiftShopRobopetActivationSelfieCapture
            onClick={handleTakeSelfie}
            isRetakeSelfie />
        )
      default:
        return (
          <GiftShopRobopetRetakeSelfieOverlay
            onClick={() => setShootStatus('selfie')} />
        )
    }
  }, [handleTakeSelfie, shootStatus])

  const handleRenderNeedPermission = useMemo(() => (
    <GiftShopTemplatePermissionDenied type="camera" />
  ), [])

  const handleRenderWebcam = useMemo(() => (
    <>
      <Webcam
        style={{height: '100%', position: 'absolute'}}
        mirrored
        onUserMediaError={() => setMediaError(true)}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraint} />
      {handleRenderOverlay}
    </>
  ), [handleRenderOverlay, videoConstraint])

  const handleRenderVerificationPage = useMemo(() => {
    switch (verifStatus) {
      case 'shoot':
        return mediaError ? handleRenderNeedPermission : handleRenderWebcam
      case 'confirmation':
        return selfie && (
          <GiftShopRobopetActivationSelfieReview
            selfie={selfie}
            onRetry={handleRetake}
            onAccept={handleUpload}
            isRetake
          />)
      default:
        return <></>
    }
  }, [handleRenderNeedPermission, handleRenderWebcam, handleRetake, handleUpload, mediaError, selfie, verifStatus])

  const renderModalSatisfied = useMemo(() => (
    <StyledModal visible>
      <Icon type="check" color="success_5" size={48} />
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('tree:hostRegistrationProcessModalTitle')}
        </Paragraph>
        <Paragraph fontSize="l" style={{marginTop: convertUnit(8)}}>
          {translate('giftShop:kycSatisfiedMessage')}
        </Paragraph>
        <StyledButton
          label={translate('global:ok')}
          onClick={() => {
            setModal(undefined)
            history.replace('giftshop_robopet', {afterKyc: true})
          }} />
    </StyledModal>
  ), [history, translate])

  const renderModal = useMemo(() => {
    switch (modal) {
      case 'progress':
        return <ModalLoading visible />
      case 'satisfied':
        return renderModalSatisfied
      default:
        return <></>
    }
  }, [modal, renderModalSatisfied])

  return (
    <StyledContainer progress={verifStatus} mediaError={mediaError}>
      {renderModal}
      {handleRenderVerificationPage}
    </StyledContainer>
  )
}
