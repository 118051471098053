import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopExploreFilterForm, GiftShopExploreFilterStates} from 'types'
import {showSnackbar} from 'utils'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopExploreFilterModalProps} from './GiftShopExploreFilterModalProps'
import {
  GiftShopExploreFilterModalFormCreator,
  GiftShopExploreFilterModalFormDateTime,
  GiftShopExploreFilterModalFormTags,
} from '../FilterModalForm'

interface StyledClearFilterProps {
  cursor: CSSProperties['cursor']
}

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(315)};
  max-height: ${convertUnit(642)};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    border-radius: ${convertUnit(18)};
    width: ${convertUnit(0)};
  }
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${convertUnit(25)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledClearFilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${convertUnit(20)};
`

const StyledClearFilter = styled.div<StyledClearFilterProps>`
  display: flex;
  flex-direction: row;
  cursor: ${({cursor}) => cursor};
`

export default function GiftShopExploreFilterModal({
  visible,
  onApplyFilter,
  toggleModal,
}: GiftShopExploreFilterModalProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const filter = useSelector('exploreFilter')
  const {
    startDate,
    endDate,
    latitude,
    longitude,
    tags,
    lastUpdated,
    creators,
  } = filter

  const stateTempFilter = useState<GiftShopExploreFilterStates>({
    latitude,
    longitude,
    startDate,
    endDate,
    tags,
    lastUpdated,
    creators: creators || [],
  })
  const [tempFilter, setTempFilter] = stateTempFilter
  const stateDateTime = useState({
    startDate: tempFilter.startDate || '',
    endDate: tempFilter.endDate || '',
  })
  const [dateTime, setDateTime] = stateDateTime

  const form = useForm<GiftShopExploreFilterForm>({
    defaultValues: {
      startDate,
      endDate,
      tags,
      lastUpdated,
      creators,
    },
  })

  const {setValue} = form

  const isNewFilter = useCallback(() => {
    const {
      startDate: tempStartDate,
      endDate: tempEndDate,
      tags: tempTags,
      creators: tempCreators,
    } = tempFilter

    const tagsIds = tags.map((item) => item.tag_id)
    const tempTagsIds = tempTags.map((item) => item.tag_id)
    const includeTags = tempTagsIds.map((item) => tagsIds.includes(item))

    const creatorIds = creators ? creators.map((item) => item.creator_id) : []
    const tempCreatorIds = tempCreators.map((item) => item.creator_id)
    const includecreators = tempCreatorIds.map((item) =>
      creatorIds.includes(item),
    )

    return !(
      tempStartDate === startDate &&
      tempEndDate === endDate &&
      (tempTags.length === 0 || includeTags.includes(true)) &&
      tempTags.length === tags.length &&
      (tempCreatorIds.length === 0 || includecreators.includes(true)) &&
      (!creators || tempCreatorIds.length === creators.length)
    )
  }, [creators, endDate, startDate, tags, tempFilter])

  const isTempFilterEmpty = useCallback(
    () =>
      tempFilter.startDate === '' &&
      tempFilter.endDate === '' &&
      tempFilter.tags.length === 0 &&
      dateTime.startDate === '' &&
      dateTime.endDate === '' &&
      tempFilter.creators.length === 0,
    [
      tempFilter.startDate,
      tempFilter.endDate,
      tempFilter.tags.length,
      tempFilter.creators.length,
      dateTime.startDate,
      dateTime.endDate,
    ],
  )

  const handleClearFilter = useCallback(() => {
    setTempFilter((prev) => ({
      ...prev,
      startDate: '',
      endDate: '',
      longitude: '',
      latitude: '',
      tags: [],
      sensitivityLevel: undefined,
      creators: [],
    }))
    setDateTime({startDate: '', endDate: ''})
    setValue('latitude', '')
    setValue('longitude', '')
  }, [setDateTime, setTempFilter, setValue])

  const handleApplyFilter = useCallback(() => {
    const currentDate = new Date()
    const currentDateTime = currentDate.getTime()
    const startDateTime = tempFilter.startDate
      ? new Date(tempFilter.startDate).getTime()
      : undefined
    const endDateTime = tempFilter.endDate
      ? new Date(tempFilter.endDate).getTime()
      : undefined

    if (startDateTime && endDateTime && endDateTime < startDateTime)
      showSnackbar(translate('auth:filterDateValidation'))
    else if (startDateTime && startDateTime > currentDateTime)
      setTempFilter((prev) => ({
        ...prev,
        startDate: currentDate.toISOString().substring(0, 16),
      }))
    else {
      update('exploreFilter', {
        startDate: tempFilter.startDate,
        endDate: tempFilter.endDate,
        tags: tempFilter.tags,
        lastUpdated: currentDate.toISOString(),
        creators: tempFilter.creators,
      })
      onApplyFilter && onApplyFilter()
      toggleModal()
    }
  }, [
    onApplyFilter,
    setTempFilter,
    tempFilter.creators,
    tempFilter.endDate,
    tempFilter.startDate,
    tempFilter.tags,
    toggleModal,
    translate,
    update,
  ])

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('global:filter')}
        </StyledTitle>
      </StyledTitleContainer>
    ),
    [translate],
  )

  const handleCancel = useCallback(() => {
    toggleModal()
    setTempFilter((prev) => ({
      ...prev,
      latitude: filter.latitude,
      longitude: filter.longitude,
      startDate: filter.startDate,
      endDate: filter.endDate,
      tags: filter.tags,
    }))
  }, [
    filter.endDate,
    filter.latitude,
    filter.longitude,
    filter.startDate,
    filter.tags,
    setTempFilter,
    toggleModal,
  ])

  const handleRenderTagsForm = useMemo(
    () => (
      <GiftShopExploreFilterModalFormTags stateTempFilter={stateTempFilter} />
    ),
    [stateTempFilter],
  )

  const handleRenderDateTimeForm = useMemo(
    () => (
      <GiftShopExploreFilterModalFormDateTime
        stateTempFilter={stateTempFilter}
        stateDateTime={stateDateTime}
      />
    ),
    [stateDateTime, stateTempFilter],
  )

  const handleRenderCreatorForm = useMemo(
    () => (
      <GiftShopExploreFilterModalFormCreator
        stateTempFilter={stateTempFilter}
      />
    ),
    [stateTempFilter],
  )

  const handleRenderClearFilter = useMemo(
    () => (
      <StyledClearFilterContainer>
        <StyledClearFilter
          cursor={isTempFilterEmpty() ? 'default' : 'pointer'}
          onClick={isTempFilterEmpty() ? undefined : handleClearFilter}>
          <Icon
            type="unfilter"
            color={isTempFilterEmpty() ? 'gray_3' : 'primary_5'}
            size={convertUnit(20)}
          />
          <Paragraph
            fontSize="m"
            fontWeight="bold"
            color={isTempFilterEmpty() ? 'gray_3' : 'primary_5'}>
            {translate('giftShop:exploreFilterClear')}
          </Paragraph>
        </StyledClearFilter>
      </StyledClearFilterContainer>
    ),
    [handleClearFilter, isTempFilterEmpty, translate],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonsContainer>
        <Button
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={handleCancel}
          width={convertUnit(125)}
        />
        <Button
          label={translate('global:apply')}
          width={convertUnit(125)}
          onClick={handleApplyFilter}
          disabled={!isNewFilter()}
        />
      </StyledButtonsContainer>
    ),
    [translate, handleCancel, handleApplyFilter, isNewFilter],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderTitle}
      {handleRenderDateTimeForm}
      {handleRenderTagsForm}
      {handleRenderCreatorForm}
      {handleRenderClearFilter}
      {handleRenderButtons}
    </StyledModal>
  )
}
