import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {TemplateProfileModalItem} from '../ModalItem'
import {TemplateProfileModalContent} from '../ModalContent'
import {TemplateProfileSelfOptionModalProps} from './TemplateProfileSelfOptionModalProps'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default function TemplateProfileSelfOptionModal({
  isPinned,
  visible,
  toggleModal,
  onClickDelete,
  onClickEdit,
  onClickPin,
  onClickShare,
}: TemplateProfileSelfOptionModalProps) {
  const {translate} = useTranslation()

  const handleRenderChildren = useMemo(
    () => (
      <StyledContainer>
        {onClickShare && (
          <TemplateProfileModalItem
            icon="share"
            fontSize="m"
            label={translate('forkygram:postShare')}
            onClickItem={onClickShare}
          />
        )}
        {onClickPin && (
          <TemplateProfileModalItem
            icon="pin"
            fontSize="m"
            label={translate(
              isPinned ? 'forkygram:postUnpin' : 'forkygram:postPin',
            )}
            onClickItem={onClickPin}
          />
        )}
        <TemplateProfileModalItem
          icon="pencil"
          fontSize="m"
          label={translate('forkygram:postEdit')}
          onClickItem={onClickEdit}
        />
        <TemplateProfileModalItem
          icon="trash"
          fontSize="m"
          label={translate('forkygram:postDelete')}
          onClickItem={onClickDelete}
        />
      </StyledContainer>
    ),
    [isPinned, onClickDelete, onClickEdit, onClickPin, onClickShare, translate],
  )

  return (
    <TemplateProfileModalContent visible={visible} toggleModal={toggleModal}>
      {handleRenderChildren}
    </TemplateProfileModalContent>
  )
}
