import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GiftShopCreationDateGroupData, LayoutView} from 'types'
import {parseShortDate, useDefaultState} from 'utils'
import {useWindowMode} from 'windows'
import {Image, Paragraph, ListLazy, View, RadioItem} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileCreationGroupProps} from './TemplateProfileCreationGroupProps'

interface StyledContainerProps {
  $toggleSelect?: boolean
}

const StyledContainer = styled(View)<StyledContainerProps>`
  ${({$toggleSelect}) => ({
    paddingTop: $toggleSelect ? convertUnit(20) : convertUnit(1),
  })};
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
  padding-left: ${convertUnit(20)};
`

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-right: ${convertUnit(20)};
  margin-bottom: ${convertUnit(24)};
  cursor: pointer;
`

const StyledItem = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${convertUnit(8)};
  aspect-ratio: 1;
  margin-bottom: ${convertUnit(8)};
`

const StyledCounter = styled.div`
  background-color: ${({theme}) => theme.white_3};
  width: ${convertUnit(22)};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${convertUnit(4)};
  position: absolute;
  z-index: 1;
  top: ${convertUnit(8)};
  left: ${convertUnit(8)};
`

const StyledImg = styled(Image)`
  object-fit: cover;
  width: 100%;
  border-radius: ${convertUnit(8)};
  aspect-ratio: 1;
  align-self: center;
`

const StyledRadioItem = styled(RadioItem)`
  position: absolute;
  z-index: 1;
  top: ${convertUnit(8)};
  left: ${convertUnit(8)};
`

export default function TemplateProfileCreationGroup<
  ItemT extends GiftShopCreationDateGroupData,
  S
>({
  stateSelected,
  toggleSelect = false,
  stateData,
  onClickItem,
  ...props
}: TemplateProfileCreationGroupProps<ItemT, S>) {
  const mode = useWindowMode()
  const [layout, setLayout] = useState<LayoutView>()
  const selected = useDefaultState([], stateSelected)[0]

  const handleRenderItem = useCallback(
    (item: ItemT) => (
      <StyledItemContainer onClick={() => onClickItem && onClickItem(item)}>
        {toggleSelect && (
          <StyledRadioItem
            isSelected={selected ? selected?.includes(item) : false}
            size={25}
          />
        )}
        {!toggleSelect && (
          <StyledCounter>
            <Paragraph fontSize="xs" fontWeight="medium">
              {item.count}
            </Paragraph>
          </StyledCounter>
        )}
        <StyledItem>
          <StyledImg src={item.thumbnail} alt={item.thumbnail} />
        </StyledItem>
        <Paragraph
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="medium">
          {parseShortDate(item.date.substring(0, 10))}
        </Paragraph>
      </StyledItemContainer>
    ),
    [mode, onClickItem, selected, toggleSelect],
  )

  const handleRenderList = useMemo(
    () =>
      layout && (
        <>
          <ListLazy
            {...props}
            stateData={stateData}
            keyExtractor={(item) => item.date}
            renderItem={handleRenderItem}
            numColumns={Math.ceil(layout.clientWidth / 200)}
          />
        </>
      ),
    [layout, props, stateData, handleRenderItem],
  )

  return (
    <StyledContainer onLayout={setLayout} $toggleSelect={toggleSelect}>
      {handleRenderList}
    </StyledContainer>
  )
}
