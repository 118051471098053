import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import convertUnit from 'lib/unit'
import {getCurrencyValue, parseDate, useDidMount, useHistory} from 'utils'
import GiftShopMessageRoomContentItemList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemNotificationRefundCreatorProps {
  payload: FirebaseMessageRoomItemPayload['notification_refund_content_creator']
}

const StyledExtraText = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};
`

export default function GiftShopMessageRoomContentItemNotificationRefundCreator({
  payload: {contents, collector_nickname, creator_refund_amount, purchased_at},
}: GiftShopMessageRoomContentItemNotificationRefundCreatorProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      contents &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:notificationRefundCreatorSubtitle')}
        </Paragraph>
        <br />
        <Paragraph fontSize="s" color="gray_5">
          {purchased_at &&
            translate('global:purchaseDate', {
              purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY'),
            })}
        </Paragraph>
        <br />
      </>
    ),
    [purchased_at, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentItemList
        data={data}
        onClick={(item) =>
          history.push(
            'giftshop_profile_creation_detail',
            {
              data,
              selectedItemId: item.content_id,
              limitContent: true,
            },
            item.creator_name,
          )
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderExtra = useMemo(
    () => (
      <>
        <StyledExtraText fontSize="m">
          {translate('messages:notificationRefundCreatorExtra', {
            creator_refund_amount: getCurrencyValue(
              parseInt(creator_refund_amount, 10),
            ),
            collector_nickname,
          })}
        </StyledExtraText>
        <Button
          label={translate('giftShop:transactionDetail')}
          onClick={() => history.push('giftshop_transaction_history', {})}
        />
      </>
    ),
    [collector_nickname, creator_refund_amount, history, translate],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderExtra}
    </>
  )
}
