import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadTagNicknameModalProps} from './GiftShopUploadTagNicknameModalProps'

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(20)};
  width: 100%;
  max-width: ${convertUnit(295)};
  gap: ${convertUnit(8)};
  text-align: center;
  justify-content: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopUploadTagNicknameModal({
  visible,
  onConfirm,
  toggleModal,
}: GiftShopUploadTagNicknameModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate('giftShop:uploadTagNicknameConfirmationTitle')}
      </Paragraph>
      <Paragraph fontSize="l">
        {translate('giftShop:uploadTagNicknameConfirmationDescription')}
      </Paragraph>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          onClick={toggleModal}
        />
        <StyledButton
          label={translate('global:yesImSure')}
          onClick={onConfirm}
          color="primary_5"
          backgroundColor="white_2"
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
