import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Lottie from 'lottie-react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  GIFT_SHOP_MIN_WITHDRAW_AMOUNT,
  GIFT_SHOP_USER_BANK_ACCOUNT_LIMIT,
  HOST_MAX_WITHDRAW_AMOUNT,
  REGEX_NUMBER,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  BalanceMessageState,
  FetchDataType,
  GiftShopUserBankResponseBodyList,
  WindowModeType,
  WithdrawForm,
} from 'types'
import {
  formatCurrency,
  getBorder,
  getCurrencyValue,
  getValueOfCurrency,
  showSnackbar,
  useDidUpdate,
} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  CheckboxItem,
  Icon,
  Input,
  Link,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {VALIDATION_TREE__PRICE_WITHDRAW} from 'common/validations'
import animationData from '../../../../../assets/lottie/activity_indicator.json'
import {TransactionBankAccountListItem} from '../../../../transaction/withdraw/container/BankAccountList'
import {TemplateTransactionWithdrawFormProps} from './TemplateTransactionWithdrawFormProps'
import {TransactionWithdrawFormWarningBox} from '../../../../transaction/withdraw/container/WarningBox'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledBankContainerProps {
  selected: boolean
}

const StyledScrollContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: mode === 'mobile' ? theme.white_1 : theme.white_2,
    padding: mode === 'mobile' ? 0 : undefined,
  })};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
`

const StyledComponentContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    width: mode !== 'mobile' ? convertUnit(505) : '100%',
    margin: mode !== 'mobile' ? convertUnit(25) : 0,
  })};
  padding: ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledRoundedBox = styled.div`
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`

const StyledUpperContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(1, 'solid', theme.gray_1),
  })}
  padding: ${convertUnit(12)} ${convertUnit(20)};
  border-radius: ${convertUnit(8)};
  margin-bottom: ${convertUnit(20)};
`

const StyledBalanceContainer = styled(StyledRoundedBox)`
  display: flex;
  flex-direction: column;
`

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertUnit(25)};
`

const StyledCheckboxItem = styled(CheckboxItem)`
  ${({theme}) => ({borderColor: theme.gray_5})}
`

const StyledDestinationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledBankContainer = styled.div<StyledBankContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`

const StyledEmptyDestinationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledEmptyBankContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const StyledEmptyLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledLottie = styled(Lottie)`
  width: 100%;
  height: ${convertUnit(20)};
  padding: ${convertUnit(20)};
  align-self: center;
`

const StyledSeperator = styled.div`
  background-color: ${({theme}) => theme.white_3};
  margin-top: ${convertUnit(12)};
  margin-bottom: ${convertUnit(12)};
  width: 100%;
  display: flex;
  flex: 1;
  height: ${convertUnit(1)};
`

export default function TemplateTransactionWithdrawForm({
  stateQuantity,
  stateBankAccount,
  onClickNext,
  roletype,
}: TemplateTransactionWithdrawFormProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const minimum = GIFT_SHOP_MIN_WITHDRAW_AMOUNT
  const maximum = HOST_MAX_WITHDRAW_AMOUNT
  const [balanceData, setBalanceData] = useState<number>(0)
  const [quantity, setQuantity] = stateQuantity
  const [selected, setSelected] = useState(false)
  const [account, setAccount] = useState<GiftShopUserBankResponseBodyList[]>([])
  const [selectedAccount, setSelectedAccount] = stateBankAccount
  const [canWithdraw, setCanWithdraw] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)
  const form = useForm<WithdrawForm>({
    defaultValues: {amount: ''},
  })
  const {watch, getValues, setValue} = form
  const {amount} = watch()
  const {update} = useDispatch()
  const [balanceStatus, setBalanceStatus] = useState<FetchDataType>('Loading')
  const [contentLoaded, setContentLoaded] = useState(false)
  const [
    shownBalanceMessage,
    setShownBalanceMessage,
  ] = useState<BalanceMessageState>(
    balanceData <= 0 ? 'INSUFFICIENT_BALANCE' : 'WITHDRAW_MIN_AMOUNT',
  )
  const getValue = useCallback(() => Number(quantity.replace(/\D/g, '')), [
    quantity,
  ])

  const handleLoadUserBalance = useCallback(async () => {
    const response = await requestData('giftshop_get_balance', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({data: dataResponse}) => {
        setBalanceData(dataResponse.result.balance)
      },
    })

    if (typeof response !== 'string') {
      setBalanceStatus('Success')
    } else {
      setBalanceStatus('Failed')
    }
  }, [])

  const handleLoadHostBalance = useCallback(async () => {
    const responseHost = await requestData('tree_fetch_hosts', {
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          const {balance} = result
          update('hostState', {
            balance,
          })
          setBalanceData(balance)
        }
      },
    })

    if (typeof responseHost !== 'string') {
      setBalanceStatus('Success')
    } else {
      setBalanceStatus('Failed')
    }
  }, [update])

  const handleLoadUserBanks = useCallback(() => {
    requestData('giftshop_get_bank_account', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {page: 1, limit: 100},
      onRequestSuccess: ({status, data: response}) => {
        if (status === 200) {
          setAccount(response.result)
          setContentLoaded(true)
          const bankList = response.result
          if (bankList.length) {
            setSelectedAccount(response.result[0])
          }
        }
      },
    })
  }, [setSelectedAccount])

  const handleLoadHostBank = useCallback(() => {
    requestData('tree_get_host_bank', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {page: 1, limit: 100},
      onRequestSuccess: ({status, data: response}) => {
        if (status === 200) {
          setAccount(new Array(response.result))
          setSelectedAccount(response.result)
          setContentLoaded(true)
        }
      },
    })
  }, [setSelectedAccount])

  const checkWithdrawAvailability = useCallback(() => {
    requestData('giftshop_check_withdraw_availability', {
      actionType: 'fetch',
      data: {is_host: roletype === 'host'},
      useDefaultMessage: true,
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setCanWithdraw(result.is_available)
        }
      },
    })
  }, [roletype])

  const isValid = useCallback(() => {
    const value = parseInt(quantity, 10)
    return !(
      quantity.length === 0 ||
      value < minimum ||
      value > maximum ||
      !selectedAccount ||
      parseInt(quantity, 10) > balanceData
    )
  }, [quantity, minimum, maximum, selectedAccount, balanceData])

  const handleChangeWithdrawForm = useCallback(() => {
    const amtVal = getValues('amount')

    if (amtVal.startsWith('0')) {
      setValue('amount', '')
    }
  }, [getValues, setValue])

  const handleRenderBalance = useMemo(
    () => (
      <StyledBalanceContainer>
        <Paragraph fontSize="m" fontWeight="regular">
          {translate('giftShop:availableBalanceLabel')}
        </Paragraph>
        {balanceStatus === 'Success' ? (
          <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
            {getCurrencyValue(balanceData)}
          </Paragraph>
        ) : balanceStatus === 'Loading' ? (
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            color={roletype === 'user' ? 'primary_3' : 'success_7'}>
            {translate('global:pleaseWait')}
          </Paragraph>
        ) : (
          <Paragraph fontSize="s" fontWeight="medium" color="warning_7">
            {translate('global:connectionInterrupted')}
          </Paragraph>
        )}
      </StyledBalanceContainer>
    ),
    [balanceData, balanceStatus, roletype, translate],
  )

  const handleWithdrawAllCheckbox = useMemo(
    () => (
      <StyledCheckboxItem
        isSelected={selected}
        description={translate('giftShop:withdrawAll')}
        size={18}
        containerStyle={{
          marginRight: convertUnit(10),
          marginTop: convertUnit(10),
        }}
        onClick={() => setSelected(!selected)}
        color="gray_3"
      />
    ),
    [selected, translate],
  )

  const handleRenderWarning = useMemo(
    () => (
      <TransactionWithdrawFormWarningBox
        shownBalanceMessage={shownBalanceMessage}
      />
    ),
    [shownBalanceMessage],
  )

  const updateErrorMessage = useCallback(() => {
    const value = getValue()
    if (userInteracted) {
      if (quantity.length !== 0 && value < minimum) {
        setShownBalanceMessage('WITHDRAW_MIN_AMOUNT')
      } else if (value > maximum) {
        setShownBalanceMessage('WITHDRAW_MAX_AMOUNT')
      } else if (balanceData < value || balanceData === 0) {
        setShownBalanceMessage('INSUFFICIENT_BALANCE')
      } else if (!selectedAccount) {
        setShownBalanceMessage('NO_BANK_ACCOUNT')
      } else {
        setShownBalanceMessage('WITHDRAW_MIN_AMOUNT')
      }
    } else if (balanceData === 0) {
      setShownBalanceMessage('INSUFFICIENT_BALANCE')
    } else {
      setShownBalanceMessage('WITHDRAW_MIN_AMOUNT')
    }
  }, [
    balanceData,
    getValue,
    maximum,
    minimum,
    quantity.length,
    selectedAccount,
    userInteracted,
  ])

  const handleRenderForm = useMemo(
    () =>
      !selected && (
        <StyledFormContainer>
          <Input
            name="amount"
            value={
              amount.length === 0 ? '' : formatCurrency(getValues('amount'))
            }
            rightIcon={'delete'}
            form={form}
            formRules={VALIDATION_TREE__PRICE_WITHDRAW}
            readOnly={selected}
            label={translate('giftShop:withdrawAmountLabel')}
            placeholder={translate('global:amount')}
            leftText={translate('global:idr')}
            allowedCharacters={REGEX_NUMBER}
            onClick={() => setUserInteracted(true)}
          />
        </StyledFormContainer>
      ),
    [amount.length, form, getValues, selected, translate],
  )

  const handleRenderHostBank = useMemo(
    () =>
      selectedAccount &&
      account.map((item) => (
        <StyledBankContainer
          selected={item.id === selectedAccount?.id}
          onClick={() => setSelectedAccount(item)}
          key={item.id}>
          <TransactionBankAccountListItem
            accountName={item.account_name}
            accountNumber={item.account_number}
            bankName={item.bank_code}
            isMainAccount
          />
        </StyledBankContainer>
      )),
    [account, selectedAccount, setSelectedAccount],
  )

  const handleRenderBankList = useMemo(
    () =>
      selectedAccount &&
      account.map((item) => (
        <StyledBankContainer
          selected={item.id === selectedAccount?.id}
          onClick={() => setSelectedAccount(item)}
          key={item.id}>
          <TransactionBankAccountListItem
            accountName={item.account_name}
            accountNumber={item.account_number}
            bankName={item.bank_code}
            isMainAccount={item.is_main_account}
            hideMainLabel={account.length === 1}
          />
        </StyledBankContainer>
      )),
    [account, selectedAccount, setSelectedAccount],
  )

  const handleRenderBank = useMemo(
    () => (roletype === 'host' ? handleRenderHostBank : handleRenderBankList),
    [handleRenderBankList, handleRenderHostBank, roletype],
  )

  const handleRenderEmptyDestination = useMemo(
    () => (
      <StyledEmptyDestinationContainer>
        <StyledEmptyBankContainer>
          <Paragraph fontWeight="bold" fontSize="m" color="gray_3">
            {translate('giftShop:bankAccountNotFound')}
          </Paragraph>

          {account.length < GIFT_SHOP_USER_BANK_ACCOUNT_LIMIT && (
            <StyledEmptyLink to="giftshop_user_bank_list">
              <Paragraph fontWeight="bold" color="primary_5">
                {translate('giftShop:addBankAccount')}
              </Paragraph>
              <Icon type="plus" color="primary_5" size={15} />
            </StyledEmptyLink>
          )}
        </StyledEmptyBankContainer>
      </StyledEmptyDestinationContainer>
    ),
    [account.length, translate],
  )

  const handleRenderDestination = useMemo(
    () => (
      <>
        <Paragraph fontWeight="medium">
          {translate('giftShop:withdrawDestinationLabel')}
        </Paragraph>
        {account.length !== 0 ? (
          <StyledDestinationContainer>
            {handleRenderBank}
          </StyledDestinationContainer>
        ) : (
          handleRenderEmptyDestination
        )}
      </>
    ),
    [account.length, handleRenderBank, handleRenderEmptyDestination, translate],
  )

  const handleRenderList = useMemo(
    () =>
      contentLoaded ? (
        handleRenderDestination
      ) : (
        <StyledLottie
          animationData={animationData}
          height={convertUnit(20)}
          width={convertUnit(20)}
        />
      ),
    [contentLoaded, handleRenderDestination],
  )

  const handleRenderNextButton = useMemo(
    () => (
      <Button
        label={translate('giftShop:withdrawButtonConfirm', {
          price: getCurrencyValue(getValue()),
        })}
        color="white_1"
        fontSize="m"
        fontWeight="medium"
        disabled={!isValid()}
        disableColor="gray_1"
        onClick={
          !canWithdraw
            ? () =>
                showSnackbar(
                  roletype === 'user'
                    ? translate(
                        'giftShop:withdrawErrorMessage_WITHDRAW_MAX_HOST',
                      )
                    : translate('tree:withdrawMaximumWarning'),
                )
            : onClickNext
        }
      />
    ),
    [canWithdraw, getValue, isValid, onClickNext, roletype, translate],
  )
  useEffect(() => {
    if (roletype === 'user') {
      handleLoadUserBalance()
      handleLoadUserBanks()
    } else {
      handleLoadHostBalance()
      handleLoadHostBank()
    }
    if (balanceData && selected) {
      setQuantity(getValueOfCurrency(balanceData).toString())
    } else {
      setQuantity(getValueOfCurrency(amount).toString())
    }
  }, [
    amount,
    balanceData,
    handleChangeWithdrawForm,
    handleLoadHostBalance,
    handleLoadHostBank,
    handleLoadUserBalance,
    handleLoadUserBanks,
    roletype,
    selected,
    setQuantity,
    setValue,
  ])

  useEffect(checkWithdrawAvailability, [checkWithdrawAvailability])
  useDidUpdate(updateErrorMessage, [updateErrorMessage])
  useDidUpdate(handleChangeWithdrawForm, [handleChangeWithdrawForm])

  return (
    <StyledScrollContainer>
      {handleRenderWarning}
      <StyledContainer mode={mode}>
        <StyledComponentContainer mode={mode}>
          <StyledUpperContainer>
            {handleRenderList}
            <StyledSeperator />
            {handleRenderBalance}
            {handleWithdrawAllCheckbox}
          </StyledUpperContainer>
          {handleRenderForm}
          {handleRenderNextButton}
        </StyledComponentContainer>
      </StyledContainer>
    </StyledScrollContainer>
  )
}
