import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useHistory, validateMinimumAge} from 'utils'
import {AUTH_USER_MIN_AGE} from 'consts'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function GiftShopRobopetActivationIncomplete() {
  const {translate} = useTranslation()
  const history = useHistory()
  const {date_of_birth} = useSelector('user') || {}

  return (
    <GiftShopRobopetActivationTemplate
      backgroundColor="primary_1"
      robopetImg="robopet-confused.png"
      title={translate('giftShop:roboyuActivationIncompleteHeader')}
      titleColor="primary_5">
      <Paragraph fontSize="m" fontWeight="regular" color="primary_5">
        {translate('giftShop:roboyuActivationIncompleteDescription')}
      </Paragraph>
      <StyledButton
        label={translate('global:tryAgain')}
        onClick={() => {
          if (
            !!date_of_birth &&
            !validateMinimumAge(date_of_birth, AUTH_USER_MIN_AGE)
          ) {
            history.push('giftshop_robopet_incomplete_child', {
              fromRoboyu: true,
            })
          } else {
            history.push('giftshop_robopet_activation_selfie', {
              isReapply: true,
            })
          }
        }}
      />
    </GiftShopRobopetActivationTemplate>
  )
}
