import {useCallback, useMemo} from 'react'
import {requestData} from 'services'
import {useDidMount} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'

export function useGiftshopUploadCompressResendSetting() {
  const {
    creatorStatus = 'unverified',
    compressEnabled: compressEnabledRedux,
    resendEnabled: resendEnabledRedux,
  } = useSelector('yuserActivationState')
  const {update} = useDispatch()
  const {id} = useSelector('user')!

  const handleLoadData = useCallback(() => {
    if (id) {
      requestData('giftshop_get_creator', {
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            update('yuserActivationState', {
              compressEnabled: result.is_always_compress,
              resendEnabled: result.is_always_resend,
            })
            update('user', {
              is_storage_subscriber: result.is_storage_subscriber,
            })
          }
        },
      })
    }
  }, [id, update])

  const compressEnabled = useMemo(
    () => compressEnabledRedux ?? creatorStatus === 'verified',
    [compressEnabledRedux, creatorStatus],
  )

  const resendEnabled = useMemo(
    () => resendEnabledRedux ?? creatorStatus === 'verified',
    [resendEnabledRedux, creatorStatus],
  )

  const validated = useMemo(
    () =>
      compressEnabledRedux !== undefined && resendEnabledRedux !== undefined,
    [compressEnabledRedux, resendEnabledRedux],
  )

  useDidMount(handleLoadData)

  return useMemo(
    () => ({
      compressEnabled,
      resendEnabled,
      validated,
    }),
    [compressEnabled, resendEnabled, validated],
  )
}
