import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_RESEND_VIDEO_TYPE, GIFT_SHOP_UPLOAD_TYPE} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen, GiftshopTemplateUploadResendModal} from 'pages'
import {GiftshopResendDataType, GiftshopResendModalState} from 'types'
import {
  handleGiftshopReadFile,
  showSnackbar,
  useDebounce,
  useDidUpdate,
  useGiftShopResendRevisionHandler,
  useHistory,
  useLocation,
} from 'utils'
import {Button, InputPicker} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopResendRevisionContent} from '../containers'

const StyledContainer = styled.div`
  height: 100%;
`

const StyledTryAgain = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopResendRevisionScreen() {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const {
    collectorNickname,
    contentId,
    invoiceId,
    collectorId,
    contentType,
  } = useLocation('giftshop_resend_revision').state
  const history = useHistory()
  const [revisionData, setRevisionData] = useState<GiftshopResendDataType>()
  const ref = useRef<HTMLInputElement>(null)
  const resendModalState = useState<GiftshopResendModalState>('none')
  const setResendModal = resendModalState[1]
  const [success, setSuccess] = useState(0)
  const {uploadRevision} = useGiftShopResendRevisionHandler()
  const debounce = useDebounce()
  const [loadingImg, setLoadingImg] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const handleUploadRevision = useCallback(async () => {
    setLoadingBtn(true)
    const isSuccess =
      revisionData &&
      (await uploadRevision(
        revisionData.image,
        contentId,
        collectorId,
        invoiceId,
        contentType,
      ))
    if (isSuccess) {
      setSuccess((prev) => prev + 1)
    } else {
      setResendModal('failed')
    }
    setLoadingBtn(false)
  }, [
    collectorId,
    contentId,
    contentType,
    invoiceId,
    revisionData,
    setResendModal,
    uploadRevision,
  ])

  const handleReadFile = useCallback(
    async (file: File) => {
      if (
        (contentType === 'photo' &&
          !GIFT_SHOP_UPLOAD_TYPE.includes(file.type)) ||
        (contentType === 'video' &&
          !GIFT_SHOP_RESEND_VIDEO_TYPE.includes(file.type))
      ) {
        showSnackbar(translate('giftShop:invalidFormatData'))
        setLoadingImg(false)
        return
      }

      const image = await handleGiftshopReadFile(
        file,
        undefined,
        false,
        contentType === 'video',
      )
      if (image !== null) {
        setLoadingImg(false)
        setRevisionData({id: contentId, image})
      } else {
        setLoadingImg(false)
      }
    },
    [contentId, contentType, translate],
  )

  const handleLoadFiles = useCallback(
    (files: File[]) => {
      setLoadingImg(true)
      Array.from(files).forEach((file) => {
        handleReadFile(file)
      })
    },
    [handleReadFile],
  )

  const handleNavigateProfile = useCallback(() => {
    history.replace('giftshop_profile', {self: true}, username)
  }, [history, username])

  const handleRenderContent = useMemo(
    () => (
      <GiftShopResendRevisionContent
        revisionData={revisionData}
        loadingImg={loadingImg}
        onAddPhoto={() => ref.current?.click()}
        onUpload={handleUploadRevision}
        loadingBtn={loadingBtn}
      />
    ),
    [handleUploadRevision, loadingBtn, loadingImg, revisionData],
  )

  const handleRenderModalBottomSection = useMemo(
    () => (
      <StyledTryAgain>
        <StyledButton
          label={translate('global:close')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={handleNavigateProfile}
        />
        <StyledButton
          label={translate('global:tryAgain')}
          onClick={() => setResendModal('none')}
        />
      </StyledTryAgain>
    ),
    [handleNavigateProfile, setResendModal, translate],
  )

  const handleRenderInputPicker = useMemo(
    () => (
      <InputPicker
        inputRef={ref}
        onLoadFiles={handleLoadFiles}
        accept={
          contentType === 'photo'
            ? GIFT_SHOP_UPLOAD_TYPE.join(',')
            : GIFT_SHOP_RESEND_VIDEO_TYPE.join(',')
        }
      />
    ),
    [contentType, handleLoadFiles],
  )

  const handleRenderModalResendStatus = useMemo(
    () => (
      <GiftshopTemplateUploadResendModal
        resendModalState={resendModalState}
        bottomElement={handleRenderModalBottomSection}
        success={success}
        total={1}
      />
    ),
    [handleRenderModalBottomSection, resendModalState, success],
  )

  useDidUpdate(() => {
    if (success === 1) {
      setResendModal('success')
      debounce(() => {
        handleNavigateProfile()
      }, 500)
    }
  }, [success, history])

  return (
    <GiftShopRootScreen
      showBadgeIcon={false}
      headerTitle={translate('giftShop:resendRevisionButton')}
      headerSubtitle={translate('global:forUser', {
        nickname: collectorNickname,
      })}>
      {handleRenderInputPicker}
      {handleRenderModalResendStatus}
      <StyledContainer>{handleRenderContent}</StyledContainer>
    </GiftShopRootScreen>
  )
}
