import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getCurrencyValue} from 'utils'
import {Button, CheckboxItem, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadQuickFillModalProps} from './GiftShopUploadQuickFillModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(300)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${convertUnit(8)};
  text-align: center;
`

const StyledCheckItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  width: 100%;
  margin-top: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledCheckboxItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const StyledDescription = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: ${convertUnit(265)};
`

export default function GiftShopUploadQuickFillModal({
  visible,
  data,
  stateQuickFill,
  toggleModal,
}: GiftShopUploadQuickFillModalProps) {
  const {translate} = useTranslation()
  const [selected, setSelected] = stateQuickFill
  const [tempSelected, setTempSelected] = useState(selected)
  const {description, location, price} = data

  const handleRenderBasicPriceDesc = useMemo(
    () => (
      <StyledCheckItemDescription>
        <Paragraph fontWeight="medium">
          {translate('giftShop:uploadQuickFillModalPrice')}
        </Paragraph>
        <StyledDescription fontSize="m" fontWeight="medium" color="primary_5">
          {price && getCurrencyValue(price)}
        </StyledDescription>
      </StyledCheckItemDescription>
    ),
    [price, translate],
  )

  const handleRenderBasicPriceCheckbox = useMemo(
    () =>
      price ? (
        <StyledCheckboxItemContainer>
          <CheckboxItem
            isSelected={tempSelected.price}
            size={20}
            color="gray_5"
            description={handleRenderBasicPriceDesc}
            onClick={() =>
              setTempSelected((prev) => ({...prev, price: !prev.price}))
            }
          />
        </StyledCheckboxItemContainer>
      ) : (
        <></>
      ),
    [handleRenderBasicPriceDesc, price, tempSelected.price],
  )

  const handleRenderLocationDesc = useMemo(
    () => (
      <StyledCheckItemDescription>
        <Paragraph fontWeight="medium">
          {translate('giftShop:uploadQuickFillModalLocation')}
        </Paragraph>
        <StyledDescription fontSize="m" fontWeight="medium" color="primary_5">
          {location.name}
        </StyledDescription>
      </StyledCheckItemDescription>
    ),
    [location.name, translate],
  )

  const handleRenderLocationCheckbox = useMemo(
    () =>
      location.name ? (
        <StyledCheckboxItemContainer>
          <CheckboxItem
            isSelected={tempSelected.location}
            size={20}
            color="gray_5"
            description={handleRenderLocationDesc}
            onClick={() =>
              setTempSelected((prev) => ({...prev, location: !prev.location}))
            }
          />
        </StyledCheckboxItemContainer>
      ) : (
        <></>
      ),
    [handleRenderLocationDesc, location.name, tempSelected.location],
  )

  const handleRenderDescriptionDesc = useMemo(
    () => (
      <StyledCheckItemDescription>
        <Paragraph fontWeight="medium">
          {translate('giftShop:uploadQuickFillModalDescription')}
        </Paragraph>
        <StyledDescription fontSize="m" fontWeight="medium" color="primary_5">
          {description}
        </StyledDescription>
      </StyledCheckItemDescription>
    ),
    [description, translate],
  )

  const handleRenderDescriptionCheckbox = useMemo(
    () =>
      description ? (
        <StyledCheckboxItemContainer>
          <CheckboxItem
            isSelected={tempSelected.description}
            size={20}
            color="gray_5"
            description={handleRenderDescriptionDesc}
            onClick={() =>
              setTempSelected((prev) => ({
                ...prev,
                description: !prev.description,
              }))
            }
          />
        </StyledCheckboxItemContainer>
      ) : (
        <></>
      ),
    [description, handleRenderDescriptionDesc, tempSelected.description],
  )

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={() => {
            toggleModal()
            setTempSelected(selected)
          }}
        />
        <StyledButton
          label={translate('global:save')}
          disabled={tempSelected === selected}
          onClick={() => {
            setSelected(tempSelected)
            toggleModal()
          }}
        />
      </StyledButtonsContainer>
    ),
    [selected, setSelected, tempSelected, toggleModal, translate],
  )

  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="l" fontWeight="medium">
        {translate('giftShop:uploadQuickFillModalTitle')}
      </Paragraph>
      {handleRenderBasicPriceCheckbox}
      {handleRenderLocationCheckbox}
      {handleRenderDescriptionCheckbox}
      {handleRenderButtons}
    </StyledModal>
  )
}
