import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_CREATION_CONTENT,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {useHistory} from 'utils'
import {ActivityIndicator, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateSearchCreationItem} from 'pages/template'
import {TransactionRootEmpty} from 'pages/transaction'
import {GiftshopProfileSearchCoverProps} from './GiftshopProfileSearchCoverProps'

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

export default function GiftshopProfileSearchCreationContent({
  stateSearchQuery,
}: GiftshopProfileSearchCoverProps) {
  const history = useHistory()
  const searchQuery = stateSearchQuery[0]
  const stateData = useState<ReadonlyArray<GiftShopContentData>>([])
  const emptyListImage = IMAGE_ASSET('giftshop', 'empty-list.png')

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id,
    [],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      if (searchQuery.length < 3) return []
      const response = await requestData('giftshop_get_creation', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_CREATION_CONTENT,
        params: {
          page,
          limit,
          q: searchQuery,
        },
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result.data
        : []
    },
    [searchQuery],
  )

  const handleClickItem = useCallback(
    (item: GiftShopContentData) => {
      history.push(
        'giftshop_profile_creation_detail',
        {
          data: [item],
          selectedItemId: item.content_id,
        },
        item.creator_name,
      )
    },
    [history],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <TemplateSearchCreationItem onClickItem={handleClickItem} data={item} />
    ),
    [handleClickItem],
  )

  const handleRenderEmpty = useMemo(
    () =>
      searchQuery.length < 3 ? (
        <StyledLoadingContainer>
          <ActivityIndicator iconColor="gray_3" iconSize={50} />
          <Paragraph color="gray_3">
            {translate('giftShop:searchInputMinThreeChar')}
          </Paragraph>
        </StyledLoadingContainer>
      ) : (
        <TransactionRootEmpty
          image={emptyListImage}
          imageAlt="NoTransaction"
          buttonRoute="giftshop_robopet"
          message={translate(
            searchQuery.length > 0
              ? 'giftShop:searchCreationNotFoundTitle'
              : 'giftShop:noContent',
          )}
          subMessage={translate(
            searchQuery.length > 0
              ? 'giftShop:searchCreationNotFoundMessage'
              : 'giftShop:allContent',
          )}
        />
      ),
    [emptyListImage, searchQuery.length],
  )

  return (
    <ListLazy
      stateData={stateData}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderItem}
      loadData={handleLoadData}
      search={searchQuery}
      backgroundColor="white_2"
      listEmptyElement={searchQuery.length ? handleRenderEmpty : <></>}
    />
  )
}
