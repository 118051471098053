import {useCallback, useMemo} from 'react'
import {
  GIFT_SHOP_MAX_FILE_NAME,
  GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_EXTENSION,
  GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME,
  GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_SECONDS,
} from 'consts'
import {requestData} from 'services'
import {
  GiftShopUploadSeriesData,
  GiftShopUploadVideoSendToFaceFormData,
  GiftshopUploadPublishFormData,
  GiftshopUploadPublishSeriesValues,
  GiftshopUploadPublishSeriesVideoParam,
} from 'types'
import {getFileName, handleUploadSingleFileToWasabi, useHistory} from 'utils'
import {useSelector} from 'lib/redux'

export function useGiftShopUploadPublishSeriesVideo({
  mapRef,
}: GiftshopUploadPublishSeriesVideoParam) {
  const {map, setMapValue, updateMap} = mapRef
  const history = useHistory()
  const {username} = useSelector('user') || {}

  const handleFinished = useCallback(() => {
    const currentEntries = Object.entries(map.current.mapForm)
    const currentTotal = currentEntries.length
    const currentSuccess = currentEntries.filter(
      ([, {status}]) => status === 'success',
    ).length

    if (currentTotal === currentSuccess) {
      setTimeout(
        () => history.push('giftshop_profile', {self: true}, username),
        1000,
      )
    }
  }, [history, map, username])

  const handleGetLink = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      videoFace: GiftShopUploadVideoSendToFaceFormData,
      totalSize: number,
    ) => {
      const {size, height, width} = item.image

      if (videoFace) {
        const {
          height: sendToFaceHeight,
          width: sendToFaceWidth,
          size: sendToFaceSize,
          extension: sendToFaceExtension,
        } = videoFace

        const response = await requestData('giftshop_post_upload_video_link', {
          data: {
            send_to_face: {
              extension: sendToFaceExtension,
              height: sendToFaceHeight,
              size: sendToFaceSize,
              width: sendToFaceWidth,
            },
            video: {
              extension: GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_EXTENSION,
              height,
              size,
              width,
              duration: GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_SECONDS * 1000,
            },
            series: {
              total_size: totalSize,
            },
          },
        })

        return typeof response !== 'string' && response.status === 200
          ? response.data.result
          : null
      }
      return null
    },
    [],
  )

  const handlePublishSendToFaceToWasabi = useCallback(
    async (link: string, videoFace: GiftShopUploadVideoSendToFaceFormData) => {
      if (videoFace) {
        const {
          height: sendToFaceHeight,
          width: sendToFaceWidth,
          extension: sendToFaceExtension,
          file: sendToFaceFile,
        } = videoFace
        const mime = `image/${sendToFaceExtension}`

        try {
          return handleUploadSingleFileToWasabi(sendToFaceFile, link, mime, {
            'Content-Type': mime,
            'x-amz-meta-imagewidth': sendToFaceWidth.toString(),
            'x-amz-meta-imageheight': sendToFaceHeight.toString(),
          })
        } catch (error) {
          return false
        }
      }
      return false
    },
    [],
  )

  const handlePublishVideoToWasabi = useCallback(
    async (item: GiftshopUploadPublishFormData, link: string) => {
      const {file, width, height} = item.image
      const mime = GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME

      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperties = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      videoLink: string,
      faceLink: string,
      seriesId: string,
    ) => {
      const {
        image,
        currencyId,
        description,
        location,
        price,
        originalAt,
        tags,
        peopleTag,
      } = item
      const {file, height, width, size} = image
      const customExif = {
        duration: GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_SECONDS * 1000,
      }
      const response = await requestData('giftshop_post_upload_video', {
        data: {
          resend: true,
          title: getFileName(file.name, GIFT_SHOP_MAX_FILE_NAME),
          description,
          content: videoLink,
          currency_id: currencyId,
          height,
          is_parent: true,
          location,
          original_at: originalAt,
          series_id: seriesId,
          size,
          unix_original_at: Math.floor(new Date(originalAt).getTime() / 1000),
          width,
          exif: JSON.stringify(customExif),
          nickname: peopleTag.map((tag) => tag.nickname).join(','),
          price: parseInt(price, 10),
          send_to_face: faceLink,
          tag_ids: tags.map((tag) => tag.tag_id),
        },
      })
      return typeof response !== 'string' && response.status === 200
    },
    [],
  )

  const handlePublishVideo = useCallback(
    async ({id, parent: parentId}: GiftShopUploadSeriesData) => {
      const variants = Object.values(map.current.mapForm).filter(
        (variant) => variant.item.series === id,
      )
      const item = variants.find((variant) => variant.id === parentId)?.item

      if (item && item.image.videoFace) {
        const {videoFace} = item.image
        const {size: sendToFaceSize} = videoFace

        const totalSize = item.size + sendToFaceSize
        const link = await handleGetLink(item, videoFace, totalSize)
        const isSuccess =
          !!link &&
          (await handlePublishVideoToWasabi(item, link.video_link)) &&
          (await handlePublishSendToFaceToWasabi(
            link.send_to_face_link,
            videoFace,
          )) &&
          (await handlePublishProperties(
            item,
            link.video_link,
            link.send_to_face_link,
            id,
          ))

        setMapValue({
          mapForm: {
            ...map.current.mapForm,
            [item.image.id]: {
              item,
              id: item.image.id,
              status: isSuccess ? 'success' : 'failed',
            },
          },
        })
        updateMap()
        handleFinished()
      }
    },
    [
      handleFinished,
      handleGetLink,
      handlePublishProperties,
      handlePublishSendToFaceToWasabi,
      handlePublishVideoToWasabi,
      map,
      setMapValue,
      updateMap,
    ],
  )

  return useMemo<GiftshopUploadPublishSeriesValues>(
    () => ({publishSeries: handlePublishVideo}),
    [handlePublishVideo],
  )
}
