import React, {useCallback, useMemo, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopLocationModal} from 'pages'
import {IconType, PlantTreeForm} from 'types'
import {useDidMount} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreePlantTypeContent} from '../TreeTypeContent'
import {TreePlantSelectedLocation} from '../TreePlantSelectedLocation'
import {TreePlantTreeUppperFormProps} from './TreePlantTreeFormProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
`

const StyledTitle = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
  margin-bottom: ${convertUnit(5)};
`

const StyledDescription = styled(Paragraph)`
  margin-bottom: ${convertUnit(5)};
  white-space: pre-wrap;
`

const StyledMapContainer = styled.div`
  display: flex;
  border-radius: ${convertUnit(8)};
`

export default function TreePlantTreeBottomForm({
  form,
}: TreePlantTreeUppperFormProps) {
  const {translate} = useTranslation()
  const {watch, register} = form
  const {latitude, longitude, location, cityName} = watch()
  const [mapModal, setMapModal] = useState(false)
  const [currentMap, setCurrentMap] = useState<UseFormMethods<PlantTreeForm>>()

  const handleIcon = useCallback(
    (icon: IconType) => (
      <Icon
        size={16}
        marginRight={convertUnit(10)}
        color="primary_5"
        type={icon}
      />
    ),
    [],
  )

  const handleChooseType = useMemo(
    () => (
      <>
        <StyledTitle fontSize="m" fontWeight="medium">
          {translate('tree:type')}
        </StyledTitle>
        <TreePlantTypeContent form={form} />
      </>
    ),
    [form, translate],
  )

  const handleClickLocation = useCallback(() => {
    setMapModal(true)
    setCurrentMap(form)
  }, [form])

  const handleRenderLocationModal = useMemo(
    () => (
      <GiftShopLocationModal
        showField="fototree"
        mapForm={currentMap}
        toggleModal={() => setMapModal((prev) => !prev)}
        visible={mapModal}
        onSave={() => setCurrentMap(undefined)}
        messageField={translate('tree:whereToPlantFotoTree')}
      />
    ),
    [currentMap, mapModal, translate],
  )

  const handleRenderMap = useMemo(
    () =>
      longitude !== 0 &&
      latitude !== 0 && (
        <StyledMapContainer>
          <TreePlantSelectedLocation
            longitude={longitude}
            latitude={latitude}
          />
        </StyledMapContainer>
      ),
    [latitude, longitude],
  )

  const handleChooseLocation = useMemo(
    () => (
      <>
        <StyledTitle fontSize="m" fontWeight="medium">
          {translate('tree:location')}
        </StyledTitle>
        <StyledDescription color="gray_3">
          {longitude === 0 && latitude === 0
            ? translate('tree:locationPlaceholder')
            : location === ''
            ? `${latitude}, ${longitude}`
            : JSON.parse(location).name}
          {cityName && `\n${cityName}`}
        </StyledDescription>
        {handleRenderMap}
        <Button
          label={
            longitude === 0 && latitude === 0
              ? translate('tree:chooseLocationButton')
              : translate('tree:changeLocationButton')
          }
          color="primary_5"
          backgroundColor="white_3"
          onClick={handleClickLocation}
          leftIcon={handleIcon('location')}
        />
      </>
    ),
    [
      cityName,
      handleClickLocation,
      handleIcon,
      handleRenderMap,
      latitude,
      location,
      longitude,
      translate,
    ],
  )

  useDidMount(() => {
    register('latitude')
    register('longitude')
    register('location')
    register('cityName')
  })

  return (
    <StyledContainer>
      {handleChooseType}
      {handleChooseLocation}
      {handleRenderLocationModal}
    </StyledContainer>
  )
}
