import React, {useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {TemplateAuthAccessFullPage} from 'pages'
import {useWindowMode} from 'windows'
import {Icon, Modal} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateAuthAccessModalProps} from './TemplateAuthAccessModalProps'

const StyledModal = styled.div<React.CSSProperties>`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

const StyledDesktopModal = styled(Modal)`
  width: ${convertUnit(375)};
  padding: ${convertUnit(25)};
`

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: ${convertUnit(25)} 0;
    padding-bottom: 0;
  }
`

const StyledIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding-right: ${convertUnit(25)};
  }
`

const StyledCloseIcon = styled.div`
  cursor: pointer;
`

export default function TemplateAuthAccessModal({
  visible,
  toggleModal,
}: TemplateAuthAccessModalProps) {
  const mode = useWindowMode()

  const handleRenderCloseIcon = useMemo(
    () => (
      <StyledIconContainer>
        <StyledCloseIcon>
          <Icon
            type="close"
            size={convertUnit(20)}
            onClick={toggleModal}
            color="primary_5"
          />
        </StyledCloseIcon>
      </StyledIconContainer>
    ),
    [toggleModal],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        {handleRenderCloseIcon}
        <TemplateAuthAccessFullPage onClose={() => toggleModal()} />
      </StyledContainer>
    ),
    [handleRenderCloseIcon, toggleModal],
  )

  const handleRenderNonDesktop = useMemo(
    () => (visible ? <StyledModal>{handleRenderContent}</StyledModal> : null),
    [handleRenderContent, visible],
  )

  return mode === 'mobile' ? (
    handleRenderNonDesktop
  ) : (
    <StyledDesktopModal visible={visible}>
      {handleRenderContent}
    </StyledDesktopModal>
  )
}
