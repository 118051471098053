import React from 'react'
import { useTranslation } from 'i18n'
import { Paragraph } from 'common/components'

export default function GiftShopMessageRoomContentItemUnsupported() {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m" color="gray_5">
      {translate('messages:unsupported')}
    </Paragraph>
  )
}
