import { createStore } from 'redux'
import { REDUX_PERSIST_CONFIG } from 'consts'
import { persistStore, persistReducer } from 'redux-persist'
import { REDUX_REDUCER } from './ReduxReducer'

const persistedReducer = persistReducer(
  REDUX_PERSIST_CONFIG,
  REDUX_REDUCER,
)

export const REDUX_STORE = createStore(persistedReducer)
export const REDUX_PERSISTOR = persistStore(REDUX_STORE)
