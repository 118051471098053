import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useDidMount} from 'utils'
import {Button, Input, Modal, Paragraph} from 'common/components'
import {VALIDATION_CREATE_PASSWORD} from 'common/validations'
import convertUnit from 'lib/unit'
import {TransactionWithdrawValidationProps} from './TransactionWithdrawValidationProps '
import {TransactionWithdrawConfirmationItem} from '../ConfirmationItem'

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(12)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${convertUnit(25)};
`

const StyledButton = styled(Button)`
  width: ${convertUnit(125)};
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-top: ${convertUnit(12)};
`

export default function TransactionWithdrawValidation({
  disabledButton,
  isLoading,
  onClickCancel,
  onClickConfirm,
  withdrawForm,
  accountDetails,
  withdrawTotal,
}: TransactionWithdrawValidationProps) {
  const {translate} = useTranslation()

  const {handleSubmit, register, errors, getValues} = withdrawForm
  const {password} = getValues()

  useDidMount(() => {
    register('password')
  })

  return (
    <StyledModal visible>
      <StyledParagraph fontSize="l" fontWeight="bold" color="primary_5">
        {translate('giftShop:withdrawConfirmation')}
      </StyledParagraph>
      <TransactionWithdrawConfirmationItem
        upperText={translate('giftShop:accountLabel')}
        lowerText={translate('giftShop:bankAccountDetail', {
          bankName: accountDetails.bank_name,
          name: accountDetails.account_name,
        })}
      />
      <StyledRow>
        <TransactionWithdrawConfirmationItem
          upperText={translate('giftShop:addBankInputAccountNumberLabel')}
          lowerText={accountDetails.account_number}
        />
        <TransactionWithdrawConfirmationItem
          upperText={translate('giftShop:totalWithdraw')}
          lowerText={withdrawTotal}
          lowerFontWeight="bold"
          lowerTextColor="primary_5"
        />
      </StyledRow>
      <Input
        type="password"
        name="password"
        form={withdrawForm}
        formRules={VALIDATION_CREATE_PASSWORD}
        placeholder={translate('giftShop:withdrawEnterPasswordPlaceholder')}
        containerStyle={{
          marginTop: convertUnit(18),
        }}
        rightIcon="visibility"
      />

      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          fontSize="m"
          fontWeight="medium"
          onClick={onClickCancel}
          backgroundColor="white_3"
          color="primary_5"
        />
        <StyledButton
          label={translate('global:confirm')}
          fontSize="m"
          fontWeight="medium"
          isLoading={isLoading}
          disabled={
            Object.keys(errors).length > 0 ||
            disabledButton ||
            password === undefined
          }
          disableColor="gray_1"
          color={errors.password !== undefined ? 'gray_3' : 'white_1'}
          onClick={handleSubmit(onClickConfirm)}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
