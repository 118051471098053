import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useTheme} from 'themes'
import {Input, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadContentLocationFormProps} from './GiftShopUploadContentFormProps'

const StyledError = styled(Paragraph)`
  ${({theme}) => ({color: theme.danger_5})}
  box-sizing: border-box;
  margin-top: ${convertUnit(7)};
  margin-bottom: ${convertUnit(2)};
`

export default function GiftShopUploadContentLocationForm({
  form,
  location,
  onAddLocation,
}: GiftShopUploadContentLocationFormProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const {errors, setValue, register} = form
  return (
    <>
      <Input
        value={location && JSON.parse(location).name}
        label={translate('global:location')}
        placeholder={translate('giftShop:uploadInputLocationPlaceholder')}
        autoComplete={false}
        containerStyle={{marginTop: '25px'}}
        inputContainerStyle={
          errors.location ? {borderColor: theme.danger_5} : undefined
        }
        onClick={() => onAddLocation && onAddLocation(form)}
        rightIcon="delete"
        onRightIconClick={() => setValue('location', '')}
      />
      <input
        value={location}
        readOnly
        name="location"
        type="hidden"
        {...register('location', {
          required: translate('global:validationRequired'),
        })}
      />
      {errors.location && (
        <StyledError fontWeight="medium">{errors.location.message}</StyledError>
      )}
    </>
  )
}
