import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getThemeColor} from 'utils'
import {Icon, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfilePhotoModalProps} from './TemplateProfilePhotoModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(420)};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${convertUnit(660)};
  overflow: hidden;
  background-color: ${({theme}) => theme.black_1};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`

const StyledBackButton = styled.a`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${convertUnit(15)};
`

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(10)} ${convertUnit(15)};
  ${({theme}) => ({
    background: `linear-gradient(360deg, #00000000 0%, ${getThemeColor(
      theme,
      'black',
      0.5,
    )} 100%) `,
  })}
`

const StyledContentContainer = styled.div`
  width: 100%;
  height: 100%;
  align-content: center;
`

const StyledImage = styled(Image)`
  margin: ${convertUnit(24)} 0;
  max-height: 80vh;
  width: 100%;
`

export default function TemplateProfilePhotoModal({
  src,
  title,
  toggleModal,
}: TemplateProfilePhotoModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible onBackdropPress={toggleModal}>
      <StyledHeader>
        <StyledBackButton onClick={toggleModal}>
          <Icon type="back" size={convertUnit(15)} color="white_1" />
        </StyledBackButton>
        <Paragraph fontSize="m" fontWeight="bold" color="white_1">
          {title || translate('giftShop:profilePhoto')}
        </Paragraph>
      </StyledHeader>
      <StyledContentContainer>
        <StyledImage src={src} alt="Profile Photo" />
      </StyledContentContainer>
    </StyledModal>
  )
}
