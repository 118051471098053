import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getContentURL, getCurrencyValue} from 'utils'
import {useTranslation} from 'i18n'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentCheckoutContentItemProps} from './PaymentCheckoutContentProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(10)} 0;
  align-items: center;
`

const StyledImage = styled(Image)`
  height: ${convertUnit(64)};
  width: ${convertUnit(64)};
  border-radius: ${convertUnit(8)};
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${convertUnit(25)};
  margin-right: ${convertUnit(10)};
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledSubInfo = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`

const StyledParagraph = styled(Paragraph)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
`

const StyledStrikeThroughParagraph = styled(Paragraph)`
  text-decoration: line-through;
  margin-top: ${convertUnit(2)};
`

export default function PaymentCheckoutContentItem({
  data: {price, title, url, creator_name, type},
  discount,
  isFree,
}: PaymentCheckoutContentItemProps) {
  const {translate} = useTranslation()

  const isDiscounted = useMemo(
    () => discount && discount.discount_percentage && price > 0,
    [discount, price],
  )

  return (
    <StyledContainer>
      <StyledImage src={getContentURL({url, type})} alt={title} />
      <StyledDescriptionContainer>
        {isDiscounted ? (
          <Paragraph fontSize="xs" fontWeight="bold" color="primary_5">
            {`${translate('giftShop:discount')} ${
              discount?.discount_percentage
            }%`}
          </Paragraph>
        ) : (
          <></>
        )}
        <StyledParagraph fontSize="m" fontWeight="bold">
          {title}
        </StyledParagraph>
        <StyledSubInfo>{creator_name}</StyledSubInfo>
      </StyledDescriptionContainer>
      <StyledPriceContainer>
        <Paragraph fontWeight="medium" color="gray_5">
          {translate('transaction:checkoutContentPrice')}
        </Paragraph>
        {isFree || isDiscounted ? (
          <StyledStrikeThroughParagraph
            fontSize="s"
            fontWeight="bold"
            color="gray_3">
            {getCurrencyValue(price)}
          </StyledStrikeThroughParagraph>
        ) : (
          <></>
        )}
        <StyledSubInfo fontSize="m" fontWeight="bold" color="primary_5">
          {getCurrencyValue(
            isFree
              ? 0
              : isDiscounted && discount
              ? discount?.price_after_discount
              : price,
          )}
        </StyledSubInfo>
      </StyledPriceContainer>
    </StyledContainer>
  )
}
