import React from 'react'
import styled from 'styled-components'
import {POLICY_NAVBAR_ITEM_LABEL_WIDTH, POLICY_ROOT_MENU_HEIGHT} from 'consts'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PolicyRootNavBarItemProps} from './PolicyRootNavBarItemProps'

interface StyledContainerProps {
  isSelected: boolean
  showLabel?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({isSelected, theme}) =>
    isSelected
      ? {
          backgroundColor: theme.primary_5,
        }
      : {
          ':hover': {
            backgroundColor: theme.primary_5,
          },
        }}
  display: flex;
  width: ${convertUnit(POLICY_NAVBAR_ITEM_LABEL_WIDTH)};
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: ${convertUnit(10)};
  height: ${convertUnit(POLICY_ROOT_MENU_HEIGHT)};
  margin-top: ${convertUnit(10)};
  margin-left: ${convertUnit(18)};
  margin-right: ${convertUnit(18)};
  padding: ${convertUnit(10)};
  align-items: center;
  direction: ltr;
  :hover {
    cursor: pointer;
  }
  transition: all 0.15s ease-out;
`

const StyledLabel = styled(Paragraph)<StyledContainerProps>`
  ${({isSelected, theme}) =>
    isSelected
      ? {
          color: theme.white_1,
        }
      : {
          color: theme.black,
          ':hover': {
            color: theme.white_1,
          },
        }}
  width:100%;
  box-sizing: border-box;
  padding: ${convertUnit(20)};
`

export default function PolicyRootNavBarItem({
  isSelected,
  label,
  onClick,
}: PolicyRootNavBarItemProps) {
  return (
    <StyledContainer
      isSelected={isSelected}
      onClick={isSelected ? undefined : onClick}>
      <StyledLabel isSelected={isSelected} fontSize="l" fontWeight="bold">
        {label}
      </StyledLabel>
    </StyledContainer>
  )
}
