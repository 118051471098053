import daysjs from 'dayjs'
import clustersDbscan from '@turf/clusters-dbscan'
import {point} from '@turf/helpers'
import {TREE_MAP_CREATOR_CATWALK, TREE_MAX_EVENT_DATE} from 'consts'
import {GiftshopLiveLocationData, numberTuple} from 'types'

export function fototreeMaxEventDate(eventStartDate: string) {
  const startDate = daysjs(eventStartDate)
  const maxDate = startDate.add(TREE_MAX_EVENT_DATE - 1, 'day')
  return maxDate.format('YYYY-MM-DD')
}

export function fototreeLocationName(name?: string) {
  if (
    !name ||
    name.trim() === '' ||
    name.startsWith('-') ||
    (!isNaN(parseFloat(name)) && isFinite(parseFloat(name))) // numeric coordinate
  ) {
    return null
  }

  return name
}

export function getCreatorPointsWithinRadius(
  data: GiftshopLiveLocationData[],
  radius = TREE_MAP_CREATOR_CATWALK,
): numberTuple[][] {
  if (!data) return []

  const points = data.map(({location}) => point([location.long, location.lat]))

  const clustered = clustersDbscan(
    {type: 'FeatureCollection', features: points},
    radius,
    {minPoints: 2, units: 'meters'},
  )

  const clusters: numberTuple[][] = []
  const clusterMap: {[key: number]: numberTuple[]} = {}

  clustered.features.forEach((feature) => {
    const clusterId = feature.properties?.cluster
    const coordinates = feature.geometry.coordinates as numberTuple

    if (clusterId !== undefined) {
      if (!clusterMap[clusterId]) {
        clusterMap[clusterId] = []
      }
      clusterMap[clusterId].push(coordinates)
    }
  })

  Object.values(clusterMap).forEach((group) => {
    if (group.length > 1) {
      clusters.push(group)
    }
  })
  return clusters
}
