import React from 'react'
import {useTranslation} from 'i18n'
import {ConfirmationModal} from 'common/components'
import {GiftShopTemplateResendpologyConfirmationModalProps} from './GiftShopTemplateResendpologyConfirmationModalProps'

export default function GiftShopTemplateResendpologyConfirmationModal({
  visible,
  onConfirm,
  toggleModal,
}: GiftShopTemplateResendpologyConfirmationModalProps) {
  const {translate} = useTranslation()

  return (
    <ConfirmationModal
      visible={visible}
      title={translate('giftShop:deleteResendpologyConfirmationModalTitle')}
      text={translate(
        'giftShop:deleteResendpologyConfirmationModalDescription',
      )}
      onConfirm={onConfirm}
      toggleModal={toggleModal}
      confirmText={translate('global:yesImSure')}
      cancelText={translate('global:cancel')}
    />
  )
}
