import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  ROBOYU_SENSITIVITY_LEVEL_MAX,
  ROBOYU_SENSITIVITY_LEVEL_MIN,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {InputRange, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopExploreSensitivitySliderProps} from './GiftShopExploreSensitivitySliderProps'

const StyledSliderContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(600)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${convertUnit(20)};
  margin-left: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-left: 0;
  }
`

const StyledInputRangeParagraph = styled(Paragraph)`
  white-space: nowrap;
`

const StyledInputRangeContainer = styled.div`
  width: 100%;
`

const StyledAccuracyDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default function GiftShopExploreSensitivitySlider({
  stateRangeValue,
  onSlide,
}: GiftShopExploreSensitivitySliderProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()

  const handleRenderInputRangeLeftDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'left'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'black'}>
          {translate('giftShop:roboyuAccuracyMediumDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledInputRangeParagraph fontWeight="medium" color="gray_5">
            {translate('global:medium')}
          </StyledInputRangeParagraph>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  const handleRenderInputRangeRightDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'right'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'black'}>
          {translate('giftShop:roboyuAccuracyHighDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledInputRangeParagraph fontWeight="medium" color="gray_5">
            {translate('global:high')}
          </StyledInputRangeParagraph>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  return (
    <StyledSliderContainer>
      <StyledInputRangeContainer>
        <InputRange
          showTick
          stateValue={stateRangeValue}
          min={ROBOYU_SENSITIVITY_LEVEL_MIN}
          max={ROBOYU_SENSITIVITY_LEVEL_MAX}
          leftDesc={handleRenderInputRangeLeftDesc}
          rightDesc={handleRenderInputRangeRightDesc}
          onSlide={onSlide}
          descPosition="side"
        />
      </StyledInputRangeContainer>
    </StyledSliderContainer>
  )
}
