import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {getWhatsAppLink, getInstagramLink} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

export interface GiftShopSettingSupportProps {
  isHorizontal?: boolean
}

const StyledSettingContainer = styled.div`
  width: 100%;
  padding: ${convertUnit(20)} 0;
`

const StyledInBalanceContainer = styled.div`
  width: 100%;
  padding: ${convertUnit(8)} 0;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_1};
  border-bottom: ${convertUnit(1)} solid ${({theme}) => theme.white_4};
  border-top: ${convertUnit(1)} solid ${({theme}) => theme.white_4};
`

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: ${convertUnit(4)};
`

const StyledLabelEndContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${convertUnit(4)};
  padding: ${convertUnit(8)};
`

const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: ${convertUnit(20)};
  width: ${convertUnit(20)};
  padding: ${convertUnit(12)};
  border-radius: ${convertUnit(28)};
  background-color: ${({theme}) => theme.white_3};
  margin-right: ${convertUnit(12)};
  cursor: pointer;
`

const StyledParagraph = styled(Paragraph)`
  margin-right: ${convertUnit(17)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(20)};
  height: ${convertUnit(20)};
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function GiftShopSettingSupport({
  isHorizontal,
}: GiftShopSettingSupportProps) {
  const {translate} = useTranslation()
  const whatsappImg = IMAGE_ASSET('giftshop', 'whats-app.png')
  const instagramImg = IMAGE_ASSET('giftshop', 'instagram.png')

  const handleRenderLink = useMemo(
    () => (
      <>
        <StyledLink href={getWhatsAppLink()} target="_blank">
          <StyledImagesContainer>
            <StyledImage src={whatsappImg} alt="whats-app" />
          </StyledImagesContainer>
        </StyledLink>
        <StyledLink href={getInstagramLink()} target="_blank">
          <StyledImagesContainer>
            <StyledImage src={instagramImg} alt="instagram" />
          </StyledImagesContainer>
        </StyledLink>
      </>
    ),
    [instagramImg, whatsappImg],
  )

  return (
    <>
      {isHorizontal ? (
        <StyledInBalanceContainer>
          <StyledLabelEndContainer>
            <StyledParagraph fontSize="m" fontWeight="bold">
              {translate('giftShop:supportTitle')}
            </StyledParagraph>
            {handleRenderLink}
          </StyledLabelEndContainer>
        </StyledInBalanceContainer>
      ) : (
        <StyledSettingContainer>
          <StyledLabelContainer>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('giftShop:supportTitle')}
            </Paragraph>
          </StyledLabelContainer>
          <StyledLabelContainer>
            <Paragraph fontSize="s" fontWeight="regular" color="gray_4">
              {translate('giftShop:supportSubTitle')}
            </Paragraph>
          </StyledLabelContainer>
          <StyledLabelContainer>{handleRenderLink}</StyledLabelContainer>
        </StyledSettingContainer>
      )}
    </>
  )
}
