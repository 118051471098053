import React, {useMemo} from 'react'
import {useHistory} from 'utils'
import {GiftShopRootScreen} from '../../root'
import {GiftShopErrorNotFound} from '../containers'

export default function GiftShopErrorNotFoundScreen() {
  const history = useHistory()
  const isTherePreviousPage = useMemo(() => history.length > 1, [history])

  return (
    <GiftShopRootScreen
      headerTitle=""
      headerBorderColor={!isTherePreviousPage ? 'white_1' : undefined}
      showBadgeIcon={false}
      showBackIcon={isTherePreviousPage}>
      <GiftShopErrorNotFound />
    </GiftShopRootScreen>
  )
}
