import React, {ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {parseShape, UserSimilarAccountData} from 'types'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'

const StyledItemContainer = styled.div`
  display: flex;
  padding: ${convertUnit(12)} 0;
  align-items: center;
`
const StyledItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: ${convertUnit(16)};
`
const StyledUsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
`
const StyledCurrentAccountContainer = styled.div`
  background-color: ${({theme}) => theme.info_5};
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
`
const StyledParagraph = styled(Paragraph)`
  word-break: break-all;
  display: inline-block;
`

interface GiftShopTemplateSimilarAccountItemProps {
  data: UserSimilarAccountData
  showCurrentLabel: boolean
  rightComponent: ReactElement
  isMultiAccount?: boolean
}
export default function GiftShopTemplateSimilarAccountItem({
  data,
  showCurrentLabel,
  rightComponent,
  isMultiAccount = false,
}: GiftShopTemplateSimilarAccountItemProps) {
  const {translate} = useTranslation()

  const handleParseCollectionText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<count>.*?<\/count>/,
        style: {
          fontFamily: 'roboto-medium',
        },
        renderText: (text) => text.replace(/<count>|<\/count>/g, ''),
      },
    ],
    [],
  )
  return (
    <StyledItemContainer>
      <StyledItemTextContainer>
        <StyledUsernameContainer>
          <Paragraph fontSize="m" fontWeight="medium">
            {data.username}
          </Paragraph>
          {showCurrentLabel && (
            <StyledCurrentAccountContainer>
              <Paragraph color="white_1" fontWeight="medium">
                {translate('giftShop:similarCurrentAccount')}
              </Paragraph>
            </StyledCurrentAccountContainer>
          )}
        </StyledUsernameContainer>
        {isMultiAccount && data.collection && (
          <StyledParagraph color="primary_5">
            <ParsedText parse={handleParseCollectionText}>
              {translate('giftShop:similarMultiAccountCollectionCount', {
                photo_count: data.collection.photo_count || 0,
                video_count: data.collection.video_count || 0,
              })}
            </ParsedText>
          </StyledParagraph>
        )}
        {data.email && (
          <StyledParagraph fontWeight="medium" color="gray_5">
            {data.email}
          </StyledParagraph>
        )}
        {data.phone_code && data.phone_number && (
          <StyledParagraph
            fontWeight="medium"
            color="gray_5">{`+${data.phone_code} ${data.phone_number}`}</StyledParagraph>
        )}
      </StyledItemTextContainer>
      {rightComponent}
    </StyledItemContainer>
  )
}
