import React, {useMemo, useState, useRef, useCallback} from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import {REGEX_NUMBER} from 'consts'
import {useDidMount} from 'utils'
import convertUnit from 'lib/unit'
import {InputOTPProps} from './InputOTPProps'

interface StyledInputContainerProps {
  borderColor: ThemeColor
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const StyledInputContainer = styled.div<StyledInputContainerProps>`
  margin: 0 ${convertUnit(5)};
  border-width: ${convertUnit(2)};
  border-style: solid;
  border-color: ${({theme, borderColor}) => theme[borderColor]};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_2};
  width: ${convertUnit(50)};
  height: ${convertUnit(50)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledInput = styled.input`
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_2};
  appearance: none;
  margin: 0;
  max-width: ${convertUnit(18)};
  font-size: ${convertUnit(18)};
  outline: none;
  box-shadow: none;
  border-width: 0;
  -moz-appearance: textfield;
  text-align: center;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default function InputOTP({boxes = 4, onSubmit}: InputOTPProps) {
  const [focusedField, setFocusedField] = useState(0)
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(boxes).fill(null),
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const currentRef = inputRefs.current[index]
      if (currentRef) currentRef.value = e.target.value.slice(-1)
      const ref = inputRefs.current[index + 1 < boxes ? index + 1 : 0]
      ref && ref.focus()
      onSubmit(inputRefs.current.map((r) => r?.value || '').join(''))
    },
    [boxes, onSubmit],
  )

  useDidMount(() => {
    const firstRef = inputRefs.current[0]
    if (firstRef) firstRef.focus()
  })

  const renderInputBoxes = useMemo(
    () =>
      Array.from({length: boxes}, (_, index) => (
        <StyledInputContainer
          key={index}
          borderColor={focusedField === index ? 'primary_5' : 'gray_1'}>
          <StyledInput
            maxLength={1}
            ref={(instance) => {
              if (instance) {
                inputRefs.current[index] = instance
              }
            }}
            type="number"
            onFocus={() => setFocusedField(index)}
            onKeyPress={(e) => !REGEX_NUMBER.test(e.key) && e.preventDefault()}
            onChange={(e) => handleInputChange(e, index)}
          />
        </StyledInputContainer>
      )),
    [inputRefs, focusedField, boxes, handleInputChange],
  )

  return <StyledContainer>{renderInputBoxes}</StyledContainer>
}
