import React, {useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_FILTER_MAX_DATE} from 'consts'
import {useTranslation} from 'i18n'
import {useTheme} from 'themes'
import {getCurrentRelativeISOString} from 'utils'
import {Icon, Input, Label, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopExploreFilterModalDateTimeFormProps} from './GiftShopExploreFilterModalFormProps'

const StyledInputContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertUnit(20)};
`

const StyledSubtitle = styled(Paragraph)`
  padding: ${convertUnit(8)} 0;
`

const StyledInput = styled(Input)`
  &:disabled {
    background-color: ${({theme}) => theme.white_4};
  }
`

export default function GiftShopExploreFilterModalFormDateTime({
  stateTempFilter,
  stateDateTime,
}: GiftShopExploreFilterModalDateTimeFormProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const [tempFilter, setTempFilter] = stateTempFilter
  const [dateTime, setDateTime] = stateDateTime

  const currentDate = useMemo(() => new Date(), [])
  const handleRenderFromDateRightIcon = useMemo(
    () =>
      tempFilter.startDate === '' ? (
        <Icon type="calendar-event" size={convertUnit(16)} color="gray_5" />
      ) : (
        <Icon
          type="close"
          size={convertUnit(16)}
          color="gray_5"
          onClick={() => {
            setDateTime({
              startDate: '',
              endDate: '',
            })
            setTempFilter((prev) => ({
              ...prev,
              startDate: '',
              endDate: '',
            }))
          }}
        />
      ),
    [setDateTime, setTempFilter, tempFilter.startDate],
  )

  const handleRenderToDateRightIcon = useMemo(
    () =>
      tempFilter.endDate === '' ? (
        <Icon type="calendar-event" size={convertUnit(16)} color="gray_5" />
      ) : (
        <Icon
          type="close"
          size={convertUnit(16)}
          color="gray_5"
          onClick={() => {
            setDateTime((prev) => ({
              ...prev,
              endDate: '',
            }))
            setTempFilter((prev) => ({...prev, endDate: ''}))
          }}
        />
      ),
    [setDateTime, setTempFilter, tempFilter.endDate],
  )

  const handleRenderFromDateForm = useMemo(
    () => (
      <Label label={translate('giftShop:exploreFilterDateTimeLabel')}>
        <StyledSubtitle fontSize="s" fontWeight="medium" color="gray_6">
          {translate('global:from')}
        </StyledSubtitle>
        <Input
          max={GIFT_SHOP_FILTER_MAX_DATE}
          type="datetime-local"
          value={dateTime.startDate}
          autoComplete={false}
          placeholder={translate('giftShop:exploreFilterDatePlaceholder')}
          rightIcon={handleRenderFromDateRightIcon}
          onChangeText={(text) => {
            if (new Date(text).getTime() > currentDate.getTime()) {
              const relativeDateISO = getCurrentRelativeISOString().substring(
                0,
                16,
              )
              setDateTime((prev) => ({...prev, startDate: relativeDateISO}))
              setTempFilter((prev) => ({
                ...prev,
                startDate: relativeDateISO,
              }))
            } else {
              setDateTime((prev) => ({...prev, startDate: text}))
              setTempFilter((prev) => ({
                ...prev,
                startDate: text,
              }))
            }
          }}
          rightIconStyle={{
            pointerEvents: tempFilter.startDate === '' ? 'none' : 'unset',
            position: 'absolute',
            right: 0,
            backgroundColor: theme.white_1,
          }}
        />
      </Label>
    ),
    [
      currentDate,
      dateTime.startDate,
      handleRenderFromDateRightIcon,
      setDateTime,
      setTempFilter,
      tempFilter.startDate,
      theme.white_1,
      translate,
    ],
  )

  const handleRenderToDateForm = useMemo(
    () => (
      <>
        <StyledSubtitle fontSize="s" fontWeight="medium" color="gray_6">
          {translate('global:to')}
        </StyledSubtitle>
        <StyledInput
          max={GIFT_SHOP_FILTER_MAX_DATE}
          type="datetime-local"
          value={dateTime.endDate}
          autoComplete={false}
          placeholder={translate('giftShop:exploreFilterDatePlaceholder')}
          rightIcon={handleRenderToDateRightIcon}
          onChangeText={(text) => {
            if (new Date(text).getTime() > currentDate.getTime()) {
              const relativeDateISO = getCurrentRelativeISOString().substring(
                0,
                16,
              )
              setDateTime((prev) => ({...prev, endDate: relativeDateISO}))
              setTempFilter((prev) => ({
                ...prev,
                endDate: relativeDateISO,
              }))
            } else {
              setDateTime((prev) => ({...prev, endDate: text}))
              setTempFilter((prev) => ({
                ...prev,
                endDate: text,
              }))
            }
          }}
          disabled={tempFilter.startDate === ''}
          rightIconStyle={{
            pointerEvents: tempFilter.endDate === '' ? 'none' : 'unset',
            position: 'absolute',
            right: 0,
            backgroundColor:
              tempFilter.startDate === '' ? theme.white_4 : theme.white_1,
          }}
        />
      </>
    ),
    [
      currentDate,
      dateTime.endDate,
      handleRenderToDateRightIcon,
      setDateTime,
      setTempFilter,
      tempFilter.endDate,
      tempFilter.startDate,
      theme.white_1,
      theme.white_4,
      translate,
    ],
  )

  return (
    <StyledInputContainer>
      {handleRenderFromDateForm}
      {handleRenderToDateForm}
    </StyledInputContainer>
  )
}
