import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {PaymentCheckoutExpandDetailsProps} from 'pages'
import {Paragraph, Icon} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  toggle: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, toggle}) => ({
    backgroundColor: theme.white_2,
    ...(!toggle && {
      ':hover': {
        backgroundColor: theme.gray_1,
      },
    }),
  })}
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(10)};
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: default;
  }
  margin-top: ${convertUnit(8)};
`

const StyledDescriptionContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(10)};
`

const StyledLabel = styled(Paragraph)`
  white-space: pre-line;
  margin-right: ${convertUnit(10)};
  margin-left: ${convertUnit(10)};
  line-height: ${convertUnit(21)};
`

export default function PaymentExpandDetails({
  label,
  description,
  ...props
}: PaymentCheckoutExpandDetailsProps) {
  const [toggle, setToggle] = useState(false)

  const handleDescription = useMemo(
    () => (
      <StyledDescriptionContainer {...props}>
        <StyledLabel fontSize="m">{description}</StyledLabel>
      </StyledDescriptionContainer>
    ),
    [description, props],
  )
  return (
    <>
      <StyledContainer
        {...props}
        toggle={toggle}
        onClick={() => setToggle((previous) => !previous)}>
        <StyledLabel fontSize="m" fontWeight="medium">
          {label}
        </StyledLabel>
        <Icon type="chevron-down" size={16} />
      </StyledContainer>
      {toggle ? handleDescription : undefined}
    </>
  )
}
