import React, {useEffect, useMemo, useState} from 'react'
import {setModalLimitedAccessHandler} from 'utils'
import {ModalLimitedAccessContext} from './ModalLimitedAccessContext'
import {ModalLimitedAccessProviderProps} from './ModalLimitedAccessProps'
import ModalLimitedAccessItem from './ModalLimitedAccessItem'

export default function ModalLimitedAccessProvider({
  children,
}: ModalLimitedAccessProviderProps) {
  const [openModal, setOpenModal] = useState(false)

  const handler = useMemo(
    () => ({
      showModalLimitedAccess: () => setOpenModal(true),
    }),
    [],
  )

  const handleRenderModal = useMemo(() => {
    if (!openModal) return

    return <ModalLimitedAccessItem onClose={() => setOpenModal(false)} />
  }, [openModal])

  useEffect(() => {
    setModalLimitedAccessHandler(handler)
  })

  return (
    <ModalLimitedAccessContext.Provider value={handler}>
      {handleRenderModal}
      {children}
    </ModalLimitedAccessContext.Provider>
  )
}
