import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileModalItemProps} from './TemplateProfileModalItemProps'

const StyledRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${convertUnit(16)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

export default function TemplateProfileModalItem({
  icon,
  label,
  fontSize,
  onClickItem,
}: TemplateProfileModalItemProps) {
  return (
    <StyledRow onClick={onClickItem}>
      <Icon type={icon} color="black" size={24} />
      <Paragraph fontSize={fontSize || 'l'} fontWeight="medium">
        {label}
      </Paragraph>
    </StyledRow>
  )
}
