import {useCallback, useEffect, useMemo, useState} from 'react'
import {CountdownParams, CountdownTime} from 'types'

export function useCountdown({endTime}: CountdownParams) {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(endTime) - +new Date()
    let timeLeft: CountdownTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }, [endTime])

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft()
      if (
        newTimeLeft.hours > 0 ||
        newTimeLeft.minutes > 0 ||
        newTimeLeft.seconds >= 0
      ) {
        setTimeLeft(newTimeLeft)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [calculateTimeLeft, timeLeft])

  return useMemo(() => timeLeft, [timeLeft])
}
