import {
  GiftShopFeeData,
  GiftShopFeeDetail,
  GiftShopFinalFeeData,
  GiftShopFinalFeeDataResponse,
  GiftShopTransactionFeeResponse,
} from 'types'

export const YOUR_PRICE_FEE = 90
export const YOUR_PRICE_FEE_MOBILE = 90

export const INITIAL_PRICING_DETAIL: GiftShopFeeDetail = {
  nominal: 0,
  percentage: 0,
}

export const INITIAL_PRICING: GiftShopFeeData = {
  apple: INITIAL_PRICING_DETAIL,
  google: INITIAL_PRICING_DETAIL,
  web: INITIAL_PRICING_DETAIL,
}

export const INITIAL_FINAL_PRICING: GiftShopFinalFeeData = {
  exclude_tax: INITIAL_PRICING_DETAIL,
  final: INITIAL_PRICING_DETAIL,
  net_creator: INITIAL_PRICING_DETAIL,
}

export const INITIAL_FINAL_PRICING_DATA: GiftShopFinalFeeDataResponse = {
  apple: INITIAL_FINAL_PRICING,
  google: INITIAL_FINAL_PRICING,
  web: INITIAL_FINAL_PRICING,
}

export const INITIAL_PRICING_DATA: GiftShopTransactionFeeResponse = {
  service_fee: INITIAL_PRICING,
  fotoyu_fee: INITIAL_PRICING,
  host_fee: INITIAL_PRICING,
  platform_fee: INITIAL_PRICING,
  price: INITIAL_FINAL_PRICING_DATA,
  tax_fee: INITIAL_PRICING,
}
