import { LanguageData } from 'types'

export const LANGUAGES: LanguageData[] = [
  {
    languageTag: 'id',
    title: 'Bahasa Indonesia',
    race: 'Indonesian',
    isRTL: false,
  },
  {
    languageTag: 'en',
    title: 'English',
    race: 'English',
    isRTL: false,
  },
]
