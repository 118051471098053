import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {Paragraph, TabView} from 'common/components'
import {GiftShopStorageHistoryType} from 'types'
import {GiftShopStorageHistoryProps} from './GiftShopStorageHistoryProps'
import {GiftShopStorageHistoryContent} from '../StorageHistoryContent'
import GiftShopStorageItem from './GiftShopStorageItem'

const StyledContainer = styled.div`
  width: ${convertUnit(580)};
  padding-top: ${convertUnit(30)};
  display: flex;
  flex-direction: column;
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    padding-top: 1;
  }
`

export default function GiftShopStorageHistory({
  stateType,
}: GiftShopStorageHistoryProps) {
  const {translate} = useTranslation()

  const handleRenderTabTitle = useCallback(
    (key: GiftShopStorageHistoryType) =>
      translate(key === 'History' ? 'giftShop:history' : 'giftShop:buy'),
    [translate],
  )

  const handleRenderTabItem = useCallback(
    (key: GiftShopStorageHistoryType) =>
      key === 'History' ? (
        <GiftShopStorageHistoryContent />
      ) : (
        <Paragraph>{translate('giftShop:buy')}</Paragraph>
      ),
    [translate],
  )

  const handleRenderTabView = useMemo(
    () => (
      <TabView
        containerStyle={{marginTop: convertUnit(16)}}
        routes={['History', 'Buy']}
        stateSelect={stateType}
        renderTitle={handleRenderTabTitle}
        renderItem={handleRenderTabItem}
        disabledClick
      />
    ),
    [stateType, handleRenderTabTitle, handleRenderTabItem],
  )

  return (
    <>
      <StyledContainer>
        <GiftShopStorageItem />
        {handleRenderTabView}
        <GiftShopStorageHistoryContent />
      </StyledContainer>
    </>
  )
}
