import {CSSProperties} from 'react'
import {Theme, ThemeColor} from 'themes'
import {CSSObject, CSSPseudos} from 'styled-components'
import convertUnit from 'lib/unit'

export function getThemeColor(
  theme: Theme,
  color: ThemeColor,
  opacity: number = 1,
) {
  return `${theme[color]}${(256 * opacity)
    .toString(16)
    .split('.')[0]
    .toUpperCase()}`
}

export function getTextShadow(
  theme: Theme,
  options: {
    horizontalOffset?: number
    verticalOffset?: number
    blurRadius?: number
    color?: ThemeColor
    opacity?: number
  } = {},
): CSSProperties['textShadow'] {
  const {
    horizontalOffset = 2,
    verticalOffset = 2,
    blurRadius = 4,
    color = 'black',
    opacity = 0.5,
  } = options

  return `${convertUnit(horizontalOffset)} ${convertUnit(
    verticalOffset,
  )} ${convertUnit(blurRadius)} ${getThemeColor(theme, color, opacity)}`
}

export function getBoxShadow(
  theme: Theme,
  options: {
    horizontalOffset?: number
    verticalOffset?: number
    blurRadius?: number
    spreadRadius?: number
    color?: ThemeColor
    opacity?: number
  } = {},
): CSSProperties['boxShadow'] {
  const {
    horizontalOffset = 0,
    verticalOffset = 0,
    blurRadius = 16,
    spreadRadius = 0,
    color = 'black',
    opacity = 0.2,
  } = options

  return `${convertUnit(horizontalOffset)} ${convertUnit(
    verticalOffset,
  )} ${convertUnit(blurRadius)} ${convertUnit(spreadRadius)} ${getThemeColor(
    theme,
    color,
    opacity,
  )}`
}

export function getPadding(
  vertical: number,
  horizontal: number,
): CSSProperties['padding'] {
  return `${convertUnit(vertical)} ${convertUnit(horizontal)}`
}

export function getBorder(
  lineWeight: number,
  style: CSSProperties['borderStyle'],
  color: string,
): CSSProperties['border'] {
  return `${convertUnit(lineWeight)} ${style} ${color}`
}

export function getPseudoStyle<P extends keyof CSSPseudos>(
  pseudo: P,
  style: CSSProperties,
): CSSObject {
  return {[pseudo]: {...style}}
}

export function getIFMTextShadowStyle(theme: Theme) {
  return getTextShadow(theme, {
    horizontalOffset: 0,
    verticalOffset: 1,
    blurRadius: 4,
    color: 'black',
    opacity: 0.6,
  })
}
