import {lazy} from 'react'

const GiftShopRecoverContentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'RecoverContent' */ './GiftShopRecoverContentScreen'
    ),
)

export {GiftShopRecoverContentScreen}
