import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {WindowModeType} from 'types'
import {
  getBorder,
  showSnackbar,
  useDidUpdate,
  useGiftshopUploadCompressResendSetting,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Paragraph, ToggleSwitch} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledTitleProps {
  expand: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: mode === 'mobile' ? theme.white_1 : theme.white_2,
    padding: mode === 'mobile' ? 0 : undefined,
  })};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
  overflow-y: scroll;

  scrollbar-width: auto;
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledInnerContainer = styled.div<StyledContainerProps>`
  ${({mode}) => ({
    marginTop: mode === 'mobile' ? 0 : convertUnit(25),
    maxWidth: mode !== 'mobile' ? convertUnit(480) : '100%',
  })}
  width: 100%;
  display: flex;
  padding: ${convertUnit(40)};
  flex-direction: column;
  background: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
`

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  margin-top: ${convertUnit(4)};
  border-radius: ${convertUnit(4)};
  justify-content: center;
  flex-direction: column;
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
`

const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 ${convertUnit(8)};
  margin: ${convertUnit(4)} 0;
`

const StyledDescriptionParagraph = styled(Paragraph)`
  line-height: ${convertUnit(21)};
`

const StyledTitle = styled.div<StyledTitleProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${convertUnit(16)};
  border-bottom: ${({theme, expand}) =>
    getBorder(expand ? 1 : 0, 'solid', theme.gray_1)};
`

const StyledExpandIconContainer = styled.div`
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function GiftShopSettingCompress() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {
    validated,
    compressEnabled,
    resendEnabled,
  } = useGiftshopUploadCompressResendSetting()
  const [toggleCompress, setToggleCompress] = useState(compressEnabled)
  const [toggleResend, setToggleResend] = useState(resendEnabled)
  const [expand, setExpand] = useState<{compress: boolean; resend: boolean}>({
    compress: true,
    resend: true,
  })
  const {update} = useDispatch()
  const {is_storage_subscriber} = useSelector('user')!
  const [loading, setLoading] = useState(false)

  const buttonEnabled = useMemo(
    () =>
      validated &&
      (compressEnabled !== toggleCompress || resendEnabled !== toggleResend),
    [validated, compressEnabled, toggleCompress, resendEnabled, toggleResend],
  )

  const handleSave = useCallback(() => {
    setLoading(true)
    requestData('giftshop_patch_creator', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        is_always_compress: toggleCompress,
        is_always_resend: toggleCompress || toggleResend,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setLoading(false)
          showSnackbar(translate('global:changesSaved'))
          update('yuserActivationState', {
            compressEnabled: toggleCompress,
            resendEnabled: toggleCompress || toggleResend,
          })
        }
      },
    })
  }, [toggleCompress, toggleResend, translate, update])

  const handleRenderCompressToggle = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('giftShop:compressHeader')}
        </Paragraph>
        <ToggleSwitch
          toggleKey="compress"
          onSwitch={() => {
            if (toggleCompress) {
              setToggleResend(false)
            }
            setToggleCompress((prev) => !prev)
          }}
          selected={toggleCompress}
          disabled={!is_storage_subscriber}
        />
      </StyledHeaderContainer>
    ),
    [is_storage_subscriber, toggleCompress, translate],
  )

  const handleRenderCompressInfo = useMemo(
    () => (
      <StyledDescriptionContainer
        onClick={() =>
          setExpand((prev) => ({...prev, compress: !prev.compress}))
        }>
        <StyledTitle expand={expand.compress}>
          <StyledDescriptionParagraph fontWeight="bold">
            {translate('giftShop:compressInfoLabel')}
          </StyledDescriptionParagraph>
          <StyledExpandIconContainer>
            <Icon
              type={expand.compress ? 'chevron-up' : 'chevron-down'}
              size={convertUnit(16)}
            />
          </StyledExpandIconContainer>
        </StyledTitle>
        {expand.compress && (
          <>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:compressInfoMessage1')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:compressInfoMessage2')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:compressInfoMessage3')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:compressInfoMessage4')}
              </Paragraph>
            </StyledDescription>
          </>
        )}
      </StyledDescriptionContainer>
    ),
    [expand.compress, translate],
  )
  const handleRenderResendToggle = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('giftShop:manageAccountResendTitle')}
        </Paragraph>
        <ToggleSwitch
          toggleKey="resend"
          disabled={toggleCompress || !is_storage_subscriber}
          onSwitch={() => setToggleResend((prev) => !prev)}
          selected={toggleCompress || toggleResend}
        />
      </StyledHeaderContainer>
    ),
    [is_storage_subscriber, toggleCompress, toggleResend, translate],
  )

  const handleRenderResendInfo = useMemo(
    () => (
      <StyledDescriptionContainer
        onClick={() => setExpand((prev) => ({...prev, resend: !prev.resend}))}>
        <StyledTitle expand={expand.resend}>
          <StyledDescriptionParagraph fontWeight="bold">
            {translate('giftShop:manageAccountResendSubtitle')}
          </StyledDescriptionParagraph>
          <StyledExpandIconContainer>
            <Icon
              type={expand.resend ? 'chevron-up' : 'chevron-down'}
              size={convertUnit(16)}
            />
          </StyledExpandIconContainer>
        </StyledTitle>
        {expand.resend && (
          <>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:manageAccountResendDescription1')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:manageAccountResendDescription2')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:manageAccountResendDescription3')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:manageAccountResendDescription4')}
              </Paragraph>
            </StyledDescription>
            <StyledDescription>
              <Icon type="dot" size={convertUnit(8)} />
              <Paragraph>
                {translate('giftShop:manageAccountResendDescription5')}
              </Paragraph>
            </StyledDescription>
          </>
        )}
      </StyledDescriptionContainer>
    ),
    [expand.resend, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={!buttonEnabled}
        label={translate('global:save')}
        onClick={handleSave}
        isLoading={loading}
      />
    ),
    [buttonEnabled, translate, handleSave, loading],
  )

  useDidUpdate(() => {
    if (validated) {
      setToggleCompress(compressEnabled)
      setToggleResend(resendEnabled)
    }
  }, [validated, compressEnabled, resendEnabled])

  return (
    <StyledContainer mode={mode}>
      <StyledInnerContainer mode={mode}>
        <StyledContentContainer>
          <div>
            {handleRenderCompressToggle}
            {handleRenderCompressInfo}
          </div>
          <div>
            {handleRenderResendToggle}
            {handleRenderResendInfo}
          </div>
        </StyledContentContainer>
        {handleRenderButton}
      </StyledInnerContainer>
    </StyledContainer>
  )
}
