import React, {useCallback, useState} from 'react'
import {useSelector} from 'lib/redux'
import {requestData} from 'services'
import {maskEmail, maskPhone} from 'utils'
import {GiftShopTemplateOTPModal} from '../../../template'
import {GiftShopEditProfilePasswordOTPModalProps} from './GiftShopEditProfileModalProps'

export default function GiftShopEditProfilePasswordOTPModal({
  verificationMethod,
  onResend,
  onFailed,
  onWrongOTP,
  onSuccess,
  onCancel,
}: GiftShopEditProfilePasswordOTPModalProps) {
  const {phone_code, phone_number, email} = useSelector('user') || {}
  const {phoneOTPTime: PhoneOTPTime, emailTime} = useSelector('lastUserState')

  const stateIncorrect = useState(false)
  const setIncorrect = stateIncorrect[1]

  const handleVerify = useCallback(
    (codes: string) => {
      requestData('giftshop_patch_password_otp', {
        data: {code: codes, method: verificationMethod},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setIncorrect(false)
            onSuccess()
          } else if (status === 422) setIncorrect(true)
          else if (status === 429) onWrongOTP()
          else onFailed()
        },
      })
    },
    [onFailed, onSuccess, onWrongOTP, setIncorrect, verificationMethod],
  )

  return (
    <GiftShopTemplateOTPModal
      stateError={stateIncorrect}
      dataToVerify={
        verificationMethod === 'email'
          ? email && maskEmail(email)
          : phone_code && phone_number && maskPhone(phone_code, phone_number)
      }
      verificationMethod={verificationMethod}
      timeRemaining={verificationMethod === 'email' ? emailTime : PhoneOTPTime}
      onCancel={onCancel}
      onVerify={handleVerify}
      onResend={onResend}
    />
  )
}
