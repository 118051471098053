import {FontFamilyMap} from 'types'

export const FONT_FAMILY_MAP: FontFamilyMap = {
  regular: 'Roboto-Regular',
  italic: 'Roboto-Italic',
  medium: 'Roboto-Medium',
  bold: 'Roboto-Bold',
  boldItalic: 'Roboto-BoldItalic',
  brand: 'ReemKufi-Regular',
  kalam: 'Kalam-Bold',
  quicksandBold: 'Quicksand-Bold',
  quicksandMedium: 'Quicksand-Medium',
}
