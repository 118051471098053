import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionWithdrawConfirmationItemProps} from './TransactionWithdrawConfirmationItemProps'

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
`

export default function TransactionWithdrawConfirmationItem({
  upperText,
  lowerText,
  fontSize = 'm',
  upperFontWeight = 'bold',
  lowerFontWeight = 'regular',
  lowerTextColor = 'black',
  marginBottom = 0,
}: TransactionWithdrawConfirmationItemProps) {
  return (
    <StyledItemContainer style={{marginBottom: convertUnit(marginBottom)}}>
      <StyledColumn>
        <Paragraph fontSize={fontSize} fontWeight={upperFontWeight}>
          {upperText}
        </Paragraph>
      </StyledColumn>

      <StyledColumn>
        <Paragraph
          fontSize={fontSize}
          fontWeight={lowerFontWeight}
          color={lowerTextColor}>
          {lowerText}
        </Paragraph>
      </StyledColumn>
    </StyledItemContainer>
  )
}
