import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useTheme} from 'themes'
import {
  ForkygramPostTagData,
  GiftShopPostTagResponse,
  GiftShopSelectContentType,
  parseShape,
} from 'types'
import {formatDateRange, useDebounce, useDefaultState} from 'utils'
import {
  Button,
  CheckboxItem,
  Chips,
  IconVerifiedBadge,
  Input,
  ListLazy,
  Menu,
  Modal,
  Paragraph,
  ParsedText,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileFilterSelectionModalProps} from './GiftShopProfileFilterSelectionModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`

const StyledSectionButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledButtonClose = styled(StyledButton)`
  ${({theme}) => ({backgroundColor: theme.white_3})}
`

const StyledButtonRetry = styled(StyledButton)`
  margin-left: ${convertUnit(10)};
  box-sizing: border-box;
`

const StyledTagItemContainer = styled.div`
  padding: ${convertUnit(6)} ${convertUnit(16)};
  &:hover {
    background-color: ${({theme}) => theme.primary_5};
  }
`

const StyledTagItemNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${convertUnit(6)};
`

const StyledTagItemName = styled(Paragraph)`
  ${StyledTagItemContainer}:hover & {
    color: ${({theme}) => theme.white_1};
  }
`

const StyledTagItemBio = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledTagItemContainer}:hover & {
    color: ${({theme}) => theme.white_3};
  }
`

const StyledEmptyContainer = styled.div`
  display: flex;
  align-self: center;
  padding: ${convertUnit(20)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  width: 100%;
`

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContentTypeContainer = styled(StyledSelectContainer)`
  margin-top: ${convertUnit(20)};
`

const StyledContentListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledSelectCheckboxContainer = styled.div`
  width: 50%;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
`

const StyledCheckboxContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledSelectCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(15)};
`

export default function GiftShopProfileFilterSelectionModal({
  visible,
  stateTag,
  stateContentType,
  onToggle,
  onSave,
}: GiftShopProfileFilterSelectionModalProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const debounce = useDebounce()
  const contentType = useDefaultState('all', stateContentType)[0]
  const [
    currContentType,
    setCurrContentType,
  ] = useState<GiftShopSelectContentType>(contentType)
  const stateTags = useState<readonly ForkygramPostTagData[]>([])
  const [searchTag, setSearchTag] = useState('')
  const selectedTags = stateTag[0]
  const [tempSelectedTags, setTempSelectedTags] = useState(selectedTags)
  const [searchQuery, setSearchQuery] = useState('')
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('giftShop:creationFilterModalTitle')}
      </StyledTitle>
    ),
    [translate],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regex = new RegExp(searchTag)

    return [
      {
        pattern: regex,
        style: {fontFamily: 'roboto-bold', color: theme.black},
        renderText: (text) => text.replace(regex, `"${text}"`),
      },
    ]
  }, [searchTag, theme.black])

  const handleSelectTag = useCallback(
    (item: ForkygramPostTagData) => {
      setTempSelectedTags((prev) => [...prev, item])
      setSearchTag('')
      setToggle((previous) => !previous)
    },
    [setToggle],
  )

  const handleDeleteTag = useCallback((tagDate: ForkygramPostTagData) => {
    setTempSelectedTags((prev) => prev.filter((item) => item !== tagDate))
  }, [])

  const handleRenderDropdownItem = useCallback(
    (item: GiftShopPostTagResponse) => (
      <StyledTagItemContainer onClick={() => handleSelectTag(item)}>
        <StyledTagItemNameContainer>
          <StyledTagItemName fontWeight="bold" lineHeight={21}>
            {item.name}
          </StyledTagItemName>
          {item.status === 'VERIFIED' && (
            <IconVerifiedBadge width={12} height={12} />
          )}
        </StyledTagItemNameContainer>
        <StyledTagItemBio fontSize="xs" color="gray_5">
          {item.location.name !== '-' && `${item.location.name} | `}
          {item.event_start_date &&
            item.event_end_date &&
            `${formatDateRange(item.event_start_date, item.event_end_date)} | `}
          {item.bio}
        </StyledTagItemBio>
      </StyledTagItemContainer>
    ),
    [handleSelectTag],
  )

  const handleLoadTagData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestData('giftshop_get_tags_post', {
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {page, limit, q},
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [],
  )

  const handleRenderEmptyTag = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph color="gray_5" fontSize="m">
          {translate('forkygram:fototreeTagNotFound')}
          <ParsedText parse={patterns}>{searchTag}</ParsedText>
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [patterns, searchTag, translate],
  )

  const handleRenderTagList = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateTags}
          loadData={handleLoadTagData}
          search={searchQuery}
          keyExtractor={(item) => item.id}
          renderItem={handleRenderDropdownItem}
          listEmptyElement={handleRenderEmptyTag}
          emptyContainerStyle={{flex: 0}}
        />
      </StyledContentListContainer>
    ),
    [
      handleLoadTagData,
      handleRenderEmptyTag,
      handleRenderDropdownItem,
      searchQuery,
      stateTags,
    ],
  )

  const handleRenderChips = useMemo(
    () => (
      <Chips
        data={tempSelectedTags}
        backgroundColor="primary_1"
        fontColor="primary_5"
        fontSize="xs"
        keyExtractor={(item) => item.id}
        labelExtractor={(item) => item.name}
        onDelete={handleDeleteTag}
      />
    ),
    [handleDeleteTag, tempSelectedTags],
  )

  const handleRenderTagInput = useMemo(
    () => (
      <Menu
        stateToggle={stateToggle}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={handleRenderTagList}>
        <Input
          value={searchTag}
          label={translate('global:fototree')}
          placeholder={translate('giftShop:uploadInputTagsPlaceholder')}
          rightIcon="delete"
          rightIconStyle={{cursor: 'pointer'}}
          containerStyle={{marginTop: convertUnit(25)}}
          onChangeText={(text) => {
            setSearchTag(text)
            debounce(() => setSearchQuery(text), 500)
          }}
          onFocus={() => setToggle(true)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              setToggle((previous) => !previous)
            }
          }}
          onRightIconClick={() => {
            setSearchTag('')
            setSearchQuery('')
            setToggle((previous) => !previous)
          }}
        />
        {handleRenderChips}
      </Menu>
    ),
    [
      debounce,
      handleRenderChips,
      handleRenderTagList,
      searchTag,
      setToggle,
      stateToggle,
      translate,
    ],
  )

  const handleRenderDescription = useMemo(
    () => (
      <StyledCheckboxContainer>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('giftShop:contentType')}
        </Paragraph>
        <StyledContentTypeContainer>
          <StyledSelectCheckboxContainer>
            <StyledSelectCheckbox
              isSelected={currContentType !== 'video'}
              onClick={() => {
                if (currContentType === 'video') {
                  setCurrContentType('all')
                } else if (currContentType === 'all') {
                  setCurrContentType('video')
                } else {
                  setCurrContentType('photo')
                }
              }}
            />
            <Paragraph fontSize="m">{translate('global:photo')}</Paragraph>
          </StyledSelectCheckboxContainer>
          <StyledSelectCheckboxContainer>
            <StyledSelectCheckbox
              isSelected={currContentType !== 'photo'}
              onClick={() => {
                if (currContentType === 'photo') {
                  setCurrContentType('all')
                } else if (currContentType === 'all') {
                  setCurrContentType('photo')
                } else {
                  setCurrContentType('video')
                }
              }}
            />
            <Paragraph fontSize="m">{translate('global:video')}</Paragraph>
          </StyledSelectCheckboxContainer>
        </StyledContentTypeContainer>
        {handleRenderTagInput}
      </StyledCheckboxContainer>
    ),
    [currContentType, handleRenderTagInput, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledSectionButton>
        <StyledButtonClose
          label={translate('global:close')}
          onClick={onToggle}
          color="primary_5"
        />
        <StyledButtonRetry
          label={translate('global:save')}
          onClick={() => onSave(currContentType, tempSelectedTags)}
          disableBackgroundColor="primary_5"
          disabledFontColor="white_1"
        />
      </StyledSectionButton>
    ),
    [currContentType, onSave, onToggle, tempSelectedTags, translate],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderTitle}
      {handleRenderDescription}
      {handleRenderButton}
    </StyledModal>
  )
}
