import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {IMAGE_ASSET, TREE_MAX_STORY_CHARACTER} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramUploadData, WindowModeType} from 'types'
import {useDidMount} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Image, InputArea} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeUploadLeafFormProps} from './TreeUploadLeafFormProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  ${({mode}) => ({
    flexDirection: mode === 'website' ? 'row' : 'column',
    padding: mode === 'website' ? `${convertUnit(60)}` : `${convertUnit(20)}`,
    gap: mode === 'website' ? `${convertUnit(40)}` : `${convertUnit(20)}`,
  })}
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(20)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledRightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${convertUnit(20)};
`

const StyledImage = styled(Image)`
  object-fit: scale-down;
  width: 100%;
  max-width: ${convertUnit(527)};
  background-color: ${({theme}) => theme.gray_1};
`

const StyledVideo = styled.video`
  object-fit: scale-down;
  width: 100%;
  max-width: ${convertUnit(527)};
  max-height: ${convertUnit(527)};
  background-color: ${({theme}) => theme.gray_1};
`

const StyledInputArea = styled(InputArea)`
  resize: none;
  scrollbar-width: auto;
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(20)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

export default function TreeUploadLeafForm({
  src,
  tag,
  onFormUpdate,
  onSubmitForm,
}: TreeUploadLeafFormProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const defaultImg = IMAGE_ASSET('giftshop', 'broken-image-explore.png')
  const form = useForm<ForkygramUploadData>({
    defaultValues: {
      caption: '',
      tag,
      height: 0,
      size: 0,
      width: 0,
    },
  })
  const {register, setValue, watch} = form
  const values = watch()

  const handleRenderInputArea = useMemo(
    () => (
      <StyledInputArea
        placeholder={translate('tree:tellYourStory')}
        value={values.caption}
        autoComplete={false}
        inputContainerStyle={{height: convertUnit(125)}}
        labelDescription={translate('global:charCount', {
          count: values.caption.length,
          max: TREE_MAX_STORY_CHARACTER,
        })}
        onChangeText={(text) => setValue('caption', text)}
        maxLength={TREE_MAX_STORY_CHARACTER}
      />
    ),
    [setValue, translate, values.caption],
  )

  const handleRenderContent = useMemo(
    () =>
      src && (
        <StyledContainer mode={mode}>
          {src.includes('video') ? (
            <StyledVideo autoPlay={false}>
              <source src={src} type="video/mp4" />
              <source src={src} type="video/mov" />
              <source src={src} type="video/heiv" />
            </StyledVideo>
          ) : (
            <StyledImage src={src || defaultImg} alt="" />
          )}

          <StyledRightContainer>
            {handleRenderInputArea}
            <Button
              onClick={() => onSubmitForm()}
              label={translate('tree:postMemory')}
            />
          </StyledRightContainer>
        </StyledContainer>
      ),
    [defaultImg, handleRenderInputArea, mode, onSubmitForm, src, translate],
  )

  useDidMount(() => {
    register('extension')
    register('mime')
    register('file')
    register('caption')
    register('height')
    register('size')
    register('width')
    register('tag')
    register('duration')
  })

  useEffect(() => {
    onFormUpdate && onFormUpdate(form)
  }, [form, onFormUpdate])

  return <>{handleRenderContent}</>
}
