import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {showSnackbar, useDebounce, useDidMount, useHistory} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'
import {GiftShopMessageCreatorRatingModal} from '../CreatorRatingModal'
import {
  getMessageContentIds,
  getMessagePrice,
  updateMessagePrice,
} from '../GiftshopMessageArrayHelper'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledFilledRatingContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(4)};
`

export interface GiftShopMessageRoomContentItemNotificationPurchaseCompletedReviewProps {
  payload: FirebaseMessageRoomItemPayload['notification_purchase_completed_review']
  isSelf?: boolean
}

export default function GiftShopMessageRoomContentItemNotificationPurchaseCompletedReview({
  payload,
  isSelf,
}: GiftShopMessageRoomContentItemNotificationPurchaseCompletedReviewProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const {
    creator_id,
    contents,
    creator_profile_url,
    creator_username,
    invoice_id,
    username,
    data: contentData,
    creator_verified_status,
  } = payload

  const contentIds = getMessageContentIds(contentData, contents)
  const prices = getMessagePrice(contentData)
  const [rating, setRating] = useState<number>()
  const debounce = useDebounce()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingRating, setLoadingRating] = useState(false)

  const handleCheckReviewed = useCallback(async () => {
    setLoadingRating(true)
    await requestData('giftshop_post_creator_rating_check', {
      actionType: 'execute',
      data: {creator_id, invoice_id},
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200 && result.rating) setRating(result.rating)
      },
    })
    setLoadingRating(false)
  }, [creator_id, invoice_id])

  const handleLoadData = useCallback(
    () =>
      contents &&
      contentIds &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contentIds.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          result = updateMessagePrice(result, contentIds, prices)
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contentIds, contents, prices],
  )

  const handlePostRating = useCallback(
    (ratingInput: number) => {
      setLoadingSubmit(true)
      debounce(() => {
        requestData('giftshop_post_creator_rating', {
          actionType: 'execute',
          data: {creator_id, invoice_id, rating: ratingInput},
          onRequestReceived: () => setLoadingSubmit(false),
          onRequestSuccess: ({status}) => {
            if (status === 200) {
              showSnackbar(translate('messages:creatorRatingSubmitSuccess'))
              setRating(ratingInput)
              setVisible((prev) => !prev)
            } else {
              showSnackbar(translate('messages:creatorRatingSubmitFailed'))
            }
          },
        })
      })
    },
    [creator_id, debounce, invoice_id, translate],
  )

  const handleRenderReviewModal = useMemo(
    () => (
      <GiftShopMessageCreatorRatingModal
        isVerified={creator_verified_status === 'verified'}
        photo={creator_profile_url}
        username={creator_username}
        visible={visible}
        loadingSubmit={loadingSubmit}
        onSubmit={handlePostRating}
        toggleModal={() => setVisible((prev) => !prev)}
      />
    ),
    [
      creator_profile_url,
      creator_username,
      creator_verified_status,
      handlePostRating,
      loadingSubmit,
      visible,
    ],
  )

  const handleRenderUpper = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:notificationPurchaseReviewOpening', {
            username,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationPurchaseReviewInfo')}
        </Paragraph>
      </>
    ),
    [translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderRatingQuestion = useMemo(
    () => (
      <Paragraph>
        {translate('messages:notificationPurchaseReviewRatingQuestion', {
          creator_username,
        })}
      </Paragraph>
    ),
    [creator_username, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('messages:notificationPurchaseReviewButton')}
        onClick={() => setVisible((prev) => !prev)}
      />
    ),
    [translate],
  )

  const handleRenderRating = useMemo(
    () =>
      rating && (
        <StyledFilledRatingContainer>
          <Paragraph>
            {translate('messages:notificationPurchaseReviewRatingFilled')}
          </Paragraph>
          <StyledRowContainer>
            {[...Array(5)].map((_, idx) => (
              <Icon
                key={`star-${idx + 1}`}
                type={idx < rating ? 'star-solid' : 'star-ol'}
                size={convertUnit(16)}
                color={idx < rating ? 'success_5' : 'gray_3'}
              />
            ))}
          </StyledRowContainer>
        </StyledFilledRatingContainer>
      ),
    [rating, translate],
  )

  const handleRenderRatingSection = useMemo(
    () =>
      rating ? (
        handleRenderRating
      ) : (
        <>
          {handleRenderRatingQuestion}
          {handleRenderButton}
        </>
      ),

    [
      handleRenderButton,
      handleRenderRating,
      handleRenderRatingQuestion,
      rating,
    ],
  )

  useDidMount(() => {
    handleLoadData()
    handleCheckReviewed()
  })

  return (
    <StyledContainer>
      {handleRenderReviewModal}
      {handleRenderUpper}
      {handleRenderContent}
      {!isSelf && !loadingRating && handleRenderRatingSection}
    </StyledContainer>
  )
}
