import React, {CSSProperties, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {formatPhoneNumber} from 'utils'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Button, Modal, Paragraph} from 'common/components'
import {GiftShopEditProfileUnverifiedModalProps} from './GiftShopEditProfileModalProps'

const StyledContainer = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({width = convertUnit(300)}) => width};
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  word-break: break-word;
`

const StyledDescriptionText = styled(StyledParagraph)`
  margin-top: ${convertUnit(8)};
  margin-bottom: ${convertUnit(20)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopEditProfileUnverifiedModal({
  visible,
  item,
  onConfirm,
  onCancel,
  onAddNew,
  stateLoading,
}: GiftShopEditProfileUnverifiedModalProps) {
  const {translate} = useTranslation()
  const {email} = useSelector('userNewEmail') || {}
  const {phoneCode, phoneNumber} = useSelector('userNewPhone') || {}
  const loading = stateLoading && stateLoading[0]

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:addNew')}
          backgroundHoverColor="white_3"
          backgroundColor="white_2"
          color="primary_5"
          onClick={onAddNew}
        />
        <StyledButton
          backgroundColor="primary_5"
          label={translate('global:verify')}
          isLoading={loading}
          onClick={onConfirm}
        />
      </StyledButtonContainer>
    ),
    [loading, onAddNew, onConfirm, translate],
  )

  return (
    <Modal visible={visible} onBackdropPress={onCancel}>
      <StyledContainer>
        <StyledParagraph fontSize="xl" fontWeight="bold" lineHeight={42}>
          {item === 'phone'
            ? `+${phoneCode} ${formatPhoneNumber(phoneNumber || '')}`
            : email}
        </StyledParagraph>
        <StyledDescriptionText fontSize="l" lineHeight={31}>
          {translate('giftShop:editProfileUnverifiedCaption', {
            item:
              item === 'phone'
                ? translate('global:mobileNumber')
                : translate('global:email'),
          })}
        </StyledDescriptionText>
        {handleRenderButton}
      </StyledContainer>
    </Modal>
  )
}
