import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { PromptHandler } from 'types'
import { setPromptHandler } from 'utils'
import { PromptContext } from './PromptContext'
import PromptItem from './PromptItem'
import { PromptProviderProps } from './PromptProps'

export default function PromptProvider({
  children,
}: PromptProviderProps) {
  const [showPrompt, setShowPrompt] = useState(false)
  const [modalDesc, setModalDesc] = useState('')

  const handleShowPrompt = useCallback(
    (when: boolean, description: string) => {
      setShowPrompt(when)
      setModalDesc(description)
    },
    [],
  )

  const handleRenderPrompt = useMemo(
    () =>
      showPrompt && (
        <PromptItem when={showPrompt} description={modalDesc} />
      ),
    [modalDesc, showPrompt],
  )

  const handler = useMemo<PromptHandler>(
    () => ({
      when: showPrompt,
      description: modalDesc,
      showPrompt: handleShowPrompt,
    }),
    [handleShowPrompt, modalDesc, showPrompt],
  )

  useEffect(() => {
    setPromptHandler(handler)
  }, [handler])

  return (
    <PromptContext.Provider value={handler}>
      {handleRenderPrompt}
      {children}
    </PromptContext.Provider>
  )
}
