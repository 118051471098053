import React, {useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {useWindowMode} from 'windows'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionWithdrawFormWarningBoxProps} from './TransactionWithdrawFormWarningBoxProps'

interface StyledInfoContainerProps {
  bgColor: ThemeColor
}

const StyledInfoContainer = styled.div<StyledInfoContainerProps>`
  ${({theme, bgColor}) => ({
    backgroundColor: theme[bgColor],
  })}
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${convertUnit(42)};
  padding: ${convertUnit(8)} 0;
  justify-content: center;
  text-align: center;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(37)};
  }
`

const StyledIconInfo = styled(Icon)`
  align-self: 'center';
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  align-self: center;
  margin-left: ${convertUnit(16)};
`

export default function TransactionWithdrawFormWarningBox({
  shownBalanceMessage,
}: TransactionWithdrawFormWarningBoxProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()

  const handleRenderMessage = useMemo(
    () => (
      <StyledParagraph
        fontSize={mode === 'mobile' ? 'xs' : 's'}
        fontWeight="medium"
        color={
          shownBalanceMessage === 'INSUFFICIENT_BALANCE' ? 'white_5' : 'black_1'
        }>
        {translate('giftShop:withdrawErrorMessage', {
          context: shownBalanceMessage,
        })}
      </StyledParagraph>
    ),
    [mode, shownBalanceMessage, translate],
  )

  const handleRenderLeftIcon = useMemo(
    () => (
      <StyledIconInfo
        type="info"
        size={mode === 'mobile' ? 's' : 'm'}
        color={
          shownBalanceMessage === 'INSUFFICIENT_BALANCE' ? 'white_5' : 'black_1'
        }
      />
    ),
    [mode, shownBalanceMessage],
  )

  return (
    <StyledInfoContainer
      bgColor={
        shownBalanceMessage === 'INSUFFICIENT_BALANCE'
          ? 'danger_5'
          : 'warning_3'
      }>
      {handleRenderLeftIcon}
      {handleRenderMessage}
    </StyledInfoContainer>
  )
}
