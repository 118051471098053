import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {createRequestTransformer} from 'amazon-location-helpers'
import ReactMapGL, {NavigationControl} from 'react-map-gl'
import {MAP_NAME} from 'consts'
import {GiftShopMapTransformType} from 'types'
import {MapProps} from './MapProps'

const StyledLoading = styled.div`
  position: absolute;
`

function Map({
  children,
  viewportState,
  reuseMaps = false,
  maxPitch = 0,
  bearing = 0,
  mapRef,
  interactiveLayerIds,
  ...props
}: MapProps) {
  const [viewport, setViewport] = viewportState
  const [
    transformRequest,
    setRequestTransformer,
  ] = useState<GiftShopMapTransformType>()

  useEffect(() => {
    const makeRequestTransformer = async () => {
      const tr = await createRequestTransformer({
        identityPoolId: process.env.AWS_POOL_ID,
      })
      setRequestTransformer(() => tr as GiftShopMapTransformType)
    }

    makeRequestTransformer()
  }, [])

  return transformRequest ? (
    <ReactMapGL
      {...viewport}
      reuseMaps={reuseMaps}
      bearing={bearing}
      maxPitch={maxPitch}
      width="100%"
      height="100%"
      ref={mapRef}
      mapStyle={MAP_NAME}
      transformRequest={transformRequest}
      interactiveLayerIds={interactiveLayerIds}
      onViewportChange={(port) => setViewport((prev) => ({...prev, ...port}))}
      {...props}>
      {children}
      <StyledLoading>
        <NavigationControl showCompass={false} showZoom={false} />
      </StyledLoading>
    </ReactMapGL>
  ) : null
}

export default Map
