import React from 'react'
import styled from 'styled-components'
import {Paragraph, RadioItem} from 'common/components'
import {GiftShopTemplateReportItemProps} from './GiftShopTemplateReportProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

export default function GiftShopTemplateReportItem({
  isSelected,
  reason,
  onSelect,
}: GiftShopTemplateReportItemProps) {
  return (
    <StyledContainer onClick={onSelect}>
      <Paragraph fontSize="m" fontWeight="medium">
        {reason}
      </Paragraph>
      <RadioItem isSelected={isSelected} color="gray_3" />
    </StyledContainer>
  )
}
