import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_NEW_YUSER_NOTICE_MODAL_MINIMUM_SCREEN_HEIGHT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {TranslationKey, useTranslation} from 'i18n'
import {parseShape} from 'types'
import {Icon, Modal, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSearchResultDisclaimerModalProps} from './GiftShopSearchResultDisclaimerModalProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(561)};
  padding: ${convertUnit(25)} ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (max-height: ${GIFT_SHOP_NEW_YUSER_NOTICE_MODAL_MINIMUM_SCREEN_HEIGHT}px) {
    max-height: calc(100vh - 8rem);
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    position: absolute;
    bottom: 0;
    width: auto;
    max-height: calc(100vh - 2rem);
    border-radius: ${convertUnit(16)} ${convertUnit(16)} 0 0;
  }
`

const StyledParagraph = styled(Paragraph)`
  max-width: 75%;
  text-align: left;
`

const StyledModalBody = styled.div`
  flex-direction: column;
  align-items: center;
  margin-top: ${convertUnit(10)};
  overflow-y: auto;
  @media (max-height: ${GIFT_SHOP_NEW_YUSER_NOTICE_MODAL_MINIMUM_SCREEN_HEIGHT}px) {
    padding-right: ${convertUnit(25)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-height: calc(75vh);
  }
`

const StyledClosedButton = styled.a`
  position: absolute;
  top: 0;
  bottom: auto;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${convertUnit(32)};
  width: ${convertUnit(32)};
  background-color: ${({theme}) => theme.gray_5};
  border-radius: ${convertUnit(8)};
  font-size: ${convertUnit(30)};
  font-weight: bold;
  cursor: pointer;
  transition: all 200ms;
`

const StyledModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default function GiftShopSearchResultDisclaimerModal({
  toggleModal,
  visible,
}: GiftShopSearchResultDisclaimerModalProps) {
  const {translate} = useTranslation()

  const keys: TranslationKey[] = useMemo(
    () => [
      'giftShop:searchResultDisclaimer1',
      'giftShop:searchResultDisclaimer2',
      'giftShop:searchResultDisclaimer3',
      'giftShop:searchResultDisclaimer4',
      'giftShop:searchResultDisclaimer5',
      'giftShop:searchResultDisclaimer6',
      'giftShop:searchResultDisclaimer7',
      'giftShop:searchResultDisclaimer8',
    ],
    [],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: 'roboto-bold'},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleRenderParagraphs = useMemo(
    () =>
      keys.map((key) => (
        <>
          <Paragraph fontSize="m" fontWeight="regular" lineHeight={26}>
            <ParsedText parse={patterns}>{translate(key)}</ParsedText>
          </Paragraph>
          <br />
        </>
      )),
    [keys, patterns, translate],
  )

  return (
    <StyledModal visible={visible} onBackdropPress={toggleModal}>
      <StyledModalHeader>
        <StyledClosedButton onClick={toggleModal}>
          <Icon type="close" color="white_1" />
        </StyledClosedButton>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:searchResultDisclaimerTitle')}
        </StyledParagraph>
      </StyledModalHeader>
      <StyledModalBody>
        {handleRenderParagraphs}
        <Paragraph fontSize="l" fontWeight="medium" lineHeight={26}>
          <ParsedText parse={patterns}>
            {translate('giftShop:searchResultDisclaimerCloser')}
          </ParsedText>
        </Paragraph>
      </StyledModalBody>
    </StyledModal>
  )
}
