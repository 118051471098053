import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'i18n'
import {GiftShopTemplateDetailContent, TemplateProfileEmptyTab} from 'pages'
import {requestData} from 'services'
import {useHistory, useLocation} from 'utils'
import {TreeHostProfileCreationDetailProps} from './TreeHostProfileContentDetailProps'

export default function TreeHostProfileContentDetail({
  stateEdit,
}: TreeHostProfileCreationDetailProps) {
  const {data, selectedItemId, creationType, date} = useLocation(
    'tree_host_content_detail',
  ).state
  const {translate} = useTranslation()
  const history = useHistory()

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_fetch_creation', {
        actionType: 'fetch',
        useDefaultMessage: true,
        params: {
          page,
          limit,
          filter: creationType,
          sort_by: 'created_at',
          sort_dir: 'desc',
          date,
        },
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.data
        : []
    },
    [creationType, date],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <TemplateProfileEmptyTab
        buttonLabel={translate('forkygram:postEmptyButton')}
        title={translate('giftShop:myCreationEmptyTitle')}
        description={translate('giftShop:myCreationEmptyDesc')}
        onClickButton={() => history.push('giftshop_upload', {})}
      />
    ),
    [history, translate],
  )

  return (
    <>
      <GiftShopTemplateDetailContent
        showUnwishlistBadge
        showPurchaseStatusBadge
        showFoundConfirmed={creationType === 'available'}
        isHost
        isEditable
        data={data}
        selectedItemId={selectedItemId}
        hideOverlayWhenExpand
        loadData={handleLoadData}
        showReportButton={false}
        showWishlistButton={false}
        stateEdit={stateEdit}
        listEmptyElement={handleRenderEmpty}
        resendSource="host"
        showFullOverlay
      />
    </>
  )
}
