import React from 'react'
import styled from 'styled-components'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {ModalLimitedAccessItemProps} from './ModalLimitedAccessProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(12)};
`

export default function ModalLimitedAccessItem({
  onClose,
}: ModalLimitedAccessItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible>
      <StyledTitle fontWeight="bold" fontSize="l" lineHeight={31}>
        {translate('global:limitedAccess')}
      </StyledTitle>
      <Paragraph fontSize="m" lineHeight={26}>
        {translate('global:limitedAccessDesc')}
      </Paragraph>
      <StyledButton label={translate('global:close')} onClick={onClose} />
    </StyledModal>
  )
}
