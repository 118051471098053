import React, {useCallback, useEffect, useState} from 'react'
import {Prompt} from 'react-router-dom'
import {Location} from 'history'
import {GIFT_SHOP_PROFILE_ROUTE} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {ConfirmationModal} from 'common/components'
import {useSelector} from 'lib/redux'
import {PromptItemProps} from './PromptItemProps'

export default function PromptItem({when, description}: PromptItemProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const [modalVisibility, setModalVisibility] = useState(false)
  const [location, setLocation] = useState<Location>()
  const [confirm, setConfirm] = useState(false)
  const history = useHistory()

  const handleBlock = useCallback(
    (loc: Location) => {
      if (!confirm) {
        setModalVisibility(true)
        setLocation(loc)
        return false
      }
      return true
    },
    [confirm],
  )

  const handleConfirm = useCallback(() => {
    setModalVisibility(false)
    setConfirm(true)
  }, [])

  const handleRefresh = useCallback((e) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useEffect(() => {
    if (confirm && location) {
      location.pathname === GIFT_SHOP_PROFILE_ROUTE
        ? history.push('giftshop_profile', {self: true}, username)
        : window.location.replace(location.pathname)
    }
    if (when) {
      window.addEventListener('beforeunload', handleRefresh)
    }
    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [confirm, handleRefresh, history, location, username, when])

  return (
    <>
      <Prompt when={when} message={handleBlock} />
      <ConfirmationModal
        title={translate('global:cancel')}
        text={description}
        onConfirm={handleConfirm}
        visible={modalVisibility}
        toggleModal={() => setModalVisibility((previous) => !previous)}
        confirmText={translate('global:yesImSure')}
        cancelText={translate('global:stay')}
      />
    </>
  )
}
