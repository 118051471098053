import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getThemeColor} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateRevisionModalYuserListProps} from './GiftShopTemplateRevisionModalProps'

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledYuserContainer = styled(StyledRow)`
  justify-content: space-between;
  padding: ${convertUnit(12)} 0;
`

const StyledYuserPhoto = styled(Image)`
  width: ${convertUnit(36)};
  height: ${convertUnit(36)};
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  border-radius: 50%;
`

const StyledRevisionButton = styled(Button)`
  height: ${convertUnit(28)};
`

const StyledOverlay = styled.div`
  position: absolute;
  width: ${convertUnit(36)};
  aspect-ratio: 1;
  background-color: ${({theme}) => getThemeColor(theme, 'white_1', 0.5)};
`

export default function GiftShopTemplateRevisionModalYuserList({
  item,
  onRevision,
}: GiftShopTemplateRevisionModalYuserListProps) {
  const {profile_url, nickname, approval_status} = item
  const {translate} = useTranslation()
  return (
    <StyledYuserContainer>
      <StyledRow>
        {approval_status === 'COMPLETED' && <StyledOverlay />}
        <StyledYuserPhoto src={profile_url} alt={nickname} />
        <Paragraph
          fontSize="m"
          fontWeight="medium"
          color={approval_status === 'COMPLETED' ? 'gray_3' : 'black'}>
          {nickname}
        </Paragraph>
      </StyledRow>
      <StyledRevisionButton
        label={translate('global:revision')}
        onClick={onRevision}
        disabled={approval_status === 'COMPLETED'}
      />
    </StyledYuserContainer>
  )
}
