import React from 'react'
import {FirebaseMessageRoomItemPayload} from 'types'
import {useTranslation} from 'i18n'
import {getCurrencyValue, parseDate} from 'utils'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationAwaitingPaymentProps {
  payload: FirebaseMessageRoomItemPayload['notification_awaiting_payment']
}

export default function GiftShopMessageRoomContentItemNotificationAwaitingPayment({
  payload: {
    username,
    total_payment,
    invoice_id,
    payment_type,
    payment_method,
    billing_number,
    transaction_expiration,
  },
}: GiftShopMessageRoomContentItemNotificationAwaitingPaymentProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationAwaitingPayment', {
        context: payment_type,
        username,
        total_payment: getCurrencyValue(total_payment),
        invoice_id,
        payment_method,
        billing_number,
        transaction_expiration: transaction_expiration
          ? parseDate(transaction_expiration, 'D MMMM YYYY, HH:mm:ss')
          : undefined,
      })}
    </Paragraph>
  )
}
