import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationPromotionHostCreatorCancelProps {
  payload: FirebaseMessageRoomItemPayload['notification_promotion_host_creator_cancel']
}

export default function GiftShopMessageRoomContentItemNotificationPromotionHostCreatorCancel({
  payload: {host_tag},
}: GiftShopMessageRoomContentItemNotificationPromotionHostCreatorCancelProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationPromotionHostCancel', {host_tag})}
    </Paragraph>
  )
}
