import React, {useState} from 'react'
import {PinProps} from './PinProps'

const ICON = `M0.25 8.4C0.25 3.89407 3.89407 0.25 8.4 0.25C12.9059 0.25 16.55 3.89407 16.55 8.4C16.55 10.6573 15.4338 13.3441 13.9935 15.8615C12.5582 18.3702 10.8232 20.6734 9.6292 22.1539C8.98551 22.9521 7.8145 22.9521 7.17081 22.1539C5.97682 20.6734 4.2418 18.3702 2.80651 15.8615C1.36621 13.3441 0.25 10.6573 0.25 8.4ZM5.15 8.4C5.15 10.1941 6.60593 11.65 8.4 11.65C10.1941 11.65 11.65 10.1941 11.65 8.4C11.65 6.60593 10.1941 5.15 8.4 5.15C6.60593 5.15 5.15 6.60593 5.15 8.4Z`

const pinStyle = {
  cursor: 'pointer',
  fill: '#DD3559',
  stroke: '#B01847',
  strokeWidth: '0.5',
}

export default function Pin({size = 24}: PinProps) {
  const sizeState = useState(size)[0]

  return (
    <svg
      height={sizeState}
      viewBox="0 0 17 24"
      style={{...pinStyle, transform: `translate(-50%, -50%)`}}>
      <circle cx="8.40039" cy="8.3999" r="3.5" fill="white" />
      <path d={ICON} />
    </svg>
  )
}
