import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {BOUNDING_BOX_BORDER_RADIUS} from 'consts'
import {getBoxShadow, getCurrencyValue, useDidMount} from 'utils'
import {translate} from 'i18n'
import {GiftShopCheckoutScreenParams} from 'pages'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {Button, Paragraph, PaymentModal} from 'common/components'
import {GiftShopCartSummaryProps} from './GiftShopCartSummaryProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    boxShadow: getBoxShadow(theme),
    borderRadius:
      mode === 'website' ? convertUnit(BOUNDING_BOX_BORDER_RADIUS) : undefined,
    padding: `${convertUnit(25)} ${convertUnit(mode === 'website' ? 25 : 50)}`,
  })}
  box-sizing: border-box;
  width: 100%;
`

const StyledSectionPrice = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(25)};
`

const StyledPrice = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`

const StyledButton = styled(Button)`
  width: ${convertUnit(125)};
  height: ${convertUnit(42)};
  padding: 0 ${convertUnit(32)};
`

const StyledBottomButton = styled(StyledButton)`
  width: 100%;
  margin-top: ${convertUnit(25)};
`

export default function GiftShopCartSummary({
  select,
  totalPrice,
  stateToggle,
  onContinue,
}: GiftShopCartSummaryProps) {
  const userInfo = useSelector('user')!
  const mode = useWindowMode()
  const [toggle, setToggle] = stateToggle
  const disabled = useMemo(() => select.length === 0, [select])

  const params = useMemo<GiftShopCheckoutScreenParams>(
    () => ({data: select, userInfo, platform: 'web'}),
    [select, userInfo],
  )

  const handleRenderTitle = useMemo(
    () =>
      mode === 'website' && (
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate('giftShop:cartSummaryTitle')}
        </StyledTitle>
      ),
    [mode],
  )

  const handleRenderPrice = useMemo(
    () => (
      <StyledPriceContainer>
        <Paragraph fontSize="s" fontWeight="medium">
          {translate('giftShop:cartSummaryTotalPriceTitle', {
            count: select.length,
          })}
        </Paragraph>
        <StyledPrice
          fontSize="l"
          fontWeight="bold"
          color={disabled ? 'black' : 'primary_5'}>
          {disabled ? '-' : getCurrencyValue(totalPrice)}
        </StyledPrice>
      </StyledPriceContainer>
    ),
    [select, disabled, totalPrice],
  )

  const handleRenderButton = useMemo(
    () =>
      mode !== 'website' && (
        <StyledButton
          disabled={disabled}
          label={translate('global:continue')}
          onClick={onContinue}
        />
      ),
    [mode, disabled, onContinue],
  )

  const handleRenderBottomButton = useMemo(
    () =>
      mode === 'website' && (
        <StyledBottomButton
          disabled={disabled}
          label={translate('global:continue')}
          onClick={onContinue}
        />
      ),
    [mode, disabled, onContinue],
  )

  const handleToggle = useCallback(() => setToggle((previous) => !previous), [
    setToggle,
  ])

  useDidMount(() => {
    ;(window as any).getData = () => select
  })

  return (
    <StyledContainer mode={mode}>
      {handleRenderTitle}
      <StyledSectionPrice>
        {handleRenderPrice}
        {handleRenderButton}
      </StyledSectionPrice>
      {handleRenderBottomButton}
      <PaymentModal
        toggleModal={handleToggle}
        visible={toggle}
        params={params}
      />
    </StyledContainer>
  )
}
