import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {useShareToSocialMedia} from 'utils'
import {
  GiftShopTemplateShareProps,
  ShareItemData,
} from './GiftShopTemplateShareModalProps'
import GiftShopTemplateShareItem from './GiftShopTemplateShareItem'

const StyledModal = styled(Modal)`
  display: grid;
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

export default function GiftShopTemplateShareModal({
  targetId,
  subTargetId,
  type,
  caption,
  toggleModal,
}: GiftShopTemplateShareProps) {
  const {translate} = useTranslation()
  const stateLoading = useState(false)

  const {shareUrl} = useShareToSocialMedia({
    stateLoading,
    targetId,
    subTargetId,
    type,
    caption,
    toggleModal,
  })

  const shareItems: ShareItemData[] = useMemo(
    () => [
      {icon: 'twitter', label: 'giftShop:twitter', destination: 'twitter'},
      {icon: 'facebook', label: 'giftShop:facebook', destination: 'facebook'},
      {icon: 'whatsapp', label: 'giftShop:whatsapp', destination: 'whatsapp'},
      {icon: 'link', label: 'global:copyLink', destination: 'copy'},
    ],
    [],
  )

  return (
    <StyledModal visible>
      <StyledTitle fontWeight="bold" fontSize="l">
        {translate('giftShop:shareTo')}
      </StyledTitle>
      <div>
        {shareItems.map(({icon, label, destination}, index) => (
          <GiftShopTemplateShareItem
            key={index.toString()}
            icon={icon}
            label={label}
            stateLoading={stateLoading}
            onClick={() => shareUrl(destination)}
          />
        ))}
      </div>
      <StyledButton
        label={translate('global:close')}
        color="primary_5"
        backgroundColor="white_3"
        backgroundHoverColor="white_4"
        onClick={toggleModal}
      />
    </StyledModal>
  )
}
