import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {REGEX_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import {TransactionCurrencyData} from 'types'
import {useDebounce} from 'utils'
import {
  DropdownItem,
  Input,
  Label,
  List,
  ListItemSelect,
  Menu,
} from 'common/components'
import {VALIDATION_GIFTSHOP_UPLOAD_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {GiftShopUploadContentPriceFormProps} from './GiftShopUploadContentFormProps'

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-top: ${convertUnit(25)};
`

const StyledCurrencyDropdownContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

export default function GiftShopUploadContentPriceForm({
  currencyName,
  form,
  stateInputPrice,
  currencyData,
}: GiftShopUploadContentPriceFormProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {price} = watch()
  const [inputPrice, setInputPrice] = stateInputPrice
  const currencyStateToggle = useState(false)
  const [toggle, setToggle] = currencyStateToggle
  const debounce = useDebounce()

  const handleSelectCurrency = useCallback(
    (item: TransactionCurrencyData) => {
      setValue('currencyName', item.currency)
      setValue('currencyId', item.id)
    },
    [setValue],
  )

  const handleRenderCurrencyDropdownItem = useCallback(
    (item: TransactionCurrencyData) => (
      <ListItemSelect
        title={item.currency}
        onClick={() => handleSelectCurrency(item)}
      />
    ),
    [handleSelectCurrency],
  )

  useEffect(() => {
    if (price.startsWith('0')) {
      setValue('price', '')
    }
    if (inputPrice === '') {
      setInputPrice('0')
    }
  }, [inputPrice, price, setInputPrice, setValue])

  return (
    <StyledPriceContainer>
      <Label
        label={translate('global:currency')}
        containerStyle={{height: convertUnit(27.4)}}>
        <StyledCurrencyDropdownContainer>
          <Menu
            stateToggle={currencyStateToggle}
            contentContainerStyle={{
              height: convertUnit(96),
              borderRadius: convertUnit(8),
            }}
            renderContent={
              <List
                data={currencyData}
                keyExtractor={(item) => item.id}
                renderItem={handleRenderCurrencyDropdownItem}
              />
            }>
            <DropdownItem
              label={currencyName}
              toggle={toggle}
              onClick={(e) => {
                e.preventDefault()
                setToggle((previous) => !previous)
              }}
            />
          </Menu>
        </StyledCurrencyDropdownContainer>
      </Label>
      <Input
        name="price"
        type="number"
        min={0}
        defaultValue={price}
        form={form}
        formRules={VALIDATION_GIFTSHOP_UPLOAD_REQUIRED}
        label={translate('global:basicPrice')}
        allowedCharacters={REGEX_NUMBER}
        autoComplete={false}
        placeholder={translate('giftShop:uploadInputPricePlaceholder')}
        containerStyle={{
          marginLeft: '25px',
          flex: 1,
        }}
        rightIcon="delete"
        onRightIconClick={() => {
          setInputPrice('0')
        }}
        onChangeText={(text) => {
          debounce(() => setInputPrice(text), 500)
        }}
      />
    </StyledPriceContainer>
  )
}
