import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDidMount} from 'utils'
import {requestData} from 'services'
import {ForkygramMusic} from 'types'

export function usePlayableMedia() {
  const [musicPool, setMusicPool] = useState<ForkygramMusic[]>([])
  const musicPoolLength = useMemo(() => musicPool.length, [musicPool])
  const stateVisibilityIndex = useState(0)
  const [visibilityIndex, setVisibilityIndex] = stateVisibilityIndex
  const stateVideoPause = useState(false)
  const setVideoPause = stateVideoPause[1]
  const stateMute = useState(true)
  const [mute, setMute] = stateMute
  const stateVideoPlaying = useState(true)
  const videoPlaying = stateVideoPlaying[0]
  const [music, setMusic] = useState<HTMLAudioElement>(new Audio())
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0)
  const [currentVolume, setCurrentVolume] = useState(0)
  const [init, setInit] = useState(false)

  const handleVisibilityIndexChange = useCallback(
    (index: number) => {
      setVisibilityIndex(index)
      setVideoPause(false)
    },
    [setVideoPause, setVisibilityIndex],
  )

  const handleGetNewMusicIndex = useCallback(() => {
    setCurrentMusicIndex((prev) => prev + 1)
  }, [])

  const handleUpdateMusic = useCallback(
    (musicObject: HTMLAudioElement) => {
      const moduloIndex =
        musicPoolLength > 0 ? currentMusicIndex % musicPoolLength : 0

      music.pause()
      if (!musicPool[moduloIndex]) return musicObject

      musicObject.src = musicPool[moduloIndex].url
      musicObject.load()
      setCurrentVolume(musicPool[moduloIndex].volume / 100)
      musicObject.volume = mute ? 0 : musicPool[moduloIndex].volume / 100
      musicObject.play().catch(() => {})
      return musicObject
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMusicIndex, music, musicPool, musicPoolLength],
  )

  const handleUpdateMusicUponIndexChange = useCallback(() => {
    setMusic((prevMusic) => handleUpdateMusic(prevMusic))
  }, [handleUpdateMusic])

  const handleGetMusicPool = useCallback(() => {
    requestData('forkygram_musics', {
      onRequestSuccess: (res) => {
        setMusicPool(res.data.result)
      },
    })
  }, [])

  useEffect(() => {
    handleGetMusicPool()
  }, [handleGetMusicPool])

  useEffect(() => {
    handleUpdateMusicUponIndexChange()
  }, [currentMusicIndex, music, handleUpdateMusicUponIndexChange])

  const handleGetLocalStorageMusicState = useCallback(async () => {
    const bgm = await localStorage.getItem('bgm')

    let parsedBgm
    try {
      parsedBgm = JSON.parse(bgm ?? '{}')
    } catch (e) {
      parsedBgm = {
        time: 0,
        index: 1,
        mute: true,
        volume: 0,
      }
    }
    return parsedBgm
  }, [])

  const updateLocalStorageMusicState = useCallback(
    async (newBgm) => {
      const parsedOldBgm = await handleGetLocalStorageMusicState()
      await localStorage.setItem(
        'bgm',
        JSON.stringify({
          ...parsedOldBgm,
          ...newBgm,
        }),
      )
    },
    [handleGetLocalStorageMusicState],
  )

  const handleCleanupMusic = useCallback(async () => {
    music.removeEventListener('ended', handleGetNewMusicIndex)
    music.pause()

    await updateLocalStorageMusicState({
      time: music.currentTime,
    })
  }, [music, handleGetNewMusicIndex, updateLocalStorageMusicState])

  const handleMusicBeforeCloseTab = useCallback(async () => {
    await handleCleanupMusic()
    await updateLocalStorageMusicState({mute: true})
  }, [handleCleanupMusic, updateLocalStorageMusicState])

  const handleInitializeMusic = useCallback(async () => {
    music.addEventListener('ended', handleGetNewMusicIndex)
    const parsedBgm = await handleGetLocalStorageMusicState()
    let validatedTime = Number(parsedBgm.time)
    if (isNaN(validatedTime)) validatedTime = 0
    music.currentTime = validatedTime

    let validatedIndex = Number(parsedBgm.index)
    if (isNaN(parsedBgm.index)) validatedIndex = 0
    setCurrentMusicIndex(validatedIndex)
    setCurrentVolume(parsedBgm.volume ? parsedBgm.volume : 0)

    setMute(parsedBgm.mute ?? true)

    await updateLocalStorageMusicState({
      time: validatedTime,
      index: Number(parsedBgm.index),
      volume: Number(parsedBgm.volume),
    })

    window.removeEventListener('beforeunload', handleMusicBeforeCloseTab)
    window.addEventListener('beforeunload', handleMusicBeforeCloseTab)

    setInit(true)
  }, [
    music,
    handleGetNewMusicIndex,
    handleGetLocalStorageMusicState,
    setMute,
    updateLocalStorageMusicState,
    handleMusicBeforeCloseTab,
  ])

  useDidMount(() => {
    handleInitializeMusic()

    return () => {
      handleCleanupMusic()
    }
  })

  const handleUpdateLocalStorageMuteAndIndex = useCallback(async () => {
    if (init) {
      const setting = {
        mute,
        index: currentMusicIndex,
        volume: currentVolume,
      }

      await updateLocalStorageMusicState(setting)
    }
  }, [
    init,
    mute,
    currentMusicIndex,
    currentVolume,
    updateLocalStorageMusicState,
  ])

  useEffect(() => {
    handleUpdateLocalStorageMuteAndIndex()
  }, [mute, currentMusicIndex, handleUpdateLocalStorageMuteAndIndex])

  const handleUpdateVideoPlayingStatus = useCallback(() => {
    if (music) {
      if (!videoPlaying && !mute) {
        const moduloIndex =
          musicPoolLength > 0 ? currentMusicIndex % musicPoolLength : 0
        setCurrentVolume(
          musicPoolLength > 0 ? musicPool[moduloIndex].volume / 100 : 0,
        )
        music.volume =
          musicPoolLength > 0 ? musicPool[moduloIndex].volume / 100 : 0
        music.play().catch(() => {})
      } else {
        if (videoPlaying) {
          music.pause()
        }
        music.volume = 0
      }
    }
  }, [currentMusicIndex, music, musicPool, musicPoolLength, mute, videoPlaying])

  useEffect(() => {
    handleUpdateVideoPlayingStatus()
  }, [handleUpdateVideoPlayingStatus, visibilityIndex])

  return useMemo(
    () => ({
      handleVisibilityIndexChange,
      stateVideoPlaying,
      stateMute,
      stateVideoPause,
      stateVisibilityIndex,
    }),
    [
      handleVisibilityIndexChange,
      stateVideoPlaying,
      stateMute,
      stateVideoPause,
      stateVisibilityIndex,
    ],
  )
}
