import {lazy} from 'react'

const GiftShopMessageScreen = lazy(
  () => import(/* webpackChunkName: 'Message' */ './GiftShopMessageScreen'),
)
const GiftShopMessageRoomScreen = lazy(
  () => import(/* webpackChunkName: 'Message' */ './GiftShopMessageRoomScreen'),
)

export {GiftShopMessageScreen, GiftShopMessageRoomScreen}
