import React, {useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_TAB_HEIGHT} from 'consts'
import {translate} from 'i18n'
import {GiftShopUploadReportStateType, ObjectState} from 'types'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledTabItemContainerProps {
  isSelected: boolean
  isDisabled: boolean
}

const StyledTabView = styled.div`
  position: relative;
  width: 100%;
  display: block;
  transition: top 0.3s;
`

const StyledTabContainer = styled.div`
  ${({theme}) => ({
    background: theme.white_1,
  })}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${convertUnit(GIFT_SHOP_TAB_HEIGHT)};
  border-bottom: 1px solid ${({theme}) => theme.gray_1};
`

const StyledTabItemContainer = styled.div<StyledTabItemContainerProps>`
  ${({theme, isSelected}) => ({
    height: isSelected
      ? `calc(100% - ${convertUnit(24)} - ${convertUnit(3)})`
      : undefined,
    color: isSelected ? theme.primary_5 : theme.gray_6,
    borderBottomStyle: isSelected ? 'solid' : undefined,
    borderBottomColor: isSelected ? theme.primary_5 : undefined,
    borderBottomWidth: isSelected ? convertUnit(3) : 0,
  })}
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(12)};
  opacity: ${({isDisabled}) => (isDisabled ? 0.6 : 1)};
  :hover {
    cursor: ${({isDisabled}) => (isDisabled ? 'default' : 'pointer')};
    opacity: 0.6;
  }
  box-sizing: content-box;
`

const StyledTitle = styled(Paragraph)`
  color: inherit;
  text-align: center;
`

export default function GiftShopUploadPublishReportTabView({
  stateViewReport,
  fail,
  duplicate,
}: {
  stateViewReport: ObjectState<GiftShopUploadReportStateType>
  fail: number
  duplicate: number
}) {
  const [viewReport, setViewReport] = stateViewReport
  const {tab} = viewReport

  const handleRenderTabView = useMemo(
    () => (
      <StyledTabView>
        <StyledTabContainer>
          <StyledTabItemContainer
            isDisabled={fail === 0}
            isSelected={tab === 'failed'}
            onClick={() => {
              fail > 0 &&
                tab !== 'failed' &&
                setViewReport((prev) => ({
                  ...prev,
                  tab: 'failed',
                }))
            }}>
            <StyledTitle fontSize="m" fontWeight="bold">
              {translate('global:failed')}
            </StyledTitle>
          </StyledTabItemContainer>
          <StyledTabItemContainer
            isDisabled={duplicate === 0}
            isSelected={tab === 'duplicate'}
            onClick={() => {
              duplicate > 0 &&
                tab !== 'duplicate' &&
                setViewReport((prev) => ({
                  ...prev,
                  tab: 'duplicate',
                }))
            }}>
            <StyledTitle fontSize="m" fontWeight="bold">
              {translate('global:duplicate')} ({duplicate})
            </StyledTitle>
          </StyledTabItemContainer>
        </StyledTabContainer>
      </StyledTabView>
    ),
    [duplicate, fail, setViewReport, tab],
  )

  return handleRenderTabView
}
