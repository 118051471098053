import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {Button, Input, InputPhone, Modal, Paragraph} from 'common/components'
import {
  AUTH_COUNTRY_DEFAULT,
  AUTH_PHONE_MIN_LENGTH_OLD,
  REGEX_PHONE_NUMBER,
} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {
  AuthCountryResponse,
  ObjectState,
  UserSimilarAccountData,
  UserSimilarAccountPayload,
  UserSimilarIdentifier,
  UserSimilarIdentifierType,
} from 'types'
import {
  VALIDATION_AUTH_EMAIL,
  VALIDATION_AUTH_PHONE_SIMILAR,
  VALIDATION_AUTH_USERNAME_SIMILAR,
} from 'common/validations'
import {useDidUpdate} from 'utils'

const StyledModal = styled(Modal)`
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(16)};
  text-align: center;
`
const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-bottom: ${convertUnit(4)};
`

interface GiftShopTemplateModalOtherAccountProps {
  data: UserSimilarAccountData
  onCancel(): void
  onConfirm(item: UserSimilarAccountPayload): void
  stateError: ObjectState<string>
  stateLoading: ObjectState<boolean>
}

export default function GiftShopTemplateModalOtherAccount({
  data,
  onCancel,
  onConfirm,
  stateError,
  stateLoading,
}: GiftShopTemplateModalOtherAccountProps) {
  const {translate} = useTranslation()
  const form = useForm<UserSimilarIdentifier>({
    defaultValues: {email: '', phone: '', username: ''},
  })
  const {errors, watch, setValue} = form
  const {email, phone, username} = watch()
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const [errorMessage, setErrorMessage] = stateError
  const loading = stateLoading[0]
  const method = useMemo<UserSimilarIdentifierType>(
    () =>
      data.signup_method === 'apple'
        ? 'username'
        : data.signup_method === 'phone'
        ? 'phone'
        : 'email',
    [data.signup_method],
  )

  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )
  const isButtonDisabled = useMemo(() => {
    if (method === 'username') {
      return (
        errors.username !== undefined || username === '' || errorMessage !== ''
      )
    }
    if (method === 'email') {
      return errors.email !== undefined || email === '' || errorMessage !== ''
    }
    if (method === 'phone') {
      return (
        phone === '' ||
        errors.phone !== undefined ||
        phone.length < AUTH_PHONE_MIN_LENGTH_OLD ||
        errorMessage !== ''
      )
    }
    return true
  }, [
    email,
    errorMessage,
    errors.email,
    errors.phone,
    errors.username,
    method,
    phone,
    username,
  ])

  const handleConfirm = useCallback(() => {
    onConfirm({
      id: data.id,
      method,
      identifier:
        method === 'username' ? username : method === 'email' ? email : phone,
    })
  }, [data.id, email, method, onConfirm, phone, username])

  const handleRenderInput = useMemo(
    () =>
      method === 'email' ? (
        <Input
          form={form}
          name="email"
          type="email"
          formRules={VALIDATION_AUTH_EMAIL}
          onChangeText={() => {
            setErrorMessage('')
            setValue('email', email.toLowerCase())
          }}
          placeholder={translate(
            'auth:registerIdentifierInputEmailPlaceholder',
          )}
        />
      ) : method === 'username' ? (
        <Input
          form={form}
          name="username"
          formRules={VALIDATION_AUTH_USERNAME_SIMILAR}
          onChangeText={() => {
            setErrorMessage('')
            setValue('username', username.toLowerCase())
          }}
          placeholder={translate(
            'giftShop:similarOtherAccountVerifUsernamePlaceholder',
          )}
        />
      ) : (
        <InputPhone
          form={form}
          name="phone"
          formRules={VALIDATION_AUTH_PHONE_SIMILAR}
          allowedCharacters={REGEX_PHONE_NUMBER}
          emptyMessage={(search) =>
            translate('auth:registerIdentifierSearchCountryEmpty', {
              search,
            })
          }
          onChangeText={() => setErrorMessage('')}
          maxLength={13}
          autoComplete={false}
          rightIcon="delete"
          loadData={handleLoadDataPhoneDummy}
          placeholder={translate(
            'auth:registerIdentifierInputPhonePlaceholder',
          )}
          stateSelect={stateCountry}
        />
      ),
    [
      email,
      form,
      handleLoadDataPhoneDummy,
      method,
      setErrorMessage,
      setValue,
      stateCountry,
      translate,
      username,
    ],
  )

  useDidUpdate(() => setErrorMessage(''), [])

  return (
    <StyledModal visible onBackdropPress={onCancel}>
      <StyledTitle fontSize="l" fontWeight="medium">
        {translate('giftShop:similarOtherAccountVerif', {context: method})}
      </StyledTitle>
      <StyledLabelContainer>
        {method === 'phone' && (
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('global:mobileNumber')}
          </Paragraph>
        )}
        <Paragraph fontSize="m" fontWeight="medium">
          {method === 'username'
            ? data.username
            : method === 'email'
            ? data.email
            : `+${data.phone_code} ${data.phone_number}`}
        </Paragraph>
      </StyledLabelContainer>
      {handleRenderInput}
      {errorMessage && (
        <Paragraph fontWeight="medium" color="danger_5">
          {errorMessage}
        </Paragraph>
      )}
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={onCancel}
        />
        <StyledButton
          label={translate('global:confirm')}
          onClick={handleConfirm}
          isLoading={loading}
          disabled={isButtonDisabled}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
