import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {Input} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

export const StyledItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

export default function TreeRegistrationFormItemCountry({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledItemsContainer>
      <Input
        disabled={disabled}
        form={form}
        name="country"
        label={translate('tree:hostRegistrationFormCountryLabel')}
        placeholder={translate('tree:hostRegistrationFormCountryPlaceholder')}
      />
    </StyledItemsContainer>
  )
}
