import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {TreeHostCreatorData} from 'types'
import {Button, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeHostManageCreatorListTemplateProps} from './TreeHostManageCreatorListTemplateProps'
import {TreeHostTemplateListHostMember} from '../../../template'

const StyledEmptyContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })};
  width: 100%;
  padding: ${convertUnit(12)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledButtonContainer = styled.div`
  padding: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(16)};
  }
`

export default function TreeHostManageCreatorListTemplate<
  ItemT extends TreeHostCreatorData,
  S
>({
  loadData,
  buttonLabel,
  onClickAdd,
  onRightComponentPress,
  onCancelInvite,
  ...props
}: TreeHostManageCreatorListTemplateProps<ItemT, S>) {
  const {translate} = useTranslation()

  const handleRenderItem = useCallback(
    (item: TreeHostCreatorData) => (
      <TreeHostTemplateListHostMember
        data={item}
        rightComponentType={item.status === 'accepted' ? 'remove' : 'cancel'}
        onCancelInvite={() =>
          onCancelInvite && onCancelInvite(item.creator_id, item.status)
        }
        onRightComponentPress={() =>
          onRightComponentPress && onRightComponentPress(item.creator_id)
        }
      />
    ),
    [onCancelInvite, onRightComponentPress],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
          {translate('tree:hostManageCreatorNoCreator')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton label={buttonLabel} onClick={onClickAdd} />
      </StyledButtonContainer>
    ),
    [buttonLabel, onClickAdd],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        {...props}
        scrollbar={false}
        loadData={loadData}
        keyExtractor={(item) => item.account_id}
        renderItem={handleRenderItem}
        listEmptyElement={handleRenderEmpty}
        listFooterElement={handleRenderButton}
      />
    ),
    [handleRenderButton, handleRenderEmpty, handleRenderItem, loadData, props],
  )

  return handleRenderList
}
