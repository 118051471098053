import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useHistory} from 'utils'
import {AvatarHighlight, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopContentDetailTemplateArtistInfoProps} from './GiftShopContentDetailTemplateArtistInfoProps'

const StyledArtistInfoContainer = styled.div`
  display: ${({hidden}) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  flex: 350;
  padding: ${convertUnit(15)} 0;
`

const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${convertUnit(25)};
`

const StyledUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledUsername = styled(Paragraph)`
  margin-left: ${convertUnit(16)};
`

const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(25)};
  padding-right: ${convertUnit(32)};
`

export default function GiftShopContentDetailTemplateArtistInfo({
  profileUrl,
  username,
  chatButton,
  hidden,
  children,
  ...props
}: GiftShopContentDetailTemplateArtistInfoProps) {
  const history = useHistory()

  const handleRenderUserInfo = useMemo(
    () => (
      <StyledUserContainer>
        <StyledUser>
          <AvatarHighlight
            src={profileUrl}
            alt={username}
            size={convertUnit(32)}
          />
          <StyledUsername fontSize="l" fontWeight="bold">
            {username}
          </StyledUsername>
        </StyledUser>
        <Icon
          type="message"
          size={convertUnit(25)}
          hidden={!chatButton}
          marginRight={convertUnit(10)}
          onClick={() => history.push('giftshop_message', {})}
        />
      </StyledUserContainer>
    ),
    [chatButton, history, profileUrl, username],
  )

  return (
    <StyledArtistInfoContainer hidden={hidden} {...props}>
      {handleRenderUserInfo}
      <StyledActionContainer>{children}</StyledActionContainer>
    </StyledArtistInfoContainer>
  )
}
