import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, ListItemSelect, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopEditProfileImageModalProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  width: ${convertUnit(325)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${convertUnit(16)};
`

const StyledModalTitle = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(25)};
  margin-bottom: ${convertUnit(15)};
`

const StyledCloseButton = styled(Button)`
  margin: ${convertUnit(25)};
  width: fill-available;
`

const StyledListItemSelect = styled(ListItemSelect)`
  padding-left: ${convertUnit(41.5)};
  height: ${convertUnit(30)};
  margin-top: ${convertUnit(10)};
  cursor: pointer;
`

export default function GiftShopEditProfileImageOptionsModal({
  defaultPic,
  visible,
  onClickClose,
  onClickEdit,
  onClickRemove,
}: GiftShopEditProfileImageModalProps) {
  const {translate} = useTranslation()
  return (
    <StyledModal visible={visible}>
      <StyledModalTitle fontSize="l" fontWeight="bold">
        {translate('global:options')}
      </StyledModalTitle>

      <StyledListItemSelect
        title={
          defaultPic
            ? translate('giftShop:choosePicture')
            : translate('giftShop:changePicture')
        }
        leftElement="pencil"
        onClick={onClickEdit}
      />
      {!defaultPic && (
        <StyledListItemSelect
          title={translate('giftShop:removePicture')}
          leftElement="trash"
          onClick={onClickRemove}
        />
      )}
      <StyledCloseButton
        color="primary_5"
        fontWeight="medium"
        onClick={onClickClose}
        backgroundColor="white_3"
        label={translate('global:close')}
      />
    </StyledModal>
  )
}
