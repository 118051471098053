import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, TREE_LEADERBOARD_LOAD_LIMIT} from 'consts'
import {Icon, ListLazy, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeFotoTreeLeaderboardData} from 'types'
import {getBoxShadow, useHistory} from 'utils'
import {useDispatch} from 'lib/redux'
import {FototreeTallestTreeProps} from './FototreeTallestTreeProps'
import FototreeTallestTreeItem from './FototreeTallestTreeItem'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: fill-available;
  background-color: ${({theme}) => theme.primary_7};
  padding: ${convertUnit(12)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      verticalOffset: 2,
      blurRadius: 4,
      opacity: 0.24,
    })};
`

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  max-width: ${convertUnit(480)};
  max-height: ${convertUnit(677)};
  margin: 0 ${convertUnit(20)};
  box-sizing: border-box;
  overflow: hidden;
  background: no-repeat center/cover
    url(${IMAGE_ASSET('giftshop', 'bg-tree.png')});
  background-color: ${({theme}) => theme.primary_7};
`

export default function FototreeTallestTree({
  stateVisible,
}: FototreeTallestTreeProps) {
  const history = useHistory()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const [visible, setVisible] = stateVisible

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('tree_get_fototree_leaderboard', {
      useDefaultMessage: true,
      params: {page, limit},
    })

    return typeof response !== 'string' && response?.status === 200
      ? response.data.result
      : []
  }, [])

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFotoTreeLeaderboardData) => {
      update('lastTreeState', {videoIntro: true})
      history.push(
        'tree_fototree_detail',
        {
          treeId: item.id,
          treeAlias: item.alias,
        },
        item.alias,
      )
    },
    [history, update],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeader>
        <Icon
          type="close"
          color="white_1"
          style={{cursor: 'pointer'}}
          onClick={() => setVisible((prev) => !prev)}
        />
        <Paragraph fontSize="l" fontWeight="medium" color="white_1">
          {translate('tree:tallestTree')}
        </Paragraph>
      </StyledHeader>
    ),
    [setVisible, translate],
  )

  const handleRenderListItem = useCallback(
    (item: TreeFotoTreeLeaderboardData, index) => (
      <FototreeTallestTreeItem
        data={item}
        rank={index + 1}
        onClickItem={handleNavigateFotoTreeDetail}
      />
    ),
    [handleNavigateFotoTreeDetail],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        keyExtractor={(item) => item.id}
        loadData={handleLoadData}
        renderItem={handleRenderListItem}
        limitPerPage={TREE_LEADERBOARD_LOAD_LIMIT}
        contentContainerStyle={{
          padding: `${convertUnit(12)} ${convertUnit(16)}`,
        }}
      />
    ),
    [handleLoadData, handleRenderListItem],
  )

  return (
    <StyledModal visible={visible}>
      {handleRenderHeader}
      {handleRenderList}
    </StyledModal>
  )
}
