import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {setLogoutModalHandler} from 'utils'
import {reduxUpdateDispatcher} from 'lib/redux'
import {LogoutModalProviderProps} from './LogoutModalProps'
import {LogoutModalContext} from './LogoutModalContext'
import LogoutModalItem from './LogoutModalItem'

export default function LogoutModalProvider({
  children,
}: LogoutModalProviderProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleShowLogoutModal = useCallback(() => {
    setOpenModal(true)
  }, [])

  const handleRemoveLogoutModal = useCallback(() => {
    setOpenModal(false)
    reduxUpdateDispatcher('lastUserState', {isTokenExpired: false})
    window.location.replace('/login')
  }, [])

  const handleRenderLogoutModal = useMemo(() => {
    if (!openModal) {
      return
    }

    return <LogoutModalItem onClickOk={handleRemoveLogoutModal} />
  }, [openModal, handleRemoveLogoutModal])

  const handler = useMemo(
    () => ({
      showLogoutModal: handleShowLogoutModal,
    }),
    [handleShowLogoutModal],
  )

  useEffect(() => {
    setLogoutModalHandler(handler)
  }, [handler])

  return (
    <LogoutModalContext.Provider value={handler}>
      {handleRenderLogoutModal}
      {children}
    </LogoutModalContext.Provider>
  )
}
