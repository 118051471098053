import React, {useMemo, useRef} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {getFileSize} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateContentVideoBadge} from '../../../template'
import {GiftShopUploadContentItemVideoImageProps} from './GiftShopUploadContentItemVideoImageProps'

interface StyledContentContainerProps {
  mode: WindowModeType
}

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  display: flex;
  padding-top: ${convertUnit(20)};
  padding-bottom: ${convertUnit(20)};
  ${({mode}) => ({
    flexDirection: mode === 'tablet' ? 'row' : 'column',
    gap: mode === 'tablet' ? convertUnit(20) : convertUnit(12),
    maxWidth: mode === 'website' ? convertUnit(226) : '100%',
    justifyContent: mode === 'tablet' ? 'space-between' : 'unset',
    paddingLeft: mode === 'mobile' ? 0 : convertUnit(20),
    paddingRight: mode === 'tablet' ? convertUnit(20) : 0,
  })}}
`

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledRelativeContainer = styled.div`
  position: relative;
`

const StyledVideo = styled.video`
  object-fit: contain;
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(226)};
  height: ${convertUnit(226)};
  aspect-ratio: 1;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }
`

const StyledHls = styled(ReactHlsPlayer)`
  object-fit: contain;
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(226)};
  height: ${convertUnit(226)};
  aspect-ratio: 1;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }
`

const StyledVideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledVideoNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  justify-content: flex-start;
  align-items: flex-start;
`

const StyledVideoName = styled(Paragraph)`
  word-break: break-all;
`

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  flex: 1;
`

export default function GiftShopUploadContentItemVideoImage({
  videoHeight,
  videoSize,
  videoTitle,
  videoSrc,
  videoWidth,
  isEdit = false,
}: GiftShopUploadContentItemVideoImageProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleRenderVideo = useMemo(
    () => (
      <StyledVideoContainer>
        <StyledRelativeContainer>
          <GiftShopTemplateContentVideoBadge offset={12} />
          {isEdit ? (
            <StyledHls playerRef={videoRef} src={videoSrc} />
          ) : (
            <StyledVideo autoPlay={false}>
              <source src={videoSrc} type="video/mp4" />
            </StyledVideo>
          )}
        </StyledRelativeContainer>
        <Paragraph fontWeight="medium" color="gray_3">
          {videoWidth} x {videoHeight}, {getFileSize(videoSize)}
        </Paragraph>
      </StyledVideoContainer>
    ),
    [isEdit, videoHeight, videoSize, videoSrc, videoWidth],
  )

  const handleRenderVideoFileName = useMemo(
    () => (
      <StyledVideoInfoContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:uploadTitleLabel')}
        </Paragraph>
        <StyledVideoNameContainer>
          <Icon
            type="video-view"
            size={convertUnit(20)}
            color="gray_4"
            style={{alignSelf: 'flex-start'}}
          />
          <StyledVideoName fontSize="m" color="gray_4">
            {videoTitle}
          </StyledVideoName>
        </StyledVideoNameContainer>
      </StyledVideoInfoContainer>
    ),
    [translate, videoTitle],
  )

  return (
    <StyledContentContainer mode={mode}>
      <StyledSectionContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:videoPreview')}
        </Paragraph>
        {handleRenderVideo}
        {handleRenderVideoFileName}
      </StyledSectionContainer>
    </StyledContentContainer>
  )
}
