import React, {useMemo} from 'react'
import {useTranslation} from 'i18n'
import {GiftshopUploadPublishStatus} from 'types'
import {
  GiftShopTemplateUploadElaborateModal,
  GiftShopTemplateUploadElaborateModalProps,
} from '../../../template'

export default function GiftShopUploadPublishModalLoadingElaborate({
  statuses,
  ...props
}: GiftShopTemplateUploadElaborateModalProps<GiftshopUploadPublishStatus>) {
  const {translate} = useTranslation()

  const {progress, total} = useMemo(
    () => ({
      progress: statuses.filter((status) => status !== 'not-started').length,
      total: statuses.length,
    }),
    [statuses],
  )
  return (
    <GiftShopTemplateUploadElaborateModal
      {...props}
      title={translate('giftShop:uploadPublishLoadingTitle')}
      description={translate('giftShop:uploadPublishLoadingDescription', {
        success: progress,
        total,
      })}
      statuses={statuses}
      note={translate('giftShop:uploadPublishLoadingNote')}
    />
  )
}
