import React, {useCallback, useMemo} from 'react'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootEmpty, GiftShopTemplateDetailContent} from 'pages'
import {requestData} from 'services'

export default function GiftShopArchiveContent() {
  const {translate} = useTranslation()
  const emptyImg = IMAGE_ASSET('giftshop', 'empty.png')

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('giftshop_get_archives', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {
        page,
        limit,
      },
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImg}
        imageAlt={emptyImg}
        title={translate('giftShop:archiveEmptyTitle')}
        message={translate('giftShop:archiveEmptySubtitle')}
      />
    ),
    [emptyImg, translate],
  )

  return (
    <GiftShopTemplateDetailContent
      isArchived
      showResBadge
      showPreviewBadge
      darkBackgroundImage
      redirectCreator
      loadData={handleLoadData}
      listEmptyElement={handleRenderEmpty}
    />
  )
}
