import React, {useMemo} from 'react'
import {translate} from 'i18n'
import {PaymentVirtualAccountInstructionsProps} from 'pages'
import PaymentCheckoutExpandDetails from './PaymentExpandDetails'

export default function PaymentInstructions({
  bank,
  accountNumber,
}: PaymentVirtualAccountInstructionsProps) {
  const handleInstruction = useMemo(
    () => (
      <>
        <PaymentCheckoutExpandDetails
          label={translate('giftShop:virtualmBankingHeader', {
            context: bank,
          })}
          description={translate('giftShop:virtualmBankingDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
        <PaymentCheckoutExpandDetails
          label={translate('global:iBanking')}
          description={translate('giftShop:virtualiBankingDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
        <PaymentCheckoutExpandDetails
          label={translate('global:transferATM')}
          description={translate('giftShop:virtualAtmDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
      </>
    ),
    [accountNumber, bank],
  )
  const handleBRIExtra = useMemo(
    () =>
      bank === 'BRI' && (
        <>
          <PaymentCheckoutExpandDetails
            label={translate('global:transferMiniATM')}
            description={translate('giftShop:virtualMiniAtmDescription_BRI')}
          />
        </>
      ),
    [bank],
  )

  return (
    <>
      {handleInstruction}
      {handleBRIExtra}
    </>
  )
}
