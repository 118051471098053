import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  ForkygramGetCommentResponse,
  ForkygramReportCategoriesResponse,
  ForkygramSelectedComment,
} from 'types'
import {requestData} from 'services'
import {ForkygramCommentItemText, ForkygramReportContent} from 'pages'
import {showSnackbar, useDidUpdate} from 'utils'
import {ConfirmationModal, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramCommentItemProps} from './ForkygramCommentItemProps'

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledLoadMoreContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(12)};
  cursor: pointer;
`

const StyledContainer = styled(StyledRowContainer)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  box-sizing: border-box;
`
const StyledReplyContainer = styled(StyledContainer)`
  padding-left: ${convertUnit(72)};
`

const StyledLoadMore = styled(Paragraph)`
  cursor: pointer;
  padding-left: ${convertUnit(72)};
`

export default function ForkygramCommentItem({
  data,
  ownerId,
  stateRecipient,
  onDelete,
  onReport,
  stateRepliedToComment,
}: ForkygramCommentItemProps) {
  const {translate} = useTranslation()

  const [loadReply, setLoadReply] = useState(false)
  const [repliedToComment, setRepliedToComment] = stateRepliedToComment

  const {username} = data.member
  const {comment_id, reply_count} = data
  const [replyData, setReplyData] = useState<
    ReadonlyArray<ForkygramGetCommentResponse>
  >([])
  const currPage = useRef(1)
  const replyNotViewed = useMemo(
    () => Math.max(0, data.reply_count - replyData.length),
    [data.reply_count, replyData.length],
  )
  const stateSelectedComment = useState<ForkygramSelectedComment>({
    member_id: '',
    comment_id: '',
  })
  const [isDataAReply, setIsDataAReply] = useState(false)
  const selectedComment = stateSelectedComment[0]
  const setRecipient = stateRecipient[1]
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalReport, setOpenModalReport] = useState(false)
  const [hasDeleteReply, setHasDeleteReply] = useState(false)

  const handleReply = useCallback(
    (replyToUsername: string) => {
      setRecipient({
        comment_id,
        username: replyToUsername,
      })
    },
    [comment_id, setRecipient],
  )

  /**
   * If successfully deleted a reply,
   * there's a chance fetched replies are duplicate of existing data.
   * Data needs to be filtered to remove redundancy.
   */
  const handleLoadReplyData = useCallback(
    async (page: number, limit: number, isDelete: boolean) => {
      const response = await requestData('forkygram_get_reply', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {id: comment_id, page, limit},
      })
      if (typeof response !== 'string' && response.status === 200) {
        if (isDelete) {
          setReplyData((prev) =>
            [...prev, ...response.data.result].filter(
              (x, index, self) =>
                index === self.findIndex((y) => y.reply_id === x.reply_id),
            ),
          )
        } else {
          setReplyData((prev) => [...prev, ...response.data.result])
        }
        setHasDeleteReply(false)
        currPage.current += 1
        setRepliedToComment('')
      }
    },
    [comment_id, setRepliedToComment],
  )

  const handlePressLoadMore = useCallback(() => {
    if (loadReply) {
      setReplyData([])
      currPage.current = 1
    } else {
      handleLoadReplyData(1, 10, hasDeleteReply)
    }
  }, [handleLoadReplyData, hasDeleteReply, loadReply])

  const handleRenderLoadMore = useMemo(
    () => (
      <StyledLoadMoreContainer
        onClick={() => {
          setLoadReply((prev) => !prev)
          handlePressLoadMore()
        }}>
        <Icon
          type={loadReply ? 'drop-up' : 'drop-down'}
          size={16}
          color="primary_5"
        />
        <Paragraph fontWeight="bold" color="primary_5">
          {loadReply
            ? translate('forkygram:hideReply')
            : translate('forkygram:viewReply', {count: data.reply_count})}
        </Paragraph>
      </StyledLoadMoreContainer>
    ),
    [data.reply_count, handlePressLoadMore, loadReply, translate],
  )

  const handleRenderReplyItem = useMemo(
    () =>
      replyData &&
      replyData.map((item) => (
        <StyledReplyContainer key={item.reply_id}>
          <ForkygramCommentItemText
            item={item}
            ownerId={ownerId}
            onReply={() => handleReply(item.member.username)}
            stateSelectedComment={stateSelectedComment}
            onDelete={() => {
              setOpenModalDelete(true)
              setIsDataAReply(true)
            }}
            onReport={() => {
              setOpenModalReport(true)
              setIsDataAReply(true)
            }}
          />
        </StyledReplyContainer>
      )),
    [handleReply, ownerId, replyData, stateSelectedComment],
  )

  const handleRenderLoadMoreReply = useMemo(
    () => (
      <StyledLoadMore
        color="primary_5"
        fontWeight="medium"
        onClick={() => {
          if (repliedToComment === comment_id) {
            setReplyData([])
            currPage.current = 1
            handleLoadReplyData(1, 10, hasDeleteReply)
          } else {
            handleLoadReplyData(currPage.current, 10, hasDeleteReply)
          }
        }}>
        {translate('forkygram:viewReply', {count: replyNotViewed})}
      </StyledLoadMore>
    ),
    [
      comment_id,
      handleLoadReplyData,
      hasDeleteReply,
      repliedToComment,
      replyNotViewed,
      translate,
    ],
  )

  const handleDeleteComment = useCallback(() => {
    requestData('forkygram_delete_comment', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        comment_id: selectedComment.comment_id,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          if (isDataAReply) {
            const deletedReply = replyData.filter(
              (x) => x.reply_id === selectedComment.comment_id,
            )
            const idx = replyData.indexOf(deletedReply[0])
            setHasDeleteReply(true)
            currPage.current =
              idx !== -1 && idx > 0 && idx % 10 === 0
                ? Math.floor(idx / 10)
                : Math.floor(idx / 10) + 1
            setReplyData((prev) =>
              prev.filter((x) => x.reply_id !== selectedComment.comment_id),
            )
          }
          const inheritedReplyCount = !isDataAReply ? reply_count : 0
          onDelete(inheritedReplyCount + 1)
        }
      },
    })
  }, [
    isDataAReply,
    onDelete,
    replyData,
    reply_count,
    selectedComment.comment_id,
  ])

  const handleRenderDeleteConfirmation = useMemo(
    () => (
      <ConfirmationModal
        visible={openModalDelete}
        title={translate('forkygram:deletePostComment')}
        text={translate('forkygram:deletePostCommentMessage')}
        onConfirm={() => handleDeleteComment()}
        toggleModal={() => setOpenModalDelete((prev) => !prev)}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
      />
    ),
    [handleDeleteComment, openModalDelete, setOpenModalDelete, translate],
  )

  const handleReportComment = useCallback(
    (category: ForkygramReportCategoriesResponse, description?: string) => {
      requestData('forkygram_post_report_comments', {
        data: {
          category_id: category.id,
          description,
          reported_id: selectedComment.comment_id,
          reported_member_id: selectedComment.member_id,
        },
        onRequestReceived: () => setOpenModalReport(false),
        onRequestFailed: () =>
          showSnackbar(translate('forkygram:reportCommentFailed')),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('forkygram:reportCommentSuccess'))
            onReport()
          } else if (status === 422) {
            showSnackbar(translate('forkygram:reportCommentAlreadyReported'))
          }
        },
      })
    },
    [
      onReport,
      selectedComment.comment_id,
      selectedComment.member_id,
      translate,
    ],
  )

  const handleRenderReportModal = useMemo(
    () => (
      <ForkygramReportContent
        visible={openModalReport}
        onSubmit={handleReportComment}
        toggleModal={() => setOpenModalReport((prev) => !prev)}
      />
    ),

    [openModalReport, setOpenModalReport, handleReportComment],
  )

  useDidUpdate(() => {
    data.reply_count === 0 && setLoadReply(false)
  }, [data.reply_count])

  return (
    <>
      {handleRenderDeleteConfirmation}
      {handleRenderReportModal}
      <StyledContainer>
        <ForkygramCommentItemText
          item={data}
          ownerId={ownerId}
          extraElement={data.reply_count > 0 ? handleRenderLoadMore : <></>}
          onReply={() => handleReply(username)}
          stateSelectedComment={stateSelectedComment}
          onDelete={() => {
            setOpenModalDelete(true)
            setIsDataAReply(false)
          }}
          onReport={() => {
            setOpenModalReport(true)
            setIsDataAReply(false)
          }}
        />
      </StyledContainer>
      {handleRenderReplyItem}
      {loadReply && replyNotViewed > 0 && handleRenderLoadMoreReply}
    </>
  )
}
