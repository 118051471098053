import React, {useMemo} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {getBorder} from 'utils'
import TreePlantTreeUpperForm from './TreePlantTreeUpperForm'
import TreePlantTreeBottomForm from './TreePlantTreeBottomForm'
import {TreePlantTreeFormProps} from './TreePlantTreeFormProps'
import {TreePlantUploadImage} from '../UploadImage'
import {TreePlantUploadVideo} from '../UploadVideo'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    flexDirection: mode === 'website' ? 'row' : 'column',
    margin: mode !== 'website' ? 0 : convertUnit(20),
    padding: mode !== 'website' ? 0 : convertUnit(20),
    border: mode !== 'website' ? 'none' : getBorder(1, 'solid', theme.gray_1),
    borderRadius: mode !== 'website' ? 0 : convertUnit(16),
    width: mode !== 'website' ? '100%' : convertUnit(900),
    minHeight: mode !== 'website' ? '100%' : convertUnit(575),
  })}
  display: flex;
  align-self: center;
  box-sizing: border-box;
  overflow-y: scroll;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledSubContainer = styled.div`
  margin: ${convertUnit(20)};
  display: flex;
  flex: 1;
  flex-direction: column;
`
export default function TreePlantTreeForm({
  form,
  imageForm,
  videoForm,
  srcState,
}: TreePlantTreeFormProps) {
  const mode = useWindowMode()

  const handleRenderWeb = useMemo(
    () => (
      <StyledContainer mode={mode}>
        <StyledSubContainer>
          <TreePlantUploadImage imageForm={imageForm} />
          <TreePlantTreeUpperForm form={form} />
        </StyledSubContainer>
        <StyledSubContainer>
          <TreePlantTreeBottomForm form={form} />
          <TreePlantUploadVideo srcState={srcState} videoForm={videoForm} />
        </StyledSubContainer>
      </StyledContainer>
    ),
    [form, imageForm, mode, srcState, videoForm],
  )

  return <>{handleRenderWeb}</>
}
