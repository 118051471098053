import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {useDidMount, useHistory} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledStarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export interface GiftShopMessageRoomContentItemNotificationCreatorReviewedProps {
  payload: FirebaseMessageRoomItemPayload['notification_creator_reviewed']
}

export default function GiftShopMessageRoomContentItemNotificationCreatorReviewed({
  payload,
}: GiftShopMessageRoomContentItemNotificationCreatorReviewedProps) {
  const {translate} = useTranslation()
  const {buyer_nickname, invoice_id, username, rating, contents} = payload
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<GiftShopContentData[]>([])
  const history = useHistory()
  const arr = useMemo(() => [1, 2, 3, 4, 5], [])

  const handleLoadData = useCallback(() => {
    contents &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      })
  }, [contents])

  const handleRenderUpper = useMemo(
    () => (
      <div>
        <Paragraph fontSize="m">
          {translate('messages:notificationCreatorReviewedOpening', {
            username,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationCreatorReviewedInfoFirst', {
            invoice_id,
          })}
        </Paragraph>
        <Paragraph fontSize="m">
          {translate('messages:notificationCreatorReviewedInfoSecond')}
        </Paragraph>
      </div>
    ),
    [invoice_id, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push(
            'giftshop_profile_creation_detail',
            {
              data,
              creationType: 'sold',
              selectedItemId: item.content_id,
              limitContent: true,
            },
            item.creator_name,
          )
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderBottomDesc = useMemo(
    () => (
      <Paragraph fontSize="m">
        {translate('messages:notificationCreatorReviewedRating', {
          buyer_nickname,
        })}
      </Paragraph>
    ),
    [buyer_nickname, translate],
  )

  const handleRenderRating = useMemo(
    () => (
      <StyledStarContainer>
        {arr.map((item, idx) => (
          <Icon
            key={`star-${item}`}
            type={idx < rating ? 'star-solid' : 'star-ol'}
            size={convertUnit(24)}
            color={idx < rating ? 'success_5' : 'gray_3'}
          />
        ))}
      </StyledStarContainer>
    ),
    [arr, rating],
  )

  useDidMount(handleLoadData)

  return (
    <StyledContainer>
      {handleRenderUpper}
      {handleRenderContent}
      {handleRenderBottomDesc}
      {handleRenderRating}
    </StyledContainer>
  )
}
