import {PhoneNumberUtil} from 'google-libphonenumber'
import {PhoneData} from 'types'

export function getPhoneNumber(number: string, regionCode?: string) {
  try {
    return PhoneNumberUtil.getInstance().parseAndKeepRawInput(
      number,
      regionCode,
    )
  } catch {
    return undefined
  }
}

export function isPhoneNumber(number: string, regionCode: string = 'ID') {
  return getPhoneNumber(number, regionCode) !== undefined
}

export function getPhoneData(number: string, regionCode: string = 'ID') {
  const phone = getPhoneNumber(number, regionCode)

  if (phone) {
    const phoneNumber = phone.getNationalNumber()
    const phoneCode = phone.getCountryCode()

    if (phoneNumber && phoneCode) {
      return {
        phoneCode: phoneCode.toString(),
        phoneNumber: phoneNumber.toString(),
      } as PhoneData
    }
  }

  return undefined
}

export function formatPhoneNumber(phoneNumber: string) {
  let formatted = ''
  for (let i = 0; i < phoneNumber.length; i += 1) {
    formatted += phoneNumber.charAt(i)
    formatted += (i + 2) % 4 === 0 ? '-' : ''
  }
  if (formatted.endsWith('-')) {
    formatted = formatted.substring(0, formatted.length - 1)
  }
  return formatted
}
