import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeEditProfileContentItemProps} from './TreeEditProfileContentItemProps'

interface StyledValueProps {
  breakWord: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledValue = styled(Paragraph)<StyledValueProps>`
  display: inline-block;
  word-break: ${({breakWord}) => (breakWord ? 'break-all' : 'break-word')};
`

export default function TreeEditProfileContentItem({
  title,
  value,
  emptyText,
  smallTitle = false,
  breakWord = false,
  onChange,
}: TreeEditProfileContentItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <Paragraph
        fontSize={smallTitle ? 's' : 'm'}
        fontWeight="medium"
        color="gray_5">
        {title}
      </Paragraph>
      <StyledValue
        breakWord={breakWord}
        fontSize="l"
        fontWeight="medium"
        color={value ? 'black' : 'gray_5'}>
        {value || emptyText}
      </StyledValue>
      {onChange && (
        <Paragraph
          fontSize="s"
          fontWeight="bold"
          color="primary_5"
          onClick={onChange}
          className="pressable">
          {translate(value ? 'global:change' : 'global:add')}
        </Paragraph>
      )}
    </StyledContainer>
  )
}
