import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AUTH_PASSWORD_MIN_LENGTH, REGEX_PASSWORD} from 'consts'
import {requestData} from 'services'
import {clearUserCache, showSnackbar, useDidMount} from 'utils'
import {Button, Input, Paragraph, TextValidationGroup} from 'common/components'
import {VALIDATION_CREATE_PASSWORD} from 'common/validations'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AuthResetPasswordProps} from './AuthResetPasswordProps'
import {AuthLinkExpired, AuthLinkSuccess} from '../../../link'

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
`

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
  width: 100%;
`

const StyledInputContainer = styled.div`
  width: 100%;
`

export default function AuthResetPassword({
  token,
  resetOnPress,
  stateTokenStatus,
  onResend,
}: AuthResetPasswordProps) {
  const {translate} = useTranslation()
  const form = useForm<{password: string; confirm: string}>({
    defaultValues: {password: '', confirm: ''},
    mode: 'onBlur',
  })
  const {watch, trigger, errors} = form
  const values = watch()
  const {confirm, password} = watch()
  const [isValid, setIsValid] = useState<boolean>()
  const [tokenStatus, setTokenStatus] = stateTokenStatus
  const [loading, setLoading] = useState(false)
  const {reset} = useDispatch()

  const handleTrigger = useCallback(() => trigger(), [trigger])

  const handleCheckToken = useCallback(() => {
    requestData('auth_check_reset_password_token', {
      actionType: 'fetch',
      params: {code: token},
      onRequestSuccess: ({status}) => {
        if (status === 200) setTokenStatus('Success')
        else if (status === 202) setTokenStatus(undefined)
        else setTokenStatus('Failed')
      },
    })
  }, [setTokenStatus, token])

  const handleResetPassword = useCallback(() => {
    setLoading(true)
    requestData('auth_reset_password', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {code: token, password},
      onRequestReceived: () => setLoading(false),
      onRequestFailed: () => {
        showSnackbar(translate('giftShop:snackbarFailedExecute'))
        setTokenStatus(undefined)
      },
      onRequestSuccess: ({status}) => {
        reset('forgotPasswordState')
        setTokenStatus(status === 200 ? undefined : 'Success')
      },
    })
  }, [password, reset, setTokenStatus, token, translate])

  const handleRenderInputPasswordConfirm = useMemo(
    () => (
      <StyledInputContainer>
        <Input
          name="confirm"
          type="password"
          form={form}
          formRules={{
            validate: {
              matcher: () =>
                confirm && password && confirm !== password
                  ? translate('auth:validationResetPasswordNotMatch')
                  : true,
            },
          }}
          autoComplete={false}
          label={translate('auth:inputPasswordConfirmLabel')}
          placeholder={translate('auth:inputPasswordConfirmPlaceholder')}
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="visibility"
          onBlur={handleTrigger}
        />
      </StyledInputContainer>
    ),
    [confirm, form, password, translate, handleTrigger],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonLogin
        disabled={
          !isValid ||
          password === '' ||
          confirm === '' ||
          Object.keys(errors).length > 0
        }
        label={translate('auth:resetPasswordButtonTitle')}
        onClick={handleResetPassword}
        isLoading={loading}
      />
    ),
    [
      isValid,
      password,
      confirm,
      errors,
      translate,
      handleResetPassword,
      loading,
    ],
  )

  const handleRenderValidation = useMemo(
    () => (
      <StyledInputContainer>
        <TextValidationGroup
          text={values.password}
          validations={[
            {
              validation: (text) => text.length >= AUTH_PASSWORD_MIN_LENGTH,
              message: translate('auth:validationPasswordLength', {
                minLength: AUTH_PASSWORD_MIN_LENGTH,
              }),
            },
            {
              validation: REGEX_PASSWORD,
              message: translate('auth:validationPassword'),
            },
          ]}
          containerStyle={{marginTop: convertUnit(25)}}
          onChangeValidity={setIsValid}
        />
      </StyledInputContainer>
    ),
    [translate, values.password],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledHeaderContainer>
          <Paragraph fontSize="xl" fontWeight="bold">
            {translate('auth:resetPasswordHeader')}
          </Paragraph>
          <StyledHeaderDescription fontSize="m" color="gray_5">
            {translate('auth:resetPasswordHeaderDescription')}
          </StyledHeaderDescription>
        </StyledHeaderContainer>
        <StyledInputContainer>
          <Input
            name="password"
            type="password"
            form={form}
            formRules={VALIDATION_CREATE_PASSWORD}
            autoComplete={false}
            label={translate('auth:loginInputPasswordLabel')}
            placeholder={translate('auth:loginInputPasswordPlaceholder')}
            containerStyle={{marginTop: convertUnit(25)}}
            rightIcon="visibility"
            onBlur={handleTrigger}
          />
        </StyledInputContainer>
        {handleRenderInputPasswordConfirm}
        {handleRenderValidation}
        {handleRenderButton}
      </>
    ),
    [
      form,
      handleRenderButton,
      handleRenderInputPasswordConfirm,
      handleRenderValidation,
      handleTrigger,
      translate,
    ],
  )

  useDidMount(() => {
    clearUserCache()
    reset('user')
    handleCheckToken()
  })

  return tokenStatus !== undefined ? (
    tokenStatus === 'Success' ? (
      handleRenderContent
    ) : tokenStatus === 'Loading' ? (
      <StyledHeaderContainer>
        <Paragraph>{translate('global:loading')}</Paragraph>
      </StyledHeaderContainer>
    ) : (
      <AuthLinkExpired
        onResend={onResend}
        resetOnPress={resetOnPress}
        cooldownText="auth:forgotPasswordPhoneResendCountdown" />
    )
  ) : (
    <AuthLinkSuccess
      title={translate('auth:linkSuccessHeader')}
      description={translate('auth:resetPasswordSuccessSubtitle')}
      information={translate('auth:resetPasswordSuccessDescription')}
    />
  )
}
