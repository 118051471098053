import {
  AUTH_DOB_MIN_AGE,
  AUTH_PASSWORD_MAX_LENGTH,
  REGEX_EMAIL_STRICT,
  REGEX_HTTPS,
  REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY,
  REGEX_NO_DASH_UNDERSCORE_PERIOD,
  REGEX_NO_SPACE,
  REGEX_URL,
  REGEX_USERNAME_CONTAINS_FOTOYU,
} from 'consts'
import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {VALIDATION_AUTH_NICKNAME, VALIDATION_AUTH_USERNAME} from '../auth'
import {getValidation} from '../Validation'

export const VALIDATION_GIFTSHOP_EDITPROFILE_EMAIL: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('giftShop:validationEmail'),
  },
  validate: {
    rule: (text) =>
      !text.match(REGEX_EMAIL_STRICT)
        ? translate('auth:validationEmail')
        : true,
  },
}

export const VALIDATION_GIFTSHOP_WEBSITE: InputFormRules = {
  validate: {
    https: (text) =>
      text !== '' && !text.match(REGEX_HTTPS)
        ? translate('auth:validationWesbite')
        : true,
    URL: (text) =>
      text !== '' && !text.match(REGEX_URL)
        ? translate('auth:validationWesbiteURL')
        : true,
  },
}

export const VALIDATION_GIFTSHOP_EDITPROFILE_DOB = (
  yearDif: number,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    minimumAge: () =>
      yearDif < AUTH_DOB_MIN_AGE
        ? translate('auth:registerMinimumAgeErrorMessage', {
            minAge: AUTH_DOB_MIN_AGE,
          })
        : true,
  },
})

export const VALIDATION_GIFTSHOP_MANAGE_PASSWORD: InputFormRules = {
  required: true,
  maxLength: AUTH_PASSWORD_MAX_LENGTH,
  messages: {
    required: getValidation('auth:validationRequired'),
    maxLength: getValidation('auth:validationPasswordMaxLength', {
      count: AUTH_PASSWORD_MAX_LENGTH,
    }),
  },
  validate: {
    notContainDashUnderscorePeriod: (text) =>
      !text.match(REGEX_NO_DASH_UNDERSCORE_PERIOD)
        ? translate('auth:validationPasswordNoDashUnderscorePeriod')
        : true,
    noSpace: (text) =>
      !text.match(REGEX_NO_SPACE)
        ? translate('auth:validationPasswordNoSpace')
        : true,
    mustUseRomanLetter: (text) =>
      !text.match(REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY)
        ? translate('auth:validationPasswordNoNonRomanCharacters')
        : true,
  },
}

export const VALIDATION_GIFTSHOP_CHANGE_USERNAME = (
  username: string | undefined,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    ...VALIDATION_AUTH_USERNAME.validate,
    notSame: (text) =>
      text === username
        ? translate('auth:validationUsernameMatchPrevious')
        : true,
  },
})

export const VALIDATION_GIFTSHOP_CHANGE_NICKNAME = (
  username: string,
  nickname: string,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('auth:validationRequired'),
  },
  validate: {
    ...VALIDATION_AUTH_NICKNAME(username).validate,
    noFotoyu: (text) =>
      text.toLowerCase().match(REGEX_USERNAME_CONTAINS_FOTOYU)
        ? translate('auth:validationNicknameContainsFotoyu')
        : true,
    notSame: (text) =>
      text === nickname
        ? translate('auth:validationNicknameMatchPrevious')
        : true,
  },
})
