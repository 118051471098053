import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadPriceLimitModalProps} from './GiftShopUploadPriceLimitModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-left: ${convertUnit(8)};
`

const StyledIcon = styled(Icon)`
  margin-bottom: ${convertUnit(8)};
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
`

export default function GiftShopUploadPriceLimitModal({
  visible,
  isUpload = false,
  toggleModal,
  continueAction,
}: GiftShopUploadPriceLimitModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <div>
        {isUpload ? (
          <></>
        ) : (
          <StyledIcon type="info-ol" size={convertUnit(40)} />
        )}
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate(
            isUpload
              ? 'giftShop:creatorPriceLimitUpgradeModalTitle'
              : 'giftShop:creatorPriceLimitModalTitle',
          )}
        </Paragraph>
      </div>
      <Paragraph fontSize="m">
        {translate(
          isUpload
            ? 'giftShop:creatorPriceLimitUpgradeModalDescription'
            : 'giftShop:creatorPriceLimitModalDescription',
        )}
      </Paragraph>
      <StyledModalButtonContainer>
        <StyledButton
          backgroundHoverColor="white_3"
          backgroundColor="white_2"
          color="primary_5"
          label={translate('global:cancel')}
          onClick={toggleModal}
        />
        {continueAction && toggleModal && (
          <StyledButton
            label={translate('global:upgrade')}
            onClick={() => {
              toggleModal()
              continueAction()
            }}
          />
        )}
      </StyledModalButtonContainer>
    </StyledModal>
  )
}
