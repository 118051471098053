import React, {useCallback, useMemo} from 'react'
import {LIST_MASONRY_PAGINATION_LOAD_LIMIT} from 'consts'
import {GiftShopContentData} from 'types'
import {ListNotLazy} from 'common/components'
import GiftShopTemplatePagination from './GiftShopTemplatePagination'
import {GiftShopTemplateListPaginationProps} from './GiftShopTemplateListPaginationProps'

export default function GiftShopTemplateListPagination({
  renderItem,
  page,
  defaultPage,
  dataCount = 0,
  setPage,
  movePage,
  loadData,
  stateData,
  ...rest
}: GiftShopTemplateListPaginationProps) {
  const pageCount = useMemo(
    () => Math.ceil(dataCount / LIST_MASONRY_PAGINATION_LOAD_LIMIT),
    [dataCount],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleRenderMasonry = useMemo(
    () => (
      <ListNotLazy
        {...rest}
        stateData={stateData}
        keyExtractor={handleKeyExtractor}
        loadData={loadData}
        page={page}
        defaultPage={page}
        pageCount={pageCount}
        renderItem={renderItem}
      />
    ),
    [
      rest,
      stateData,
      handleKeyExtractor,
      loadData,
      page,
      pageCount,
      renderItem,
    ],
  )

  return (
    <GiftShopTemplatePagination
      {...rest}
      movePage={movePage}
      loadData={loadData}
      page={page}
      setPage={setPage}
      dataCount={dataCount}
      defaultPage={defaultPage}
      renderMain={() => handleRenderMasonry}
    />
  )
}
