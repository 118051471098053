import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorHostProps {
  payload: FirebaseMessageRoomItemPayload['notification_reject_invitation_creator_host']
}

export default function GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorHost({
  payload: {host_member_username, host_tag, username, creator_type},
}: GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorHostProps) {
  const {translate} = useTranslation()

  return (
    <>
      <Paragraph fontSize="m">
        {translate('messages:hiUsername', {username})}
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        {translate(
          'messages:notificationHostOfficialCreatorInvitationRejected',
          {
            host_member_username,
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          },
        )}
      </Paragraph>
    </>
  )
}
