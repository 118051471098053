import { useCallback, useMemo } from 'react'
import {
  useDispatch as useDispatchOrigin,
  useSelector as useSelectorOrigin,
} from 'react-redux'
import {
  ReduxActionBase,
  ReduxReducerState,
  ReduxStateKey,
} from 'lib/redux'

export function useSelector<K extends ReduxStateKey>(key: K) {
  return useSelectorOrigin((state: ReduxReducerState) => state[key])
}

export function useDispatch() {
  const dispatch = useDispatchOrigin()

  const handleUpdate = useCallback(
    <K extends ReduxStateKey>(
      key: K,
      payload: Partial<ReduxReducerState[K]>,
    ) => {
      dispatch({ key, payload, type: 'update' } as ReduxActionBase<K>)
    },
    [dispatch],
  )

  const handleReset = useCallback(
    <K extends ReduxStateKey>(key: K) => {
      dispatch({ key, type: 'reset' } as ReduxActionBase<K>)
    },
    [dispatch],
  )

  return useMemo(
    () => ({ update: handleUpdate, reset: handleReset }),
    [handleReset, handleUpdate],
  )
}
