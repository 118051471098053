import React, {useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {Modal, Icon, Paragraph, Button} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSetAsMainBankConfirmationModalProps} from './GiftShopAddBankAccountModalProps'

const StyledSelectionModalContainer = styled.div<React.CSSProperties>`
  width: ${({width = convertUnit(300)}) => width};
  padding: ${convertUnit(25)} ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledConfirmationButton = styled(Button)`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(20)};
`

export default function GiftShopAddBankAccountConfirmationModal({
  onConfirm,
  isSuccessful,
  action,
}: GiftShopSetAsMainBankConfirmationModalProps) {
  const renderModalMessage = useMemo(() => {
    let message = ''
    if (action === 'add') {
      message = isSuccessful
        ? translate('giftShop:addMainAccountSuccess')
        : translate('giftShop:addMainAccountFailed')
    } else {
      message = isSuccessful
        ? translate('giftShop:removeMainAccountSuccess')
        : translate('giftShop:removeMainAccountFailed')
    }

    return (
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {message}
      </StyledParagraph>
    )
  }, [action, isSuccessful])

  return (
    <Modal visible>
      <StyledSelectionModalContainer>
        <Icon
          type={isSuccessful ? 'check' : 'close'}
          color={isSuccessful ? 'success_5' : 'danger_5'}
          size={50}
        />
        {renderModalMessage}

        <StyledConfirmationButton
          label={translate('global:ok')}
          width={convertUnit(125)}
          backgroundColor="primary_5"
          color="white_1"
          onClick={onConfirm}
        />
      </StyledSelectionModalContainer>
    </Modal>
  )
}
