import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {useHistory} from 'utils'
import {Button, List, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftshopProfileModalYuserListComponent from './GiftshopProfileModalYuserListComponent'
import {ModalYuserProps} from './GiftshopModalYuserProps'

const StyledModal = styled(Modal)`
  display: flex;
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  margin: ${convertUnit(20)};
  flex-direction: column;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const StyledDesc = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(10)};
`
const StyledListContainer = styled.div`
  display: flex;
  height: ${convertUnit(160)};
`

const StyledButtonClose = styled(Button)`
  flex: 1;
  margin-top: ${convertUnit(10)};
  ${({theme}) => ({backgroundColor: theme.white_3})}
`

export default function GiftshopProfileModalYuser({
  visible,
  toggleModal,
  yuserMemberId,
}: ModalYuserProps) {
  const history = useHistory()
  const handleTitle = useMemo(
    () => (
      <StyledTitle fontSize="xl" fontWeight="bold">
        {translate('giftShop:chooseYuser')}
      </StyledTitle>
    ),
    [],
  )

  const handleDescription = useMemo(
    () => (
      <StyledDesc fontSize="m" color="gray_5">
        {translate('giftShop:chooseYuserDesc')}
      </StyledDesc>
    ),
    [],
  )

  const handleRenderComponent = useCallback(
    (yuserId: string) => (
      <GiftshopProfileModalYuserListComponent
        yuserMemberId={yuserId}
        onClickChat={() => {
          history.push('giftshop_message_room', {
            participantId: yuserId,
            selfRole: 'seller',
            roomType: 'giftshop',
          })
        }}
      />
    ),
    [history],
  )

  const handleRenderYuserList = useMemo(
    () => (
      <StyledListContainer>
        <List
          data={yuserMemberId}
          keyExtractor={(item) => item}
          renderItem={handleRenderComponent}
          scrollbar={false}
        />
      </StyledListContainer>
    ),
    [handleRenderComponent, yuserMemberId],
  )

  const handleCloseButton = useMemo(
    () => (
      <StyledButtonClose
        label={translate('global:close')}
        color="primary_5"
        onClick={toggleModal}
      />
    ),
    [toggleModal],
  )

  return (
    <StyledModal visible={visible}>
      {handleTitle}
      {handleDescription}
      {handleRenderYuserList}
      {handleCloseButton}
    </StyledModal>
  )
}
