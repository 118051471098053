import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestDataPayload} from 'services'
import {GiftShopBankResponseBody} from 'types'
import {Icon, Input, ListItemSelect, ListLazy, Menu} from 'common/components'
import {VALIDATION_TREE_REQUIRED} from 'common/validations'
import convertUnit from 'lib/unit'
import {TreeRegistrationFormItemProps} from './TreeRegistrationFormItemProps'

const StyledLeftItemsContainer = styled.div`
  margin-top: ${convertUnit(20)};
  width: 50%;
  margin-right: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: 0;
    width: 100%;
  }
`

export const StyledContentListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

export default function TreeRegistrationFormItemBankName({
  disabled,
  form,
}: TreeRegistrationFormItemProps) {
  const {translate} = useTranslation()
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const stateBanks = useState<readonly GiftShopBankResponseBody[]>([])
  const banks = stateBanks[0]
  const {watch, setValue, clearErrors} = form
  const {bankName} = watch()

  const handleGetBanks = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestDataPayload('giftshop_get_banks', {
        params: {page, limit, q},
        useDefaultMessage: true,
        actionType: 'fetch',
      })
      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [],
  )

  const handleSelect = useCallback(
    (item: GiftShopBankResponseBody) => {
      setValue('bankId', item.id)
      setValue('bankName', item.name)
      setToggle((previous) => !previous)
      clearErrors('bankName')
    },
    [clearErrors, setToggle, setValue],
  )

  const handleRenderDropdownItem = useCallback(
    (item: GiftShopBankResponseBody) => (
      <ListItemSelect title={item.name} onClick={() => handleSelect(item)} />
    ),
    [handleSelect],
  )

  const handleRenderBankList = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateBanks}
          loadData={handleGetBanks}
          search={bankName}
          data={banks}
          keyExtractor={(bank) => bank.id}
          renderItem={handleRenderDropdownItem}
        />
      </StyledContentListContainer>
    ),
    [stateBanks, handleGetBanks, bankName, banks, handleRenderDropdownItem],
  )

  return (
    <StyledLeftItemsContainer>
      <Menu
        stateToggle={stateToggle}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={handleRenderBankList}>
        <Input
          name="bankName"
          form={form}
          formRules={VALIDATION_TREE_REQUIRED}
          disabled={disabled}
          label={translate('tree:hostRegistrationFormBankNameLabel')}
          placeholder={translate(
            'tree:hostRegistrationFormBankNamePlaceholder',
          )}
          onChangeText={() => setToggle(true)}
          onFocus={() => setToggle(true)}
          rightIcon={
            !disabled && bankName.length > 0 ? (
              <Icon
                type="close"
                size={convertUnit(16)}
                onClick={() => setValue('bankName', '')}
              />
            ) : undefined
          }
        />
      </Menu>
    </StyledLeftItemsContainer>
  )
}
