import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from 'register-sw'
import {initTranslation} from './i18n'
import {APPLICATION_ROOT_ID} from './consts'
import App from './App'

import(
  /* webpackChunkName: 'ConfigLazysizes' */ 'config/lazysizes'
).then((data) => data.lazySizesInit())

initTranslation()

const mountElement: HTMLElement | null = document.getElementById(
  APPLICATION_ROOT_ID,
)

if (mountElement) {
  ReactDOM.render(React.createElement(App), mountElement)
  registerServiceWorker()
}
