import React from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_NOT_LINK_BASED_SOCIAL_MEDIA} from 'consts'
import {Paragraph} from 'common/components'
import {ProfileDetailSocialLinkItemProps} from './TemplateProfileDetailItemProps'

const StyledSocialSpan = styled.span`
  color: ${({theme}) => theme.info_3};
  cursor: pointer;
`
export default function ProfileDetailSocialLinkItem({
  item,
  onClickItem,
}: ProfileDetailSocialLinkItemProps) {
  return (
    <Paragraph fontSize="m" fontWeight="bold" color="white_1">
      {item.social_type.charAt(0).toUpperCase() + item.social_type.substring(1)}
      :{' '}
      <StyledSocialSpan onClick={() => onClickItem(item)}>
        {GIFT_SHOP_NOT_LINK_BASED_SOCIAL_MEDIA.includes(item.social_type)
          ? item.social_value.substring(item.social_value.lastIndexOf('/') + 1)
          : item.social_value}
      </StyledSocialSpan>
    </Paragraph>
  )
}
