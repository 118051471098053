import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  TreeHostCreatorData,
  TreeHostCreatorType,
  TreeHostInvitationStatus,
} from 'types'
import {showSnackbar, useHistory} from 'utils'
import {ConfirmationModal, ModalLoading} from 'common/components'
import {TreeHostManageCreatorListTemplate} from '../CreatorListTemplate'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledListContainer = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: ${({theme}) => theme.white_1};
`

export default function TreeHostManageCreatorListTab() {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<TreeHostCreatorData>>([])
  const setData = stateData[1]
  const [selected, setSelected] = useState('')
  const [modal, setModal] = useState(false)
  const stateSelect = useState<TreeHostCreatorType | undefined>('internal')
  const [loading, setLoading] = useState(true)
  const selectedTab = stateSelect[0]

  const handleLoadInternalData = useCallback(async () => {
    setLoading(true)
    const response = await requestData('tree_host_get_host_creator', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {type: 'internal'},
      onRequestReceived: () => setLoading(false),
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleRightComponentPress = useCallback((creatorId: string) => {
    setSelected(creatorId)
    setModal((prev) => !prev)
  }, [])

  const handleDemoteCreator = useCallback(
    async (creatorId: string, hostStatus?: TreeHostInvitationStatus) => {
      await requestData('tree_host_demote_creator', {
        useDefaultMessage: true,
        actionType: 'execute',
        params: {creator_id: creatorId},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) =>
              prev.filter((item) => item.creator_id !== creatorId),
            )
            hostStatus === 'unconfirmed' &&
              showSnackbar(translate('tree:hostCancelInvitationSuccess'))
          }
        },
      })
    },
    [setData, translate],
  )

  const handleRenderInternalCreatorList = useMemo(
    () => (
      <TreeHostManageCreatorListTemplate
        stateData={stateData}
        loadData={handleLoadInternalData}
        buttonLabel={translate('tree:hostManageCreatorAddCreatorInternal')}
        search={selectedTab}
        onClickAdd={() =>
          history.push('tree_host_promote_creator', {creatorType: 'internal'})
        }
        onCancelInvite={(creatorId, hostStatus) =>
          handleDemoteCreator(creatorId, hostStatus)
        }
        onRightComponentPress={handleRightComponentPress}
      />
    ),
    [
      handleDemoteCreator,
      handleLoadInternalData,
      handleRightComponentPress,
      history,
      selectedTab,
      stateData,
      translate,
    ],
  )

  const handleRenderDemoteConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modal}
        title={translate('tree:hostManageCreatorDemoteCreator')}
        text={translate('tree:hostManageCreatorDemoteCreatorContent')}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        onConfirm={() => handleDemoteCreator(selected)}
        toggleModal={() => {
          setSelected('')
          setModal((prev) => !prev)
        }}
      />
    ),
    [handleDemoteCreator, modal, selected, translate],
  )

  const handleRenderLoadingModal = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  return (
    <StyledContainer>
      {handleRenderLoadingModal}
      {handleRenderDemoteConfirmationModal}
      <StyledListContainer>
        {handleRenderInternalCreatorList}
      </StyledListContainer>
    </StyledContainer>
  )
}
