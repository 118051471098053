import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {TreeHostTemplateListHostMember, TreeHostWarningBox} from 'pages'
import {requestData} from 'services'
import {TreeHostAdminData, WindowModeType} from 'types'
import {showSnackbar, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  ConfirmationModal,
  ListLazy,
  ModalLoading,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    padding: mode === 'mobile' ? 0 : undefined,
    width: mode === 'website' ? convertUnit(600) : '100%',
    height: mode === 'website' ? convertUnit(610) : '100%',
  })};
  display: flex;
  border-radius: ${convertUnit(16)};
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-self: center;
`

const StyledNoAdminContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })};
  height: ${convertUnit(50)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledListContainer = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertUnit(20)};
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_2};
    width: ${convertUnit(5)};
  }
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function TreeHostManageAdmin() {
  const [modal, setModal] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(true)
  const [selected, setSelected] = useState<TreeHostAdminData>({
    account_id: '',
    username: '',
    photo: '',
  })
  const mode = useWindowMode()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<TreeHostAdminData>>([])
  const [data, setData] = stateData
  const totalAdmin = useMemo(() => data.length, [data.length])

  const handleOnAddAdmin = useCallback(() => {
    history.push('tree_host_promote_admin', {totalAdmin})
  }, [history, totalAdmin])

  const handleLoadAdmin = useCallback(async () => {
    setShowLoadingModal(true)
    const response = await requestData('tree_host_get_host_admin', {
      actionType: 'fetch',
      useDefaultMessage: true,
      onRequestSuccess: ({status}) => {
        status === 200 && setShowLoadingModal(false)
      },
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleDemoteAdmin = useCallback(
    async ({account_id, status: hostStatus}: TreeHostAdminData) => {
      await requestData('tree_host_demote_host_admin', {
        params: {
          account_id,
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((previous) =>
              previous.filter((item) => item.account_id !== account_id),
            )
            hostStatus === 'unconfirmed' &&
              showSnackbar(translate('tree:hostCancelInvitationSuccess'))
          } else {
            showSnackbar(translate('global:messageError'))
          }
        },
      })
    },
    [setData],
  )

  const handleRenderNoAdmin = useMemo(
    () => (
      <StyledNoAdminContainer>
        <Paragraph fontSize="m" fontWeight="bold" color="gray_3">
          {translate('tree:hostManageAdminNoAdmin')}
        </Paragraph>
      </StyledNoAdminContainer>
    ),
    [],
  )

  const handleDeleteModal = useMemo(
    () =>
      modal && (
        <ConfirmationModal
          title={translate('tree:hostManageAdminDemoteAdmin')}
          text={translate('tree:hostManageAdminDemoteAdminContent')}
          onConfirm={() => handleDemoteAdmin(selected)}
          cancelText={translate('global:cancel')}
          confirmText={translate('global:yesImSure')}
          toggleModal={() => setModal((previous) => !previous)}
          visible={modal}
        />
      ),
    [handleDemoteAdmin, modal, selected],
  )

  const renderAdminListItem = useCallback(
    (item: TreeHostAdminData) => (
      <TreeHostTemplateListHostMember
        data={item}
        rightComponentType={item.status === 'unconfirmed' ? 'cancel' : 'remove'}
        onRightComponentPress={() => {
          setSelected(item)
          setModal(true)
        }}
        onCancelInvite={() => handleDemoteAdmin(item)}
      />
    ),
    [handleDemoteAdmin],
  )

  const handleRenderAddAdminButton = useMemo(
    () => (
      <StyledButton
        label={translate('tree:hostManageAdminAddAdmin')}
        disabled={totalAdmin >= 5}
        onClick={handleOnAddAdmin}
      />
    ),
    [handleOnAddAdmin, totalAdmin],
  )

  const handleRenderAdminList = useMemo(
    () => (
      <ListLazy
        stateData={stateData}
        style={{marginTop: convertUnit(20)}}
        loadData={handleLoadAdmin}
        keyExtractor={(item) => item.account_id}
        emptyContainerStyle={{flex: 0}}
        renderItem={renderAdminListItem}
        listEmptyElement={handleRenderNoAdmin}
        listFooterElement={handleRenderAddAdminButton}
        scrollbar={false}
      />
    ),
    [
      handleLoadAdmin,
      handleRenderAddAdminButton,
      handleRenderNoAdmin,
      renderAdminListItem,
      stateData,
    ],
  )

  const handleWarningBox = useMemo(
    () => (
      <TreeHostWarningBox
        backgroundColor="white_3"
        fontColor="black"
        label={translate('tree:hostManageAdminCaution')}
      />
    ),
    [],
  )

  const handleLoadingModal = useMemo(
    () => <ModalLoading visible={showLoadingModal} />,
    [showLoadingModal],
  )

  return (
    <StyledContainer mode={mode}>
      <StyledListContainer>
        {handleWarningBox}
        {handleRenderAdminList}
      </StyledListContainer>
      {handleDeleteModal}
      {handleLoadingModal}
    </StyledContainer>
  )
}
