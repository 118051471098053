import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  AUTH_PASSWORD_MIN_LENGTH,
  REGEX_PASSWORD,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  GiftshopVerificationMethod,
  GiftShopVerificationPasswordModalStatus,
  PasswordForm,
  WindowModeType,
} from 'types'
import {showPrompt, showSnackbar, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  Icon,
  Input,
  Paragraph,
  TextValidationGroup,
} from 'common/components'
import {VALIDATION_GIFTSHOP_MANAGE_PASSWORD} from 'common/validations'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateVerificationMethodModal} from '../../../template'
import {
  GiftShopEditProfilePasswordWrongOTPModal,
  GiftShopEditProfilePasswordOTPModal,
  GiftShopEditProfileSuccessStatus,
} from '../Modal'
import {GiftShopFormPropsBase} from './GiftShopFormProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(20)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(480)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
  border-radius: ${convertUnit(30)};
`

const StyledDisclaimerContainer = styled.div<StyledContainerProps>`
  background-color: ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  gap: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_3};
  margin-bottom: ${convertUnit(20)};
`

export default function GiftshopPasswordForm({
  stateActiveContent,
  backToPage,
}: GiftShopFormPropsBase) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {update} = useDispatch()
  const mode = useWindowMode()
  const {has_password, phone_number} = useSelector('user') || {}
  const {phoneOTPTime, emailTime} = useSelector('lastUserState')
  const setActiveContent = stateActiveContent[1]
  const stateSelection = useState<GiftshopVerificationMethod>(
    phone_number ? 'whatsapp' : 'email',
  )
  const selection = stateSelection[0]
  const [modal, setModal] = useState<GiftShopVerificationPasswordModalStatus>(
    'hidden',
  )

  const form = useForm<PasswordForm>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    mode: 'onChange',
  })

  const {handleSubmit, watch, errors, trigger} = form
  const {newPassword, confirmNewPassword} = watch()
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [success, setSuccess] = useState(false)
  const [publishedState, setPublishedState] = useState(false)
  const today = useMemo(() => new Date().getTime(), [])
  const emailTimeDiff = Math.floor(today - emailTime) / 1000
  const timeDiff = Math.floor(today - phoneOTPTime) / 1000
  const continueCount: boolean =
    selection === 'email' ? emailTimeDiff < 300 : timeDiff < 300

  const handleToggleModal = useCallback(() => {
    setModal('hidden')
    setLoading(false)
  }, [])

  const handleBackToPage = useCallback(() => {
    handleToggleModal()
    history.goBack()
  }, [handleToggleModal, history])

  const handleCreateNewPassword = useCallback(() => {
    setLoading(true)
    requestData('auth_patch_passwordsso', {
      data: {
        password: newPassword,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        setLoading(false)
        if (status === 200) {
          update('user', {has_password: true})
          setSuccess(true)
        } else {
          setSuccess(false)
        }
        setModal('confirmation')
      },
    })
  }, [newPassword, update])

  const handleChangePassword = useCallback(() => {
    setLoading(true)
    requestData('giftshop_patch_userPassword', {
      data: {
        method: selection,
        password: newPassword,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status, data: {message}}) => {
        setLoading(false)
        if (status === 200) {
          setPublishedState(true)
          if (selection === 'email') {
            update('lastUserState', {
              emailTime: new Date().getTime(),
            })
          } else {
            update('lastUserState', {
              phoneOTPTime: new Date().getTime(),
            })
          }
          setModal('verification')
        } else if (status === 429) {
          const msgSplit = message.split(' ')
          const msgLen = message.split(' ').length
          const countdown = msgSplit[msgLen - 1]
          handleToggleModal()
          showSnackbar(translate('global:pleaseTryAgain', {countdown}))
        } else {
          setSuccess(false)
          setModal('confirmation')
        }
      },
    })
  }, [
    handleToggleModal,
    newPassword,
    selection,
    translate,
    update,
  ])

  const handleFormSubmit = useCallback(() => {
    if (continueCount) {
      setModal('verification')
    } else handleChangePassword()
  }, [continueCount, handleChangePassword])

  const handleSuccessConfirm = useCallback(() => {
    backToPage ? handleBackToPage() : setActiveContent('profile')
  }, [backToPage, handleBackToPage, setActiveContent])

  const handleRenderSelectionModal = useMemo(
    () => (
      <GiftShopTemplateVerificationMethodModal
        loading={loading}
        stateVerificationMethod={stateSelection}
        modalStage={modal}
        toggleModal={handleToggleModal}
        onSelect={handleSubmit(
          modal === 'selection' ? handleFormSubmit : handleChangePassword,
        )}
      />
    ),
    [
      handleChangePassword,
      handleFormSubmit,
      handleSubmit,
      handleToggleModal,
      loading,
      modal,
      stateSelection,
    ],
  )

  const handleRenderOTPModal = useMemo(
    () => (
      <GiftShopEditProfilePasswordOTPModal
        verificationMethod={selection}
        onCancel={handleToggleModal}
        onResend={() =>
          selection === 'email'
            ? handleChangePassword()
            : setModal('selection-resend')
        }
        onSuccess={() => {
          setSuccess(true)
          setModal('confirmation')
        }}
        onFailed={() => {
          setSuccess(false)
          setModal('confirmation')
        }}
        onWrongOTP={() => {
          setModal('wrong-otp')
        }}
      />
    ),
    [handleChangePassword, handleToggleModal, selection],
  )

  const handleRenderSuccessStatus = useMemo(
    () => (
      <GiftShopEditProfileSuccessStatus
        success={success}
        backToPage={backToPage}
        desc={translate(
          success
            ? has_password
              ? 'giftShop:forgotPasswordSuccessDescription'
              : 'giftShop:successAddPassword'
            : 'giftShop:problemWhenProcessingRequest',
        )}
        onClickButton={() =>
          success ? handleSuccessConfirm() : handleToggleModal()
        }
      />
    ),
    [
      backToPage,
      handleSuccessConfirm,
      handleToggleModal,
      has_password,
      success,
      translate,
    ],
  )

  const handleRenderWrongOTP = useMemo(
    () => (
      <GiftShopEditProfilePasswordWrongOTPModal
        toggleModal={() => setActiveContent('profile')}
      />
    ),
    [setActiveContent],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'verification':
        return handleRenderOTPModal
      case 'selection':
      case 'selection-resend':
        return handleRenderSelectionModal
      case 'confirmation':
        return handleRenderSuccessStatus
      case 'wrong-otp':
        return handleRenderWrongOTP
      case 'hidden':
        return null
    }
  }, [
    handleRenderWrongOTP,
    handleRenderOTPModal,
    handleRenderSelectionModal,
    handleRenderSuccessStatus,
    modal,
  ])

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={
          !isValid &&
          (errors.newPassword !== undefined ||
            newPassword === '' ||
            errors.confirmNewPassword !== undefined ||
            confirmNewPassword === '')
        }
        isLoading={loading}
        label={translate('global:save')}
        onClick={() =>
          has_password ? setModal('selection') : handleCreateNewPassword()
        }
      />
    ),
    [
      confirmNewPassword,
      errors.confirmNewPassword,
      errors.newPassword,
      handleCreateNewPassword,
      has_password,
      isValid,
      loading,
      newPassword,
      translate,
    ],
  )

  const handleRenderTextValidation = useMemo(
    () => (
      <TextValidationGroup
        text={newPassword}
        validations={[
          {
            validation: (text) => text.length >= AUTH_PASSWORD_MIN_LENGTH,
            message: translate('auth:validationPasswordLength', {
              minLength: AUTH_PASSWORD_MIN_LENGTH,
            }),
          },
          {
            validation: REGEX_PASSWORD,
            message: translate('auth:validationPasswordError'),
          },
        ]}
        containerStyle={{marginTop: convertUnit(20)}}
        onChangeValidity={() => setIsValid}
      />
    ),
    [newPassword, translate],
  )

  useEffect(() => {
    const prompt =
      (newPassword.length > 0 || confirmNewPassword.length > 0) &&
      !publishedState &&
      !backToPage
    showPrompt(prompt, translate('giftShop:uploadModalMessage'))
  }, [
    newPassword.length,
    confirmNewPassword.length,
    translate,
    backToPage,
    publishedState,
  ])

  useEffect(() => {
    confirmNewPassword !== '' && trigger('confirmNewPassword')
  }, [newPassword, confirmNewPassword, trigger])

  return (
    <StyledContainer>
      {handleRenderModal}
      <StyledScreenContainer>
      <StyledDisclaimerContainer mode={mode}>
        <Icon type="info" size={convertUnit(16)} />
        <Paragraph fontWeight="medium" lineHeight={21}>
          {translate('giftShop:changePasswordWarning')}
        </Paragraph>
      </StyledDisclaimerContainer>
        <Input
          type="password"
          name="newPassword"
          form={form}
          formRules={VALIDATION_GIFTSHOP_MANAGE_PASSWORD}
          label={translate('giftShop:newPassword')}
          placeholder={translate('giftShop:newPasswordPlaceholder')}
          rightIcon="visibility"
        />
        <Input
          type="password"
          name="confirmNewPassword"
          form={form}
          formRules={{
            validate: (value) =>
              value === newPassword
                ? undefined
                : translate('auth:validationResetPasswordNotMatch'),
          }}
          label={translate('giftShop:confirmNewPassword')}
          placeholder={translate('giftShop:confirmNewPasswordPlaceholder')}
          containerStyle={{marginTop: convertUnit(20)}}
          rightIcon="visibility"
        />
        {handleRenderTextValidation}
        {handleRenderButton}
      </StyledScreenContainer>
    </StyledContainer>
  )
}
