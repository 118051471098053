import React from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import {Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {GiftShopUploadUploadModalVideoAutoPreviewProps} from './GiftShopUploadUploadModalVideoAutoPreviewProps'

interface StyledProgressProps {
  progressColor: ThemeColor
  progress: number
}

const StyledModal = styled(Modal)`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(325)};
  padding: ${convertUnit(20)};
`

const StyledParagraph = styled(Paragraph)`
  line-height: ${convertUnit(26)};
  text-align: center;
`

const StyledProgressContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.gray_1})}
  width: 100%;
  height: ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
  margin: ${convertUnit(20)} 0;
  overflow: hidden;
`

const StyledProgress = styled.div<StyledProgressProps>`
  ${({theme, progress, progressColor}) => ({
    backgroundColor: theme[progressColor],
    width: `${progress * 100}%`,
  })}
  height: 100%;
`

export default function GiftShopUploadUploadModalVideoAutoPreview({
  visible,
  processing,
  uploading,
}: GiftShopUploadUploadModalVideoAutoPreviewProps) {
  const successAll = processing.file_loaded + uploading.file_loaded
  const totalAll = processing.total_file + uploading.total_file

  return (
    <StyledModal visible={visible}>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {translate('giftShop:contentModalLoadingHeader')}
      </StyledParagraph>
      <StyledParagraph fontSize="m" color="gray_3">
        {translate('giftShop:modalLoadingDescription')}
      </StyledParagraph>
      <StyledProgressContainer>
        <StyledProgress
          progress={successAll / totalAll}
          progressColor="primary_5"
        />
      </StyledProgressContainer>
      {!uploading.isLoading && (
        <StyledParagraph fontSize="l" color="primary_5">
          {translate('giftShop:contentModalLoadingProcessingDescription', {
            success: processing.file_loaded,
            total: processing.total_file,
          })}
        </StyledParagraph>
      )}
      <StyledParagraph
        fontSize="l"
        color={uploading.isLoading ? 'primary_5' : 'gray_5'}>
        {translate('giftShop:contentModalLoadingUploadingDescription', {
          success: uploading.file_loaded,
          total: uploading.total_file,
        })}
      </StyledParagraph>
    </StyledModal>
  )
}
