import {lazy} from 'react'

const GiftShopEditProfileScreen = lazy(
  () =>
    import(/* webpackChunkName: 'EditProfile' */ './GiftShopEditProfileScreen'),
)

const GiftShopEditProfileChangePasswordStatusScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'EditProfileChangePasswordStatus' */ './GiftShopEditProfileChangePasswordStatusScreen'
    ),
)

const GiftShopEditProfileResetPasswordScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'EditProfileResetPassword' */ './GiftShopEditProfileResetPasswordScreen'
    ),
)

export * from './GiftShopEditProfileScreenParam'

export {
  GiftShopEditProfileScreen,
  GiftShopEditProfileChangePasswordStatusScreen,
  GiftShopEditProfileResetPasswordScreen,
}
