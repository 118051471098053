import React from 'react'
import styled from 'styled-components'
import {Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {getBoxShadow} from 'utils'
import {GiftShopTemplateContentVideoBadgeProps} from './GiftShopTemplateContentBadgeProps'

const StyledBadgeContainer = styled.div<GiftShopTemplateContentVideoBadgeProps>`
  ${({theme, width = 32, offset = 8}) => ({
    backgroundColor: theme.primary_5,
    boxShadow: getBoxShadow(theme, {
      verticalOffset: 1,
      blurRadius: 6,
      opacity: 0.4,
    }),
    right: convertUnit(offset),
    bottom: convertUnit(offset),
    width: convertUnit(width),
  })}
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: 3;
`

export default function GiftShopTemplateContentVideoBadge({
  width = 32,
  offset,
}: GiftShopTemplateContentVideoBadgeProps) {
  return (
    <StyledBadgeContainer width={width} offset={offset}>
      <Icon type="play" size={convertUnit(width * 0.625)} color="white_1" />
    </StyledBadgeContainer>
  )
}
