import React, {useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_TAB_HEIGHT} from 'consts'
import {ThemeColor} from 'themes'
import {useDefaultState, useDidUpdate} from 'utils'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'
import {TabViewProps} from './TabViewProps'

interface StyledTabItemContainerProps {
  isSelected: boolean
  disabledColor: ThemeColor
  bottomBorder: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: ${({theme}) => theme.white_1};
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${convertUnit(GIFT_SHOP_TAB_HEIGHT)};
`

const StyledTabIconContainer = styled.div`
  margin-right: 12px;
`

const StyledTabItemContainer = styled.div<StyledTabItemContainerProps>`
  ${({isSelected, theme, disabledColor, bottomBorder}) => ({
    borderBottomColor: isSelected
      ? theme.primary_5
      : bottomBorder
      ? theme[disabledColor]
      : undefined,
    color: isSelected ? theme.primary_5 : theme[disabledColor],
    borderBottomStyle: isSelected || bottomBorder ? 'solid' : undefined,
    borderBottomWidth: isSelected || bottomBorder ? convertUnit(3) : 0,
  })}
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${convertUnit(12)};
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const StyledTitle = styled(Paragraph)`
  color: inherit;
  text-align: center;
`

export default function TabView<T extends string>({
  routes,
  selectedRoute,
  containerStyle,
  stateSelect,
  disabledColor = 'gray_1',
  bottomBorder = true,
  disabledClick = false,
  showTab = true,
  renderIcon,
  renderTitle,
  renderItem,
  onClickItem,
}: TabViewProps<T>) {
  const [select, setSelect] = useDefaultState<T | undefined>(
    selectedRoute || (routes.length ? routes[0] : undefined),
    stateSelect,
  )

  const handleRenderTabItems = useMemo(
    () =>
      routes.map((route) => (
        <StyledTabItemContainer
          data-testid="tab"
          key={route}
          isSelected={select === route}
          disabledColor={disabledColor}
          bottomBorder={bottomBorder}
          onClick={
            disabledClick
              ? undefined
              : () => {
                  setSelect(route)
                  onClickItem && onClickItem(route)
                }
          }>
          <StyledTabIconContainer>
            {renderIcon ? renderIcon(route) : null}
          </StyledTabIconContainer>
          <StyledTitle fontSize="m" fontWeight="medium">
            {renderTitle(route)}
          </StyledTitle>
        </StyledTabItemContainer>
      )),
    [
      routes,
      select,
      disabledColor,
      bottomBorder,
      disabledClick,
      renderIcon,
      renderTitle,
      setSelect,
      onClickItem,
    ],
  )

  const handleRenderItem = useMemo(() => (select ? renderItem(select) : null), [
    select,
    renderItem,
  ])

  useDidUpdate(() => setSelect(selectedRoute), [selectedRoute, setSelect])

  return (
    <StyledContainer style={containerStyle}>
      {showTab && (
        <StyledTabContainer>{handleRenderTabItems}</StyledTabContainer>
      )}
      {handleRenderItem}
    </StyledContainer>
  )
}
