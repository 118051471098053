import {lazy} from 'react'

const TreeManageAdminScreens = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostProfile' */ './TreeHostManageAdminScreens'
    ),
)

const TreeHostPromoteAdminScreens = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostProfile' */ './TreeHostPromoteAdminScreens'
    ),
)

const TreeHostSuccessPromoteAdminScreens = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostProfile' */ './TreeHostSuccessPromoteAdminScreens'
    ),
)

export {
  TreeManageAdminScreens,
  TreeHostPromoteAdminScreens,
  TreeHostSuccessPromoteAdminScreens,
}
