import {AUTH_USER_MIN_AGE_TEEN, AUTH_USER_MIN_AGE, IMAGE_ASSET} from 'consts'
import {REDUX_STORE, reduxUpdateDispatcher} from 'lib/redux'
import {getAge} from '../dates'

export function getDefaultAvatar() {
  return IMAGE_ASSET('giftshop', 'users/default-portrait.png')
}

export function getChildrenStatus() {
  const {date_of_birth} = REDUX_STORE.getState().user || {}
  const {status} = REDUX_STORE.getState().parentalConsentState

  if (!date_of_birth) return

  const age = getAge(date_of_birth)
  if (age >= AUTH_USER_MIN_AGE) {
    if (status) {
      reduxUpdateDispatcher('parentalConsentState', {status: undefined})
    }
    return
  }

  const newStatus = age < AUTH_USER_MIN_AGE_TEEN ? 'UNDERAGE' : 'TEEN'
  if (status !== newStatus) {
    reduxUpdateDispatcher('parentalConsentState', {status: newStatus})
  }
}
