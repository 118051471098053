import {Avatar, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import React, {useCallback} from 'react'
import styled from 'styled-components'
import {TreeHostAdminData} from 'types'

export interface TreeHostTemplateIconProps {
  data: TreeHostAdminData
}

const StyledItemContainer = styled.div`
  display: flex;
  margin: ${convertUnit(4)} ${convertUnit(20)};
  flex-direction: row;
`

const StyledColumnContainer = styled.div``

const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1;
  margin-right: ${convertUnit(15)};
`

export default function TreeHostTemplateIcon({
  data,
}: TreeHostTemplateIconProps) {
  const shortName = useCallback((str = '') => {
    if (str.length > 7) return `${str.slice(0, 7)}...`
    return str
  }, [])

  return (
    <StyledItemContainer>
      <StyledColumnContainer>
        <StyledAvatar
          containerStyle={{marginRight: 15}}
          src={data.photo}
          alt="Avatar"
          size={40}
        />
        <Paragraph fontWeight="medium">{shortName(data.username)}</Paragraph>
      </StyledColumnContainer>
    </StyledItemContainer>
  )
}
