import React from 'react'
import styled from 'styled-components'
import {getTextShadow} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostButtonsItemProps} from './ForkygramTemplatePostButtonsItemProps'

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 3;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

export default function ForkygramTemplatePostButtonsItem({
  icon,
  label,
  isOverlay,
  isSendToFace,
  onClickIcon,
  onClickLabel,
}: ForkygramTemplatePostButtonsItemProps) {
  return (
    <StyledIconContainer>
      <Icon
        type={icon}
        size={convertUnit(32)}
        color="white_1"
        onClick={onClickIcon}
        hasShadow
        hasOpacity
        style={isSendToFace ? {marginRight: convertUnit(6)} : undefined}
      />
      {label && (
        <StyledParagraph
          fontSize={isOverlay ? 'xxs' : 's'}
          color="white_1"
          onClick={onClickLabel}>
          {label}
        </StyledParagraph>
      )}
    </StyledIconContainer>
  )
}
