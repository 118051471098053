import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IconVerifiedBadge, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramCommentMentionItemProps} from './ForkygramCommentInputProps'

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
  flex: none;
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  cursor: pointer;
  width: ${convertUnit(36)};
  height: ${convertUnit(36)};
  flex: none;
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
  box-sizing: border-box;
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  box-sizing: border-box;
  cursor: pointer;
`
const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function ForkygramCommentMentionItem({
  data,
  onClickItem,
}: ForkygramCommentMentionItemProps) {
  const {creator_status, username, photo} = data

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  return (
    <StyledContainer onClick={onClickItem}>
      <StyledContentContainer>
        <StyledAvatar src={photo} alt="avatar" />
        <StyledParagraph className="pressable" fontSize="m" fontWeight="medium">
          {username}
        </StyledParagraph>
        {handleRenderBadge}
      </StyledContentContainer>
    </StyledContainer>
  )
}
