import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {BadgeProps} from './BadgeProps'
import {Paragraph} from '../Paragraph'
import {Icon} from '../Icon'

const StyledBadge = styled.div<{backgroundColor: string, isMessageBadge: boolean}>`
  ${({isMessageBadge}) => ({
    borderRadius: isMessageBadge ? convertUnit(12) : convertUnit(8),
    paddingLeft: isMessageBadge ? convertUnit(8) : convertUnit(5),
    paddingRight: isMessageBadge ? convertUnit(8) : convertUnit(5),
    paddingTop: isMessageBadge ? convertUnit(2) : convertUnit(5),
    paddingBottom: isMessageBadge ? convertUnit(2) : convertUnit(5),
  })};
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: ${convertUnit(10)};
`

const StyledLeftIcon = styled(Icon)`
  margin-right: ${convertUnit(8)};
`

export default function Badge({
  text,
  color,
  backgroundColor,
  leftIcon,
  iconSize,
  isMessageBadge = false,
  ...props
}: BadgeProps) {
  return (
    <StyledBadge backgroundColor={backgroundColor} isMessageBadge={isMessageBadge} {...props}>
      {leftIcon && (
        <StyledLeftIcon type={leftIcon} color={color} size={iconSize} />
      )}
      <Paragraph fontSize="xxs" color={color}>
        {text}
      </Paragraph>
    </StyledBadge>
  )
}
