import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GiftShopTransactionHistoryResponseBody} from 'types'
import {requestData, requestDataPayload} from 'services'
import {useTranslation} from 'i18n'
import {TransactionRootEmpty} from 'pages'
import {
  IMAGE_ASSET,
  LIST_LOAD_LIMIT,
  SERVICE_CANCELLATION_GET_TRANSACTION_HISTORY,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {ActivityIndicator, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateTransactionHistoryContentItem} from '../ContentItem'
import {TemplateTransactionHistoryContentProps} from './TemplateTransactionHistoryContentProps'

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

export default function TemplateTransactionHistoryContent({
  stateSearchQuery,
  roletype,
  lastUpdate,
  isSearch = false,
}: TemplateTransactionHistoryContentProps) {
  const {translate} = useTranslation()
  const stateData = useState<
    ReadonlyArray<GiftShopTransactionHistoryResponseBody>
  >([])
  const setData = stateData[1]
  const searchQuery = stateSearchQuery[0]
  const emptyListImage = IMAGE_ASSET('giftshop', 'empty-list.png')
  const [lastUpdateLocal, setLastUpdateLocal] = useState<number>(
    Math.floor(new Date().getTime() / 1000),
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopTransactionHistoryResponseBody) => item.invoice_id,
    [],
  )

  const handleLoadUserData = useCallback(
    async (page: number, limit: number) => {
      if (searchQuery.length < 3 && isSearch) return []
      const response = await requestData('giftshop_get_transaction_history', {
        useDefaultMessage: true,
        cancelId: SERVICE_CANCELLATION_GET_TRANSACTION_HISTORY,
        actionType: 'fetch',
        params: {
          page,
          limit,
          q: searchQuery,
        },
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [isSearch, searchQuery],
  )

  const handleLoadHostData = useCallback(
    async (page: number, limit: number) => {
      if (searchQuery.length < 3 && isSearch) return []
      const response = await requestDataPayload(
        'tree_get_transaction_history',
        {
          useDefaultMessage: true,
          actionType: 'fetch',
          params: {
            page,
            limit,
            is_host: 1,
            q: searchQuery,
          },
        },
      )
      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [isSearch, searchQuery],
  )

  const handleLoadData = useCallback(async () => {
    if (lastUpdate && lastUpdate > lastUpdateLocal) {
      setLastUpdateLocal(lastUpdate)

      const updatedData =
        roletype === 'user'
          ? await handleLoadUserData(0, LIST_LOAD_LIMIT)
          : await handleLoadHostData(0, LIST_LOAD_LIMIT)

      if (Array.isArray(updatedData)) setData(updatedData)
    }
  }, [
    handleLoadHostData,
    handleLoadUserData,
    lastUpdate,
    lastUpdateLocal,
    roletype,
    setData,
  ])

  const handleRenderItem = useCallback(
    (item: GiftShopTransactionHistoryResponseBody) => (
      <TemplateTransactionHistoryContentItem data={item} roletype={roletype} />
    ),
    [roletype],
  )

  const handleRenderEmpty = useMemo(
    () =>
      searchQuery.length < 3 ? (
        <StyledLoadingContainer>
          <ActivityIndicator iconColor="gray_3" iconSize={50} />
          <Paragraph color="gray_3">
            {translate('giftShop:searchInputMinThreeChar')}
          </Paragraph>
        </StyledLoadingContainer>
      ) : (
        <TransactionRootEmpty
          image={emptyListImage}
          imageAlt="NoTransaction"
          buttonRoute="giftshop_robopet"
          message={translate(
            searchQuery.length > 0
              ? 'giftShop:transactionHistoryNotFoundTitle'
              : 'giftShop:noTransaction',
          )}
          subMessage={translate(
            searchQuery.length > 0
              ? 'giftShop:transactionHistoryNotFoundDescription'
              : 'giftShop:alltransaction',
          )}
        />
      ),
    [emptyListImage, searchQuery.length, translate],
  )

  useEffect(() => {
    handleLoadData()
  }, [lastUpdate, handleLoadData])

  return (
    <ListLazy
      cache={
        roletype === 'host' ? 'transaction_history_host' : 'transaction_history'
      }
      stateData={stateData}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderItem}
      loadData={roletype === 'user' ? handleLoadUserData : handleLoadHostData}
      search={searchQuery}
      backgroundColor="white_2"
      listEmptyElement={searchQuery.length ? handleRenderEmpty : <></>}
    />
  )
}
