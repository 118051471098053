import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationStorageAdditionrProps {
  payload: FirebaseMessageRoomItemPayload['notification_storage_addition']
}

export default function GiftShopMessageRoomContentItemNotificationStorageAddition({
  payload: {collector_nickname, username},
}: GiftShopMessageRoomContentItemNotificationStorageAdditionrProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationStorageAddition', {
        username,
        collector_nickname,
      })}
    </Paragraph>
  )
}
