import {Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import {AuthRegisterTermOfCondition} from 'pages/auth'
import React, {useCallback, useMemo, useState} from 'react'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {useDidMount, useHistory} from 'utils'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemReminderAbusiveContentProps {
  payload: FirebaseMessageRoomItemPayload['reminder_abusive_content']
}

export default function GiftShopMessageRoomContentItemReminderAbusiveContent({
  payload: {contents},
}: GiftShopMessageRoomContentItemReminderAbusiveContentProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const toggleToS = useState(false)
  const setToggleToS = toggleToS[1]
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleToggleToS = useCallback(
    () => setToggleToS((previous) => !previous),
    [setToggleToS],
  )

  const handleRenderMessage = useMemo(
    () => (
      <Paragraph fontSize="m">
        {translate('messages:reminderAbusiveContent', {
          username,
        })}
      </Paragraph>
    ),
    [username, translate],
  )

  const handleRenderExtra = useMemo(
    () => (
      <Paragraph fontSize="m" onClick={handleToggleToS}>
        {translate('messages:reminderAbusiveContentExtra')}
      </Paragraph>
    ),
    [translate, handleToggleToS],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push(
            'giftshop_profile_creation_detail',
            {
              data,
              selectedItemId: item.content_id,
            },
            item.creator_name,
          )
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderToS = useMemo(
    () => (
      <AuthRegisterTermOfCondition
        showModalState={toggleToS}
        handleAgree={handleToggleToS}
      />
    ),
    [toggleToS, handleToggleToS],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderToS}
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderExtra}
    </>
  )
}
