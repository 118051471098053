import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {requestData} from 'services'
import {TreeGetHostInvitationResponse, TreeHostInvitationStatus} from 'types'
import {useHistory} from 'utils'
import {ListLazy, ModalLoading} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeHostNotificationContentItem} from '../ContentItem'
import {TreeHostNotificationEmpty} from '../Empty'

const StyledContainer = styled.div`
  max-width: ${convertUnit(560)};
  max-height: ${convertUnit(570)};
  width: 100%;
  height: 100%;
  padding: ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_1};
  display: flex;
  border-radius: ${convertUnit(16)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    max-width: 100%;
    max-height: 100%;
  }
`

export default function TreeHostNotificationContent() {
  const stateData = useState<ReadonlyArray<TreeGetHostInvitationResponse>>([])
  const [data, setData] = stateData
  const {update} = useDispatch()
  const history = useHistory()
  const {invitationCount} = useSelector('hostState')
  const {username} = useSelector('user') || {}
  const [loading, setLoading] = useState(false)

  const handleLoadData = useCallback(async () => {
    const response = await requestData('tree_get_host_invitation', {
      actionType: 'fetch',
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && update('hostState', {invitationCount: result.length})
      },
    })
    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [update])

  const handleInvitationSuccess = useCallback(
    (
      item: TreeGetHostInvitationResponse,
      hostStatus: TreeHostInvitationStatus,
    ) => {
      setData((prev) =>
        prev.map((v) =>
          v.host_id === item.host_id &&
          v.account_id === item.account_id &&
          v.creator_id === item.creator_id &&
          item.role === v.role
            ? {...v, status: hostStatus}
            : {...v},
        ),
      )

      if (hostStatus === 'accepted') {
        update('hostRegistrationState', {
          status: 'verified',
          tagName: item.host_tag_name,
        })
        update('hostState', {invitationCount: undefined})
        history.push('tree_host_profile', {})
      } else if (hostStatus === 'rejected') {
        invitationCount &&
          update('hostState', {invitationCount: invitationCount - 1})
        if (data.map((v) => v.status === 'unconfirmed').length === 1)
          history.push('giftshop_profile', {self: true}, username)
      }
    },
    [data, history, invitationCount, setData, update, username],
  )

  const handleInvitationAdmin = useCallback(
    (
      item: TreeGetHostInvitationResponse,
      hostStatus: TreeHostInvitationStatus,
    ) => {
      setLoading(true)
      const {account_id, host_id} = item
      requestData('tree_patch_host_admin', {
        data: {
          account_id,
          host_id,
          status: hostStatus,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            handleInvitationSuccess(item, hostStatus)
          }
        },
      })
    },
    [handleInvitationSuccess],
  )

  const handleInvitationCreator = useCallback(
    (
      item: TreeGetHostInvitationResponse,
      hostStatus: TreeHostInvitationStatus,
    ) => {
      setLoading(true)
      const {creator_id, host_id} = item
      requestData('tree_patch_host_creator', {
        data: {
          creator_id,
          host_id,
          status: hostStatus,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            handleInvitationSuccess(item, hostStatus)
          }
        },
      })
    },
    [handleInvitationSuccess],
  )

  const handleRenderItem = useCallback(
    (item: TreeGetHostInvitationResponse) => (
      <TreeHostNotificationContentItem
        data={item}
        onAccept={(dt) =>
          item.role === 'admin'
            ? handleInvitationAdmin(dt, 'accepted')
            : handleInvitationCreator(dt, 'accepted')
        }
        onReject={(dt) =>
          item.role === 'admin'
            ? handleInvitationAdmin(dt, 'rejected')
            : handleInvitationCreator(dt, 'rejected')
        }
      />
    ),
    [handleInvitationAdmin, handleInvitationCreator],
  )

  const handleRenderListLazy = useMemo(
    () => (
      <ListLazy
        stateData={stateData}
        loadData={handleLoadData}
        keyExtractor={(item) => item.host_tag_name + item.role + item.status}
        renderItem={handleRenderItem}
        listEmptyElement={<TreeHostNotificationEmpty />}
      />
    ),
    [handleLoadData, handleRenderItem, stateData],
  )

  const handleRenderLoadingModal = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  return (
    <StyledContainer>
      {handleRenderLoadingModal}
      {handleRenderListLazy}
    </StyledContainer>
  )
}
