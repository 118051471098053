import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GiftShopCreationDateGroupFolderData, LayoutView} from 'types'
import {useWindowMode} from 'windows'
import {Image, Paragraph, ListLazy, View} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileCreationGroupFolderProps} from './TemplateProfileCreationGroupFolderProps'

const StyledContainer = styled(View)`
  display: flex;
  height: 100%;
  padding-top: ${convertUnit(20)};
  padding-left: ${convertUnit(20)};
`

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-right: ${convertUnit(20)};
  margin-bottom: ${convertUnit(24)};
  cursor: pointer;
`

const StyledItem = styled.div`
  width: 100%;
  aspect-ratio: 1;
  margin-bottom: ${convertUnit(8)};
`

const StyledCounter = styled.div`
  background-color: ${({theme}) => theme.white_3};
  width: ${convertUnit(22)};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${convertUnit(4)};
  position: absolute;
  z-index: 1;
  top: ${convertUnit(8)};
  left: ${convertUnit(8)};
`

const StyledImg = styled(Image)`
  object-fit: cover;
  width: 100%;
  border-radius: ${convertUnit(8)};
  aspect-ratio: 1;
  align-self: center;
`

export default function TemplateProfileCreationGroupFolder<
  ItemT extends GiftShopCreationDateGroupFolderData,
  S
>({
  stateData,
  onClickItem,
  ...props
}: TemplateProfileCreationGroupFolderProps<ItemT, S>) {
  const mode = useWindowMode()
  const [layout, setLayout] = useState<LayoutView>()

  const handleRenderItem = useCallback(
    (item: ItemT) => (
      <StyledItemContainer onClick={() => onClickItem && onClickItem(item)}>
        <StyledCounter>
          <Paragraph fontSize="xs" fontWeight="medium">
            {item.count}
          </Paragraph>
        </StyledCounter>
        <StyledItem>
          <StyledImg src={item.thumbnail} alt={item.thumbnail} />
        </StyledItem>
        <Paragraph
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="medium">
          {item.folder_name}
        </Paragraph>
      </StyledItemContainer>
    ),
    [mode, onClickItem],
  )

  const handleRenderList = useMemo(
    () =>
      layout && (
        <ListLazy
          {...props}
          stateData={stateData}
          keyExtractor={(item) => item.folder_id}
          renderItem={handleRenderItem}
          numColumns={Math.ceil(layout.clientWidth / 200)}
        />
      ),
    [layout, props, stateData, handleRenderItem],
  )

  return (
    <StyledContainer onLayout={setLayout}>{handleRenderList}</StyledContainer>
  )
}
