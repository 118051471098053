import {lazy} from 'react'

const GiftShopRoboPetScreen = lazy(
  () => import(/* webpackChunkName: 'RoboPet' */ './GiftShopRoboPetScreen'),
)

const GiftShopRobopetActivationSelfieScreen = lazy(
  () => import('./GiftShopRobopetActivationSelfieScreen'),
)
const GiftShopRobopetChildReapplyScreen = lazy(
  () => import('./GiftShopRobopetChildReapplyScreen'),
)
const GiftShopRobopetChildWefieScreen = lazy(
  () => import('./GiftShopRobopetChildWefieScreen'),
)
const GiftShopRobopetRetakeScreen = lazy(
  () => import('./GiftShopRobopetRetakeScreen'),
)
const GiftShopRobopetRetakeSelfieScreen = lazy(
  () => import('./GiftShopRobopetRetakeSelfieScreen'),
)
export * from './GiftShopRobopetActivationSelfieScreenParam'
export * from './GiftShopRobopetChildReapplyScreenParam'
export * from './GiftShopRobopetScreenParam'

export {
  GiftShopRoboPetScreen,
  GiftShopRobopetActivationSelfieScreen,
  GiftShopRobopetChildReapplyScreen,
  GiftShopRobopetChildWefieScreen,
  GiftShopRobopetRetakeScreen,
  GiftShopRobopetRetakeSelfieScreen,
}
