import React, {useCallback, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {FlatlistItemProps} from './FlatlistItemProps'

interface StyledContainerProps {
  top: number
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({top}) => ({top})}
  position: absolute;
  width: 100%;
`

export default function FlatlistItem({
  offset = 0,
  children,
  onLayout,
  ...props
}: FlatlistItemProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  const handleLayoutEffect = useCallback(() => {
    if (ref.current) {
      const {height} = ref.current.getBoundingClientRect()

      onLayout(height)
    }
  }, [onLayout])

  useLayoutEffect(handleLayoutEffect)

  return (
    <StyledContainer {...props} ref={ref} top={offset}>
      {children}
    </StyledContainer>
  )
}
