import {ObjectToggleValues} from 'consts'
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {ObjectMapRef, ObjectState} from 'types'

export function useDefaultState<S>(value: S, state?: ObjectState<S>) {
  const defaultState = useState(value)

  return state || defaultState
}

export function useDidMount(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [])
}

export function useDidUpdate(effect: EffectCallback, deps?: DependencyList) {
  const initialize = useRef(false)

  useEffect(() => {
    if (initialize.current) {
      effect()
    } else {
      initialize.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export function useMapRef<T extends object>(data: T) {
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refData = useRef(data)

  const handleUpdateData = useCallback(() => setLastUpdate(new Date()), [])

  const handleSetData = useCallback((map: Partial<T>) => {
    for (const [key, value] of Object.entries(map)) {
      // @ts-ignore
      refData.current[key] = value
    }
  }, [])

  return useMemo<ObjectMapRef<T>>(
    () => ({
      updateMap: handleUpdateData,
      setMapValue: handleSetData,
      map: refData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastUpdate, handleUpdateData, handleSetData],
  )
}

export function useToggle(initialState: boolean = false) {
  const [value, setValue] = useState(initialState)

  const handleToggle = useCallback(() => setValue((previous) => !previous), [])

  return useMemo<ObjectToggleValues>(
    () => ({value, setValue, toggle: handleToggle}),
    [handleToggle, value],
  )
}
