import axios, {AxiosResponse} from 'axios'
import {
  ServiceAPIRejectResponse,
  ServiceAPIResponse,
  ServiceConfig,
  ServiceError,
  ServiceHandlerValue,
} from 'types'
import {
  SERVICE_CANCELED_MESSAGE,
  SERVICE_CANCELLED_RESPONSE,
  SERVICE_IGNORE_MESSAGE,
  SERVICE_TIMEOUT,
  SERVICE_TIMEOUT_MESSAGE,
} from 'consts'
import {getMultiformData, handleServiceResponseMessage} from 'utils'
import {SERVICE_HANDLER} from './ServiceHandler'
import {SERVICE_MAP} from './ServiceMap'
import {ServiceParam} from './ServiceParam'
import {
  handleRequestOnFulfilled,
  handleServiceResponseInterceptor,
} from './ServiceInterceptor'

function createAxiosAPIInstance() {
  const axiosInstance = axios.create({
    timeout: SERVICE_TIMEOUT,
    timeoutErrorMessage: SERVICE_TIMEOUT_MESSAGE,
  })
  axiosInstance.interceptors.request.use(handleRequestOnFulfilled)

  return axiosInstance
}

function formatConfig<T extends keyof ServiceParam>(
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  if (config) {
    const {
      data,
      dataType,
      headers,
      headerContentType = 'application/json',
    } = config

    config.data = data
      ? dataType === 'form-data' && typeof data === 'object'
        ? getMultiformData(data as any)
        : data
      : undefined
    config.headers = {
      ...headers,
      'Content-Type': headerContentType,
    }
  }

  return config
}

function handleRequestFulfilled<T extends keyof ServiceParam>(
  _key: T,
  value: AxiosResponse<ServiceParam[T]['response']>,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const onRequestFulfilled = config?.onRequestFulfilled

  value = handleServiceResponseInterceptor(value) as AxiosResponse<
    ServiceParam[T]['response']
  >

  onRequestFulfilled && onRequestFulfilled(value)

  return value
}

function handleRequestRejected<T extends keyof ServiceParam>(
  key: T,
  reason: ServiceAPIRejectResponse<
    ServiceParam[T]['response']
  > = 'unknown-error',
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
): AxiosResponse<ServiceParam[T]['response']> | ServiceError {
  if (typeof reason === 'object') {
    const {response, message} = reason

    if (response) {
      return handleRequestFulfilled(key, response, config)
    }

    switch (message) {
      case SERVICE_TIMEOUT_MESSAGE:
        reason = 'timeout'
        break
      case SERVICE_CANCELED_MESSAGE:
        reason = SERVICE_CANCELLED_RESPONSE
        break
      default:
        reason = 'unknown-error'
    }
  }

  return reason
}

const ABORT_CONTROLLER_MAP: Record<string, AbortController> = {}

function handleRequestResponse<T extends keyof ServiceParam>(
  key: T,
  response: ServiceAPIResponse<AxiosResponse<ServiceParam[T]['response']>>,
  config: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const cancelKey = `${key}.${config?.cancelId}`
  const {
    actionType,
    useDefaultMessage,
    onRequestReceived,
    onRequestFailed,
    onRequestSuccess,
  } = config
  const handlerPreset = SERVICE_HANDLER[key] as ServiceHandlerValue | undefined

  const hasStatusMessage = (status: number) =>
    status >= 300 && !SERVICE_IGNORE_MESSAGE.includes(status)

  onRequestReceived && onRequestReceived()
  useDefaultMessage &&
    (response as string) !== SERVICE_CANCELLED_RESPONSE &&
    (typeof response === 'string' || hasStatusMessage(response.status)) &&
    handleServiceResponseMessage(response, actionType)

  if (typeof response === 'string') {
    onRequestFailed && onRequestFailed(response)
  } else {
    onRequestSuccess && onRequestSuccess(response)
    handlerPreset && handlerPreset({response})
  }

  if (
    config &&
    config.cancelId &&
    (response as string) !== SERVICE_CANCELLED_RESPONSE
  ) {
    delete ABORT_CONTROLLER_MAP[cancelKey]
  }

  return response
}

const instance = createAxiosAPIInstance()

export async function requestData<T extends keyof ServiceParam>(
  key: T,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
  link?: string,
): Promise<AxiosResponse<ServiceParam[T]['response']> | ServiceError> {
  const cancelKey = `${key}.${config?.cancelId}`

  if (config && config.cancelId) {
    const existing = ABORT_CONTROLLER_MAP[cancelKey]
    if (existing) {
      existing.abort()
    }
    ABORT_CONTROLLER_MAP[cancelKey] = new AbortController()
  }

  const serviceMap =
    link !== undefined ? {...SERVICE_MAP[key], url: link} : SERVICE_MAP[key]
  const response = navigator.onLine
    ? await instance
        .request<ServiceParam[T]['response']>({
          ...serviceMap,
          ...formatConfig(config),
          signal: ABORT_CONTROLLER_MAP[cancelKey]
            ? ABORT_CONTROLLER_MAP[cancelKey].signal
            : undefined,
        })
        .then(
          (value) => handleRequestFulfilled(key, value, config),
          (reason) => handleRequestRejected(key, reason, config),
        )
    : handleRequestRejected(key, 'no-connection')

  return handleRequestResponse(key, response, config || {})
}

export async function requestDataPayload<T extends keyof ServiceParam>(
  key: T,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const response = await requestData(key, config)

  return typeof response !== 'string' ? response : null
}
