import React, {useCallback, useRef, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_UPLOAD_TYPE, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {LayoutDimension} from 'types'
import {getContentURL, handleGiftshopReadFile, showSnackbar} from 'utils'
import {InputPicker} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileResendBatchItem} from '../ResendBatchItem'
import {GiftShopProfileResendBatchContentProps} from './GiftShopProfileResendBatchContentProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    gap: ${convertUnit(4)};
    padding: 0;
    margin-bottom: ${convertUnit(4)};
  }
`

const StyledItem = styled.div`
  width: 50%;
  flex: 1;
`

export default function GiftShopProfileResendBatchContent({
  data,
  stateResendData,
}: GiftShopProfileResendBatchContentProps) {
  const {translate} = useTranslation()
  const {url, title, original_at, content_id, type, resolution, size} = data
  const resendRef = useRef<HTMLInputElement>(null)
  const [src, setSrc] = useState<
    | {
        url: string
        name: string
        date: string
        size: number
        resolution: LayoutDimension
      }
    | undefined
  >(undefined)
  const [resendData, setResendData] = stateResendData
  const [loadingImg, setLoadingImg] = useState(false)

  const handleReadFile = useCallback(
    async (file: File) => {
      setLoadingImg(true)
      if (!GIFT_SHOP_UPLOAD_TYPE.includes(file.type)) {
        showSnackbar(translate('giftShop:invalidFormatData'))
        setLoadingImg(false)
        return
      }
      const image = await handleGiftshopReadFile(file, undefined, false)

      if (image !== null) {
        if (resendData.length === 0) {
          setResendData((prev) => [...prev, {id: content_id, image}])
        } else {
          const idx = resendData.findIndex((item) => item.id === content_id)
          if (idx !== -1) {
            resendData[idx].image = image
          } else {
            setResendData((prev) => [...prev, {id: content_id, image}])
          }
        }
        setSrc({
          date:
            image.exif?.CreateDate?.toISOString() ||
            new Date(image.file.lastModified).toISOString() ||
            new Date().toISOString(),
          name: image.file.name,
          resolution: {
            height: image.height,
            width: image.width,
          },
          size: image.size,
          url: image.src,
        })
      }
      setLoadingImg(false)
    },
    [content_id, resendData, setResendData, translate],
  )

  const handleLoadFiles = useCallback(
    (files: File[]) => {
      Array.from(files).forEach((file) => {
        handleReadFile(file)
      })
    },
    [handleReadFile],
  )

  return (
    <StyledContainer>
      <StyledItem>
        {typeof original_at === 'string' && (
          <GiftShopProfileResendBatchItem
            date={original_at}
            src={getContentURL({url, type})}
            title={title}
            resolution={resolution}
            size={size}
          />
        )}
      </StyledItem>

      <StyledItem>
        <GiftShopProfileResendBatchItem
          showButton
          src={src?.url}
          date={src?.date}
          title={src?.name}
          size={src?.size}
          resolution={src?.resolution}
          loadingImg={loadingImg}
          onChangePhoto={() => resendRef.current?.click()}
        />
      </StyledItem>
      <InputPicker inputRef={resendRef} onLoadFiles={handleLoadFiles} />
    </StyledContainer>
  )
}
