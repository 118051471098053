import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Modal, Paragraph, RadioItem} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateCreationSortModalProps} from './TemplateCreationSortModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  z-index: 15;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(20)};
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${convertUnit(16)};
`

const StyledRadioItem = styled(RadioItem)`
  margin-right: ${convertUnit(20)};
`

export default function TemplateCreationSortModal({
  visible,
  onClickItem,
  toggleModal,
}: TemplateCreationSortModalProps) {
  const {translate} = useTranslation()
  const {resendSortType} = useSelector('giftshopState')
  const handleRenderCheckButton = useMemo(
    () => (
      <>
        <StyledRowContainer>
          <StyledRadioItem
            size={20}
            isSelected={resendSortType === 'title'}
            onClick={() => {
              onClickItem('title')
              toggleModal()
            }}
          />
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:creationSortBy', {context: 'title'})}
          </Paragraph>
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledRadioItem
            size={20}
            isSelected={resendSortType === 'purchased_at'}
            onClick={() => {
              onClickItem('purchased_at')
              toggleModal()
            }}
          />
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:creationSortBy', {context: 'purchasedAt'})}
          </Paragraph>
        </StyledRowContainer>
      </>
    ),
    [resendSortType],
  )

  return (
    <StyledModal visible={visible} onBackdropPress={toggleModal}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('giftShop:creationSortByTitle')}
      </StyledTitle>
      {handleRenderCheckButton}
    </StyledModal>
  )
}
