import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'
import {IconFirstBuyProps} from './IconFirstBuyProps'

const StyledFirstBuy = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.primary_5,
  })};
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(4)};
`

const StyledFirstBuyText = styled(Paragraph)`
  font-size: ${convertUnit(10)};
`

export default function IconFirstBuy({text}: IconFirstBuyProps) {
  return (
    <StyledFirstBuy>
      <StyledFirstBuyText color="white_1">{text}</StyledFirstBuyText>
    </StyledFirstBuy>
  )
}
