import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE,
  FORKYGRAM_UPLOAD_MAX_RESOLUTION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE,
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION,
  FORKYGRAM_UPLOAD_TYPE,
  ICON_SIZE,
  IMAGE_ASSET,
  WINDOW_MODE_TABLET_WIDTH,
  GIFT_SHOP_HEADER_HEIGHT,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  AuthCredentialType,
  GiftshopProfileInfo,
  WindowModeType,
  parseShape,
} from 'types'
import {
  useHistory,
  showSnackbar,
  getAndSupplyFileMime,
  getTextShadow,
  showModalReprocessKyc,
  showModalLimitedAccess,
  getFontFamily,
  getBorder,
} from 'utils'
import {useTheme} from 'themes'
import {useWindowMode} from 'windows'
import {
  GiftShopTemplateModalCredential,
  GiftShopTemplateModalPassword,
  TemplateAuthAccessModal,
} from 'pages'
import {
  Button,
  ButtonOutline,
  ConfirmationModal,
  Icon,
  IconVerifiedBadge,
  Image,
  Paragraph,
  ParsedText,
  Rating,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {convertImage} from 'utils/lib/heic2any/heic2anyHelper'
import {TemplateProfileInfoProps} from './TemplateProfileInfoProps'
import {TemplateProfileUploadModal} from '../UploadModal'
import {TemplateSorryModalContent} from '../../../sorry-modal'
import {TemplateFollowModal} from '../FollowModal'
import {TemplateProfilePhotoModal} from '../ProfilePhotoModal'
import {ProfileDetailSocialLinkItem} from '../ProfileDetailItem'
import {TemplateFullStorageModal} from '../FullStorageModal'
import {TemplateProfileSellGuidelineModal} from '../SellGuidelineModal'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledInteractiveContainerProps {
  isDisabled?: boolean
}

interface StyledAvatarContainerProps {
  isLive: boolean
}

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledInfoContainer = styled.div`
  padding-top: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.gray_8};
  position: relative;
`

const StyledBackgroundPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_8};
  max-height: ${convertUnit(258)};
  background-size: cover;
  width: 100%;
  position: fixed;
`

const StyledBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  position: absolute;
  background-size: cover;
  object-fit: cover;
  object-position: center;
`

const StyledBackground = styled(Image)`
  width: 100%;
  height: 100%;
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledFollowingContainer = styled(StyledRowContainer)`
  margin-top: ${convertUnit(4)};
  margin-bottom: ${convertUnit(8)};
  gap: ${convertUnit(20)};
  display: flex;
  flex: 1;
`

const StyledProfileContainer = styled.div<StyledContainerProps>`
  padding: 0 ${convertUnit(20)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  ${({mode}) => ({flexDirection: mode === 'website' ? 'row' : 'column'})}
`

const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  ${({theme, isLive}) => ({
    border: getBorder(2, 'solid', isLive ? theme.fotoyu_logo_1 : theme.white_1),
  })}
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${convertUnit(208)};
  aspect-ratio: 0.75;
  z-index: 2;
  border-radius: ${convertUnit(10)};
  cursor: pointer;
  background-color: ${({theme}) => theme.black};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(128)};
    border-radius: ${convertUnit(8)};
  }
`

const StyledAvatar = styled(Image)`
  height: ${convertUnit(208)};
  aspect-ratio: 0.75;
  object-fit: contain;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(128)};
    border-radius: ${convertUnit(8)};
  }
`

const StyledLiveContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(2)};
  padding: 0 ${convertUnit(2)};
  background-color: ${({theme}) => theme.fotoyu_logo_1};
  min-height: ${convertUnit(28)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    min-height: ${convertUnit(21)};
  }
`

const StyledUpperProfileInfoContainer = styled(StyledRowContainer)`
  flex: 1;
  gap: ${convertUnit(20)};
`

const StyledUsernameContainer = styled(StyledRowContainer)`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${convertUnit(4)};
  flex: 1;
  z-index: 0;
`

const StyledSocialLinkContainer = styled(
  StyledColumnContainer,
)<StyledContainerProps>`
  display: flex;
  justify-content: flex-end;
  gap: ${convertUnit(4)};
  position: relative;
  z-index: 1;
  width: 100%;
  ${({mode}) => ({maxWidth: mode === 'website' ? convertUnit(300) : `unset`})};
  word-break: break-all;
`

const StyledBioContainer = styled(StyledColumnContainer)`
  width: 100%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(12)};
    z-index: 1;
  }
`

const StyledButtonsContainer = styled(StyledRowContainer)`
  display: flex;
  gap: ${convertUnit(20)};
  justify-content: left;
  align-items: center;
  margin-top: ${convertUnit(12)};
  z-index: 1;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: 0;
    width: 100%;
  }
`

const StyledWebButtonsContainer = styled(StyledRowContainer)`
  display: flex;
  margin-top: ${convertUnit(12)};
  position: relative;
  align-items: center;
  z-index: 1;
`

const StyledEditProfileButton = styled(Button)`
  background-color: ${({theme}) => theme.white_3};
  display: flex;
  height: ${convertUnit(34)};
  width: ${convertUnit(160)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledInteractiveContainer = styled(
  StyledRowContainer,
)<StyledInteractiveContainerProps>`
  cursor: ${({isDisabled}) => (isDisabled ? 'default' : 'pointer')};
`

const StyledButton = styled(Button)`
  height: ${convertUnit(34)};
  width: ${convertUnit(160)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledButtonOutline = styled(ButtonOutline)`
  height: ${convertUnit(34)};
  width: ${convertUnit(160)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledTextWrap = styled(Paragraph)`
  word-break: break-all;
  white-space: pre-wrap;
  display: inline-block;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`
const StyledUsernameWrapper = styled(StyledTextWrap)`
  display: inline;
`
const StyledFollowingTextWrap = styled(Paragraph)`
  word-break: break-all;
  display: inline-block;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledFotoyuBadge = styled.img`
  width: ${convertUnit(ICON_SIZE)};
  height: ${convertUnit(ICON_SIZE)};
  margin-left: ${convertUnit(4)};
  margin-bottom: ${convertUnit(-6)};
`
const StyledIconVerifiedBadgeWrapper = styled.div`
  display: inline;
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
  margin-bottom: ${convertUnit(-4)};
`

const StyledUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
`

export default function TemplateProfileInfo({
  self,
  memberId,
  username,
  photo,
  background_photo,
  isUnderage,
  kycStatus,
  creatorStatus,
  stateSelectTab,
  isOfficial,
  isLive,
}: TemplateProfileInfoProps) {
  const theme = useTheme()
  const avatarDefault = IMAGE_ASSET('giftshop', 'users/default-portrait.png')
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const user = useSelector('user')
  const {status: childStatus} = useSelector('parentalConsentState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const history = useHistory()
  const {update} = useDispatch()
  const selectTab = stateSelectTab[0]
  const stateModalPassword = useState(
    !user?.has_password && user?.signup_method !== 'apple',
  )
  const credential = useMemo<AuthCredentialType | undefined>(
    () => (!user?.email ? 'email' : !user?.phone_number ? 'phone' : undefined),
    [user?.email, user?.phone_number],
  )
  const creatorHasIncompleteCredential = useMemo(
    () => self && creatorStatus === 'verified' && !!credential,
    [creatorStatus, credential, self],
  )
  const stateModalCredential = useState(
    !!user?.has_password && creatorHasIncompleteCredential,
  )

  const [modal, setModal] = useState<
    | 'upload'
    | 'link'
    | 'kyc'
    | 'follow'
    | 'following'
    | 'profile'
    | 'full'
    | 'guideline'
  >()
  const [profileInfo, setProfileInfo] = useState<GiftshopProfileInfo>({
    website: '',
    isBanned: false,
    isFollowed: false,
    isLive: false,
    biodata: '',
    social: [],
    backgroundPhoto: '',
    creatorStatus: 'unregistered',
  })
  const fotoyuBadge = IMAGE_ASSET('giftshop', 'fotoyu-badge.svg')
  const [follower, setFollower] = useState<number>(0)
  const [following, setFollowing] = useState<number>(0)
  const uploadRef = useRef<HTMLInputElement>(null)
  const linkRef = useRef('')
  const [loginModal, setLoginModal] = useState(false)
  const [postModal, setPostModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const {guidelineCount} = useSelector('giftshopState')

  const handleRenderUploadPostKYCModal = useMemo(
    () => (
      <TemplateSorryModalContent
        visible={postModal}
        upperDesc={translate('forkygram:uploadPostActivateRoboyuUpperDesc')}
        bottomDesc={translate('forkygram:uploadPostActivateRoboyuBottomDesc')}
        toggleModal={() => setPostModal((prev) => !prev)}
      />
    ),
    [postModal, translate],
  )

  const fetchUserData = useCallback(() => {
    if (self) {
      requestData('giftshop_get_gs_self_info', {
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({data: {result}, status}) => {
          setLoading((prev) => !prev)
          if (status === 200) {
            update('user', {
              has_password: result.has_password,
              social: result.social,
              background_photo: result.background_photo,
              photo: result.photo,
              email: result.email,
              phone_code: result.phone_code,
              phone_number: result.phone_number,
              username: result.username,
              nickname: result.nickname,
              biodata: result.biodata,
              gender: result.gender,
              website: result.website,
              date_of_birth: result.date_of_birth,
              is_verified: result.is_verified,
              signup_method: result.signup_method,
            })
          }
        },
      })
    } else if (memberId) {
      setLoading(true)
      requestData('forkygram_get_other_profile', {
        params: {id: memberId, q: username},
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestReceived: () => setLoading((prev) => !prev),
        onRequestSuccess: ({data: {result}}) => {
          if (result.is_banned) {
            showSnackbar(translate('forkygram:bannedSnackbar'))
          }
          setProfileInfo({
            website: result.website,
            backgroundPhoto: result.background_photo,
            isFollowed: result.is_followed,
            isBanned: result.is_banned,
            social: result.social,
            creatorStatus: result.creator_status,
            biodata: result.biodata,
            creatorRating: result.creator_rating,
            ratingCount: result.creator_rating_count,
            isLive: result.is_live,
          })
        },
      })
    }
  }, [self, memberId, username, update, translate])

  const fetchFollowData = useCallback(() => {
    const targetId = self ? user?.id : memberId

    if (
      !targetId ||
      (self && childStatus === 'UNDERAGE') ||
      (!self && isUnderage)
    ) {
      return
    }

    requestData('giftshop_get_follow_count', {
      params: {member_id: targetId},
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({data: {result}}) => {
        setFollower(result.follower_count)
        setFollowing(result.following_count)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childStatus, isUnderage, memberId, self])

  const isFollowModalDisabled = useMemo(
    () => (!self && isUnderage) || loading,
    [isUnderage, loading, self],
  )

  const handleUploadDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    setPostModal(true)
  }, [childStatus, history, kycStatus, similarStatus])

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, similarStatus])

  const handleFollow = useCallback(() => {
    setProfileInfo((x) => ({...x, isFollowed: !x.isFollowed}))
    setFollower(() => follower + 1)
    memberId &&
      requestData('giftshop_post_follow', {
        data: {member_id: memberId},
        onRequestFailed: () => {
          setProfileInfo((x) => ({...x, isFollowed: !x.isFollowed}))
          setFollower(() => follower - 1)
        },
      })
  }, [follower, memberId])

  const handleUnfollow = useCallback(() => {
    setProfileInfo((x) => ({...x, isFollowed: !x.isFollowed}))
    setFollower(() => follower - 1)
    memberId &&
      requestData('giftshop_delete_follow', {
        data: {member_id: memberId},
        onRequestFailed: () => {
          setProfileInfo((x) => ({...x, isFollowed: !x.isFollowed}))
          setFollower(() => follower + 1)
        },
      })
  }, [follower, memberId])

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleCheckStorage = useCallback(async () => {
    const response = await requestData('giftshop_get_storage')
    if (typeof response === 'string' || response.status !== 200) {
      return
    }
    const {limit_storage, used_storage, bonus_storage} = response.data.result

    const calculatePercentageBar =
      (used_storage / (limit_storage + bonus_storage)) * 100
    if (calculatePercentageBar >= 100) {
      setModal('full')
    } else setModal('upload')
  }, [])

  const handleRenderUploadButton = useMemo(
    () => (
      <StyledButton
        fontSize="s"
        fontWeight="bold"
        label={translate('global:upload')}
        onClick={() => {
          if (
            childStatus === 'UNDERAGE' ||
            !(kycStatus === 'verified' || kycStatus === 'verified_check')
          ) {
            handleUploadDisabled()
            return
          }
          handleCheckStorage()
        }}
      />
    ),
    [
      translate,
      kycStatus,
      childStatus,
      handleCheckStorage,
      handleUploadDisabled,
    ],
  )

  const handleNavigateChat = useCallback(() => {
    if (kycStatus === 'unregistered') {
      return history.push('giftshop_robopet_activation_selfie', {})
    }
    if (user?.access_token) {
      if (
        !(
          kycStatus === 'verified' ||
          kycStatus === 'verified_check' ||
          kycStatus === 'onhold'
        )
      ) {
        handleFeatureDisabled()
        return
      }
      history.push('giftshop_message_room', {
        participantId: memberId,
        roomType: 'chat',
        roomName: username,
        roomPhoto: photo,
      })
    } else setLoginModal(true)
  }, [
    handleFeatureDisabled,
    history,
    kycStatus,
    memberId,
    photo,
    user?.access_token,
    username,
  ])

  const handleClickFollow = useCallback(() => {
    if (user?.access_token) {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      handleFollow()
    } else setLoginModal(true)
  }, [handleFeatureDisabled, handleFollow, kycStatus, user?.access_token])

  const handleRenderSelfButton = useMemo(
    () => (
      <StyledButtonsContainer>
        {mode !== 'website' && selectTab === 'creation' ? (
          <></>
        ) : (
          <>
            <StyledEditProfileButton
              fontSize="s"
              fontWeight="bold"
              label={translate('giftShop:editProfile')}
              color="primary_5"
              leftIcon={
                <Icon
                  type="pencil"
                  color="primary_5"
                  size={convertUnit(12)}
                  marginRight={convertUnit(14)}
                />
              }
              onClick={() => history.push('giftshop_edit_profile', {})}
            />
            {handleRenderUploadButton}
          </>
        )}
      </StyledButtonsContainer>
    ),
    [handleRenderUploadButton, history, mode, selectTab, translate],
  )

  const handleRenderOtherProfileButton = useMemo(
    () => (
      <StyledButtonsContainer>
        <StyledEditProfileButton
          fontSize="s"
          fontWeight="bold"
          label={translate('global:chat')}
          color="primary_5"
          leftIcon={
            <Icon
              type="message"
              color={
                loading || (!loading && isUnderage) ? 'gray_3' : 'primary_5'
              }
              size={convertUnit(12)}
              marginRight={convertUnit(14)}
            />
          }
          disabled={loading || (!loading && isUnderage)}
          onClick={handleNavigateChat}
        />
        {profileInfo.isFollowed ? (
          <StyledButtonOutline
            color="white_1"
            borderColor="white_1"
            borderWidth={2}
            fontSize="s"
            fontWeight="bold"
            backgroundHoverColor="primary_4"
            label={translate('forkygram:following')}
            onClick={() => {
              if (
                !(kycStatus === 'verified' || kycStatus === 'verified_check')
              ) {
                handleFeatureDisabled()
                return
              }
              handleUnfollow()
            }}
          />
        ) : (
          <StyledButton
            fontSize="s"
            color="white_1"
            fontWeight="bold"
            label={translate('forkygram:follow')}
            disabled={loading || (!loading && isUnderage)}
            onClick={handleClickFollow}
          />
        )}
      </StyledButtonsContainer>
    ),
    [
      handleClickFollow,
      handleFeatureDisabled,
      handleNavigateChat,
      handleUnfollow,
      isUnderage,
      kycStatus,
      loading,
      profileInfo.isFollowed,
      translate,
    ],
  )

  const handleRenderButton = useMemo(
    () => (self ? handleRenderSelfButton : handleRenderOtherProfileButton),
    [handleRenderOtherProfileButton, handleRenderSelfButton, self],
  )

  const handleGoToLink = useCallback(() => {
    if (!linkRef.current.includes('https://'))
      window.open(`https://${linkRef.current}`, '_blank')
    else window.open(linkRef.current, '_blank')
  }, [])

  const handleRenderLinkModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('giftShop:externalLink')}
        text={translate('giftShop:externalLinkDesc')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handleGoToLink}
        toggleModal={() => setModal(undefined)}
      />
    ),
    [handleGoToLink, translate],
  )

  const handleCheckImage = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      let img = uploadRef.current.files[0]
      const {size, name} = img

      let type = getAndSupplyFileMime(FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE, img)

      const handlingSpecialImageType = FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE.includes(
        type,
      )
      const handlingSpecialVideoType = FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE.includes(
        type,
      )

      if (!FORKYGRAM_UPLOAD_TYPE.includes(type)) {
        setModal('upload')
        showSnackbar(
          img.type.startsWith('image')
            ? translate('forkygram:uploadInvalidImageFormat')
            : translate('forkygram:uploadInvalidVideoFormat'),
        )
        return
      }
      if (type.startsWith('image') && size > FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE) {
        setModal('upload')
        showSnackbar(translate('forkygram:uploadMaxImageSizeMessage'))
        return
      }

      if (type.startsWith('video') && size > FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE) {
        setModal('upload')
        showSnackbar(translate('forkygram:uploadMaxVideoSizeMessage'))
        return
      }

      const reader = new FileReader()
      reader.onload = async () => {
        const url = reader.result?.toString()

        if (!url) return

        if (
          url?.includes('image') ||
          (type.includes('image') && handlingSpecialImageType)
        ) {
          const image = document.createElement('img')

          if (handlingSpecialImageType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE

            const {file: newImage, blob} = await convertImage(
              url,
              FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
              name,
            )

            image.src = URL.createObjectURL(blob)
            img = newImage
          } else {
            image.src = url
          }

          image.onload = () => {
            const {width, height} = image

            if (width * height > FORKYGRAM_UPLOAD_MAX_RESOLUTION) {
              setModal('upload')
              showSnackbar(
                translate('forkygram:uploadMaxImageResolutionMessage'),
              )
              return
            }
            if (
              width < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION ||
              height < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION
            ) {
              setModal('upload')
              showSnackbar(
                translate('forkygram:uploadMinImageResolutionMessage'),
              )
              return
            }
            history.push('forkygram_upload', {file: img})
          }
        }
        if (
          url?.includes('video') ||
          (type.includes('video') && handlingSpecialVideoType)
        ) {
          const vid = document.createElement('video')

          if (handlingSpecialVideoType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE

            const finalName = name.replace(
              /\.(.)+/g,
              `.${FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE.split('/')[1]}`,
            )

            const newVideo = await fetch(url)
              .then((res) => res.blob())
              .then(
                (res) =>
                  new File([res], finalName, {
                    type: FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
                  }),
              )

            vid.src = URL.createObjectURL(newVideo)
            img = newVideo as File
          } else {
            vid.src = url
          }

          vid.onloadedmetadata = () => {
            const {videoWidth, duration} = vid

            if (duration > FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION) {
              setModal('upload')
              showSnackbar(translate('forkygram:uploadMaxVideoDurationMessage'))
              return
            }
            if (videoWidth > FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH) {
              setModal('upload')
              showSnackbar(translate('forkygram:uploadMaxVideoWidthMessage'))
              return
            }
            history.push('forkygram_upload', {file: img})
          }
        }
      }
      reader.readAsDataURL(img)
    }
  }, [history, translate])

  const handleRenderUploadKYCModal = useMemo(
    () => (
      <TemplateSorryModalContent
        visible
        upperDesc={translate('giftShop:roboyuUploadNotActivatedDesc')}
        bottomDesc={translate('giftShop:roboyuUploadNotActivatedInfo')}
        toggleModal={() => {
          setModal(undefined)
          kycStatus === 'unregistered' &&
            history.push('giftshop_robopet_activation_selfie', {})
        }}
      />
    ),
    [history, kycStatus, translate],
  )

  const parseText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<follow>.*?<\/follow>/,
        style: {
          color: theme.white_1,
          fontFamily: getFontFamily('bold'),
          display: mode !== 'website' ? 'grid' : 'inline',
        },
        renderText: (text) => text.replace(/<follow>|<\/follow>/g, ''),
      },
    ],
    [mode, theme.white_1],
  )

  const handleRenderFollowing = useMemo(
    () => (
      <StyledFollowingContainer>
        <StyledInteractiveContainer
          isDisabled={isFollowModalDisabled}
          onClick={() => {
            if (isFollowModalDisabled) return
            if (self && childStatus === 'UNDERAGE') {
              showModalLimitedAccess()
              return
            }
            fetchFollowData()
            setModal('follow')
          }}>
          <StyledFollowingTextWrap fontSize="m" color="gray_3" lineHeight={26}>
            <ParsedText parse={parseText}>
              {translate('forkygram:followerCount', {
                follow: !self && isUnderage ? '-' : follower,
              })}
            </ParsedText>
          </StyledFollowingTextWrap>
        </StyledInteractiveContainer>
        <StyledInteractiveContainer
          isDisabled={isFollowModalDisabled}
          onClick={() => {
            if (isFollowModalDisabled) return
            if (self && childStatus === 'UNDERAGE') {
              showModalLimitedAccess()
              return
            }
            fetchFollowData()
            setModal('following')
          }}>
          <StyledFollowingTextWrap fontSize="m" color="gray_3" lineHeight={26}>
            <ParsedText parse={parseText}>
              {translate('forkygram:followingCount', {
                follow: !self && isUnderage ? '-' : following,
              })}
            </ParsedText>
          </StyledFollowingTextWrap>
        </StyledInteractiveContainer>
      </StyledFollowingContainer>
    ),
    [
      childStatus,
      fetchFollowData,
      follower,
      following,
      isFollowModalDisabled,
      isUnderage,
      parseText,
      self,
      translate,
    ],
  )

  const handleRenderUploadModal = useMemo(
    () => (
      <TemplateProfileUploadModal
        visible
        onPost={() => {
          setModal(undefined)
          kycStatus === 'verified' || kycStatus === 'verified_check'
            ? uploadRef.current?.click()
            : setModal('kyc')
        }}
        onSell={() => {
          setModal(undefined)
          kycStatus === 'verified' || kycStatus === 'verified_check'
            ? guidelineCount < 10
              ? setModal('guideline')
              : history.push('giftshop_upload', {creatorStatus, kycStatus})
            : setModal('kyc')
        }}
        toggleModal={() => setModal(undefined)}
      />
    ),
    [creatorStatus, guidelineCount, history, kycStatus],
  )

  const handleRenderBadge = useMemo(
    () =>
      ((self && creatorStatus === 'verified') ||
        (!self && profileInfo.creatorStatus === 'verified')) && (
        <StyledIconVerifiedBadge />
      ),
    [creatorStatus, profileInfo.creatorStatus, self],
  )

  const handleFollowCallback = useCallback(
    (value: boolean) => {
      if (self) {
        if (value) {
          setFollowing((prevState) => prevState + 1)
        } else {
          setFollowing((prevState) => prevState - 1)
        }
      }
    },
    [self],
  )

  const handleRenderFollowerModal = useMemo(
    () => (
      <TemplateFollowModal
        memberId={memberId || user?.id || ''}
        toggleModal={() => setModal(undefined)}
        callback={handleFollowCallback}
        isFollower
      />
    ),
    [memberId, user?.id, handleFollowCallback],
  )

  const handleRenderFollowingModal = useMemo(
    () => (
      <TemplateFollowModal
        memberId={memberId || user?.id || ''}
        toggleModal={() => setModal(undefined)}
        callback={handleFollowCallback}
        isFollower={false}
      />
    ),
    [memberId, user?.id, handleFollowCallback],
  )

  const handleRenderFullStorageModal = useMemo(
    () => <TemplateFullStorageModal toggleModal={() => setModal(undefined)} />,
    [],
  )

  const handleRenderProfilePhotoModal = useMemo(
    () => (
      <TemplateProfilePhotoModal
        toggleModal={() => setModal(undefined)}
        src={self && !profileInfo.isBanned ? user?.photo : photo}
      />
    ),
    [photo, profileInfo.isBanned, self, user?.photo],
  )

  const handleRenderGuidelineModal = useMemo(
    () => (
      <TemplateProfileSellGuidelineModal
        isVerified={creatorStatus === 'verified'}
        onContinue={() =>
          history.push('giftshop_upload', {creatorStatus, kycStatus})
        }
        onToggle={() => setModal(undefined)}
      />
    ),
    [creatorStatus, history, kycStatus],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'link':
        return handleRenderLinkModal
      case 'kyc':
        return handleRenderUploadKYCModal
      case 'upload':
        return handleRenderUploadModal
      case 'follow':
        return handleRenderFollowerModal
      case 'following':
        return handleRenderFollowingModal
      case 'profile':
        return handleRenderProfilePhotoModal
      case 'full':
        return handleRenderFullStorageModal
      case 'guideline':
        return handleRenderGuidelineModal
    }
    return <></>
  }, [
    handleRenderFollowerModal,
    handleRenderFollowingModal,
    handleRenderFullStorageModal,
    handleRenderGuidelineModal,
    handleRenderLinkModal,
    handleRenderProfilePhotoModal,
    handleRenderUploadKYCModal,
    handleRenderUploadModal,
    modal,
  ])

  const handleFotoyuBadge = useMemo(
    () => (isOfficial ? <StyledFotoyuBadge src={fotoyuBadge} /> : null),
    [fotoyuBadge, isOfficial],
  )

  const handleRenderInputFile = useMemo(
    () => (
      <StyledUploadInput
        type="file"
        ref={uploadRef}
        onChange={handleCheckImage}
        accept={FORKYGRAM_UPLOAD_TYPE.join(',')}
      />
    ),
    [handleCheckImage],
  )

  const handleRenderBio = useMemo(
    () => (
      <StyledBioContainer>
        <StyledTextWrap
          fontSize={mode === 'website' ? 's' : 'm'}
          lineHeight={mode === 'website' ? 21 : 26}
          color="white_1">
          {self ? user?.biodata : profileInfo.biodata}
        </StyledTextWrap>
      </StyledBioContainer>
    ),
    [mode, profileInfo.biodata, self, user?.biodata],
  )

  const handleNavigateAddCredential = useCallback(() => {
    stateModalCredential[1](false)
    history.push('giftshop_edit_profile', {
      menu: credential === 'email' ? 'email' : 'phone',
    })
  }, [credential, history, stateModalCredential])

  useEffect(() => {
    fetchUserData()
    fetchFollowData()
  }, [fetchFollowData, fetchUserData, self])

  return (
    <>
      {self ? (
        <>
          <GiftShopTemplateModalPassword
            stateModal={stateModalPassword}
            onSuccess={() => {
              creatorHasIncompleteCredential && stateModalCredential[1](true)
            }}
          />
          <GiftShopTemplateModalCredential
            stateModal={stateModalCredential}
            credential={credential}
            onAgree={handleNavigateAddCredential}
          />
        </>
      ) : (
        <></>
      )}
      {handleRenderLoginModal}
      {handleRenderUploadPostKYCModal}
      {handleRenderModal}
      {handleRenderInputFile}
      <StyledInfoContainer>
        {profileInfo.isBanned ? (
          <StyledBackgroundPlaceholder />
        ) : self ? (
          !user?.background_photo || user?.background_photo === '' ? (
            <StyledBackgroundPlaceholder />
          ) : (
            <StyledBackgroundContainer>
              <StyledBackground
                width="100%"
                height="100%"
                src={
                  self && !profileInfo.isBanned
                    ? user?.background_photo
                    : background_photo
                }
                alt="Background Photo"
              />
            </StyledBackgroundContainer>
          )
        ) : profileInfo.backgroundPhoto === '' ? (
          <StyledBackgroundPlaceholder />
        ) : (
          <StyledBackgroundContainer>
            <StyledBackground
              width="100%"
              height="100%"
              src={profileInfo.backgroundPhoto}
              alt="Background Photo"
            />
          </StyledBackgroundContainer>
        )}
        <StyledProfileContainer mode={mode}>
          <StyledUpperProfileInfoContainer>
            <StyledAvatarContainer
              isLive={self ? isLive : profileInfo.isLive}
              onClick={() => setModal('profile')}>
              <StyledAvatar
                src={
                  profileInfo.isBanned || (!self && !photo)
                    ? avatarDefault
                    : self
                    ? user?.photo
                    : photo
                }
                alt="Default Profile"
              />
              {(self ? isLive : profileInfo.isLive) && (
                <StyledLiveContainer>
                  <Icon type="fg" size={convertUnit(12)} color="white_1" />
                  <Paragraph fontWeight="bold" color="white_1">
                    {translate('global:live').toUpperCase()}
                  </Paragraph>
                </StyledLiveContainer>
              )}
            </StyledAvatarContainer>
            <StyledUsernameContainer>
              <StyledColumnContainer>
                <div>
                  <StyledUsernameWrapper
                    fontSize="xl"
                    fontWeight="bold"
                    color="white_1">
                    {self ? user?.username : username}
                  </StyledUsernameWrapper>
                  <StyledIconVerifiedBadgeWrapper>
                    {handleRenderBadge}
                    {handleFotoyuBadge}
                  </StyledIconVerifiedBadgeWrapper>
                </div>
                <Rating
                  value={self ? user?.rating : profileInfo.creatorRating}
                  valueColor="white_5"
                  ratingCount={
                    self ? user?.ratingCount : profileInfo.ratingCount
                  }
                  countColor="gray_1"
                />
                {handleRenderFollowing}
                {self && mode !== 'website' && selectTab === 'creation'
                  ? handleRenderUploadButton
                  : mode === 'website' && handleRenderBio}
                {mode === 'website' && handleRenderButton}
              </StyledColumnContainer>
            </StyledUsernameContainer>
          </StyledUpperProfileInfoContainer>
          {((self && mode !== 'website' && selectTab === 'post') ||
            (!self && mode !== 'website')) &&
            handleRenderBio}
          <StyledSocialLinkContainer mode={mode}>
            {self
              ? (selectTab === 'post' ||
                  (selectTab === 'creation' && mode === 'website')) &&
                user &&
                user.social &&
                user?.social.map((item) => (
                  <ProfileDetailSocialLinkItem
                    key={item.social_type + item.social_value}
                    onClickItem={(elem) => {
                      linkRef.current = elem.social_value
                      setModal('link')
                    }}
                    item={item}
                  />
                ))
              : profileInfo.social.map((item) => (
                  <ProfileDetailSocialLinkItem
                    key={item.social_type + item.social_value}
                    onClickItem={(elem) => {
                      linkRef.current = elem.social_value
                      setModal('link')
                    }}
                    item={item}
                  />
                ))}
          </StyledSocialLinkContainer>
          <StyledWebButtonsContainer>
            {mode !== 'website' && handleRenderButton}
          </StyledWebButtonsContainer>
        </StyledProfileContainer>
      </StyledInfoContainer>
    </>
  )
}
