import React, {useMemo} from 'react'
import {Layer, Source} from 'react-map-gl'
import {AnyPaint} from 'mapbox-gl'
import {useSelector} from 'lib/redux'
import {useTheme} from 'themes'
import {
  TREE_MAP_LAYER_ID_ACTIVE,
  TREE_MAP_LAYER_ID_CREATOR_LIVE_ACTIVE,
  TREE_MAP_LAYER_LAYOUT,
  TREE_MAP_LAYER_LAYOUT_CREATOR_LIVE,
  TREE_MAP_LAYER_LAYOUT_FOTOTREE,
} from 'consts'
import {TreeActiveMarkerProps} from './TreeFototreeModalProps'

export default function TreeActiveMarker({
  featureData,
  creatorFeatureData,
  layerCreatorVisibility,
}: TreeActiveMarkerProps) {
  const theme = useTheme()
  const {id} = useSelector('user') || {}

  const mapStylePaint = useMemo<AnyPaint>(
    () => ({
      'text-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_5,
        'community',
        theme.success_7,
        theme.primary_5,
      ],
      'text-halo-width': 1,
      'text-halo-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_1,
        'community',
        theme.success_1,
        theme.primary_1,
      ],
    }),
    [
      theme.danger_1,
      theme.danger_5,
      theme.primary_1,
      theme.primary_5,
      theme.success_1,
      theme.success_7,
    ],
  )

  const mapStylePaintCreator = useMemo<AnyPaint>(
    () => ({
      'text-color': theme.primary_7,
      'text-halo-color': theme.gray_1,
      'text-halo-width': 1,
    }),
    [theme.gray_1, theme.primary_7],
  )

  const handleRenderActiveMarker = useMemo(
    () =>
      featureData && (
        <Source id="map-active-source" type="geojson" data={featureData}>
          <Layer
            id={TREE_MAP_LAYER_ID_ACTIVE}
            type="symbol"
            layout={{
              ...TREE_MAP_LAYER_LAYOUT,
              ...TREE_MAP_LAYER_LAYOUT_FOTOTREE,
              'icon-size': 0.4,
              'text-field': ['get', 'name'],
              'text-size': 14,
              'text-font': ['Ubuntu Bold'],
            }}
            paint={mapStylePaint}
          />
        </Source>
      ),
    [featureData, mapStylePaint],
  )

  const handleRenderCreatorActiveMarker = useMemo(
    () =>
      creatorFeatureData && (
        <Source
          id="map-creator-active-source"
          type="geojson"
          data={creatorFeatureData}>
          <Layer
            id={TREE_MAP_LAYER_ID_CREATOR_LIVE_ACTIVE}
            type="symbol"
            layout={{
              ...TREE_MAP_LAYER_LAYOUT,
              ...TREE_MAP_LAYER_LAYOUT_CREATOR_LIVE,
              visibility: layerCreatorVisibility,
            }}
            paint={mapStylePaintCreator}
            filter={['!=', 'member_id', id]}
          />
        </Source>
      ),
    [creatorFeatureData, id, layerCreatorVisibility, mapStylePaintCreator],
  )

  return (
    <>
      {handleRenderCreatorActiveMarker}
      {handleRenderActiveMarker}
    </>
  )
}
