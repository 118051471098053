import {BanModalHandler} from 'types'

let handler: BanModalHandler | undefined

export function setBanModalHandler(instance: BanModalHandler) {
  handler = instance
}

export function showBanModal(status: number, message: string) {
  handler && handler.showBanModal(status, message)
}
