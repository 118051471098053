import {useCallback, useMemo, useRef} from 'react'
import {FACEBOOK_URL, TWITTER_URL, WHATSAPP_URL} from 'consts'
import {translate} from 'i18n'
import {showSnackbar} from 'utils'
import {requestData, ServiceParam} from 'services'
import {
  GiftShopShareDestinationType,
  ShareUrlDataProps,
  ShareUrlOptions,
  ShareUrlValues,
} from 'types'

function objectToUrlParams(data: ShareUrlDataProps) {
  const params = Object.entries(data)
    .filter(([, value]) => value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
  return params.length ? `?${params.join('&')}` : ''
}

export async function copyToClipboard(text: string, onSuccess: () => void) {
  setTimeout(async () => {
    try {
      await navigator.clipboard
        .writeText(text)
        .then(() => onSuccess())
        .catch(() => showSnackbar(translate('global:messageError')))
    } catch (e) {
      throw Error()
    }
  })
}

export function shareToSocialMedia(
  destination: GiftShopShareDestinationType,
  url: string,
  caption?: string,
) {
  const platform: Record<
    Exclude<GiftShopShareDestinationType, 'copy'>,
    string
  > = {
    twitter: `${TWITTER_URL}${objectToUrlParams({url, text: caption})}`,
    facebook: `${FACEBOOK_URL}${objectToUrlParams({u: url})}`,
    whatsapp: `${WHATSAPP_URL}${objectToUrlParams({
      text: caption ? `${caption} ${url}` : url,
    })}`,
  }

  if (destination in platform) {
    window.open(platform[destination], '_blank')
  } else if (destination === 'copy') {
    copyToClipboard(url, () => {
      showSnackbar(translate('global:linkCopied'))
    })
  }
}

export function useShareToSocialMedia({
  stateLoading,
  targetId,
  subTargetId,
  type,
  caption,
  toggleModal,
}: ShareUrlOptions) {
  const shortUrl = useRef('')
  const setLoading = stateLoading[1]

  const handleShareUrl = useCallback(
    (destination: GiftShopShareDestinationType) => {
      const serviceKey: keyof ServiceParam =
        type === 'leaf'
          ? 'tree_post_share_leaf'
          : type === 'fototree'
          ? 'tree_post_share_fototree'
          : type === 'post'
          ? 'forkygram_post_share'
          : type === 'creator_link'
          ? 'giftshop_get_share_creator_link'
          : type === 'creator_live'
          ? 'giftshop_post_live_location_share'
          : 'giftshop_post_profile_share'

      const data =
        type === 'leaf' && subTargetId
          ? {tree_id: targetId, post_id: subTargetId}
          : type === 'fototree'
          ? {id: targetId}
          : type === 'post' && subTargetId
          ? {id: targetId, username: subTargetId}
          : type === 'profile'
          ? {username: targetId}
          : undefined

      const errorMessage =
        type === 'leaf' || type === 'post'
          ? translate('global:content')
          : type === 'fototree'
          ? translate('global:fototree')
          : translate('global:profile')

      setLoading(true)
      requestData(serviceKey, {
        data,
        actionType: 'execute',
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status, data: {result}}) => {
          toggleModal()
          if (status === 200) {
            shortUrl.current = result.url
            shareToSocialMedia(destination, shortUrl.current, caption)
          } else {
            showSnackbar(
              translate('global:shareContentFailed', {
                type: errorMessage.toLowerCase(),
              }),
            )
          }
        },
      })
    },
    [caption, setLoading, subTargetId, targetId, toggleModal, type],
  )

  return useMemo<ShareUrlValues>(() => ({shareUrl: handleShareUrl}), [
    handleShareUrl,
  ])
}
