import {lazy} from 'react'

const GiftshopTransactionWithdrawScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionWithdraw' */ './GiftshopTransactionWithdrawScreen'
    ),
)

export {GiftshopTransactionWithdrawScreen}
