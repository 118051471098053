import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Icon, Paragraph, ParsedText} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useTheme} from 'themes'
import {parseShape} from 'types'
import {COMPANY_NAME} from 'consts'

export interface LegalLikeAndCommentProps {
  scrollTo: (childRef: number | undefined) => void
}

const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalLikeAndComment({
  scrollTo,
}: LegalLikeAndCommentProps) {
  const theme = useTheme()
  const {translate} = useTranslation()

  const pattern = useMemo((): parseShape[] => {
    const regex = /\[(.+?)\]/
    return [
      {
        pattern: regex,
        style: {
          cursor: 'pointer',
          color: theme.primary_5,
          textDecoration: 'underline',
        },
        renderText: (text) => text.replace(regex, `$1`),
        onClick: () => scrollTo(6),
      },
    ]
  }, [scrollTo, theme.primary_5])

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksLikeComment')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksLikeCommentDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksLike')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksLikeDesc_1')}
          <Icon type="like-outlined" />
          {translate('policy:howItWorksLikeDesc_2')}
          <Icon type="like-filled" />
          {translate('policy:howItWorksLikeDesc_3')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksComment')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={pattern}>
            {translate('policy:howItWorksCommentDesc', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [pattern, translate],
  )

  return <>{handleTitle}</>
}
