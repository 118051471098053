import React from 'react'
import { Paragraph } from 'common/components'
import { useTranslation } from 'i18n'
import { FirebaseMessageRoomItemPayload } from 'types'

export interface GiftShopMessageRoomContentItemNotificationHostAdminDemotionProps {
  payload: FirebaseMessageRoomItemPayload['notification_demotion_host_admin']
}

export default function GiftShopMessageRoomContentItemNotificationHostAdminDemotion({
  payload: {host_tag, username},
}: GiftShopMessageRoomContentItemNotificationHostAdminDemotionProps) {
  const {translate} = useTranslation()

  return (
    <Paragraph fontSize="m">
      {translate('messages:hostAdminDemotion', {
        username,
        host_tag,
      })}
    </Paragraph>
  )
}
