import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {AUDIO_ASSET, GIFT_SHOP_HEADER_HEIGHT, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopKYCRecordStatus} from 'types'
import {useWindowLayout} from 'windows'
import {Image, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContentContainerProps {
  height: number
  width: number
}

const StyledContainer = styled.div<StyledContentContainerProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  ${({height, width}) => ({height, width})};
`
const StyledOutlineOverlay = styled(Image)<StyledContentContainerProps>`
  ${({width}) => ({width})};
`
const StyledIndicatorContainer = styled.div<StyledContentContainerProps>`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: row;
  top: ${convertUnit(20)};
  justify-content: space-between;
  ${({width}) => ({width})};
`
const StyledFaceIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledRecordIcon = styled(Image)`
  width: ${convertUnit(50)};
  margin-right: ${convertUnit(20)};
`
const StyledBottomContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(20)};
  width: 100%;
  z-index: 10;
  justify-content: center;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export interface AuthEasySignupChildrenOverlayRecordProps {
  progress: GiftShopKYCRecordStatus
  isRecording: boolean
  onAudioEnd(): void
  isEasySignup?: boolean
}
export default function AuthEasySignupChildrenOverlayRecord({
  progress,
  isRecording,
  onAudioEnd,
  isEasySignup,
}: AuthEasySignupChildrenOverlayRecordProps) {
  const {height, width} = useWindowLayout()
  const {translate} = useTranslation()
  const faceFront = IMAGE_ASSET('giftshop', 'front-face.png')
  const overlayFront = IMAGE_ASSET('giftshop', 'overlay-parent-consent.png')

  const audioFront = AUDIO_ASSET('giftshop', 'en-part-1.mp3')
  const audioFrontId = AUDIO_ASSET('giftshop', 'id-part-1.mp3')
  const audioEasySignupFront = AUDIO_ASSET('giftshop', 'en-kyc-1.mp3')

  const audioEasySignupFrontId = AUDIO_ASSET('giftshop', 'id-kyc-1.mp3')

  const recordGif = IMAGE_ASSET('giftshop', 'record.gif')
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio())
  const {lang} = useSelector('languageState')

  const handleAudio = useCallback(
    (audioAsset: HTMLAudioElement) => {
      audio.pause()
      switch (progress) {
        case 'front':
          audioAsset.src = isEasySignup
            ? lang === 'en'
              ? audioEasySignupFront
              : audioEasySignupFrontId
            : lang && lang === 'en'
            ? audioFront
            : audioFrontId
          break
        default:
          audioAsset.src = lang && lang === 'en' ? audioFront : audioFrontId
          break
      }
      audioAsset.load()
      if (audioAsset.volume > 0.15) audioAsset.volume = 0.15
      audioAsset.play().catch(() => {})
      return audioAsset
    },
    [
      audio,
      audioEasySignupFront,
      audioEasySignupFrontId,
      audioFront,
      audioFrontId,
      isEasySignup,
      lang,
      progress,
    ],
  )

  const handleUpdateAudio = useCallback(() => {
    setAudio((prev) => handleAudio(prev))
  }, [handleAudio])

  useEffect(() => {
    handleUpdateAudio()
    if (progress === 'done') {
      audio.pause()
    }
    audio.addEventListener('ended', onAudioEnd)
    return () => audio.removeEventListener('ended', onAudioEnd)
  }, [audio, handleUpdateAudio, onAudioEnd, progress])

  return (
    <>
      <StyledIndicatorContainer
        height={height - GIFT_SHOP_HEADER_HEIGHT}
        width={Math.min((height - GIFT_SHOP_HEADER_HEIGHT) * 0.67, width)}>
        <StyledFaceIndicatorContainer>
          <Image src={faceFront} alt="" />
          <Image src={faceFront} alt="" />
        </StyledFaceIndicatorContainer>
        {isRecording ? <StyledRecordIcon src={recordGif} alt="" /> : <></>}
      </StyledIndicatorContainer>
      <StyledContainer
        height={height - GIFT_SHOP_HEADER_HEIGHT}
        width={(height - GIFT_SHOP_HEADER_HEIGHT) * 0.67}>
        <StyledOutlineOverlay
          src={overlayFront}
          alt=""
          height={height - GIFT_SHOP_HEADER_HEIGHT}
          width={(height - GIFT_SHOP_HEADER_HEIGHT) * 0.67}
        />
        <StyledBottomContainer>
          <StyledParagraph fontSize="xl" color="white_1">
            {translate('giftShop:kycRecord', {context: progress})}
          </StyledParagraph>
        </StyledBottomContainer>
      </StyledContainer>
    </>
  )
}
