import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Button} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {GiftShopTemplateUploadModal} from '../../../template'
import {GiftShopUploadPublishModalRetryProps} from './GiftShopUploadPublishModalRetryProps'

const StyledSectionButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledButtonClose = styled(StyledButton)`
  ${({theme}) => ({backgroundColor: theme.white_3})}
`

const StyledButtonRetry = styled(StyledButton)`
  margin-left: ${convertUnit(10)};
  box-sizing: border-box;
`

export default function GiftShopUploadPublishModalRetry({
  success,
  total,
  onClose,
  onRetry,
  stateLoadingRetryButton,
  ...props
}: GiftShopUploadPublishModalRetryProps) {
  const {translate} = useTranslation()
  const loading = stateLoadingRetryButton[0]

  const handleRenderContent = useMemo(
    () => (
      <GiftShopTemplateUploadModal
        {...props}
        title={translate('giftShop:uploadPublishRetryTitle')}
        description={translate('giftShop:uploadPublishRetryCountFail', {
          count: total - success,
        })}
        progressColor="danger_5"
        success={success}
        total={total}
      />
    ),
    [props, success, total, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledSectionButton>
        <StyledButtonClose
          label={translate('global:close')}
          onClick={onClose}
          color="primary_5"
        />
        <StyledButtonRetry
          label={translate('global:retry')}
          onClick={onRetry}
          isLoading={loading}
          disabled={loading}
          disableBackgroundColor="primary_5"
          disabledFontColor="white_1"
        />
      </StyledSectionButton>
    ),
    [loading, onClose, onRetry, translate],
  )

  return (
    <>
      {handleRenderContent}
      {handleRenderButton}
    </>
  )
}
