import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationAcceptInvitationAdminHostProps {
  payload: FirebaseMessageRoomItemPayload['notification_accept_invitation_admin_host']
}

export default function GiftShopMessageRoomContentItemNotificationAcceptInvitationAdminHost({
  payload: {host_member_username, host_tag, username},
}: GiftShopMessageRoomContentItemNotificationAcceptInvitationAdminHostProps) {
  const {translate} = useTranslation()

  return (
    <>
      <Paragraph fontSize="m">
        {translate('messages:hiUsername', {username})}
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        {translate('messages:notificationHostAdminInvitationAccepted', {
          host_member_username,
          host_tag,
        })}
      </Paragraph>
    </>
  )
}
