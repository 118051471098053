import {
  VideoExtractBlobOptions,
  VideoExtractOptions,
  VideoFrameTimeBuildType,
  VideoTimeFrameOptions,
} from 'types'
import {canvasToBlob} from '../canvas'

const VIDEO_PREVIEW_FRAMES_TEMPLATE: VideoFrameTimeBuildType[] = [
  [0, 0],
  [2, 0],
  ...Array.from({length: 12}, (_, i) => [4, i] as VideoFrameTimeBuildType),
  ...Array.from(
    {length: 2},
    (_, i) => [2 * (i + 3), 0] as VideoFrameTimeBuildType,
  ),
  [10, -1],
]

export function getVideoTimeFrames({
  duration,
  offset = 2,
  timeRange = 20,
  fps = 24,
}: VideoTimeFrameOptions) {
  const scale = Math.min(1, duration / timeRange)
  const durationPerFrame = (1 / fps) * scale
  const offsetFinal = offset * scale
  const timeFrames: number[] = []

  for (const [second, offsetFrame] of VIDEO_PREVIEW_FRAMES_TEMPLATE) {
    const secondFinal = second * scale
    const timeFrame = offsetFinal + secondFinal + offsetFrame * durationPerFrame

    timeFrames.push(Number(timeFrame.toFixed(3)))
  }

  return timeFrames
}

export function extractVideoFrames<T>({
  file,
  timestamps,
  maxDimension,
  metadata,
  frameExtractor,
}: VideoExtractOptions<T>) {
  // @ts-ignore
  return new Promise<T[] | null>((resolve) => {
    const objectUrl = URL.createObjectURL(file)
    const video = document.createElement('video')
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const frames: T[] = []
    if (!context) return null
    let scale = 1
    let index = 0
    const handleFinished = () => {
      URL.revokeObjectURL(objectUrl)
    }
    const handleError = () => {
      handleFinished()
      resolve(null)
    }
    const handleUpdateCanvas = () => {
      const {width, height, duration} = metadata
      metadata = {width, height, duration}
      scale = maxDimension
        ? maxDimension / (height > width ? height : width)
        : 1
      canvas.width = metadata.width * scale
      canvas.height = metadata.height * scale
    }
    const extractFrame = () => {
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      return frameExtractor(canvas, context)
    }
    const loadNextFrame = async () => {
      index += 1
      if (index < timestamps.length) {
        video.currentTime = timestamps[index]
      } else {
        handleFinished()
        resolve(frames)
      }
    }
    const handleTimeUpdate = async () => {
      if (index === 0) {
        await new Promise((res) => {
          setTimeout(() => {
            res(true)
          }, 200)
        })
      }
      const frame = await extractFrame()
      if (!frame) {
        handleError()
        return
      }
      frames.push(frame)
      loadNextFrame()
    }
    handleUpdateCanvas()
    video.src = objectUrl
    video.onabort = handleError
    video.onerror = handleError
    video.ontimeupdate = handleTimeUpdate
    video.currentTime = timestamps[index]
  })
}
export function extractVideoFrameBlobs({
  format,
  quality,
  ...options
}: VideoExtractBlobOptions) {
  return extractVideoFrames({
    ...options,
    frameExtractor: (canvas) => canvasToBlob(canvas, format, quality),
  })
}
