import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, {useTheme} from 'styled-components'
import {Marker, FlyToInterpolator, InteractiveMapProps} from 'react-map-gl'
import {useTranslation} from 'i18n'
import {
  MAP_LAT_LNG_PRECISION,
  TREE_MAX_CITY_NAME_CHARACTER,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {requestData} from 'services'
import {
  GiftShopGetLocation,
  GiftshopLocationField,
  LastUploadState,
  UploadContentLocation,
} from 'types'
import {useWindowMode} from 'windows'
import {getGiftshopLocationName, showSnackbar} from 'utils'
import {
  Button,
  Icon,
  Input,
  ListItemSelect,
  Map,
  Menu,
  ModalFullscreen,
  Paragraph,
  Pin,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopLocationModalProps} from './GiftShopLocationModalProps'

interface StyledFormContainerProps {
  showField?: GiftshopLocationField
}

const StyledModalFullscreen = styled(ModalFullscreen)<CSSProperties>`
  display: ${({display}) => display};
  border-radius: 0;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`

const StyledSearchBar = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  order: 1;
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    order: 2;
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledMapHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledMapHeaderSubtitle = styled(Paragraph)`
  width: max-content;
`

const StyledFormContainer = styled.div<StyledFormContainerProps>`
  ${({showField}) => ({
    gridTemplateColumns: showField === 'giftshop' ? '1fr auto' : '1fr 1fr auto',
    gap: showField ? convertUnit(20) : undefined,
  })}
  display: grid;
  width: 100%;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    grid-template-columns: auto;
  }
`

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledOptionsContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  flex: 1;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${convertUnit(20)};
`

const StyledMapContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: ${({theme}) => theme.white_2};
  order: 2;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    order: 1;
  }
`

const StyledGPSContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${convertUnit(25)};
  right: ${convertUnit(25)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  padding: ${convertUnit(5)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: ${convertUnit(25)};
    right: ${convertUnit(25)};
    top: auto;
  }
`

export default function GiftShopLocationModal({
  mapForm,
  showField,
  messageField,
  updateUploadState = false,
  toggleModal,
  onSave,
  visible,
  uploadType,
}: GiftShopLocationModalProps) {
  const theme = useTheme()
  const mode = useWindowMode()

  const mapLocationRef = useRef<UploadContentLocation | null>()
  const userInfoRef = useRef(useSelector('user')!)

  const mapAttributeState = useState<InteractiveMapProps>({
    latitude: -6.175372,
    longitude: 106.827194,
    zoom: 15,
    maxZoom: 16,
    minZoom: 4,
  })

  const [mapAttribute, setMapAttribute] = mapAttributeState

  const {translate} = useTranslation()
  const stateToggle = useState(false)
  const [toggle, setToggle] = stateToggle
  const [searchQuery, setSearchQuery] = useState('')
  const {update} = useDispatch()
  const [data, setData] = useState<GiftShopGetLocation[]>([])
  const [cityName, setCityName] = useState('')
  const lastUploadPhoto = useSelector('lastUploadStatePhoto')
  const lastUploadVideo = useSelector('lastUploadStateVideo')

  const handleFetchLocation = useCallback((query: string) => {
    if (query.length >= 3) {
      requestData('giftshop_get_location', {
        params: {q: query},
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
        },
      })
    }
  }, [])

  const handleGetCityName = useCallback((lat: number, long: number) => {
    requestData('tree_get_location', {
      params: {lat, long},
      useDefaultMessage: true,
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setCityName(result.city_name)
      },
    })
  }, [])

  const handleSelect = useCallback(
    (item: GiftShopGetLocation) => {
      setSearchQuery(item.address)
      setMapAttribute((old) => ({
        ...old,
        latitude: item.latitude,
        longitude: item.longitude,
      }))

      mapLocationRef.current = {
        latitude: item.latitude,
        longitude: item.longitude,
        name: item.address,
      }

      if (showField === 'fototree') {
        handleGetCityName(item.latitude, item.longitude)
      }

      setToggle(false)
    },
    [handleGetCityName, setMapAttribute, setToggle, showField],
  )

  const handleRenderList = useMemo(
    () => (
      <StyledOptionsContainer>
        {data &&
          data.map((item) => (
            <ListItemSelect
              key={`${item.longitude}, ${item.latitude}`}
              title={item.address}
              onClick={() => handleSelect(item)}
              leftElement={
                <Icon type="mark" style={{marginRight: convertUnit(5)}} />
              }
            />
          ))}
      </StyledOptionsContainer>
    ),
    [data, handleSelect],
  )

  const updateMapboxPosition = useCallback(
    (newLat: number, newLng: number, name?: string) => {
      const precisionLat = newLat.toFixed(MAP_LAT_LNG_PRECISION)
      const precisionLng = newLng.toFixed(MAP_LAT_LNG_PRECISION)
      setMapAttribute((old) => ({
        ...old,
        latitude: Number(precisionLat),
        longitude: Number(precisionLng),
        transitionInterpolator: new FlyToInterpolator(),
      }))
      mapLocationRef.current = {
        latitude: Number(precisionLat),
        longitude: Number(precisionLng),
        name: name || `lat: ${precisionLat} lng: ${precisionLng}`,
      }

      setSearchQuery(`Lat: ${precisionLat} Lng: ${precisionLng}`)
    },
    [setMapAttribute],
  )

  const getUserCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      // eslint-disable-next-line no-undef
      (position: GeolocationPosition) => {
        updateMapboxPosition(
          position.coords.latitude,
          position.coords.longitude,
        )
      },
    )
  }, [updateMapboxPosition])

  const handleFocusUserLocation = useCallback(() => {
    if (
      mapForm &&
      mapForm.getValues('location') !== '' &&
      !!mapForm.getValues('location')
    ) {
      const {latitude, longitude} = JSON.parse(mapForm.getValues('location'))
      updateMapboxPosition(latitude, longitude)
    } else if (userInfoRef.current.latitude && userInfoRef.current.longitude) {
      updateMapboxPosition(
        userInfoRef.current.latitude,
        userInfoRef.current.longitude,
      )
      if (showField === 'fototree' && !mapForm?.getValues('cityName')) {
        handleGetCityName(
          userInfoRef.current.latitude,
          userInfoRef.current.longitude,
        )
      }
    }
  }, [mapForm, updateMapboxPosition, showField, handleGetCityName])

  const handleCurrentPositionClick = useCallback(() => {
    if (navigator.geolocation) {
      getUserCurrentPosition()
    }
  }, [getUserCurrentPosition])

  useEffect(() => {
    if (mapForm === undefined) return
    handleFocusUserLocation()
  }, [mapForm, theme.danger_5, handleFocusUserLocation])

  useEffect(() => {
    if (
      mapForm &&
      !!mapForm.getValues('location') &&
      mapForm.getValues('location') !== ''
    ) {
      setSearchQuery(JSON.parse(mapForm.getValues('location')).name)
      mapForm.setValue('location', '')
    }
  }, [mapForm])

  useEffect(() => {
    if (toggle) {
      const debounce = setTimeout(() => {
        handleFetchLocation(searchQuery)
      }, 300)
      return () => clearTimeout(debounce)
    }
    return undefined
  }, [handleFetchLocation, searchQuery, toggle])

  const handleDragEnd = useCallback(() => {
    if (mapAttribute.latitude && mapAttribute.longitude) {
      const precisionLat = mapAttribute.latitude.toFixed(MAP_LAT_LNG_PRECISION)
      const precisionLng = mapAttribute.longitude.toFixed(MAP_LAT_LNG_PRECISION)
      if (showField === 'giftshop') {
        const updatedData: LastUploadState = {
          latitude: precisionLat,
          longitude: precisionLng,
          name: `lat: ${precisionLat} lng: ${precisionLng}`,
        }
        update('lastUploadStatePhoto', updatedData)
        update('lastUploadStateVideo', updatedData)
      }
      mapLocationRef.current = {
        latitude: Number(precisionLat),
        longitude: Number(precisionLng),
        name: `lat: ${precisionLat} lng: ${precisionLng}`,
      }
      setSearchQuery(`Lat: ${precisionLat} Lng: ${precisionLng}`)
      if (showField === 'fototree') {
        handleGetCityName(mapAttribute.latitude, mapAttribute.longitude)
      }
    }
  }, [
    handleGetCityName,
    mapAttribute.latitude,
    mapAttribute.longitude,
    showField,
    update,
  ])

  const handleSave = useCallback(() => {
    if (mapForm && mapLocationRef.current) {
      const {latitude, longitude, name} = mapLocationRef.current
      if (showField) {
        mapForm.setValue('location', JSON.stringify(mapLocationRef.current))
        if (updateUploadState) {
          uploadType !== 'video'
            ? update('lastUploadStatePhoto', {
                name,
                latitude: latitude.toString(),
                longitude: longitude.toString(),
              })
            : update('lastUploadStateVideo', {
                name,
                latitude: latitude.toString(),
                longitude: longitude.toString(),
              })
        }
        if (showField === 'fototree') {
          mapForm.setValue('cityName', cityName)
        }
        mapForm.trigger()
      }
      mapForm.setValue('latitude', latitude)
      mapForm.setValue('longitude', longitude)
      onSave()
      toggleModal()
    }
  }, [
    cityName,
    mapForm,
    onSave,
    showField,
    toggleModal,
    update,
    updateUploadState,
    uploadType,
  ])

  const handleClose = useCallback(() => {
    if (mapForm) {
      if (showField === 'giftshop') {
        const {latitude, longitude, name} =
          uploadType === 'video' ? lastUploadVideo : lastUploadPhoto
        const locname = getGiftshopLocationName({latitude, longitude, name})
        mapForm.setValue('location', locname)
      }
      mapForm.trigger()
      toggleModal()
    }
  }, [
    lastUploadPhoto,
    lastUploadVideo,
    mapForm,
    showField,
    toggleModal,
    uploadType,
  ])

  const handleInputCityName = useCallback(
    (text: string) => {
      setCityName(text)
      if (text.length >= TREE_MAX_CITY_NAME_CHARACTER) {
        showSnackbar(
          translate('tree:hostValidateMaxCharacter', {
            count: TREE_MAX_CITY_NAME_CHARACTER,
          }),
        )
      }
    },
    [translate],
  )

  const renderMap = useMemo(
    () =>
      mapAttribute.latitude && mapAttribute.longitude ? (
        <Map viewportState={mapAttributeState} onMouseUp={handleDragEnd}>
          <Marker
            longitude={mapAttribute.longitude}
            latitude={mapAttribute.latitude}>
            <Pin />
          </Marker>
        </Map>
      ) : null,
    [
      handleDragEnd,
      mapAttribute.latitude,
      mapAttribute.longitude,
      mapAttributeState,
    ],
  )

  const handleRenderFieldLocation = useMemo(
    () => (
      <StyledInputContainer>
        {showField === 'giftshop' || showField === 'fototree' ? (
          <Menu
            stateToggle={stateToggle}
            contentContainerStyle={{
              height: convertUnit(250),
              borderRadius: convertUnit(8),
              width: '100%',
              top: mode === 'website' ? '100%' : '-325%',
            }}
            renderContent={handleRenderList}>
            <Input
              value={searchQuery}
              label={translate('global:location')}
              placeholder={translate('giftShop:searchLocationInputPlaceholder')}
              onChangeText={(text) => {
                setSearchQuery(text)
                setToggle(true)
              }}
              onClick={() => {
                setToggle(true)
              }}
              rightIcon="delete"
              onRightIconClick={() => {
                if (showField === 'fototree') {
                  mapLocationRef.current = null
                  setCityName('')
                }
                setSearchQuery('')
              }}
            />
            <Paragraph color="gray_5">
              {`Lat: ${
                mapLocationRef.current?.latitude.toFixed(
                  MAP_LAT_LNG_PRECISION,
                ) || 0
              } Lng: ${
                mapLocationRef.current?.longitude.toFixed(
                  MAP_LAT_LNG_PRECISION,
                ) || 0
              }`}
            </Paragraph>
          </Menu>
        ) : (
          <Paragraph color="gray_5">{`${mapAttribute.latitude}, ${mapAttribute.longitude}`}</Paragraph>
        )}
      </StyledInputContainer>
    ),
    [
      handleRenderList,
      mapAttribute.latitude,
      mapAttribute.longitude,
      mode,
      searchQuery,
      setToggle,
      showField,
      stateToggle,
      translate,
    ],
  )

  const handleRenderFieldCityName = useMemo(
    () =>
      showField === 'fototree' && (
        <Input
          label={translate('global:city')}
          placeholder={translate('giftShop:searchCityPlaceholder')}
          rightIcon={cityName ? <Icon size={16} type="close" /> : undefined}
          value={cityName}
          maxLength={TREE_MAX_CITY_NAME_CHARACTER}
          onChangeText={handleInputCityName}
          onRightIconClick={() => setCityName('')}
          containerStyle={{alignSelf: 'baseline'}}
        />
      ),
    [cityName, handleInputCityName, showField, translate],
  )

  return (
    <StyledModalFullscreen
      display={!mapForm ? 'none' : 'block'}
      visible={visible}>
      <StyledContainer>
        <StyledSearchBar>
          <StyledMapHeaderContainer>
            <Paragraph
              fontSize={mode === 'website' ? 'xxl' : 'xl'}
              lineHeight={mode === 'website' ? 53 : 42}
              fontWeight="bold">
              {translate('giftShop:maps')}
            </Paragraph>
            <StyledMapHeaderSubtitle
              fontSize="m"
              lineHeight={26}
              color="gray_5">
              {messageField || translate('giftShop:whereYourContentIsMade')}
            </StyledMapHeaderSubtitle>
          </StyledMapHeaderContainer>
          <StyledFormContainer showField={showField}>
            {handleRenderFieldLocation}
            {handleRenderFieldCityName}
            <StyledButtonContainer>
              <Button
                label={translate('global:close')}
                color="primary_5"
                backgroundColor="white_2"
                backgroundHoverColor="white_3"
                style={{width: mode === 'website' ? 'auto' : '50%'}}
                onClick={handleClose}
              />
              <Button
                label={translate('global:saveChanges')}
                disabled={
                  mapForm === undefined ||
                  (mapForm !== undefined &&
                    mapForm.getValues('location') === '' &&
                    !mapLocationRef.current)
                }
                style={{width: mode === 'website' ? 'auto' : '50%'}}
                onClick={handleSave}
              />
            </StyledButtonContainer>
          </StyledFormContainer>
        </StyledSearchBar>
        <StyledMapContainer>
          {renderMap}
          <StyledGPSContainer onClick={handleCurrentPositionClick}>
            <Icon type="gps" size={15} />
          </StyledGPSContainer>
        </StyledMapContainer>
      </StyledContainer>
    </StyledModalFullscreen>
  )
}
