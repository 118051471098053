import React, {useMemo, useRef, useState} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled from 'styled-components'
import {getFileSize, getVideoDuration} from 'utils'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopResendApprovalImageItemProps} from './GiftShopResendApprovalImageItemProps'

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledContentContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-color: ${({theme}) => theme.white_3};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`

const StyledVideoIconContainer = styled.div`
  position: absolute;
  align-self: center;
  z-index: 3;
  cursor: pointer;
`

const StyledHLS = styled(ReactHlsPlayer)`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 1;
`

const StyledInfoContainer = styled(StyledColumn)`
  padding: ${convertUnit(12)};
  gap: ${convertUnit(2)};
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_2};
`

export default function GiftShopResendApprovalImageItem({
  date,
  src,
  title,
  content_type,
  duration,
  size,
  resolution,
}: GiftShopResendApprovalImageItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [paused, setPaused] = useState(true)

  const handleRenderPlayButton = useMemo(
    () => (
      <StyledVideoIconContainer
        onClick={() => {
          setPaused((prev) => !prev)
          paused ? videoRef.current?.play() : videoRef.current?.pause()
        }}>
        <Icon
          type={paused ? 'play' : 'pause'}
          size={convertUnit(40)}
          color="white_1"
        />
      </StyledVideoIconContainer>
    ),
    [paused],
  )

  const handleRenderVideo = useMemo(
    () => (
      <>
        {handleRenderPlayButton}
        <StyledHLS
          playerRef={videoRef}
          src={src}
          onEnded={() => setPaused(true)}
        />
      </>
    ),
    [handleRenderPlayButton, src],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={src} alt={title} />,
    [src, title],
  )

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph fontWeight="bold" color="primary_5">
        {title}
      </Paragraph>
    ),
    [title],
  )

  const handleRenderDurationSize = useMemo(
    () =>
      content_type === 'video' && duration && size ? (
        <Paragraph fontWeight="medium" color="gray_5">
          {`${getVideoDuration(duration, true)} • ${getFileSize(size)}`}
        </Paragraph>
      ) : (
        <></>
      ),
    [content_type, duration, size],
  )

  const handleRenderPhotoSizeResolution = useMemo(
    () =>
      content_type === 'photo' && size && resolution ? (
        <Paragraph fontWeight="medium" color="gray_5">
          {`${resolution.width} x ${resolution.height} • ${getFileSize(size)}`}
        </Paragraph>
      ) : (
        <></>
      ),
    [content_type, resolution, size],
  )

  const handleRenderDate = useMemo(
    () => <Paragraph color="gray_4">{date}</Paragraph>,
    [date],
  )

  return (
    <StyledColumn>
      <StyledContentContainer>
        {content_type === 'photo' ? handleRenderImage : handleRenderVideo}
      </StyledContentContainer>
      <StyledInfoContainer>
        {handleRenderTitle}
        {handleRenderDurationSize}
        {handleRenderPhotoSizeResolution}
        {handleRenderDate}
      </StyledInfoContainer>
    </StyledColumn>
  )
}
