import {useCallback, useState} from 'react'
import firebase from 'lib/firebase'
import {useSelector} from 'lib/redux'
import {FirestorePaymentResendRefreshPayload} from 'types'
import {useDidMount} from 'utils'

export function useFirestorePaymentResendRefresh() {
  const user = useSelector('user')
  const [lastUpdate, setLastUpdate] = useState<number>()

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIREBASE_COLLECTION_USER!)
        .doc(user?.id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data() as FirestorePaymentResendRefreshPayload
          data && setLastUpdate(data.resend_last_request)
        }),
    [user?.id],
  )

  useDidMount(handleFirestore)

  return lastUpdate
}
