import {GiftShopItFotMyType} from 'types'

export function getGiftshopLocationName({
  latitude,
  longitude,
  name,
}: {
  latitude?: string
  longitude?: string
  name?: string
}) {
  if (longitude && latitude && name) {
    return `{"name":"${name}", "longitude":${longitude}, "latitude":${latitude}}`
  }
  if (longitude && latitude) {
    return `{"name":"lat: ${latitude} lng: ${longitude}", "longitude":${longitude}, "latitude":${latitude}}`
  }
  return ''
}

export function getContentURL({
  url,
  type,
}: {
  url: string | Record<GiftShopItFotMyType, string>
  type?: GiftShopItFotMyType
}) {
  if (typeof url !== 'string') {
    return type ? url[type] : ''
  }
  return url
}
