import {lazy} from 'react'

const AuthVerificationEmailConfirmScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthVerificationEmailConfirm' */ './AuthVerificationEmailConfirmScreen'
    ),
)
const AuthVerificationEmailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthVerificationEmail' */ './AuthVerificationEmailScreen'
    ),
)
const AuthVerificationPhoneScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthVerificationPhone' */ './AuthVerificationPhoneScreen'
    ),
)
const AuthVerificationPhoneConfirmScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthVerificationPhoneConfirm' */ './AuthVerificationPhoneConfirmScreen'
    ),
)

export * from './AuthVerificationEmailScreenParam'
export * from './AuthVerificationPhoneScreenParam'
export {
  AuthVerificationEmailConfirmScreen,
  AuthVerificationEmailScreen,
  AuthVerificationPhoneScreen,
  AuthVerificationPhoneConfirmScreen,
}
