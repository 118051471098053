import {lazy} from 'react'

export * from './TemplateSimilarIdentityScreenParam'

const TemplateSimilarIdentityScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'SimilarIdentity' */ './TemplateSimilarIdentityScreen'
    ),
)

export {TemplateSimilarIdentityScreen}
