import React from 'react'
import styled from 'styled-components'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'

const StyledModal = styled(Modal)`
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${convertUnit(20)};
`
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

interface GiftShopTemplateModalOtherAccountFinishProps {
  onClickOk(): void
}

export default function GiftShopTemplateModalOtherAccountFinish({
  onClickOk,
}: GiftShopTemplateModalOtherAccountFinishProps) {
  const {translate} = useTranslation()
  return (
    <StyledModal visible>
      <StyledTitleContainer>
        <Icon type="info-ol" size={48} />
        <Paragraph fontSize="xl" fontWeight="bold">{translate('global:allDone')}</Paragraph>
      </StyledTitleContainer>
      <Paragraph fontSize="m">{translate('giftShop:similarOtherAccountFinish')}</Paragraph>
      <Button label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>)
}
