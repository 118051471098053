import {lazy} from 'react'

const TreeTransactionDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionDetail' */ './TreeTransactionDetailScreen'
    ),
)

const TransactionDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionDetail' */ './TransactionDetailScreen'
    ),
)

const TransactionWaitingDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionWaitingDetail' */ './TransactionWaitingDetailScreen'
    ),
)

const TransactionPendingContentDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionPendingContentDetail' */ './TransactionPendingContentDetailScreen'
    ),
)

const TransactionPaymentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionPayment' */ './TransactionPaymentScreen'
    ),
)

const TransactionEwalletPaymentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TransactionEwalletPayment' */ './TransactionEwalletPaymentScreen'
    ),
)

export * from './TransactionPendingContentDetailParam'
export * from './TransactionPendingContentDetailScreenProps'

export {
  TreeTransactionDetailScreen,
  TransactionDetailScreen,
  TransactionPendingContentDetailScreen,
  TransactionPaymentScreen,
  TransactionWaitingDetailScreen,
  TransactionEwalletPaymentScreen,
}
