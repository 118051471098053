import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {getContentURL} from 'utils'
import convertUnit from 'lib/unit'
import {GiftShopProfileResendBatchItem} from '../ResendBatchItem'
import {GiftShopProfileResendSingleContentProps} from './GiftShopProfileResendSingleContentProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    gap: ${convertUnit(4)};
    padding: 0;
    margin-bottom: ${convertUnit(4)};
  }
`

const StyledItem = styled.div`
  width: 50%;
  flex: 1;
`

export default function GiftShopProfileResendSingleContent({
  data,
  src,
  loadingImg,
}: GiftShopProfileResendSingleContentProps) {
  const {url, title, original_at, type, size, resolution} = data

  return (
    <StyledContainer>
      <StyledItem>
        {typeof original_at === 'string' && (
          <GiftShopProfileResendBatchItem
            date={original_at}
            src={getContentURL({url, type})}
            title={title}
            size={size}
            resolution={resolution}
          />
        )}
      </StyledItem>

      <StyledItem>
        <GiftShopProfileResendBatchItem
          src={src?.url}
          date={src?.date}
          title={src?.name}
          resolution={src?.resolution}
          size={src?.size}
          loadingImg={loadingImg}
        />
      </StyledItem>
    </StyledContainer>
  )
}
