import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopFeedOptionsItem from './GiftShopTemplateOptionsItem'
import {GiftShopTemplateOptionsModalProps} from './GiftShopTemplateOptionsProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  padding: ${convertUnit(20)};
  text-align: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopFeedOptionsModal({
  onBlock,
  onDelete,
  onReport,
  onShare,
  toggleModal,
}: GiftShopTemplateOptionsModalProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('global:options')}
      </Paragraph>
      <div>
        {onShare && (
          <GiftShopFeedOptionsItem
            icon="share"
            text={translate('global:shareContent')}
            onClickItem={onShare}
          />
        )}
        {onReport && (
          <GiftShopFeedOptionsItem
            icon="report"
            text={translate('global:report')}
            onClickItem={onReport}
          />
        )}
        {onDelete && (
          <GiftShopFeedOptionsItem
            icon="trash"
            text={translate('forkygram:postDelete')}
            onClickItem={onDelete}
          />
        )}
        {onBlock && (
          <GiftShopFeedOptionsItem
            icon="block"
            text={translate('forkygram:blockYuserAcc')}
            onClickItem={onBlock}
          />
        )}
      </div>
      <StyledButton
        label={translate('global:close')}
        color="primary_5"
        backgroundColor="white_2"
        onClick={toggleModal}
      />
    </StyledModal>
  )
}
