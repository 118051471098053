import React, { useCallback, useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector';
import { ViewProps } from './ViewProps'

export default function View({ onLayout, ...props }: ViewProps) {
  const { width, height, ref } = useResizeDetector<HTMLDivElement>();


  const handleLayout = useCallback(() => {
    const instance = ref.current

    if (onLayout && instance) {
      const {
        clientWidth,
        clientHeight,
        offsetWidth,
        offsetHeight,
        scrollWidth,
        scrollHeight,
      } = instance

      onLayout({
        clientWidth,
        clientHeight,
        offsetWidth,
        offsetHeight,
        scrollWidth,
        scrollHeight,
      })
    }
  }, [ref, onLayout])

  useEffect(handleLayout, [width, height, handleLayout])

  return <div {...props} ref={ref} />
}
