import {FirebaseMessagePrice, GiftShopContentData} from 'types'

export function getMessageArrayValue(contentData: FirebaseMessagePrice[]) {
  if (contentData && toString.call(contentData) === '[object Array]') {
    return contentData
  }
  return undefined
}

export function getMessageContentIds(
  contentData: FirebaseMessagePrice[],
  contents: string[],
) {
  if (contentData && toString.call(contentData) === '[object Array]') {
    return contentData.map((val) => val.content_id)
  }
  return contents
}

export function getMessagePrice(contentData: FirebaseMessagePrice[]) {
  if (contentData && toString.call(contentData) === '[object Array]') {
    return contentData.map((val) => val.price)
  }
  return []
}

export function updateMessagePrice(
  result: GiftShopContentData[],
  contentIds: string[],
  prices: number[],
) {
  for (let i = 0; i < result.length; i += 1) {
    if (contentIds && prices.length && result[i].content_id === contentIds[i])
      result[i].price = prices[i]
  }
  return result
}
