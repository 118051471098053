import React, {CSSProperties} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {useTimer} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthLinkExpiredProps} from './AuthLinkExpiredProps'

interface StyledResendProps {
  cursor: CSSProperties['cursor']
}

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledIllustrationContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${convertUnit(200)};
  align-items: center;
  justify-content: center;
`

const StyledIllustration = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(350)};
  object-fit: contain;
`

const StyledResend = styled(Paragraph)<StyledResendProps>`
  margin-left: ${convertUnit(5)};
  cursor: ${({cursor}) => cursor};
  display: inline;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledBottomDesc = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`

export default function AuthLinkExpired({
  resetOnPress = true,
  onResend,
  cooldownText,
  showResend = true,
}: AuthLinkExpiredProps) {
  const {translate} = useTranslation()
  const expiredImage = IMAGE_ASSET('giftshop', 'expired.png')

  const {countdown, reset} = useTimer({
    duration: 300,
    countOnStart: false,
  })

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  return (
    <>
      <StyledParagraph fontSize="xl" fontWeight="bold">
        {translate('auth:linkExpiredHeader')}
      </StyledParagraph>
      <StyledHeaderDescription fontSize="m" fontWeight="regular" color="gray_5">
        {translate('auth:linkExpiredHeaderDescription')}
      </StyledHeaderDescription>
      <StyledIllustrationContainer>
        <StyledIllustration src={expiredImage} alt="Link Expired" />
      </StyledIllustrationContainer>
      {showResend ? (
        <StyledBottomDesc>
          <StyledParagraph>
            {translate('auth:linkExpiredInformation')}
          </StyledParagraph>
          <StyledResend
            color={countdown === 0 && resetOnPress ? 'primary_5' : 'gray_3'}
            cursor={countdown === 0 && resetOnPress ? 'pointer' : 'default'}
            onClick={
              countdown === 0 && resetOnPress
                ? () => {
                    resetOnPress && reset(300)
                    onResend && onResend()
                  }
                : undefined
            }
            fontWeight="medium">
            {countdown === 0 || !resetOnPress
              ? translate('auth:linkExpiredResend')
              : cooldownText
              ? translate(cooldownText, {
                  countdown:
                    countdown > 60
                      ? `${countDownMinute}m${countDownSecond}`
                      : `${countDownSecond}`,
                })
              : translate('auth:verificationPhoneResendCountdown', {
                  countdown:
                    countdown > 60
                      ? `${countDownMinute}m${countDownSecond}`
                      : `${countDownSecond}`,
                })}
          </StyledResend>
        </StyledBottomDesc>
      ) : (
        <></>
      )}
    </>
  )
}
