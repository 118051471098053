import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {requestData} from 'services'
import {
  getCount,
  showModalLimitedAccess,
  showModalReprocessKyc,
  showSnackbar,
  useDebounce,
  useHistory,
} from 'utils'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostButtonsProps} from './ForkygramTemplatePostButtonsProps'
import {ForkygramTemplatePostAudio} from '../Audio'
import {ForkygramTemplatePostButtonsItem} from '../ButtonsItem'
import {ForkygramLikeModalContent} from '../../LikeModal'
import {ForkygramCommentModalContent} from '../../CommentModal'

const StyledContentButtons = styled.div`
  position: absolute;
  right: 0;
  bottom: ${convertUnit(18)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`

const StyledMenuContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(8)};
  margin-right: ${convertUnit(8)};
`

export default function ForkygramTemplatePostButtons({
  data,
  mute,
  onPressMute,
  onClickMore,
  handleShowModal,
  allowMusic = false,
  postId,
  ownerId,
  photo,
  username,
  member_id,
  creator_status,
}: ForkygramTemplatePostButtonsProps) {
  const {
    is_official = false,
    likes_count,
    content_type,
    comment_count,
    is_liked,
  } = data
  const {access_token} = useSelector('user') || {}
  const [isLike, setIsLike] = useState<boolean>(is_liked)
  const [likeCount, setLikeCount] = useState<number>(likes_count || 0)
  const stateCommentCount = useState<number>(comment_count || 0)
  const commentCount = stateCommentCount[0]
  const [likeModal, setLikeModal] = useState<boolean>(false)
  const stateCommentModal = useState<boolean>(false)
  const setCommentModal = stateCommentModal[1]
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')
  const history = useHistory()

  const debounce = useDebounce()

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleClickLike = useCallback(() => {
    if (
      childStatus === 'UNDERAGE' ||
      !(kycStatus === 'verified' || kycStatus === 'verified_check')
    ) {
      handleFeatureDisabled()
      return
    }
    debounce(() => {
      setIsLike((prev) => !prev)
      if (isLike) {
        setLikeCount((prev) => prev - 1)
        requestData('forkygram_delete_like', {
          useDefaultMessage: true,
          actionType: 'execute',
          data: {post_id: postId},
          onRequestFailed: () => {
            setLikeCount((prev) => prev + 1)
            setIsLike((prev) => !prev)
          },
        })
      } else {
        setLikeCount((prev) => prev + 1)
        requestData('forkygram_post_like', {
          actionType: 'execute',
          data: {post_id: postId},
          onRequestFailed: () => {
            setLikeCount((prev) => prev - 1)
            setIsLike((prev) => !prev)
          },
          onRequestSuccess: ({status, data: {detail}}) => {
            if (status === 429 && detail) {
              setLikeCount((prev) => prev - 1)
              setIsLike((prev) => !prev)
              showSnackbar(
                detail.error.includes('day')
                  ? translate('forkygram:likeLimitPerDay')
                  : translate('forkygram:likeLimitPerHour'),
              )
            }
          },
        })
      }
    })
  }, [childStatus, kycStatus, debounce, handleFeatureDisabled, isLike, postId])

  const handleClickLikeLabel = useCallback(() => {
    if (!access_token) {
      handleShowModal()
      return
    }
    if (childStatus === 'UNDERAGE') {
      handleFeatureDisabled()
      return
    }
    setLikeModal(true)
  }, [access_token, childStatus, handleFeatureDisabled, handleShowModal])

  const handleClickComment = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      handleFeatureDisabled()
      return
    }
    setCommentModal(true)
  }, [childStatus, handleFeatureDisabled, setCommentModal])

  const handleCloseLikeModal = useCallback(() => {
    setLikeModal(false)
  }, [])

  const handleCloseCommentModal = useCallback(() => {
    setCommentModal(false)
  }, [setCommentModal])

  const handleRenderLikeModal = useMemo(
    () => (
      <ForkygramLikeModalContent
        postId={postId}
        visible={likeModal}
        toggleModal={handleCloseLikeModal}
      />
    ),
    [handleCloseLikeModal, likeModal, postId],
  )

  const handleRenderCommentModal = useMemo(
    () => (
      <ForkygramCommentModalContent
        data={data}
        username={username}
        photo={photo}
        member_id={member_id}
        creator_status={creator_status}
        ownerId={ownerId}
        postId={postId}
        stateVisible={stateCommentModal}
        toggleModal={handleCloseCommentModal}
        stateCommentCount={stateCommentCount}
      />
    ),
    [
      creator_status,
      data,
      handleCloseCommentModal,
      member_id,
      ownerId,
      photo,
      postId,
      stateCommentCount,
      stateCommentModal,
      username,
    ],
  )

  const handleRenderVideoMenu = useMemo(
    () =>
      (content_type === 'video' || allowMusic) && (
        <ForkygramTemplatePostAudio mute={mute} onPressMute={onPressMute} />
      ),
    [allowMusic, content_type, mute, onPressMute],
  )

  const handleRenderMenu = useMemo(
    () => (
      <StyledMenuContainer>
        <ForkygramTemplatePostButtonsItem
          icon={isLike ? 'like-filled' : 'like-outlined'}
          label={likeCount === 0 ? '0' : getCount(likeCount)}
          onClickIcon={access_token ? handleClickLike : handleShowModal}
          onClickLabel={handleClickLikeLabel}
          isOverlay={false}
        />
        <ForkygramTemplatePostButtonsItem
          icon="comment"
          label={commentCount === 0 ? '0' : getCount(commentCount)}
          onClickIcon={handleClickComment}
          onClickLabel={handleClickComment}
          isOverlay={false}
        />
        {!is_official && onClickMore && (
          <ForkygramTemplatePostButtonsItem
            icon="more-filled"
            onClickIcon={onClickMore}
            isOverlay={false}
          />
        )}
        {handleRenderVideoMenu}
      </StyledMenuContainer>
    ),
    [
      access_token,
      commentCount,
      handleClickComment,
      handleClickLike,
      handleClickLikeLabel,
      handleRenderVideoMenu,
      handleShowModal,
      isLike,
      is_official,
      likeCount,
      onClickMore,
    ],
  )

  return (
    <StyledContentButtons>
      {handleRenderCommentModal}
      {handleRenderLikeModal}
      {handleRenderMenu}
    </StyledContentButtons>
  )
}
