import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {useDidMount, useHistory} from 'utils'
import {Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemReminderWishlistProps {
  payload: FirebaseMessageRoomItemPayload['reminder_wishlist']
}

export default function GiftShopMessageRoomContentItemReminderWishlist({
  payload: {contents},
}: GiftShopMessageRoomContentItemReminderWishlistProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!
  const [data, setData] = useState<GiftShopContentData[]>([])
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) =>
          status === 200 &&
          setData(result.map((item) => ({...item, is_wishlist: true}))),
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <Paragraph fontSize="m">
        {translate('messages:reminderWishlist', {
          username,
        })}
      </Paragraph>
    ),
    [username, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={() => history.push('giftshop_feed', {})}
      />
    ),
    [data, history],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
    </>
  )
}
