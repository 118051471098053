import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AUTH_PASSWORD_MIN_LENGTH, REGEX_PASSWORD} from 'consts'
import {requestData} from 'services'
import {WindowModeType} from 'types'
import {showPrompt, showSnackbar} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Input, TextValidationGroup} from 'common/components'
import {VALIDATION_CREATE_PASSWORD} from 'common/validations'
import convertUnit from 'lib/unit'
import {GiftShopEditProfileResetPasswordProps} from './GiftShopEditProfileResetPasswordProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledScreenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledContainer = styled.div<StyledContainerProps>`
  ${({mode, theme}) => ({
    backgroundColor: theme.white_1,
    width: mode === 'website' ? convertUnit(770) : '100%',
    padding: mode === 'mobile' ? convertUnit(25) : convertUnit(50),
    margin: mode === 'mobile' ? 0 : convertUnit(25),
  })}
  display: flex;
  flex-direction: column;
`

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
`

export default function GiftShopEditProfileResetPassword({
  token,
  stateSuccess,
}: GiftShopEditProfileResetPasswordProps) {
  const {translate} = useTranslation()
  const form = useForm<{password: string; confirm: string}>({
    defaultValues: {password: '', confirm: ''},
    mode: 'onBlur',
  })
  const {watch, trigger, errors} = form
  const values = watch()
  const {confirm, password} = watch()
  const [isValid, setIsValid] = useState<boolean>()
  const setSuccess = stateSuccess[1]
  const [loading, setLoading] = useState(false)
  const mode = useWindowMode()

  const handleTrigger = useCallback(() => trigger(), [trigger])
  const [submitted, setSubmitted] = useState(false)
  const disabled =
    !isValid ||
    password === '' ||
    confirm === '' ||
    Object.keys(errors).length > 0

  const handleResetPassword = useCallback(() => {
    setSubmitted(true)
    setLoading(true)
    requestData('auth_reset_password', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {code: token, password},
      onRequestReceived: () => setLoading(false),
      onRequestFailed: () => {
        showSnackbar(translate('giftShop:snackbarFailedExecute'))
        setSuccess(false)
      },
      onRequestSuccess: ({status}) => setSuccess(status === 200),
    })
  }, [token, password, translate, setSuccess])

  const handleRenderInputPasswordConfirm = useMemo(
    () => (
      <Input
        name="confirm"
        type="password"
        form={form}
        formRules={{
          validate: {
            matcher: () =>
              confirm && password && confirm !== password
                ? translate('auth:validationResetPasswordNotMatch')
                : true,
          },
        }}
        autoComplete={false}
        label={translate('auth:inputPasswordConfirmLabel')}
        placeholder={translate('auth:inputPasswordConfirmPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="visibility"
        onBlur={handleTrigger}
      />
    ),
    [confirm, form, password, translate, handleTrigger],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonLogin
        disabled={disabled}
        color={disabled ? 'gray_3' : undefined}
        label={translate('auth:resetPasswordButtonTitle')}
        onClick={handleResetPassword}
        isLoading={loading}
      />
    ),
    [disabled, translate, handleResetPassword, loading],
  )

  const handleRenderValidation = useMemo(
    () => (
      <TextValidationGroup
        text={values.password}
        validations={[
          {
            validation: (text) => text.length >= AUTH_PASSWORD_MIN_LENGTH,
            message: translate('auth:validationPasswordLength', {
              minLength: AUTH_PASSWORD_MIN_LENGTH,
            }),
          },
          {
            validation: REGEX_PASSWORD,
            message: translate('auth:validationPassword'),
          },
        ]}
        containerStyle={{marginTop: convertUnit(25)}}
        onChangeValidity={setIsValid}
      />
    ),
    [translate, values.password],
  )

  useEffect(() => {
    const prompt = (password.length > 0 || confirm.length > 0) && !submitted
    showPrompt(prompt, translate('giftShop:resetPasswordPromptMessage'))
  }, [confirm, password, password.length, submitted, translate])

  return (
    <StyledScreenContainer>
      <StyledContainer mode={mode}>
        <Input
          name="password"
          type="password"
          form={form}
          formRules={VALIDATION_CREATE_PASSWORD}
          autoComplete={false}
          label={translate('giftShop:newPassword')}
          placeholder={translate('auth:loginInputPasswordPlaceholder')}
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="visibility"
          onBlur={handleTrigger}
        />
        {handleRenderInputPasswordConfirm}
        {handleRenderValidation}
        {handleRenderButton}
      </StyledContainer>
    </StyledScreenContainer>
  )
}
