import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {ForkygramReportCategoriesResponse} from 'types'
import {showSnackbar} from 'utils'
import {Button, InputArea, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {ForkygramReportItem} from '../Item'
import {ForkygramReportContentProps} from './ForkygramReportContentProps'

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  width: 100%;
`

const StyledModal = styled(Modal)`
  width: 100%;
  padding: ${convertUnit(20)};
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function ForkygramReportContent({
  visible = false,
  onSubmit,
  toggleModal,
}: ForkygramReportContentProps) {
  const {access_token} = useSelector('user') || {}
  const {translate} = useTranslation()
  const [select, setSelect] = useState<
    ForkygramReportCategoriesResponse | undefined
  >(undefined)
  const [report, setReport] = useState<ForkygramReportCategoriesResponse[]>([])
  const form = useForm<{description: string}>({
    defaultValues: {
      description: '',
    },
  })
  const {watch} = form
  const {description} = watch()

  const handleGetReportReason = useCallback(() => {
    requestData('forkygram_get_report_comments_categories', {
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setReport(result)
      },
    })
  }, [])

  const reportCancel = useCallback(() => {
    showSnackbar(translate('forkygram:reportCommentCancel'))
    toggleModal()
  }, [toggleModal, translate])

  useEffect(() => {
    if (access_token && visible) {
      handleGetReportReason()
    }
  }, [access_token, handleGetReportReason, visible])

  useEffect(() => {
    if (!visible) {
      setSelect(undefined)
    }
  }, [visible])

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={reportCancel}
        />
        <StyledButton
          disabled={
            !select || (select.name === 'Other' && description.length === 0)
          }
          label={translate('global:submit')}
          onClick={() => select && onSubmit(select, description)}
        />
      </StyledButtonContainer>
    ),
    [description, onSubmit, select, reportCancel, translate],
  )

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('global:reportContent')}
      </StyledTitle>

      <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
        {translate('global:reportReason')}
      </Paragraph>

      {report.map((item) => (
        <ForkygramReportItem
          key={item.id}
          reason={translate('forkygram:reportComment', {
            context: item.name.toUpperCase(),
          })}
          isSelected={select?.id === item.id}
          onSelect={() => setSelect(item)}
        />
      ))}
      {select?.name === 'Other' && (
        <InputArea
          form={form}
          name="description"
          placeholder={translate('forkygram:reportOtherPlaceholder')}
          maxLength={300}
          inputContainerStyle={{height: convertUnit(100)}}
        />
      )}

      {handleRenderButtons}
    </StyledModal>
  )
}
