import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopPatchNicknameBody} from 'types'
import {
  AUTH_NICKNAME_MAX_LENGTH,
  GIFT_SHOP_PROFILE_CHANGE_USERNAME_LIMIT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import {Button, Icon, Input, Paragraph} from 'common/components'
import {
  VALIDATION_AUTH_LOGIN_PASSWORD,
  VALIDATION_GIFTSHOP_CHANGE_NICKNAME,
} from 'common/validations'
import {requestData} from 'services'
import {formatCooldownTime, showPrompt, showSnackbar} from 'utils'
import {GiftShopEditProfileLimitPasswordModal} from '../Modal'
import {GiftShopFormPropsBase} from './GiftShopFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${convertUnit(20)};
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(480)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledDisclaimerContainer = styled.div`
  background-color: ${({theme}) => theme.white_3};
  display: flex;
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
  border-radius: ${convertUnit(30)};
`

export default function GiftShopNicknameForm({
  stateActiveContent,
}: GiftShopFormPropsBase) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {nickname: currentNickname = '', username = ''} =
    useSelector('user') || {}
  const [loading, setLoading] = useState(false)
  const [publish, setPublish] = useState(false)
  const [passwordLimitModal, setPasswordLimitModal] = useState(false)
  const [cooldownTimer, setCooldownTimer] = useState('0')
  const setActiveContent = stateActiveContent[1]

  const form = useForm<GiftShopPatchNicknameBody>({
    defaultValues: {
      nickname: '',
      password: '',
    },
    reValidateMode: 'onChange',
  })
  const {errors, handleSubmit, setError, watch} = form
  const {nickname, password} = watch()

  const handleChangeNickname = useCallback(() => {
    setLoading(true)
    setPublish((prev) => !prev)
    requestData('giftshop_patch_nickname', {
      data: {nickname, password},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({data: {detail}, status}) => {
        if (status === 200) {
          update('user', {nickname})
          setActiveContent('profile')
          showSnackbar(translate('giftShop:changeNicknameSuccess'))
        } else if (status === 400) {
          if (detail?.nickname === 'your nickname is already taken') {
            setError('nickname', {
              type: 'manual',
              message: translate('auth:validationNicknameHasBeenUsed'),
            })
          } else if (detail?.attempt_left) {
            setError('password', {
              type: 'manual',
              message: translate('auth:validationLoginAttempt', {
                attempt: detail?.attempt_left,
              }),
            })
          }
        } else if (status === 423 && typeof detail !== 'undefined') {
          setCooldownTimer(detail?.wait_time)
          setPasswordLimitModal(true)
        } else if (status === 429 && typeof detail !== 'undefined') {
          const {day, hour, minute, second} = formatCooldownTime(
            detail.wait_time.split(' ')[2],
            true,
            true,
          )
          showSnackbar(
            translate('auth:resendLimitReached', {
              duration: day + hour + minute + second,
            }),
          )
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
    })
  }, [nickname, password, setActiveContent, setError, translate, update])

  const isButtonDisabled = useMemo(() => {
    const isValidNickname = errors.nickname !== undefined || nickname === ''
    const isValidPassword = errors.password !== undefined || password === ''

    return isValidNickname || isValidPassword
  }, [errors.nickname, errors.password, nickname, password])

  const handleRenderPasswordLimitModal = useMemo(
    () =>
      passwordLimitModal && (
        <GiftShopEditProfileLimitPasswordModal
          cooldownTimer={cooldownTimer}
          onConfirm={() => setPasswordLimitModal((prev) => !prev)}
        />
      ),
    [cooldownTimer, passwordLimitModal],
  )

  const handleRenderNickname = useMemo(
    () => (
      <Input
        name="nickname"
        form={form}
        formRules={VALIDATION_GIFTSHOP_CHANGE_NICKNAME(
          username,
          currentNickname,
        )}
        autoComplete={false}
        maxLength={AUTH_NICKNAME_MAX_LENGTH}
        rightIcon="delete"
        label={translate('giftShop:newNickname')}
        placeholder={translate('giftShop:newNicknamePlaceholder')}
      />
    ),
    [currentNickname, form, translate, username],
  )

  const handleRenderPassword = useMemo(
    () => (
      <Input
        type="password"
        name="password"
        form={form}
        formRules={VALIDATION_AUTH_LOGIN_PASSWORD}
        label={translate('global:password')}
        placeholder={translate('auth:loginInputPasswordPlaceholder')}
        containerStyle={{marginTop: convertUnit(20)}}
        rightIcon="visibility"
      />
    ),
    [form, translate],
  )

  const handleRenderDisclaimer = useMemo(
    () => (
      <StyledDisclaimerContainer>
        <Icon type="info" size={convertUnit(16)} />
        <Paragraph fontWeight="medium" lineHeight={21}>
          {translate('giftShop:changeNicknameWarning', {
            limit: GIFT_SHOP_PROFILE_CHANGE_USERNAME_LIMIT,
          })}
        </Paragraph>
      </StyledDisclaimerContainer>
    ),
    [translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={isButtonDisabled || loading}
        isLoading={loading}
        label={translate('global:save')}
        onClick={handleSubmit(handleChangeNickname)}
      />
    ),
    [handleChangeNickname, handleSubmit, isButtonDisabled, loading, translate],
  )

  useEffect(() => {
    const prompt = nickname !== '' || password !== ''
    showPrompt(!publish && prompt, translate('giftShop:uploadModalMessage'))
  }, [nickname, password, publish, translate])

  return (
    <StyledContainer>
      {handleRenderPasswordLimitModal}
      <StyledScreenContainer>
        {handleRenderNickname}
        {handleRenderPassword}
        {handleRenderDisclaimer}
        {handleRenderButton}
      </StyledScreenContainer>
    </StyledContainer>
  )
}
