import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {ThemeColor} from 'themes'
import {DropdownItemProps} from './DropdownItemProps'
import {Icon} from '../Icon'
import {Paragraph} from '../Paragraph'

interface StyledContainerProps {
  toggle: boolean
  borderColor: ThemeColor
  backgroundColor: ThemeColor
  backgroundToggleColor: ThemeColor
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, toggle, borderColor, backgroundToggleColor, backgroundColor}) => ({
    borderColor: theme[borderColor],
    backgroundColor: toggle
      ? theme[backgroundToggleColor]
      : theme[backgroundColor],
    ...(!toggle && {
      ':hover': {
        borderColor: theme.gray_2,
      },
    }),
  })}
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  padding: ${convertUnit(11)} ${convertUnit(16)};
  border-style: solid;
  border-width: ${convertUnit(1)};
  border-radius: ${convertUnit(8)};
  align-items: center;
  :hover {
    cursor: default;
  }
`

const StyledLabel = styled(Paragraph)`
  margin-right: ${convertUnit(10)};
`

export default function DropdownItem({
  label,
  toggle,
  borderColor = 'gray_1',
  backgroundColor = 'white_2',
  backgroundToggleColor = 'gray_1',
  itemColor = 'black',
  fontWeight = 'medium',
  ...props
}: DropdownItemProps) {
  return (
    <StyledContainer
      {...props}
      toggle={toggle}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      backgroundToggleColor={backgroundToggleColor}>
      <StyledLabel fontSize="m" fontWeight={fontWeight} color={itemColor}>
        {label}
      </StyledLabel>
      <Icon type="chevron-down" size={16} marginLeft="auto" color={itemColor} />
    </StyledContainer>
  )
}
