import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {getCurrencyValue, parseDate, useDidMount, useHistory} from 'utils'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'
import {
  getMessageContentIds,
  getMessagePrice,
  updateMessagePrice,
} from '../GiftshopMessageArrayHelper'

export interface GiftShopMessageRoomContentItemNotificationRefundProps {
  payload: FirebaseMessageRoomItemPayload['notification_refund_content']
}

const StyledExtraText = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};
`

export default function GiftShopMessageRoomContentItemNotificationRefund({
  payload: {
    contents,
    data: contentData,
    creator_username,
    purchased_at,
    collector_refund_amount: refundAmount,
  },
}: GiftShopMessageRoomContentItemNotificationRefundProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const contentIds = getMessageContentIds(contentData, contents)
  const prices = getMessagePrice(contentData)

  const handleLoadData = useCallback(
    () =>
      contents &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          result = updateMessagePrice(result, contentIds, prices)
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontWeight="bold" fontSize="m">
          {translate('messages:notificationRefundTitle')}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationRefund', {
            creator_username,
            refundAmount: getCurrencyValue(refundAmount),
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="s" color="gray_5">
          {purchased_at &&
            translate('global:purchaseDate', {
              purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY'),
            })}
        </Paragraph>
      </>
    ),
    [translate, creator_username, refundAmount, purchased_at],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderExtra = useMemo(
    () => (
      <>
        <StyledExtraText fontSize="m">
          {translate('messages:notificationRefundExtra')}
        </StyledExtraText>
        <Button
          label={translate('messages:buttonViewBalance')}
          onClick={() => history.push('giftshop_balance', {})}
        />
      </>
    ),
    [history, translate],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderExtra}
    </>
  )
}
