import React from 'react'
import {useTranslation} from 'i18n'
import {AuthRegisterWaitingModalProps} from './AuthRegisterWaitingModalProps'
import {TemplateSorryModalContent} from '../../../../template'

export default function AuthRegisterWaitingModal({
  visible,
  toggleModal,
}: AuthRegisterWaitingModalProps) {
  const {translate} = useTranslation()

  return (
    <TemplateSorryModalContent
      visible={visible}
      upperDesc={translate('auth:waitingModalInfo')}
      bottomDesc={translate('auth:waitingModalDescription')}
      toggleModal={toggleModal}
    />
  )
}
