import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {ThemeColor} from 'themes'
import {WindowModeType} from 'types'
import {useWindowLayout, useWindowMode} from 'windows'
import {GiftShopBannerReferralTemplate} from 'pages'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopRobopetActivationTemplateProps} from './GiftShopRobopetActivationTemplateProps'

interface StyledContentContainerProps {
  backgroundColor: ThemeColor
  maxWidth: number
  mode: WindowModeType
  bannerClosed: boolean
  fullWidth?: boolean
}

interface StyledContainerProps {
  bannerClosed: boolean
  fullWidth?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  align-items: center;
  height: 100%;
  padding-top: ${convertUnit(60)};
  padding-bottom: ${convertUnit(20)};
  padding-left: ${convertUnit(40)};
  padding-right: ${convertUnit(40)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    ${({fullWidth}) =>
      fullWidth
        ? {
            width: 'inherit',
            paddingTop: 0,
            paddingBottom: convertUnit(20),
            paddingLeft: convertUnit(20),
            paddingRight: convertUnit(20),
            flexDirection: 'column-reverse',
            gap: convertUnit(60),
          }
        : {
            paddingLeft: 0,
            paddingRight: 0,
          }}
  }
`

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  border-radius: ${convertUnit(28)};
  max-width: ${({mode, maxWidth, fullWidth}) =>
    mode === 'mobile'
      ? convertUnit(maxWidth)
      : fullWidth
      ? 'calc(100% - 48px)'
      : convertUnit(375)}};
  width: 100%;
  padding: ${convertUnit(24)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`

const StyledRowContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: ${convertUnit(100)};
`

const StyledPanthomImage = styled.div`
  width: ${convertUnit(180)};
`

const StyledBannerContainer = styled.div<StyledContentContainerProps>`
  width: 100%;
  visibility: ${({bannerClosed}) => (bannerClosed ? 'hidden' : 'visible')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({mode, maxWidth, fullWidth}) =>
    mode === 'mobile'
      ? convertUnit(maxWidth)
      : fullWidth
      ? 'calc(100% - 48px)'
      : convertUnit(375)}};
`

const StyledRobopetImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  position: absolute;
  bottom: ${convertUnit(0)};
  left: 0;
`

const StyledRobopetImage = styled(Image)`
  width: ${convertUnit(161)};
  height: ${convertUnit(174)};
`

export default function GiftShopRobopetActivationTemplate({
  children,
  backgroundColor = 'primary_5',
  robopetImg,
  title,
  titleColor,
  fullWidth = false,
  showBanner = true,
}: GiftShopRobopetActivationTemplateProps) {
  const img = IMAGE_ASSET('giftshop', robopetImg)
  const mode = useWindowMode()
  const {width} = useWindowLayout()
  const bannerClosed = useSelector('bannerClosed')

  const handleRenderTitle = useMemo(
    () => (
      <StyledRowContainer>
        <StyledPanthomImage />
        <StyledRobopetImageContainer>
          <StyledRobopetImage src={img} alt={img} />
        </StyledRobopetImageContainer>
        <Paragraph fontSize="xl" fontWeight="bold" color={titleColor}>
          {title}
        </Paragraph>
      </StyledRowContainer>
    ),
    [img, title, titleColor],
  )

  return (
    <StyledContainer bannerClosed={bannerClosed.roboyu} fullWidth={fullWidth}>
      <StyledContentContainer
        mode={mode}
        maxWidth={width - 100}
        backgroundColor={backgroundColor}
        bannerClosed={bannerClosed.roboyu}
        fullWidth={fullWidth}>
        {handleRenderTitle}
        {children}
      </StyledContentContainer>
      {showBanner && (
        <StyledBannerContainer
          mode={mode}
          maxWidth={width - 100}
          backgroundColor={backgroundColor}
          bannerClosed={bannerClosed.roboyu}
          fullWidth={fullWidth}>
          <GiftShopBannerReferralTemplate page="roboYu" />
        </StyledBannerContainer>
      )}
    </StyledContainer>
  )
}
