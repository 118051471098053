import {lazy} from 'react'

const GiftShopExploreScreen = lazy(
  () => import(/* webpackChunkName: 'Explore' */ './GiftShopExploreScreen'),
)

const GiftShopExploreDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'ExploreDetail' */ './GiftShopExploreDetailScreen'
    ),
)

export * from './GiftShopExploreScreenParam'
export {GiftShopExploreScreen, GiftShopExploreDetailScreen}
