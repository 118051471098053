export const POLICY_NAVBAR_ITEM_LABEL_WIDTH = 275
export const POLICY_ROOT_MENU_HEIGHT = 61
export const POLICY_ROOT_MENU_WIDTH = 312
export const POLICY_HEADER_HEIGHT = 41

export const DOMAIN_NAME = 'fotoyu.com'
export const COMPANY_NAME = 'Fotoyu'
export const WEBSITE_NAME = ' www.fotoyu.com'

export const CREATOR_PRICE_PERCENTAGE = '100%'
export const CREATOR_PRICE_EXAMPLE = '50,000'
export const HOST_FEE_PERCENTAGE = '10%'
export const HOST_FEE_EXAMPLE = '5,000'
export const FOTOYU_FEE_PERCENTAGE = '10%'
export const FOTOYU_FEE_EXAMPLE = '5,000'
export const NET_CREATOR_EXAMPLE = '45,000'
export const NET_CREATOR_PERCENTAGE = '90%'
export const NET_HOST_EXAMPLE = '40,000'
export const NET_HOST_PERCENTAGE = '80%'
