import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopExploreTabView,
  GiftShopRootEmpty,
  GiftShopTemplateMasonry,
} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {GiftShopCollectionContentProps} from './GiftShopCollectionContentProps'
import {GiftShopCollectionResendApprovalBanner} from '../ResendApprovalBanner'

const StyledMasonryContainer = styled.div`
  position: relative;
  height: 100%;
  flex: '1 1 auto';
`

const StyledEmptyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function GiftShopCollectionContent({
  stateCollectionData,
  stateSelect,
  stateSelectMode,
}: GiftShopCollectionContentProps) {
  const {translate} = useTranslation()
  const data = stateCollectionData[0]
  const history = useHistory()
  const [select, setSelect] = stateSelect
  const selectMode = stateSelectMode[0]
  const mode = useWindowMode()
  const tabViewVisible = useMemo(
    () => (data.length !== 0 ? !selectMode : false),
    [data.length, selectMode],
  )
  const emptyImg = IMAGE_ASSET('giftshop', 'empty.png')

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('giftshop_get_collection', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {page, limit, status: 'COMPLETED'},
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleTabView = useMemo(
    () =>
      tabViewVisible ? (
        <GiftShopExploreTabView selectedTab="collection" />
      ) : (
        <></>
      ),
    [tabViewVisible],
  )

  const handleClickItem = useCallback(
    (item: GiftShopContentData) => {
      selectMode
        ? setSelect((previous) =>
            previous.includes(item)
              ? previous.filter((value) => value.content_id !== item.content_id)
              : [...previous, item],
          )
        : history.push('giftshop_collection_detail', {
            data: [...data],
            selectedItemId: item.content_id,
          })
    },
    [data, history, selectMode, setSelect],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <GiftShopRootEmpty
          image={emptyImg}
          imageAlt={emptyImg}
          title={translate('giftShop:collectionEmptyTitle')}
          message={translate('giftShop:collectionEmptyDesc')}
        />
      </StyledEmptyContainer>
    ),
    [emptyImg, translate],
  )

  return (
    <>
      {handleTabView}
      {mode === 'website' && <GiftShopCollectionResendApprovalBanner />}
      <StyledMasonryContainer>
        <GiftShopTemplateMasonry
          cache="collection"
          showResBadge
          loadData={handleLoadData}
          onClickItem={handleClickItem}
          listEmptyElement={handleRenderEmpty}
          scrollbar={false}
          toggleSelect={selectMode}
          selected={select}
          stateData={stateCollectionData}
          onSelectRule={() => true}
        />
      </StyledMasonryContainer>
      {mode !== 'website' && <GiftShopCollectionResendApprovalBanner />}
    </>
  )
}
