import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {getFontFamily, getFontSize} from 'utils'
import {Image, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

export interface LegalCreatorPortalProps {
  scrollTo: (childRef: number | undefined) => void
}

interface StyledImageProps {
  width: number
  height: number
}

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledFullImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledImage = styled(Image)<StyledImageProps>`
  ${({width, height}) => ({
    width: convertUnit(width),
    height: convertUnit(height),
  })}
  margin-left: ${convertUnit(5)};
  margin-right: ${convertUnit(5)};
  object-fit: contain;
  border-radius: 5px;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalCreatorPortal({
  scrollTo,
}: LegalCreatorPortalProps) {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xxl" color="primary_5">
          {translate('policy:creator')}
        </StyledSubTitle>
        <StyledDescription>{translate('policy:creatorDesc')}</StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRecap = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorQuickRecap')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 13}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:creatorQuickRecap', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleCategory = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorCategory')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorCategoryDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorCategoryBasic')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorCategoryBasicDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorCategoryVerified')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorCategoryVerifiedDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorCategoryHost')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorCategoryHostDesc')}
          <StyledLink onClick={() => () => scrollTo(3)}>
            {translate('global:here')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [scrollTo, translate],
  )

  const handleHowItWorks = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creationEssential')}</StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorEssentialTransparency')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorEssentialTransparencyDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorEssentialFotoyuVest')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorEssentialFotoyuVestDesc')}
        </StyledDescription>
        <StyledFullImageContainer>
          <StyledImage
            src={IMAGE_ASSET('howitworks', '12.png')}
            alt="img"
            height={150}
            width={300}
          />
        </StyledFullImageContainer>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleUpload = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:creatorUploadContent')}
        </StyledSubTitle>
        <StyledListUl>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:creatorUploadContentWatermark')}
            </StyledSubTitle>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:creatorUploadContentWatermarkDesc')}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              <ParsedText parse={patterns}>
                {translate('policy:creatorUploadContentSell')}
              </ParsedText>
            </StyledSubTitle>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:creatorUploadContentSellDesc')}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
        </StyledListUl>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleResolution = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:creatorContentResolution')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorContentResolutionDesc')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleStorage = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorStorage')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorStorageDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleSingleAndBulk = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:creatorPhotoUpload')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorPhotoUploadSingleAndBulk')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorPhotoUploadSingleAndBulkDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorPhotoEditPrice')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorPhotoEditPriceStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:creatorPhotoEditPriceStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorPhotoEditFototree')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorPhotoEditFototreeStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:creatorPhotoEditFototreeStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:creatorPhotoDelete')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorPhotoDeleteStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:creatorPhotoDeleteStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:creatorPhotoDeleteDescBottom')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleSamePerson = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorSamePerson')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorSamePersonDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledFullImageContainer>
          <StyledImage
            src={IMAGE_ASSET('howitworks', '17.png')}
            alt="img"
            height={300}
            width={150}
          />
          <StyledImage
            src={IMAGE_ASSET('howitworks', '18.png')}
            alt="img"
            height={300}
            width={150}
          />
          <StyledImage
            src={IMAGE_ASSET('howitworks', '19.png')}
            alt="img"
            height={300}
            width={150}
          />
          <StyledImage
            src={IMAGE_ASSET('howitworks', '20.png')}
            alt="img"
            height={300}
            width={150}
          />
        </StyledFullImageContainer>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorSamePersonDescMiddle')}
          </ParsedText>
        </StyledDescription>
        <StyledImage
          src={IMAGE_ASSET('howitworks', '21.png')}
          alt="img"
          height={300}
          width={150}
        />
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorSamePersonDescBottom')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRenderDiscount = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorDiscount')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorDiscountDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:yuserDiscountClassification')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorDiscountClassificationDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(`policy:yuserDiscountClassificationDesc`, {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleAnonymosity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:creatorYuserAnonymity')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:creatorYuserAnonymityDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledDescription>
          {translate('policy:creatorYuserAnonymityMessage')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:creatorYuserAnonymityMessage', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleCompress = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:creatorAutoCompress')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorAutoCompressDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handlePayment = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:creatorPayment')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:creatorPaymentDesc')}
          <StyledLink
            onClick={() =>
              document
                .getElementById('pembayaran')
                ?.scrollIntoView({behavior: 'smooth'})
            }>
            {translate('global:here')}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleTitle}
      {handleRecap}
      {handleCategory}
      {handleHowItWorks}
      {handleUpload}
      {handleResolution}
      {handleCompress}
      {handleStorage}
      {handleSingleAndBulk}
      {handleSamePerson}
      {handleRenderDiscount}
      {handleAnonymosity}
      {handlePayment}
    </>
  )
}
