import React from 'react'
import { Link as LinkOrigin } from 'react-router-dom'
import { getQueryParam, getRoutePath } from 'utils'
import { LinkProps } from './LinkProps'

export default function Link({
  style,
  to,
  param,
  ...props
}: LinkProps) {
  return (
    <LinkOrigin
      {...props}
      to={
        param
          ? getRoutePath(to) + getQueryParam(param)
          : getRoutePath(to)
      }
      style={{
        ...style,
        textDecoration: 'inherit',
        color: 'inherit',
      }}
    />
  )
}
