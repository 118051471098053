import {PaymentCheckoutScreen} from 'pages'
import {RouteMap} from '../RouteMap'
import {RouteParamPayment} from './RouteParamPayment'

export const ROUTE_MAP_PAYMENT: RouteMap<RouteParamPayment> = {
  payment_checkout: {
    component: PaymentCheckoutScreen,
    path: '/checkout-screen',
    defaultState: null,
    permission: 'user',
  },
}
