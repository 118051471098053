import {useCallback, useMemo} from 'react'
import {requestData} from 'services'
import {GiftshopUploadContentData, VideoExtensionType} from 'types'
import {handleUploadSingleFileToWasabi} from 'utils'

export function useGiftShopResendVideoHandler() {
  const handleGetLink = useCallback(
    async (image: GiftshopUploadContentData, contentId: string) => {
      const {height, width, size, duration, extension} = image
      const vidExtension = extension as VideoExtensionType
      if (duration) {
        const response = await requestData('giftshop_post_resend_video_link', {
          data: {
            content_id: contentId,
            video: {
              duration: Math.round(duration * 1000),
              extension: vidExtension,
              height,
              size,
              width,
            },
            series: {
              total_size: size,
            },
          },
        })
        return typeof response !== 'string' && response.status === 200
          ? response.data.result.resend_link
          : null
      }
      return null
    },
    [],
  )

  const handlePublishToWasabi = useCallback(
    async (image: GiftshopUploadContentData, link: string) => {
      const {file, width, height, extension} = image
      const mime = `video/${extension}`
      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperty = useCallback(
    async (
      image: GiftshopUploadContentData,
      link: string,
      contentId: string,
    ) => {
      const {height, width, size, duration: vidDuration} = image
      const duration = vidDuration ? vidDuration * 1000 : undefined
      const customExif = {duration}

      const response = await requestData('giftshop_post_resend_video', {
        data: {
          content: link,
          content_id: contentId,
          exif: JSON.stringify(customExif),
          height,
          size,
          width,
        },
      })

      return typeof response !== 'string' && response.status === 200
    },
    [],
  )

  const handleUploadResend = useCallback(
    async (image: GiftshopUploadContentData, contentId: string) => {
      const link = await handleGetLink(image, contentId)
      const isSuccess =
        !!link &&
        (await handlePublishToWasabi(image, link)) &&
        (await handlePublishProperty(image, link, contentId))
      return isSuccess
    },
    [handleGetLink, handlePublishProperty, handlePublishToWasabi],
  )

  return useMemo(
    () => ({
      uploadResend: handleUploadResend,
    }),
    [handleUploadResend],
  )
}
