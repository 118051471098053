import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {
  BUTTON_PADDING_HORIZONTAL,
  BUTTON_PADDING_VERTICAL,
  BUTTON_SIZE_MAP,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {getBoxShadow, getPadding, getTextShadow} from 'utils'
import convertUnit from 'lib/unit'
import {ButtonProps} from './ButtonProps'
import {Paragraph} from '../Paragraph'
import {ActivityIndicator} from '../ActivityIndicator'

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledButton = styled.div<ButtonProps>`
  ${({
    theme,
    disableBackgroundColor = 'gray_1',
    disabled,
    isLoading,
    backgroundColor = 'primary_5',
    backgroundHoverColor = 'primary_4',
    backgroundLoadingColor = 'primary_4',
  }) =>
    disabled
      ? {
          backgroundColor: theme[disableBackgroundColor],
        }
      : {
          backgroundColor: theme[backgroundColor],
          ...(isLoading
            ? {backgroundColor: theme[backgroundLoadingColor]}
            : {
                ':hover': {
                  cursor: 'pointer',
                  backgroundColor: theme[backgroundHoverColor],
                },
              }),
        }}
  ${({
    theme,
    type = 'normal',
    rounded = true,
    hasLabelShadow,
    hasShadow,
    width,
  }) => ({
    width,
    height: BUTTON_SIZE_MAP[type],
    borderRadius: convertUnit(rounded ? BUTTON_SIZE_MAP[type] / 2 : 10),
    textShadow: hasLabelShadow ? getTextShadow(theme) : undefined,
    boxShadow: hasShadow
      ? getBoxShadow(theme, {
          horizontalOffset: 1,
          verticalOffset: 2,
          blurRadius: 8,
          opacity: 0.15,
        })
      : undefined,
  })}
  padding: ${getPadding(BUTTON_PADDING_VERTICAL, BUTTON_PADDING_HORIZONTAL)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${({width}) => width};
  user-select: none;
  transition: background-color 0.15s ease-out;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
  }
`

export default function Button({
  label,
  color = 'white_1',
  disabledFontColor = 'gray_3',
  fontSize = 'm',
  fontWeight = 'bold',
  leftIcon,
  rightIcon,
  disabled,
  isLoading,
  onClick,
  children,
  ...props
}: ButtonProps) {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      !disabled && onClick && onClick(event)
    },
    [disabled, onClick],
  )

  const handleRenderContent = useMemo(
    () =>
      children && typeof children !== 'string' ? (
        children
      ) : (
        <StyledParagraph
          color={disabled ? disabledFontColor : color}
          fontSize={fontSize}
          fontWeight={fontWeight}>
          {children || label}
        </StyledParagraph>
      ),
    [children, color, disabled, disabledFontColor, fontSize, fontWeight, label],
  )

  const handleRenderChild = useMemo(
    () =>
      isLoading ? (
        <ActivityIndicator data-testid="btn-loading" iconColor="white_1" />
      ) : (
        handleRenderContent
      ),
    [isLoading, handleRenderContent],
  )

  return (
    <StyledButton
      data-testid="button"
      {...props}
      label={label}
      disabled={disabled}
      isLoading={isLoading}
      onClick={handleClick}>
      {leftIcon}
      {handleRenderChild}
      {rightIcon}
    </StyledButton>
  )
}
