import {useMemo} from 'react'
import {
  ListMasonryBatchData,
  ListMasonryPaginationAction,
  ListPaginationMasonryActionParam,
} from 'types'
import {
  LIST_MASONRY_MAX_ITEM,
  LIST_MASONRY_MAX_RATIO,
  LIST_MASONRY_MAX_RATIO_PER_ITEM,
  LIST_MASONRY_MIN_ITEM,
  LIST_MASONRY_MIN_RATIO_PER_ITEM,
} from 'consts'
import {useListPaginationAction} from './ListPaginationHooks'

export function useListMasonryPaginationAction<ItemT, S>({
  loadLayout,
  minItem = LIST_MASONRY_MIN_ITEM,
  maxItem = LIST_MASONRY_MAX_ITEM,
  maxRatio = LIST_MASONRY_MAX_RATIO,
  minRatioPerItem = LIST_MASONRY_MIN_RATIO_PER_ITEM,
  maxRatioPerItem = LIST_MASONRY_MAX_RATIO_PER_ITEM,
  ...params
}: ListPaginationMasonryActionParam<ItemT, S>) {
  const {items, ...action} = useListPaginationAction(params)

  const batch = useMemo(() => {
    const values: ListMasonryBatchData<ItemT>[] = []
    let index = 0
    let count = 0
    let sum = 0

    for (const item of items) {
      const {width, height} = loadLayout(item)

      if (width && height) {
        const ratio = Math.max(
          minRatioPerItem,
          Math.min(maxRatioPerItem, width / height),
        )

        if ((count >= minItem && sum + ratio > maxRatio) || count >= maxItem) {
          index += 1
          count = 0
          sum = 0
        }

        count += 1
        sum += ratio

        if (!values[index]) {
          values[index] = {ratio: sum, items: []}
        }

        values[index].ratio = sum
        values[index].items.push({ratio, item})
      }
    }

    return values
  }, [
    items,
    loadLayout,
    minRatioPerItem,
    maxRatioPerItem,
    minItem,
    maxRatio,
    maxItem,
  ])

  return useMemo<ListMasonryPaginationAction<ItemT>>(
    () => ({
      ...action,
      batch,
      items,
    }),
    [action, batch, items],
  )
}
