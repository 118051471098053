import { PromptHandler } from 'types'

let handler: PromptHandler | undefined

export function setPromptHandler(instance: PromptHandler) {
  handler = instance
}

export function showPrompt(when: boolean, description: string) {
  handler && handler.showPrompt(when, description)
}
