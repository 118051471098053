import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {parseDate, useDidMount, useHistory} from 'utils'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemNotificationMakeItFreeProps {
  payload: FirebaseMessageRoomItemPayload['notification_make_it_free_content']
}

const StyledExtraText = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
  margin-bottom: ${convertUnit(20)};
`

export default function GiftShopMessageRoomContentItemNotificationMakeItFree({
  payload: {contents, creator_username, purchased_at},
}: GiftShopMessageRoomContentItemNotificationMakeItFreeProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      contents &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontWeight="bold" fontSize="m">
          {translate('messages:notificationRefundTitle')}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationMakeItFree', {
            creator_username,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="s" color="gray_5">
          {purchased_at &&
            translate('global:purchaseDate', {
              purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY'),
            })}
        </Paragraph>
      </>
    ),
    [translate, creator_username, purchased_at],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        showRefundAmount
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderExtra = useMemo(
    () => (
      <>
        <StyledExtraText fontSize="m">
          {translate('messages:notificationRefundExtra')}
        </StyledExtraText>
        <Button
          label={translate('messages:buttonViewBalance')}
          onClick={() => history.push('giftshop_balance', {})}
        />
      </>
    ),
    [history, translate],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderExtra}
    </>
  )
}
