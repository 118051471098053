import React from 'react'
import {useTranslation} from 'i18n'
import {ConfirmationModal} from 'common/components'
import {GiftShopTemplateZeroPriceModalProps} from './GiftShopTemplateZeroPriceModalProps'

export default function GiftShopTemplateZeroPriceModal({
  visible,
  onConfirm,
  toggleModal,
  onCancel,
}: GiftShopTemplateZeroPriceModalProps) {
  const {translate} = useTranslation()

  return (
    <ConfirmationModal
      visible={visible}
      title={translate('giftShop:uploadPriceZeroModalTitle')}
      text={translate('giftShop:uploadPriceZeroModalDescription')}
      onConfirm={onConfirm}
      toggleModal={() => {
        toggleModal()
        onCancel && onCancel()
      }}
      confirmText={translate('global:yesImSure')}
      cancelText={translate('giftShop:uploadPriceZeroModalButtonCancel')}
    />
  )
}
