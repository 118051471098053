import {DownTimeModalHandler} from 'types'

let handler: DownTimeModalHandler | undefined

export function setDownTimeModalHandler(instance: DownTimeModalHandler) {
  handler = instance
}

export function showDownTimeModal(visible: boolean) {
  handler && handler.showDownTimeModal(visible)
}
