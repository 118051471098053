import React, {useCallback, useMemo} from 'react'
import {Size} from 'react-virtualized-auto-sizer'
import {GiftShopContentData} from 'types'
import {
  GIFT_SHOP_ROOT_CONTENT_PADDING,
  LIST_MASONRY_PAGINATION_LOAD_LIMIT,
} from 'consts'
import {ListMasonryPagination} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateMasonryItem} from '../MasonryItem'
import GiftShopTemplatePagination from './GiftShopTemplatePagination'
import {GiftShopTemplateMasonryPaginationProps} from './GiftShopTemplateMasonryPaginationProps'

export default function GiftShopTemplateMasonryPagination({
  stateData,
  refresh,
  toggleSelect = false,
  showResBadge = false,
  selected,
  cache,
  onClickItem,
  search,
  scrollbar,
  showPurchaseStatusBadge,
  showUnwishlistBadge,
  showFoundConfirmed,
  isSelected,
  page,
  defaultPage,
  dataCount = 0,
  setPage,
  movePage,
  onSelectRule,
  ...rest
}: GiftShopTemplateMasonryPaginationProps) {
  const pageCount = useMemo(
    () => Math.ceil(dataCount / LIST_MASONRY_PAGINATION_LOAD_LIMIT),
    [dataCount],
  )

  const handleLoadLayout = useCallback(
    (item: GiftShopContentData) => item.resolution,
    [],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <GiftShopTemplateMasonryItem
        showResBadge={showResBadge}
        item={item}
        toggleSelect={toggleSelect}
        isSelected={
          isSelected ? isSelected(item.content_id) : selected?.includes(item)
        }
        onClickItem={onClickItem}
        showPurchaseStatusBadge={showPurchaseStatusBadge}
        showUnwishlistBadge={showUnwishlistBadge}
        showFoundConfirmed={showFoundConfirmed}
        onSelectRule={onSelectRule}
      />
    ),
    [
      showResBadge,
      toggleSelect,
      isSelected,
      selected,
      onClickItem,
      showPurchaseStatusBadge,
      showUnwishlistBadge,
      showFoundConfirmed,
      onSelectRule,
    ],
  )

  const handleRenderMasonry = useCallback(
    ({height, width}: Size) => (
      <ListMasonryPagination
        {...rest}
        refresh={refresh}
        cache={cache}
        stateData={stateData}
        loadLayout={handleLoadLayout}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        width={width}
        height={height}
        style={{
          padding: convertUnit(GIFT_SHOP_ROOT_CONTENT_PADDING),
        }}
        scrollbar={scrollbar}
        search={search}
        page={page}
        pageCount={pageCount}
        defaultPage={defaultPage}
      />
    ),
    [
      rest,
      refresh,
      cache,
      stateData,
      handleLoadLayout,
      handleKeyExtractor,
      handleRenderItem,
      scrollbar,
      search,
      page,
      pageCount,
      defaultPage,
    ],
  )

  return (
    <GiftShopTemplatePagination
      {...rest}
      movePage={movePage}
      page={page}
      setPage={setPage}
      dataCount={dataCount}
      defaultPage={defaultPage}
      renderMain={handleRenderMasonry}
    />
  )
}
