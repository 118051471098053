import {GIFT_SHOP_UPLOAD_TYPE} from 'consts'
import React, {ChangeEventHandler, useCallback} from 'react'
import styled from 'styled-components'
import {InputPickerProps} from './InputPickerProps'

const StyledInput = styled.input`
  display: none;
`

export default function InputPicker({
  inputRef,
  accept = GIFT_SHOP_UPLOAD_TYPE.join(', '),
  onChange,
  onLoadFiles,
  ...props
}: InputPickerProps) {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const picker = inputRef.current

      onChange && onChange(event)

      if (picker?.files) {
        const {files} = picker
        onLoadFiles(Array.from(files))
        picker.value = ''
      }
    },
    [inputRef, onChange, onLoadFiles],
  )

  return (
    <StyledInput
      {...props}
      type="file"
      ref={inputRef}
      accept={accept}
      onChange={handleChange}
    />
  )
}
