import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {Paragraph} from 'common/components'

export interface GiftShopMessageRoomContentItemNotificationAcceptInvitationCreatorProps {
  payload: FirebaseMessageRoomItemPayload['notification_accept_invitation_creator']
}

export default function GiftShopMessageRoomContentItemNotificationAcceptInvitationCreator({
  payload: {host_tag, username, creator_type},
}: GiftShopMessageRoomContentItemNotificationAcceptInvitationCreatorProps) {
  const {translate} = useTranslation()

  return (
    <>
      <Paragraph fontSize="m">
        {translate('messages:hiUsername', {username})}
      </Paragraph>
      <br />
      <Paragraph fontSize="m">
        {translate(
          'messages:notificationCreatorOfficialCreatorInvitationAccepted',
          {
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          },
        )}
      </Paragraph>
    </>
  )
}
