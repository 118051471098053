import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE, REGEX_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import {TransactionCurrencyData} from 'types'
import {
  DropdownItem,
  Input,
  Label,
  List,
  ListItemSelect,
  Menu,
  Paragraph,
} from 'common/components'
import {VALIDATION_GIFTSHOP_EDIT_BULK_PRICE} from 'common/validations'
import convertUnit from 'lib/unit'
import {GiftShopProfileEditBulkModalFormPriceProps} from './GiftShopProfileEditBulkModalProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(25)};
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`

const StyledCurrencyDropdownContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledError = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(4)};
  gap: ${convertUnit(4)};
`

export default function GiftShopProfileEditBulkModalFormPrice({
  currencyData,
  form,
  maxPrice,
  minPrice,
  notVerifiedCreator,
  onClickLearnMore,
}: GiftShopProfileEditBulkModalFormPriceProps) {
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {price, currencyName} = watch()
  const currencyStateToggle = useState(false)
  const [toggle, setToggle] = currencyStateToggle

  const handleSelectCurrency = useCallback(
    (item: TransactionCurrencyData) => {
      setToggle((prev) => !prev)
      setValue('currencyName', item.currency)
      setValue('currencyId', item.id)
    },
    [setToggle, setValue],
  )

  const handleRenderCurrencyDropdownItem = useCallback(
    (item: TransactionCurrencyData) => (
      <ListItemSelect
        title={item.currency}
        onClick={() => handleSelectCurrency(item)}
      />
    ),
    [handleSelectCurrency],
  )

  const handleRenderPriceLimitError = useMemo(
    () =>
      notVerifiedCreator &&
      parseInt(price, 10) > GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE ? (
        <StyledError>
          <Paragraph fontSize="s" fontWeight="bold" color="danger_5">
            {translate('giftShop:creatorPriceLimitErrorMessage')}
            <Paragraph
              fontSize="s"
              fontWeight="bold"
              color="primary_5"
              className="pressable"
              onClick={onClickLearnMore}>
              {translate('giftShop:creatorPriceLimitErrorMessageContinue')}
            </Paragraph>
          </Paragraph>
        </StyledError>
      ) : (
        <></>
      ),
    [notVerifiedCreator, onClickLearnMore, price, translate],
  )

  return (
    <StyledContainer>
      <StyledPriceContainer>
        <Label
          label={translate('global:currency')}
          containerStyle={{height: convertUnit(27.4)}}>
          <StyledCurrencyDropdownContainer>
            <Menu
              stateToggle={currencyStateToggle}
              contentContainerStyle={{
                height: convertUnit(96),
                borderRadius: convertUnit(8),
              }}
              renderContent={
                <List
                  data={currencyData}
                  keyExtractor={(item) => item.id}
                  renderItem={handleRenderCurrencyDropdownItem}
                />
              }>
              <DropdownItem
                label={currencyName}
                toggle={toggle}
                onClick={(e) => {
                  e.preventDefault()
                  setToggle((previous) => !previous)
                }}
              />
            </Menu>
          </StyledCurrencyDropdownContainer>
        </Label>
        <Input
          name="price"
          min={minPrice}
          max={maxPrice}
          defaultValue={price}
          form={form}
          formRules={VALIDATION_GIFTSHOP_EDIT_BULK_PRICE(minPrice, maxPrice)}
          label={translate('global:basicPrice')}
          allowedCharacters={REGEX_NUMBER}
          autoComplete={false}
          placeholder={translate('giftShop:uploadInputPricePlaceholder')}
          containerStyle={{
            marginLeft: '25px',
            flex: 1,
          }}
          rightIcon="delete"
          onRightIconClick={() => {
            setValue('price', '0')
          }}
          onChangeText={(text) => {
            if (price.startsWith('0')) setValue('price', '0')
            else setValue('price', text)
          }}
        />
      </StyledPriceContainer>
      {handleRenderPriceLimitError}
    </StyledContainer>
  )
}
