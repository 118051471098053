import {lazy} from 'react'

const TreeEventsScreen = lazy(
  () => import(/* webpackChunkName: 'TreeEvents' */ './TreeEventsScreen'),
)

const TreeEventsBIBMasonryScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TreeEventsBIBMasonry' */ './TreeEventsBIBMasonryScreen'
    ),
)

const TreeEventsBIBContentDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'TreeEventsBIBDetail' */ './TreeEventsBIBContentDetailScreen'
    ),
)

export * from './TreeEventsBIBMasonryScreenParam'
export * from './TreeEventsBIBContentDetailScreenParam'

export {
  TreeEventsScreen,
  TreeEventsBIBMasonryScreen,
  TreeEventsBIBContentDetailScreen,
}
