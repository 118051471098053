import {lazy} from 'react'

const GiftShopFavoriteContentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'FavoriteContent' */ './GiftShopFavoriteContentScreen'
    ),
)

const GiftShopFavoriteDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'FavoriteDetail' */ './GiftShopFavoriteDetailScreen'
    ),
)

export * from './GiftShopFavoriteDetailScreenParam'

export {GiftShopFavoriteContentScreen, GiftShopFavoriteDetailScreen}
