import {QueryParam, RoutePath, Routes} from 'types'
import {ROUTE_MAP} from '../../routes/RouteMap'

export function getRoute<K extends Routes>(key: K) {
  return ROUTE_MAP[key]
}

export function getQueryParam(queryParam: QueryParam) {
  const res: string[] = []

  Object.keys(queryParam).forEach((key) =>
    res.push(`?${key}=${queryParam[key]}`),
  )

  return res.toString().replaceAll(',', '')
}

export function getRoutePath(path: RoutePath) {
  return typeof path === 'string'
    ? getRoute(path).path
    : getRoute(path.key).path + getQueryParam(path.param)
}

/**
 * Determines if the current page can navigate back to a previous page.
 *
 * @experimental
 * The `window.navigation.canGoBack` property is still experimental and may not be fully supported
 * across all browsers, so it fallback to using the `window.history.length` check. See:
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigation/canGoBack}.
 *
 * @returns boolean
 */
export function isCanGoBack(): boolean {
  const {navigation} = window as any

  if (
    navigation &&
    typeof navigation === 'object' &&
    'canGoBack' in navigation &&
    typeof navigation.canGoBack === 'boolean'
  ) {
    return navigation.canGoBack
  }
  return window.history.length > 1
}
