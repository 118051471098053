import React from 'react'
import styled from 'styled-components'
import {Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionWithdrawResultModalTemplateProps} from './TransactionWithdrawResultModalTemplateProps '

const StyledModal = styled(Modal)`
  width: ${convertUnit(275)};
  padding: ${convertUnit(25)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const StyledTitleParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(16)};
  margin-bottom: ${convertUnit(10)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(25)};
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

export default function TransactionWithdrawResultModalTemplate({
  iconType,
  iconSize,
  iconColor,
  title,
  description,
  buttonLabel,
  onClickButton,
}: TransactionWithdrawResultModalTemplateProps) {
  return (
    <StyledModal visible>
      <Icon type={iconType} size={iconSize} color={iconColor} />
      <StyledTitleParagraph fontSize="xl" fontWeight="bold">
        {title}
      </StyledTitleParagraph>
      <StyledParagraph fontSize="l" fontWeight="regular">
        {description}
      </StyledParagraph>
      <StyledButton
        label={buttonLabel}
        fontWeight="medium"
        onClick={onClickButton}
      />
    </StyledModal>
  )
}
