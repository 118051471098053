import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {maskEmail, maskPhoneZero} from 'utils'
import {GiftShopTemplateVerificationMethodModalProps} from './GiftShopTemplateVerificationMethodModalProps'
import GiftShopTemplateVerificationMethodItem from './GiftShopTemplateVerificationMethodItem'

const StyledModal = styled(Modal)`
  padding: ${convertUnit(20)};
  width: 100%;
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(20)};
  text-align: center;
`

const StyledSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateVerificationMethodModal({
  stateVerificationMethod,
  modalStage,
  loading,
  toggleModal,
  onSelect,
}: GiftShopTemplateVerificationMethodModalProps) {
  const {translate} = useTranslation()
  const {email, phone_code, phone_number} = useSelector('user') || {}
  const [verificationMethod, setVerificationMethod] = stateVerificationMethod

  const emailMask = useMemo(() => {
    if (email) {
      return maskEmail(email)
    }
    return undefined
  }, [email])

  const phoneMask = useMemo(() => {
    if (phone_code && phone_number) {
      return `0${maskPhoneZero(phone_number).trimLeft()}`
    }
    return undefined
  }, [phone_code, phone_number])

  const handleRenderEmailSelection = useMemo(
    () =>
      modalStage === 'selection' &&
      emailMask && (
        <GiftShopTemplateVerificationMethodItem
          icon="email-ol"
          text={translate('giftShop:emailTo')}
          info={emailMask}
          selected={verificationMethod === 'email'}
          onClick={() => setVerificationMethod('email')}
        />
      ),
    [
      emailMask,
      modalStage,
      setVerificationMethod,
      translate,
      verificationMethod,
    ],
  )

  const handleRenderPhoneSelection = useMemo(
    () =>
      modalStage === 'selection-resend' &&
      phoneMask && (
        <GiftShopTemplateVerificationMethodItem
          icon="message-ol"
          text={translate('giftShop:textTo')}
          info={phoneMask}
          selected={verificationMethod === 'phone'}
          onClick={() => setVerificationMethod('phone')}
        />
      ),
    [
      modalStage,
      phoneMask,
      setVerificationMethod,
      translate,
      verificationMethod,
    ],
  )

  const handleRenderWhatsappSelection = useMemo(
    () =>
      phoneMask && (
        <GiftShopTemplateVerificationMethodItem
          icon="whatsapp-ol"
          text={translate('giftShop:whatsappTo')}
          info={phoneMask}
          selected={verificationMethod === 'whatsapp'}
          onClick={() => setVerificationMethod('whatsapp')}
        />
      ),
    [phoneMask, verificationMethod, setVerificationMethod, translate],
  )

  const handleRenderCancelBtn = useMemo(
    () => (
      <StyledButton
        label={translate('global:cancel')}
        color="primary_5"
        backgroundColor="white_2"
        backgroundHoverColor="white_3"
        onClick={toggleModal}
      />
    ),
    [toggleModal, translate],
  )

  const handleRenderSelectBtn = useMemo(
    () => (
      <StyledButton
        label={translate('global:select')}
        disabled={loading}
        onClick={onSelect}
      />
    ),
    [loading, onSelect, translate],
  )

  return (
    <StyledModal visible>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:selectVerificationMethod')}
      </Paragraph>
      <StyledSelectionContainer>
        {handleRenderWhatsappSelection}
        {handleRenderEmailSelection}
        {handleRenderPhoneSelection}
      </StyledSelectionContainer>
      <StyledButtonContainer>
        {handleRenderCancelBtn}
        {handleRenderSelectBtn}
      </StyledButtonContainer>
    </StyledModal>
  )
}
