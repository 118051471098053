import React, {Fragment, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {requestDataPayload} from 'services'
import {
  FontSizeType,
  GiftShopContentData,
  GiftShopFirstPurchaseHost,
  WindowModeType,
} from 'types'
import {clearUserCache, useDidMount, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopCartContentProps} from './GiftShopCartContentProps'
import {GiftShopCartContentEmpty} from '../ContentEmpty'
import {
  GiftShopCartContentHeader,
  GiftShopCartAvailableContentHeader,
} from '../ContentHeader'
import {GiftShopCartContentItem} from '../ContentItem'
import {GiftShopCartSummary} from '../Summary'

interface StyledContinueContainerProps {
  mode: WindowModeType
}

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 740;
  height: 100%;
  background-color: ${({theme}) => theme.white_1};
`

const StyledScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 740;
  height: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: ${convertUnit(25)};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }
`

const StyledHostHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.white_1};
`

const StyledHostText = styled(Paragraph)`
  padding-top: ${convertUnit(20)};
  padding-left: ${convertUnit(20)};
`

const StyledFooterPanthom = styled.div`
  height: ${convertUnit(100)};
`

const StyledContinueContainer = styled.div<StyledContinueContainerProps>`
  ${({theme, mode}) =>
    mode === 'website'
      ? {
          backgroundColor: theme.white_2,
          flex: 450,
          padding: convertUnit(50),
        }
      : {position: 'sticky', width: '100%', bottom: 0}}
`

export default function GiftShopCartContent({
  stateSelect,
}: GiftShopCartContentProps) {
  const [select, setSelect] = stateSelect
  const stateData = useState<readonly GiftShopContentData[]>([])
  const [firstPurchase, setFirstPurchase] = useState<
    GiftShopFirstPurchaseHost[]
  >([])
  const [data, setData] = stateData
  const mode = useWindowMode()
  const history = useHistory()
  const stateToggle = useState<boolean>(false)
  const {kycStatus} = useSelector('yuserActivationState')

  const handleLoadData = useCallback(async () => {
    const response = await requestDataPayload('giftshop_get_cart', {
      params: {page: 1, limit: 100},
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setFirstPurchase(result.available_first_purchase)
        }
      },
    })

    if (typeof response !== 'string' && response?.status === 200) {
      setData(
        response.data.result.data.map((item) => ({...item, is_cart: true})),
      )
    }
  }, [setData])

  const handleSelect = useCallback(
    (item: GiftShopContentData) =>
      setSelect((prev) =>
        !prev.some((v) => v.content_id === item.content_id)
          ? [...prev, item]
          : prev.filter((value) => value.content_id !== item.content_id),
      ),
    [setSelect],
  )

  const getSection = useMemo(
    () =>
      data.reduce((prev, curr) => {
        const host = curr.tags.find((v) => v.type === 'host')?.name || ''

        if (prev[host]) {
          prev[host].push(curr)
        } else {
          prev[host] = [curr]
        }

        return prev
      }, {} as Record<string, GiftShopContentData[]>),
    [data],
  )

  const firstPurchaseContent = useMemo(() => {
    if (!firstPurchase.length) return []

    const map: Record<string, GiftShopContentData> = {}

    for (const item of select) {
      const hostTag = item.tags.find((v) => v.type === 'host')?.name
      if (
        hostTag &&
        firstPurchase.some((v) => v.tag_name === hostTag) &&
        !map[hostTag]
      ) {
        map[hostTag] = item
      }
    }

    return Object.values(map)
  }, [firstPurchase, select])

  const totalPrice = useMemo(
    () =>
      select
        .filter(
          (item) =>
            !firstPurchaseContent.some((v) => v.content_id === item.content_id),
        )
        .reduce((prev, curr) => prev + curr.price, 0),
    [firstPurchaseContent, select],
  )

  const handleContinue = useCallback(() => {
    clearUserCache('cart')
    history.push('payment_checkout', {
      data: select,
      firstPurchase: firstPurchaseContent,
      totalPrice,
    })
  }, [firstPurchaseContent, history, select, totalPrice])

  const handleClickItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_cart_detail', {
        data: [...data],
        selectedItemId: item.content_id,
      })
    },
    [data, history],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id,
    [],
  )

  const handleRenderContentHeader = useMemo(
    () =>
      select.length || data.length ? (
        <GiftShopCartContentHeader
          stateData={stateData}
          stateSelect={stateSelect}
        />
      ) : null,
    [data.length, select.length, stateData, stateSelect],
  )

  const handleRenderFreeContentHeader = useMemo(
    () => <GiftShopCartAvailableContentHeader tagName={firstPurchase} />,
    [firstPurchase],
  )

  const handleHostHeader = useCallback(
    (name: string, size?: FontSizeType) => (
      <StyledHostHeaderContainer>
        <StyledHostText color="gray_5" fontWeight="medium" fontSize={size}>
          {name}
        </StyledHostText>
      </StyledHostHeaderContainer>
    ),
    [],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <GiftShopCartContentItem
        key={handleKeyExtractor(item)}
        isSelected={select.some((v) => v.content_id === item.content_id)}
        data={item}
        isFree={firstPurchaseContent.some(
          (v) => v.content_id === item.content_id,
        )}
        onSelect={() => handleSelect(item)}
        onClickItem={handleClickItem}
      />
    ),
    [
      handleKeyExtractor,
      select,
      firstPurchaseContent,
      handleClickItem,
      handleSelect,
    ],
  )

  const renderListSection = useCallback(() => {
    const hostTag = getSection
      ? Object.keys(getSection).filter((item) => item !== '')
      : []
    const creatorData = getSection[''] || []
    return (
      <StyledScrollContainer>
        {hostTag.length ? (
          handleHostHeader(translate('global:host'), 'l')
        ) : (
          <></>
        )}
        {getSection &&
          hostTag.map((tag) => (
            <Fragment key={tag}>
              {handleHostHeader(tag)}
              {getSection[tag].map(handleRenderItem)}
            </Fragment>
          ))}
        {creatorData.length ? (
          handleHostHeader(translate('giftShop:creator'), 'l')
        ) : (
          <></>
        )}
        {creatorData.map(handleRenderItem)}
      </StyledScrollContainer>
    )
  }, [getSection, handleHostHeader, handleRenderItem])

  const renderCustomList = useMemo(
    () =>
      (kycStatus === 'verified' || kycStatus === 'verified_check' || kycStatus === 'onhold') ? data.length > 0 ? renderListSection() : <GiftShopCartContentEmpty /> : <GiftShopCartContentEmpty />,
    [data.length, kycStatus, renderListSection],
  )
  const renderFooter = useMemo(
    () => mode !== 'website' && <StyledFooterPanthom />,
    [mode],
  )

  const handleRenderSummary = useMemo(
    () => (
      <StyledContinueContainer mode={mode}>
        <GiftShopCartSummary
          stateToggle={stateToggle}
          select={select}
          totalPrice={totalPrice}
          onContinue={handleContinue}
        />
      </StyledContinueContainer>
    ),
    [handleContinue, mode, select, stateToggle, totalPrice],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      <StyledContentContainer>
        {handleRenderContentHeader}
        {handleRenderFreeContentHeader}
        {renderCustomList}
        {renderFooter}
      </StyledContentContainer>
      {handleRenderSummary}
    </>
  )
}
