import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import convertUnit from 'lib/unit'
import {
  Button,
  CheckboxItem,
  ConfirmationModal,
  Paragraph,
} from 'common/components'
import {GiftShopBankDetailProps} from './GiftShopBankDetailProps'
import {GiftShopSetAsMainBankConfirmationModal} from '../Modal'

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${convertUnit(75)};
  max-width: ${convertUnit(600)};
  background-color: ${({theme}) => theme.white_1};
  align-self: center;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(25)};
  }
`

const StyledInfoContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  margin-bottom: ${convertUnit(15)};
`

const StyledInfoLabel = styled.div`
  margin-bottom: ${convertUnit(5)};
`

const StyledInfoValue = styled.div`
  margin-bottom: ${convertUnit(15)};
  justify-self: end;
  text-align: right;
`

export default function GiftShopBankDetail({
  accountId,
  accountName,
  accountNumber,
  bankDetail,
  branchName,
  isMainAccount,
  isOnlyAccount,
}: GiftShopBankDetailProps) {
  const {translate} = useTranslation()
  const [modalState, setModalState] = useState<
    'hidden' | 'action' | 'confirmation'
  >('hidden')
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false,
  )
  const [mainSelected, setMainSelected] = useState(false)
  const isAccountSetSuccessfulRef = useRef(true)

  const handleToggleMainAccount = useCallback(() => {
    requestData('giftshop_patch_bank_account', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        bank_account_id: accountId,
        main_status: !mainSelected,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          isAccountSetSuccessfulRef.current = true
        } else {
          isAccountSetSuccessfulRef.current = false
        }
        setModalState('confirmation')
      },
    })
  }, [mainSelected, accountId])

  const handleRemoveBankAccount = useCallback(() => {
    requestData('giftshop_delete_bank_account', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {id: accountId},
      onRequestReceived: () => setConfirmationModalVisible(false),

      onRequestSuccess: ({status}) => {
        status === 200 && location.reload()
      },
    })
  }, [accountId])

  useEffect(() => {
    isMainAccount && setMainSelected(true)
  }, [isMainAccount])

  const handleRenderInfo = useMemo(
    () => (
      <StyledInfoContainer>
        <StyledInfoLabel>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:accountName')}
          </Paragraph>
        </StyledInfoLabel>
        <StyledInfoValue>
          <Paragraph fontSize="m">{accountName}</Paragraph>
        </StyledInfoValue>
        <StyledInfoLabel>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:addBankInputAccountNumberLabel')}
          </Paragraph>
        </StyledInfoLabel>
        <StyledInfoValue>
          <Paragraph fontSize="m">{accountNumber}</Paragraph>
        </StyledInfoValue>
        <StyledInfoLabel>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:addBankInputBankNameLabel')}
          </Paragraph>
        </StyledInfoLabel>
        <StyledInfoValue>
          <Paragraph fontSize="m">{bankDetail.name}</Paragraph>
        </StyledInfoValue>
        <StyledInfoLabel>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:branchName')}
          </Paragraph>
        </StyledInfoLabel>
        <StyledInfoValue>
          <Paragraph fontSize="m">{branchName || '-'}</Paragraph>
        </StyledInfoValue>
      </StyledInfoContainer>
    ),
    [bankDetail, translate, accountNumber, branchName, accountName],
  )

  const renderModal = useMemo(() => {
    switch (modalState) {
      case 'action':
        return (
          <ConfirmationModal
            title={
              mainSelected
                ? translate('giftShop:modalTitleRemoveFromMain')
                : translate('giftShop:modalTitleSetAsMain')
            }
            text={
              mainSelected
                ? translate('giftShop:modalRemoveFromMain')
                : translate('giftShop:modalSetAsMain')
            }
            confirmText={translate('global:yesImSure')}
            onConfirm={handleToggleMainAccount}
            visible
            toggleModal={() => setModalState('hidden')}
          />
        )
      case 'confirmation':
        return (
          <GiftShopSetAsMainBankConfirmationModal
            isSuccessful={isAccountSetSuccessfulRef.current}
            action={mainSelected ? 'remove' : 'add'}
            onConfirm={() => {
              isAccountSetSuccessfulRef.current &&
                setMainSelected((previous) => !previous)
              setModalState('hidden')
            }}
          />
        )
    }
    return null
  }, [translate, modalState, mainSelected, handleToggleMainAccount])

  return (
    <StyledContainer>
      {renderModal}
      <ConfirmationModal
        title={translate('giftShop:modalTitleDeleteBankAccount')}
        text={translate('giftShop:modalDeleteBankAccount')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handleRemoveBankAccount}
        visible={confirmationModalVisible}
        toggleModal={() => setConfirmationModalVisible((prev) => !prev)}
      />
      {handleRenderInfo}
      {!isOnlyAccount && (
        <CheckboxItem
          containerStyle={{marginBottom: convertUnit(25)}}
          isSelected={mainSelected}
          onClick={() => setModalState('action')}
          description={translate('giftShop:setAsMainBankAccount')}
          fontSize="m"
          fontWeight="regular"
          color="black"
        />
      )}
      <Button
        label={translate('giftShop:deleteBankAccount')}
        color="primary_5"
        backgroundColor="white_2"
        backgroundHoverColor="white_3"
        style={{width: '100%'}}
        onClick={() => setConfirmationModalVisible((prev) => !prev)}
      />
    </StyledContainer>
  )
}
