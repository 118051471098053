import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  GIFT_SHOP_MAX_BIO_CHARACTER,
  REGEX_NO_SPACE,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {WebForm} from 'types'
import {showPrompt, showSnackbar} from 'utils'
import {Button, Input, ModalLoading} from 'common/components'
import {VALIDATION_GIFTSHOP_WEBSITE} from 'common/validations'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopFormPropsBase} from './GiftShopFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(50)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(770)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(25)};
`

export default function GiftShopWebForm({
  defaultValue,
  stateActiveContent,
}: GiftShopFormPropsBase) {
  const {translate} = useTranslation()
  const setActiveContent = stateActiveContent[1]
  const [loading, setLoading] = useState(false)
  const {update} = useDispatch()
  const [prompt, setPrompt] = useState(true)
  const biodata = useSelector('user')?.biodata

  const form = useForm<WebForm>({
    defaultValues: {
      web: defaultValue || '',
    },
  })
  const {watch, setValue, handleSubmit, errors} = form
  const {web} = watch()

  const handlePatchWebsite = useCallback(async () => {
    setLoading(true)
    await requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {website: web, bio: biodata},
      dataType: 'form-data',
      onRequestReceived: () => {
        setPrompt(false)
        setLoading(false)
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          update('user', {website: web})
          setActiveContent('profile')
        }
      },
    })
  }, [web, biodata, update, setActiveContent])

  const handleInputBio = useCallback(
    (text: string) => {
      if (web.length >= GIFT_SHOP_MAX_BIO_CHARACTER) {
        showSnackbar(
          translate('giftShop:bioMaxMessage', {
            count: GIFT_SHOP_MAX_BIO_CHARACTER,
          }),
        )
      } else setValue('web', text)
    },
    [setValue, translate, web.length],
  )

  useEffect(() => {
    showPrompt(
      web !== defaultValue && prompt,
      translate('giftShop:uploadModalMessage'),
    )
  }, [web, defaultValue, prompt, translate])

  return (
    <>
      <ModalLoading visible={loading} />
      <StyledContainer>
        <StyledScreenContainer>
          <Input
            name="web"
            form={form}
            defaultValue={defaultValue}
            placeholder={translate('giftShop:websiteLinkPlaceholder')}
            label={translate('giftShop:website')}
            allowedCharacters={REGEX_NO_SPACE}
            onChangeText={handleInputBio}
            formRules={VALIDATION_GIFTSHOP_WEBSITE}
            rightIcon="delete"
            labelDescription={translate('global:charCount', {
              count: web.length,
              max: GIFT_SHOP_MAX_BIO_CHARACTER,
            })}
            labelDescriptionColor={
              web.length === GIFT_SHOP_MAX_BIO_CHARACTER
                ? 'danger_5'
                : undefined
            }
            maxLength={GIFT_SHOP_MAX_BIO_CHARACTER}
          />
          <StyledButton
            disabled={web === defaultValue || Object.keys(errors).length > 0}
            label={translate('global:save')}
            onClick={handleSubmit(handlePatchWebsite)}
            isLoading={loading}
          />
        </StyledScreenContainer>
      </StyledContainer>
    </>
  )
}
