import {lazy} from 'react'

const TreeProfileScreens = lazy(
  () =>
    import(/* webpackChunkName: 'HostProfile' */ './TreeHostProfileScreens'),
)

const TreeHostProfileContentDetailScreens = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostProfile' */ './TreeHostProfileContentDetailScreens'
    ),
)

const TreeHostProfileCreationGroupDetailScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'HostCreation' */ './TreeHostProfileCreationGroupDetailScreen'
    ),
)

export {
  TreeProfileScreens,
  TreeHostProfileContentDetailScreens,
  TreeHostProfileCreationGroupDetailScreen,
}

export * from './TreeHostProfileContentDetailScreensParam'
export * from './TreeHostProfileCreationGroupDetailScreenParam'
