import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  FirebaseMessageRoomItemPayload,
  ForkygramPostData,
  parseShape,
} from 'types'
import {useHistory} from 'utils'
import {Paragraph, ParsedText, Image} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

export interface GiftShopMessageRoomContentItemNotificationCommentProps {
  payload: FirebaseMessageRoomItemPayload['notification_comment']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMessageContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

export default function GiftShopMessageRoomContentItemNotificationComment({
  payload: {
    comment,
    post_id,
    member_params,
    post_image_url,
    commented_member_id,
    username,
  },
}: GiftShopMessageRoomContentItemNotificationCommentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]
  const {id} = useSelector('user') || {}

  const patterns: parseShape[] = useMemo(
    () =>
      member_params &&
      member_params.map((member) => {
        const regex = new RegExp(`${member.id}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${member.username}`),
          onClick: () => {
            history.push(
              'giftshop_profile',
              {
                self: member.id === id,
                postData: {
                  member_id: member.id,
                  username: member.username,
                  photo: member.photo,
                },
              },
              member.username,
            )
          },
        }
      }),
    [history, id, member_params],
  )

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContiner>
        <StyledParagraph fontSize="m">
          <ParsedText parse={patterns}>{commented_member_id}</ParsedText>
          {translate('messages:notificationComment', {comment})}
        </StyledParagraph>
      </StyledMessageContiner>
    ),
    [patterns, commented_member_id, translate, comment],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImage
        src={post_image_url}
        alt="image"
        onClick={() => {
          history.push(
            'giftshop_profile_post_detail',
            {
              data,
              self: true,
              selectedItemId: post_id,
            },
            username,
          )
        }}
      />
    ),
    [data, history, post_id, post_image_url, username],
  )

  return (
    <StyledContainer>
      {handleRenderMessage}
      {handleRenderContent}
    </StyledContainer>
  )
}
