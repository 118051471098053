import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {getHowItWorksWebUrl} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeRegistrationBeAHostModalContentProps} from './TreeRegistrationBeAHostModalContentProps'
import {TreeRegistrationBeAHostModalContentItem} from '../BeAHostModalContentItem'

interface StyledModalProps {
  mode: WindowModeType
}

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledModal = styled(Modal)<StyledModalProps>`
  width: 100%;
  max-width: ${({mode}) => convertUnit(mode === 'mobile' ? 295 : 335)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
`

const StyledTitleContainer = styled(StyledColumn)`
  padding: ${convertUnit(20)} 0;
  text-align: center;
`

const StyledContentContainer = styled(StyledColumn)`
  padding: 0 ${convertUnit(20)};
`

const StyledBottomSection = styled(StyledColumn)`
  padding-top: ${convertUnit(4)};
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function TreeRegistrationBeAHostModalContent({
  visible,
  onClickHost,
  toggleModal,
}: TreeRegistrationBeAHostModalContentProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <Paragraph fontSize="xl" fontWeight="bold" color="primary_5">
          {translate('tree:beAHost')}
        </Paragraph>
      </StyledTitleContainer>
    ),
    [translate],
  )

  const handleRenderDescription = useMemo(
    () => (
      <StyledContentContainer>
        <TreeRegistrationBeAHostModalContentItem
          icon="hashtag"
          iconColor="primary_5"
          description={translate('tree:beAHostModalHashtagDesc')}
        />
        <TreeRegistrationBeAHostModalContentItem
          icon="cash"
          iconColor="success_5"
          description={translate('tree:beAHostModalRevenueDesc')}
        />
        <TreeRegistrationBeAHostModalContentItem
          icon="marker-radius"
          iconColor="danger_5"
          description={translate('tree:beAHostModalPlaceDesc')}
        />
      </StyledContentContainer>
    ),
    [translate],
  )

  const handleRenderReadFurther = useMemo(
    () => (
      <a href={getHowItWorksWebUrl()} target="__blank">
        <Paragraph fontSize="m" fontWeight="medium" color="primary_5">
          {translate('tree:readFurther')}
        </Paragraph>
      </a>
    ),
    [translate],
  )

  const handleRenderBeAHostButton = useMemo(
    () => (
      <StyledButton label={translate('tree:beAHost')} onClick={onClickHost} />
    ),
    [onClickHost, translate],
  )

  const handleRenderCancelButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:cancel')}
        color="primary_5"
        backgroundColor="white_2"
        onClick={toggleModal}
      />
    ),
    [toggleModal, translate],
  )

  const handleRenderBottomSection = useMemo(
    () => (
      <StyledBottomSection>
        {handleRenderReadFurther}
        {handleRenderBeAHostButton}
        {handleRenderCancelButton}
      </StyledBottomSection>
    ),
    [
      handleRenderBeAHostButton,
      handleRenderCancelButton,
      handleRenderReadFurther,
    ],
  )

  return (
    <StyledModal visible={visible} mode={mode}>
      <StyledContainer>
        {handleRenderTitle}
        {handleRenderDescription}
        {handleRenderBottomSection}
      </StyledContainer>
    </StyledModal>
  )
}
