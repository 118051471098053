import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemNotificationReceiveHiresProps {
  payload: FirebaseMessageRoomItemPayload['notification_receive_hires']
}

export default function GiftShopMessageRoomContentItemNotificationReceiveHires({
  payload: {content, creator_username},
}: GiftShopMessageRoomContentItemNotificationReceiveHiresProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const user = useSelector('user')
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      content &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: content},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [content],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:notificationReceiveHiresTitle', {
            collector_username: user?.username,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationReceiveHiresDescription', {
            creator_username,
          })}
        </Paragraph>
        <br />
      </>
    ),
    [creator_username, translate, user?.username],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <GiftShopMessageRoomContentList
          data={data}
          onClick={(item) =>
            history.push('giftshop_collection_detail', {
              data: [{...item, type: 'collection'}],
              selectedItemId: item.content_id,
              limitContent: true,
            })
          }
          dataCount={content.length}
          loading={loading}
        />
        <br />
      </>
    ),
    [content.length, data, history, loading],
  )

  const handleRenderButton = useMemo(
    () => (
      <Button
        label={translate('messages:buttonMyCollection')}
        onClick={() => history.push('giftshop_collection', {})}
      />
    ),
    [history, translate],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderButton}
    </>
  )
}
