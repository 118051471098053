import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useTheme} from 'themes'
import {Input, Paragraph} from 'common/components'
import {VALIDATION_GIFTSHOP_UPLOAD_REQUIRED} from 'common/validations'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormLocationProps} from './GiftShopTemplateUploadFormLocationProps'

const StyledError = styled(Paragraph)`
  ${({theme}) => ({color: theme.danger_5})}
  box-sizing: border-box;
  margin-top: ${convertUnit(7)};
  margin-bottom: ${convertUnit(2)};
`

export default function GiftShopTemplateUploadFormLocation({
  form,
  location,
  onAddLocation,
  toggleModal,
}: GiftShopTemplateUploadFormLocationProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const {errors, setValue} = form
  const {update} = useDispatch()

  const handleClickLocation = useCallback(() => {
    toggleModal()
    onAddLocation && onAddLocation(form)
    if (location) {
      update('lastUploadStatePhoto', {
        name: JSON.parse(location).name,
        latitude: JSON.parse(location).latitude,
        longitude: JSON.parse(location).longitude,
      })
      update('lastUploadStateVideo', {
        name: JSON.parse(location).name,
        latitude: JSON.parse(location).latitude,
        longitude: JSON.parse(location).longitude,
      })
    }
    setValue('location', location)
  }, [form, location, onAddLocation, setValue, toggleModal, update])

  return (
    <>
      <Input
        value={location && JSON.parse(location).name}
        form={form}
        formRules={VALIDATION_GIFTSHOP_UPLOAD_REQUIRED}
        label={translate('giftShop:uploadInputLocationLabel')}
        autoComplete={false}
        placeholder={translate('giftShop:uploadInputLocationPlaceholder')}
        containerStyle={{marginTop: '25px'}}
        inputContainerStyle={
          errors.location ? {borderColor: theme.danger_5} : undefined
        }
        rightIcon="delete"
        onClick={handleClickLocation}
        onRightIconClick={() => setValue('location', '')}
      />
      {errors.location && (
        <StyledError fontWeight="medium">{errors.location.message}</StyledError>
      )}
    </>
  )
}
