import {
  PAYMENT_CURRENCY_ID_DEFAULT,
  PAYMENT_CURRENCY_NAME_DEFAULT,
} from 'consts'
import {GiftShopUploadSeriesData, GiftShopUploadType} from 'types'
import {REDUX_STORE} from 'lib/redux'
import {getLocalDate} from '../../../dates'
import {getGiftshopLocationName} from '../GiftshopHelper'

export function getGiftshopUploadDefaultValues({
  data,
  uploadType,
  selectId,
  sendToFace = false,
}: {
  data: GiftShopUploadSeriesData
  uploadType: GiftShopUploadType
  selectId?: string
  sendToFace?: boolean
}) {
  const {id, images} = data
  const lastUpload =
    uploadType === 'video'
      ? REDUX_STORE.getState().lastUploadStateVideo
      : REDUX_STORE.getState().lastUploadStatePhoto
  const select = images.find((item) => item.id === selectId) || images[0]
  const {role} = REDUX_STORE.getState().hostState

  const {
    tags = [],
    price = '0',
    description = '',
    latitude: lastLatitude,
    longitude: lastLongitude,
    name: lastLocationName,
  } = lastUpload
  const hasFotoTreeTag = (role === 'admin' || role === 'leader') && tags[0]

  const {
    extension,
    size,
    exif,
    file,
    latitude: selectLatitude,
    longitude: selectLongitude,
  } = select

  const lastModified = getLocalDate(
    exif?.CreateDate || new Date(file.lastModified) || new Date(),
  ).toISOString()

  const validLatitude = selectLatitude !== undefined && selectLatitude !== 'NaN'
  const validLongitude =
    selectLongitude !== undefined && selectLongitude !== 'NaN'

  const date = lastModified.substring(0, 10)
  const time = lastModified.substring(11, 16)

  const latitude = validLatitude
    ? selectLatitude
    : sendToFace
    ? ''
    : hasFotoTreeTag && tags[0].location
    ? tags[0].location?.latitude.toString()
    : lastLatitude
  const longitude = validLongitude
    ? selectLongitude
    : sendToFace
    ? ''
    : hasFotoTreeTag && tags[0].location
    ? tags[0].location?.longitude.toString()
    : lastLongitude
  const locationName =
    validLatitude || validLongitude
      ? ''
      : sendToFace
      ? ''
      : hasFotoTreeTag && tags[0].location
      ? tags[0].name
      : lastLocationName

  const location =
    latitude === '' || longitude === ''
      ? ''
      : getGiftshopLocationName({
          latitude,
          longitude,
          name: locationName,
        })

  const originalAt =
    date &&
    time &&
    new Date(
      date.concat('T').concat(time).concat(':00.000+07:00'),
    ).toISOString()

  const defaultValues = {
    id,
    tags,
    file,
    title: file.name,
    peopleTag: [],
    currencyName: PAYMENT_CURRENCY_NAME_DEFAULT,
    currencyId: PAYMENT_CURRENCY_ID_DEFAULT,
    price,
    date,
    time,
    location,
    description,
    width: 0,
    height: 0,
    extension,
    originalAt,
    size,
  }

  return defaultValues
}
