import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_RESENDPOLOGY_DURATION,
  GIFT_SHOP_RESEND_DURATION,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {parseDate, useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(15)};
`

export interface GiftShopMessageRoomContentItemNotificationResendpologyCreatorProps {
  payload: FirebaseMessageRoomItemPayload['notification_resendpology_creator']
}

export default function GiftShopMessageRoomContentItemNotificationResendpologyCreator({
  payload,
}: GiftShopMessageRoomContentItemNotificationResendpologyCreatorProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const {
    collector_nickname,
    contents,
    creator_refund_amount,
    purchased_at,
    username,
  } = payload

  const expire = useMemo(() => {
    const date = new Date(purchased_at)
    date.setDate(
      date.getDate() +
        GIFT_SHOP_RESEND_DURATION +
        GIFT_SHOP_RESENDPOLOGY_DURATION,
    )
    return date
  }, [purchased_at])

  const isExpired = useMemo(() => {
    const today = new Date()
    return today >= expire
  }, [expire])

  const handleLoadData = useCallback(() => {
    requestData('giftshop_get_contents_detail', {
      params: {content_ids: contents.toString()},
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setData(result)
        setLoading(false)
      },
    })
  }, [contents])

  const handleRenderUpperMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:hiUsername', {username})}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationResendpologyCreatorFirst')}
        </Paragraph>
        <br />
        <Paragraph fontSize="xs" color="gray_5">
          {translate('messages:notificationResendpologyCreatorPurchaseDate', {
            purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY'),
          })}
        </Paragraph>
      </>
    ),
    [purchased_at, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push(
            'giftshop_profile_creation_detail',
            {
              data,
              selectedItemId: item.content_id,
              creationType: 'resend',
              limitContent: true,
            },
            item.creator_name,
          )
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderBottomMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:notificationResendpologyCreatorSecond', {
            creator_refund_amount,
            collector_nickname,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationResendpologyCreatorThird', {
            collector_nickname,
          })}
        </Paragraph>
      </>
    ),
    [collector_nickname, creator_refund_amount, translate],
  )

  const handleRenderButton = useMemo(
    () =>
      !isExpired && (
        <StyledButton
          label={translate('messages:resendpologyButton')}
          backgroundColor="warning_5"
          backgroundHoverColor="warning_4"
          onClick={() =>
            history.push(
              'giftshop_profile',
              {
                self: true,
                creationType: 'resend',
              },
              username,
            )
          }
        />
      ),
    [history, isExpired, translate, username],
  )

  useDidMount(handleLoadData)

  return (
    <>
      {handleRenderUpperMessage}
      {handleRenderContent}
      {handleRenderBottomMessage}
      {handleRenderButton}
    </>
  )
}
