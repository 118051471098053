import {MaintenanceStatusResponse, ModalMaintenanceHandler} from 'types'

let handler: ModalMaintenanceHandler | undefined

export function setModalMaintenanceHandler(instance: ModalMaintenanceHandler) {
  handler = instance
}

export function showModalMaintenance(
  visible: boolean,
  data: MaintenanceStatusResponse,
) {
  handler && handler.showModal(visible, data)
}
