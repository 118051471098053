import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {WebMessageReceiver} from 'consts'
import {translate} from 'i18n'
import {WindowModeType} from 'types'
import {useDidMount, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {Modal, ModalProps} from '../Modal'
import {Paragraph} from '../Paragraph'

interface StyledProps {
  mode: WindowModeType
}

const StyledContentContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: center;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  max-width: ${convertUnit(900)};
`

const StyledHeaderParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(20)};
`

const StyledModalHeader = styled.div<StyledProps>`
  ${({mode}) => ({
    padding: mode !== 'mobile' ? 0 : convertUnit(20),
    paddingLeft: 0,
  })}
  position: relative;
  display: flex;
  align-items: center;
`

const StyledSeperator = styled.div<StyledProps>`
  ${({mode}) => ({
    marginTop: mode !== 'mobile' ? convertUnit(10) : 0,
  })}
  background-color: ${({theme}) => theme.white_3};
  width: 100%;
  display: flex;
  flex: 1;
  height: ${convertUnit(1)};
`

const StyledModal = styled(Modal)<StyledProps>`
  ${({mode}) => ({
    width: mode !== 'mobile' ? convertUnit(375) : '100%',
    height: mode !== 'mobile' ? convertUnit(600) : '100%',
    padding: mode !== 'mobile' ? convertUnit(20) : convertUnit(10),
    borderRadius: mode !== 'mobile' ? convertUnit(16) : 0,
  })}
`

interface PaymentModalProps extends ModalProps {
  token: string
  toggleModal: () => void
}

export default function PaymentMethodModal({
  token,
  toggleModal,
  ...props
}: PaymentModalProps) {
  const history = useHistory()
  const mode = useWindowMode()
  const {access_token} = useSelector('user')!
  const host = useMemo(
    () =>
      `${process.env.HOST_WEB_PAYMENT}/checkout?token=${token}&access_token=${access_token}`,
    [access_token, token],
  )

  const paymentHost = useMemo(() => process.env.HOST_WEB_PAYMENT || '', [])

  const validateOrigin = useCallback(
    (origin: string) => origin.startsWith(paymentHost),
    [paymentHost],
  )

  const responseHandler = useCallback(
    ({origin, data}: MessageEvent) => {
      const valid = validateOrigin(origin)
      const {type} = JSON.parse(data) as WebMessageReceiver

      if (!valid) {
        return
      }

      switch (type) {
        case 'close':
          toggleModal()
          break
        case 'explore':
          history.push('giftshop_explore', {})
          break
        default:
      }
    },
    [history, toggleModal, validateOrigin],
  )

  useDidMount(() => {
    window.addEventListener('message', responseHandler)

    return () => {
      window.removeEventListener('message', responseHandler)
    }
  })

  return (
    <StyledModal mode={mode} {...props}>
      <StyledModalHeader mode={mode}>
        <StyledHeaderParagraph fontSize="l" fontWeight="medium">
          {translate('global:checkoutPayment')}
        </StyledHeaderParagraph>
      </StyledModalHeader>
      <StyledSeperator mode={mode} />
      <StyledContentContainer>
        <StyledIframe src={host} title="Payment" />
      </StyledContentContainer>
    </StyledModal>
  )
}
