import React, {useCallback, useEffect, useMemo, useState} from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import {IMAGE_ASSET, INITIALIZE_GOPAY_DATA} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {calcAddMinutes} from 'utils'
import {useWindowMode} from 'windows'
import {Image, ModalLoading, Paragraph} from 'common/components'
import firebase from 'lib/firebase'
import {REDUX_STORE} from 'lib/redux'
import convertUnit from 'lib/unit'
import {
  GopayOutletData,
  TransactionEwalletDetailsParams,
} from './PaymentEwalletDetailsProps'
import {PaymentUserInfo} from '../UserInfo'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({mode, theme}) => ({
    flexDirection: mode === 'website' ? 'row' : 'column',
    margin: convertUnit(mode === 'website' ? 25 : 0),
    padding: convertUnit(mode === 'website' ? 50 : 25),
    backgroundColor: theme.white_1,
  })}
  display: flex;
  box-sizing: border-box;
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledRightSection = styled(StyledSection)<StyledContainerProps>`
  ${({mode}) => ({
    marginLeft: mode !== 'website' ? undefined : convertUnit(50),
    marginTop: mode !== 'website' ? convertUnit(25) : undefined,
  })};
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(Paragraph)`
  margin-top: ${convertUnit(8)};
`

const StyledLogo = styled(Image)`
  width: ${convertUnit(44)};
  height: ${convertUnit(26)};
  object-fit: contain;
`

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledDescriptionContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(10)};
  padding: ${convertUnit(10)};
`

const StyledList = styled.ol`
  font-family: 'Roboto-Regular' !important;
  margin: 0;
  padding-left: ${convertUnit(20)};
`

const StyledDesc = styled(Paragraph)`
  white-space: pre-line;
  margin-right: ${convertUnit(10)};
  margin-left: ${convertUnit(10)};
  line-height: ${convertUnit(21)};
`
const StyledQrContainer = styled(Paragraph)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledQrCode = styled(QRCode)`
  margin-top: ${convertUnit(10)};
  height: ${convertUnit(200)};
  width: ${convertUnit(200)};
  display: flex;
  align-self: center;
`

const StyledQrImage = styled(Image)`
  margin-top: ${convertUnit(10)};
  height: ${convertUnit(200)};
  width: ${convertUnit(200)};
  display: flex;
  justify-content: center;
  align-self: center;
`

export default function PaymentEwalletDetail({
  invoiceId,
  transactionDate,
  paymentMethod,
}: TransactionEwalletDetailsParams) {
  const {translate} = useTranslation()
  const [eWallet, setEWallet] = useState<GopayOutletData>(INITIALIZE_GOPAY_DATA)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [expiryDate, setExpiryDate] = useState(eWallet.transaction_time)
  const [timeDiff, setTimeDiff] = useState(
    calcAddMinutes(eWallet.transaction_time, currentDate, 15),
  )
  const [extraFee, setExtraFee] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const mode = useWindowMode()
  const [loading, setLoading] = useState(true)
  const appName = useMemo(
    () => (paymentMethod === 'ShopeePay' ? 'Shopee' : 'Gojek'),
    [paymentMethod],
  )
  const appLogo = useMemo(
    () =>
      paymentMethod === 'ShopeePay'
        ? IMAGE_ASSET('icons', 'shopeepay-logo.png')
        : IMAGE_ASSET('icons', 'gopay.png'),
    [paymentMethod],
  )

  const qrImage = useMemo(() => eWallet.actions.qr_checkout_string, [
    eWallet.actions.qr_checkout_string,
  ])

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(invoiceId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setEWallet(data.ewallet)
            setExtraFee(data.extra_fee)
            setTotalPrice(data.price)
            setLoading(false)
          }
        }),
    [invoiceId],
  )

  useEffect(handleFirestore, [handleFirestore])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [currentDate])

  useEffect(() => {
    setTimeDiff(calcAddMinutes(eWallet.transaction_time, currentDate, 15))
    const newExpiryDate =
      new Date(eWallet.transaction_time).getTime() + 15 * 60 * 1000
    const newExpiryDateString = new Date(newExpiryDate).toUTCString()
    setExpiryDate(newExpiryDateString)
  }, [currentDate, eWallet.transaction_time])

  const handleRenderInfo = useMemo(
    () => (
      <PaymentUserInfo
        currentDate={transactionDate}
        expirationDate={expiryDate}
        timeDiff={timeDiff}
        expectedAmount={totalPrice}
        totalPrice={totalPrice}
        extraFee={extraFee}
        username={REDUX_STORE.getState().user?.username!}
      />
    ),
    [expiryDate, extraFee, timeDiff, totalPrice, transactionDate],
  )

  const handleAccountDetail = useMemo(
    () => (
      <StyledRightSection mode={mode}>
        <StyledTitleContainer>
          <StyledTitle fontSize="m" fontWeight="medium">
            {translate('giftShop:paymentInstruction')}
          </StyledTitle>
          <StyledLogo src={appLogo} alt="Payment Logo" />
        </StyledTitleContainer>
        <StyledDescriptionContainer>
          <StyledList>
            <li>
              <StyledDesc fontSize="m">
                {translate('giftShop:eWalletQRInstruction', {
                  method: paymentMethod,
                  app: appName,
                })}
              </StyledDesc>
            </li>
            <li>
              <StyledDesc fontSize="m">
                {translate('giftShop:eWalletQRInstruction_second')}
              </StyledDesc>
            </li>
          </StyledList>
          <StyledQrContainer>
            {qrImage ? (
              <StyledQrCode value={qrImage} />
            ) : (
              <StyledQrImage
                alt={eWallet.actions.generate_qr_code}
                src={eWallet.actions.generate_qr_code}
              />
            )}
          </StyledQrContainer>
        </StyledDescriptionContainer>
      </StyledRightSection>
    ),
    [
      appLogo,
      appName,
      eWallet.actions.generate_qr_code,
      mode,
      paymentMethod,
      qrImage,
      translate,
    ],
  )

  return (
    <StyledContainer mode={mode}>
      {handleRenderInfo}
      {handleAccountDetail}
      <ModalLoading visible={loading} />
    </StyledContainer>
  )
}
