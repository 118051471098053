import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getFileSize, parseDate} from 'utils'
import {
  ActivityIndicator,
  ButtonOutline,
  Icon,
  Image,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileResendBatchItemProps} from './GiftShopProfileResendBatchItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_3};
`

const StyledImage = styled(Image)`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 1;
`

const StyledBottomContainer = styled(StyledContainer)`
  gap: ${convertUnit(8)};
  padding: ${convertUnit(12)};
`

const StyledNoSrc = styled(StyledContainer)`
  padding: ${convertUnit(16)};
  gap: ${convertUnit(4)};
  text-align: center;
`

const StyledTitleContainer = styled(StyledContainer)`
  text-align: center;
  gap: ${convertUnit(2)};
  background-color: ${({theme}) => theme.white_2};
`

export default function GiftShopProfileResendBatchItem({
  date,
  src,
  title,
  resolution,
  size,
  showButton = false,
  loadingImg = false,
  onChangePhoto,
}: GiftShopProfileResendBatchItemProps) {
  const {translate} = useTranslation()

  const handleRenderImage = useMemo(
    () => (
      <StyledImageContainer>
        {loadingImg ? (
          <ActivityIndicator iconColor="gray_5" iconSize={30} />
        ) : src ? (
          <StyledImage src={src} alt={src} />
        ) : (
          <StyledNoSrc>
            <Icon type="image" size={convertUnit(32)} color="gray_3" />
            <Paragraph fontSize="s" fontWeight="medium" color="gray_3">
              {translate('giftShop:resendBatchPhotoPlaceholder')}
            </Paragraph>
          </StyledNoSrc>
        )}
      </StyledImageContainer>
    ),
    [loadingImg, src, translate],
  )

  const handleRenderFileName = useMemo(
    () => (
      <Paragraph fontWeight="bold" color={title ? 'primary_5' : 'gray_5'}>
        {title || translate('giftShop:resendBatchNoFileName')}
      </Paragraph>
    ),
    [title, translate],
  )

  const handleRenderResolutionAndSize = useMemo(
    () => (
      <Paragraph fontWeight="medium" color="gray_5">
        {resolution && size
          ? `${resolution.width} x ${resolution.height} • ${getFileSize(size)}`
          : `${translate('giftShop:resendBatchNoResolution')} • ${translate(
              'giftShop:resendBatchNoFileSize',
            )}`}
      </Paragraph>
    ),
    [resolution, size, translate],
  )

  const handleRenderDate = useMemo(
    () => (
      <Paragraph fontSize="s" color="gray_4">
        {(date && parseDate(date, 'D MMMM, YYYY, hh:mm')) ||
          translate('giftShop:resendBatchNoDate')}
      </Paragraph>
    ),
    [date, translate],
  )

  return (
    <StyledContainer>
      {handleRenderImage}
      <StyledBottomContainer>
        <StyledTitleContainer>
          {handleRenderFileName}
          {handleRenderResolutionAndSize}
          {handleRenderDate}
        </StyledTitleContainer>
        {showButton && (
          <ButtonOutline
            label={
              src
                ? translate('giftShop:changePhotoButton')
                : translate('giftShop:addPhoto')
            }
            borderColor="gray_5"
            color="gray_5"
            onClick={onChangePhoto}
          />
        )}
      </StyledBottomContainer>
    </StyledContainer>
  )
}
