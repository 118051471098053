import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateOptionsItemProps} from './GiftShopTemplateOptionsProps'

const StyledContainer = styled.div`
  padding: ${convertUnit(16)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

export default function GiftShopTemplateOptionsModal({
  icon,
  text,
  onClickItem,
}: GiftShopTemplateOptionsItemProps) {
  return (
    <StyledContainer onClick={onClickItem}>
      <Icon type={icon} size={convertUnit(24)} />
      <Paragraph fontSize="m" fontWeight="medium">
        {text}
      </Paragraph>
    </StyledContainer>
  )
}
