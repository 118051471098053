import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IconType} from 'types'
import {Icon, Paragraph, Button} from 'common/components'
import convertUnit from 'lib/unit'
import {useHistory} from 'utils'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledIcon = styled(Icon)`
  margin-right: ${convertUnit(20)};
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export default function GiftShopRobopetActivationUnregistered() {
  const {translate} = useTranslation()
  const history = useHistory()

  const handleRenderDescriptionItems = useCallback(
    (icon: IconType) => (
      <StyledRowContainer>
        <StyledIcon type={icon} size={convertUnit(32)} color="white_1" />
        <Paragraph fontSize="m" fontWeight="medium" color="white_1">
          {translate('giftShop:roboyuActivationUnregisteredDescription', {
            context: icon,
          })}
        </Paragraph>
      </StyledRowContainer>
    ),
    [translate],
  )

  return (
    <GiftShopRobopetActivationTemplate
      backgroundColor="primary_5"
      robopetImg="robopet.png"
      title={translate('giftShop:roboyuActivationUnregisteredHeader')}
      titleColor="white_1">
      <StyledContentContainer>
        {handleRenderDescriptionItems('search')}
        {handleRenderDescriptionItems('robopet')}
        {handleRenderDescriptionItems('filter-ol')}
        {handleRenderDescriptionItems('image-search')}
      </StyledContentContainer>
      <StyledButton
        label={translate('global:next')}
        color="primary_5"
        backgroundColor="white_3"
        backgroundHoverColor="white_1"
        onClick={() => history.push('giftshop_robopet_activation_selfie', {})}
      />
    </GiftShopRobopetActivationTemplate>
  )
}
