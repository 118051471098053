import {DateDay, DateMonth} from 'types'

export const DATE_DAY_LIST: DateDay[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const DATE_MONTH_LIST: DateMonth[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const ONE_YEAR_MILISECONDS = 31536000000
